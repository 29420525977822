import { Col, Form, Row, Space, Table } from "@pankod/refine";
import { FunctionComponent } from "react";
import { useList } from "./hooks";

interface ListComponentProps {
    data: any[];
    onPageChange: any;
    pagination: any;
    dataLoading: any;
}

export const List: FunctionComponent<ListComponentProps> = (props: ListComponentProps) => {
    const [columns] = useList();
    return (
        <>
            <Row>
                <Col span={24}>
                    <Form name="basic" layout="inline" initialValues={{ remember: true }} autoComplete="off">
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Table
                                columns={columns}
                                pagination={props.pagination}
                                loading={props.dataLoading}
                                onChange={(page) => props.onPageChange(page)}
                                dataSource={props.data}
                                scroll={{ x: "100%" }}
                            />
                        </Space>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
