import { EditOutlined } from "@ant-design/icons";
import { Button, Col, Form, Row, Space, Typography, useTranslate } from "@pankod/refine";
import { ShowCampaignExplanation } from "components/atomics/show-items";
import { GetPlatforms } from "helpers/StateHelpers";
import { ICampaignData } from "pages/campaign/Model/CampaignList";
import { FunctionComponent } from "react";
import { Money } from "services/models/money";

const { Text, Title } = Typography;

interface ClickCampaignStep4Props {
    campaignData: ICampaignData;
    formFinished: (campaignData: ICampaignData) => void;
    triggerEdit: (pageNo: number) => void;
    approved: () => void;
    loading: boolean;
    brands: any[];
    categories: any[];
    countries: any[];
    back: any;
    isEditPage: boolean;
}

export const ClickCampaignStep4: FunctionComponent<ClickCampaignStep4Props> = (props: ClickCampaignStep4Props) => {
    const t = useTranslate();
    const getMarj = () => {
        if (
            props.campaignData.totalAmount &&
            +props.campaignData.totalAmount > 0 &&
            props.campaignData.influencerTotalAmount &&
            +props.campaignData.influencerTotalAmount > 0
        ) {
            const totalAmount = +props.campaignData.totalAmount;
            const totalInfAmount = +props.campaignData.influencerTotalAmount;
            return (100 * (totalAmount - totalInfAmount)) / totalAmount;
        } else {
            return "-";
        }
    };

    return (
        <Form
            layout="vertical"
            onFinish={(values) => {
                props.approved();
            }}
        >
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "space-between" }}>
                <Title style={{ color: "#952ae7" }} level={4}>
                    {t("campaign.create.step1.stepTitle")}
                </Title>
                <Button
                    type="link"
                    onClick={() => {
                        props.triggerEdit(0);
                    }}
                >
                    <EditOutlined />
                </Button>
            </Space>
            <hr color={"#952ae7"} />
            <Row style={{ marginTop: "25px" }} gutter={[16, 16]}>
                <Col xs={24} sm={24} md={7} span={7}>
                    <Title level={5}>{t("campaign.create.step1.brandNameLabel")}</Title>
                    <Text>{props.brands.filter((p) => p.id === props.campaignData.brandName)[0].name}</Text>

                    <Title level={5}>{t("campaign.create.step1.campaignNameLabel")}</Title>
                    <Text>{props.campaignData.campaignName}</Text>
                </Col>
                <Col xs={24} sm={24} md={7} span={7}>
                    <ShowCampaignExplanation description={props.campaignData.campaignExplanation} />
                </Col>
                <Col xs={24} sm={24} md={7} span={7}>
                    <Title level={5}>{t("campaign.create.step1.campaignLinkLabel")}</Title>
                    <Text>{props.campaignData.campaignLink}</Text>
                </Col>
            </Row>
            <br />
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "space-between" }}>
                <Title style={{ color: "#952ae7" }} level={4}>
                    {t("campaign.create.step2.stepTitle")}
                </Title>
                <Button
                    type="link"
                    onClick={() => {
                        props.triggerEdit(1);
                    }}
                >
                    <EditOutlined />
                </Button>
            </Space>
            <hr color={"#952ae7"} />
            <Row style={{ marginTop: "25px" }} gutter={[16, 16]}>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("global.location")}</Title>
                    <Text>{props.countries.filter((p) => p.id === props.campaignData.country)[0].text}</Text>

                    <Title level={5}>{t("global.platforms")}</Title>
                    <Text>
                        {GetPlatforms()
                            .filter((sm) => props.campaignData.platforms?.includes(sm.value))
                            .map((sm) => sm.label)
                            .join(" - ")}
                    </Text>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("global.categories")}</Title>
                    <Text>
                        {props.categories
                            .filter((c) => props.campaignData.categories?.includes(c.id))
                            .map((c) => "#" + c.name)
                            .join(" - ")}
                    </Text>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("campaign.create.step2.whitelistCategoriesLabel")}</Title>
                    <Text>
                        {props.categories
                            .filter((c) => props.campaignData.whitelistCategories?.includes(c.id))
                            .map((c) => "#" + c.name)
                            .join(" - ")}
                    </Text>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("campaign.create.step2.blacklistCategoriesLabell")}</Title>
                    <Text>
                        {props.categories
                            .filter((c) => props.campaignData.blacklistCategories?.includes(c.id))
                            .map((c) => "#" + c.name)
                            .join(" - ")}
                    </Text>
                </Col>
            </Row>
            <br />
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "space-between" }}>
                <Title style={{ color: "#952ae7" }} level={4}>
                    {t("campaign.create.step3.stepTitle")}
                </Title>
                <Button
                    type="link"
                    onClick={() => {
                        props.triggerEdit(2);
                    }}
                >
                    <EditOutlined />
                </Button>
            </Space>
            <hr color={"#952ae7"} />
            <Row style={{ marginTop: "25px" }} gutter={[16, 16]}>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("global.start-date")}</Title>
                    <Text>{(props.campaignData.startDate as any).format("YYYY-MM-DD")}</Text>

                    <Title level={5}>{t("campaign.create.step3.minimumInfluencerCountLabel")}</Title>
                    <Text>{props.campaignData.minimumInfluencerCount}</Text>

                    <Title level={5}>{t("campaign.create.step3.influencerExtra")}</Title>
                    <Text>{getMarj()}</Text>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("global.end-date")}</Title>
                    <Text>{props.campaignData.endDate && (props.campaignData.endDate as any).format("YYYY-MM-DD")}</Text>

                    <Title level={5}>{t("campaign.create.step3.amountByClickLabel")}</Title>
                    <Text>{new Money(props.campaignData.amountByClick).toStringWithCurrency("₺")}</Text>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("global.total-budget")}</Title>
                    <Text>{new Money(props.campaignData.totalAmount).toStringWithCurrency("₺")}</Text>

                    <Title level={5}>{t("campaign.create.step3.amountByClickInfluencerEarningLabel")}</Title>
                    <Text>{new Money(props.campaignData.amountByClickInfluencerEarning).toStringWithCurrency("₺")}</Text>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("campaign.create.step3.influencerTotalAmountLabel")}</Title>
                    <Text>{new Money(props.campaignData.influencerTotalAmount).toStringWithCurrency("₺")}</Text>

                    <Title level={5}>{t("campaign.create.step3.amountByInfluencerLabel")}</Title>
                    <Text>{new Money(props.campaignData.amountByInfluencer).toStringWithCurrency("₺")}</Text>
                </Col>
            </Row>

            <Form.Item>
                <Space direction="horizontal" style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button onClick={() => props.back()} style={{ marginLeft: "auto" }} type="primary">
                        {t("buttons.back")}
                    </Button>
                    {props.isEditPage ? (
                        <Button style={{ marginLeft: "auto" }} type="default" htmlType="submit" loading={props.loading}>
                            {t("global.update")}
                        </Button>
                    ) : (
                        <Button style={{ marginLeft: "auto" }} type="default" htmlType="submit" loading={props.loading}>
                            {t("global.approve")}
                        </Button>
                    )}
                </Space>
            </Form.Item>
        </Form>
    );
};
