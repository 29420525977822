import { Authenticated, LayoutWrapper } from "@pankod/refine";
import { InfluencerBalance, InfluencerBilling, InfluencerConversion } from "modules/wallet";
import UserHelper from "../../helpers/userHelper";

const Wallet = () => {
    const influencerId = UserHelper.getId();

    return (
        <>
            <Authenticated>
                <LayoutWrapper>
                    <InfluencerBalance influencerId={influencerId} />
                    <InfluencerConversion influencerId={influencerId} />
                    <InfluencerBilling influencerId={influencerId} />
                </LayoutWrapper>
            </Authenticated>
        </>
    );
};

export default Wallet;
