import { useTranslate } from "@pankod/refine";
import { Button, Form, Col, Row } from "antd";
import { FC } from "react";

interface ReportFilterProps {
    onFilter: (values: any) => void;
}

export const ReportFilter: FC<ReportFilterProps> = ({ onFilter, children }) => {
    const t = useTranslate();

    const handleSubmit = ({ date, ...values }: { date: any }) => {
        let startDate = "";
        let endDate = "";
        if (date?.length) {
            startDate = date[0];
            endDate = date[1];
        }

        onFilter({ startDate, endDate, ...values });
    };
    return (
        <Form
            layout="inline"
            style={{ width: "100%", justifyContent: "flex-end", margin: "10px 20px", gap: "10px" }}
            onFinish={handleSubmit}
        >
            <Row style={{ width: "100%" }} justify="end" gutter={[1, 16]}>
                {children}
                <Col xs={24} sm={6} md={4} xl={3}>
                    <Form.Item wrapperCol={{ span: 24 }}>
                        <Button style={{ width: "100%" }} type="ghost" htmlType="submit">
                            {t("buttons.filter")}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};
