import { Tabs, Space, Badge, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { FunctionComponent, useEffect, useState } from "react";
import { InfluencerList } from "./InfluencerList";
import { CampaignsService } from "../../../../../../../../services";
import { localizationHelper } from "helpers/globalizationHelper";
import { InfluencerListType } from "pages/campaign/InfluencerListType";

const { TabPane } = Tabs;

interface CampaignInfluencerListContainerProps {
    campaignDetail: any;
    categories: any[];
}

const CampaignInfluencerListContainer: FunctionComponent<CampaignInfluencerListContainerProps> = (
    props: CampaignInfluencerListContainerProps
) => {
    const t = useTranslate();
    const [countData, setCountData] = useState<any>({
        totalCount: 0,
        appliedCount: 0,
        activeCount: 0,
        rejectedCount: 0,
        invitedCount: 0,
    });

    useEffect(() => {
        fetchCounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCounts = () => {
        CampaignsService.getCampaignInfluencersInfoByCampaign({
            campaignId: props.campaignDetail?.id,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setCountData(resp.data);
        });
    };

    const updateCount = () => {
        window.location.reload();
    };

    return (
        <PageContainer pageTitle={props.campaignDetail?.name ?? " "} helperContent={"Buraya helper içeriği gelecek"}>
            <>
                <hr style={{ border: "1px solid #0000000F" }} />
                <Tabs defaultActiveKey="1" destroyInactiveTabPane>
                    <TabPane
                        disabled={countData.activeCount === 0}
                        tab={
                            <Space direction="horizontal">
                                {t("global.checkPhenomenons")}
                                <Badge count={countData.activeCount} />
                            </Space>
                        }
                        key="1"
                    >
                        <InfluencerList
                            campaignDetail={props.campaignDetail}
                            updateCount={updateCount}
                            categories={props.categories}
                            listType={InfluencerListType.active}
                            campaignId={props.campaignDetail?.id}
                        />
                    </TabPane>
                    <TabPane
                        disabled={countData.appliedCount === 0}
                        tab={
                            <Space direction="horizontal">
                                {t("global.pendingInfluencers")}
                                <Badge count={countData.appliedCount} />
                            </Space>
                        }
                        key="2"
                    >
                        <InfluencerList
                            campaignDetail={props.campaignDetail}
                            updateCount={updateCount}
                            categories={props.categories}
                            listType={InfluencerListType.applied}
                            campaignId={props.campaignDetail?.id}
                        />
                    </TabPane>
                    <TabPane
                        disabled={countData.invitedCount === 0}
                        tab={
                            <Space direction="horizontal">
                                {t("global.invitedInfluencers")}
                                <Badge count={countData.invitedCount} />
                            </Space>
                        }
                        key="3"
                    >
                        <InfluencerList
                            campaignDetail={props.campaignDetail}
                            updateCount={updateCount}
                            categories={props.categories}
                            listType={InfluencerListType.listAll}
                            campaignId={props.campaignDetail?.id}
                        />
                    </TabPane>
                </Tabs>
            </>
        </PageContainer>
    );
};

export default CampaignInfluencerListContainer;
