import { Col, Row, useTranslate } from "@pankod/refine";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { DisplayForm } from "modules/brands/detail/influencer";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { useBrandDetail } from "modules/brands/detail/hooks";

export const InfluencerBrandDetail: FunctionComponent = () => {
    const { brandId } = useParams<{ brandId: string }>();

    const [, brand] = useBrandDetail(brandId);
    const t = useTranslate();

    return (
        <Row>
            <Col span={12}>
                <FenBreadCrumb
                    items={[
                        { name: t("global.campaigns"), url: "/campaign/list" },
                        { name: t("global.brand"), url: "" },
                    ]}
                />
                <PageContainer pageTitle={"" + brand.name} helperContent={"Buraya yardım içeriği gelecek"}>
                    <>
                        <DisplayForm brand={brand} />
                    </>
                </PageContainer>
            </Col>
        </Row>
    );
};
