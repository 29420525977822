import { useTranslate } from "@pankod/refine";
import { EVENTS } from "Constanst";
import { useModal } from "hooks";
import { useState } from "react";

export const usePaymentResult = <T>() => {
    const [copied, setCopied] = useState(false);
    const [open, detail, close] = useModal<T>(EVENTS.PAYMENT_RESULT_MODAL_IS_OPENED);

    const t = useTranslate();
    function handleCopy() {
        if (!detail) return;

        // Empty strings is adding to make space between title and name list
        const text = [
            t("payment.result.succeeded", "Başarılı Olanlar"),
            "",
            ...detail.successfulPayments,
            "",
            t("payment.result.failed", "Başarısız Olanlar"),
            "",
            ...detail.failedPayments,
        ];
        navigator.clipboard.writeText(text.join("\n")).then(() => {
            setCopied(true);
        });
    }

    function handleClose() {
        setCopied(false);
        close();
    }
    return [open, detail as T, handleClose, handleCopy, copied] as const;
};
