import { FC, useMemo } from "react";
import { useBrandReceipt } from "./useBrandReceipt";
import moment from "moment";
import { useTranslate } from "@pankod/refine";
import { Table } from "antd";
import { Money } from "services/models/money";

export const BrandReceipts: FC = () => {
    const [loading, receipts, pagination] = useBrandReceipt();

    const t = useTranslate();
    const columns = useMemo(
        () => [
            {
                title: <span className="table-font">{t("global.reference")}</span>,
                dataIndex: "referenceId",
                key: "referenceId",
                render: (text: string, row: any, index: number) => <>#{text}</>,
            },
            {
                title: <span className="table-font">{t("global.date")}</span>,
                dataIndex: "date",
                key: "date",
                render: (date: string, row: any, index: number) => <>#{moment(date).format("DD/MM/YYYY")}</>,
            },
            {
                title: <span className="table-font">{t("global.amount")}</span>,
                dataIndex: "price",
                key: "price",
                render: (price: string) => <>{new Money(price).toStringWithCurrency("₺")} </>,
            },
            {
                title: <span className="table-font">{t("global.campaign-name")}</span>,
                dataIndex: "campaignName",
                key: "campaignName",
                render: (text: string) => <>#t{text}</>,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return <Table pagination={pagination} loading={loading} columns={columns} dataSource={receipts} scroll={{ x: "100%" }} />;
};
