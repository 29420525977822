import { EVENTS } from "Constanst";
import { useModal } from "hooks";
import { useState, useEffect } from "react";
import { BillingDetail, InfluencersService } from "services";

export const useAccountInformationModal = () => {
    // TODO: InfluencerBillingDetail Model will be added
    const [influencerBillingDetail, setInfluencerBillingDetail] = useState<BillingDetail>();
    const [loading, setLoading] = useState(true);

    const [open, detail, handleClose] = useModal<any>(EVENTS.PAYMENT_ACCOUNT_DETAIL_MODAL_IS_OPENED);

    useEffect(() => {
        if (!detail?.id) return;

        const request = InfluencersService.getInfluencersBillingDetail(detail?.id);

        request
            .then((response) => {
                setInfluencerBillingDetail(response.data);
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            request.cancel();
        };
    }, [detail?.id]);
    return [loading, influencerBillingDetail, open, detail?.paymentInvoiceStatus, handleClose] as const;
};
