import { RouteProps } from "react-router-dom";
import { UserDashBoard } from "./userDashboard";

export const dashboardRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: UserDashBoard,
            path: "/",
        },
    ];
};
