import React from "react";
import ReactDOM from "react-dom";

import reportWebVitals from "./reportWebVitals";
import App from "./App";
import "./providers/i18n";
import { store } from "./store";
import { Provider } from "react-redux";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spin } from "@pankod/refine";
import "./extensions/impls/string.extensions";

ReactDOM.render(
    <React.StrictMode>
        <React.Suspense
            fallback={
                <>
                    <div className={"cloud-container"} style={{ backgroundColor: "white" }}>
                        <Spin className={"center-screen"} size="large" />
                    </div>
                </>
            }
        >
            <Provider store={store}>
                <GoogleReCaptchaProvider reCaptchaKey="6LdbL7MdAAAAAGnfW-l7eUT9aBT5VGCLfecIs5RF">
                    <App />
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </GoogleReCaptchaProvider>
            </Provider>
        </React.Suspense>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
