import Wallet from "./wallet";
import { RouteProps } from "react-router-dom";

export const walletRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: Wallet,
            path: "/wallet",
        },
    ];
};
