import { CampaignType } from "Constanst";

export const getCampaignLiterals = (type: CampaignType) => {
    if (type === CampaignType.clicking) {
        return "global.click";
    } else if (type === CampaignType.download) {
        return "global.download";
    } else if (type === CampaignType.action) {
        return "global.action";
    } else if (type === CampaignType.selling) {
        return "global.sales";
    } else if (type === CampaignType.amazon) {
        return "global.amazon"
    }
    return "";
};

export const getCampaignLiteralsForClick = (type: CampaignType) => {
    return "global.total-click";
};
