import { useTranslate } from "@pankod/refine";
import { Button, Card, Modal, Checkbox, Col, Form, Input, Row, Select, Space, Table, Tag } from "antd";
import { PageTitle } from "components/PageTitle/PageTitle";
import { InfluencerStatusColors, PaginationConstant, SocialMedias } from "Constanst";
import { GetInfluencerStatus, GetInfluencerStatuText, GetPlatforms } from "helpers/StateHelpers";
import { FunctionComponent, useEffect, useState } from "react";
import { localizationHelper } from "../../../../helpers/globalizationHelper";
import { CampaignsService, InfluencersService, LookupsService } from "../../../../services";
import { Pagination } from "../../../../services/core/Pagination";
import { CategoryService } from "../../../../services/services/CategoryService";
import { Link } from "react-router-dom";
import { SocialMedia, getSocialMediaFlowers } from "helpers";
import { SorterResult } from "antd/lib/table/interface";

interface AdminInfluencerListProps {}

export const AdminInfluencerList: FunctionComponent<AdminInfluencerListProps> = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [countries, setCountries] = useState<any>();
    const [categories, setCategories] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>();
    const [campaigns, setCampaigns] = useState([]);
    const [selectedInfluencers, setSelectedInfluencers] = useState<any[]>([]);
    const [isInviteModalVisible, setIsInviteModalVisible] = useState(false);
    const [isInviteFailModalVisible, setIsInviteFailModalVisible] = useState(false);
    const [disabled, setDisabled] = useState(true);
    const [selectedCampaign, setSelectedCampaign] = useState<any>();
    const [sendingInviteMail, setSendingInviteMail] = useState(false);
    const [sendingInvite, setSendingInvite] = useState(false);

    const t = useTranslate();

    const getCategories = () => {
        CategoryService.getAllCategory({ acceptLanguage: localizationHelper.getLocale() })
            .then((res) => {
                setCategories(res.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const selectedCampaignHandler = (campaign: any) => {
        setSelectedCampaign(campaign);
        eventHandler();
    };

    const getCountries = () => {
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
        });
    };

    const prepareFilterRequest = ({ ...body }) => {
        if (body?.categories?.length === 0) {
            delete body.categories;
        }

        if (body?.countries?.length === 0) {
            delete body.countries;
        }

        const requestCategory = checkCategoryParam();

        if (requestCategory) {
            // @ts-ignore
            body.categories = [requestCategory];
        }

        return body;
    };

    const fetchData = ({ pageNumber = 1, pageSize = 10, ...filters }) => {
        setDataLoading(true);
        InfluencersService.searchInfluencers({
            body: prepareFilterRequest({ ...filters, pageNumber, pageSize }),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data?.data);
            setPagination({
                current: resp.data?.pageInfo.pageNumber,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount,
            });
        });
    };

    const doFilter = (values: any) => {
        setPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize,
        });
        setFilterValues(values);
        fetchData(values);
    };

    const checkCategoryParam = () => {
        if (window.location.search) {
            if (window.location.search.includes("?categoryId=")) {
                return window.location.search.replace("?categoryId=", "");
            }
        }

        return "";
    };

    const handleTableChange = (page: any, sort: SorterResult<any>) => {
        const SortOrder = { ascend: 1, descend: 2, default: 0 }[sort.order || "default"];

        const SortBySocialMedia = sort.columnKey || SocialMedias.instagram.name;

        fetchData({ pageNumber: page.current, pageSize: page.pageSize, ...filterValues, SortOrder, SortBySocialMedia });
    };

    const getCampaigns = () => {
        CampaignsService.getAllCampaignSummary({
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setCampaigns(resp.data);
        });
    };

    const sendInvite = (campaignForm: any, sendMail: boolean) => {
        if (selectedInfluencers && selectedInfluencers.length > 0 && campaignForm && campaignForm.campaignId) {
            if (sendMail) {
                setSendingInviteMail(true);
            } else {
                setSendingInvite(true);
            }
            CampaignsService.sendInvite({
                body: {
                    campaignId: campaignForm.campaignId,
                    influencerIds: selectedInfluencers,
                    sendMail: sendMail,
                },
                acceptLanguage: localizationHelper.getLocale(),
            })
                .then((resp) => {
                    setSendingInvite(false);
                    setSendingInviteMail(false);
                    setIsInviteModalVisible(true);
                    setSelectedInfluencers([]);
                    eventHandler();
                })
                .catch((err) => {
                    setSendingInvite(false);
                    setSendingInviteMail(false);
                    setIsInviteFailModalVisible(true);
                    setSelectedInfluencers([]);
                    eventHandler();
                });
        }
    };

    const eventHandler = () => {
        if (selectedInfluencers && selectedInfluencers.length > 0 && selectedCampaign) {
            setDisabled(false);
            return;
        }
        setDisabled(true);
    };

    const selectInfluencers = (infIds: any) => {
        setSelectedInfluencers(infIds);
    };

    useEffect(() => {
        eventHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedInfluencers]);

    const handleCancel = () => {
        setIsInviteModalVisible(false);
        setIsInviteFailModalVisible(false);
    };

    useEffect(() => {
        fetchData({ pageNumber: PaginationConstant.initialPage, pageSize: PaginationConstant.initialPageSize });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getCountries();
        getCategories();
        getCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        eventHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, selectedCampaign);
    return (
        <Row gutter={[16, 16]}>
            <Modal visible={isInviteModalVisible} centered footer={[]} onCancel={handleCancel}>
                <div style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>Kampanya Davetiniz Gönderilmiştir</h3>
                    <p>Fenomen onayladığında kampanyadan faydalanmaya başlayacaktır.</p>
                </div>
            </Modal>
            <Modal visible={isInviteFailModalVisible} centered footer={[]} onCancel={handleCancel}>
                <div style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>Kampanya Davetiniz Gönderilemedi</h3>
                    <p>Davet etmek için uygun kriterlerde fenomen bulunamadı.</p>
                </div>
            </Modal>
            <Col span={24}>
                <Card>
                    <PageTitle title={t("global.phenomenon", "Fenomen")} content={<>Buraya yardım içeriği gelecek</>} />
                    <br />
                    <Space direction="vertical" size="large" style={{ width: "100%" }}>
                        <FilterComponent pagination={pagination} countries={countries} doFilter={doFilter} categories={categories} />
                        <hr style={{ border: "1px solid #0000000F" }} />
                        <ListComponent
                            selectedCampaignHandler={selectedCampaignHandler}
                            eventHandler={eventHandler}
                            disabled={disabled}
                            sendingInvite={sendingInvite}
                            sendingInviteMail={sendingInviteMail}
                            selectedInfluencers={selectedInfluencers}
                            selectInfluencer={selectInfluencers}
                            sendInvite={sendInvite}
                            campaigns={campaigns}
                            pagination={pagination}
                            dataLoading={dataLoading}
                            data={data}
                            onPageChange={handleTableChange}
                        />
                    </Space>
                </Card>
            </Col>
        </Row>
    );
};

interface FilterComponentProps {
    countries: any[];
    categories: any[];
    doFilter: (values: any) => void;
    pagination: Pagination;
}

const FilterComponent: FunctionComponent<FilterComponentProps> = (props) => {
    const t = useTranslate();

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form name="basic" layout="vertical" onFinish={props.doFilter} autoComplete="off">
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Row gutter={[40, 0]}>
                                <Col xs={24} sm={24} md={5} span={5}>
                                    <Form.Item label={t("global.influencer-id")} name="influencerId">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={5} span={5}>
                                    <Form.Item label={t("global.influencer-name")} name="firstName">
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={5} span={5}>
                                    <Form.Item label={t("global.country")} name="countries">
                                        <Select
                                            allowClear
                                            mode={"tags"}
                                            options={props.countries?.map((country) => {
                                                return {
                                                    label: country.text,
                                                    value: country.id,
                                                };
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={5} span={5}>
                                    <Form.Item label="Platform" name="socialMedias">
                                        <Select mode={"multiple"} placeholder={t("global.platform-select")} options={GetPlatforms()} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={5} span={5}>
                                    <Form.Item label={t("campaign.create.step2.categoriesLabel")} name="categories">
                                        <Select
                                            allowClear
                                            mode={"multiple"}
                                            options={props.categories?.map((category) => {
                                                return {
                                                    label: category.name,
                                                    value: category.id,
                                                };
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={4} span={4}>
                                    <Form.Item label={t("global.status")} name="status">
                                        <Select options={GetInfluencerStatus()} allowClear />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 20, justifyContent: "space-between" }}>
                                <Col>
                                    <Form.Item name="listWaitingInfluencers" valuePropName="checked">
                                        <Checkbox>{t("campaign.create.step2.phenomenonpendingapproval")}</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col>
                                    <Col style={{ marginLeft: "auto" }}>
                                        <Button type="ghost" htmlType={"submit"}>
                                            {t("buttons.filter")}
                                        </Button>
                                    </Col>
                                </Col>
                            </Row>
                            <Row>
                                <Col className="mt-10" style={{ marginRight: "auto" }}>
                                    {props.pagination.total ? props.pagination.total : 0} {t("campaign.create.step3.phenomenonlisted")}
                                </Col>
                            </Row>
                        </Space>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

interface ListComponentProps {
    data: any[];
    onPageChange: (page: any, sort: SorterResult<any>) => void;
    pagination: any;
    dataLoading: any;
    campaigns: any[];
    selectInfluencer: any;
    sendInvite: any;
    disabled: any;
    eventHandler: any;
    selectedCampaignHandler: any;
    selectedInfluencers: any;
    sendingInvite: any;
    sendingInviteMail: any;
}

const ListComponent: FunctionComponent<ListComponentProps> = (props: ListComponentProps) => {
    const t = useTranslate();
    const [form] = Form.useForm<any>();

    const toggleSelection = (ids: any) => {
        props.selectInfluencer(ids);
        props.eventHandler();
    };

    const sendMail = () => {
        props.sendInvite(form.getFieldsValue(), true);
    };

    const columns = [
        {
            title: <span className="table-font">Id</span>,
            dataIndex: "id",
            key: "id",
            render: (text: string, row: any, index: number) => <>#{text}</>,
        },
        {
            title: <span className="table-font">{t("global.phenomenon")}</span>,
            dataIndex: "firstName",
            key: "firstName",
            render: (text: string, row: any, index: number) => (
                <>
                    <Space direction="vertical" size="small">
                        {
                            <Link to={"/influencers/detail/" + row.id}>
                                <span
                                    className="clickable-span"
                                    style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold" }}
                                >
                                    {" "}
                                    {row.firstName} {row.lastName}
                                </span>
                            </Link>
                        }
                        {row.socialMedias.map((social: any) => (
                            <a
                                key={social.name}
                                href={social.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{ padding: 0, height: 25, fontSize: "small", color: "#9254de" }}
                            >
                                {" "}
                                {"@" + social.username}{" "}
                            </a>
                        ))}
                    </Space>
                </>
            ),
        },
        {
            title: <img alt="social media icon" src="/images/social-media-icon/INSTAGRAM36Px.svg" />,
            width: 100,
            dataIndex: "socialMedias",
            key: SocialMedias.instagram.name,
            sorter: true,
            render: (socialMedias: SocialMedia[], row: any, index: number) =>
                getSocialMediaFlowers(SocialMedias.instagram.name, socialMedias),
        },
        {
            title: <img alt="social media icon" src="/images/social-media-icon/TWITTER36Px.svg" />,
            width: 100,
            dataIndex: "socialMedias",
            key: SocialMedias.twitter.name,
            sorter: true,
            render: (socialMedias: SocialMedia[], row: any, index: number) =>
                getSocialMediaFlowers(SocialMedias.twitter.name, socialMedias),
        },
        {
            title: <img alt="social media icon" src="/images/social-media-icon/YOUTUBE36Px.svg" />,
            width: 100,
            dataIndex: "socialMedias",
            key: SocialMedias.youtube.name,
            sorter: true,
            render: (socialMedias: SocialMedia[], row: any, index: number) =>
                getSocialMediaFlowers(SocialMedias.youtube.name, socialMedias),
        },
        {
            title: <img alt="social media icon" src="/images/social-media-icon/TWITCH36Px.svg" />,
            width: 100,
            dataIndex: "socialMedias",
            key: SocialMedias.twitch.name,
            sorter: true,
            render: (socialMedias: SocialMedia[], row: any, index: number) => getSocialMediaFlowers(SocialMedias.twitch.name, socialMedias),
        },
        {
            title: <img alt="social media icon" src="/images/social-media-icon/TELEGRAM36Px.svg" />,
            width: 100,
            dataIndex: "socialMedias",
            key: SocialMedias.telegram.name,
            sorter: true,
            render: (socialMedias: SocialMedia[], row: any, index: number) =>
                getSocialMediaFlowers(SocialMedias.telegram.name, socialMedias),
        },
        {
            title: <img alt="social media icon" src="/images/social-media-icon/DISCORD36Px.svg" />,
            width: 100,
            dataIndex: "socialMedias",
            key: SocialMedias.discord.name,
            sorter: true,
            render: (socialMedias: SocialMedia[], row: any, index: number) =>
                getSocialMediaFlowers(SocialMedias.discord.name, socialMedias),
        },
        {
            title: <img alt="social media icon" src="/images/social-media-icon/TIKTOK36Px.svg" />,
            width: 100,
            dataIndex: "socialMedias",
            key: SocialMedias.tiktok.name,
            sorter: true,
            render: (socialMedias: SocialMedia[], row: any, index: number) => getSocialMediaFlowers(SocialMedias.tiktok.name, socialMedias),
        },
        {
            title: <span className="table-font">{t("global.status")}</span>,
            dataIndex: "status",
            key: "status",
            render: (status: any) => {
                return <Tag color={InfluencerStatusColors[status]?.toString()}>{GetInfluencerStatuText(status)}</Tag>;
            },
        },
        {
            title: <span className="table-font">E-posta</span>,
            dataIndex: "email",
            key: "email",
            render: (text: string, row: any, index: number) => <span>{text}</span>,
        },
        {
            title: <span className="table-font">{t("pages.brand.registration")}</span>,
            dataIndex: "createdOn",
            key: "createdOn",
            render: (text: string, row: any, index: number) => <span>{text.split("T")[0]}</span>,
        },
        {
            title: <span className="table-font">{t("global.category")}</span>,
            width: 100,
            dataIndex: "categories",
            key: "categories",
            render: (text: string[], row: any, index: number) => <span style={{ fontSize: "small" }}>{text.join(", ")}</span>,
        },
        {
            render: (text: string, row: any, index: number) => (
                <Link
                    to={{
                        pathname: "/influencers/edit/" + row.id,
                        state: { datas: true },
                    }}
                >
                    <Button type="link">
                        <img alt="edit" src="/images/menu-icon/EDIT.svg" />
                    </Button>
                </Link>
            ),
        },
    ];

    const onSelectChange = (selectedRowKeys: any[]) => {
        toggleSelection(selectedRowKeys);
    };

    const handleTableChange = (pagination: any, filters: any, sort: any) => {
        props.onPageChange(pagination, sort);
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form
                        form={form}
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={(values) => props.sendInvite(values, false)}
                        autoComplete="off"
                    >
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Row>
                                <Col style={{ display: "flex" }}>
                                    <Row>
                                        <Col xs={24} sm={24} md={11} span={11} style={{ marginRight: "22px" }}>
                                            <Form.Item label={t("global.campaign")} name="campaignId">
                                                <Select
                                                    onChange={(e) => {
                                                        props.selectedCampaignHandler(e);
                                                    }}
                                                    placeholder={t("global.select-campaign")}
                                                    options={props.campaigns.map((c) => {
                                                        return {
                                                            label: c.name,
                                                            value: c.id,
                                                        };
                                                    })}
                                                />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} sm={24} md={11} span={11} style={{ display: "flex", justifyContent: "end" }}>
                                            <Button
                                                disabled={props.disabled || props.sendingInvite || props.sendingInviteMail}
                                                htmlType={"submit"}
                                                style={{ marginLeft: "10px", marginTop: "28px" }}
                                                type="default"
                                            >
                                                {props.sendingInvite ? (
                                                    <span>{t("global.sending-invite")}</span>
                                                ) : (
                                                    <span>{t("campaign.create.step1.inviteselected")}</span>
                                                )}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col style={{ marginLeft: "auto" }}>
                                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "flex-end" }}>
                                        <Button
                                            disabled={props.disabled || props.sendingInviteMail || props.sendingInvite}
                                            onClick={() => sendMail()}
                                            style={{ marginLeft: "auto", marginTop: "28px" }}
                                            type="default"
                                        >
                                            {props.sendingInviteMail ? (
                                                <span>{t("global.sending-mail")}</span>
                                            ) : (
                                                <span>{t("global.mailseleted")}</span>
                                            )}
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                            <Table
                                rowSelection={{
                                    preserveSelectedRowKeys: true,
                                    selectedRowKeys: props.selectedInfluencers,
                                    onChange: onSelectChange,
                                }}
                                columns={columns}
                                pagination={props.pagination}
                                dataSource={props.data.map((d) => {
                                    d.key = d.id;
                                    return d;
                                })}
                                loading={props.dataLoading}
                                onChange={handleTableChange}
                                scroll={{ x: "100%" }}
                                locale={{
                                    triggerDesc: t("global.sorting.tooltip.descend"),
                                    triggerAsc: t("global.sorting.tooltip.ascend"),
                                    cancelSort: t("global.sorting.tooltip.cancel"),
                                }}
                            />
                        </Space>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
