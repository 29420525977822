import { Badge, Space, Tabs, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { ICampaignData } from "pages/campaign/Model/CampaignList";
import { FunctionComponent } from "react";
import { TransactionsSummaryOfCampaignResponse } from "services/models/TransactionsSummaryOfCampaignResponse";
import { PerformanceInfluencerList } from "./PerformanceInfluencerList";
const { TabPane } = Tabs;

interface PerformanceInfluencerListContainerProps {
    data: ICampaignData;
    transactionsSummary: TransactionsSummaryOfCampaignResponse;
    transactionsSummaryPaginationChange: (pageNumber: number) => void;
    currency: string;
}

const PerformanceInfluencerListContainer: FunctionComponent<PerformanceInfluencerListContainerProps> = (
    props: PerformanceInfluencerListContainerProps
) => {
    const t = useTranslate();

    return (
        <PageContainer pageTitle={props.data?.name ?? ""} helperContent={"Buraya helper içeriği gelecek"}>
            <Tabs defaultActiveKey="1" destroyInactiveTabPane>
                <TabPane
                    disabled={props.transactionsSummary?.pageInfo?.totalItemCount === 0}
                    tab={
                        <Space direction="horizontal">
                            {t("global.phenomenons")}
                            <Badge count={props.transactionsSummary?.pageInfo?.totalItemCount} />
                        </Space>
                    }
                    key="1"
                >
                    <PerformanceInfluencerList
                        currency={props.currency}
                        balancePerInfluencer={props.data?.perInfluencerBudget}
                        transactionsSummary={props.transactionsSummary}
                        transactionsSummaryPaginationChange={props.transactionsSummaryPaginationChange}
                    />
                </TabPane>
            </Tabs>
        </PageContainer>
    );
};

export default PerformanceInfluencerListContainer;
