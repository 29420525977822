import { Authenticated, Button, Col, Form, Input, LayoutWrapper, Row, Select, Space, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { checkTCKN } from "../../../helpers/FormHelper";
import { IInfluencer } from "pages/campaign/Model/Influencer";
import { FunctionComponent, useState } from "react";
import { Fenomio_Contract_App_Brand_UpdateBrandRequest } from "services";

interface InfluencerPaymentProps {
    location: {
        state: {
            data: IInfluencer;
        };
    };
}

export const InfluencerPayment: FunctionComponent<InfluencerPaymentProps> = (props: InfluencerPaymentProps) => {
    const influencer = props.location.state.data;
    const [form] = Form.useForm();
    const [canPrepareBill, setCanPrepareBill] = useState(false);
    const [hasCompany, setHasCompany] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const t = useTranslate();

    const paymentInfo: paymentInfo = {
        canPrepareBill: true,
        hasCompany: true,
        companyType: "LTD",
        companyName: "SameUp",
        nameSurname: "Ahmet Kaya",
        identifier: "123456789",
        bankName: t("bank.isbankasi"),
        IBAN: "TR12341234123412341234123412341234",
        address: "Istanbul",
    };

    const editPaymentInfo = (value: any) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    const handleTCKN = (e: React.ChangeEvent<HTMLInputElement>) => {
        let parsedValue = e.target.value;
        const regexPattern = /[^0-9]/gm;
        parsedValue = parsedValue.replace(regexPattern, "");

        let formValues = form.getFieldsValue();
        formValues["identifier"] = parsedValue;
        form.setFieldsValue(formValues);
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageContainer
                    pageTitle={influencer.nickname + " " + t("global.payment-information")}
                    helperContent={"Buraya yardım içeriği gelecek"}
                >
                    <Form<Fenomio_Contract_App_Brand_UpdateBrandRequest>
                        layout="horizontal"
                        requiredMark={false}
                        scrollToFirstError
                        onFinish={(value) => {
                            editPaymentInfo(value);
                        }}
                    >
                        <Row gutter={[30, 8]}>
                            <Col span={18}>
                                <Form.Item
                                    name="canPrepareBill"
                                    label={t("globla.invoicestate")}
                                    initialValue={paymentInfo?.canPrepareBill && canPrepareBill}
                                >
                                    <Select
                                        onChange={(selected) => {
                                            setCanPrepareBill(selected);
                                        }}
                                        options={[
                                            { label: t("global.invoice"), value: true },
                                            { label: t("global.cantinvoice"), value: false },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ display: !canPrepareBill ? "none" : "" }}
                                    name="hasCompany"
                                    label={t("campaign.create.step1.havecompany")}
                                    initialValue={paymentInfo?.hasCompany && hasCompany}
                                >
                                    <Select
                                        onChange={(selected) => {
                                            setHasCompany(selected);
                                        }}
                                        options={[
                                            { label: t("global.yes"), value: true },
                                            { label: t("global.no"), value: false },
                                        ]}
                                    />
                                </Form.Item>
                                <Form.Item
                                    style={{ display: !hasCompany ? "none" : "" }}
                                    name="companyType"
                                    label={t("campaign.create.step1.companytype")}
                                    initialValue={paymentInfo?.companyType}
                                >
                                    <Select
                                        options={[
                                            { label: t("global.company-type-anonim"), value: "anonim" },
                                            { label: t("global.company-type-limited"), value: "limited" },
                                            { label: t("global.company-type-sahis"), value: "sahis" },
                                        ]}
                                    ></Select>
                                </Form.Item>

                                <Form.Item
                                    label={t("global.company-name")}
                                    name={"companyName"}
                                    rules={[{ required: true, message: t("global.company-name-required") }]}
                                    style={{ display: !hasCompany ? "none" : "" }}
                                    initialValue={paymentInfo?.companyName}
                                >
                                    <Input placeholder={t("global.company-name-placeholder")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("global.bank-name")}
                                    name={"bankName"}
                                    rules={[{ required: true, message: t("global.bank-name-required") }]}
                                    initialValue={paymentInfo?.bankName}
                                >
                                    <Input placeholder={t("global.bank-name-placeholder")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("global.full-name")}
                                    name={"nameSurname"}
                                    rules={[{ required: true, message: t("global.full-name-required") }]}
                                    initialValue={paymentInfo?.nameSurname}
                                >
                                    <Input placeholder={t("global.full-name-placeholder")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("global.identity-number")}
                                    name={"identifier"}
                                    initialValue={paymentInfo?.identifier}
                                    rules={[
                                        { required: true, message: t("global.identity-number-required") },
                                        { min: 11, message: t("global.identity-number-min") },
                                        { max: 11, message: t("global.identity-number-max") },
                                        {
                                            validator: (rule, value) => {
                                                return new Promise((resolve, reject) => {
                                                    if (!checkTCKN(value)) reject(t("global.identity-number-invalid"));
                                                    else resolve("");
                                                });
                                            },
                                        },
                                    ]}
                                >
                                    <Input type="text" placeholder={t("global.identity-number-placeholder")} onChange={handleTCKN} />
                                </Form.Item>

                                <Form.Item
                                    name={"IBAN"}
                                    label={t("global.iban")}
                                    rules={[
                                        { required: true, message: t("global.iban-required") },
                                        { min: 16, message: t("global.iban-min-value") },
                                        { max: 34, message: t("global.iban-max-value") },
                                    ]}
                                    initialValue={paymentInfo?.IBAN}
                                >
                                    <Input placeholder={t("global.iban-placeholder")} />
                                </Form.Item>

                                <Form.Item
                                    label={t("global.address")}
                                    name={"address"}
                                    rules={[{ required: true, max: 250, message: t("global.address-required") }]}
                                    initialValue={paymentInfo?.address}
                                >
                                    <Input.TextArea rows={4} placeholder={t("global.address-placeholder")} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Space direction="horizontal" size={"middle"} style={{ width: "100%", justifyContent: "flex-end" }}>
                            <Button type="default" htmlType="submit" loading={isLoading}>
                                {" "}
                                {t("buttons.save")}{" "}
                            </Button>
                        </Space>
                    </Form>
                </PageContainer>
            </LayoutWrapper>
        </Authenticated>
    );
};

interface paymentInfo {
    canPrepareBill: boolean;
    hasCompany: boolean;
    companyType: string;
    companyName: string;
    nameSurname: string;
    identifier: string;
    bankName: string;
    IBAN: string;
    address: string;
}
