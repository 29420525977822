import { Card, Col, Row, Space, useTranslate } from "@pankod/refine";
import {
    MultiplePaymentApprovement,
    PaymentAccountInformationModal,
    PaymentApprovementModal,
    PaymentBalanceDetailModal,
    PaymentListModal,
    PaymentRefundOrBonusModal,
    PaymentResultModal,
} from "components";
import { PageTitle } from "components/PageTitle/PageTitle";
import { Filter, List } from "modules/payments";
import { FunctionComponent } from "react";

interface AdminPaymentListProps {}

export const AdminPaymentList: FunctionComponent<AdminPaymentListProps> = () => {
    const t = useTranslate();

    return (
        <>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card>
                        <PageTitle title={t("global.payments")} content={""} />
                        <br />
                        <Space direction="vertical" size={5} style={{ width: "100%" }}>
                            <Filter />
                            <div style={{ fontSize: 20, fontWeight: 600 }}>Fenomen Ödemeleri</div>
                            <hr style={{ border: "1px solid #F4EAFD", margin: "1px 0" }} />
                            <List />
                        </Space>
                    </Card>
                </Col>
            </Row>
            <PaymentBalanceDetailModal />
            <PaymentAccountInformationModal />
            <PaymentListModal />
            <PaymentApprovementModal />
            <PaymentRefundOrBonusModal />
            <MultiplePaymentApprovement />
            <PaymentResultModal />
        </>
    );
};
