import { useTranslate } from "@pankod/refine";
import { Select, Form, Col, Spin } from "antd";
import { FC } from "react";
import { useInfluencerInput } from "../useInfluencerInput";

import "./style.scss";

export const InfluencerInput: FC = () => {
    const [options, loading, onSearch, resetOptions] = useInfluencerInput();
    const t = useTranslate();

    return (
        <Col xs={24} sm={12} md={10} xl={8}>
            <Form.Item
                name="influencerIds"
                label={t("reports.amazon.filter.influencerInput.label", "Fenomen")}
                wrapperCol={{ span: 18 }}
                labelCol={{ span: 6 }}
            >
                <Select
                    style={{ width: "100%" }}
                    mode="multiple"
                    placeholder={t("reports.amazon.filter.influencerInput.placeholder", "Fenomen Seciniz")}
                    options={options}
                    onSearch={onSearch}
                    loading={loading}
                    allowClear
                    filterOption={false}
                    autoClearSearchValue={false}
                    defaultActiveFirstOption={false}
                    notFoundContent={
                        loading ? (
                            <div className="loading-container">
                                <Spin size="small" />
                            </div>
                        ) : null
                    }
                    onDropdownVisibleChange={resetOptions}
                />
            </Form.Item>
        </Col>
    );
};
