import { useTranslate } from "@pankod/refine";
import { Button, Form, Input, notification, Row, Typography } from "antd";
import { useState, useCallback, useEffect } from "react";
import { useAppDispatch } from "../../../hooks";
import { setActiveAuthTab, setNeedsShowMessage, toggleForgotPassword } from "pages/auth/authSlice";
import { Fenomio_Contract_App_Login_ForgotPasswordRequest, LoginService } from "services";
import { localizationHelper } from "helpers/globalizationHelper";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const { Title } = Typography;

export const ForgotPasswordPage = () => {
    const translate = useTranslate();
    const [form] = Form.useForm<Fenomio_Contract_App_Login_ForgotPasswordRequest>();
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const t = useTranslate();

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }
        const token = await executeRecaptcha("submit");
        console.log(token);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        handleReCaptchaVerify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const retrivePassword = async (values: any) => {
        setIsLoading(true);

        LoginService.forgotPassword({
            acceptLanguage: localizationHelper.getLocale(),
            requestBody: {
                email: values.email,
            },
        })
            .then((response: any) => {
                setIsLoading(false);
                if (response.status === "SUCCESS") {
                    dispatch(toggleForgotPassword(false));
                    dispatch(setNeedsShowMessage(response.message));
                }
            })
            .catch((error) => {
                setIsLoading(false);
                if (error.body) {
                    let validationError = error.body.data;

                    let message = "";
                    if (validationError.validationMessages.length > 0) {
                        message = validationError.validationMessages.join(", ");
                    } else {
                        message = validationError.message;
                    }

                    notification.open({
                        message: validationError.Message,
                        description: message,
                        duration: 2,
                    });
                }
            });
    };

    return (
        <Form<Fenomio_Contract_App_Login_ForgotPasswordRequest>
            layout="vertical"
            form={form}
            onFinish={(values) => {
                retrivePassword(values);
            }}
            requiredMark={false}
            initialValues={{
                remember: false,
            }}
            style={{ minHeight: "400px", display: "flex", justifyContent: "center", alignItems: "stretch", flexDirection: "column" }}
        >
            <Row justify="center">
                <Title level={3}>{t("pages.login.youforgotPassword")}</Title>
            </Row>
            <Row justify="center">
                <Title level={5}>{t("pages.login.emailAddress")}</Title>
            </Row>
            <Form.Item name="email" rules={[{ required: true }]}>
                <Input size="large" autoComplete="email" placeholder={translate("pages.login.email", "E Posta")} />
            </Form.Item>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyItems: "center" }}>
                <Button
                    type="default"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                    onClick={handleReCaptchaVerify}
                >
                    {translate("pages.auth.continue", "Devam Et")}
                </Button>
                <br />
                <br />
                <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
                    <span>{translate("pages.login.noAccount")}</span>
                    <Button
                        type="link"
                        style={{ paddingLeft: "5px", textDecoration: "underline" }}
                        onClick={() => {
                            dispatch(toggleForgotPassword(false));
                            dispatch(setActiveAuthTab("2"));
                        }}
                    >
                        <span style={{ textDecoration: "underline" }}>{translate("pages.login.signupnow")}</span>
                    </Button>
                </div>
            </div>
        </Form>
    );
};
