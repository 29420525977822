import { Col, Row, Space, Table, useTranslate } from "@pankod/refine";
import { Platforms, SocialMedias } from "Constanst";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { FunctionComponent, useMemo } from "react";
import { Link } from "react-router-dom";
import { BaseIdNameResponse } from "services/models/BaseIdNameResponse";
import { TransactionsSummaryOfCampaignResponse } from "services/models/TransactionsSummaryOfCampaignResponse";
import { TransactionsSummaryOfCampaignResponseItem } from "services/models/TransactionsSummaryOfCampaignResponseItem";
import { Money } from "services/models/money";
export interface PerformanceInfluencerListProps {
    balancePerInfluencer?: string | null;
    transactionsSummary: TransactionsSummaryOfCampaignResponse;
    transactionsSummaryPaginationChange: (pageNumber: number) => void;
    currency: string;
}

type SocialMediaConversion = {
    conversions: number;
    platform: Platforms;
    username: string;
};

export const PerformanceInfluencerList: FunctionComponent<PerformanceInfluencerListProps> = (props: PerformanceInfluencerListProps) => {
    const t = useTranslate();

    const getSocialMediaFlowerCount = (socialMediaConversions: SocialMediaConversion[], platform: number): number | null => {
        const selectedSocialMedia = socialMediaConversions.find((socialMediaConversion) => socialMediaConversion.platform === platform);
        return selectedSocialMedia?.conversions ?? null;
    };

    const isBrand = RoleHelper.isInRole(Roles.Brand);

    const columns = useMemo(
        () => [
            {
                title: "Id",
                dataIndex: "influencer",
                key: "influencer",
                render: (influencer: BaseIdNameResponse, row: any) => (influencer ? <p>#{influencer.id}</p> : null),
            },
            {
                title: t("global.namee"),
                dataIndex: "influencer",
                key: "influencer",
                render: (influencer: BaseIdNameResponse, row: TransactionsSummaryOfCampaignResponseItem, index: number) => (
                    <>
                        {influencer ? (
                            <Space direction="vertical" size="small">
                                {
                                    <Link
                                        className="clickable-span"
                                        style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold" }}
                                        to={`/influencers/detail/${influencer.id}`}
                                    >
                                        {" "}
                                        {influencer.name}
                                    </Link>
                                }
                                {row.influencerSocialMediaNames?.map((social: any) => (
                                    <a
                                        href={social.link}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ padding: 0, fontSize: "small", color: "#9254de" }}
                                    >
                                        <img
                                            width={16}
                                            height={16}
                                            alt={"social"}
                                            src={`/images/social-media-icon/${social.socialMedia.toUpperCase()}36Px.svg`}
                                        />{" "}
                                        {"@" + social.username}{" "}
                                    </a>
                                ))}
                            </Space>
                        ) : null}
                    </>
                ),
            },
            {
                title: <img src={`/images/social/${SocialMedias.instagram.icon}.svg`} alt="instagram icon" />,
                width: 100,
                dataIndex: "socialMediaConversions",
                render: (socialMediaConversions: any, row: any, index: number) => (
                    <span>{getSocialMediaFlowerCount(socialMediaConversions, Platforms.Instagram)}</span>
                ),
            },
            {
                title: <img src={`/images/social/${SocialMedias.twitter.icon}.svg`} alt="twitter icon" />,
                width: 100,
                dataIndex: "socialMediaConversions",
                render: (socialMediaConversions: any, row: any, index: number) => (
                    <span>{getSocialMediaFlowerCount(socialMediaConversions, Platforms.Twitter)}</span>
                ),
            },
            {
                title: <img src={`/images/social/${SocialMedias.youtube.icon}.svg`} alt="youtube icon" />,
                width: 100,
                dataIndex: "socialMediaConversions",
                render: (socialMediaConversions: any, row: any, index: number) => (
                    <span>{getSocialMediaFlowerCount(socialMediaConversions, Platforms.YouTube)}</span>
                ),
            },
            {
                title: <img src={`/images/social/${SocialMedias.twitch.icon}.svg`} alt="twitch icon" />,
                width: 100,
                dataIndex: "socialMediaConversions",
                render: (socialMediaConversions: any, row: any, index: number) => (
                    <span>{getSocialMediaFlowerCount(socialMediaConversions, Platforms.Twitch)}</span>
                ),
            },
            {
                title: <img src={`/images/social/${SocialMedias.telegram.icon}.svg`} alt="telegram icon" />,
                width: 100,
                dataIndex: "socialMediaConversions",
                render: (socialMediaConversions: any, row: any, index: number) => (
                    <span>{getSocialMediaFlowerCount(socialMediaConversions, Platforms.Telegram)}</span>
                ),
            },
            {
                title: <img src={`/images/social/${SocialMedias.discord.icon}.svg`} alt="discord icon" />,
                width: 100,
                dataIndex: "socialMediaConversions",
                render: (socialMediaConversions: any, row: any, index: number) => (
                    <span>{getSocialMediaFlowerCount(socialMediaConversions, Platforms.Discord)}</span>
                ),
            },
            {
                title: <img src={`/images/social/${SocialMedias.tiktok.icon}.svg`} alt="tiktok icon" />,
                width: 100,
                dataIndex: "socialMediaConversions",
                render: (socialMediaConversions: any, row: any, index: number) => (
                    <span>{getSocialMediaFlowerCount(socialMediaConversions, Platforms.TikTok)}</span>
                ),
            },
            {
                title: t("global.transformation"),
                dataIndex: "count",
                key: "count",
                render: (text: string, row: any, index: number) => <>{text}</>,
            },
            {
                title: isBrand ? t("global.amountToBePaid", "Ödenecek Tutar") : t("global.earning", "Kazanc"),
                dataIndex: "earningPrice",
                key: "earningPrice",
                render: (earningPrice: string) =>
                    isBrand ? (
                        <>{new Money(earningPrice).toStringWithCurrency(props.currency)}</>
                    ) : (
                        <>{`${new Money(earningPrice).toStringWithCurrency(props.currency)} / ${new Money(props.balancePerInfluencer).toStringWithCurrency(props.currency)}`}</>
                    ),
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <>
            <Row>
                <Col span={24}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Table
                            rowKey={(record) => record.campaignInfluencerId}
                            pagination={{
                                current: props.transactionsSummary?.pageInfo?.pageNumber || 1,
                                pageSize: props.transactionsSummary?.pageInfo?.pageSize || 1,
                                total: props.transactionsSummary?.pageInfo?.totalItemCount,
                            }}
                            columns={columns}
                            dataSource={props.transactionsSummary?.data}
                            onChange={(pagination) => props.transactionsSummaryPaginationChange(pagination.current as number)}
                            scroll={{ x: "100%" }}
                        />
                    </Space>
                </Col>
            </Row>
        </>
    );
};
