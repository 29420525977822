import { QuestionCircleOutlined, InboxOutlined, EditOutlined } from "@ant-design/icons";
import { useNavigation, useTranslate, Button, Popover, Space, Row, Tag, Tooltip, Table } from "@pankod/refine";
import { CampaignType, CampaignStatesColors, CampaignStates } from "Constanst";
import { GetCampaignTypeTextWithManagementType, GetCampaignStateText } from "helpers/StateHelpers";
import { FC, useMemo } from "react";
import { Link } from "react-router-dom";
import { Pagination } from "services/core/Pagination";
import { useTable } from "./useTable";
import { Money } from "services/models/money";

export const List: FC = () => {
    const [loading, campaigns, pagination, handleTableChange] = useTable();

    const { push } = useNavigation();
    const t = useTranslate();

    const columns = useMemo(
        () => [
            {
                title: <span className="table-font">Id</span>,
                dataIndex: "id",
                key: "id",
                render: (text: string, row: any, index: number) => text,
            },
            {
                title: <span className="table-font">{t("global.campaign-name")}</span>,
                dataIndex: "name",
                key: "name",
                render: (text: string, row: any, index: number) => (
                    <Link
                        to={{
                            pathname: "/campaign/detail/" + row.id,
                            state: row,
                        }}
                    >
                        <Button type="link">{text}</Button>
                    </Link>
                ),
            },
            {
                title: <span className="table-font">{t("global.campaignType")}</span>,
                dataIndex: "campaignType",
                key: "campaignType",
                render: (text: any, row: any) => <span>{GetCampaignTypeTextWithManagementType(row?.typeHash, row?.managementType)}</span>,
            },
            {
                title: <span className="table-font">{t("global.start")}</span>,
                dataIndex: "startDate",
                key: "startDate",
            },
            {
                title: <span className="table-font">{t("global.end")}</span>,
                dataIndex: "endDate",
                key: "endDate",
                render: (text: string, row: any) => {
                    if (row.typeHash === CampaignType.amazon) {
                        return " - ";
                    } else {
                        return text;
                    }
                },
            },
            {
                title: <span className="table-font">{t("campaign.create.step3.totalAmount")}</span>,
                dataIndex: "budget",
                key: "budget",
                render: (text: Number, row:any) => <span style={{whiteSpace:"nowrap"}}>{new Money(text).toStringWithCurrency(row.currency)}</span>,
            },
            {
                title: <span className="table-font">{t("global.unit-price")}</span>,
                dataIndex: "unitPrice",
                key: "unitPrice",
                render: (text: string, row: any, index: number) => {
                    if (row.typeHash !== CampaignType.selling) {
                        if (row.unitPrice !== "") {
                            return <span style={{whiteSpace:"nowrap"}}>{new Money(row.unitPrice).toStringWithCurrency(row.currency)}</span>;
                        }
                        return <span>-</span>;
                    }

                    return (
                        <Popover
                            placement="bottomRight"
                            content={
                                <Space direction="vertical" size="small">
                                    <p>{t("global.earnings-rates")}</p>
                                    <p>{t("global.earnings-rates")}</p>
                                </Space>
                            }
                        >
                            <Button type="link" onClick={() => {}}>
                                <span>
                                    {t("global.earnings-rates")} <QuestionCircleOutlined />
                                </span>
                            </Button>
                        </Popover>
                    );
                },
            },
            {
                title: <span className="table-font">{t("global.transformation")}</span>,
                dataIndex: "transformation",
                key: "transformation",
            },
            {
                title: <span className="table-font">{t("global.category")}</span>,
                dataIndex: "categories",
                key: "categories",
                render: (text: string[]) => text?.map((category: any) => <Row key={category?.id}>#{category?.name}</Row>),
            },
            {
                title: <span className="table-font">{t("global.status")}</span>,
                dataIndex: "campaignStateModel",
                key: "campaignStateModel",
                render: ({ campaignState }: any) => {
                    return <Tag color={CampaignStatesColors[campaignState].toString()}>{GetCampaignStateText(campaignState)}</Tag>;
                },
            },
            {
                title: "",
                key: "",
                render: () => (
                    <Button
                        type="link"
                        onClick={() => {
                            push("/influencers/list");
                        }}
                    >
                        <Tooltip placement="top" title={t("global.invite")}>
                            <InboxOutlined style={{ fontSize: "22px" }} />
                        </Tooltip>
                    </Button>
                ),
            },
            {
                title: "",
                key: "",
                render: (row: any) =>
                    row.campaignStateModel.campaignState === CampaignStates.Expired ||
                    row.campaignStateModel.campaignState === CampaignStates.BudgetCompleted ? (
                        <span></span>
                    ) : (
                        <Button
                            type="link"
                            onClick={() => {
                                push("/campaign/edit/" + row.id);
                            }}
                        >
                            <Tooltip placement="top" title={t("global.update")}>
                                <EditOutlined style={{ fontSize: "22px" }} />
                            </Tooltip>
                        </Button>
                    ),
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return (
        <Table
            columns={columns}
            pagination={pagination}
            dataSource={campaigns}
            loading={loading}
            onChange={(page) => handleTableChange(page as Pagination)}
            scroll={{ x: "100%" }}
        />
    );
};
