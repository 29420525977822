import { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class AuditLogService {
    public static searchAuditLogs({
        acceptLanguage,
        requestBody,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `AuditLog/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }
}
