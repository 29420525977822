import { LayoutWrapper, useTranslate } from "@pankod/refine";
import { CampaignType } from "Constanst";
import { FunctionComponent, useEffect, useState } from "react";
import { CreateActionCampaign, CreateClickCampaign, CreateDownloadCampaign, CreateSaleCampaign } from "./CampaignTypes";
import SelectCampaignType from "./SelectCampaignType";
import { CampaignsService } from "../../../services";
import { localizationHelper } from "helpers/globalizationHelper";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";

interface CreateCampaignProps {}

const CreateCampaign: FunctionComponent<CreateCampaignProps> = (props: any) => {
    const [campaignType, setCampaignType] = useState<CampaignType | null>(null);
    const [isEditPage, setIsEditPage] = useState(false);
    const [campaignDetail, setCampaignDetail] = useState({});
    const [campaignId, setCampaignId] = useState();
    const t = useTranslate();

    useEffect(() => {
        const campaignId = props.match.params.campaignId;

        if (campaignId) {
            setIsEditPage(true);
            setCampaignId(campaignId);
            CampaignsService.getCampaignDetail({ campaignId: campaignId, acceptLanguage: localizationHelper.getLocale() }).then((resp) => {
                if (resp.data.typeHash === 1) {
                    setCampaignType(CampaignType.clicking);
                } else if (resp.data.typeHash === 2) {
                    setCampaignType(CampaignType.download);
                } else if (resp.data.typeHash === 3) {
                    setCampaignType(CampaignType.action);
                } else {
                    setCampaignType(CampaignType.selling);
                }

                setCampaignDetail(resp.data);
            });
        }
    }, [props.match.params.campaignId]);

    return (
        <LayoutWrapper>
            <FenBreadCrumb
                items={[
                    { name: t("global.campaigns"), url: "/campaign/list" },
                    { name: t("global.create-campaign"), url: "" },
                ]}
            />
            {campaignType === null && !isEditPage && (
                <SelectCampaignType
                    campaignTypeSelected={(selectedType: CampaignType) => {
                        setCampaignType(selectedType);
                    }}
                />
            )}
            {campaignType !== null && campaignType === CampaignType.clicking && (
                <CreateClickCampaign
                    campaignId={campaignId}
                    isEditPage={isEditPage}
                    campaignDetail={campaignDetail}
                    back={() => setCampaignType(null)}
                />
            )}
            {campaignType !== null && campaignType === CampaignType.download && (
                <CreateDownloadCampaign
                    campaignId={campaignId}
                    isEditPage={isEditPage}
                    campaignDetail={campaignDetail}
                    back={() => setCampaignType(null)}
                />
            )}
            {campaignType !== null && campaignType === CampaignType.selling && (
                <CreateSaleCampaign
                    campaignId={campaignId}
                    isEditPage={isEditPage}
                    campaignDetail={campaignDetail}
                    back={() => setCampaignType(null)}
                />
            )}
            {campaignType !== null && campaignType === CampaignType.action && (
                <CreateActionCampaign
                    campaignId={campaignId}
                    isEditPage={isEditPage}
                    campaignDetail={campaignDetail}
                    back={() => setCampaignType(null)}
                />
            )}
        </LayoutWrapper>
    );
};

export default CreateCampaign;
