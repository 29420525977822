// @flow
import * as React from "react";

import styles from "./confidental-agreement.module.scss";

type Props = {
    lang: string;
};

const ConfidentalAgreement: React.FC<Props> = (props) => {
    return (
        <div className={styles.container}>
            {props.lang === "tr" ? (
                <div className="confidental-agreement">
                    <h1>Gizlilik Sözleşmesi</h1>

                    <p>
                        Sitemiz üzerinden verilen tüm hizmetler Fenomio İnternet Reklamcılık Hizmetleri Ticaret Anonim Şirketi ünvanlı
                        firmamıza aittir ve firmamız tarafından işletilir.
                    </p>
                    <p>
                        Firmamız, çeşitli amaçlarla kişisel veriler toplayabilir. Aşağıda, toplanan kişisel verilerin nasıl ve ne şekilde
                        toplandığı, bu verilerin nasıl ve ne şekilde korunduğu belirtilmiştir.
                    </p>
                    <p>
                        Üyelik veya sitemiz üzerindeki çeşitli form ve anketlerin doldurulması suretiyle üyelerin kendileriyle ilgili bir
                        takım kişisel bilgileri (isim-soy isim, firma bilgileri, telefon, adres veya e-posta adresleri gibi) sitemiz
                        tarafından işin doğası gereği toplanmaktadır. Sitemizde üye olan Yayıncı’lara ve onların takipçilerine dair veriler
                        Reklam Veren’e sunulmaktadır.
                    </p>
                    <p>
                        Firmamız bazı dönemlerde müşterilerine ve üyelerine kampanya bilgileri, yeni ürünler hakkında bilgiler, promosyon
                        teklifleri gönderebilir. Üyelerimiz bu gibi bilgileri alıp almama konusunda her türlü seçimi üye girişi yaptıktan
                        sonra hesap bilgileri bölümünden değiştirilebilir ya da kendisine gelen bilgilendirme iletisindeki linkle bildirim
                        yapabilir.
                    </p>
                    <p>
                        Sitemiz üzerinden veya e-posta ile gerçekleştirilen onay sürecinde, üyelerimiz tarafından sitemıza elektronik
                        ortamdan iletilen kişisel bilgiler, Üyelerimiz ile yaptığımız "Kullanıcı Sözleşmesi" ile belirlenen amaçlar ve
                        kapsam dışında üçüncü kişilere açıklanmayacaktır.
                    </p>
                    <p>
                        Sistemle ilgili sorunların tanımlanması ve verilen hizmet ile ilgili çıkabilecek sorunların veya uyuşmazlıkların
                        hızla çözülmesi için, Firmamız, üyelerinin IP adresini kaydetmekte ve bunu kullanmaktadır. IP adresleri,
                        kullanıcıları genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak amacıyla da kullanılabilir.
                    </p>
                    <p>
                        Firmamız, Üyelik Sözleşmesi ile belirlenen amaçlar ve kapsam dışında da, talep edilen bilgileri kendisi veya
                        işbirliği içinde olduğu kişiler tarafından doğrudan pazarlama yapmak amacıyla kullanabilir. Kişisel bilgiler,
                        gerektiğinde kullanıcıyla temas kurmak için de kullanılabilir. Firmamız tarafından talep edilen bilgiler veya
                        kullanıcı tarafından sağlanan bilgiler veya sitemiz üzerinden yapılan işlemlerle ilgili bilgiler; Firmamız ve
                        işbirliği içinde olduğu kişiler tarafından, "Hizmet Sözleşmesi" ile belirlenen amaçlar ve kapsam dışında da,
                        üyelerimizin kimliği ifşa edilmeden çeşitli istatistiksel değerlendirmeler, veri tabanı oluşturma ve pazar
                        araştırmalarında kullanılabilir.
                    </p>
                    <p>
                        Firmamız, gizli bilgileri kesinlikle özel ve gizli tutmayı, bunu bir sır saklama yükümü olarak addetmeyi ve
                        gizliliğin sağlanması ve sürdürülmesi, gizli bilginin tamamının veya herhangi bir kısmının kamu alanına girmesini
                        veya yetkisiz kullanımını veya üçüncü bir kişiye ifşasını önlemek için gerekli tüm tedbirleri almayı ve gerekli
                        özeni göstermeyi taahhüt etmektedir.
                    </p>

                    <h3> KREDİ KARTI GÜVENLİĞİ</h3>

                    <p>
                        Firmamız, sitemizde kredi kartı kullanan kart sahiplerinin güvenliğini ilk planda tutmaktadır. Kredi kartı bilgileri
                        hiçbir şekilde sistemimizde saklanmamaktadır.
                    </p>
                    <p>
                        İşlemler sürecine girdiğinizde güvenli bir sitede olduğunuzu anlamak için dikkat etmeniz gereken iki şey vardır.
                        Bunlardan biri tarayıcınızın en alt satırında bulunan bir anahtar ya da kilit simgesidir. Bu güvenli bir internet
                        sayfasında olduğunuzu gösterir ve her türlü bilgileriniz şifrelenerek korunur. Bu bilgiler, ancak satış işlemleri
                        sürecine bağlı olarak ve verdiğiniz talimat istikametinde kullanılır. Kartla ilgili hiçbir bilgi tarafımızdan
                        görüntülenemediğinden ve kaydedilmediğinden, üçüncü şahısların herhangi bir koşulda bu bilgileri ele geçirmesi
                        engellenmiş olur.
                    </p>
                    <p>
                        Üye olurken verdiğiniz tüm bilgilere sadece siz ulaşabilir ve siz değiştirebilirsiniz. Üye giriş bilgilerinizi
                        güvenli koruduğunuz takdirde başkalarının sizinle ilgili bilgilere ulaşması ve bunları değiştirmesi mümkün değildir.
                    </p>

                    <h3> MAİL ORDER KREDİ KART BİLGİLERİ GÜVENLİĞİ</h3>

                    <p>
                        Kredi kartı mail-order yöntemi ile bize göndereceğiniz kimlik ve kredi kart bilgileriniz firmamız tarafından
                        gizlilik prensibine göre saklanacaktır. Bu bilgiler olası banka ile oluşabilecek kredi kartından para çekim
                        itirazlarına karşı 60 gün süre ile bekletilip daha sonrasında imha edilmektedir. Sipariş ettiğiniz ürünlerin bedeli
                        karşılığında bize göndereceğiniz tarafınızdan onaylı mail-order formu bedeli dışında herhangi bir bedelin
                        kartınızdan çekilmesi halinde doğal olarak bankaya itiraz edebilir ve bu tutarın ödenmesini engelleyebileceğiniz
                        için bir risk oluşturmamaktadır.
                    </p>

                    <h3>ÜÇÜNCÜ TARAF WEB SİTELERİ VE UYGULAMALAR</h3>

                    <p>
                        Sitemiz, web sitesi dâhilinde başka sitelere link verebilir. Firmamız, bu linkler vasıtasıyla erişilen sitelerin
                        gizlilik uygulamaları ve içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır. İş bu sözleşmedeki Gizlilik
                        Politikası Prensipleri, sadece sitemiz kullanımına ilişkindir, üçüncü taraf web sitelerini kapsamaz.
                    </p>

                    <h3> İSTİSNAİ HALLER</h3>

                    <p>
                        Aşağıda belirtilen sınırlı hallerde Firmamız, işbu "Gizlilik Politikası" hükümleri dışında kullanıcılara ait
                        bilgileri üçüncü kişilere açıklayabilir. Bu durumlar sınırlı sayıda olmak üzere;
                    </p>
                    <p>
                        1.Kanun, Kanun Hükmünde Kararname, Yönetmelik v.b. yetkili hukuki otorite tarafından çıkarılan ve yürürlükte olan
                        hukuk kurallarının getirdiği zorunluluklara uymak;
                    </p>
                    <p>
                        2.Sitemizin kullanıcılarla akdettiği "Hizmet Sözleşmesi"'nin ve diğer sözleşmelerin gereklerini yerine getirmek ve
                        bunları uygulamaya koymak amacıyla;
                    </p>
                    <p>
                        3.Yetkili idari ve adli otorite tarafından usulüne göre yürütülen bir araştırma veya soruşturmanın yürütümü amacıyla
                        kullanıcılarla ilgili bilgi talep edilmesi;
                    </p>
                    <p>4.Kullanıcıların hakları veya güvenliklerini korumak için bilgi vermenin gerekli olduğu hallerdir.</p>

                    <h3>E-POSTA GÜVENLİĞİ</h3>

                    <p>
                        Firmamız Müşteri Hizmetleri’ne, herhangi bir konuyla ilgili olarak göndereceğiniz e-postalarda, asla kredi kartı
                        numaranızı veya şifrelerinizi yazmayınız. E-postalarda yer alan bilgiler üçüncü şahıslar tarafından görülebilir.
                        Firmamız e-postalarınızdan aktarılan bilgilerin güvenliğini hiçbir koşulda garanti edemez.
                    </p>

                    <h3>TARAYICI ÇEREZLERİ</h3>

                    <p>
                        Firmamız, sitemizi ziyaret eden kullanıcılar ve kullanıcıların web sitesini kullanımı hakkındaki bilgileri teknik
                        bir iletişim dosyası (Çerez-Cookie) kullanarak elde edebilir. Bahsi geçen teknik iletişim dosyaları, ana bellekte
                        saklanmak üzere bir internet sitesinin kullanıcının tarayıcısına (browser) gönderdiği küçük metin dosyalarıdır.
                        Teknik iletişim dosyası site hakkında durum ve tercihleri saklayarak İnternet'in kullanımını kolaylaştırır.
                    </p>
                    <p>
                        Teknik iletişim dosyası, siteyi kaç kişinin ziyaret ettiğini, bir kişinin siteyi hangi amaçla, kaç kez ziyaret
                        ettiğini ve ne kadar sitede kaldıkları hakkında istatistiksel bilgileri elde etmeye ve kullanıcılar için özel
                        tasarlanmış kullanıcı sayfalarından dinamik olarak reklam ve içerik üretilmesine yardımcı olur. Teknik iletişim
                        dosyası, ana bellekte veya e-postanızdan veri veya başkaca herhangi bir kişisel bilgi almak için tasarlanmamıştır.
                        Tarayıcıların pek çoğu başta teknik iletişim dosyasını kabul eder biçimde tasarlanmıştır ancak kullanıcılar dilerse
                        teknik iletişim dosyasının gelmemesi veya teknik iletişim dosyasının gönderildiğinde uyarı verilmesini sağlayacak
                        biçimde ayarları değiştirebilirler.
                    </p>
                    <p>
                        Firmamız Google Analytics, Mixpanel, Search Console, Tableau gibi sitemiz ziyaretçileri hakkında veri toplayan
                        yazılımları kullanmaktadır.
                    </p>
                    <p>
                        Firmamız, işbu "Gizlilik Politikası" hükümlerini dilediği zaman sitede yayınlamak suretiyle değiştirebilir. Gizlilik
                        Politikası hükümleri değiştiği takdirde, yayınlandığı tarihte yürürlük kazanır.
                    </p>
                </div>
            ) : (
                <div>
                    <h1>Nondisclosure Agreement</h1>

                    <p>
                        All services provided on our website belong to Fenomio Internet Advertisement Services Reklamcılık Trading Joint
                        Stock Company and it's managed by our company.
                    </p>
                    <p>
                        Our firm can collect personal data for various reasons. Below, it's indicated how these collected personal data are
                        collected how these data are protected.
                    </p>
                    <p>
                        As a matter of course, some of personal information of members about themselves (name-surname, firm information,
                        telephone, e-mail etc...) are collected by us while signing up or filling various forms and questionnaire on our
                        site. Data that belong to Publishers who join our site and their followers are shared with Advertiser.
                    </p>
                    <p>
                        Our firm may send its customers and members campaign information, information about new products, promotion offers
                        at certain times. Our members can change settings whether to receive such information or not on account information
                        part when they login or they can notify us by a link in a notification message.
                    </p>
                    <p>
                        In the approval process on our site or e-mail, personal information sent to our side by our members via electronic
                        media won't be disclosed to third parties outside the goals and scope defined in "User Agreement" we make with our
                        members.
                    </p>
                    <p>
                        Our firm saves and uses the IP number of our members to describe the system related problems and solve the problems
                        about the provided service or any issues that may stem from it quickly. IP adresses may also be used to describe
                        users in general and collect extensive demographic information.
                    </p>
                    <p>
                        Our firm may use the requested information for marketing purposes outside goals and scope defined in Membership
                        Agreement either by itself or others that it's in collaboration with. Personal information may also be used for
                        contact. Information requested by our firm or information provided by a user or information related to the actions
                        carried out on our site; They can be used by our firm or people that our firm is in collaboration with outside the
                        goals and scope defined in "Service Agreement", without exposing the identity of our members for various statistical
                        evaluations, to create a database and market search.
                    </p>
                    <p>
                        Our firm guarantee keeping secret information as certainly private and secret, assuming this as a secrecy obligation
                        and making sure of privacy and sustaining it, taking every necessary precaution and showing attention to prevent the
                        whole or part of secret information to enter public domain or unauthorized use or exposure to a third party and to
                        show the necessary attention.
                    </p>

                    <h3> CREDIT CARD SAFETY</h3>

                    <p>
                        It's our firm's first priority to protect the security of members who use a credit card. Credit card information is
                        not saved in our system in no circumstances.
                    </p>
                    <p>
                        There are things you need to consider to realize you are on a secure page when you enter actions process. The first
                        of these is a key or lock symbol found at the last line of your browser. This indicates you are on a safe internet
                        page and every type of information is password protected. This information is used only in the direction of your
                        instructions depending on sale activity processes. As no inforation can be displayed or saved about the card, no
                        third person is allowed to reach this information under any conditions.
                    </p>
                    <p>
                        Only you can reach and change the information you provided while signing up. As long as you protect your login
                        information well, it's impossible for others to reach or change this information.
                    </p>

                    <h3> MAIL ORDER CREDIT CARD INFORMATION SECURITY</h3>

                    <p>
                        ID and credit card information you will send by credit card mail-order method will be saved by our firm in
                        accordance with our privacy policy. This information is kept for 60 days and destroyed later in case of money
                        withdrawal objections to a bank. In case another amount is charged to your credit card other than the mail-order
                        form approved by you, which is the cost of a products you ordered, you can object to the bank naturally and there is
                        no risk involved as there is not risk as you can prevent this amount from being paid.
                    </p>

                    <h3>THIRD PARTY WEBSITES AND APPLICATIONS</h3>

                    <p>
                        Our website can include links to other sites in its website. Our firm doesn't bear any responsibility regarding
                        privacy practices and content reached by such links. Privacy Policy Principals in this contract are concerned solely
                        with the use of our website, it doesn't include third party websites.
                    </p>

                    <h3> EXCEPTIONAL CASES</h3>

                    <p>
                        In limited cases below, our firms may disclose information about users to third parties except for provisions stated
                        in this Privacy Policy Principials. These cases are limited;
                    </p>
                    <p>
                        1.To obey the obligations brought by the rule of law enacted and in use by the authorized law authority such as Law,
                        Decree Law, Regulations etc...;
                    </p>
                    <p>
                        2.In order to fulfill and put to practice the "Service Agreement" and other contracts that our website made to
                        users;
                    </p>
                    <p>
                        3.Requesting information of the users by the authorized administrative and judiciary authority in an aim to conduct
                        an investigation or hearing in due form;
                    </p>
                    <p>4.These are the cases when providing information to protect the rights and security of users.</p>

                    <h3>E-MAIL SECURITY</h3>

                    <p>
                        Never include your credit card password in e-mails you will send to Our Firm's Customer Service about any issue.
                        Information in e-mails may be seen by third parties. Our firm can never guarantee the security of information sent
                        by your e-mails.
                    </p>

                    <h3>BROWSER COOKIES</h3>

                    <p>
                        Our firm can acquire the information about the users and the usage of our website by a technical communication file
                        (Cookie). The mentioned technical communication files are small text files that a webpage sends the user's browser
                        to save it in main storage. Technical communication files makes it easy to use Internet by saving the status and
                        preferences about the site.
                    </p>
                    <p>
                        Technical communication file helps acquiring statistical information regarding how many users visit the site, for
                        which purpose and how many times a user visit and stay on that page and produce advertisement and content
                        dynamically that are produced specially for users on user pages. Technical communication file is not designed to
                        receive any information from main storage or your e-mail. Most browsers are designed in a way to accept technical
                        communication file first. However, users can change the settings in a way to prevent technical communication file
                        from receiving or receiving notification when technicial communication file is received when it's sent.
                    </p>
                    <p>
                        Our firm uses softwares like Google Analytics, Mixpanel, Search Console, Tableau that collect data about our
                        visitors.
                    </p>
                    <p>
                        Our firm may change this "Privacy Policy" when it sees fit on condition that it shares it on the website. If the
                        articles of Privacy Policy are changed, they are regarded as in effect on the day it's published.
                    </p>
                </div>
            )}
        </div>
    );
};

export default ConfidentalAgreement;
