import { CampaignType } from "Constanst";
import { FunctionComponent } from "react";
import { ActionCampaignDetail, ClickCampaignDetil, DownloadCampaignDetail, SellingCampaignDetail, AmazonCampaignDetail } from "../Brand/CampaignTypes";

interface BrandCampaignDetailProps {
    brands: any[];
    categories: any[];
    countries: any[];
    campaignDetail: any;
    campaignType: CampaignType;
    refresh: any;
    loading: any;
}

export const BrandCampaignDetail: FunctionComponent<BrandCampaignDetailProps> = (props: BrandCampaignDetailProps) => {
    return (
        <>
            {!props.loading && props.campaignType === CampaignType.action && (
                <ActionCampaignDetail
                    refresh={props.refresh}
                    categories={props.categories}
                    campaignDetail={props.campaignDetail}
                    countries={props.countries}
                    brands={props.brands}
                />
            )}
            {!props.loading && props.campaignType === CampaignType.clicking && (
                <ClickCampaignDetil
                    refresh={props.refresh}
                    categories={props.categories}
                    campaignDetail={props.campaignDetail}
                    countries={props.countries}
                    brands={props.brands}
                />
            )}
            {!props.loading && props.campaignType === CampaignType.download && (
                <DownloadCampaignDetail
                    refresh={props.refresh}
                    categories={props.categories}
                    campaignDetail={props.campaignDetail}
                    countries={props.countries}
                    brands={props.brands}
                />
            )}
            {!props.loading && props.campaignType === CampaignType.selling && (
                <SellingCampaignDetail
                    refresh={props.refresh}
                    categories={props.categories}
                    campaignDetail={props.campaignDetail}
                    countries={props.countries}
                    brands={props.brands}
                />
            )}
            {!props.loading && props.campaignType === CampaignType.amazon && (
                <AmazonCampaignDetail
                    refresh={props.refresh}
                    categories={props.categories}
                    campaignDetail={props.campaignDetail}
                    countries={props.countries}
                    brands={props.brands}
                />
            )}
        </>
    );
};
