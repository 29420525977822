import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Col, Row, useNavigation, useTranslate } from "@pankod/refine";

import { FunctionComponent } from "react";

interface AdminInfluencerSocialMediasProps {
    influencer: any;
}

export const AdminInfluencerSocialMedias: FunctionComponent<AdminInfluencerSocialMediasProps> = (
    props: AdminInfluencerSocialMediasProps
) => {
    const { push } = useNavigation();
    const t = useTranslate();
    const calculateFollowersString = (count: any) => {
        return (count % 1000 > 0 ? (count - (count % 1000)) / 1000 : count / 1000) + "K";
    };
    const influencer = props.influencer?.socialMedias;
    if (influencer) {
        influencer["Id"] = props.influencer?.id;
    }

    return (
        <>
            <h2 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("global.platforms")}</h2>
            <Row gutter={[10, 8]}>
                {props.influencer?.socialMedias.map((item: any) => (
                    <Col span={8} style={{ textAlign: "center" }}>
                        <Row
                            style={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                flexDirection: "row",
                                flexWrap: "nowrap",
                            }}
                        >
                            <img alt={"social"} src={`/images/social-media-icon/${item.socialMedia.toUpperCase()}36Px.svg`} />
                            <Col style={{ fontSize: "0.85em" }}>
                                <a href={item.link} target="_blank" rel="noreferrer" style={{ color: "#9254de" }}>
                                    {"  "}@{item.username}
                                </a>
                                <p>
                                    {item.isApproved
                                        ? calculateFollowersString(item.followers)
                                        : calculateFollowersString(item.pendingApprovalFollowers)}{" "}
                                    {!item.isApproved ? <ClockCircleOutlined /> : null}{" "}
                                </p>
                            </Col>
                        </Row>
                    </Col>
                ))}
            </Row>
            <Row style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                <Button type="ghost" onClick={() => push(`/editPlatform/${props.influencer.id}`, influencer)}>
                    {t("global.edit-platforms")}
                </Button>
            </Row>
        </>
    );
};
