export interface SocialMedia {
    id: string;
    socialMedia: string;
    username: string;
    followers: number;
    link: string;
    pendingApprovalFollowers: number;
    isApproved: boolean;
    remove: boolean;
    updateOn: Date;
}

export enum HistoryState {
    added = 1,
    removed = 2,
    updated = 3,
    requested = 4,
    approved = 5,
    rejected = 6,
}

export interface SocialMediaHistory {
    id: string;
    socialMedia: string;
    username: string;
    followers: number;
    link: string;
    state: HistoryState;
    updatedOn: Date;
    createdOn: Date; // history kaydının oluşturulma tarih/saati. Yani sosyal medyasının değil, kaydın oluşturulma tarih/saati
}
