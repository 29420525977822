import { localizationHelper } from "helpers/globalizationHelper";
import { useEffect, useState } from "react";
import { BrandsService, LookupsService } from "services";
import { Country } from "services/models/Lookups";

export const useEditForm = (updateForm: () => void) => {
    const [sectors, setSectors] = useState<any[]>([]);
    const [countries, setCountries] = useState<Country[]>([]);

    const editBrand = (branchId: string, values: any) => {
        BrandsService.updateBrand({
            id: branchId,
            acceptLanguage: localizationHelper.getLocale(),
            requestBody: {
                name: values.name,
                detail: values.detail,
                workingDetail: values.workingDetail,
                sectorId: values.sector,
                delegateName: values.delegateName,
                phone: values.phone,
                countryCode: values.countryCode,
                hasMarketingPermission: values.hasMarketingPermission,
            },
        }).then((resp) => {
            updateForm();
        });
    };

    useEffect(() => {
        LookupsService.getSectors({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setSectors(res.data ?? []);
        });

        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [sectors, countries, editBrand] as const;
};
