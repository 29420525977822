import { Icons, LayoutWrapper, useTranslate } from "@pankod/refine";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { localizationHelper } from "helpers/globalizationHelper";
import { RoleHelper, Roles } from "helpers/roleHelper";
import UserHelper from "helpers/userHelper";
import { FunctionComponent, useEffect, useState } from "react";
import { Fenomio_Contract_App_Login_ChangePasswordRequest, LoginService } from "services";
import { ProfileModel } from "./models";

interface ChangePasswordProps {}

export const ChangePasswordPage: FunctionComponent<ChangePasswordProps> = (props: ChangePasswordProps) => {
    const translate = useTranslate();
    const [isLoading, setLoading] = useState(false);
    const [name, setName] = useState();
    const [role, setRole] = useState<any>();
    const [profileData, setProfileData] = useState<ProfileModel>();
    const t = useTranslate();

    const getProfileInfo = () => {
        UserHelper.getProfile().then((resp) => {
            setProfileData(resp as ProfileModel);
        });
    };

    useEffect(() => {
        setName(UserHelper.getName());
        setName(UserHelper.getName());
        setRole(UserHelper.getRole());
        getProfileInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [passwordForm] = Form.useForm<Fenomio_Contract_App_Login_ChangePasswordRequest>();

    const { EyeInvisibleOutlined, EyeTwoTone } = Icons;

    function changePassword() {
        passwordForm.validateFields().then((values) => {
            if (values) {
                setLoading(true);
                LoginService.changePassword({
                    id: UserHelper.getId(),
                    acceptLanguage: localizationHelper.getLocale(),
                    requestBody: {
                        oldPassword: values.oldPassword,
                        newPassword: values.newPassword,
                        newPasswordConfirm: values.newPasswordConfirm,
                    },
                })
                    .then(function (response) {
                        setLoading(false);
                        if (response.status === "SUCCESS") {
                            Modal.success({
                                title: t("notifications.success"),
                                content: t("notifications.password-change-success"),
                            });
                            passwordForm.resetFields();
                        }
                    })
                    .catch((error) => {
                        setLoading(false);
                        if (error.body) {
                            let validationError = error.body;

                            let message = "";
                            if (validationError.validationMessages?.length > 0) {
                                message = validationError.validationMessages.join(", ");
                            } else {
                                message = validationError.Message;
                            }

                            Modal.error({
                                title: validationError.Message,
                                content: message,
                            });
                        }
                    });
            }
        });
    }
    console.log(profileData);
    return (
        <LayoutWrapper>
            <style>
                {`
                .Body/regular {
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                }

                .ant-form-horizontal .ant-form-item-label {
                    width: 150px;
                }

                .ant-form-item-label {
                    text-align: left;
                }
                .ant-form-item-label > label {
                    color: #767474;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 22px;
                    letter-spacing: 0em;
                    text-align: left;
                }

                #changePasswordCard {
                    width: 783px;
                }

                @media (max-width: 783px) {
                    #changePasswordCard {
                        width: 100%;
                    }
                  }
            `}
            </style>
            <Col xs={24} sm={24} md={14} span={14}>
                <FenBreadCrumb
                    items={[
                        { name: t("global.profile"), url: "/profile/detail" },
                        { name: t("global.change-password"), url: "" },
                    ]}
                />
                <PageContainer
                    pageTitle={t("global.change-password")}
                    helperContent={t("global.influencer.profilepage.helper-content.change-password-info")}
                >
                    <Row>
                        <Row style={{ padding: "10px 0px", width: "100%" }} align="bottom">
                            {RoleHelper.isInRole(Roles.Brand) || RoleHelper.isInRole(Roles.Influencer) ? (
                                <Col xs={24} sm={24} md={7} span={7}>
                                    <img
                                        src={profileData?.logoPath ? profileData?.logoPath : "/images/profile/profile_influencer.png"}
                                        alt="img"
                                        width={150}
                                        style={{ borderRadius: "10px", boxShadow: "1px 1px 1px 1px" }}
                                    />
                                </Col>
                            ) : null}
                            {RoleHelper.isInRole(Roles.Brand) ? (
                                <Col xs={24} sm={24} md={10} span={10}>
                                    <h2>{profileData?.name}</h2>
                                    <p>{profileData?.sector}</p>
                                    <p>{profileData?.createdOn}</p>
                                </Col>
                            ) : null}
                            {RoleHelper.isInRole(Roles.Root) || RoleHelper.isInRole(Roles.Admin) ? (
                                <Col xs={24} sm={24} md={10} span={10}>
                                    <h2>{name}</h2>
                                    <p>{role}</p>
                                </Col>
                            ) : null}
                        </Row>
                        <hr style={{ borderTop: "1px solid #952AE7", width: "100%", marginBottom: "10px" }} />
                        <Row style={{ width: "100%" }}>
                            <Col>
                                <Form<Fenomio_Contract_App_Login_ChangePasswordRequest>
                                    layout="vertical"
                                    form={passwordForm}
                                    requiredMark={false}
                                    scrollToFirstError
                                >
                                    <Form.Item
                                        name="oldPassword"
                                        label={translate("pages.register.oldPassword", "Geçerli Parola:")}
                                        rules={[{ required: true }]}
                                    >
                                        <Input.Password
                                            type="oldPassword"
                                            size="large"
                                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="newPassword"
                                        label={translate("pages.register.password", "Yeni Parola:")}
                                        rules={[{ required: true }]}
                                    >
                                        <Input.Password
                                            type="password"
                                            size="large"
                                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="newPasswordConfirm"
                                        label={translate("pages.register.passwordConfirm", "Yeni Parola Tekrar:")}
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please confirm your password!",
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("newPassword") === value) {
                                                        return Promise.resolve();
                                                    }
                                                    return Promise.reject(new Error(t("global")));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            type="password"
                                            size="large"
                                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    </Row>
                    <Row justify="end">
                        <Col>
                            <Button type="default" onClick={() => changePassword()} loading={isLoading}>
                                {translate("buttons.save")}
                            </Button>
                        </Col>
                    </Row>
                </PageContainer>
            </Col>
        </LayoutWrapper>
    );
};
