import { Icons, useTranslate } from "@pankod/refine";
import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import { useEffect, useState, useCallback, useMemo } from "react";
import PasswordChecklist from "react-password-checklist";
import { useAppDispatch } from "../../../hooks";
import { setNeedsShowMessage } from "pages/auth/authSlice";
import {
    BrandsService,
    Fenomio_Common_BaseModels_Api_DetailItemDto,
    Fenomio_Contract_App_Brand_SignUpBrandRequest,
    LookupsService,
} from "services";
import { localizationHelper } from "helpers/globalizationHelper";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { toast } from "react-toastify";
import allCountries from "../../../helpers/Countries";
import UserAgreement from "components/UserAgreement/UserAgreement";
import { AgreementResponse } from "services/models/AgreementResponse";
import { latestAgreementsAtom } from "providers/atoms";
import { useAtom } from "jotai";

export const CompanyRegisterPage = () => {
    const translate = useTranslate();
    const [form] = Form.useForm<Fenomio_Contract_App_Brand_SignUpBrandRequest>();
    const { Option } = Select;
    const { EyeInvisibleOutlined, EyeTwoTone } = Icons;

    const [isLoading, setLoading] = useState(false);
    const [password, setPassword] = useState("");
    const [agreements] = useAtom(latestAgreementsAtom);
    const [passwordAgain, setPasswordAgain] = useState("");
    const [agreementVisible, setagreementVisible] = useState(false);
    const [activeAgreement, setActiveAgreement] = useState<AgreementResponse | undefined>(undefined);
    const [selectedPhoneCode, setSelectedPhoneCode] = useState("90");
    const [countries, setCountries] = useState<Fenomio_Common_BaseModels_Api_DetailItemDto[]>([]);
    const defaultSelectedCountry = 250;

    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.log("Execute recaptcha not yet available");
            return;
        }
        await executeRecaptcha("submit");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const dispatch = useAppDispatch();
    const PrefixSelector = (
        <Select
            value={selectedPhoneCode}
            onChange={(e: any) => {
                setSelectedPhoneCode(e);
            }}
            style={{
                width: 72,
            }}
        >
            {allCountries.map((country) => {
                return <Option value={country.dialCode}>+{country.dialCode}</Option>;
            })}
        </Select>
    );

    useEffect(() => {
        getCountries();
        handleReCaptchaVerify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCountries = async () => {
        setLoading(true);
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
            setLoading(false);
        });
    };

    function registerCompany(values: any) {
        setLoading(true);
        BrandsService.signup({
            acceptLanguage: localizationHelper.getLocale(),
            requestBody: {
                name: values.name,
                delegateName: values.delegateName,
                email: values.email,
                phone: (selectedPhoneCode ? selectedPhoneCode : "90") + values.phone,
                countryCode: values.countryCode,
                password: values.password,
                agreements: [
                    {
                        id: service?.id,
                        value: values[service?.id || "service"] ? values[service?.id || "service"] : false,
                    },
                    {
                        id: kvkk?.id,
                        value: values[kvkk?.id || "kvkk"] ? values[kvkk?.id || "kvkk"] : false,
                    },
                    {
                        id: clarification?.id,
                        value: values[clarification?.id || "clarification"] ? values[clarification?.id || "clarification"] : false,
                    },
                    {
                        id: ipaz?.id,
                        value: values[ipaz?.id || "ipaz"] ? values[ipaz?.id || "ipaz"] : false,
                    },
                ],
            },
        })
            .then(function (response) {
                setLoading(false);
                if (response.status === "SUCCESS") {
                    dispatch(setNeedsShowMessage(translate("pages.register.successMessage")));
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err.body.message);
            });
    }

    const KvkkPolicyText = () => {
        return (
            <span
                style={{ color: "#952ae7" }}
                onClick={() => {
                    setActiveAgreement(kvkk);
                    setagreementVisible(true);
                }}
            >
                {translate("pages.register.kvkkPolicyLink")}
            </span>
        );
    };

    const KvkkClarificationText = () => {
        return (
            <span
                style={{ color: "#952ae7" }}
                onClick={() => {
                    setActiveAgreement(clarification);
                    setagreementVisible(true);
                }}
            >
                {translate("pages.register.clarificationLink")}
            </span>
        );
    };

    const KvkkWholeLine = () => {
        const fullText = translate("pages.register.kvkkCheckbox", { 0: "!0!" });
        const parts = fullText.split("!0!");
        return (
            <span>
                {parts[0] || ""}
                <KvkkPolicyText />
                {parts[1] || ""}
            </span>
        );
    };

    const ClarificationWholeLine = () => {
        const fullText = translate("pages.register.clarificationCheckbox", { 0: "!0!" });
        const parts = fullText.split("!0!");
        return (
            <span>
                {parts[0] || ""}
                <KvkkClarificationText />
                {parts[1] || ""}
            </span>
        );
    };

    const kvkk = useMemo(() => {
        return agreements?.find((x) => x.agreementType === 3 && x.userType === 3);
    }, [agreements]);

    const clarification = useMemo(() => {
        return agreements?.find((x) => x.agreementType === 4 && x.userType === 3);
    }, [agreements]);

    const service = useMemo(() => {
        return agreements?.find((x) => x.agreementType === 1 && x.userType === 3);
    }, [agreements]);

    const ipaz = useMemo(() => {
        return agreements?.find((x) => x.agreementType === 5 && x.userType === 3);
    }, [agreements]);

    return (
        <Form<Fenomio_Contract_App_Brand_SignUpBrandRequest>
            layout="vertical"
            form={form}
            onFinish={async (values) => {
                registerCompany(values);
            }}
            requiredMark={false}
            initialValues={{
                countryCode: defaultSelectedCountry,
            }}
            scrollToFirstError
        >
            <style>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance:textfield; /* Firefox */
        }
      `}</style>
            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: translate("pages.brand.minimum"),
                        min: 2,
                        max: 150,
                    },
                ]}
            >
                <Input size="large" maxLength={60} placeholder={translate("global.brand", "Marka")} />
            </Form.Item>
            <Form.Item
                name="delegateName"
                rules={[
                    {
                        required: true,
                        message: translate("pages.register.delegateNameLength"),
                        min: 3,
                        max: 100,
                    },
                ]}
            >
                <Input size="large" maxLength={30} placeholder={translate("pages.register.delegateName", "Temsilci Adı")} />
            </Form.Item>
            <Form.Item
                name="email"
                rules={[
                    {
                        type: "email",
                        required: true,
                        message: translate("pages.register.emaill"),
                    },
                ]}
            >
                <Input
                    type="email"
                    maxLength={40}
                    autoComplete="email"
                    size="large"
                    placeholder={translate("pages.login.email", "E Posta")}
                />
            </Form.Item>
            <Form.Item
                name="countryCode"
                rules={[
                    {
                        required: true,
                        message: translate("pages.countrySelect.countrySelect"),
                    },
                ]}
            >
                <Select
                    size="large"
                    onChange={(cty) => {
                        const selectedcty = allCountries.find((item) => item.value === cty);
                        if (selectedcty !== undefined) setSelectedPhoneCode(selectedcty.dialCode.toString());
                    }}
                    defaultValue={defaultSelectedCountry}
                    options={countries?.map((country) => {
                        return {
                            label: country.text,
                            value: country.value,
                        };
                    })}
                ></Select>
            </Form.Item>
            <Form.Item
                name="phone"
                rules={[
                    {
                        required: true,
                        message: translate("pages.register.phoneMessage"),
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (selectedPhoneCode === "90") {
                                if (value.length > 10) {
                                    return Promise.reject(new Error(translate("pages.register.phoneLength")));
                                }
                            }
                            return Promise.resolve();
                        },
                    }),
                ]}
            >
                <Input
                    addonBefore={PrefixSelector}
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    maxLength={15}
                />
            </Form.Item>

            <Form.Item
                name="password"
                rules={[
                    { required: true, message: translate("pages.login.ispassword") },
                    { min: 8, message: translate("pages.register.minLength", "Şifre en az 8 karakter olmalı") },
                ]}
            >
                <Input.Password
                    type="password"
                    size="large"
                    maxLength={20}
                    placeholder={translate("pages.login.password", "Şifre")}
                    onChange={(e) => setPassword(e.target.value)}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </Form.Item>
            <Form.Item
                name="passwordConfirm"
                rules={[
                    {
                        required: true,
                        message: translate("pages.login.validate-passwordd"),
                    },
                    { min: 8, message: translate("pages.register.minLength", "Şifre en az 8 karakter olmalı") },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject(new Error(translate("pages.login.notMatch")));
                        },
                    }),
                ]}
            >
                <Input.Password
                    type="password"
                    size="large"
                    maxLength={20}
                    placeholder={translate("pages.login.password-again", "Şifre (Tekrar)")}
                    onChange={(e) => setPasswordAgain(e.target.value)}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </Form.Item>
            <PasswordChecklist
                rules={["minLength", "specialChar", "number", "capital", "match", "lowercase"]}
                minLength={8}
                value={password}
                valueAgain={passwordAgain}
                onChange={(isValid) => {}}
                messages={{
                    minLength: translate("pages.register.minLength", "Şifre en az 8 karakter olmalı"),
                    specialChar: translate("pages.register.specialChar", "Şifre özel karakter içermeli"),
                    number: translate("pages.register.number", "Şifre en az bir rakam içermeli"),
                    capital: translate("pages.register.capital", "Şifre en az bir büyük harf içermeli"),
                    match: translate("pages.register.match", "Şifre tekrarı ile aynı olmalı"),
                    lowercase: translate("pages.register.lowercase", "Şifre en az bir küçük harf içermeli"),
                }}
            />
            <Form.Item
                name={service?.id || "service"}
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) => {
                            return value ? Promise.resolve() : Promise.reject(new Error("Sözleşmeyi onaylamalısınız"));
                        },
                    },
                ]}
            >
                <Checkbox>
                    <span
                        style={{ color: "#952ae7" }}
                        onClick={() => {
                            setActiveAgreement(service);
                            setagreementVisible(true);
                        }}
                    >
                        {translate("pages.register.iacceptagreement", " Kabul Ediyorum.")}
                    </span>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={kvkk?.id || "kvkk"}
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) => {
                            return value ? Promise.resolve() : Promise.reject(new Error("Sözleşmeyi onaylamalısınız"));
                        },
                    },
                ]}
            >
                <Checkbox>
                    <KvkkWholeLine />
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={clarification?.id || "clarification"}
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) => {
                            return value ? Promise.resolve() : Promise.reject(new Error("Sözleşmeyi onaylamalısınız"));
                        },
                    },
                ]}
            >
                <Checkbox>
                    <ClarificationWholeLine />
                </Checkbox>
            </Form.Item>
            <Form.Item name={ipaz?.id || "ipaz"} valuePropName="checked">
                <Checkbox>
                    <span
                        style={{ color: "#952ae7" }}
                        onClick={() => {
                            setActiveAgreement(ipaz);
                            setagreementVisible(true);
                        }}
                    >
                        {translate("pages.register.ipaz", " Kabul Ediyorum.")}
                    </span>
                </Checkbox>
            </Form.Item>
            <Button
                type="default"
                className="ant-btn-block"
                size="large"
                htmlType="submit"
                loading={isLoading}
                onClick={handleReCaptchaVerify}
            >
                {translate("pages.register.registerButton", "Kaydet")}
            </Button>
            <Modal
                visible={agreementVisible}
                okType="default"
                okText={translate("pages.register.agreement.acceptButton", "Okudum, Onaylıyorum")}
                onOk={() => {
                    setagreementVisible(false);
                    const dynamicObj: any = {};
                    dynamicObj[activeAgreement?.id || ""] = true;
                    form.setFieldsValue(dynamicObj);
                }}
                cancelButtonProps={{ hidden: true }}
                onCancel={() => {
                    setagreementVisible(false);
                    const dynamicObj: any = {};
                    dynamicObj[activeAgreement?.id || ""] = false;
                    form.setFieldsValue(dynamicObj);
                }}
                width={800}
            >
                <UserAgreement body={activeAgreement?.text} title={activeAgreement?.name} />
            </Modal>
        </Form>
    );
};
