/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { AgreementResponse } from "services/models/AgreementResponse";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";
import { Fenomio_Common_BaseModels_Api_DataResponse, Fenomio_Common_BaseModels_Api_DetailItemDto } from "services";
import { OptionItem } from "services/models/OptionItem";

export class AgreementsService {
    /**
     * Get Latest Agreements
     * @returns Fenomio_Common_BaseModels_Api_DetailItemDto Success
     * @throws ApiError
     */
    public static getLatest({
        acceptLanguage,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<Array<AgreementResponse>>> {
        return __request({
            method: "GET",
            path: `agreements/latest`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getRequired({
        acceptLanguage,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<Array<AgreementResponse>>> {
        return __request({
            method: "GET",
            path: `agreements/required`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static signAgreements({
        body,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        body?: {agreements:Array<OptionItem>};
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `agreements/sign`,
            body: body,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }
}
