import { useTranslate } from "@pankod/refine";
import { Row, Table } from "antd";
import { FC } from "react";
import { Pagination } from "services/core/Pagination";
import { ReportsService } from "services/services/ReportsService";
import { DateInput, ReportFilter } from "./filter";
import { ReportTab } from "./ReportTab";
import { useTableReport } from "./hooks";
import { Money } from "services/models/money";

export const PayReport: FC = () => {
    const [data, loading, handlePaginationChange, onFilter, filter] = useTableReport({ fetchData: ReportsService.getPayEarningReport });

    const t = useTranslate();

    const columns = [
        {
            title: t("global.datee"),
            dataIndex: "date",
            key: "date",
        },
        {
            title: `${t("global.click", "Tıklama")}`,
            dataIndex: "click",
            key: "click",
        },
        {
            title: `${t("global.action", "Aksiyon")}`,
            dataIndex: "action",
            key: "action",
        },
        {
            title: t("global.download"),
            dataIndex: "install",
            key: "install",
        },
        {
            title: t("global.sell-count"),
            dataIndex: "selling",
            key: "selling",
        },
        {
            title: t("global.sell-product-count"),
            dataIndex: "numberProductsSold",
            key: "numberProductsSold",
        },
        {
            title: t("global.fenomioTotalBudgett"),
            dataIndex: "fenomioEarning",
            key: "fenomioEarning",
            render: (val:Number) => <span style={{whiteSpace:"nowrap"}}>{new Money(val).toStringWithCurrencyEnum(3)}</span>
        },
        {
            title: t("global.fenomen-earning"),
            dataIndex: "influencerEarning",
            key: "influencerEarning",
            //render: (text: string) => <>{text?.toString().replace(".", ",")}</>,
            render: (val:Number) => <span style={{whiteSpace:"nowrap"}}>{new Money(val).toStringWithCurrencyEnum(3)}</span>
        },
    ];

    return (
        <ReportTab downloadAPIService={ReportsService.getPayEarningReportExport} filter={filter} fileName="pay-earnings.xlsx">
            <Row>
                <ReportFilter onFilter={onFilter}>
                    <DateInput />
                </ReportFilter>
            </Row>
            <hr />
            <Table
                loading={loading}
                columns={columns}
                dataSource={data.data}
                onChange={(pagination) => handlePaginationChange(pagination as Pagination)}
                pagination={{
                    pageSize: data.pageInfo.pageSize,
                    total: data.pageInfo.totalItemCount,
                    current: data.pageInfo.pageNumber,
                }}
            />
        </ReportTab>
    );
};
