import { MyCampaigns } from "./myCampaigns";
import { RouteProps } from "react-router-dom";
import { MyCampaignWrapper } from "./campaignType/wrapper";

export const myCampaignsRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: MyCampaigns,
            path: "/campaign-influencer/list",
        },
        {
            exact: true,
            component: MyCampaignWrapper,
            path: "/campaign-influencer/detail/:campaignInfluencerId",
        },
        {
            exact: true,
            component: MyCampaignWrapper,
            path: "/campaign-influencer/detail/sale/:campaignId",
        },
    ];
};
