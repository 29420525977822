import { useTranslate } from "@pankod/refine";
import { Row, Col, Form, Space, Input, Select, Checkbox, Button } from "antd";
import { FC } from "react";
import { useFilter } from "./useFilter";

export const Filter: FC = () => {
    const [handleFilter, countries] = useFilter();
    const t = useTranslate();

    return (
        <Row>
            <Col style={{ fontSize: "small" }} span={24}>
                <Form name="basic" layout="vertical" initialValues={{ remember: true }} onFinish={handleFilter} autoComplete="off">
                    <Space direction="vertical" size={1} style={{ width: "100%" }}>
                        <Row gutter={[32, 8]}>
                            <Col xs={24} sm={24} md={8} span={8}>
                                <Form.Item label={t("global.influencer-id", "Fenomen ID")} name="influencerId">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} span={8}>
                                <Form.Item label={t("global.influencer-name", "Fenomen")} name="firstName">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} span={8}>
                                <Form.Item label={t("global.country")} name="countries">
                                    <Select
                                        allowClear
                                        mode={"multiple"}
                                        options={countries.map((country) => {
                                            return {
                                                label: country.text,
                                                value: country.id,
                                            };
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Form.Item name="listWaitingPaymentInfluencers" valuePropName="checked">
                                    <Checkbox>{t("global.payThisMonth-list")}</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ marginBottom: "60px" }}>
                            <Col style={{ marginRight: "auto" }}>
                                <Button htmlType={"submit"} type="default">
                                    {t("buttons.filter")}
                                </Button>
                            </Col>
                        </Row>
                    </Space>
                </Form>
            </Col>
        </Row>
    );
};
