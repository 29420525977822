import { Col, LayoutWrapper, Row, Table, useTranslate } from "@pankod/refine";
import { CampaignType } from "Constanst";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { RefundAndBonusTable } from "components/tables/refund-and-bonus-table";
import { localizationHelper } from "helpers/globalizationHelper";
import { EarningChart } from "pages/myCampaigns/campaignType/components/earningChart";
import { PerformanceChart } from "pages/myCampaigns/campaignType/components/performanceChart";
import { useEffect, useMemo, useState } from "react";
import { CampaignsService } from "services";

type SellingDetail = {
    count: number,
    payout: number,
    productName: string,
    price:number,
    saleCount:number,
    refundCount:number
};

const AdminInfluencerCampaingnPerformance = (props: any) => {
    useEffect(() => {
        console.log(props);
    });
    const t = useTranslate();
    const campaignInfluencerId = props.match.params.campaignInfluencerId;

    const [performanceDetail, setPerformanceDetail] = useState<any>(null);
    const [selectedPlatform, setSelectedPlatform] = useState<any>(null);
    const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
    const [influencer, setInfluencer] = useState<any>(null);
    const [campaign, setCampaign] = useState<any>(null);

    useEffect(() => {
        getInfluencerByCampaignInfluencerId(campaignInfluencerId);
        getCampaignInfluencerPerformance({});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getInfluencerByCampaignInfluencerId = (campaignInfluencerId: string) => {
        CampaignsService.getCampaignInfluencerById({ campaignInfluencerId, acceptLanguage: localizationHelper.getLocale() }).then(
            (resp) => {
                const respData = resp.data;
                setInfluencer({
                    id: respData.influencerId,
                    firstName: respData.influencerFirstName,
                    lastName: respData.influencerLastName,
                });
                setCampaign({
                    id: respData.campaignId,
                    name: respData.campaignName,
                    type: respData.campaignTypeHash,
                    ...respData
                });
            }
        );
    };

    const getCampaignInfluencerPerformance = (body: any) => {
        CampaignsService.getCampaignInfluencerPerformance({
            campaignInfluencerId: campaignInfluencerId,
            body,
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                setPerformanceDetail(resp.data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const changePlatform = (e: any) => {
        setSelectedPlatform(e);
        getCampaignInfluencerPerformance({
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            platform: e,
        });
    };

    const changeDate = (e: any) => {
        var startDate = e !== null ? e[0]?.$d : null;
        var endDate = e !== null ? e[1]?.$d : null;
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);

        getCampaignInfluencerPerformance({
            startDate: startDate,
            endDate: endDate,
            platform: selectedPlatform,
        });
    };

    const valuesTotal = useMemo(() => {
        let bonuses = 0;
        let refunds = 0;
        performanceDetail?.bonusesAndRefunds?.forEach((item:any)=>{
            if(item.transactionType===4)
                refunds += item.amount;
            else if(item.transactionType===3)
                bonuses += item.amount;
        });
        return {bonuses, refunds};
        // eslint-disable-next-line
    },[performanceDetail?.bonusesAndRefunds]);

    const saleItems = useMemo(()=>{
        var sellingDetails:Array<SellingDetail> = [];

        performanceDetail?.influencers[0]?.sales.forEach((item:any) => {
            const count = Number.parseFloat(item.count);
            const payout = Number.parseFloat(item.payout);
            const price = Number.parseFloat(item.price);
            var exist = sellingDetails.find(x=>x.productName===item.productName && x.price===price);
            if(exist){
                exist.payout+=payout;
                exist.count += count;
                if(count>0)
                    exist.saleCount+=count;
                else
                    exist.refundCount += count;
            }
            else{
                var temp:SellingDetail = {
                    productName:item.productName,
                    price:price,
                    payout: payout,
                    count:count,
                    saleCount:0,
                    refundCount:0
                }
                if(count>0)
                    temp.saleCount+=count;
                else
                    temp.refundCount += count;
                sellingDetails.push(temp);
            }
        });
        return sellingDetails;
    //eslint-disable-next-line
    },[performanceDetail?.influencers[0]?.sales])

    return (
        <LayoutWrapper>
            <FenBreadCrumb
                items={[
                    { name: t("global.campaigns"), url: "/campaign/list" },
                    {
                        name: campaign?.name,
                        url: "/campaign/detail/" + campaign?.id,
                    },
                    { name: t("global.phenomenons", "Fenomenler"), url: "/influencers/list" },
                    {
                        name: influencer?.firstName + " " + influencer?.lastName,
                        url: "/influencers/detail/" + influencer?.id,
                    },
                    { name: t("global.performancee"), url: "" },
                ]}
            />
            {campaign && campaign.type && (
                <Row style={{ margin: "20px 0px" }} gutter={[16, 16]}>
                    <Col span={24}>
                        <PageContainer
                            pageTitle={t("global.conversions")}
                            helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                        >
                            <>
                                <PerformanceChart
                                    campaignType={campaign.type}
                                    changeDate={changeDate}
                                    changePlatform={changePlatform}
                                    performance={performanceDetail}
                                />

                                {(campaign.type === CampaignType.selling || campaign.type === CampaignType.amazon) ? (
                                    <Table
                                        style={{ marginTop: "20px" }}
                                        rowKey="id"
                                        columns={[
                                            {
                                                title: <span className="table-font">{t("global.productName")}</span>,
                                                dataIndex: "productName",
                                                key: "productName",
                                            },
                                            {
                                                title: <span className="table-font">{t("global.saleCount")}</span>,
                                                dataIndex: "saleCount",
                                                key: "saleCount",
                                            },
                                            {
                                                title: <span className="table-font">{t("global.refundCount")}</span>,
                                                dataIndex: "refundCount",
                                                key: "refundCount",
                                            },
                                            {
                                                title: <span className="table-font">{t("global.commissionAmount")}</span>,
                                                dataIndex: "payout",
                                                key: "payout",
                                            },
                                        ]}
                                        dataSource={saleItems}
                                        scroll={{ x: "100%" }}
                                    />
                                ) : (
                                    <></>
                                )}
                            </>
                        </PageContainer>
                    </Col>
                    <Col xs={24} sm={24} md={8}>
                        <PageContainer
                            pageTitle={campaign?.name}
                            helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                        >
                            <Row gutter={[20, 0]}>
                                <Col xs={24} sm={24} md={12} span={12}>
                                    <Row>
                                        <h3 className="head">
                                            {campaign?.type === CampaignType.amazon
                                                ? t("global.commissionEarnings")
                                                : t("global.conversionEarnings")}
                                        </h3>
                                    </Row>
                                    <Row>
                                        <p>{performanceDetail?.totalInfluencerEarning}</p>
                                    </Row>
                                    <Row>
                                        <h3 className="head">{t("global.bonuses")}</h3>
                                    </Row>
                                    <Row>
                                        <p>{valuesTotal.bonuses}</p>
                                    </Row>
                                    <Row>
                                        <h3 className="head">{t("global.refunds")}</h3>
                                    </Row>
                                    <Row>
                                        <p>{valuesTotal.refunds}</p>
                                    </Row>
                                    <Row>
                                        <h3 className="head">{t("global.netEarnings")}</h3>
                                    </Row>
                                    <Row>
                                        <p>{performanceDetail?.totalInfluencerEarning + valuesTotal.bonuses - valuesTotal.refunds}</p>
                                    </Row>
                                </Col>
                            </Row>
                            <Row
                                style={{
                                    display: "flex",
                                    justifyContent: "flex-end",
                                    alignItems: "center",
                                    width: "100%",
                                    padding: "20px 0px",
                                }}
                            ></Row>
                        </PageContainer>
                    </Col>
                    <Col xs={24} md={16}>
                        <RefundAndBonusTable
                            bonusesAndRefunds={performanceDetail?.bonusesAndRefunds}
                            containerTitle={t("global.refundsAndBonuses")}
                            helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                            isAllInfluencers={false}
                        />
                    </Col>
                    {campaign.type !== CampaignType.clicking && (
                        <Col xs={24} sm={24} md={12} span={12}>
                            <PageContainer
                                pageTitle={t("global.performancee")}
                                helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                            >
                                <EarningChart campaignType={campaign.type} performance={performanceDetail} />
                            </PageContainer>
                        </Col>
                    )}
                </Row>
            )}
        </LayoutWrapper>
    );
};

export default AdminInfluencerCampaingnPerformance;
