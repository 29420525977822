import { Table } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { FunctionComponent } from "react";
import { useCampaignListForm } from "../hooks";

interface CampaignListFormProps {
    brandId: string;
    brandName: string;
}

export const CampaignListForm: FunctionComponent<CampaignListFormProps> = ({ brandId, brandName }: CampaignListFormProps) => {
    const [dataLoading, campaigns, pagination, columns, handleTableChange] = useCampaignListForm(brandId);

    return (
        <PageContainer pageTitle={brandName ?? ""} helperContent={"Buraya yardım içeriği gelecek"}>
            <Table
                columns={columns}
                dataSource={campaigns}
                pagination={pagination}
                loading={dataLoading}
                onChange={(page) => handleTableChange(page)}
                scroll={{ x: "100%" }}
            />
        </PageContainer>
    );
};
