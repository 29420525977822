import { useEffect, useState } from "react";
import { BillingDetail, InfluencersService } from "services";

export const useInfluencerBilling = (influencerId: string) => {
    const [billing, setBilling] = useState<BillingDetail>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const request = InfluencersService.getInfluencersBillingDetail(influencerId);

        request
            .then((response) => {
                setBilling(response.data);
            })
            .finally(() => {
                setLoading(false);
            });
        return () => {
            request.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return [billing, loading] as const;
};
