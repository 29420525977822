import { Button, Col, DatePicker, Form, Input, Row, useTranslate } from "@pankod/refine";
import { useEffect, useState } from "react";
import { PageProps } from "./PageProps";
import dayjs from "dayjs";
import { localizationHelper } from "helpers/globalizationHelper";

export interface ICampaignStep3DataSelfService {
    startDate: any;
    endDate: any;
    totalAmount: string;
    minimumInfluencerCount: string;
    amountByClick: string;
    amountByClickInfluencerEarning: string;
    amountByInfluencer: string;
    influencerTotalAmount: string;
    campaignLink: string | null;
}

export const ThirdPage = (props: PageProps) => {
    const t = useTranslate();

    const [form] = Form.useForm();
    const [selectedStartDate, setSelectedStartDate] = useState<any>(null);

    useEffect(() => {
        if (props.campaignData && props.isEditPage) {
            setSelectedStartDate(dayjs(props.campaignData.startDate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.campaignData]);

    const disabledStartDate = (current: any) => {
        return current < dayjs().add(-1, "day");
    };

    const disabledEndDate = (current: any) => {
        if (selectedStartDate) {
            return current < selectedStartDate.add(1, "day");
        }
        return false;
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                name="thirdStep"
                requiredMark={false}
                onFinish={(values) => {
                    const influencerTotalAmount = values.totalAmount * 0.7; // it is used for view!. it is backend's job
                    const amountByInfluencer = influencerTotalAmount / values.minimumInfluencerCount;
                    const amountByClickInfluencerEarning = (+values.amountByClick * (influencerTotalAmount / values.totalAmount)).toFixed(
                        2
                    );

                    let responseFormData: ICampaignStep3DataSelfService = {
                        startDate: values.startDate,
                        endDate: values.endDate,
                        totalAmount: values.totalAmount,
                        minimumInfluencerCount: values.minimumInfluencerCount,
                        amountByClick: values.amountByClick,
                        amountByClickInfluencerEarning: amountByClickInfluencerEarning,
                        amountByInfluencer: amountByInfluencer.toString(),
                        influencerTotalAmount: influencerTotalAmount.toString(),
                        campaignLink: values.campaignLink,
                    };
                    props.onFinish(responseFormData);
                }}
                scrollToFirstError
            >
                <Row>
                    <Col xs={24} sm={24} md={6} style={{ marginRight: "30px" }} span={6}>
                        <Form.Item
                            name="totalAmount"
                            label={t("global.budget-label")}
                            rules={[
                                {
                                    required: true,
                                    message: t("global.campaignBudget"),
                                },
                                {
                                    validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                        +value < 0 ? cb(t("global.please-positive")) : cb();
                                    },
                                },
                                {
                                    validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                        +value < 5000 ? cb(t("global.please-min-5000")) : cb();
                                    },
                                },
                            ]}
                            initialValue={props.campaignData.totalAmount}
                        >
                            <Input disabled={props.isEditPage} step={0.1} type={"number"} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Form.Item
                            label={t("global.start-date")}
                            name="startDate"
                            rules={[
                                {
                                    required: true,
                                    message: t("global.please-start-date"),
                                },
                            ]}
                            initialValue={props.campaignData.startDate !== undefined ? dayjs(props.campaignData.startDate) : undefined}
                        >
                            <DatePicker
                                locale={localizationHelper.getLocaleForComponent()}
                                disabled={props.isEditPage}
                                onChange={(c) => {
                                    setSelectedStartDate(c);
                                    form.setFieldsValue({ endDate: null });
                                }}
                                disabledDate={disabledStartDate}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={6} style={{ marginRight: "30px" }} span={6}>
                        <Form.Item
                            name="amountByClick"
                            label={t("global.amountbyClickkk")}
                            rules={[
                                {
                                    required: true,
                                    message: t("global.amountbyClickk"),
                                },
                                {
                                    validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                        +value < 0 ? cb(t("global.please-positive")) : cb();
                                    },
                                },
                            ]}
                            initialValue={props.campaignData.amountByClick}
                        >
                            <Input disabled={props.isEditPage} step={0.1} type={"number"} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Form.Item
                            label={t("global.end-date")}
                            name="endDate"
                            initialValue={props.campaignData.endDate !== undefined ? dayjs(props.campaignData.endDate) : undefined}
                        >
                            <DatePicker
                                locale={localizationHelper.getLocaleForComponent()}
                                disabledDate={disabledEndDate}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24} sm={24} md={6} style={{ marginRight: "30px" }} span={6}>
                        <Form.Item
                            name="minimumInfluencerCount"
                            label={t("global.minimumInfluencerCountt")}
                            tooltip={t("campaign.create.step3.minimumInfluencerCount.tooltip")}
                            rules={[
                                {
                                    required: true,
                                    message: t("global.please-minimumInfluencerCountt"),
                                },
                                {
                                    validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                        +value < 0 ? cb(t("global.please-positive")) : cb();
                                    },
                                },
                            ]}
                            initialValue={props.campaignData.minimumInfluencerCount}
                        >
                            <Input disabled={props.isEditPage} step={0.1} type={"number"} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Form.Item
                            label={t("global.targetLink")}
                            name="campaignLink"
                            initialValue={props.campaignData.campaignLink}
                            rules={[
                                { required: true, min: 3, max: 250, type: "url", message: t("campaign.create.step1.campaignLinkRule") },
                            ]}
                        >
                            <Input disabled={props.isEditPage} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ float: "right", display: "flex" }}>
                    <Col>
                        <Button
                            style={{ marginLeft: "auto", marginRight: "5px" }}
                            type="primary"
                            onClick={() => {
                                const currentFormValues = form.getFieldsValue();
                                props.onBack(currentFormValues);
                            }}
                        >
                            {t("buttons.back")}
                        </Button>
                        <Button type="default" htmlType="submit" style={{ width: "100px" }} loading={props.isLoading}>
                            {t("buttons.next")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
