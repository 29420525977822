import { useTranslate, useNavigation } from "@pankod/refine";
import { Row, Table, Modal } from "antd";
import { FC, useState } from "react";
import { ReportsService } from "services/services/ReportsService";
import { DateInput, ReportFilter } from "./filter";
import { ReportTab } from "./ReportTab";
import { useStandardReport } from "./hooks";
import { PaymentListData } from "services/models/reports";
import { Money } from "services/models/money";

export const BonusCancelReport: FC = () => {
    const [data, onFilter, filter, loading] = useStandardReport<PaymentListData[]>({
        fetchData: async (data) =>
            ReportsService.getCancelBonusReport({ ...data, onlyPayments: false }).then((response) => {
                return { data: response.data.filter((item: any) => item.process !== "Payment") };
            }),
    });

    const [visible, setVisible] = useState(false);
    const [description, setDescription] = useState<any>();
    const { push } = useNavigation();

    const t = useTranslate();

    const columns = [
        {
            title: <span className="table-font">{t("global.date")}</span>,
            dataIndex: "date",
            key: "date",
        },
        {
            title: <span className="table-font">{t("campaign.create.step2.fenomenid")}</span>,
            dataIndex: "influencerId",
            key: "influencerId",
            render: (text: any) => (
                <span style={{ cursor: "pointer" }} className="table-font" onClick={() => push("/influencers/detail/" + text)}>
                    #{text}
                </span>
            ),
        },
        {
            title: <span className="table-font">{t("campaign.create.step2.adminid")}</span>,
            dataIndex: "adminId",
            key: "adminId",
            render: (text: any) => <span>#{text}</span>,
        },
        {
            title: <span className="table-font">{t("campaign.create.step3.lastamount")}</span>,
            dataIndex: "previousBalance",
            key: "previousBalance",
            render: (previousBalance: string, row: PaymentListData) => (
                <>{new Money(previousBalance).toStringWithCurrency(row.currencyDisplay)}</>
            ),
        },
        {
            title: <span className="table-font">{t("campaign.create.step3.nextamount")}</span>,
            dataIndex: "nextBalance",
            key: "nextBalance",
            render: (nextBalance: string, row: PaymentListData) => <>{new Money(nextBalance).toStringWithCurrency(row.currencyDisplay)}</>,
        },
        {
            title: <span className="table-font">{t("campaign.create.step1.campaignid")}</span>,
            dataIndex: "campaignId",
            key: "campaignId",
            render: (text: any) => (
                <span style={{ cursor: "pointer" }} className={"table-font"} onClick={() => push("/campaign/detail", { id: text })}>
                    #{text}
                </span>
            ),
        },
        {
            title: <span className="table-font">{t("global.Process")}</span>,
            dataIndex: "process",
            key: "process",
        },
        {
            title: <span className="table-font">{t("global.amount")}</span>,
            dataIndex: "amount",
            key: "amount",
            render: (amount: number, row: PaymentListData) => <span>{new Money(amount).toStringWithCurrency(row.currencyDisplay)}</span>,
        },
        {
            title: <span className="table-font">{t("global.description")}</span>,
            dataIndex: "description",
            key: "description",
            render: (text: any, row: any) => {
                return (
                    <p>
                        <img
                            src={"/images/menu-icon/InfoCircle.svg"}
                            alt="info"
                            onClick={() => {
                                setDescription(text);
                                setVisible(true);
                            }}
                        />
                    </p>
                );
            },
        },
    ];

    return (
        <ReportTab
            downloadAPIService={async (filter) => ReportsService.getCancelBonusReportExport({ ...filter, onlyPayments: false })}
            filter={filter}
            fileName="cancel-bonus.xlsx"
        >
            <Row>
                <ReportFilter onFilter={onFilter}>
                    <DateInput />
                </ReportFilter>
            </Row>
            <hr />
            <Table loading={loading} columns={columns} dataSource={data} pagination={false} />
            <Modal onCancel={() => setVisible(false)} visible={visible} footer={[]}>
                <p style={{ fontWeight: "bold", fontSize: "22px", textAlign: "center", color: "#952AE7" }}>Açıklama</p>,
                <p style={{ textAlign: "center" }}>{description}</p>
            </Modal>
        </ReportTab>
    );
};
