import { Col, Input, LayoutWrapper, notification, useTranslate } from "@pankod/refine";
import Header from "../header";
import { Button, Card, Layout, Row, Typography, Form } from "antd";
import { Fenomio_Contract_App_User_GetTokenContract, LoginService } from "services";
import { localizationHelper } from "helpers/globalizationHelper";
import { useState } from "react";
import { EyeTwoTone, EyeInvisibleOutlined } from "@ant-design/icons";
import PasswordChecklist from "react-password-checklist";

import "./resetPassword.scss";

const { Title } = Typography;

export const ResetPasswordPage = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [passwordAgain, setPasswordAgain] = useState("");
    const [password, setPassword] = useState("");
    const [form] = Form.useForm<Fenomio_Contract_App_User_GetTokenContract>();
    const translate = useTranslate();
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const userId = params.get("id");
    function resetPassword(values: any) {
        setLoading(true);
        LoginService.resetPassword({
            acceptLanguage: localizationHelper.getLocale(),
            id: userId ?? "",
            requestBody: {
                password: values.password,
                passwordConfirm: values.passwordConfirm,
            },
        })
            .then((res) => {
                setLoading(false);
                if (res.status === "SUCCESS") {
                    notification.open({
                        message: res.status,
                        description: res.message,
                        duration: 3,
                    });
                }

                setTimeout(() => {
                    props.history.push("/login");
                }, 4000);
            })
            .catch((err) => {
                setLoading(false);
                notification.open({
                    message: err.body.status,
                    description: err.body.message,
                    duration: 2,
                });
            });
    }

    return (
        <LayoutWrapper
            Sider={() => <></>}
            Footer={() => <></>}
            Title={() => <></>}
            Header={() => (
                <>
                    <Header onLoginClicked={() => {}} onRegisterClicked={() => {}} />
                </>
            )}
        >
            <div style={{ height: "200px", margin: "-30px -10px 0px -25px" }}></div>
            <Layout>
                <div
                    id="resetPassword"
                    style={{
                        minHeight: "300px",
                        display: "flex",
                        alignContent: "center",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Title style={{ verticalAlign: "middle", textAlign: "center" }}>Şifre Yenile</Title>

                    <Row justify="center" align="middle">
                        <Col span={24}>
                            <Card style={{ maxWidth: 500 }}>
                                <Form<Fenomio_Contract_App_User_GetTokenContract>
                                    layout="vertical"
                                    form={form}
                                    onFinish={(values) => {
                                        resetPassword(values);
                                    }}
                                    requiredMark={false}
                                    initialValues={{
                                        remember: false,
                                    }}
                                >
                                    <Form.Item
                                        name="password"
                                        rules={[
                                            { required: true, message: translate("pages.register.pleasePassword") },
                                            {
                                                min: 8,
                                                message: translate(
                                                    "pages.register.passwordValidation.minLength",
                                                    "Şifre en az 8 karakter olmalı"
                                                ),
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            type="password"
                                            size="large"
                                            maxLength={20}
                                            placeholder={translate("pages.register.password", "Şifre")}
                                            onChange={(e) => setPassword(e.target.value)}
                                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        name="passwordConfirm"
                                        rules={[
                                            {
                                                required: true,
                                                message: translate("pages.login.validate-passwordd"),
                                            },
                                            {
                                                min: 8,
                                                message: translate(
                                                    "pages.register.passwordValidation.minLength",
                                                    "Şifre en az 8 karakter olmalı"
                                                ),
                                            },
                                            ({ getFieldValue }) => ({
                                                validator(_, value) {
                                                    if (!value || getFieldValue("password") === value) {
                                                        return Promise.resolve();
                                                    }

                                                    return Promise.reject(new Error(translate("pages.login.notMatch")));
                                                },
                                            }),
                                        ]}
                                    >
                                        <Input.Password
                                            type="password"
                                            size="large"
                                            maxLength={20}
                                            placeholder={translate("pages.register.passwordConfirm", "Şifre (Tekrar)")}
                                            onChange={(e) => setPasswordAgain(e.target.value)}
                                            iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                        />
                                    </Form.Item>

                                    <PasswordChecklist
                                        rules={["minLength", "specialChar", "number", "capital", "match"]}
                                        minLength={8}
                                        value={password}
                                        valueAgain={passwordAgain}
                                        onChange={(isValid) => {}}
                                        messages={{
                                            minLength: translate(
                                                "pages.register.passwordValidation.minLength",
                                                "Şifre en az 8 karakter olmalı"
                                            ),
                                            specialChar: translate(
                                                "pages.register.passwordValidation.specialChar",
                                                "Şifre özel karakter içermeli"
                                            ),
                                            number: translate("pages.register.passwordValidation.number", "Şifre en az bir rakam içermeli"),
                                            capital: translate(
                                                "pages.register.passwordValidation.capital",
                                                "Şifre en az bir büyük harf içermeli"
                                            ),
                                            match: translate("pages.register.passwordValidation.match", "Şifre tekrarı ile aynı olmalı"),
                                        }}
                                    />

                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "center",
                                            justifyItems: "center",
                                        }}
                                    >
                                        <br />
                                        <br />
                                        <Button
                                            type="default"
                                            size="large"
                                            htmlType="submit"
                                            loading={loading}
                                        >
                                            {translate("pages.login.resetPassword", "Şifreyi yenile")}
                                        </Button>
                                    </div>
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </Layout>
        </LayoutWrapper>
    );
};
