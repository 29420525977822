import { Authenticated, LayoutWrapper } from "@pankod/refine";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { FC } from "react";
import { AdminBrandList } from "modules/brands/list/AdminBrandList";

export const BrandList: FC = () => {
    return (
        <Authenticated>
            <LayoutWrapper>{RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root) ? <AdminBrandList /> : null}</LayoutWrapper>
        </Authenticated>
    );
};
