import { useTranslate } from "@pankod/refine";
import { useMemo } from "react";
import { Button, Popover, Space } from "antd";
import { Link } from "react-router-dom";
import { InfoCircleOutlined, MoreOutlined } from "@ant-design/icons";
import { EVENTS } from "Constanst";
import { PaymentApprovementDetail, PaymentRefundOrBonusDetail } from "components";
import { Money } from "services/models/money";

export const useColumns = () => {
    const t = useTranslate();

    // the functions to open modals
    function showBalanceDetails(paymentBalanceOnMonthDetails: any, currency: string) {
        window.dispatchEvent(
            new CustomEvent(EVENTS.PAYMENT_BALANCE_DETAIL_MODAL_IS_OPENED, { detail: { paymentBalanceOnMonthDetails, currency } })
        );
    }

    function showAccountDetail(paymentInvoiceStatus: boolean, id: string) {
        window.dispatchEvent(new CustomEvent(EVENTS.PAYMENT_ACCOUNT_DETAIL_MODAL_IS_OPENED, { detail: { paymentInvoiceStatus, id } }));
    }

    function showPaymentList(payments: any[]) {
        window.dispatchEvent(new CustomEvent(EVENTS.PAYMENT_LIST_MODAL_IS_OPENED, { detail: payments }));
    }

    function showPaymentApprovement(detail: PaymentApprovementDetail) {
        window.dispatchEvent(new CustomEvent(EVENTS.PAYMENT_APPROVEMENT_MODAL_IS_OPENED, { detail }));
    }

    function showRefundOrBonusForm(detail: PaymentRefundOrBonusDetail) {
        window.dispatchEvent(new CustomEvent(EVENTS.PAYMENT_REFUND_OR_BONUS_MODAL_IS_OPENED, { detail }));
    }
    // Table columns
    const columns = useMemo(
        () => [
            {
                title: (
                    <span style={{ fontSize: "small" }} className="table-font">
                        Fenomen
                    </span>
                ),
                dataIndex: "nickname",
                key: "nickname",
                render: (text: string, row: any, index: number) => (
                    <>
                        <Space direction="vertical" size="small">
                            {
                                <Link to={"/influencers/detail/" + row.id}>
                                    <Button type="link" style={{ fontWeight: "bold", padding: 0, borderWidth: 0 }}>
                                        {" "}
                                        {row.firstName} {row.lastName}
                                    </Button>
                                </Link>
                            }
                            {row.socialMedias.map((social: any) => (
                                <a
                                    href={social.link}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ padding: 0, height: 25, fontSize: "small", color: "#9254de" }}
                                >
                                    <img
                                        alt="social media icon"
                                        width={22}
                                        height={22}
                                        src={`/images/social-media-icon/${social?.name?.toUpperCase()}36Px.svg`}
                                    />{" "}
                                    {"@" + social.username}{" "}
                                </a>
                            ))}
                        </Space>
                    </>
                ),
            },
            {
                title: (
                    <span style={{ fontSize: "small" }} className="table-font">
                        {" "}
                        Toplam Hakediş
                    </span>
                ),
                dataIndex: "paymentBalanceOnMonthDetails",
                key: "progressPaymentLastMonth",
                render: (item: any, row: any) => (
                    <span style={{ whiteSpace: "nowrap" }}>
                        {new Money(item.progressPaymentLastMonth).toStringWithCurrency(row.currency)}
                    </span>
                ),
            },
            {
                title: (
                    <span style={{ fontSize: "small" }} className="table-font">
                        {" "}
                        Ödenecek Net Tutar
                    </span>
                ),
                dataIndex: "paymentBalanceOnMonthDetails",
                key: "netPayable",
                render: (item: any, row: any, index: number) => (
                    <span>
                        <span style={{ whiteSpace: "nowrap" }}>{new Money(item.netPayable).toStringWithCurrency(row.currency)} </span>
                        {item?.netPayable?.toString().substring(0).length > 0 && (
                            <InfoCircleOutlined onClick={() => showBalanceDetails(item, row.currency)} />
                        )}{" "}
                    </span>
                ),
            },
            {
                title: (
                    <span style={{ fontSize: "small" }} className="table-font">
                        {" "}
                        Hesap Bilgileri
                    </span>
                ),
                dataIndex: "paymentIBAN",
                key: "paymentIBAN",
                render: (text: string, row: any, index: number) => {
                    return (
                        <>
                            {row.paymentIBAN && row.paymentFullname ? (
                                <>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            showAccountDetail(row.paymentInvoiceStatus, row.id);
                                        }}
                                    >
                                        {t("global.view")}
                                    </Button>
                                </>
                            ) : (
                                <p>-</p>
                            )}
                        </>
                    );
                },
            },
            {
                title: (
                    <span style={{ fontSize: "small" }} className="table-font">
                        {" "}
                        {t("global.past-payment")}
                    </span>
                ),
                dataIndex: "payments",
                key: "payments",
                render: (payments: any) => {
                    return (
                        <>
                            {payments && payments.length > 0 ? (
                                <Button
                                    type="link"
                                    onClick={() => {
                                        showPaymentList(payments);
                                    }}
                                >
                                    {t("global.view")}
                                </Button>
                            ) : (
                                <p>-</p>
                            )}
                        </>
                    );
                },
            },
            {
                title: (
                    <span style={{ fontSize: "small" }} className="table-font">
                        {" "}
                        Kategoriler
                    </span>
                ),
                dataIndex: "categories",
                key: "paymentTotalBalance",
                render: (cats: string[], row: any, index: number) => <span>{cats?.join(", ")}</span>,
            },
            {
                title: (
                    <span style={{ fontSize: "small" }} className="table-font">
                        {" "}
                        {t("global.action", "Aksiyon")}
                    </span>
                ),
                key: "paymentTotalBalanceOnMonth",
                dataIndex: "paymentTotalBalanceOnMonth",
                render: (paymentTotalBalanceOnMonth: number, row: any) => (
                    <Space size="middle">
                        <Popover
                            placement="bottomRight"
                            content={
                                <Space direction="vertical" size="small">
                                    {row.paymentBalanceOnMonthDetails?.paymentTotalBalanceOnMonth?.substring(1) > 0 && (
                                        <>
                                            <Button
                                                type="link"
                                                onClick={() => {
                                                    showPaymentApprovement({
                                                        id: row.id,
                                                        firstName: row.firstName,
                                                        lastName: row.lastName,
                                                        paymentTotalBalanceOnMonth,
                                                        currency: row.currency,
                                                    });
                                                }}
                                            >
                                                {" "}
                                                Ödeme Onayı{" "}
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            showRefundOrBonusForm({ id: row.id, type: "refund" });
                                        }}
                                    >
                                        {" "}
                                        {t("global.addReturn")}{" "}
                                    </Button>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            showRefundOrBonusForm({
                                                id: row.id,
                                                type: "bonus",
                                            });
                                        }}
                                    >
                                        {" "}
                                        {t("campaign.create.step3.addbonus")}{" "}
                                    </Button>
                                </Space>
                            }
                            trigger="click"
                        >
                            <Button type="link">
                                <MoreOutlined style={{ fontSize: "24px" }} />
                            </Button>
                        </Popover>
                    </Space>
                ),
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return columns;
};
