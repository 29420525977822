import { LayoutWrapper } from "@pankod/refine";
import { Roles } from "helpers/roleHelper";
import UserHelper from "helpers/userHelper";
import { FunctionComponent } from "react";
import { AdminCampaignList, BrandCampaignList, InfluencerCampaignList } from "./Components";

export const CampaignList: FunctionComponent = () => {
    return (
        <LayoutWrapper>
            {{
                [Roles.Admin]: <AdminCampaignList />,
                [Roles.Root]: <AdminCampaignList />,
                [Roles.Brand]: <BrandCampaignList />,
                [Roles.Influencer]: <InfluencerCampaignList />,
            }[UserHelper.getRole() as Roles] || null}
        </LayoutWrapper>
    );
};
