import { useTranslate } from "@pankod/refine";
import { DatePicker, Form, Col } from "antd";
import { FC } from "react";

const { RangePicker } = DatePicker;
export const DateInput: FC = () => {
    const t = useTranslate();
    return (
        <Col xs={24} sm={12} md={10} xl={8}>
            <Form.Item name="date" label={t("global.date")} wrapperCol={{ span: 18 }} labelCol={{ span: 6 }}>
                <RangePicker style={{ width: "100%" }} />
            </Form.Item>
        </Col>
    );
};
