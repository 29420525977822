/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Fenomio_Contract_App_Brand_GetBrandDetailForInfluencerResponse } from "../models/Fenomio_Contract_App_Brand_GetBrandDetailForInfluencerResponse";
import type { Fenomio_Contract_App_Brand_GetBrandDetailResponse } from "../models/Fenomio_Contract_App_Brand_GetBrandDetailResponse";
import type { Fenomio_Contract_App_Brand_SignUpBrandRequest } from "../models/Fenomio_Contract_App_Brand_SignUpBrandRequest";
import type { Fenomio_Contract_App_Brand_UpdateBrandRequest } from "../models/Fenomio_Contract_App_Brand_UpdateBrandRequest";
import type { Filtery_Models_FilteryRequest } from "../models/Filtery_Models_FilteryRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";
import { Fenomio_Common_BaseModels_Api_DataResponse } from "services";

export class BrandsService {
    /**
     * Delete Brand
     * @returns any Success
     * @throws ApiError
     */
    public static deleteBrands({
        id,
        acceptLanguage,
    }: {
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "DELETE",
            path: `brands/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    /**
     * Get Brand
     * @returns Fenomio_Contract_App_Brand_GetBrandDetailResponse Success
     * @throws ApiError
     */
    public static getBrands({
        id,
        acceptLanguage,
    }: {
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<Fenomio_Contract_App_Brand_GetBrandDetailResponse>> {
        return __request({
            method: "GET",
            path: `brands/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getAllBrands({
        acceptLanguage,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `brands/all`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    /**
     * Update Brand
     * @returns any Success
     * @throws ApiError
     */
    public static updateBrand({
        id,
        acceptLanguage,
        requestBody,
    }: {
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `brands/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    /**
     * Get Brand Detail for Influencers
     * @returns Fenomio_Contract_App_Brand_GetBrandDetailForInfluencerResponse Success
     * @throws ApiError
     */
    public static getBrands1({
        id,
        acceptLanguage,
    }: {
        /** The brand identifier. **/
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Contract_App_Brand_GetBrandDetailForInfluencerResponse> {
        return __request({
            method: "GET",
            path: `brands/${id}/influencer`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    /**
     * Search Brand
     * @returns any Success
     * @throws ApiError
     */
    public static searchBrands({
        acceptLanguage,
        requestBody,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `brands/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    /**
     * Sign Up Brand
     * @returns any Success
     * @throws ApiError
     */
    public static signup({
        acceptLanguage,
        requestBody,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody?: Fenomio_Contract_App_Brand_SignUpBrandRequest;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `brands/signup`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    public static uploadPhoto({
        body,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `brands/upload-photo`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static getPhoto({
        id,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        id: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `brands/${id}/get-photo`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getReceipts({
        id,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        id: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `brands/${id}/receipts`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }
}
