import { useCallback, useEffect, useState } from "react";
import { BrandsService } from "services";
import { IBrand } from "modules/brands/model";
import { localizationHelper } from "helpers/globalizationHelper";

export const useBrandDetail = (brandId: string) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [brand, setBrand] = useState<IBrand>({
        id: "",
        name: "",
        explanation: "",
        biography: "",
        sector: "",
        sectorId: "",
        country: "",
        countryCode: 0,
        delegate: "",
        phone: "",
        email: "",
        status: 1,
        logo: "",
        recordDate: "",
        delegateName: "",
        workingDetail: "",
        detail: "",
        hasMarketingPermission: false,
    });

    const getBrand = useCallback(() => {
        setLoading(true);
        BrandsService.getBrands({
            id: brandId,
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((response) => {
                setBrand(response.data as IBrand);
            })
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId]);

    useEffect(() => {
        if (brandId) getBrand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId]);

    return [loading, brand, getBrand] as const;
};
