import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Button, Col, Form, Input, LayoutWrapper, Row, Select, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { Fenomio_Contract_App_User_CreateUserRequest, UsersService } from "../../../services";
import { localizationHelper } from "../../../helpers/globalizationHelper";
import { toast } from "react-toastify";
import { useState } from "react";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
const { Option } = Select;

const AdminCreate = () => {
    const [loading, setLoading] = useState(false);
    const t = useTranslate();

    const prepareRequest = (value: any) => {
        const data: Fenomio_Contract_App_User_CreateUserRequest = {};
        data.email = value.email;
        data.firstName = value.firstName;
        data.lastName = value.lastName;
        data.password = value.password;
        data.userType = value.userType;
        return data;
    };

    return (
        <LayoutWrapper>
            <Col span={24}>
            <FenBreadCrumb
                items={[
                    { name: t("global.users"), url: "/brands/list" },
                    { name: t("pages.login.adduser"), url: "" },
                ]}
            />
                <PageContainer pageTitle={t("pages.login.adduser")} helperContent={"Yardım içeriği eklenecek!"}>
                    <Col span={24}>
                        <Form
                            layout="vertical"
                            onFinish={(values) => {
                                UsersService.postUsers({
                                    acceptLanguage: localizationHelper.getLocale(),
                                    requestBody: prepareRequest(values),
                                })
                                    .then((response) => {
                                        setLoading(false);
                                        toast.success(t("pages.login.success-adduser"));
                                    })
                                    .catch((error) => {
                                        setLoading(false);
                                        toast.error("Hata..");
                                    });
                            }}
                        >
                            <Form.Item label="Ad" name="firstName" rules={[{ required: true, message: t("pages.login.entername") }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="Soyad" name="lastName" rules={[{ required: true, message: t("pages.login.enterlastname") }]}>
                                <Input autoComplete="off" />
                            </Form.Item>
                            <Form.Item label="E-posta" name="email" rules={[{ required: true, message: t("pages.login.enteremail") }]}>
                                <Input type="email" autoComplete="off" placeholder={"Email"} />
                            </Form.Item>
                            <Form.Item
                                label="Şifre"
                                name="password"
                                rules={[
                                    { required: true, message: t("pages.login.ispassword") },
                                    { min: 8, message: t("pages.login.passwordlength") },
                                ]}
                            >
                                <Input.Password
                                    type="password"
                                    autoComplete="off"
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t("pages.login.password-again")}
                                name="confirmPassword"
                                dependencies={["password"]}
                                hasFeedback
                                rules={[
                                    { required: true, message: t("pages.login.ispassword") },
                                    { min: 8, message: t("pages.login.passwordlength") },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || getFieldValue("password") === value) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error(t("pages.login.passwordmatch")));
                                        },
                                    }),
                                ]}
                            >
                                <Input.Password
                                    type="confirmPassword"
                                    autoComplete="off"
                                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                />
                            </Form.Item>
                            <Form.Item
                                label={t("global.user-type")}
                                name="userType"
                                rules={[{ required: true, message: t("global.please-user-type") }]}
                            >
                                <Select>
                                    <Option value={1}>Root</Option>
                                    <Option value={2}>Admin</Option>
                                </Select>
                            </Form.Item>
                            <Row justify="end">
                                <Button
                                    loading={loading}
                                    onClick={() => setLoading(true)}
                                    htmlType="submit"
                                    type="default"
                                    style={{ marginRight: "20px" }}
                                >
                                    {t("buttons.save")}
                                </Button>
                            </Row>
                        </Form>
                    </Col>
                </PageContainer>
            </Col>
        </LayoutWrapper>
    );
};

export default AdminCreate;
