import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store";

// Define a type for the slice state
interface AuthState {
    isLoggedIn: boolean;
    message: string;
    forgotPassword: boolean;
    activeAuthTab: string;
    needsShowMessage: boolean;
}

// Define the initial state using that type
const initialState: AuthState = {
    isLoggedIn: false,
    message: "",
    forgotPassword: false,
    activeAuthTab: "1",
    needsShowMessage: false,
};

export const authSlice = createSlice({
    name: "auth",
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        toggleForgotPassword: (state, action: PayloadAction<boolean>) => {
            state.forgotPassword = action.payload;
        },
        setLoggedIn: (state) => {
            state.isLoggedIn = true;
        },
        setActiveAuthTab: (state, action: PayloadAction<string>) => {
            state.activeAuthTab = action.payload;
        },
        setNeedsShowMessage: (state, message: PayloadAction<string>) => {
            state.needsShowMessage = true;
            state.message = message.payload;
        },
        hideMessage: (state) => {
            state.needsShowMessage = false;
        },
    },
});

export const { setActiveAuthTab, setNeedsShowMessage, hideMessage, toggleForgotPassword, setLoggedIn } = authSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const isLoggedIn = (state: RootState) => state.auth.isLoggedIn;

export default authSlice.reducer;
