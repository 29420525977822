/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";
import { Fenomio_Common_BaseModels_Api_DataResponse } from "services";

export class PaymentsService {
    public static createBonus({
        body,
        acceptLanguage,
    }: {
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "POST",
            path: `payments/bonus`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static createRefund({
        body,
        acceptLanguage,
    }: {
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "POST",
            path: `payments/refund`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static createPayment({
        body,
        acceptLanguage,
    }: {
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "POST",
            path: `payments`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static getPayments({
        body,
        acceptLanguage,
    }: {
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "POST",
            path: `payments/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static paymentFailed({
        body,
        acceptLanguage,
    }: {
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "POST",
            path: `payments/payment-failed`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }
}
