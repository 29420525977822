import { EVENTS } from "Constanst";
import { useModal } from "hooks";
import { PaymentsService } from "services/services/PaymentsService";
import { PaymentResultDetail } from "../PaymentResultModal/Component";
import { useState } from "react";

export const useMultiplePaymentApprovement = <T>() => {
    const [loading, setLoading] = useState(false);
    const [open, detail, handleClose] = useModal<T>(EVENTS.PAYMENT_MULTIPLE_APPROVEMENT_MODAL_IS_OPENED);

    function showResultModal(detail: PaymentResultDetail) {
        window.dispatchEvent(new CustomEvent(EVENTS.PAYMENT_RESULT_MODAL_IS_OPENED, { detail }));
    }

    function handleOk() {
        if (detail?.influencerList) setLoading(true);
        PaymentsService.createPayment({
            body: {
                influencerIds: detail?.influencerList,
            },
        })
            .then((response) => {
                showResultModal(response.data as PaymentResultDetail);
            })
            .finally(() => {
                setLoading(false);
                handleClose();
            });
    }

    return [open, detail as T, handleClose, handleOk, loading] as const;
};
