export interface InfluencerCategory {
    id: string;
    isPublic: boolean;
    name: string;
}

export interface InfluencerSocialMedia {
    id: string;
    createdOn: Date;
    followers: number;
    isApproved: false;
    link: string;
    pendingApprovalFollowers: number;
    remove: boolean;
    socialMedia: string;
    updatedOn: Date;
    username: string;
}

export interface InfluencerBillingDetail {
    id: string;
    address: string;
    bankName: string;
    companyName: string | null;
    companyType: string | null;
    firstName: string;
    fullName: string;
    hasExceptionLicence: boolean;
    iban: string;
    identityNumber: string;
    isBillActive: boolean;
    lastName: string;
}

export enum BILLING_STATUS {
    Empty = 0,
    hasExceptionLicense = 1,
    canInvoice = 2,
    canNotInvoice = 3,
}

export type Influencer = {
    id?: string;
    logoPath?: string | null;
    firstName?: string | null;
    lastName?: string | null;
    biography?: string | null;
    socialMedias?: Array<InfluencerSocialMedia> | null;
    categories?: Array<InfluencerCategory> | null;
    country?: string | null;
    phone?: string | null;
    email?: string | null;
    createdOn?: string | null;
    balance?: string | null;
    balanceOnMonth?: string | null;
    billingDetail?: InfluencerBillingDetail;
    currency?: any;
    billingStatus: BILLING_STATUS;
    status: number;
};
