import { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { List, Skeleton, Divider, notification } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import { Modal, Col, Row, Button, Input, useTranslate } from "@pankod/refine";
import { CampaignsService } from "../../../../services";
import { localizationHelper } from "helpers/globalizationHelper";

export interface InfinityListProps {
    datas: any[];
    refresh: any;
}

export const InfiniteList = (props: InfinityListProps) => {
    const [loading, setLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedLink, setSelectedLink] = useState<any>(null);
    const [changedName, setChangedName] = useState("");
    const t = useTranslate();

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const copyToClipboard = (link: string) => {
        const textField = document.createElement("textarea");
        textField.innerText = link;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        notification.open({ message: t("global.copied"), type: "success" });
    };

    const loadMoreData = () => {
        if (!props.datas) {
            setLoading(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        loadMoreData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeLinkName = () => {
        CampaignsService.updateCampaignLink({
            id: selectedLink.id,
            body: {
                name: changedName,
            },
            acceptLanguage: localizationHelper.getLocale(),
        }).then(() => {
            setSelectedLink(null);
            setIsModalVisible(false);
            setChangedName("");
            props.refresh();
        });
    };

    return (
        <div
            id="scrollableDiv"
            style={{
                height: 250,
                overflow: "auto",
                padding: "0 16px",
                border: "1px solid rgba(140, 140, 140, 0.35)",
                width: "100%",
            }}
        >
            <InfiniteScroll
                dataLength={props.datas.length}
                next={loadMoreData}
                hasMore={loading}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain />}
                scrollableTarget="scrollableDiv"
            >
                <List
                    dataSource={props.datas}
                    renderItem={(item: any) => (
                        <List.Item key={item.id}>
                            <List.Item.Meta
                                title={
                                    <div>
                                        <a href={item.link}>{item.title} </a>
                                        <EditOutlined
                                            style={{ cursor: "pointer", color: "#952AE7" }}
                                            onClick={() => {
                                                setSelectedLink(item);
                                                setIsModalVisible(true);
                                            }}
                                        />
                                    </div>
                                }
                            />
                            <Row onClick={() => copyToClipboard(item.targetLink)}>
                                <Col style={{ cursor: "pointer", color: "#952AE7" }}>{item.customName}</Col>
                                <CopyOutlined style={{ cursor: "pointer", color: "#952AE7" }} />
                            </Row>
                        </List.Item>
                    )}
                />
            </InfiniteScroll>
            <Modal visible={isModalVisible} centered footer={[]} onCancel={handleCancel}>
                <Row gutter={[0, 10]} style={{ display: "flex", flexDirection: "column" }}>
                    <Col style={{ fontWeight: "bold", fontSize: "18px" }}>{t("global.linkname")}</Col>
                    <Col>
                        <Row>{t("global.linkname")}</Row>
                        <Input onChange={(e) => setChangedName(e.target.value)} placeholder={selectedLink?.customName} />
                    </Col>
                    <Col>
                        <Row>{t("global.link")}</Row>
                        <Input disabled={true} value={selectedLink?.targetLink} />
                    </Col>
                    <Col>
                        <Button disabled={!changedName} onClick={() => changeLinkName()} type="default">
                            {t("buttons.save")}
                        </Button>
                    </Col>
                </Row>
            </Modal>
        </div>
    );
};
