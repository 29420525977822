import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Col, Row, Tooltip, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import UserHelper from "helpers/userHelper";
import { useEffect, useState } from "react";
import { ProfileModel } from "./models";
import ProfileImg from "components/microparts/profile-img";
import { Roles } from "helpers/roleHelper";
import { Link } from "react-router-dom";

export const InfluencerProfilePage = () => {
    const [profileData, setProfileData] = useState<ProfileModel>();
    const t = useTranslate();

    const getProfileInfo = () => {
        UserHelper.getProfile().then((resp) => {
            setProfileData(resp as ProfileModel);
        });
    };

    useEffect(() => {
        getProfileInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const calculateFollowersString = (count: any) => {
        return (count % 1000 > 0 ? (count - (count % 1000)) / 1000 : count / 1000) + "K";
    };

    return (
        <Row gutter={[15, 10]}>
            <Col xs={24} sm={24} md={12} span={12}>
                <PageContainer pageTitle={t("global.account-overview")} helperContent={t("global.account-overview-help")}>
                    <div style={{ padding: "20px 0px" }}>
                        <Row align="bottom">
                            <Col>
                                <ProfileImg type={Roles.Influencer} alt="profil" width={150} />
                            </Col>
                            <Col style={{ padding: "8px 0px 8px 20px" }}>
                                <h2 style={{ color: "#9254de", fontWeight: "bold" }}>
                                    {profileData?.name} {profileData?.surname}
                                </h2>
                                <p>
                                    <span>
                                        {profileData?.categories &&
                                            profileData?.categories.length > 0 &&
                                            profileData?.categories.map((c: any) => {
                                                return c.isPublic ? <span>{"#" + c.name + " "}</span> : null;
                                            })}
                                    </span>
                                    {" / " + profileData?.country}
                                </p>
                                <p>{profileData?.createdOn}</p>
                            </Col>
                        </Row>
                    </div>
                    <hr style={{ borderTop: "1px solid #952AE7" }} />
                    <Row>
                        <Col xs={24} sm={24} md={10} span={10}>
                            <Row style={{ marginBottom: "20px", display: "flex", flexDirection: "column" }}>
                                <h3 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("global.contact-number")}</h3>
                                <p>{profileData?.phone}</p>
                            </Row>
                            <Row style={{ display: "flex", flexDirection: "column" }}>
                                <h3 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("global.contact-email")}</h3>
                                <p>{profileData?.email}</p>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <h3 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("global.about-phenomenon")}</h3>
                            <p>{profileData?.biography}</p>
                        </Col>
                        <Row style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <Link to={{ pathname: "/profile/edit", state: profileData }}>
                                <Button type="ghost">{t("global.profileEdit")}</Button>
                            </Link>
                        </Row>
                    </Row>
                </PageContainer>
            </Col>
            <Col xs={24} sm={24} md={12} span={12}>
                <Col>
                    <PageContainer
                        pageTitle={t("global.platforms", "Platformlar")}
                        helperContent={t("global.influencer.profilepage.helper-content.platforms-info")}
                    >
                        <Row gutter={[20, 20]}>
                            {profileData?.socialMedias.map((item: any) => (
                                <Col xs={24} sm={24} md={8} span={8} style={{ textAlign: "center" }}>
                                    <Row
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            flexWrap: "nowrap",
                                        }}
                                    >
                                        <img alt={"social"} src={`/images/social-media-icon/${item.socialMedia.toUpperCase()}36Px.svg`} />
                                        <Col style={{ fontSize: "0.85em" }}>
                                            <a href={item.link} target="_blank" rel="noreferrer" style={{ color: "#9254de" }}>
                                                {"  "}@{item.username.toLowerCase()}
                                            </a>
                                            <p>
                                                {item.isApproved
                                                    ? calculateFollowersString(item.followers)
                                                    : calculateFollowersString(item.pendingApprovalFollowers)}{" "}
                                                {!item.isApproved ? (
                                                    <Tooltip placement="top" title={t("global.pending-approve")}>
                                                        <ClockCircleOutlined />
                                                    </Tooltip>
                                                ) : null}{" "}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                        <Row style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <Link to={{ pathname: "/profile/editPlatform", state: profileData?.socialMedias }}>
                                <Button type="ghost">{t("global.add-platform")}</Button>
                            </Link>
                        </Row>
                    </PageContainer>
                </Col>
                <Col style={{ marginTop: "30px" }}>
                    <PageContainer
                        pageTitle={t("global.payment-information")}
                        helperContent={t("global.influencer.profilepage.helper-content.payment-info")}
                    >
                        <Row style={{ display: "flex", flexDirection: "column" }}>
                            {profileData?.billingDetail !== undefined && profileData?.billingDetail !== null && (
                                <>
                                    <h3 style={{ fontWeight: "bold" }}>{t("global.payment-information")}</h3>
                                    <p>
                                        {profileData.billingDetail.hasExceptionLicence ? (
                                            <span>{t("global.bill-exception-licence")}</span>
                                        ) : profileData.billingDetail.isBillActive ? (
                                            <span>{t("global.invoice")}</span>
                                        ) : (
                                            <span>{t("global.cantinvoice")}</span>
                                        )}
                                    </p>
                                    <p>
                                        {profileData?.billingDetail && profileData?.billingDetail.isBillActive ? (
                                            <span>
                                                {profileData?.billingDetail?.companyName} -{" "}
                                                {t("global.company-type-" + profileData.billingDetail?.companyType.toString())}
                                            </span>
                                        ) : (
                                            <div>
                                                <span>
                                                    {profileData?.billingDetail?.fullName}- {profileData?.billingDetail?.identityNumber}
                                                </span>
                                                <br />
                                                <span>{profileData?.billingDetail?.address}</span>
                                            </div>
                                        )}
                                    </p>
                                    <p>
                                        {profileData?.billingDetail && profileData.billingDetail.isBillActive ? (
                                            <span>
                                                {profileData?.billingDetail?.bankName} - {profileData?.billingDetail?.iban}
                                            </span>
                                        ) : (
                                            <div>
                                                <span>
                                                    {profileData?.billingDetail?.bankName} - {profileData?.billingDetail?.iban}
                                                </span>{" "}
                                                <br />
                                            </div>
                                        )}
                                    </p>
                                </>
                            )}
                        </Row>
                        <Row style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}>
                            <Link to={{ pathname: "/profile/editPayment", state: profileData }}>
                                <Button type="ghost">
                                    {profileData?.billingDetail ? (
                                        <span>{t("global.payment-information-update")}</span>
                                    ) : (
                                        <span>{t("global.payment-information-add")}</span>
                                    )}
                                </Button>
                            </Link>
                        </Row>
                    </PageContainer>
                </Col>
            </Col>
        </Row>
    );
};
