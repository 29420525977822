const countries = [
    {
        id: "bc3f082e-1975-4296-9e27-35bb1a44e711",
        value: 1,
        text: "Afghanistan",
        dialCode: "93",
        code: "AF",
    },
    {
        id: "070348b2-6622-46ef-96b8-d6c22ead4980",
        value: 2,
        text: "Albania",
        dialCode: "355",
        code: "AL",
    },
    {
        id: "b6be66d2-1c95-4bbe-a941-e72b031c3331",
        value: 3,
        text: "Algeria",
        dialCode: "213",
        code: "DZ",
    },
    {
        id: "a9c8e047-0218-4949-a7c1-e520b9a14774",
        value: 4,
        name: "AmericanSamoa",
        dialCode: "1684",
        code: "AS",
    },
    {
        id: "64806a3e-81de-4a4c-94cf-6dac8feabdf4",
        value: 5,
        text: "Andorra",
        dialCode: "376",
        code: "AD",
    },
    {
        id: "a14608b0-6ace-4fa3-a3ef-243c7c1ab3ed",
        value: 6,
        text: "Angola",
        dialCode: "244",
        code: "AO",
    },
    {
        id: "be5c2bee-e884-4cc6-a6df-97d90fd7f3f1",
        value: 7,
        text: "Anguilla",
        dialCode: "1264",
        code: "AI",
    },
    {
        id: "0f180ac7-30ae-4d0a-a960-944bb24eb824",
        value: 8,
        text: "Antarctica",
        dialCode: "672",
        code: "AQ",
    },
    {
        id: "9ee18280-9d2e-4fee-a1a5-25f5460b0782",
        value: 9,
        text: "Antigua and Barbuda",
        dialCode: "1268",
        code: "AG",
    },
    {
        id: "a79d6b53-191a-4521-aefb-4b8e818d17ed",
        value: 10,
        text: "Argentina",
        dialCode: "54",
        code: "AR",
    },
    {
        id: "7e6308c4-9232-48dd-ad5b-834556ff6a7e",
        value: 11,
        text: "Armenia",
        dialCode: "374",
        code: "AM",
    },
    {
        id: "22b72740-8ad1-4642-bacf-f31fd4f1b54b",
        value: 12,
        text: "Aruba",
        dialCode: "297",
        code: "AW",
    },
    {
        id: "854f844a-4342-4bc3-b20d-d79188a95f6c",
        value: 13,
        text: "Australia",
        dialCode: "61",
        code: "AU",
    },
    {
        id: "33d0321a-93e5-4949-99b8-2dda55754b47",
        value: 14,
        text: "Austria",
        dialCode: "43",
        code: "AT",
    },
    {
        id: "61a444e6-79a2-40bc-9d38-4f2938ba5d55",
        value: 15,
        text: "Azerbaijan",
        dialCode: "994",
        code: "AZ",
    },
    {
        id: "bae93f2a-95b6-427a-8fee-a8f24a879055",
        value: 16,
        text: "Bahamas (the)",
        dialCode: "1242",
        code: "BS",
    },
    {
        id: "2917e5a9-348f-48f3-a5ef-de188e2dc349",
        value: 17,
        text: "Bahrain",
        dialCode: "973",
        code: "BH",
    },
    {
        id: "ca105c23-ba02-4800-bfb7-114f14a13361",
        value: 18,
        text: "Bangladesh",
        dialCode: "880",
        code: "BD",
    },
    {
        id: "a44ca9eb-28ea-48a9-a843-f2184f668bde",
        value: 19,
        text: "Barbados",
        dialCode: "1246",
        code: "BB",
    },
    {
        id: "385f33b5-d070-449a-a4ea-0b681b98506b",
        value: 20,
        text: "Belarus",
        dialCode: "375",
        code: "BY",
    },
    {
        id: "1b27d254-2633-42f5-9590-9a5256bf2c52",
        value: 21,
        text: "Belgium",
        dialCode: "32",
        code: "BE",
    },
    {
        id: "2ca0d7a8-183d-436a-a7cd-3c3a0fb2c2f1",
        value: 22,
        text: "Belize",
        dialCode: "501",
        code: "BZ",
    },
    {
        id: "d4e5d115-51e9-4a59-b066-677164ebd88f",
        value: 23,
        text: "Benin",
        dialCode: "229",
        code: "BJ",
    },
    {
        id: "a28ff9fe-ca16-492a-b93c-e2076aa9aa41",
        value: 24,
        text: "Bermuda",
        dialCode: "1441",
        code: "BM",
    },
    {
        id: "d3c36b20-567c-4201-a0a3-5559b2a984cd",
        value: 25,
        text: "Bhutan",
        dialCode: "975",
        code: "BT",
    },
    {
        id: "e2a5679d-cec6-4fd3-8e00-657407923c48",
        value: 26,
        text: "Bolivia (Plurinational State of)",
        dialCode: "591",
        code: "BO",
    },
    {
        id: "b56c6d65-2c20-4a4d-b652-d11e7c62b154",
        value: 27,
        text: "Bonaire, Sint Eustatius and Saba",
        dialCode: "599",
        code: "BQ",
    },
    {
        id: "854ac30a-fb1f-4cda-898b-62b39baae5f6",
        value: 28,
        text: "Bosnia and Herzegovina",
        dialCode: "387",
        code: "BA",
    },
    {
        id: "116d68c0-3649-4473-88af-1cdfe7a5a850",
        value: 29,
        text: "Botswana",
        dialCode: "267",
        code: "BW",
    },
    {
        id: "f04617a3-648a-4ba3-bcd5-f542599417cc",
        value: 30,
        text: "Bouvet Island",
        dialCode: "892",
        code: "BV",
    },
    {
        id: "6bb7a56c-80fe-44b7-b02e-f6832c9dfbe8",
        value: 31,
        text: "Brazil",
        dialCode: "55",
        code: "BR",
    },
    {
        id: "d7ff465f-face-4f23-ba8c-65d1e01a14e9",
        value: 32,
        text: "British Indian Ocean Territory (the)",
        dialCode: "246",
        code: "IO",
    },
    {
        id: "4e229329-3a9a-4284-a0d6-9826dd3d303d",
        value: 33,
        text: "Brunei Darussalam",
        dialCode: "673",
        code: "BN",
    },
    {
        id: "3aa4220a-fca1-4084-8728-1b88bc022c96",
        value: 34,
        text: "Bulgaria",
        dialCode: "359",
        code: "BG",
    },
    {
        id: "9ac59668-efc6-4845-bc6b-98512b72226b",
        value: 35,
        text: "Burkina Faso",
        dialCode: "226",
        code: "BF",
    },
    {
        id: "93a6182f-9752-4de1-9485-0b239dff2b50",
        value: 36,
        text: "Burundi",
        dialCode: "257",
        code: "BI",
    },
    {
        id: "ebc0a7b1-5324-4da7-805b-d4ab093c8947",
        value: 37,
        text: "Cabo Verde",
        dialCode: "238",
        code: "CV",
    },
    {
        id: "83699fab-b6cb-41ac-9ccc-3a37692463c2",
        value: 38,
        text: "Cambodia",
        dialCode: "855",
        code: "KH",
    },
    {
        id: "68ae464e-f091-46e2-a658-c8e28be7ca9a",
        value: 39,
        text: "Cameroon",
        dialCode: "237",
        code: "CM",
    },
    {
        id: "d9fef37c-daac-45c4-aab5-1610d758560f",
        value: 40,
        text: "Canada",
        dialCode: "1",
        code: "CA",
    },
    {
        id: "21dfe669-a2c3-4392-bf99-d5c1ab2f6d09",
        value: 41,
        text: "Cayman Islands (the)",
        dialCode: " 345",
        code: "KY",
    },
    {
        id: "e8ca0156-c812-4cac-9837-3de42ab19e87",
        value: 42,
        text: "Central African Republic (the)",
        dialCode: "236",
        code: "CF",
    },
    {
        id: "276f166b-e31c-4f75-8b28-a5898e35aa07",
        value: 43,
        text: "Chad",
        dialCode: "235",
        code: "TD",
    },
    {
        id: "a81a2ff9-cef7-4f5f-896d-908d3568e5dd",
        value: 44,
        text: "Chile",
        dialCode: "56",
        code: "CL",
    },
    {
        id: "543148a8-340c-425d-832b-201cd33474b8",
        value: 45,
        text: "China",
        dialCode: "86",
        code: "CN",
    },
    {
        id: "dedfcff9-17cc-42c2-a723-e567c82584bc",
        value: 46,
        text: "Christmas Island",
        dialCode: "61",
        code: "CX",
    },
    {
        id: "48f67e7a-2873-4236-b6be-6a90333bc59e",
        value: 47,
        text: "Cocos (Keeling) Islands (the)",
        dialCode: "61",
        code: "CC",
    },
    {
        id: "3a69f05c-b0cf-4d8c-aa6a-fb7445ac09b7",
        value: 48,
        text: "Colombia",
        dialCode: "57",
        code: "CO",
    },
    {
        id: "cb6512a8-8a02-46bf-95ab-397473b64d7d",
        value: 49,
        text: "Comoros (the)",
        dialCode: "269",
        code: "KM",
    },
    {
        id: "59cf101c-acc0-4a05-b65a-0a99f0b29b93",
        value: 50,
        text: "Congo (the Democratic Republic of the)",
        dialCode: "243",
        code: "CD",
    },
    {
        id: "cd0d1b34-3b5a-4345-8431-b4a6e2f44cd8",
        value: 51,
        text: "Congo (the)",
        dialCode: "242",
        code: "CG",
    },
    {
        id: "7312187b-01d7-42e4-a498-9c121b0a7908",
        value: 52,
        text: "Cook Islands (the)",
        dialCode: "682",
        code: "CK",
    },
    {
        id: "bfa2fa09-f228-41d1-b92d-d1007a1dc57e",
        value: 53,
        text: "Costa Rica",
        dialCode: "506",
        code: "CR",
    },
    {
        id: "6cb84014-1e61-4e32-bb55-a1948b5144df",
        value: 54,
        text: "Croatia",
        dialCode: "385",
        code: "HR",
    },
    {
        id: "8efd05b5-9c54-4e32-8517-5244a8442eeb",
        value: 55,
        text: "Cuba",
        dialCode: "53",
        code: "CU",
    },
    {
        id: "8c4e405c-eaee-4b08-a3ad-97eaa4054e89",
        value: 56,
        text: "Cura?ao",
        dialCode: "599",
        code: "CW",
    },
    {
        id: "22cdf512-9800-4f23-bb17-f8724edd0ffb",
        value: 57,
        text: "Cyprus",
        dialCode: "357",
        code: "CY",
    },
    {
        id: "f102e8ed-744a-4e10-9453-558b3dc26709",
        value: 58,
        text: "Czechia",
        dialCode: "420",
        code: "CZ",
    },
    {
        id: "ed8ad743-dfd6-4cdd-93fb-aa2b5e10d5c6",
        value: 59,
        text: "C?te d'Ivoire",
        dialCode: "225",
        code: "CI",
    },
    {
        id: "741c65ab-f828-4617-94aa-1268c2e4495f",
        value: 60,
        text: "Denmark",
        dialCode: "45",
        code: "DK",
    },
    {
        id: "58012ee4-1f9f-4bf6-899b-d3916f74d10b",
        value: 61,
        text: "Djibouti",
        dialCode: "253",
        code: "DJ",
    },
    {
        id: "f31de52b-530a-4477-957f-1988cc58d731",
        value: 62,
        text: "Dominica",
        dialCode: "1767",
        code: "DM",
    },
    {
        id: "0042c324-7a50-4065-870c-f65b84fbb664",
        value: 63,
        text: "Dominican Republic (the)",
        dialCode: "1849",
        code: "DO",
    },
    {
        id: "6b3bb98e-62a4-42ea-a695-610d5c3b5bf4",
        value: 64,
        text: "Ecuador",
        dialCode: "593",
        code: "EC",
    },
    {
        id: "27a55fa0-986f-4d4b-b28f-203e613bc504",
        value: 65,
        text: "Egypt",
        dialCode: "20",
        code: "EG",
    },
    {
        id: "b76a555c-2dcd-45b6-9b24-213db210f59d",
        value: 66,
        text: "El Salvador",
        dialCode: "503",
        code: "SV",
    },
    {
        id: "9bf0845a-9730-4aec-b867-a954ec5542b3",
        value: 67,
        text: "Equatorial Guinea",
        dialCode: "240",
        code: "GQ",
    },
    {
        id: "bb70ffb0-e506-4a74-9b69-2db825862475",
        value: 68,
        text: "Eritrea",
        dialCode: "291",
        code: "ER",
    },
    {
        id: "203d9043-3bc8-4875-bfef-aba604a96d66",
        value: 69,
        text: "Estonia",
        dialCode: "372",
        code: "EE",
    },
    {
        id: "0c90ae13-524d-4e1f-9b16-c9c15a52ae60",
        value: 70,
        text: "Eswatini",
        dialCode: "268",
        code: "SZ",
    },
    {
        id: "cfd2aea2-a570-4670-b472-b507d338c678",
        value: 71,
        text: "Ethiopia",
        dialCode: "251",
        code: "ET",
    },
    {
        id: "84be70c8-7006-4fcc-943c-3f6b52566a13",
        value: 72,
        text: "Falkland Islands (the) [Malvinas]",
        dialCode: "500",
        code: "FK",
    },
    {
        id: "be9ac991-2419-483d-b57c-fc1222b8e068",
        value: 73,
        text: "Faroe Islands (the)",
        dialCode: "298",
        code: "FO",
    },
    {
        id: "2b826be7-c45d-4ff5-8126-bfc781ed90f2",
        value: 74,
        text: "Fiji",
        dialCode: "679",
        code: "FJ",
    },
    {
        id: "f493c968-93bf-42fd-ba2d-424559a38a30",
        value: 75,
        text: "Finland",
        dialCode: "358",
        code: "FI",
    },
    {
        id: "5228acb7-0557-44d9-8aa3-596aa9f5d391",
        value: 76,
        text: "France",
        dialCode: "33",
        code: "FR",
    },
    {
        id: "2eeb29fd-f87c-411f-a128-bcbc9ce20183",
        value: 77,
        text: "French Guiana",
        dialCode: "594",
        code: "GF",
    },
    {
        id: "34e51239-0e3d-4842-858f-155e8ac2bcc0",
        value: 78,
        text: "French Polynesia",
        dialCode: "689",
        code: "PF",
    },
    {
        id: "885eaea3-9c87-4a60-bd13-0afec238a9ac",
        value: 79,
        text: "French Southern Territories (the)",
        dialCode: "262",
        code: "TF",
    },
    {
        id: "2d6d0acc-b70f-4c19-a6a7-862e164b9cce",
        value: 80,
        text: "Gabon",
        dialCode: "241",
        code: "GA",
    },
    {
        id: "6eef6c55-2763-42fc-a3b6-26b2d89ac13c",
        value: 81,
        text: "Gambia (the)",
        dialCode: "220",
        code: "GM",
    },
    {
        id: "ca0bb1a7-7648-4a33-ad5c-5ec453ee064f",
        value: 82,
        text: "Georgia",
        dialCode: "995",
        code: "GE",
    },
    {
        id: "fe85a037-76fe-4375-8536-2e8d2fe58410",
        value: 83,
        text: "Germany",
        dialCode: "49",
        code: "DE",
    },
    {
        id: "b182c22d-2998-4210-b543-c8522fd8c29a",
        value: 84,
        text: "Ghana",
        dialCode: "233",
        code: "GH",
    },
    {
        id: "a35e0f97-32e4-4b71-8d20-fadf4232ff8b",
        value: 85,
        text: "Gibraltar",
        dialCode: "350",
        code: "GI",
    },
    {
        id: "dc519d7f-df56-43d6-81e9-27d986134e0b",
        value: 86,
        text: "Greece",
        dialCode: "30",
        code: "GR",
    },
    {
        id: "f982d7c7-c7f6-48da-aa70-d7f79dc47167",
        value: 87,
        text: "Greenland",
        dialCode: "299",
        code: "GL",
    },
    {
        id: "d5f226c5-d951-44c0-8c3f-6faa1ff463d7",
        value: 88,
        text: "Grenada",
        dialCode: "1473",
        code: "GD",
    },
    {
        id: "fbd54b04-9cc3-4cb6-8cd6-f64cb98c5dae",
        value: 89,
        text: "Guadeloupe",
        dialCode: "590",
        code: "GP",
    },
    {
        id: "60f363fa-dc17-4196-a623-d9c36aeb9347",
        value: 90,
        text: "Guam",
        dialCode: "1671",
        code: "GU",
    },
    {
        id: "7ddada34-5323-4dc9-95d5-0fb849e0cf4d",
        value: 91,
        text: "Guatemala",
        dialCode: "502",
        code: "GT",
    },
    {
        id: "1cf404e6-98d9-49ff-94e8-13027b447615",
        value: 92,
        text: "Guernsey",
        dialCode: "44",
        code: "GG",
    },
    {
        id: "c0f7ba92-1a81-4363-b9a7-acfc7929e872",
        value: 93,
        text: "Guinea",
        dialCode: "224",
        code: "GN",
    },
    {
        id: "dc6fb15c-cd77-4fcd-9960-60e134e68b09",
        value: 94,
        text: "Guinea-Bissau",
        dialCode: "245",
        code: "GW",
    },
    {
        id: "005af32d-f39b-4f8a-bfed-0ff46afa6c78",
        value: 95,
        text: "Guyana",
        dialCode: "595",
        code: "GY",
    },
    {
        id: "c892969c-bc9a-4545-88a4-a9e51e9e4cea",
        value: 96,
        text: "Haiti",
        dialCode: "509",
        code: "HT",
    },
    {
        id: "44a9268f-7b54-40b2-891c-d3995fb009bc",
        value: 97,
        text: "Heard Island and McDonald Islands",
        dialCode: "672",
        code: "HM",
    },
    {
        id: "ff0f73ce-6dd5-4c3e-b59d-0dcc9744e9dd",
        value: 98,
        text: "Holy See (the)",
        dialCode: "379",
        code: "VA",
    },
    {
        id: "0a1b960b-521c-4a02-b449-0f03420437bd",
        value: 99,
        text: "Honduras",
        dialCode: "504",
        code: "HN",
    },
    {
        id: "f000fff1-c6e8-42bc-a359-88fd3079bf2b",
        value: 100,
        text: "Hong Kong",
        dialCode: "852",
        code: "HK",
    },
    {
        id: "7657ec3d-5050-407c-be61-233a3f56d37c",
        value: 101,
        text: "Hungary",
        dialCode: "36",
        code: "HU",
    },
    {
        id: "c6a75a46-28be-4db7-a1dd-5fc60c00aafe",
        value: 102,
        text: "Iceland",
        dialCode: "354",
        code: "IS",
    },
    {
        id: "f4bc416e-4da4-4bbe-9c9a-de3a3099fe66",
        value: 103,
        text: "India",
        dialCode: "91",
        code: "IN",
    },
    {
        id: "bbb585c2-bec9-4ddc-a06c-2c5742c4fbec",
        value: 104,
        text: "Indonesia",
        dialCode: "62",
        code: "ID",
    },
    {
        id: "a97bb988-1a0d-42e9-8e5b-386b270a76a7",
        value: 105,
        text: "Iran (Islamic Republic of)",
        dialCode: "98",
        code: "IR",
    },
    {
        id: "986c137b-f016-4bd9-9148-82bff66c5180",
        value: 106,
        text: "Iraq",
        dialCode: "964",
        code: "IQ",
    },
    {
        id: "bd5251c9-2d0b-4dcb-a3c7-1eda7e628bd9",
        value: 107,
        text: "Ireland",
        dialCode: "353",
        code: "IE",
    },
    {
        id: "ef25f2ff-d77b-42af-8851-fdb4c5313bdd",
        value: 108,
        text: "Isle of Man",
        dialCode: "44",
        code: "IM",
    },
    {
        id: "ff9fd31f-1e75-4580-a966-ce772293b08b",
        value: 109,
        text: "Israel",
        dialCode: "972",
        code: "IL",
    },
    {
        id: "ab3a368c-9d06-4612-9233-24f5ff5b7ccc",
        value: 110,
        text: "Italy",
        dialCode: "39",
        code: "IT",
    },
    {
        id: "75780234-b5f1-4c10-be95-5bd955bea9e0",
        value: 111,
        text: "Jamaica",
        dialCode: "1876",
        code: "JM",
    },
    {
        id: "c0b982bc-8bae-4a65-a066-84a037a37fc1",
        value: 112,
        text: "Japan",
        dialCode: "81",
        code: "JP",
    },
    {
        id: "2e54025d-2560-4b84-8f5e-30ab01647315",
        value: 113,
        text: "Jersey",
        dialCode: "44",
        code: "JE",
    },
    {
        id: "cea467e5-5e77-422f-adf3-887b10105ed8",
        value: 114,
        text: "Jordan",
        dialCode: "962",
        code: "JO",
    },
    {
        id: "cb2ea2b2-d42f-4eb2-8ee6-7cbc6396bc8b",
        value: 115,
        text: "Kazakhstan",
        dialCode: "77",
        code: "KZ",
    },
    {
        id: "e457f0ad-06d0-4ef2-84c0-15a13d315029",
        value: 116,
        text: "Kenya",
        dialCode: "254",
        code: "KE",
    },
    {
        id: "e8d4439d-9638-44d0-aa95-97549d354e5c",
        value: 117,
        text: "Kiribati",
        dialCode: "686",
        code: "KI",
    },
    {
        id: "94944ddf-f158-41fa-b66f-bdbdc48b508d",
        value: 118,
        text: "Korea (the Democratic People's Republic of)",
        dialCode: "850",
        code: "KP",
    },
    {
        id: "ee4e2f51-0920-4966-b649-45d7a120b86c",
        value: 119,
        text: "Korea (the Republic of)",
        dialCode: "82",
        code: "KR",
    },
    {
        id: "71530ec7-10d9-4365-b20f-f8f44efa7630",
        value: 120,
        text: "Kuwait",
        dialCode: "965",
        code: "KW",
    },
    {
        id: "86849403-b936-478f-a75f-5abbabb7071c",
        value: 121,
        text: "Kyrgyzstan",
        dialCode: "996",
        code: "KG",
    },
    {
        id: "d8b46f8a-d5a3-476b-82b8-62d48dfa59f5",
        value: 122,
        text: "Lao People's Democratic Republic (the)",
        dialCode: "856",
        code: "LA",
    },
    {
        id: "75c158a1-d4aa-405d-8ee9-0b39171b43a8",
        value: 123,
        text: "Latvia",
        dialCode: "371",
        code: "LV",
    },
    {
        id: "2df678f2-41d6-4861-99a8-3e5b7ed4aea8",
        value: 124,
        text: "Lebanon",
        dialCode: "961",
        code: "LB",
    },
    {
        id: "1494c912-269a-47fa-8e34-fdc8d05b3309",
        value: 125,
        text: "Lesotho",
        dialCode: "266",
        code: "LS",
    },
    {
        id: "d088d598-d4db-48f7-b388-2ab275717999",
        value: 126,
        text: "Liberia",
        dialCode: "231",
        code: "LR",
    },
    {
        id: "c2dff3b8-a4b7-4410-b057-c7b08bcb903f",
        value: 127,
        text: "Libya",
        dialCode: "218",
        code: "LY",
    },
    {
        id: "065bb362-dc90-4b5b-8635-1b6cad528db6",
        value: 128,
        text: "Liechtenstein",
        dialCode: "423",
        code: "LI",
    },
    {
        id: "786e2fe8-466b-44d5-b18a-bd83db341c3e",
        value: 129,
        text: "Lithuania",
        dialCode: "370",
        code: "LT",
    },
    {
        id: "b3f4804c-5675-4a78-9b6e-1a636d5fe546",
        value: 130,
        text: "Luxembourg",
        dialCode: "352",
        code: "LU",
    },
    {
        id: "a1feac16-c5be-4a7f-8e5e-77b679d35a97",
        value: 131,
        text: "Macao",
        dialCode: "853",
        code: "MO",
    },
    {
        id: "a90d6fdc-f464-46a2-b32b-47eb9da42e71",
        value: 132,
        text: "Madagascar",
        dialCode: "261",
        code: "MG",
    },
    {
        id: "8d32ff23-1d1b-4cb0-86c0-02cd661e86f3",
        value: 133,
        text: "Malawi",
        dialCode: "265",
        code: "MW",
    },
    {
        id: "1c166f3a-19f9-48cd-bef0-0ba119e511dd",
        value: 134,
        text: "Malaysia",
        dialCode: "60",
        code: "MY",
    },
    {
        id: "15228790-82c6-4cd5-ac1a-8a375a7444ee",
        value: 135,
        text: "Maldives",
        dialCode: "960",
        code: "MV",
    },
    {
        id: "a8a155e3-a2ad-48ee-8497-ab25493934ba",
        value: 136,
        text: "Mali",
        dialCode: "223",
        code: "ML",
    },
    {
        id: "ffb00c6f-e4e5-4a71-a4cf-04cbf57c5919",
        value: 137,
        text: "Malta",
        dialCode: "356",
        code: "MT",
    },
    {
        id: "7ae4caf8-63d9-4462-b4ce-01029456700f",
        value: 138,
        text: "Marshall Islands (the)",
        dialCode: "692",
        code: "MH",
    },
    {
        id: "f61f1afc-930b-490e-821e-8bc8888e21dd",
        value: 139,
        text: "Martinique",
        dialCode: "596",
        code: "MQ",
    },
    {
        id: "9ac76a03-16db-4237-bade-0a165ccf5f47",
        value: 140,
        text: "Mauritania",
        dialCode: "222",
        code: "MR",
    },
    {
        id: "42b06f38-1333-49a4-9271-9a6ad32f6f03",
        value: 141,
        text: "Mauritius",
        dialCode: "230",
        code: "MU",
    },
    {
        id: "8f52005e-3c6e-4fd9-b575-e94ee3b7b6a7",
        value: 142,
        text: "Mayotte",
        dialCode: "262",
        code: "YT",
    },
    {
        id: "cd54b478-2bab-4bf0-a3c8-cd4d0bfe38de",
        value: 143,
        text: "Mexico",
        dialCode: "52",
        code: "MX",
    },
    {
        id: "34605c1f-f819-41f9-b4e6-0cebed03d90a",
        value: 144,
        text: "Micronesia (Federated States of)",
        dialCode: "691",
        code: "FM",
    },
    {
        id: "f3f4773f-5031-4dde-b336-cd14aa026fac",
        value: 145,
        text: "Moldova (the Republic of)",
        dialCode: "373",
        code: "MD",
    },
    {
        id: "eae0daa1-c16b-436f-82e3-f4aa770224a1",
        value: 146,
        text: "Monaco",
        dialCode: "377",
        code: "MC",
    },
    {
        id: "cb035ae2-cdfb-429d-af9a-c7c4bdc6950b",
        value: 147,
        text: "Mongolia",
        dialCode: "976",
        code: "MN",
    },
    {
        id: "5c717723-f67a-49a6-b4fe-77234af5f22c",
        value: 148,
        text: "Montenegro",
        dialCode: "382",
        code: "ME",
    },
    {
        id: "b66efbdc-7437-4432-ac40-cfd5058ecfda",
        value: 149,
        text: "Montserrat",
        dialCode: "1664",
        code: "MS",
    },
    {
        id: "cd32999b-f01d-4381-8d14-bf06426c872f",
        value: 150,
        text: "Morocco",
        dialCode: "212",
        code: "MA",
    },
    {
        id: "98ddc294-daba-4f33-a8b6-f3a3fb36f4b9",
        value: 151,
        text: "Mozambique",
        dialCode: "258",
        code: "MZ",
    },
    {
        id: "372948db-fc15-4582-9635-6a6b2e3f3f3e",
        value: 152,
        text: "Myanmar",
        dialCode: "95",
        code: "MM",
    },
    {
        id: "dfff19e2-cae0-4f31-a294-82b4bf441f5b",
        value: 153,
        text: "Namibia",
        dialCode: "264",
        code: "NA",
    },
    {
        id: "485759de-86cb-41cc-b6b9-2a4485fc14bc",
        value: 154,
        text: "Nauru",
        dialCode: "674",
        code: "NR",
    },
    {
        id: "d5852250-0ab1-4ecb-8fa3-3ee33aab4621",
        value: 155,
        text: "Nepal",
        dialCode: "977",
        code: "NP",
    },
    {
        id: "2cf95711-2fc1-45ea-987b-aaee98a6277c",
        value: 156,
        text: "Netherlands (the)",
        dialCode: "31",
        code: "NL",
    },
    {
        id: "f675597b-1b86-4ad7-8890-993ed179962f",
        value: 157,
        text: "New Caledonia",
        dialCode: "687",
        code: "NC",
    },
    {
        id: "10c5e919-b779-4151-b8fe-3fd779f214a8",
        value: 158,
        text: "New Zealand",
        dialCode: "64",
        code: "NZ",
    },
    {
        id: "72fdb908-652e-45d3-b6ce-db6171777ab5",
        value: 159,
        text: "Nicaragua",
        dialCode: "505",
        code: "NI",
    },
    {
        id: "f1ef64c7-90c1-46b8-bd93-141db50eb0db",
        value: 160,
        text: "Niger (the)",
        dialCode: "227",
        code: "NE",
    },
    {
        id: "12556869-ac2a-463d-9711-1d95d5070079",
        value: 161,
        text: "Nigeria",
        dialCode: "234",
        code: "NG",
    },
    {
        id: "6bac83e0-f841-4c50-ac68-ee98f5d79c34",
        value: 162,
        text: "Niue",
        dialCode: "683",
        code: "NU",
    },
    {
        id: "e3cffcf7-ebaf-4ea4-b8c2-2b139696e674",
        value: 163,
        text: "Norfolk Island",
        dialCode: "672",
        code: "NF",
    },
    {
        id: "a52896f6-9bf3-4a0a-aab8-6470ae65989b",
        value: 164,
        text: "Northern Mariana Islands (the)",
        dialCode: "1670",
        code: "MP",
    },
    {
        id: "729d5f80-4586-493f-aee2-70bfeef40045",
        value: 165,
        text: "Norway",
        dialCode: "47",
        code: "NO",
    },
    {
        id: "11e0f43a-414e-4722-9e0e-1bd056f052e6",
        value: 166,
        text: "Oman",
        dialCode: "968",
        code: "OM",
    },
    {
        id: "79016887-84fb-4dfe-a477-096f818df1c4",
        value: 167,
        text: "Pakistan",
        dialCode: "92",
        code: "PK",
    },
    {
        id: "0f13b734-66cd-453d-a062-9a751b885781",
        value: 168,
        text: "Palau",
        dialCode: "680",
        code: "PW",
    },
    {
        id: "de900f50-db51-458e-b225-13c047c9156d",
        value: 169,
        text: "Palestine, State of",
        dialCode: "970",
        code: "PS",
    },
    {
        id: "10c205fb-1c75-4932-b719-89d25973197a",
        value: 170,
        text: "Panama",
        dialCode: "507",
        code: "PA",
    },
    {
        id: "f6dc33c3-98fc-4bb2-8ef2-ef29783dcd56",
        value: 171,
        text: "Papua New Guinea",
        dialCode: "675",
        code: "PG",
    },
    {
        id: "dbffdf1b-da1c-4d6d-99b7-71b567550d50",
        value: 172,
        text: "Paraguay",
        dialCode: "595",
        code: "PY",
    },
    {
        id: "140d9017-510e-41f2-9068-12752ac82e4c",
        value: 173,
        text: "Peru",
        dialCode: "51",
        code: "PE",
    },
    {
        id: "8118f133-54cc-4823-83b5-829f36ef99d0",
        value: 174,
        text: "Philippines (the)",
        dialCode: "63",
        code: "PH",
    },
    {
        id: "e1b31859-540f-464a-bba6-c5679cf45857",
        value: 175,
        text: "Pitcairn",
        dialCode: "872",
        code: "PN",
    },
    {
        id: "bc09c423-8bff-4c8a-9fea-72118aec27f2",
        value: 176,
        text: "Poland",
        dialCode: "48",
        code: "PL",
    },
    {
        id: "9a77a1b7-eb8c-43b4-b877-44d154f9f7ec",
        value: 177,
        text: "Portugal",
        dialCode: "351",
        code: "PT",
    },
    {
        id: "97d32104-7b6b-496e-a684-bde843d917cd",
        value: 178,
        text: "Puerto Rico",
        dialCode: "1939",
        code: "PR",
    },
    {
        id: "b75e2862-827e-413a-aad9-2deb4584bf46",
        value: 179,
        text: "Qatar",
        dialCode: "974",
        code: "QA",
    },
    {
        id: "ba4dc862-3a99-48b4-b832-bff937f3e414",
        value: 180,
        text: "Republic of North Macedonia",
        dialCode: "389",
        code: "MK",
    },
    {
        id: "6f92edfb-55fa-4129-b24c-2d540fad9077",
        value: 181,
        text: "Romania",
        dialCode: "40",
        code: "RO",
    },
    {
        id: "55fa09ab-e9f4-47f1-88dc-f223d03c6ec3",
        value: 182,
        text: "Russian Federation (the)",
        dialCode: "7",
        code: "RU",
    },
    {
        id: "28a562c6-8300-467f-9cbe-bd219050086b",
        value: 183,
        text: "Rwanda",
        dialCode: "250",
        code: "RW",
    },
    {
        id: "3dddb027-ff49-43a4-982a-5b7b82d7189c",
        value: 184,
        text: "R?union",
        dialCode: "262",
        code: "RE",
    },
    {
        id: "f7ebb7d0-4f53-4dc2-9f11-ed04a2ae46a8",
        value: 185,
        text: "Saint Barth?lemy",
        dialCode: "590",
        code: "BL",
    },
    {
        id: "5d2daba5-4946-432a-a54c-fb316e623789",
        value: 186,
        text: "Saint Helena, Ascension and Tristan da Cunha",
        dialCode: "290",
        code: "SH",
    },
    {
        id: "49c81451-c8cf-40cb-a36f-bf3bab71bbbd",
        value: 187,
        text: "Saint Kitts and Nevis",
        dialCode: "1869",
        code: "KN",
    },
    {
        id: "c121d7ba-f8ff-4a80-8f39-fedb4891359d",
        value: 188,
        text: "Saint Lucia",
        dialCode: "1758",
        code: "LC",
    },
    {
        id: "c2336460-6b14-4ada-b2a0-c18c3c4760d7",
        value: 189,
        text: "Saint Martin (French part)",
        dialCode: "590",
        code: "MF",
    },
    {
        id: "1589302b-ce03-4a9d-9bb6-718721c7aef1",
        value: 190,
        text: "Saint Pierre and Miquelon",
        dialCode: "508",
        code: "PM",
    },
    {
        id: "84e7661f-060f-470e-a11c-cc53516bab71",
        value: 191,
        text: "Saint Vincent and the Grenadines",
        dialCode: "1784",
        code: "VC",
    },
    {
        id: "adc0c267-309a-4576-b841-ae591093a0ee",
        value: 192,
        text: "Samoa",
        dialCode: "685",
        code: "WS",
    },
    {
        id: "6eb48555-130c-46c5-bcb2-879bf4078eb0",
        value: 193,
        text: "San Marino",
        dialCode: "378",
        code: "SM",
    },
    {
        id: "f9c2a010-b6b5-49d1-9195-0db527b03a01",
        value: 194,
        text: "Sao Tome and Principe",
        dialCode: "239",
        code: "ST",
    },
    {
        id: "351bfb16-0fd2-4d5e-8c80-7aa58a748020",
        value: 195,
        text: "Saudi Arabia",
        dialCode: "966",
        code: "SA",
    },
    {
        id: "a63131b2-b144-4807-9beb-b5cb2ef03f6b",
        value: 196,
        text: "Senegal",
        dialCode: "221",
        code: "SN",
    },
    {
        id: "5d8bc8c2-e553-4131-91f2-fc40f0bd79b4",
        value: 197,
        text: "Serbia",
        dialCode: "381",
        code: "RS",
    },
    {
        id: "8c4be790-057f-408c-814c-72662c02b48c",
        value: 198,
        text: "Seychelles",
        dialCode: "248",
        code: "SC",
    },
    {
        id: "b8e93e00-9243-4917-a591-b248246c20e0",
        value: 199,
        text: "Sierra Leone",
        dialCode: "232",
        code: "SL",
    },
    {
        id: "bbd39946-f9c4-4ae8-b0df-b70e2b09b9b5",
        value: 200,
        text: "Singapore",
        dialCode: "65",
        code: "SG",
    },
    {
        id: "586064f4-ef95-4590-a9f3-43be472e3695",
        value: 201,
        text: "Sint Maarten (Dutch part)",
        dialCode: "721",
        code: "SX",
    },
    {
        id: "8fd2d643-9062-4ba5-949d-cbe3c3d2f4fc",
        value: 202,
        text: "Slovakia",
        dialCode: "421",
        code: "SK",
    },
    {
        id: "cf6acf0b-152b-4494-95b9-5f4567b78896",
        value: 203,
        text: "Slovenia",
        dialCode: "386",
        code: "SI",
    },
    {
        id: "c53e6702-6095-4b12-9fad-3190deade285",
        value: 204,
        text: "Solomon Islands",
        dialCode: "677",
        code: "SB",
    },
    {
        id: "a695e7f5-799d-4d27-bf93-0316b0cbb8be",
        value: 205,
        text: "Somalia",
        dialCode: "252",
        code: "SO",
    },
    {
        id: "b187b5fb-fe1b-45f7-bb29-01cae65689a1",
        value: 206,
        text: "South Africa",
        dialCode: "27",
        code: "ZA",
    },
    {
        id: "62768501-711e-4e1b-ab27-b15d783fc698",
        value: 207,
        text: "South Georgia and the South Sandwich Islands",
        dialCode: "500",
        code: "GS",
    },
    {
        id: "a7c27a7f-99f1-473c-b836-93ca7a0cbaa9",
        value: 208,
        text: "South Sudan",
        dialCode: "211",
        code: "SS",
    },
    {
        id: "89fe1020-86ba-4196-96aa-30498dd04e55",
        value: 209,
        text: "Spain",
        dialCode: "34",
        code: "ES",
    },
    {
        id: "3cba267b-07ea-41bb-a5b1-63ca427a6539",
        value: 210,
        text: "Sri Lanka",
        dialCode: "94",
        code: "LK",
    },
    {
        id: "e1c68e5c-a11e-4098-9fbb-63c665fe69ad",
        value: 211,
        text: "Sudan (the)",
        dialCode: "249",
        code: "SD",
    },
    {
        id: "930dd4e3-85de-47ce-9ac0-44d0251d13ae",
        value: 212,
        text: "Suriname",
        dialCode: "597",
        code: "SR",
    },
    {
        id: "d4e874ea-a4cf-41d4-b504-7fe8d4ecdcff",
        value: 213,
        text: "Svalbard and Jan Mayen",
        dialCode: "47",
        code: "SJ",
    },
    {
        id: "a0643588-020a-44c7-9bee-ae27f544c515",
        value: 214,
        text: "Sweden",
        dialCode: "46",
        code: "SE",
    },
    {
        id: "d2a69a4b-8245-4f76-917d-75e02753380d",
        value: 215,
        text: "Switzerland",
        dialCode: "41",
        code: "CH",
    },
    {
        id: "8ea1b9da-46d3-4fa5-b528-87cf82a181db",
        value: 216,
        text: "Syrian Arab Republic",
        dialCode: "963",
        code: "SY",
    },
    {
        id: "8fc0c5c3-e09c-4600-b06c-d0813099419c",
        value: 217,
        text: "Taiwan",
        dialCode: "886",
        code: "TW",
    },
    {
        id: "ad29a423-4d36-40a5-9901-c3cb3f8a1f7c",
        value: 218,
        text: "Tajikistan",
        dialCode: "992",
        code: "TJ",
    },
    {
        id: "65aa3859-dd83-4f93-82ad-6a692c683002",
        value: 219,
        text: "Tanzania, United Republic of",
        dialCode: "255",
        code: "TZA",
    },
    {
        id: "209f18e6-e749-4c13-b257-bd2744fa614a",
        value: 220,
        text: "Thailand",
        dialCode: "66",
        code: "TH",
    },
    {
        id: "6edbf8bb-f9e6-463b-8389-00055fd165cb",
        value: 221,
        text: "Timor-Leste",
        dialCode: "670",
        code: "TL",
    },
    {
        id: "c862050a-a256-4a73-9463-d238ed0d4de2",
        value: 222,
        text: "Togo",
        dialCode: "228",
        code: "TG",
    },
    {
        id: "a6237369-4ccb-4788-91f4-de793dbc8377",
        value: 223,
        text: "Tokelau",
        dialCode: "690",
        code: "TK",
    },
    {
        id: "1adbc3fb-d9d5-4b1b-9528-a26ce05525bf",
        value: 224,
        text: "Tonga",
        dialCode: "676",
        code: "TO",
    },
    {
        id: "9bdcddd6-d2cb-437e-86c1-e3fa0d10af29",
        value: 225,
        text: "Trinidad and Tobago",
        dialCode: "1868",
        code: "TT",
    },
    {
        id: "6b8d193b-61ca-4f36-99bc-13a58f834fe0",
        value: 226,
        text: "Tunisia",
        dialCode: "216",
        code: "TN",
    },
    {
        id: "75bbcb99-c7a1-4197-b810-4a70caa8ef13",
        value: 250,
        text: "Turkey",
        dialCode: "90",
        code: "TR",
    },
    {
        id: "b8bd7ce8-b824-4b21-81c0-10539587a594",
        value: 228,
        text: "Turkmenistan",
        dialCode: "993",
        code: "TM",
    },
    {
        id: "428d7ef0-9bc7-42f1-94d7-25edd979f277",
        value: 229,
        text: "Turks and Caicos Islands (the)",
        dialCode: "1-649",
        code: "TCA",
    },
    {
        id: "733f1c65-5878-4542-94a6-8b5ecf1a842d",
        value: 230,
        text: "Tuvalu",
        dialCode: "688",
        code: "TV",
    },
    {
        id: "a1d6d7b9-4f75-4fb4-909e-57021cb4a23c",
        value: 231,
        text: "Uganda",
        dialCode: "256",
        code: "UG",
    },
    {
        id: "1bc48f7f-20c8-41c2-b994-860cbcfccdc9",
        value: 232,
        text: "Ukraine",
        dialCode: "380",
        code: "UA",
    },
    {
        id: "498c1775-a0e1-41ad-ace2-6754e955a940",
        value: 233,
        text: "United Arab Emirates (the)",
        dialCode: "971",
        code: "AE",
    },
    {
        id: "66b1f797-6f1a-472e-b2e4-7348b83ab604",
        value: 234,
        text: "United Kingdom of Great Britain and Northern Ireland (the)",
        dialCode: "44",
        code: "GB",
    },
    {
        id: "29f48af3-2675-4d9b-9292-f2a8c1742e55",
        value: 235,
        text: "United States Minor Outlying Islands (the)",
        dialCode: "581",
        code: "UM",
    },
    {
        id: "1e8b08f4-9416-4e65-89a5-5bdf635a3a0d",
        value: 236,
        text: "United States of America (the)",
        dialCode: "1",
        code: "US",
    },
    {
        id: "795ec083-f2b4-46c0-bf54-10e33d517cfa",
        value: 237,
        text: "Uruguay",
        dialCode: "598",
        code: "UY",
    },
    {
        id: "9e5d2dd5-b4d7-4b86-ad5d-7855c5d4171e",
        value: 238,
        text: "Uzbekistan",
        dialCode: "998",
        code: "UZ",
    },
    {
        id: "5f9fef0f-4b01-4a31-8d55-dd6c4225fc92",
        value: 239,
        text: "Vanuatu",
        dialCode: "678",
        code: "VU",
    },
    {
        id: "3e51bc61-5d0b-4c66-bde0-ce220b65ee11",
        value: 240,
        text: "Venezuela (Bolivarian Republic of)",
        dialCode: "58",
        code: "VE",
    },
    {
        id: "3f0153cd-4f78-4596-a46e-59d8783ddfb1",
        value: 241,
        text: "Viet Nam",
        dialCode: "84",
        code: "VN",
    },
    {
        id: "00e55dce-3732-40e3-bf17-0193aeb7abf2",
        value: 242,
        text: "Virgin Islands (British)",
        dialCode: "1284",
        code: "VG",
    },
    {
        id: "8e1d0506-472e-42e3-9a6d-ee4e297bf905",
        value: 243,
        text: "Virgin Islands (U.S.)",
        dialCode: "1340",
        code: "VI",
    },
    {
        id: "723c42a4-26ea-4d33-8d18-a47caa3d20e5",
        value: 244,
        text: "Wallis and Futuna",
        dialCode: "681",
        code: "WF",
    },
    {
        id: "7ce260cf-d290-464d-bc5c-aad18ce6c802",
        value: 245,
        text: "Western Sahara",
        dialCode: "212",
        code: "ESH",
    },
    {
        id: "9f185d82-898b-465b-9346-123be2adde03",
        value: 246,
        text: "Yemen",
        dialCode: "967",
        code: "YE",
    },
    {
        id: "00ee6359-a743-4e0e-a3c5-3820426ddbc1",
        value: 247,
        text: "Zambia",
        dialCode: "260",
        code: "ZM",
    },
    {
        id: "c4ef52cf-3fa5-401d-b105-6bf2b058746d",
        value: 248,
        text: "Zimbabwe",
        dialCode: "263",
        code: "ZW",
    },
    {
        id: "94d69df1-67e5-423b-9913-1973764314ec",
        value: 249,
        text: "Aland Islands",
        dialCode: "358",
        code: "AX",
    },
];
export default countries;
