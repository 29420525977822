import { Button, Form, Select, Space, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { CampaignType } from "Constanst";
import { GetCampaignTypes } from "helpers/StateHelpers";
import { FunctionComponent } from "react";

interface SelectCampaignTypeProps {
    campaignTypeSelected: (campaignType: CampaignType) => void;
}

const SelectCampaignType: FunctionComponent<SelectCampaignTypeProps> = (props: SelectCampaignTypeProps) => {
    const t = useTranslate();

    return (
        <PageContainer pageTitle={t("campaign.create.pageTitle")} helperContent={t("campaign.create.helperContent")}>
            <Form
                layout="vertical"
                onFinish={(values) => {
                    props.campaignTypeSelected(values.campaignType);
                }}
            >
                <Form.Item label={t("campaign.create.campaignTypeLabel")} name="campaignType" initialValue={CampaignType.clicking}>
                    <Select options={GetCampaignTypes()} />
                </Form.Item>
                <Form.Item>
                    <Space direction="horizontal" style={{ width: "100%", justifyContent: "flex-end" }}>
                        <Button style={{ marginLeft: "auto" }} htmlType="submit" type="default">
                            {t("global.continue")}
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </PageContainer>
    );
};

export default SelectCampaignType;
