import { useTranslate } from "@pankod/refine";
import { Col, Row } from "antd";
import { FC } from "react";
import { ReportsService } from "services/services/ReportsService";
import { DateInput, ReportFilter } from "./filter";
import { ReportTab } from "./ReportTab";
import { useStandardReport } from "./hooks";

export const CampaignReport: FC = () => {
    const [data, onFilter, filter] = useStandardReport<any>({ fetchData: ReportsService.getCampaignReport });

    const t = useTranslate();
    return (
        <ReportTab downloadAPIService={ReportsService.getCampaignReportExport} filter={filter} fileName="campaigns.xlsx">
            <Row>
                <ReportFilter onFilter={onFilter}>
                    <DateInput />
                </ReportFilter>
            </Row>
            <Row>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Row>
                        <Col className="gutter-row" span={24}>
                            <div style={{ fontWeight: "bold" }}>{t("global.campaign-count")}</div>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <div>{data?.campaignCount}</div>
                        </Col>
                    </Row>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Row>
                        <Col className="gutter-row" span={24}>
                            <div style={{ fontWeight: "bold" }}>{t("global.past-campaign-count")}</div>
                        </Col>
                        <Col className="gutter-row" span={24}>
                            <div>{data?.oldCampaignCount}</div>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <hr />
            <Row
                style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "row",
                    width: "90%",
                    justifyContent: "space-between",
                    padding: "20px 0px",
                }}
            >
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>{t("global.active-campaign-count")}</div>
                    <div>{data?.activeCampaignCount}</div>
                </Col>
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>{t("global.active-campaign-types")}</div>
                    <div>{data?.activeSellingCampaignCount} Satış</div>
                    <div>
                        {data?.activeClickCampaignCount} {t("global.click", "Tıklama")}
                    </div>
                    <div>
                        {data?.activeActionCampaignCount} {t("global.action", "Aksiyon")}
                    </div>
                    <div>{data?.activeInstallCampaignCount} İndirme</div>
                </Col>
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>{t("global.total-created-link")}</div>
                    <div>{data?.createdLinkCount}</div>
                </Col>
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>{t("campaign.create.step3.totalclick")}</div>
                    <div>{data?.totalClickCount}</div>
                </Col>
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>{t("campaign.create.step3.totaltransformationquantity")}</div>
                    <div>{data?.totalActionCount}</div>
                </Col>
            </Row>
        </ReportTab>
    );
};
