import { Button, Col, Form, Input, Row, Select, useTranslate } from "@pankod/refine";
import { localizationHelper } from "helpers/globalizationHelper";
import { GetCampaignTypes, GetPlatforms } from "helpers/StateHelpers";
import { FunctionComponent, useEffect, useState } from "react";
import { LookupsService } from "services";

export interface FilterComponentProps {
    countries: any[];
    doFilter: any;
    count: number | undefined;
}

export const FilterComponent: FunctionComponent<FilterComponentProps> = (props) => {
    const [countries, setCountries] = useState<any[]>([]);
    const t = useTranslate();

    useEffect(() => {
        LookupsService.getCountries({
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp: any) => {
            setCountries(resp?.data as []);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const filter = (values: any) => {
        props.doFilter(values);
    };

    return (
        <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={async (values) => {
                filter(values);
            }}
            autoComplete="off"
            style={{ width: "100%" }}
        >
            <Row justify="center" align="middle">
                <Col xs={24} sm={24} md={5} span={5}>
                    <Form.Item label={t("global.campaign-name")} name="campaignName">
                        <Input
                            style={{
                                width: "100%",
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col style={{ marginLeft: "5px" }} xs={24} sm={24} md={5} span={5}>
                    <Form.Item label={t("global.campaignType")} name="campaignType">
                        <Select
                            style={{
                                width: "100%",
                            }}
                            allowClear
                            options={GetCampaignTypes()}
                        />
                    </Form.Item>
                </Col>
                <Col style={{ marginLeft: "5px" }} xs={24} sm={24} md={5} span={5}>
                    <Form.Item label={t("global.country")} name="countryId">
                        <Select
                            style={{
                                width: "100%",
                            }}
                            allowClear
                            options={countries?.map((country) => {
                                return {
                                    label: country.text,
                                    value: country.id,
                                };
                            })}
                        />
                    </Form.Item>
                </Col>
                <Col style={{ marginLeft: "5px" }} xs={24} sm={24} md={5} span={5}>
                    <Form.Item label={t("global.platform")} name="platforms">
                        <Select allowClear mode="multiple" placeholder={t("global.platform-select")} options={GetPlatforms()} />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" style={{ padding: "20px 70px" }}>
                <Col>
                    <Button htmlType={"submit"} type="ghost" style={{ float: "right" }}>
                        {t("buttons.filter")}
                    </Button>
                </Col>
            </Row>
            <Row style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                <p>
                    {props.count} {t("global.comand")}
                </p>
            </Row>
        </Form>
    );
};
