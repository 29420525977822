import { Authenticated, LayoutWrapper } from "@pankod/refine";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { InfluencerEditPage } from "./edit/influencerEdit";
import { AdminProfile } from "./AdminProfile";
import { BrandProfilePage } from "./brandProfile";

export const EditProfilePage = (props: any) => {
    return (
        <Authenticated>
            <LayoutWrapper>
                {(RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root)) && <AdminProfile />}
                {RoleHelper.isInRole(Roles.Influencer) && <InfluencerEditPage profile={props.location} />}
                {RoleHelper.isInRole(Roles.Brand) && <BrandProfilePage />}
            </LayoutWrapper>
        </Authenticated>
    );
};
