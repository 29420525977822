import { useTranslate } from "@pankod/refine";
import { Divider, Modal, Typography } from "antd";
import { FC } from "react";
import { usePaymentResult } from "./usePaymentResult";

const { Paragraph, Title } = Typography;

export interface PaymentResultDetail {
    successfulPayments: string[];
    failedPayments: string[];
}

export const PaymentResultModal: FC = () => {
    const [open, detail, handleClose, handleCopy, copied] = usePaymentResult<PaymentResultDetail>();
    const t = useTranslate();
    if (!detail) return null;
    console.log({ copied });
    return (
        <Modal
            open={open}
            title={<Title level={4}>{"ÖDEME DETAYI"}</Title>}
            cancelText={t("global.giveup")}
            cancelButtonProps={{ type: "primary" }}
            onCancel={handleClose}
            okText={copied ? t("global.copied") : t("global.copy")}
            okType={"default"}
            onOk={() => handleCopy()}
        >
            <>
                <Title level={5}> {t("payment.result.succeeded", "Başarılı Olanlar")}</Title>
                {detail.successfulPayments.map((name, index) => (
                    <Paragraph key={"successfulPayments-" + index}>{name}</Paragraph>
                ))}
                <Divider />
                <Title level={5}> {t("payment.result.failed", "Başarısız Olanlar")}</Title>
                {detail.failedPayments.map((name, index) => (
                    <Paragraph key={"failedPayments-" + index}>{name}</Paragraph>
                ))}
            </>
        </Modal>
    );
};
