export const checkTCKN = (TCKN: string | undefined | null): boolean => {
    if (TCKN === undefined || TCKN === null) return false;

    let TCKN_int = Array.from(TCKN, (x) => parseInt(x));
    let TCKN_length = TCKN_int.length;
    if (TCKN_length === 11) {
        let totalSum = 0;
        let evenSum = 0;
        let oddSum = 0;

        for (let i = 1; i <= TCKN.length; i++) {
            totalSum += TCKN_int[i - 1];
            if (i % 2 && i < 10) oddSum += TCKN_int[i - 1];
            else if (i % 2 === 0 && i < 10) evenSum += TCKN_int[i - 1];
        }

        let checkCase1 = (oddSum * 7 + evenSum * 9) % 10 === TCKN_int[TCKN_length - 2];
        let checkCase2 = (oddSum * 8) % 10 === TCKN_int[TCKN_length - 1];
        let checkCase3 = (totalSum - TCKN_int[TCKN_length - 1]) % 10 === TCKN_int[TCKN_length - 1];

        return checkCase1 && checkCase2 && checkCase3;
    }
    return false;
};
