import { CampaignType, PaginationConstant } from "Constanst";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { FunctionComponent, useEffect, useRef, useState } from "react";

import dayjs from "dayjs";
import { localizationHelper } from "helpers/globalizationHelper";
import { BonusesAndRefundsRequest } from "services/models/BonusesAndRefundsRequest";
import { BonusesAndRefundsResponse } from "services/models/BonusesAndRefundsResponse";
import { DailyCampaignPerformanceSummaryResponse } from "services/models/DailyCampaignPerformanceSummaryResponse";
import { TransactionsSummaryOfCampaignRequest } from "services/models/TransactionsSummaryOfCampaignRequest";
import { TransactionsSummaryOfCampaignResponse } from "services/models/TransactionsSummaryOfCampaignResponse";
import { CampaignsService } from "../../../services";
import {
    AdminActionCampaignPerformance,
    AdminAmazonCampaignPerformance,
    AdminClickCampaignPerformance,
    AdminDownloadCampaignPerformance,
    AdminSellingCampaignPerformance,
} from "./CampaignDetails/Admin/CampaignPerformance";

const CampaignPerformance: FunctionComponent<any> = (props: any) => {
    const [campaignId] = useState<any>(props.match.params.campaignId);
    const [campaignDetail, setCampaignDetail] = useState<any>(null);
    const [selectedPlatform, setSelectedPlatform] = useState<any>(null);
    const [influencerName, setInfluencerName] = useState<string>("");
    const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
    const [dailyPerformanceSummary, setDailyPerformanceSummary] = useState<DailyCampaignPerformanceSummaryResponse>();
    const [bonusesAndRefundsResponse, setBonusesAndRefundsResponse] = useState<BonusesAndRefundsResponse>();
    const [transactionsSummary, setTransactionsSummary] = useState<TransactionsSummaryOfCampaignResponse>();

    const timerRef = useRef<any>();

    useEffect(() => {
        CampaignsService.getCampaignDetail({
            campaignId,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setCampaignDetail(resp.data);
        });
        const endDate = dayjs();
        const startDate = dayjs().add(-7, "days");
        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);
        getPerformance({
            startDate: startDate,
            endDate: endDate,
            platform: selectedPlatform,
            influencerName,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getPerformance = (body: any) => {
        CampaignsService.getDailyPerformanceSummary({
            id: campaignId,
            body,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            var result = resp.data as DailyCampaignPerformanceSummaryResponse;
            setDailyPerformanceSummary(result);
        });
        getBonusesAndRefunds({
            ...body,
            pageSize: PaginationConstant.initialPageSize,
            pageNumber: PaginationConstant.initialPage,
        });

        getTransactionSummariesOfCampaign({
            ...body,
            pageSize: PaginationConstant.initialPageSize,
            pageNumber: PaginationConstant.initialPage,
        });
    };

    const getBonusesAndRefunds = (body: BonusesAndRefundsRequest) => {
        CampaignsService.getBonusesAndRefunds({
            id: campaignId,
            body: {
                endDate: body.endDate,
                startDate: body.startDate,
                platform: body.platform,
                pageNumber: body.pageNumber,
                pageSize: body.pageSize,
                influencerName: body.influencerName,
            },
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            var result = resp.data as BonusesAndRefundsResponse;
            setBonusesAndRefundsResponse(result);
        });
    };

    const getTransactionSummariesOfCampaign = (body: TransactionsSummaryOfCampaignRequest) => {
        CampaignsService.getTransactionsSummaryOfCampaign({
            id: campaignId,
            body: {
                endDate: body.endDate,
                startDate: body.startDate,
                platform: body.platform,
                pageNumber: body.pageNumber,
                pageSize: body.pageSize,
                influencerName: body.influencerName,
            },
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            var result = resp.data as TransactionsSummaryOfCampaignResponse;
            setTransactionsSummary(result);
        });
    };

    const changePlatform = (e: any) => {
        setSelectedPlatform(e);
        getPerformance({
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            platform: e,
            influencerName,
        });
    };

    const changeName = (value: string) => {
        setInfluencerName(value);
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            getPerformance({
                startDate: selectedStartDate,
                endDate: selectedEndDate,
                platform: selectedPlatform,
                influencerName: value,
            });
        }, 400);
    };

    const changeDate = (e: any) => {
        if (e !== null) {
            setSelectedStartDate(e[0]);
            setSelectedEndDate(e[1]);
            getPerformance({
                startDate: e[0],
                endDate: e[1],
                platform: selectedPlatform,
                influencerName,
            });
        } else {
            const endDate = dayjs();
            const startDate = dayjs().add(-7, "day");
            getPerformance({
                startDate: startDate,
                endDate: endDate,
                platform: selectedPlatform,
            });
        }
    };

    const handleBonusPagination = (pageNumber: number) => {
        getBonusesAndRefunds({
            endDate: selectedEndDate,
            pageNumber,
            pageSize: PaginationConstant.initialPageSize,
            platform: selectedPlatform,
            startDate: selectedStartDate,
            influencerName,
        });
    };

    const handleTransactionSummaryPagination = (pageNumber: number) => {
        getTransactionSummariesOfCampaign({
            endDate: selectedEndDate,
            pageNumber,
            pageSize: PaginationConstant.initialPageSize,
            platform: selectedPlatform,
            startDate: selectedStartDate,
            influencerName,
        });
    };

    if (!campaignDetail || !dailyPerformanceSummary) return null;

    return (
        <>
            {RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root) || RoleHelper.isInRole(Roles.Brand)
                ? {
                      [CampaignType.action]: (
                          <AdminActionCampaignPerformance
                              changePlatform={changePlatform}
                              changeDate={changeDate}
                              changeName={changeName}
                              dailyPerformanceSummary={dailyPerformanceSummary as DailyCampaignPerformanceSummaryResponse}
                              data={campaignDetail}
                              bonusPaginationChange={handleBonusPagination}
                              bonusesAndRefunds={bonusesAndRefundsResponse}
                              transactionsSummary={transactionsSummary as TransactionsSummaryOfCampaignResponse}
                              transactionsSummaryPaginationChange={handleTransactionSummaryPagination}
                          />
                      ),
                      [CampaignType.clicking]: (
                          <AdminClickCampaignPerformance
                              changePlatform={changePlatform}
                              changeDate={changeDate}
                              changeName={changeName}
                              dailyPerformanceSummary={dailyPerformanceSummary as DailyCampaignPerformanceSummaryResponse}
                              data={campaignDetail}
                              bonusPaginationChange={handleBonusPagination}
                              bonusesAndRefunds={bonusesAndRefundsResponse}
                              transactionsSummary={transactionsSummary as TransactionsSummaryOfCampaignResponse}
                              transactionsSummaryPaginationChange={handleTransactionSummaryPagination}
                          />
                      ),
                      [CampaignType.download]: (
                          <AdminDownloadCampaignPerformance
                              changeName={changeName}
                              changePlatform={changePlatform}
                              changeDate={changeDate}
                              dailyPerformanceSummary={dailyPerformanceSummary as DailyCampaignPerformanceSummaryResponse}
                              data={campaignDetail}
                              bonusPaginationChange={handleBonusPagination}
                              bonusesAndRefunds={bonusesAndRefundsResponse}
                              transactionsSummary={transactionsSummary as TransactionsSummaryOfCampaignResponse}
                              transactionsSummaryPaginationChange={handleTransactionSummaryPagination}
                          />
                      ),
                      [CampaignType.selling]: (
                          <AdminSellingCampaignPerformance
                              changePlatform={changePlatform}
                              changeDate={changeDate}
                              changeName={changeName}
                              dailyPerformanceSummary={dailyPerformanceSummary as DailyCampaignPerformanceSummaryResponse}
                              data={campaignDetail}
                              bonusPaginationChange={handleBonusPagination}
                              bonusesAndRefunds={bonusesAndRefundsResponse}
                              transactionsSummary={transactionsSummary as TransactionsSummaryOfCampaignResponse}
                              transactionsSummaryPaginationChange={handleTransactionSummaryPagination}
                          />
                      ),
                      [CampaignType.amazon]: (
                          <AdminAmazonCampaignPerformance
                              dailyPerformanceSummary={dailyPerformanceSummary as DailyCampaignPerformanceSummaryResponse}
                              changePlatform={changePlatform}
                              changeName={changeName}
                              bonusPaginationChange={handleBonusPagination}
                              transactionsSummary={transactionsSummary}
                              transactionsSummaryPaginationChange={handleTransactionSummaryPagination}
                              bonusesAndRefunds={bonusesAndRefundsResponse}
                              changeDate={changeDate}
                              data={campaignDetail}
                          />
                      ),
                  }[campaignDetail.typeHash as string]
                : null}
        </>
    );
};

export default CampaignPerformance;
