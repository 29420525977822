import { Button, Col, Form, Row, useTranslate } from "@pankod/refine";
import { Select } from "antd";
import { useEffect, useState } from "react";
import { Fenomio_Common_BaseModels_Api_DetailItemDto } from "services";
import { PageProps } from "./PageProps";

export interface ICampaignStep2DataSelfService {
    category: string | null;
    whitelistCategories: string[] | null;
    blacklistCategories: string[] | null;
    country: string | null;
}

export interface SecondPageProps extends PageProps {
    countries: Fenomio_Common_BaseModels_Api_DetailItemDto[];
    categories: any[];
}

export const SecondPage = (props: SecondPageProps) => {
    const [form] = Form.useForm();
    const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
    const [whiteListCategoryOptions, setWhiteListCategoryOptions] = useState<any[]>([]);
    const [blackListCategoryOptions, setBlackListCategoryOptions] = useState<any[]>([]);

    const t = useTranslate();

    useEffect(() => {
        prepareCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const colStyle = {
        marginLeft: "10px",
        marginRight: "10px",
    };

    const getCategoryOptions = () => {
        let categories: any[] = [];

        if (props.categories && props.categories.length > 0) {
            const categoryFieldValue = form.getFieldValue("categories");
            const whiteListCategoryFieldValue = form.getFieldValue("whitelistCategories");
            const blackListCategoryFieldValue = form.getFieldValue("blacklistCategories");

            categories = props.categories.filter((c) => {
                if (categoryFieldValue && categoryFieldValue.length > 0 && categoryFieldValue.includes(c.id)) {
                    return false;
                }
                if (whiteListCategoryFieldValue && whiteListCategoryFieldValue.length > 0 && whiteListCategoryFieldValue.includes(c.id)) {
                    return false;
                }
                if (blackListCategoryFieldValue && blackListCategoryFieldValue.length > 0 && blackListCategoryFieldValue.includes(c.id)) {
                    return false;
                }

                return true;
            });

            let normalCategories: any[] = JSON.parse(JSON.stringify(categories));
            let whiteListCategories: any[] = JSON.parse(JSON.stringify(categories));
            let blackListCategories: any[] = JSON.parse(JSON.stringify(categories));

            if (categoryFieldValue !== undefined && categoryFieldValue.length > 0) {
                const existingCategories = props.categories.filter((c) => categoryFieldValue.includes(c.id));
                existingCategories.forEach((c) => normalCategories.push(c));
                existingCategories.forEach((c) => whiteListCategories.unshift(c));
            }
            if (whiteListCategoryFieldValue !== undefined && whiteListCategoryFieldValue.length > 0) {
                const existingCategories = props.categories.filter((c) => whiteListCategoryFieldValue.includes(c.id));
                existingCategories.forEach((c) => whiteListCategories.push(c));
                existingCategories.forEach((c) => normalCategories.unshift(c));
            }
            if (blackListCategoryFieldValue !== undefined && blackListCategoryFieldValue.length > 0) {
                const existingCategories = props.categories.filter((c) => blackListCategoryFieldValue.includes(c.id));
                existingCategories.forEach((c) => blackListCategories.push(c));
            }

            normalCategories = [...new Set(normalCategories)];
            whiteListCategories = [...new Set(whiteListCategories)];
            blackListCategories = [...new Set(blackListCategories)];

            console.log(normalCategories);

            const normalOptions = normalCategories.map((c) => {
                return {
                    label: c.name,
                    value: c.id,
                };
            });
            setCategoryOptions(normalOptions);

            const whiteListOptions = whiteListCategories.map((c) => {
                return {
                    label: c.name,
                    value: c.id,
                };
            });
            setWhiteListCategoryOptions(whiteListOptions);

            const blacklistOptions = blackListCategories.map((c) => {
                return {
                    label: c.name,
                    value: c.id,
                };
            });
            setBlackListCategoryOptions(blacklistOptions);
        }
    };

    const prepareCategories = () => {
        getCategoryOptions();
    };

    return (
        <>
            <Form
                form={form}
                layout="vertical"
                name="secondStep"
                requiredMark={false}
                onFinish={(values) => {
                    props.onFinish({
                        ...values,
                        blacklistCategories: values.blacklistCategories !== undefined ? values.blacklistCategories : [],
                        whitelistCategories: values.whitelistCategories !== undefined ? values.whitelistCategories : [],
                    });
                }}
                scrollToFirstError
            >
                <Row>
                    <Col style={colStyle} xs={24} sm={24} md={7} span={7}>
                        <Form.Item
                            label={t("global.location")}
                            name="country"
                            rules={[
                                {
                                    required: true,
                                    message: t("global.please-select-location"),
                                },
                            ]}
                            initialValue={props.campaignData.country}
                        >
                            <Select
                                allowClear
                                optionFilterProp="label"
                                placeholder={t("pages.phenomenon.countrySelectt")}
                                options={props.countries.map((country) => {
                                    return {
                                        label: country.text,
                                        value: country.id,
                                    };
                                })}
                            />
                        </Form.Item>
                        <Form.Item
                            rules={[
                                {
                                    required: true,
                                    message: t("campaign.create.step2.categoriesRule"),
                                },
                            ]}
                            label={t("global.categories")}
                            name="categories"
                            initialValue={props.campaignData.categories}
                        >
                            <Select
                                allowClear
                                mode={"multiple"}
                                onChange={() => prepareCategories()}
                                options={categoryOptions}
                                optionFilterProp="label"
                            />
                        </Form.Item>
                    </Col>
                    <Col style={colStyle} xs={24} sm={24} md={7} span={7}>
                        <Form.Item
                            label={t("campaign.create.step2.whitelistCategoriesLabel")}
                            name="whitelistCategories"
                            initialValue={props.campaignData.whitelistCategories}
                        >
                            <Select
                                allowClear
                                mode={"multiple"}
                                optionFilterProp="label"
                                onChange={() => prepareCategories()}
                                options={whiteListCategoryOptions}
                            />
                        </Form.Item>
                        <Form.Item
                            label={t("campaign.create.step2.blacklistCategoriesLabel")}
                            name="blacklistCategories"
                            initialValue={props.campaignData.blacklistCategories}
                        >
                            <Select
                                allowClear
                                optionFilterProp="label"
                                mode={"multiple"}
                                options={blackListCategoryOptions}
                                onChange={() => prepareCategories()}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ float: "right", display: "flex" }}>
                    <Col>
                        <Button
                            style={{ marginLeft: "auto", marginRight: "5px" }}
                            type="primary"
                            htmlType="submit"
                            onClick={() => {
                                const currentFormValues = form.getFieldsValue();
                                props.onBack(currentFormValues);
                            }}
                        >
                            {t("buttons.back")}
                        </Button>
                        <Button
                            type="default"
                            htmlType="submit"
                            style={{ width: "100px" }}
                            loading={props.isLoading}
                        >
                            {t("buttons.next")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
