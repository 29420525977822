import { InfoCircleTwoTone } from "@ant-design/icons";
import { Authenticated, Button, Col, Form, Input, LayoutWrapper, Row, Table, Tooltip, useTranslate } from "@pankod/refine";
import { DatePicker } from "antd";
import { PageContainer } from "components/pageContainer/pageContainer";
import { useEffect, useState } from "react";
import { PaginationConstant } from "../../Constanst";
import { localizationHelper } from "../../helpers/globalizationHelper";
import { Pagination } from "../../services/core/Pagination";
import { AuditLogService } from "../../services/services/AuditLogService";

const { RangePicker } = DatePicker;

const AuditLogs = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>();
    const t = useTranslate();

    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const getData = () => {
        setDataLoading(true);
        AuditLogService.searchAuditLogs({
            requestBody: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data.data);
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount
            });
        });
    };

    const prepareFilterRequest = () => {
        const auditLogSearch: any = {};
        if (filterValues) {
            auditLogSearch.startDate =
                filterValues.date !== undefined && filterValues.date[0] ? new Date(filterValues.date[0]).toDateString() : null;
            auditLogSearch.endDate =
                filterValues.date !== undefined && filterValues.date[1] ? new Date(filterValues.date[1]).toDateString() : null;

            auditLogSearch.EntityName = filterValues.entityName ?? null;
            auditLogSearch.OperationName = filterValues.operationName ?? null;
            auditLogSearch.pageNumber = pagination.current;
            auditLogSearch.pageSize = pagination.pageSize;
        } else {
            auditLogSearch.StartDate = null;
            auditLogSearch.EndDate = null;
            auditLogSearch.EntityName = null;
            auditLogSearch.OperationName = null;
            auditLogSearch.pageNumber = pagination.current;
            auditLogSearch.pageSize = pagination.pageSize;
        }

        return auditLogSearch;
    };

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total
        });
        setSearch(true);
    };

    const doFilter = (values: any) => {
        setPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize
        });
        setFilterValues(values);
        setSearch(true);
    };

    const columns = [
        {
            title: <span className="table-font">{t("campaign.create.step3.startDateLabel")}</span>,
            dataIndex: "createdOn",
            key: "createdOn",
        },
        {
            title: <span className="table-font">Created By</span>,
            dataIndex: "keyPropertyValue",
            key: "keyPropertyValue",
            render: (text: string, row: any, index: number) => row.accountInfo.name,
        },
        {
            title: <span className="table-font">Entity Name</span>,
            dataIndex: "entityName",
            key: "entityName ",
        },
        {
            title: <span className="table-font">Operation Name</span>,
            dataIndex: "operationType",
            key: "operationType",
        },
        {
            title: <span className="table-font">Old Value</span>,
            dataIndex: "oldValue",
            key: "oldValue",
            render: (text: string, row: any, index: number) => (
                <Tooltip color={"#952AE7"} placement="top" title={text}>
                    <InfoCircleTwoTone />
                </Tooltip>
            ),
        },
        {
            title: <span className="table-font">New Value</span>,
            dataIndex: "newValue",
            key: "newValue",
            render: (text: string, row: any, index: number) => (
                <Tooltip color={"#952AE7"} placement="top" title={text}>
                    <InfoCircleTwoTone />
                </Tooltip>
            ),
        },
    ];

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageContainer pageTitle={"Loglar"} helperContent={"Yardım içeriği eklenecek!"}>
                    <Row>
                        <Form
                            name="basic"
                            layout="inline"
                            initialValues={{ remember: true }}
                            onFinish={async (values) => {
                                doFilter(values);
                            }}
                            autoComplete="off"
                            style={{ width: "100%", padding: "10px", justifyContent: "end" }}
                        >
                            <Row justify="space-around">
                                <Col xs={24} sm={24} md={7} span={7}>
                                    <Form.Item name="date" label={t("global.date")}>
                                        <RangePicker locale={localizationHelper.getLocaleForComponent()} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={7} span={7}>
                                    <Form.Item name="entityName" label={"Entity Name"}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={7} span={7}>
                                    <Form.Item name="operationName" label={"Operation Name"}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} style={{ display: "flex", justifyItems: "end" }}>
                                    <Button type="ghost" style={{ margin: "10px" }}>
                                        {t("buttons.filter")}
                                    </Button>
                                </Col>
                            </Row>
                        </Form>
                    </Row>
                    <Table
                        size={"small"}
                        columns={columns}
                        pagination={pagination}
                        loading={dataLoading}
                        onChange={(page) => handleTableChange(page)}
                        dataSource={data}
                        scroll={{ x: "100%" }}
                    />
                </PageContainer>
            </LayoutWrapper>
        </Authenticated>
    );
};

export default AuditLogs;
