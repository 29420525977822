import { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class NotificationService {
    public static getAll({
        acceptLanguage,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `notifications/getall`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static searchNotifications({
        body,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `notifications/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static resetCount({
        acceptLanguage,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `notifications/reset-count`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static SeenInformation({
        body,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `notifications/seen-information`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static InformationCount({
        acceptLanguage,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `notifications/count`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }
}
