import { useNavigation } from "@pankod/refine";
import { useState } from "react";

export const useAdminBrandCreate = () => {
    const [isLoading, setLoading] = useState(false);
    const { push } = useNavigation();

    const saveBrand = (value: any) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    };

    const handleCancel = () => {
        push("/brands/list");
    };

    return [isLoading, saveBrand, handleCancel] as const;
};
