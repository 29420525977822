import { Authenticated, LayoutWrapper } from "@pankod/refine";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { useEffect } from "react";
import { AdminProfile } from "./AdminProfile";
import { BrandProfilePage } from "./brandProfile";
import { InfluencerProfilePage } from "./influencerProfile";

export const ProfilePage = () => {
    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Authenticated>
            <LayoutWrapper>
                {(RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root)) && <AdminProfile />}
                {RoleHelper.isInRole(Roles.Influencer) && <InfluencerProfilePage />}
                {RoleHelper.isInRole(Roles.Brand) && <BrandProfilePage />}
            </LayoutWrapper>
        </Authenticated>
    );
};
