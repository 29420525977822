/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export const $Fenomio_Common_Auth_Concrete_AccessTokenContract = {
    properties: {
        accessToken: {
            type: "string",
            isNullable: true,
        },
        refreshToken: {
            type: "string",
            isNullable: true,
        },
        expiresIn: {
            type: "number",
            isNullable: true,
            format: "int32",
        },
        refreshTokenExpireDate: {
            type: "string",
            format: "date-time",
        },
    },
} as const;
