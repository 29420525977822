import { useNavigation, useTranslate, Tag } from "@pankod/refine";
import { BrandStatusColors } from "Constanst";
import { IBrand } from "modules/brands/model";
import React from "react";
import { Link } from "react-router-dom";

export const useList = () => {
    const { push } = useNavigation();
    const t = useTranslate();

    const showBrand = (brand: IBrand) => {
        push("/brands/detail/" + brand.id);
    };

    const columns = [
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">Id</span>
                </p>
            ),
            key: "id",
            render: (text: string, row: any, index: number) => (
                <p style={{ textAlign: "center" }}>
                    <Link to={"/brands/detail/" + row.id}>
                        <span
                            className="clickable-span"
                            onClick={() => showBrand(row)}
                            style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold", fontSize: "small" }}
                        >
                            #{row.id}
                        </span>
                    </Link>
                </p>
            ),
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">{t("global.brand", "Marka")}</span>
                </p>
            ),
            dataIndex: "name",
            key: "name",
            render: (text: string, row: any, index: number) => (
                <p style={{ textAlign: "center" }}>
                    <Link to={"/brands/detail/" + row.id}>
                        <span
                            className="clickable-span"
                            style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold", fontSize: "small" }}
                        >
                            {" "}
                            {text}{" "}
                        </span>
                    </Link>
                </p>
            ),
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">{t("global.sector")}</span>
                </p>
            ),
            dataIndex: "sector",
            key: "sector",
            render: (text: string, row: any, index: number) => (
                <p style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "small" }}>{text}</span>
                </p>
            ),
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">{t("global.country")}</span>
                </p>
            ),
            dataIndex: "country",
            key: "country",
            render: (text: string, row: any, index: number) => (
                <p style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "small" }}>{text}</span>
                </p>
            ),
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">{t("global.contact-number")}</span>
                </p>
            ),
            dataIndex: "phoneNumber",
            key: "phone",
            render: (text: string, row: any, index: number) => (
                <p style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "small" }}>{text}</span>
                </p>
            ),
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">{t("global.contact-email")}</span>
                </p>
            ),
            dataIndex: "email",
            key: "email",
            render: (text: string, row: any, index: number) => <span style={{ fontSize: "small" }}>{text}</span>,
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">{t("pages.brand.statu")}</span>
                </p>
            ),
            dataIndex: "status",
            key: "status",
            render: (text: string, row: any, index: number) => {
                return <Tag color={BrandStatusColors[row.status - 1].toString()}>{t(["global.brand.status.", row.status].join(""))}</Tag>;
            },
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">{t("global.action")}</span>
                </p>
            ),
            render: (text: string, row: any, index: number) => (
                <p style={{ textAlign: "center" }}>
                    <Link to={"/campaign/list?brandId=" + row.id}>
                        <span
                            className="clickable-span"
                            style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold", fontSize: "small" }}
                        >
                            {t("campaign.create.result.secondaryButton")}
                        </span>
                    </Link>
                </p>
            ),
        },
    ];

    return [columns] as const;
};
