import { persistentStorageHelper } from "./persistentStorageHelper";
import { transientStorageHelper } from "./transientStorageHelper";
import * as Constants from "../Constanst";


var sessionStorage_transfer = function(event:any) {
    if(!event) { event = window.event; } // ie suq
    if(!event.newValue) return;          // do nothing if no value to work with
    if (event.key === 'getSessionStorage') {
      // another tab asked for the sessionStorage -> send it
      localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage));
      // the other tab should now have it, so we're done with it.
      localStorage.removeItem('sessionStorage'); // <- could do short timeout as well.
    } else if (event.key === 'sessionStorage' && !sessionStorage.length) {
      // another tab sent data <- get it
      var data = JSON.parse(event.newValue);
      for (var key in data) {
        sessionStorage.setItem(key, data[key]);
      }
    }
  };
  
  // listen for changes to localStorage
  if(window.addEventListener) {
    window.addEventListener("storage", sessionStorage_transfer, false);
  } else {
      //eslint-disable-next-line
    (<any>window).attachEvent("onstorage", sessionStorage_transfer);
  };
  
  
  // Ask other tabs for session storage (this is ONLY to trigger event)
  if (!sessionStorage.length) {
    localStorage.setItem('getSessionStorage', 'foobar');
    localStorage.removeItem('getSessionStorage');
  };

export class storageHelper {
    static isRememberMeSelected() {
        return persistentStorageHelper.getItem(Constants.REMEMBER_ME_KEY) === "true";
    }

    static setItem(key: string, value: any) {
        if (storageHelper.isRememberMeSelected()) {
            persistentStorageHelper.setItem(key, value);
        } else {
            transientStorageHelper.setItem(key, value);
        }
    }

    static getItem(key: string) {
        if (storageHelper.isRememberMeSelected()) {
            return persistentStorageHelper.getItem(key);
        } else {
            return transientStorageHelper.getItem(key);
        }
    }

    static removeItem(key: string) {
        if (storageHelper.isRememberMeSelected()) {
            persistentStorageHelper.removeItem(key);
        } else {
            transientStorageHelper.removeItem(key);
        }
    }

    static clear() {
        if (storageHelper.isRememberMeSelected()) {
            persistentStorageHelper.clear();
        } else {
            transientStorageHelper.clear();
        }
    }
}
