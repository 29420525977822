import { RouteProps } from "react-router-dom";
import { ResetPasswordPage } from "./resetPassword";

export const resetPasswordRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: ResetPasswordPage,
            path: "/reset-password/",
        },
    ];
};
