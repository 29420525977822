import jwtDecode from "jwt-decode";
import moment from "moment";
import { ProfileModel } from "pages/profile/models";
import { BrandsService, InfluencersService, LoginService, UsersService } from "services";
import * as Constants from "../Constanst";
import { localizationHelper } from "./globalizationHelper";
import { persistentStorageHelper } from "./persistentStorageHelper";
import { Roles } from "./roleHelper";
import { storageHelper } from "./storageHelper";
import { Format } from "./dateFormatHelper";

export default class UserHelper {
    static getId() {
        const token = storageHelper.getItem(Constants.TOKEN_KEY);
        if (!token) {
            return "";
        }
        var decodedToken: any = jwtDecode(token ?? "");
        return decodedToken.Id;
    }

    static getEmail() {
        const token = storageHelper.getItem(Constants.TOKEN_KEY);
        if (!token) {
            return "";
        }
        var decodedToken: any = jwtDecode(token ?? "");
        return decodedToken.email;
    }

    static getLogoPath() {
        const updatedLogoPath = storageHelper.getItem(Constants.UPDATED_PHOTO);
        if (updatedLogoPath) {
            return updatedLogoPath;
        }
        const token = storageHelper.getItem(Constants.TOKEN_KEY);
        if (!token) {
            return "";
        }
        var decodedToken: any = jwtDecode(token ?? "");
        return decodedToken.family_name;
    }

    static getRole() {
        const token = storageHelper.getItem(Constants.TOKEN_KEY);
        if (!token) {
            return "";
        }
        var decodedToken: any = jwtDecode(token ?? "");
        const role: string = decodedToken.role;
        return role as Roles;
    }

    static getName() {
        const token = storageHelper.getItem(Constants.TOKEN_KEY);
        if (!token) {
            return "";
        }
        var decodedToken: any = jwtDecode(token ?? "");
        return decodedToken.unique_name;
    }

    static async getProfile() {
        const role = this.getRole();
        const helper = new UserHelper();
        if (role === Roles.Brand) {
            return await helper.getBrandProfile();
        } else if (role === Roles.Influencer) {
            return await helper.getInfluencerProfile();
        } else {
            return await helper.getUserProfile();
        }
    }

    async getBrandProfile() {
        return await BrandsService.getBrands({
            id: UserHelper.getId(),
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((response) => {
                return {
                    country: response.data?.country,
                    countryId: response.data?.countryId,
                    delegateName: response.data?.delegateName,
                    detail: response.data?.detail,
                    email: response.data?.email,
                    id: response.data?.id,
                    logoPath: response.data?.logoPath,
                    name: response.data?.name,
                    phone: response.data?.phone,
                    sector: response.data?.sector,
                    sectorId: response.data?.sectorId,
                    workingDetail: response.data?.workingDetail,
                    createdOn: Format(response.data?.createdOn as string),
                } as ProfileModel;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    async getInfluencerProfile() {
        return await InfluencersService.getInfluencers({
            id: UserHelper.getId(),
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((response) => {
                return {
                    country: response.data?.country,
                    email: response.data?.email,
                    id: response.data?.id,
                    logoPath: response.data?.logoPath,
                    name: response.data?.firstName,
                    phone: response.data?.phone,
                    surname: response.data?.lastName,
                    biography: response.data?.biography,
                    socialMedias: response.data?.socialMedias,
                    categories: response.data?.categories,
                    createdOn: Format(response.data?.createdOn as string),
                    balance: response.data?.balance,
                    balanceOnMonth: response.data?.balanceOnMonth,
                    billingDetail: response.data?.billingDetail,
                    currency: response.data?.currency,
                    balanceDecimal: response.data?.balanceDecimal,
                    balanceOnMonthDecimal: response.data?.balanceOnMonthDecimal
                } as ProfileModel;
            })
            .catch((error) => {
                console.error(error);
            });
    }

    static async needsRefreshToken() {
        if (!storageHelper.getItem(Constants.TOKEN_KEY)) {
            return false;
        }

        if (moment(storageHelper.getItem(Constants.REFRESH_TOKEN_EXPIRE_KEY)).isAfter(moment().add(60, "minutes"))) {
            return true;
        }

        if (persistentStorageHelper.getItem(Constants.REMEMBER_ME_KEY)) {
            return true;
        }

        return false;
    }

    static async refreshToken() {
        return await LoginService.refreshToken({
            acceptLanguage: localizationHelper.getLocale(),
            requestBody: {
                token: storageHelper.getItem(Constants.REFRESH_TOKEN_KEY),
            },
        })
            .then((response: any) => {
                if (response.status === "SUCCESS") {
                    storageHelper.setItem(Constants.TOKEN_KEY, response.data.accessToken);
                    storageHelper.setItem(Constants.REFRESH_TOKEN_KEY, response.data.refreshToken);
                    storageHelper.setItem(Constants.REFRESH_TOKEN_EXPIRE_KEY, response.data.refreshTokenExpireDate);
                    return Promise.resolve();
                }
                return Promise.reject();
            })
            .catch((error) => {
                let message = error.body.message;
                return Promise.reject(message);
            });
    }
    async getUserProfile() {
        return await UsersService.getUsers({
            id: UserHelper.getId(),
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((response) => {
                return {
                    id: response.data.id,
                    firstName: response.data.firstName,
                    lastName: response.data.lastName,
                    email: response.data.email,
                    type: response.data.type,
                };
            })
            .catch((error) => {
                console.error(error);
            });
    }
}
