import { PaginationConstant } from "Constanst";
import { useState, useEffect } from "react";
import { Pagination } from "services/core/Pagination";

type fetchDataRequest = {
    startDate?: string | Date;
    endDate?: string | Date;
    acceptLanguage?: any;
    [key: string]: any;
};

type Props = {
    fetchData: (data: fetchDataRequest) => Promise<any>;
};

export const useTableReport = ({ fetchData }: Props) => {
    const [filter, setFilter] = useState<any>({ startDate: "", endDate: "" });
    const [data, setData] = useState<any>({
        data: [],
        pageInfo: {
            pageSize: PaginationConstant.initialPageSize,
            totalItemCount: 0,
            pageNumber: PaginationConstant.initialPage,
        },
    });
    const [loading, setLoading] = useState<boolean>(true);

    const onFilter = (values: any) => {
        setFilter(values);
        getReport(values);
    };

    const getReport = async ({
        startDate = "",
        endDate = "",
        page = PaginationConstant.initialPage,
        size = PaginationConstant.initialPageSize,
    }) => {
        setLoading(true);
        fetchData({ startDate, endDate, page, size })
            .then((resp) => {
                setData(resp.data);
            })
            .finally(() => setLoading(false));
    };

    const handlePaginationChange = (pagination: Pagination) => {
        getReport({ ...filter, page: pagination.current, size: pagination.pageSize });
    };

    useEffect(() => {
        getReport(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [data, loading, handlePaginationChange, onFilter, filter] as const;
};
