import { DualAxes } from "@ant-design/plots";
import { useTranslate } from "@pankod/refine";
import { FC } from "react";
import dayjs from "dayjs";

interface AdminChartProps {
    items: any[];
    currency: string;
}

export const AdminChart: FC<AdminChartProps> = ({ items, currency }) => {
    const t = useTranslate();

    let config: any = {
        data: [[], []],
        xField: "time",
        yField: ["value", "count"],
        yAxis: {
            count: {
                min: 0,
            },
            value: {
                min: 0,
            },
        },
        geometryOptions: [
            {
                geometry: "column",
                columnWidthRatio: 0.4,
                seriesField: "name",
                isGroup: true,
            },
            {
                geometry: "line",
                seriesField: "name",
                color: ["black", "blue"],
            },
        ],
    };

    if (items.length > 0) {
        const clicks: any[] = [];
        const transforms: any[] = [];
        items.forEach((i) => {
            transforms.push({
                time: dayjs(i.createdOn).format("DD/MM"),
                count: i.conversions,
                name: t("global.sales"),
            });
            transforms.push({
                time: dayjs(i.createdOn).format("DD/MM"),
                count: i.clicks,
                name: t("global.click"),
            });
            clicks.push({
                time: dayjs(i.createdOn).format("DD/MM"),
                value: Number(i.fenomioEarning.toFixed(2)),
                name: t("global.fenomioTotalBudgett") + "(" + currency + ")",
            });
            clicks.push({
                time: dayjs(i.createdOn).format("DD/MM"),
                value: Number(i.influencerEarning.toFixed(2)),
                name: t("global.phenomenonTotalBudget") + "(" + currency + ")",
            });
        });

        config = {
            data: [clicks, transforms],
            xField: "time",
            yField: ["value", "count"],
            yAxis: {
                count: {
                    min: 0,
                },
                value: {
                    min: 0,
                },
            },
            geometryOptions: [
                {
                    geometry: "column",
                    columnWidthRatio: 0.4,
                    seriesField: "name",
                    isGroup: true,
                    color: ["#952AE7", "#FF0C85"],
                },
                {
                    geometry: "line",
                    seriesField: "name",
                    color: ["black", "blue"],
                },
            ],
        };
    }

    return <DualAxes {...config} />;
};
