import { useTranslate } from "@pankod/refine";
import { Button, Col, Row, Spin } from "antd";
import { PageContainer } from "components/pageContainer/pageContainer";
import { FC } from "react";
import { Link } from "react-router-dom";
import { useInfluencerBilling } from "./useInfluencerBilling";

interface InfluencerBillingProps {
    influencerId: string;
}
export const InfluencerBilling: FC<InfluencerBillingProps> = ({ influencerId }) => {
    const [billing, loading] = useInfluencerBilling(influencerId);
    const t = useTranslate();

    return (
        <Row style={{ marginTop: "20px", marginBottom: "75px" }}>
            <Col xs={24} sm={24} md={10} span={10}>
                <PageContainer
                    pageTitle={t("global.payment-information")}
                    helperContent={t("global.influencer.wallet.helper-content.payment-info")}
                >
                    {loading ? (
                        <div className="loading-container">
                            <Spin />
                        </div>
                    ) : billing ? (
                        <>
                            <Row style={{ display: "flex", flexDirection: "column" }}>
                                <h3 style={{ fontWeight: "bold" }}>{t("global.payment-information")}</h3>
                                <p>
                                    {billing.hasExceptionLicence ? (
                                        <span>{t("global.bill-exception-licence")}</span>
                                    ) : billing.isBillActive ? (
                                        <span>{t("global.invoice")}</span>
                                    ) : (
                                        <span>{t("global.cantinvoice")}</span>
                                    )}
                                </p>
                                <p>
                                    {billing.isBillActive ? (
                                        <span>
                                            {billing.companyName} - {t("global.company-type-" + billing.companyType)}
                                        </span>
                                    ) : (
                                        <div>
                                            <span>
                                                {billing.fullName} - {billing.identityNumber}
                                            </span>
                                            <br />
                                            <span>{billing.address}</span>
                                        </div>
                                    )}
                                </p>
                                <p>
                                    {billing.isBillActive ? (
                                        <span>
                                            {billing.bankName} - {billing.iban}
                                        </span>
                                    ) : (
                                        <div>
                                            <span>
                                                {billing.bankName} - {billing.iban}
                                            </span>{" "}
                                            <br />
                                        </div>
                                    )}
                                </p>
                            </Row>

                            <Row style={{ display: "flex", justifyContent: "end" }}>
                                <Col>
                                    <Link to="/profile/editPayment">
                                        <Button type="default">{t("global.payment-information-update")}</Button>
                                    </Link>
                                </Col>
                            </Row>
                        </>
                    ) : (
                        <Row style={{ display: "flex", justifyContent: "end" }}>
                            <Col>
                                <Link to="/profile/editPayment">
                                    <Button type="default">{t("global.payment-information-add")}</Button>
                                </Link>
                            </Col>
                        </Row>
                    )}
                </PageContainer>
            </Col>
        </Row>
    );
};
