import { LayoutWrapper, useNavigation, useTranslate } from "@pankod/refine";
import { Button, Card, Col, Layout, Row, Tabs, Typography } from "antd";
import { useState } from "react";
import { CompanyRegisterPage, PhenomenonRegisterPage } from "./register";
import { Login } from "./login/login";
import {
    containerStyles,
    loginPageLayoutRowStyle,
    loginPageLayoutStyle,
    loginTabWorkaroutStyle,
    registerTabWorkaroutStyle,
    secondTitleStyle,
    titleStyle,
} from "./styles";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { ForgotPasswordPage } from "./forgotPassword/forgotPassword";
import { hideMessage, setActiveAuthTab, toggleForgotPassword } from "./authSlice";
import Header from "./header";
import "./authPage.scss";

const { Title } = Typography;

export const AuthPage = () => {
    const { TabPane } = Tabs;
    const t = useTranslate();
    const {push} = useNavigation();
    const [message, setMessage] = useState(t("pages.login.message"));
    const [pageTitle, setPageTitle] = useState(t("pages.login.title"));
    const dispatch = useAppDispatch();
    const registerMessage = useAppSelector((state) => state.auth.message);
    const forgotPassword = useAppSelector((state) => state.auth.forgotPassword);
    const activeTab = useAppSelector((state) => state.auth.activeAuthTab);
    const needsShowMessage = useAppSelector((state) => state.auth.needsShowMessage);
    window.document.addEventListener("needSign", function (e:any) {
        push("/sign-agreements",e.detail);
    });
    return (
        <LayoutWrapper
            Sider={() => <></>}
            Footer={() => <></>}
            Title={() => <></>}
            Header={() => (
                <>
                    <Header
                        onLoginClicked={() => {
                            dispatch(toggleForgotPassword(false));
                            dispatch(hideMessage());
                            dispatch(setActiveAuthTab("1"));
                        }}
                        onRegisterClicked={() => {
                            dispatch(toggleForgotPassword(false));
                            dispatch(hideMessage());
                            dispatch(setActiveAuthTab("2"));
                        }}
                    />
                </>
            )}
        >
            <div style={{ height: "400px", margin: "-30px -10px 0px -25px" }}></div>
            <Layout style={loginPageLayoutStyle}>
                <Row justify="center" align="middle" style={loginPageLayoutRowStyle}>
                    {!forgotPassword && !needsShowMessage && (
                        <Row justify="center">
                            <Title level={2} style={titleStyle}>
                                {pageTitle}
                            </Title>
                        </Row>
                    )}
                    {!forgotPassword && !needsShowMessage && (
                        <Row justify="center">
                            <Title level={3} style={secondTitleStyle}>
                                {message}
                            </Title>
                        </Row>
                    )}
                    <Row justify="center" id="welcomeCard">
                        <Col xs={24}>
                            <div style={containerStyles}>
                                <style>
                                    {loginTabWorkaroutStyle}
                                    {registerTabWorkaroutStyle}
                                </style>
                                <Card headStyle={{ borderBottom: 0 }}>
                                    {!needsShowMessage && !forgotPassword && (
                                        <Tabs
                                            centered
                                            animated={true}
                                            id="login-tabs"
                                            activeKey={activeTab}
                                            onChange={(index) => {
                                                dispatch(setActiveAuthTab(index));
                                                if (index === "1") {
                                                    setMessage(t("pages.login.message", "Lütfen Hesabınıza Giriş Yapınız."));
                                                    setPageTitle(t("pages.login.title", "Hoş geldiniz."));
                                                } else if (index === "2") {
                                                    setMessage(t("pages.login.complete", "Lütfen hesabınızı oluşturmak için tamamlayın"));
                                                    setPageTitle(t("pages.login.hello", "Merhaba!"));
                                                }
                                            }}
                                        >
                                            <TabPane tab={t("pages.login.signin", "Giriş Yap")} key="1">
                                                <Login
                                                    showRegisterTab={() => {
                                                        dispatch(setActiveAuthTab("2"));
                                                    }}
                                                />
                                            </TabPane>
                                            <TabPane tab={t("pages.login.signup", "Kayıt Ol")} key="2">
                                                <RegisterTabs />
                                            </TabPane>
                                        </Tabs>
                                    )}
                                    {needsShowMessage && (
                                        <div
                                            style={{
                                                minHeight: "300px",
                                                display: "flex",
                                                alignContent: "center",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                            }}
                                        >
                                            <Title level={2} style={{ ...secondTitleStyle, verticalAlign: "middle" }}>
                                                {registerMessage}
                                            </Title>

                                            <Button
                                                type="default"
                                                onClick={() => {
                                                    dispatch(hideMessage());
                                                    dispatch(setActiveAuthTab("1"));
                                                }}
                                            >
                                                {t("pages.auth.message.returnMain", "Giriş Sayfasına Dön")}
                                            </Button>
                                        </div>
                                    )}

                                    {forgotPassword && (
                                        <div>
                                            <ForgotPasswordPage />
                                        </div>
                                    )}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                </Row>
            </Layout>
        </LayoutWrapper>
    );
};

export const RegisterTabs = () => {
    const { TabPane } = Tabs;
    const t = useTranslate();

    return (
        <Tabs defaultActiveKey="1" centered animated={true} id="registerTabs">
            <TabPane tab={t("pages.login.iAmPhenomenon", "Fenomenim")} key="1">
                <PhenomenonRegisterPage />
            </TabPane>
            <TabPane tab={t("pages.login.iAmBrand", "Markayım")} key="2">
                <CompanyRegisterPage />
            </TabPane>
        </Tabs>
    );
};
