import { RouteProps } from "react-router-dom";
import { CampaignDetail } from "./campaignDetail";
import CampaignPerformance from "./CampaignPerformance";

export const campaignDetailRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: CampaignDetail,
            path: "/campaign/detail",
        },
        {
            exact: true,
            component: CampaignDetail,
            path: "/campaign/detail/:campaignId",
        },
        {
            exact: true,
            component: CampaignPerformance,
            path: "/campaign/detail/performance/:campaignId",
        },
    ];
};
