import { LayoutWrapper, useNavigation, useTranslate } from "@pankod/refine";
import { Button, Col, Row, Typography } from "antd";
import { PageContainer } from "components/pageContainer/pageContainer";

const { Title, Paragraph } = Typography;

export const CampaignOfferPage = () => {
    const t = useTranslate();
    const { push } = useNavigation();

    return (
        <LayoutWrapper>
            <Row gutter={[16, 16]}>
                <Col xs={24} sm={24} md={12} span={12}>
                    <PageContainer pageTitle={"Self Servis " + t("global.campaignn")} helperContent={"Buraya helper içeriği gelecek"}>
                        <Row justify="center">
                            <Title level={4} style={{ textAlign: "center" }}>
                                {t("global.brand.dashboard.create-campaign.self-service-title")}
                            </Title>
                            <Paragraph style={{ textAlign: "center" }}>
                                {t("global.brand.dashboard.create-campaign.self-service-content")}
                            </Paragraph>
                            <br />
                            <Button
                                type="default"
                                onClick={() => {
                                    push("/campaign/create/apply/brand");
                                }}
                            >
                                {t("posts.titles.create")}
                            </Button>
                        </Row>
                    </PageContainer>
                </Col>
                <Col xs={24} sm={24} md={12} span={12} style={{ marginBottom: "50px" }}>
                    <PageContainer pageTitle={"Yönetilen Kampanya"} helperContent={"Buraya helper içeriği gelecek"}>
                        <Row justify="center">
                            <Title level={4} style={{ textAlign: "center" }}>
                                {t("global.brand.dashboard.create-campaign.create-campaign-by-admin-title")}
                            </Title>

                            <Paragraph style={{ textAlign: "center" }}>
                                {t("global.brand.dashboard.create-campaign.create-campaign-by-admin-content")}
                            </Paragraph>
                            <br />
                            <Button
                                type="default"
                                onClick={() => {
                                    push("/campaign/create/apply/fenomio");
                                }}
                            >
                                {t("posts.titles.create")}
                            </Button>
                        </Row>
                    </PageContainer>
                </Col>
            </Row>
        </LayoutWrapper>
    );
};
