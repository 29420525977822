import { Category } from "../../pages/campaign/Model/GetAllCategoryData";
import { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";
import { Fenomio_Common_BaseModels_Api_DataResponse } from "../models/Fenomio_Common_BaseModels_Api_DataResponse";

export class CategoryService {
    public static getAllCategory({
        acceptLanguage,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<Category[]>> {
        return __request({
            method: "GET",
            path: `categories/getall`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCategoryById({
        id,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        id: string;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<Category[]>> {
        return __request({
            method: "GET",
            path: `categories/` + id,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static searchCategory({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `categories/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static updateCategory({ body, acceptLanguage, id }: { body: any; acceptLanguage?: any; id: string }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `categories/` + id,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }
    public static createCategory({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `categories/create`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }
}
