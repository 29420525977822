export type TransactionType = {
    id:1|2|3|4,
    name:"campaign"|"payment"|"bonus"|"refund"
}

export const Bonus:TransactionType = {
    id:3,
    name:"bonus"
}

export const Refund:TransactionType = {
    id:4,
    name:"refund"
}

export const Payment:TransactionType = {
    id:2,
    name:"payment"
}

export const Campaign:TransactionType = {
    id:1,
    name:"campaign"
}

export const getTransactionTypeName = (id:number)=>{
    switch (id){
        case 1:
            return "campaign";
        case 2:
            return "payment";
        case 3:
            return "bonus";
        case 4:
            return "refund";
        default:
            return "campaign";
    }
}