import { Authenticated, Button, Col, Input, LayoutWrapper, Modal, Row, useTranslate } from "@pankod/refine";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { ShowCampaignExplanation } from "components/atomics/show-items";
import { PageContainer } from "components/pageContainer/pageContainer";
import { RefundAndBonusTable } from "components/tables/refund-and-bonus-table";
import { localizationHelper } from "helpers/globalizationHelper";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { useEffect, useMemo, useState } from "react";
import { Money } from "services/models/money";
import { CampaignInfluencerStates, CampaignStates, CampaignType } from "../../../Constanst";
import { CampaignsService } from "../../../services";
import { EarningChart } from "./components/earningChart";
import { PerformanceChart } from "./components/performanceChart";
import { InfiniteList } from "./components/scrollingList";
import { Transactions } from "./components/transactions/component";
import { MyCampaignDetailProps } from "./wrapper";

export const AmazonCampaign = (props: MyCampaignDetailProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newLinkUrl, setNewLinkUrl] = useState("");
    const [linkName, setLinkName] = useState("");
    const [disable, setDisable] = useState<boolean>(false);
    const [isLoading, setLoading] = useState(false);

    const [isFailModalVisible, setIsFailModalVisible] = useState(false);
    const [createFailMessage, setCreateFailMessage] = useState("");

    const t = useTranslate();

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const createLink = () => {
        if (!newLinkUrl.startsWith(props.campaignDetail?.link)) {
            //TODO: Bu mesaj geçici olarak eklendi. Diğer dillerdeki karşılığı eklendiğinde bu alan common.json'dan okunacak şekilde güncellenmeli. İlgili task linki:https://app.clickup.com/t/866a8mzxx
            setCreateFailMessage(
                "Yalnızca amazon.com.tr şeklinde Amazon Türkiye üzerinden komisyon kazanabilirsiniz. Lütfen Türkiye sitesinden bir link dönüştürün."
            );
            setIsFailModalVisible(true);
            return;
        }
        setLoading(true);
        CampaignsService.createCampaignLink({
            body: {
                campaignInfluencerId: props.campaignInfluencer.id,
                originalLink: newLinkUrl,
                name: linkName ? linkName : "Auto Generated",
            },
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then(
                (resp) => {
                    props.refresh();
                    setLoading(false);
                },
                (fail) => {
                    setCreateFailMessage(fail?.body?.message);
                    setIsFailModalVisible(true);
                    setLoading(false);
                }
            )
            .catch((err) => {
                setCreateFailMessage(err?.message);
                setIsFailModalVisible(true);
                setLoading(false);
            });
    };

    useEffect(() => {
        const disableState =
            props?.campaignDetail?.campaignState !== CampaignStates.Accepted ||
            props?.campaignDetail?.campaignInfluencerState === null ||
            props?.campaignDetail?.campaignInfluencerState !== CampaignInfluencerStates.Accepted;

        setDisable(disableState);
    }, [props.campaignDetail]);

    const valuesTotal = useMemo(() => {
        let bonuses = 0;
        let refunds = 0;
        props.performance?.bonusesAndRefunds?.forEach((item: any) => {
            if (item.transactionType === 4) refunds += item.amount;
            else if (item.transactionType === 3) bonuses += item.amount;
        });
        return { bonuses, refunds };
    }, [props.performance?.bonusesAndRefunds]);

    return (
        <Authenticated>
            <style>
                {`
                    .head{
                        font-weight: bold;
                    }

                    .button{
                        background-color: #952AE7;
                        color: white;
                    }
                    .button:hover{
                        background-color: #952AE7;
                        color: white;
                    }
                    .link-row{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px 0px;
                    }
                    .link-input{
                        color: #C2C9D1;
                        width: 15vw;
                    }
                `}
            </style>
            <LayoutWrapper>
                <Modal visible={isFailModalVisible} centered footer={[]} onCancel={() => setIsFailModalVisible(false)}>
                    <div style={{ textAlign: "center" }}>
                        <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>{t("global.warning")}</h3>
                        <p>{createFailMessage}</p>
                    </div>
                </Modal>
                <FenBreadCrumb
                    items={[
                        {
                            name: t("global.myCampaign"),
                            url: "/campaign/list",
                        },
                        {
                            name: props.campaignDetail?.name,
                            url: "",
                        },
                    ]}
                />
                <Row gutter={[16, 16]}>
                    {!RoleHelper.isInRole(Roles.Admin) && !RoleHelper.isInRole(Roles.Root) && (
                        <Col xs={24} sm={24} md={12} span={12}>
                            <PageContainer
                                pageTitle={props.campaignDetail?.name}
                                helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                            >
                                <Row gutter={[20, 0]}>
                                    <Col xs={24} sm={24} md={12} span={12}>
                                        <Row>
                                            <h3 className="head">{t("global.campaignState")}</h3>
                                        </Row>
                                        <Row>
                                            <span className="clickable-span">{props.showCampaignState}</span>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.campaign-name")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{props.campaignDetail?.name}</p>
                                        </Row>
                                        <ShowCampaignExplanation description={props.campaignDetail?.description} />
                                        <Row>
                                            <h3 className="head">{t("global.location")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{props.campaignDetail?.country}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("campaign.create.step2.categoriesLabel")}</h3>
                                        </Row>
                                        <Row>
                                            <p>
                                                {props.campaignDetail?.categories && props.campaignDetail?.categories.length > 0 ? (
                                                    (props.campaignDetail?.categories as any[])?.map((c) => " #" + c.name)
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </p>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} span={12}>
                                        <Row>
                                            <h3 className="head">{t("campaign.create.step3.startDateLabel")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{props.campaignDetail?.startDate}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.end-date")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{" - "}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.commissionRates")}</h3>
                                        </Row>
                                        <Row>
                                            {(props.campaignDetail?.commissionRates as [])?.map((cr) => (
                                                <p>{cr}</p>
                                            ))}
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.bonusInformation")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{props.campaignDetail?.bonusDescription}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.commissionEarnings")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{new Money(props.performance?.totalInfluencerEarning).toStringWithCurrency("₺")}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.bonuses")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{new Money(valuesTotal.bonuses).toStringWithCurrency("₺")}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.refunds")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{new Money(valuesTotal.refunds).toStringWithCurrency("₺")}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.netEarnings")}</h3>
                                        </Row>
                                        <Row>
                                            <p>
                                                {new Money(
                                                    props.performance?.totalInfluencerEarning + valuesTotal.bonuses - valuesTotal.refunds
                                                ).toStringWithCurrency("₺")}
                                            </p>
                                        </Row>
                                    </Col>
                                </Row>
                            </PageContainer>
                        </Col>
                    )}
                    {!RoleHelper.isInRole(Roles.Admin) && !RoleHelper.isInRole(Roles.Root) && (
                        <Col xs={24} sm={24} md={12} span={12}>
                            <PageContainer
                                pageTitle={t("global.mylinks")}
                                helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                            >
                                <Row>
                                    <Col span={24}>
                                        <Row className="link-row">
                                            <h2 style={{ color: "#952AE7", fontWeight: "bold" }}>Link Oluştur</h2>
                                            <Button
                                                disabled={disable}
                                                className="button"
                                                onClick={() => {
                                                    window.open(props.campaignDetail?.link, "_blank");
                                                }}
                                            >
                                                Siteye Git
                                            </Button>
                                        </Row>
                                    </Col>
                                </Row>
                                <hr style={{ borderTop: "1px solid #952AE7" }} />
                                <Row style={{ padding: "20px 0px" }}>
                                    <Col xs={24} sm={24} md={12} span={12}>
                                        <Row>
                                            <h3>{t("global.link")}</h3>
                                        </Row>
                                        <Row>
                                            <Input
                                                disabled={disable}
                                                onChange={(v) => setNewLinkUrl(v.target.value)}
                                                className="link-input"
                                                placeholder="https://"
                                            />
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} span={12}>
                                        <Row>
                                            <h3>{t("global.linkname")}</h3>
                                        </Row>
                                        <Row>
                                            <Input
                                                disabled={disable}
                                                onChange={(e) => setLinkName(e.target.value)}
                                                className="link-input"
                                            />
                                        </Row>
                                    </Col>
                                    <Row
                                        style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            width: "100%",
                                            padding: "20px 0px",
                                        }}
                                    >
                                        <Button
                                            loading={isLoading}
                                            onClick={() => createLink()}
                                            disabled={!newLinkUrl || disable}
                                            type="primary"
                                        >
                                            {t("buttons.create")}
                                        </Button>
                                    </Row>
                                    <Modal visible={isModalVisible} centered footer={[]} onCancel={handleCancel}>
                                        <div style={{ textAlign: "center" }}>
                                            <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>
                                                {t("global.campaignGet")}
                                            </h3>
                                            <p>{t("global.accountCheck")}</p>
                                        </div>
                                    </Modal>
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <InfiniteList
                                        refresh={props.refresh}
                                        datas={props.campaignDetail?.targetLinks ? props.campaignDetail?.targetLinks : []}
                                    />
                                </Row>
                            </PageContainer>
                        </Col>
                    )}
                    <Col span={24}>
                        <PageContainer
                            pageTitle={t("global.conversions")}
                            helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                        >
                            <>
                                <PerformanceChart
                                    campaignType={CampaignType.selling}
                                    changeDate={props.changeDate}
                                    changePlatform={props.changePlatform}
                                    performance={props.performance}
                                />
                                <Transactions id={props.campaignInfluencer.id} />
                            </>
                        </PageContainer>
                    </Col>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <PageContainer
                            pageTitle={t("global.performancee")}
                            helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                        >
                            <EarningChart campaignType={CampaignType.selling} performance={props.performance} />
                        </PageContainer>
                    </Col>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <RefundAndBonusTable
                            containerTitle={t("global.refundsAndBonuses")}
                            helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                            bonusesAndRefunds={props.performance?.bonusesAndRefunds}
                        />
                    </Col>
                </Row>
            </LayoutWrapper>
        </Authenticated>
    );
};
