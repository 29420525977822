import { Authenticated, LayoutWrapper } from "@pankod/refine";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { FunctionComponent } from "react";
import BrandDashboard from "./brandDashboard";
import { DashboardPage } from "./dashboard";
import InfluencerDashboard from "./influencerDashboard";

interface UserDashBoardProps {}

export const UserDashBoard: FunctionComponent<UserDashBoardProps> = (props: UserDashBoardProps) => {
    return (
        <Authenticated>
            <LayoutWrapper>
                {(RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root)) && <DashboardPage />}
                {RoleHelper.isInRole(Roles.Influencer) && <InfluencerDashboard />}
                {RoleHelper.isInRole(Roles.Brand) && <BrandDashboard />}
            </LayoutWrapper>
        </Authenticated>
    );
};
