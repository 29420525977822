import { EditOutlined } from "@ant-design/icons";
import { Form, Space, Button, Typography, Col, Row, useTranslate } from "@pankod/refine";
import { ShowCampaignExplanation } from "components/atomics/show-items";
import { TrackingPlatform } from "Constanst";
import { GetTrackingPlatformText } from "helpers/StateHelpers";
import { ICampaignData } from "pages/campaign/Model/CampaignList";
import { FunctionComponent } from "react";

const { Text, Title } = Typography;

interface DownloadCampaignStep4Props {
    campaignData: any;
    formFinished: (campaignData: ICampaignData) => void;
    triggerEdit: (pageNo: number) => void;
    approved: () => void;
    loading: boolean;
    brands: any[];
    categories: any[];
    countries: any[];
    back: any;
    isEditPage: boolean;
}

export const DownloadCampaignStep4: FunctionComponent<DownloadCampaignStep4Props> = (props: DownloadCampaignStep4Props) => {
    const t = useTranslate();

    const getAdditionalProductGroups = () => {
        const items: any[] = [];

        for (let i = 0; i < 999; i++) {
            if (props.campaignData["productGroup" + i] && props.campaignData["influencerComissionRatio" + i]) {
                items.push(
                    <div style={{ marginTop: "10px" }} key={i}>
                        <Title level={5}>Ürün Grubu</Title>
                        <Text>{props.campaignData["productGroup" + i]}</Text>

                        <Title level={5}>Gösterilecek Fenomen Komisyonu</Title>
                        <Text>{props.campaignData["influencerComissionRatio" + i]}</Text>
                    </div>
                );
            } else {
                break;
            }
        }

        return items;
    };

    return (
        <Form
            layout="vertical"
            onFinish={(values) => {
                props.approved();
            }}
        >
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "space-between" }}>
                <Title style={{ color: "#952ae7" }} level={4}>
                    {t("global.campaign")}
                </Title>
                <Button
                    type="link"
                    onClick={() => {
                        props.triggerEdit(0);
                    }}
                >
                    <EditOutlined />
                </Button>
            </Space>
            <hr color={"#952ae7"} />
            <Row style={{ marginTop: "25px" }} gutter={[16, 16]}>
                <Col xs={24} sm={24} md={7} span={7}>
                    <Title level={5}>{t("campaign.create.step1.brandNameLabel")}</Title>
                    <Text>{props.brands.filter((p) => p.id === props.campaignData.brandName)[0].name}</Text>

                    {props.campaignData.trackingPlatform && props.campaignData.trackingPlatform === TrackingPlatform.BcnMoniteze && (
                        <>
                            <Title level={5}>{t("campaign.create.step1.influencerBrandNameLabel")}</Title>
                            <Text>{props.brands.filter((p) => p.id === props.campaignData.influencerBrandName)[0].name}</Text>
                        </>
                    )}

                    <Title level={5}>{t("campaign.create.step1.campaignNameLabel")}</Title>
                    <Text>{props.campaignData.campaignName}</Text>
                </Col>
                <Col xs={24} sm={24} md={7} span={7}>
                    <ShowCampaignExplanation description={props.campaignData.campaignExplanation} />

                    <Title level={5}>{t("campaign.create.step1.campaignLinkLabel")}</Title>
                    <Text>{props.campaignData.campaignLink}</Text>
                </Col>
                <Col xs={24} sm={24} md={7} span={7}>
                    <Title level={5}>{t("global.trackingPlatform")}</Title>
                    <Text>{props.campaignData.trackingPlatform && GetTrackingPlatformText(props.campaignData.trackingPlatform)} </Text>

                    <Title level={5}>{t("global.trackingCode")}</Title>
                    <Text>{props.campaignData.trackingCode ? props.campaignData.trackingCode : "-"}</Text>
                </Col>
            </Row>
            <br />
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "space-between" }}>
                <Title style={{ color: "#952ae7" }} level={4}>
                    {t("campaign.create.step2.stepTitle")}
                </Title>
                <Button
                    type="link"
                    onClick={() => {
                        props.triggerEdit(1);
                    }}
                >
                    <EditOutlined />
                </Button>
            </Space>
            <hr color={"#952ae7"} />
            <Row style={{ marginTop: "25px" }} gutter={[16, 16]}>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("global.location")}</Title>
                    <Text>{props.countries.filter((p) => p.id === props.campaignData.country)[0].text}</Text>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("global.categories")}</Title>
                    <Text>
                        {props.categories
                            .filter((c) => props.campaignData.categories?.includes(c.id))
                            .map((c) => "#" + c.name)
                            .join(" - ")}
                    </Text>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("campaign.create.step2.whitelistCategoriesLabel")}</Title>
                    <Text>
                        {props.categories
                            .filter((c) => props.campaignData.whitelistCategories?.includes(c.id))
                            .map((c) => "#" + c.name)
                            .join(" - ")}
                    </Text>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Title level={5}>{t("campaign.create.step2.blacklistCategoriesLabell")}</Title>
                    <Text>
                        {props.categories
                            .filter((c) => props.campaignData.blacklistCategories?.includes(c.id))
                            .map((c) => "#" + c.name)
                            .join(" - ")}
                    </Text>
                </Col>
            </Row>
            <br />
            <Space direction="horizontal" style={{ width: "100%", justifyContent: "space-between" }}>
                <Title style={{ color: "#952ae7" }} level={4}>
                    {t("campaign.create.step3.stepTitle")}
                </Title>
                <Button
                    type="link"
                    onClick={() => {
                        props.triggerEdit(2);
                    }}
                >
                    <EditOutlined />
                </Button>
            </Space>
            <hr color={"#952ae7"} />
            <Row style={{ marginTop: "25px" }} gutter={[16, 16]}>
                <Col xs={24} sm={24} md={7} span={7}>
                    <Title level={5}>{t("global.start-date")}</Title>
                    <Text>{(props.campaignData.startDate as any).format("YYYY-MM-DD")}</Text>

                    <Title level={5}>{t("campaign.create.step4.influencerCommissionRate")}</Title>
                    <Text>{props.campaignData.influencerCommissionRate}</Text>
                </Col>
                <Col xs={24} sm={24} md={7} span={7}>
                    <Title level={5}>{t("global.end-date")}</Title>
                    <Text>{props.campaignData.endDate && (props.campaignData.endDate as any).format("YYYY-MM-DD")}</Text>

                    <Title level={5}>{t("global.productGroup")}</Title>
                    <Text>{props.campaignData.productGroup}</Text>

                    <Title level={5}>{t("global.influencerComissionRatioo")}</Title>
                    <Text>{props.campaignData.influencerComissionRatio}</Text>

                    {getAdditionalProductGroups()}
                </Col>
                <Col xs={24} sm={24} md={7} span={7}>
                    <Title level={5}>{t("campaign.create.step3.bonusinfo")}</Title>
                    <Text>{props.campaignData.bonusInformation}</Text>
                </Col>
            </Row>

            <Form.Item>
                <Space direction="horizontal" style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button onClick={() => props.back()} style={{ marginLeft: "auto" }} type="primary">
                        {t("buttons.back")}
                    </Button>
                    {props.isEditPage ? (
                        <Button style={{ marginLeft: "auto" }} htmlType="submit" type="default" loading={props.loading}>
                            {t("global.update")}
                        </Button>
                    ) : (
                        <Button type="default" style={{ marginLeft: "auto" }} htmlType="submit" loading={props.loading}>
                            {t("global.approve")}
                        </Button>
                    )}
                </Space>
            </Form.Item>
        </Form>
    );
};
