import { Form, Row, Button, Col, Input, DatePicker, useTranslate } from "@pankod/refine";
import { localizationHelper } from "helpers/globalizationHelper";
import { FunctionComponent } from "react";

const { RangePicker } = DatePicker;

export interface FilterComponentProps {
    doFilter: any;
    count: number | undefined;
}

export const NotificationFilterComponent: FunctionComponent<FilterComponentProps> = (props) => {
    const t = useTranslate();

    const filter = (values: any) => {
        props.doFilter(values);
    };

    return (
        <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={async (values) => {
                filter(values);
            }}
            autoComplete="off"
            style={{ width: "100%" }}
        >
            <Row>
                <Col span={6}>
                    <Form.Item label={t("global.notification")} name="notification">
                        <Input
                            style={{
                                width: "70%",
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col span={8}>
                    <Form.Item label={t("global.date")} name="date">
                        <RangePicker locale={localizationHelper.getLocaleForComponent()} style={{ width: "100%" }} />
                    </Form.Item>
                </Col>
                <Col offset={2} span={6}>
                    <Button htmlType={"submit"} type="ghost" style={{ marginTop: "28px" }}>
                        {t("buttons.filter")}
                    </Button>
                </Col>
            </Row>
            <Row style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                <p>
                    {props.count} {t("global.list-notification-count")}
                </p>
            </Row>
        </Form>
    );
};
