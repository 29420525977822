import { useTranslate } from "@pankod/refine";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { FunctionComponent, useState } from "react";
import { useParams } from "react-router-dom";
import { CampaignListForm, DisplayForm, EditForm } from "modules/brands/detail/admin";
import { useBrandDetail } from "modules/brands/detail/hooks";

export const AdminBrandDetail: FunctionComponent = () => {
    const [isDisplaying, setDisplaying] = useState<boolean>(true);

    const { brandId } = useParams<{ brandId: string }>();

    const [, brand, getBrand] = useBrandDetail(brandId);

    const t = useTranslate();

    const updateForm = () => {
        setDisplaying(true);
        getBrand();
    };

    return (
        <>
            <FenBreadCrumb
                items={[
                    { name: t("global.brands"), url: "/brands/list" },
                    { name: t("global.platforms"), url: "" },
                ]}
            />

            {isDisplaying && (
                <>
                    <DisplayForm brand={brand} setDisplaying={setDisplaying} />
                    <br />
                    <CampaignListForm brandId={brand.id} brandName={brand.name} />{" "}
                </>
            )}
            {!isDisplaying && <EditForm brand={brand} updateForm={updateForm} />}
        </>
    );
};