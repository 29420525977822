import { Button, Form, Input, Select, Space, useTranslate } from "@pankod/refine";
import { CampaignType, TrackingPlatform } from "Constanst";
import { ICampaignData } from "pages/campaign/Model/CampaignList";
import { FunctionComponent, useEffect, useState } from "react";
import { Col, Row } from "antd";
import { GetTrackingPlatforms } from "helpers/StateHelpers";

const { TextArea } = Input;

export interface ICampaignStep1Data {
    brandName: string | null;
    campaignName: string | null;
    campaignExplanation: string | null;
    campaignLink: string | null;
    trackingPlatform: number | null;
    trackingCode: string | null;
    actionExplanation?: string;
}

interface CampaignStep1Props {
    brands: any[];
    campaignType: CampaignType;
    campaignData: ICampaignData;
    formFinished: (campaignData: ICampaignStep1Data) => void;
    back: any;
    isEditPage: boolean;
}

export const CampaignStep1: FunctionComponent<CampaignStep1Props> = (props: CampaignStep1Props) => {
    const t = useTranslate();
    const [form] = Form.useForm();
    const [influencerBrandDisable, setInfluencerBrandDisable] = useState(true);

    const colStyle = {
        marginLeft: "10px",
        marginRight: "10px",
    };

    useEffect(() => {
        if (props.campaignData) {
            form.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.campaignData]);

    return (
        <Form
            form={form}
            layout={"vertical"}
            labelAlign={"right"}
            onFinish={(values) => {
                props.formFinished(values);
            }}
        >
            <Row>
                <Col style={colStyle} xs={24} sm={24} md={7} span={7}>
                    <Form.Item
                        label={t("campaign.create.step1.brandNameLabel")}
                        name="brandName"
                        initialValue={props.campaignData.brandName}
                        rules={[{ required: true, type: "string", message: t("campaign.create.step1.brandNameRule") }]}
                    >
                        <Select
                            disabled={props.isEditPage}
                            onSelect={(x: any) => {
                                form.setFieldsValue({ influencerBrandName: x });
                            }}
                            options={props.brands.map((brand) => {
                                return {
                                    label: brand.name,
                                    value: brand.id,
                                };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("campaign.create.step1.influencerBrandNameLabel")}
                        name="influencerBrandName"
                        initialValue={props.campaignData.influencerBrandName}
                    >
                        <Select
                            disabled={influencerBrandDisable || props.isEditPage}
                            options={props.brands.map((brand) => {
                                return {
                                    label: brand.name,
                                    value: brand.id,
                                };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("campaign.create.step1.campaignNameLabel")}
                        name="campaignName"
                        initialValue={props.campaignData.campaignName}
                        rules={[{ required: true, min: 3, max: 50, type: "string", message: t("campaign.create.step1.campaignNameRule") }]}
                    >
                        <Input disabled={props.isEditPage} />
                    </Form.Item>
                </Col>
                <Col style={colStyle} xs={24} sm={24} md={7} span={7}>
                    <Form.Item
                        label={t("campaign.create.step1.campaignLinkLabel")}
                        name="campaignLink"
                        initialValue={props.campaignData.campaignLink}
                        rules={[{ required: true, min: 3, max: 250, type: "url", message: t("campaign.create.step1.campaignLinkRule") }]}
                    >
                        <Input disabled={props.isEditPage} />
                    </Form.Item>
                    {props.campaignType !== CampaignType.clicking && (
                        <>
                            <Form.Item
                                rules={[{ required: true, message: t("campaign.create.step1.trackingPlatformError") }]}
                                label={t("campaign.create.step1.trackingPlatformLabel")}
                                name="trackingPlatform"
                                initialValue={props.campaignData.trackingPlatform}
                            >
                                <Select
                                    onChange={(v) => setInfluencerBrandDisable(v !== TrackingPlatform.BcnMoniteze)}
                                    disabled={props.isEditPage}
                                    placeholder={t("campaign.create.step1.trackingPlatformPlaceholder")}
                                    options={GetTrackingPlatforms()}
                                />
                            </Form.Item>
                            {props.campaignType !== CampaignType.download && (
                                <>
                                    <Form.Item
                                        rules={
                                            props.campaignType === CampaignType.selling
                                                ? []
                                                : [{ required: true, type: "string", message: "Lütfen bir takip kodu giriniz." }]
                                        }
                                        label={t("campaign.create.step1.trackingCodeLabel")}
                                        name="trackingCode"
                                        initialValue={props.campaignData.trackingCode}
                                    >
                                        <Input disabled={props.isEditPage} />
                                    </Form.Item>
                                </>
                            )}
                        </>
                    )}
                </Col>
                <Col style={colStyle} xs={24} sm={24} md={7} span={7}>
                    <Form.Item
                        label={t("campaign.create.step1.campaignExplanationLabel")}
                        name="campaignExplanation"
                        initialValue={props.campaignData.campaignExplanation}
                        rules={[
                            {
                                required: true,
                                min: 10,
                                max: 5000,
                                type: "string",
                                message: t("campaign.create.step1.campaignExplanationRule"),
                            },
                        ]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Space direction="horizontal" style={{ width: "100%", justifyContent: "flex-end" }}>
                    {!props.isEditPage && (
                        <Button onClick={() => props.back()} style={{ marginLeft: "auto" }} type="primary">
                            {t("buttons.back")}
                        </Button>
                    )}
                    <Button style={{ marginLeft: "auto" }} htmlType="submit" type="default">
                        {t("global.continue")}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
