import {
    BrandStatus,
    CampaignInfluencerStates,
    CampaignManagementType,
    CampaignStates,
    CampaignType,
    InfluencerStatus,
    Platforms,
    TrackingPlatform,
} from "Constanst";

type State = {
    label: string;
    value: number;
};

export const GetBrandStatuText = (statu: number): string => {
    if (BrandStatus[statu]) return ["global.brand.status.", statu].join("").getLocaleText();
    return "";
};

export const GetInfluencerStatuText = (statu: number): string => {
    if (InfluencerStatus[statu]) return ["global.brand.status.", statu].join("").getLocaleText();
    return "";
};

export const GetCampaignStateText = (statu: number): string => {
    if (CampaignStates[statu]) return ["global.campaign.states.", statu].join("").getLocaleText();
    return "";
};

export const GetCampaignStateViewInfluencerText = (statu: number): string => {
    if (CampaignStates[statu]) return ["global.campaign.view.influencer.states.", statu].join("").getLocaleText();
    return "";
};

export const GetCampaignInfluencerStateText = (statu: number): string => {
    if (CampaignInfluencerStates[statu]) return ["global.campaigninfluencer.states.", statu].join("").getLocaleText();
    return "";
};

export const GetCampaignInfluencerStateViewInfluencerText = (statu: number): string => {
    if (CampaignInfluencerStates[statu]) return ["global.campaigninfluencer.view.influencer.states.", statu].join("").getLocaleText();
    return "";
};

export const GetCampaignTypeText = (statu: number): string => {
    if (CampaignType[statu]) return ["global.campaigntype.", statu].join("").getLocaleText();
    return "";
};

export const GetCampaignTypeTextWithManagementType = (statu: number, managementType: number): string => {
    if (managementType === CampaignManagementType.SelfService) {
        return [GetCampaignTypeText(statu), "-", GetManagementTypeText(managementType)].join(" ");
    }
    return GetCampaignTypeText(statu);
};

export const GetPlatformText = (statu: number): string => {
    if (Platforms[statu]) return ["global.platform.", statu].join("").getLocaleText();
    return "";
};

export const GetManagementTypeText = (statu: number): string => {
    if (CampaignManagementType[statu]) return ["global.managementtype.", statu].join("").getLocaleText();
    return "";
};

export const GetTrackingPlatformText = (statu: number): string => {
    if (TrackingPlatform[statu]) return ["global.trackingplatform.", statu].join("").getLocaleText();
    return "";
};

export const GetBrandStatus = (): Array<State> => {
    return Object.values(BrandStatus)
        .filter((x) => Number.isSafeInteger(x))
        .map((statu: any) => {
            return {
                label: GetBrandStatuText(statu),
                value: statu,
            };
        });
};

export const GetInfluencerStatus = (): Array<State> => {
    return Object.values(InfluencerStatus)
        .filter((x) => Number.isSafeInteger(x))
        .map((statu: any) => {
            return {
                label: GetInfluencerStatuText(statu),
                value: statu,
            };
        });
};

export const GetCampaignStates = (): Array<State> => {
    return Object.values(CampaignStates)
        .filter((x) => Number.isSafeInteger(x))
        .map((statu: any) => {
            return {
                label: GetCampaignStateText(statu),
                value: statu,
            };
        });
};

export const GetCampaignStatesForInfluencer = (): Array<State> => {
    return Object.values(CampaignStates)
        .filter((x) => Number.isSafeInteger(x))
        .map((statu: any) => {
            return {
                label: GetCampaignStateViewInfluencerText(statu),
                value: statu,
            };
        });
};

export const GetCampaignInfluencerStates = (): Array<State> => {
    return Object.values(CampaignInfluencerStates)
        .filter((x) => Number.isSafeInteger(x))
        .map((statu: any) => {
            return {
                label: GetCampaignInfluencerStateText(statu),
                value: statu,
            };
        });
};

export const GetCampaignInfluencerStatesForInfluencer = (): Array<State> => {
    return Object.values(CampaignInfluencerStates)
        .filter((x) => Number.isSafeInteger(x))
        .map((statu: any) => {
            return {
                label: GetCampaignInfluencerStateViewInfluencerText(statu),
                value: statu,
            };
        });
};

export const GetCampaignTypes = (): Array<State> => {
    return Object.values(CampaignType)
        .filter((x) => Number.isSafeInteger(x) && x !== CampaignType.amazon)
        .map((statu: any) => {
            return {
                label: GetCampaignTypeText(statu),
                value: statu,
            };
        });
};

export const GetPlatforms = (): Array<State> => {
    return Object.values(Platforms)
        .filter((x) => Number.isSafeInteger(x))
        .map((statu: any) => {
            return {
                label: GetPlatformText(statu),
                value: statu,
            };
        });
};

export const GetManagementTypes = (): Array<State> => {
    return Object.values(Platforms)
        .filter((x) => Number.isSafeInteger(x))
        .map((statu: any) => {
            return {
                label: GetManagementTypeText(statu),
                value: statu,
            };
        });
};

export const GetTrackingPlatforms = (): Array<State> => {
    return Object.values(TrackingPlatform)
        .filter((x) => Number.isSafeInteger(x) && x !== TrackingPlatform.Website)
        .map((statu: any) => {
            return {
                label: GetTrackingPlatformText(statu),
                value: statu,
            };
        });
};

export const GetCampaignStateForInfluencer = (campaignState: CampaignStates, campaignInfluencerState: CampaignInfluencerStates) => {
    if (campaignState === undefined || campaignInfluencerState === undefined) return "";
    if (campaignInfluencerState === null) return GetCampaignStateViewInfluencerText(campaignState);

    let stateText = GetCampaignInfluencerStateViewInfluencerText(campaignInfluencerState);
    const exceptInfluencerBudgetStatus =
        campaignInfluencerState !== null && campaignInfluencerState === CampaignInfluencerStates.BudgetCompleted;
    if (exceptInfluencerBudgetStatus) return stateText;
    else if (
        campaignState === CampaignStates.WaitingForPayment ||
        campaignState === CampaignStates.BudgetCompleted ||
        campaignState === CampaignStates.Expired ||
        campaignState === CampaignStates.Paused
    ) {
        stateText = GetCampaignStateViewInfluencerText(campaignState);
    }
    return stateText;
};

export const GetActionForInfluencer = () => {};
