import { EVENTS } from "Constanst";
import { useModal } from "hooks";
import { PaymentsService } from "services/services/PaymentsService";

export const usePaymentApprovement = <T>() => {
    const [open, detail, handleClose] = useModal<T>(EVENTS.PAYMENT_APPROVEMENT_MODAL_IS_OPENED);

    function handleOk(id: string) {
        PaymentsService.createPayment({
            body: {
                influencerIds: [id],
            },
        }).finally(() => {
            handleClose();
        });
    }

    return [open, detail as T, handleClose, handleOk] as const;
};
