import { Authenticated, LayoutWrapper, useTranslate } from "@pankod/refine";
import { Tabs } from "antd";
import { ExportButton } from "components";
import { PageContainer } from "components/pageContainer/pageContainer";
import {
    AmazonReport,
    BonusCancelReport,
    BrandReport,
    CampaignReport,
    DailyReport,
    InfluencerReport,
    PayReport,
    PaymentReport,
} from "modules/reports";
import { FC, useMemo } from "react";

const ReportsPage: FC = () => {
    const t = useTranslate();

    const tabItems = useMemo(
        () => [
            {
                label: t("global.campaign"),
                key: "1",
                children: <CampaignReport />,
            },
            {
                label: t("global.phenomenon", "Fenomen"),
                key: "2",
                children: <InfluencerReport />,
            },
            {
                label: t("global.brand", "Marka"),
                key: "3",
                children: <BrandReport />,
            },
            {
                label: t("global.payearnings"),
                key: "4",
                children: <PayReport />,
            },
            {
                label: t("global.dailyreports"),
                key: "5",
                children: <DailyReport />,
            },
            {
                label: t("global.cancel-refund-bonus"),
                key: "6",
                children: <BonusCancelReport />,
            },
            {
                label: t("global.payments"),
                key: "7",
                children: <PaymentReport />,
            },
            {
                label: "Amazon",
                key: "8",
                children: <AmazonReport />,
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return (
        <Authenticated>
            <LayoutWrapper>
                <PageContainer pageTitle={t("global.reports")} helperContent="Buradan raporları görüntüleyebilirsiniz.">
                    <Tabs tabBarExtraContent={<ExportButton />} items={tabItems} destroyInactiveTabPane></Tabs>
                </PageContainer>
            </LayoutWrapper>
        </Authenticated>
    );
};

export default ReportsPage;
