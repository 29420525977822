import { FC } from "react";
import { useTranslate } from "@pankod/refine";

export const FilterResponse: FC = () => {
    const t = useTranslate();

    return (
        <>
            <h3> {t("reports.amazon.filter.result.tittle", "Başarılı")}</h3>
            <p> {t("reports.amazon.filter.result.description", "Filtreler başarıyla uygulandı")}</p>
        </>
    );
};
