export class Money extends Number {
    // eslint-disable-next-line @typescript-eslint/no-useless-constructor
    constructor(value: any) {
        super(value);
    }

    toString(): string {
        const roundedValue = Number(this.valueOf().toFixed(2));
        return roundedValue.toLocaleString("tr-TR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    }

    toStringWithCurrency(currency: string | undefined = "₺", currencyPosition: "right" | "left" = "left"): string {
        if (currencyPosition === "left") return `${this.toString()} ${currency || ""}`;
        return `${currency || ""} ${this.toString()}`;
    }

    toStringWithCurrencyEnum(currencyEnum: number ): string {
        let currencySymbol = '₺';
        if(currencyEnum === 1)
            currencySymbol = '$';
        else if(currencyEnum === 2)
            currencySymbol = '€';
        return `${this.toString()} ${currencySymbol}`;
    }
}
