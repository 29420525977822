import { PaginationConstant } from "Constanst";
import { localizationHelper } from "helpers/globalizationHelper";
import { useEffect, useState } from "react";
import { BrandsService } from "services";
import { Pagination } from "services/core/Pagination";

export const useAdminBrandList = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });
    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>({});
    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const getData = () => {
        setDataLoading(true);
        BrandsService.searchBrands({
            requestBody: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data?.data);
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount,
            });
            setSearch(false);
        });
    };

    const prepareFilterRequest = () => ({
        name: filterValues.brandName ?? null,
        sectorId: filterValues.sector ?? null,
        brandStatus: filterValues.status ?? null,
        countryId: filterValues.country ?? null,
        hasMarketingPermission: filterValues.hasMarketingPermission ?? null,
        pageNumber: pagination.current,
        pageSize: pagination.pageSize,
    });

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });
        setSearch(true);
    };

    const applyFilter = (values: any) => {
        setPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize,
        });
        setFilterValues(values);
        setSearch(true);
    };
    return [dataLoading, data, pagination, handleTableChange, applyFilter] as const;
};
