import { Table, useTranslate } from "@pankod/refine"
import { PaginationConstant } from "Constanst"
import { PageContainer } from "components/pageContainer/pageContainer"
import { Format } from "helpers/dateFormatHelper"
import { getTransactionTypeName } from "helpers/transaction-types"
import { useEffect, useState } from "react"
import { Pagination } from "services/core/Pagination"
import { BonusesAndRefundsResponse } from "services/models/BonusesAndRefundsResponse"
import { Money } from "services/models/money"

export type RefundAndBonusTableProps = {
    bonusesAndRefunds?:BonusesAndRefundsResponse,
    containerTitle:string,
    helperContent:string,
    isAllInfluencers?:boolean,
    pageChange?:(pageNumber:number) => void
}
export const RefundAndBonusTable = (props:RefundAndBonusTableProps) => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize
    });
    const t = useTranslate();
    const [columns,setColumns] = useState([
        {
            title: <span className="table-font">{t("global.createdOn")}</span>,
            dataIndex: "createdOn",
            key: "createdOn",
            render: (val:string)=>{
                return Format(val || "")
            }
        },
        {
            title: <span className="table-font">{t("global.amount")}</span>,
            dataIndex: "amount",
            key: "amount",
            //render: val => <span>&#8378; {val}</span>
            render: val => <span style={{whiteSpace:"nowrap"}}>{new Money(val).toStringWithCurrencyEnum(3)}</span>
        },
        {
            title: <span className="table-font">{t("global.description")}</span>,
            dataIndex: "description",
            key: "description",
        },
        {
            title: <span className="table-font">{t("global.transactionType")}</span>,
            dataIndex: "transactionType",
            key: "transactionType",
            render:(val:any)=>{
                const typeName = getTransactionTypeName(val);
                const stringKey = "global."+typeName;
                return t(stringKey);
            }
        },
    ]);

    useEffect(()=>{
        if(!props.isAllInfluencers || columns.length!==4)
            return;
        setColumns((val:any[]) => {
            const newVal = [...val];
            newVal.push({
                title: <span className="table-font">{t("global.phenomenon")}</span>,
                dataIndex: "influencerId",
                key: "influencerId"
            });
            return newVal;
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[props.isAllInfluencers]);

    useEffect(()=>{
        setPagination({
            current: props.bonusesAndRefunds?.pageInfo?.pageNumber || 1,
            pageSize: props.bonusesAndRefunds?.pageInfo?.pageSize || 1,
            total: props.bonusesAndRefunds?.pageInfo?.totalItemCount
        });
    },[props.bonusesAndRefunds])

    return (
        <PageContainer
            pageTitle={props.containerTitle}
            helperContent={props.helperContent}
        >
            <Table
                style={{ marginTop: "20px" }}
                rowKey="id"
                columns={columns}
                dataSource={props.bonusesAndRefunds?.data}
                scroll={{ x: "100%" }}
                pagination={pagination}
                onChange={(page)=>{
                    props.pageChange && props.pageChange(page.current||1);
                }}
            />
        </PageContainer>
    )
}