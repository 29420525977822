import { useTranslate } from "@pankod/refine";
import { Col, Row } from "antd";
import { FC } from "react";
import { ReportsService } from "services/services/ReportsService";
import { DateInput, ReportFilter } from "./filter";
import { useStandardReport } from "./hooks";
import { ReportTab } from "./ReportTab";
import { InfluencersService } from "services";
import { localizationHelper } from "helpers/globalizationHelper";

export const InfluencerReport: FC = () => {
    const [data, onFilter, filter] = useStandardReport<any>({ fetchData: ReportsService.getInfluencerReport });

    const t = useTranslate();

    const getPlatformCountFromData = (platform: any) => {
        if (data) {
            const filteredPlatforms = (data.socialMediaPlatforms as any[]).filter((smp) => smp.socialMedia === platform);

            if (filteredPlatforms.length > 0) {
                return filteredPlatforms[0].count;
            } else {
                return 0;
            }
        }
    };
    return (
        <ReportTab
            downloadAPIService={async (filter) =>
                InfluencersService.influencersReportExport({
                    body: {
                        CreatedFrom: filter.startDate,
                        CreatedTo: filter.endDate,
                        toMail: false,
                        pageSize: -1,
                    },
                    acceptLanguage: localizationHelper.getLocale(),
                })
            }
            filter={filter}
            fileName="influencer.xlsx"
        >
            <Row>
                <ReportFilter onFilter={onFilter}>
                    <DateInput />
                </ReportFilter>
            </Row>
            <hr />
            <Row
                style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "row",
                    width: "90%",
                    justifyContent: "space-between",
                    padding: "20px 0px",
                }}
            >
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>{t("global.inf-count")}</div>
                    <div>{data?.influencerCount}</div>
                </Col>
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>{t("global.active-inf-count")}</div>
                    <div>{data?.activeInfluencerCount}</div>
                </Col>
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>{t("global.inactive-inf-count")}</div>
                    <div>{data?.inActiveInfluencerCount}</div>
                </Col>
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>{t("global.never-active-inf-count")}</div>
                    <div>{data?.neverActiveInfluencerCount}</div>
                </Col>
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>{t("campaign.create.step2.approvedplatform")}</div>
                    <div>
                        <span>
                            <img alt={"youtube"} src="/images/social-media-icon/YOUTUBE36Px.svg" />
                        </span>{" "}
                        {getPlatformCountFromData("YouTube")}
                    </div>
                    <div>
                        <span>
                            <img alt={"instagram"} src="/images/social-media-icon/INSTAGRAM36Px.svg" />
                        </span>{" "}
                        {getPlatformCountFromData("Instagram")}
                    </div>
                    <div>
                        <span>
                            <img alt={"twitter"} src="/images/social-media-icon/TWITTER36Px.svg" />
                        </span>{" "}
                        {getPlatformCountFromData("Twitter")}
                    </div>
                    <div>
                        <span>
                            <img alt={"tiktok"} src="/images/social-media-icon/TIKTOK36Px.svg" />
                        </span>
                        {getPlatformCountFromData("TikTok")}
                    </div>
                    <div>
                        <span>
                            <img alt={"twitch"} src="/images/social-media-icon/TWITCH36Px.svg" />
                        </span>{" "}
                        {getPlatformCountFromData("Twitch")}
                    </div>
                    <div>
                        <span>
                            <img alt={"telegram"} src="/images/social-media-icon/TELEGRAM36Px.svg" />
                        </span>{" "}
                        {getPlatformCountFromData("Telegram")}
                    </div>
                    <div>
                        <span>
                            <img alt={"discord"} src="/images/social-media-icon/DISCORD36Px.svg" />
                        </span>{" "}
                        {getPlatformCountFromData("Discord")}
                    </div>
                </Col>
            </Row>
        </ReportTab>
    );
};
