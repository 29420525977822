import { EVENTS, PaginationConstant } from "Constanst";
import UserHelper from "helpers/userHelper";
import { useEffect, useState } from "react";
import { Pagination } from "services/core/Pagination";
import { Receipt } from "services/models/Receipt";
import { PaymentsService } from "services/services/PaymentsService";

export const useInfluencerReceipt = (influencerId?: string) => {
    const [loading, setLoading] = useState(true);
    const [receipts, setReceipts] = useState<Receipt[]>([]);
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });

    const userId = influencerId || UserHelper.getId();

    const getReceipts = async (pageNumber: number, pageSize: number) => {
        setLoading(true);

        PaymentsService.getPayments({
            body: {
                pageNumber,
                pageSize,
                influencerIds: [userId],
            },
        })
            .then((response) => {
                setReceipts((response.data?.data as Receipt[]) || []);

                setPagination({
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: response.data?.pageInfo.totalItemCount || 0,
                });
            })
            .finally(() => setLoading(false));
    };

    const handlePaginationChange = (page: Pagination) => {
        getReceipts(page.current, page.pageSize);
    };

    const handleShowReceiptDetail = (receipt: Receipt) => {
        window.dispatchEvent(new CustomEvent(EVENTS.MODAL_IS_OPENED, { detail: receipt }));
    };

    useEffect(() => {
        getReceipts(pagination.current, pagination.pageSize);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [loading, receipts, pagination, handlePaginationChange, handleShowReceiptDetail] as const;
};
