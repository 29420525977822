import { FC } from "react";
import { useUserInfo } from "./useUserInfo";
import { Col, Popover, Row } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import ProfileImg from "components/microparts/profile-img";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { useTranslate } from "@pankod/refine";

export const UserInfo: FC = () => {
    const [profileData, data] = useUserInfo();
    const t = useTranslate();

    console.log(profileData);
    return (
        <>
            <Row gutter={[8, 8]} className="ant-card-header">
                <Col span={23}>{t("global.reciepts")}</Col>
                <Col span={1}>
                    <Popover
                        content={t("global." + RoleHelper.getRoleLocale() + ".receipt-page.helper-content.general-view")}
                        placement="bottomRight"
                        title={t("global.reciepts")}
                        trigger="hover"
                    >
                        <InfoCircleOutlined />
                    </Popover>
                </Col>
            </Row>

            <Row>
                <Col>
                    <ProfileImg type={Roles.Influencer} alt="profil" width={150} />
                </Col>
                <Col style={{ padding: "50px 0px 0px 20px" }}>
                    <h2 style={{ color: "#9254de", fontWeight: "bold" }}>
                        {profileData?.name} {profileData?.surname}
                    </h2>
                    <p>
                        <span>{data} /</span> {profileData?.country || "Country"}
                    </p>
                    <p>{profileData?.createdOn || null}</p>
                </Col>
            </Row>
        </>
    );
};
