import { MoreOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Card, Col, DatePicker, Form, Input, Popover, Row, Space, Table, Tag, useNavigation, useTranslate } from "@pankod/refine";
import { Select } from "antd";
import { PageTitle } from "components/PageTitle/PageTitle";
import { CampaignManagementType, CampaignStates, CampaignStatesColors, CampaignType, PaginationConstant } from "Constanst";
import { GetCampaignStates, GetCampaignTypeTextWithManagementType } from "helpers/StateHelpers";
import { FunctionComponent, useEffect, useState } from "react";
import { localizationHelper } from "../../../../helpers/globalizationHelper";
import { CampaignsService, LookupsService } from "../../../../services";
import { Pagination } from "../../../../services/core/Pagination";
import { CategoryService } from "../../../../services/services/CategoryService";
import { Link } from "react-router-dom";
import { Money } from "services/models/money";

interface BrandCampaignListProps {}
const { RangePicker } = DatePicker;

export const BrandCampaignList: FunctionComponent<BrandCampaignListProps> = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [campaigns, setCampaigns] = useState<any>();
    const [categories, setCategories] = useState<any>();
    const [countries, setCountries] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>();

    const t = useTranslate();

    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) getCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        getCountries();
        getCategories();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCategories = () => {
        CategoryService.getAllCategory({ acceptLanguage: localizationHelper.getLocale() })
            .then((res) => {
                setCategories(res.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const getCountries = () => {
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
        });
    };

    const getCampaigns = () => {
        setDataLoading(true);
        CampaignsService.searchCampaigns({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                setDataLoading(false);
                setCampaigns(resp?.data.data);
                setPagination({
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: resp?.data?.pageInfo.totalItemCount,
                });
                setSearch(false);
            })
            .catch((error) => console.error(error));
    };

    const prepareFilterRequest = () => {
        const campaignSearch: any = {};
        if (filterValues) {
            campaignSearch.campaignType =
                filterValues.campaignType !== undefined && filterValues.campaignType !== null ? filterValues.campaignType : 0;
            campaignSearch.campaignName =
                filterValues.campaignName !== undefined && filterValues.campaignName !== null ? filterValues.campaignName : null;
            campaignSearch.startDate =
                filterValues.date !== undefined && filterValues.date !== null && filterValues.date[0]
                    ? new Date(filterValues.date[0]).toDateString()
                    : null;
            campaignSearch.endDate =
                filterValues.date !== undefined && filterValues.date !== null && filterValues.date[1]
                    ? new Date(filterValues.date[1]).toDateString()
                    : null;
            campaignSearch.campaignStates = filterValues.status ? [filterValues.status] : [];
            campaignSearch.state = filterValues.status ?? 0;

            campaignSearch.campaignInfluencerStates = [];

            campaignSearch.categoryIds = filterValues.categoryIds ?? [];

            campaignSearch.countryIds = filterValues.countryIds ? filterValues.countryIds : [];
            campaignSearch.pageNumber = pagination.current;
            campaignSearch.pageSize = pagination.pageSize;
            campaignSearch.platforms = filterValues.platforms ?? [];
            campaignSearch.campaignOrderState = "DateDesc";
        } else {
            campaignSearch.campaignType = 0;
            campaignSearch.campaignName = null;
            campaignSearch.startDate = null;
            campaignSearch.endDate = null;
            campaignSearch.state = 0;
            campaignSearch.categoryIds = [];
            campaignSearch.countryIds = [];
            campaignSearch.pageNumber = pagination.current;
            campaignSearch.pageSize = pagination.pageSize;
            campaignSearch.platforms = [];
            campaignSearch.campaignOrderState = "DateDesc";
        }
        return campaignSearch;
    };

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });
        setSearch(true);
    };

    const doFilter = (values: any) => {
        setPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize,
        });
        setFilterValues(values);
        setSearch(true);
    };

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Card>
                    <PageTitle title={t("global.myCampaign")} content={<>Buraya yardım içeriği gelecek</>} />
                    <br />

                    <Space direction="vertical" size="large" style={{ width: "100%" }}>
                        <FilterComponent
                            campaignCount={pagination?.total ? pagination.total : 0}
                            doFilter={doFilter}
                            categories={categories}
                            countries={countries}
                            campaignStates={GetCampaignStates()}
                        />
                        <ListComponent
                            pagination={pagination}
                            dataLoading={dataLoading}
                            onPageChange={handleTableChange}
                            data={campaigns}
                        />
                    </Space>
                </Card>
            </Col>
        </Row>
    );
};

interface FilterComponentProps {
    countries: any[];
    categories: any[];
    campaignStates: any[];

    doFilter: any;
    campaignCount: any;
}

const FilterComponent: FunctionComponent<FilterComponentProps> = (props) => {
    const filter = (values: any) => {
        props.doFilter(values);
    };
    const t = useTranslate();

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={{ categoryIds: [] }}
                        onFinish={async (values) => {
                            filter(values);
                        }}
                        autoComplete="off"
                    >
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Row gutter={[40, 0]}>
                                <Col xs={24} sm={24} md={6} span={6}>
                                    <Form.Item label={t("global.campaign-name")} name="campaignName">
                                        <Input
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6} span={6}>
                                    <Form.Item label={t("campaign.create.step2.categoriesLabel")} name="categoryIds">
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            allowClear
                                            mode={"multiple"}
                                            options={props.categories?.map((category) => {
                                                return {
                                                    label: category.name,
                                                    value: category.id,
                                                };
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6} span={6}>
                                    <Form.Item label={t("pages.brand.statu")} name="status">
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            allowClear
                                            options={props.campaignStates}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6} span={6}>
                                    <Form.Item label={t("global.country")} name="countryIds">
                                        <Select
                                            style={{
                                                width: "100%",
                                            }}
                                            allowClear
                                            mode="multiple"
                                            options={props.countries?.map((country) => {
                                                return {
                                                    label: country.text,
                                                    value: country.id,
                                                };
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={[40, 0]} style={{ marginTop: 20 }}>
                                <Col xs={24} sm={24} md={8} span={8}>
                                    <Form.Item label={t("global.date")} name="date">
                                        <RangePicker locale={localizationHelper.getLocaleForComponent()} style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col style={{ display: "flex", justifyContent: "end", float: "right" }} xs={24} sm={24} md={16} span={16}>
                                    <Button type="ghost" htmlType="submit">
                                        {t("buttons.filter")}
                                    </Button>
                                </Col>
                            </Row>
                            <Row style={{ paddingTop: "20px" }}>
                                <p>
                                    {props.campaignCount} {t("global.comand")}
                                </p>
                            </Row>
                        </Space>
                    </Form>
                    <hr />
                </Col>
            </Row>
        </>
    );
};

interface ListComponentProps {
    data: any[];
    onPageChange: any;
    pagination: any;
    dataLoading: any;
}

const ListComponent: FunctionComponent<ListComponentProps> = (props) => {
    const { push } = useNavigation();
    const t = useTranslate();

    const showCampaignDetail = (campaign: any) => {
        push("/campaign/detail", campaign);
    };

    const columns = [
        {
            title: <span className="table-font">Id</span>,
            dataIndex: "id",
            key: "id",
            render: (text: string, row: any, index: number) => text,
        },
        {
            title: <span className="table-font">{t("global.campaign-name")}</span>,
            dataIndex: "name",
            key: "name",
            render: (text: string, row: any, index: number) => (
                <Link to={"/campaign/detail/" + row.id}>
                    <Button type="link">{text}</Button>
                </Link>
            ),
        },
        {
            title: <span className="table-font">{t("global.campaignType")}</span>,
            dataIndex: "campaignType",
            key: "campaignType",
            render: (text: any, row: any) => <span>{GetCampaignTypeTextWithManagementType(row?.typeHash, row?.managementType)}</span>,
        },
        {
            title: <span className="table-font">{t("global.status")}</span>,
            dataIndex: "campaignStateModel",
            key: "campaignStateModel",
            render: ({ campaignStateText, campaignState }: any) => {
                return <Tag color={CampaignStatesColors[campaignState].toString()}>{campaignStateText}</Tag>;
            },
        },
        {
            title: <span className="table-font">{t("global.start")}</span>,
            dataIndex: "startDate",
            key: "startDate",
        },
        {
            title: <span className="table-font">{t("global.end")}</span>,
            dataIndex: "endDate",
            key: "endDate",
            render: (text: string, row: any) => {
                if (row.typeHash === CampaignType.amazon) {
                    return " - ";
                } else {
                    return text;
                }
            },
        },
        {
            title: <span className="table-font">{t("global.unit-price")}</span>,
            dataIndex: "unitPrice",
            key: "unitPrice",
            render: (text: string, row: any, index: number) => {
                if (row.typeHash !== CampaignType.selling) {
                    return <span style={{whiteSpace:"nowrap"}}>{new Money(row.unitPrice).toStringWithCurrency(row.currency)}</span>
                }

                return (
                    <Popover
                        placement="bottomRight"
                        content={
                            <Space direction="vertical" size="small">
                                <p>{t("global.earnings-rates")}</p>
                                <p>{t("global.earnings-rates")}</p>
                            </Space>
                        }
                    >
                        <Button type="link" onClick={() => {}}>
                            <span>
                                {t("global.earnings-rates")} <QuestionCircleOutlined />
                            </span>
                        </Button>
                    </Popover>
                );
            },
        },
        {
            title: <span className="table-font">{t("campaign.create.step3.totalAmount")}</span>,
            dataIndex: "budget",
            key: "budget",
            render: (num: Number, row:any) => <span style={{whiteSpace:"nowrap"}}>{new Money(num).toStringWithCurrency(row.currency)}</span>
        },

        {
            title: <span className="table-font">{t("campaign.create.step3.budgetUsingRatebudgetUsingRate")}</span>,
            dataIndex: "budgetUsingRate",
            key: "budgetUsingRate",
        },
        {
            title: <span className="table-font">{t("campaign.create.step3.transformationquantity")}</span>,
            dataIndex: "transformation",
            key: "transformation",
        },
        {
            title: <span className="table-font">{t("global.action")}</span>,
            key: "action",
            render: (text: string, row: any, index: number) => {
                if (row.campaignStateModel.campaignState === CampaignStates.WaitingForPayment) {
                    return (
                        <Button
                            type="link"
                            onClick={() => {
                                showCampaignDetail(row);
                            }}
                        >
                            {t("global.make-payment")}
                        </Button>
                    );
                } else if (row.managementType === CampaignManagementType.SelfService) {
                    return (
                        <Space size="middle">
                            <Popover
                                placement="bottomRight"
                                content={
                                    <Space direction="vertical" size="small">
                                        <Button
                                            type="link"
                                            onClick={() => {
                                                push("/influencers/list");
                                            }}
                                        >
                                            {t("global.invitePhenomenon")}
                                        </Button>
                                    </Space>
                                }
                                trigger="click"
                            >
                                <Button type="link">
                                    <MoreOutlined />
                                </Button>
                            </Popover>
                        </Space>
                    );
                }
            },
        },
    ];

    return (
        <Table
            columns={columns}
            pagination={props.pagination}
            dataSource={props.data}
            loading={props.dataLoading}
            onChange={(page) => props.onPageChange(page)}
            scroll={{ x: "100%" }}
        />
    );
};
