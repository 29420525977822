/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Fenomio_Common_Auth_Concrete_AccessTokenContract } from "../models/Fenomio_Common_Auth_Concrete_AccessTokenContract";
import type { Fenomio_Common_BaseModels_Api_DataResponse } from "../models/Fenomio_Common_BaseModels_Api_DataResponse";
import type { Fenomio_Contract_App_Login_ChangePasswordRequest } from "../models/Fenomio_Contract_App_Login_ChangePasswordRequest";
import type { Fenomio_Contract_App_Login_ForgotPasswordRequest } from "../models/Fenomio_Contract_App_Login_ForgotPasswordRequest";
import type { Fenomio_Contract_App_Login_ResendConfirmEmailRequest } from "../models/Fenomio_Contract_App_Login_ResendConfirmEmailRequest";
import type { Fenomio_Contract_App_Login_ResetPasswordRequest } from "../models/Fenomio_Contract_App_Login_ResetPasswordRequest";
import type { Fenomio_Contract_App_User_GetTokenContract } from "../models/Fenomio_Contract_App_User_GetTokenContract";
import type { Fenomio_Contract_App_User_RefreshTokenContract } from "../models/Fenomio_Contract_App_User_RefreshTokenContract";

import type { Fenomio_Contract_App_Login_ConfirmEmailRequest } from "../models/Fenomio_Contract_App_Login_ConfirmEmailRequest";

import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class LoginService {
    /**
     * Reset the password with the token if still is valid and not used.
     * @returns Fenomio_Common_BaseModels_Api_DataResponse Success
     * @throws ApiError
     */
    public static getLogin({
        id,
        acceptLanguage,
    }: {
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<string>> {
        return __request({
            method: "GET",
            path: `login/reset-password/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    /**
     * Reset the password using the token, new password, and new password confirmation.The token is in the mail link.
     * @returns Fenomio_Common_BaseModels_Api_DataResponse Success
     * @throws ApiError
     */
    public static postLogin({
        id,
        acceptLanguage,
        requestBody,
    }: {
        /** The forgot password token. **/
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
        /** The request Fenomio.Contract.App.Login.ResetPasswordRequest model. **/
        requestBody?: Fenomio_Contract_App_Login_ResetPasswordRequest;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<string>> {
        return __request({
            method: "POST",
            path: `login/reset-password/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    /**
     * Confirm email.The token is in the mail link.
     * @returns Fenomio_Common_BaseModels_Api_DataResponse Success
     * @throws ApiError
     */
    public static getLogin1({
        id,
        acceptLanguage,
    }: {
        /** The confirm email token. **/
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<string>> {
        return __request({
            method: "GET",
            path: `login/confirm-email/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    /**
     * Get Token
     * @returns Fenomio_Common_Auth_Concrete_AccessTokenContract Success
     * @throws ApiError
     */
    public static login({
        acceptLanguage,
        requestBody,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody?: Fenomio_Contract_App_User_GetTokenContract;
    }): CancelablePromise<Fenomio_Common_Auth_Concrete_AccessTokenContract> {
        return __request({
            method: "POST",
            path: `login/token`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    /**
     * Refresh Token
     * @returns Fenomio_Common_Auth_Concrete_AccessTokenContract Success
     * @throws ApiError
     */
    public static refreshToken({
        acceptLanguage,
        requestBody,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody?: Fenomio_Contract_App_User_RefreshTokenContract;
    }): CancelablePromise<Fenomio_Common_Auth_Concrete_AccessTokenContract> {
        return __request({
            method: "POST",
            path: `login/refresh-token`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    /**
     * Forgot Password
     * @returns Fenomio_Common_BaseModels_Api_DataResponse Success
     * @throws ApiError
     */
    public static forgotPassword({
        acceptLanguage,
        requestBody,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody?: Fenomio_Contract_App_Login_ForgotPasswordRequest;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<string>> {
        return __request({
            method: "POST",
            path: `login/forgot-password`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    /**
     * Confirm email.The confirm token is in the mail link.
     * @returns Fenomio_Common_BaseModels_Api_DataResponse Success
     * @throws ApiError
     */
    public static confirmEmail({
        id,
        acceptLanguage,
    }: {
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `login/confirm-email/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    /**
     * Change password with using old password, new password and new password confirm.
     * @returns Fenomio_Common_BaseModels_Api_DataResponse Success
     * @throws ApiError
     */
    public static changePassword({
        id,
        acceptLanguage,
        requestBody,
    }: {
        /** The user identifier. **/
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
        /** The request Fenomio.Contract.App.Login.ChangePasswordRequest model. **/
        requestBody?: Fenomio_Contract_App_Login_ChangePasswordRequest;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<string>> {
        return __request({
            method: "POST",
            path: `login/change-password/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    /**
     * Reset the password using the token, new password, and new password confirmation.The token is in the mail link.
     * @returns Fenomio_Common_BaseModels_Api_DataResponse Success
     * @throws ApiError
     */
    public static resetPassword({
        id,
        acceptLanguage,
        requestBody,
    }: {
        /** The user identifier. **/
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
        /** The request Fenomio.Contract.App.Login.ChangePasswordRequest model. **/
        requestBody?: Fenomio_Contract_App_Login_ResetPasswordRequest;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<string>> {
        return __request({
            method: "POST",
            path: `login/reset-password/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    /**
     * Resend confirm email.When user not access the account confirm mail he can be request a new confirm mail.
     * @returns Fenomio_Common_BaseModels_Api_DataResponse Success
     * @throws ApiError
     */
    public static postLogin5({
        acceptLanguage,
        requestBody,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody?: Fenomio_Contract_App_Login_ResendConfirmEmailRequest;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<string>> {
        return __request({
            method: "POST",
            path: `login/resend-confirm-email`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }
}
