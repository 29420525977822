import { InfoCircleOutlined } from "@ant-design/icons";
import { Authenticated, LayoutWrapper, Steps, useNavigation, useTranslate } from "@pankod/refine";
import { Card, Col, Popover, Row } from "antd";
import UserHelper from "helpers/userHelper";
import moment from "moment";
import { useEffect, useState } from "react";
import { CampaignManagementType, CampaignStates, CampaignType } from "../../../Constanst";
import { localizationHelper } from "../../../helpers/globalizationHelper";
import { CampaignsService, Fenomio_Common_BaseModels_Api_DetailItemDto, LookupsService } from "../../../services";
import { CategoryService } from "../../../services/services/CategoryService";
import PaymentButton from "../create/Payment";
import CampaignCreateResult from "../create/Result";
import { ICampaignData } from "../Model/CampaignList";
import {
    FirstPage,
    FourthPage,
    ICampaignStep1DataSelfService,
    ICampaignStep2DataSelfService,
    ICampaignStep3DataSelfService,
    SecondPage,
    ThirdPage,
} from "./Components/CreateCampaign";
import "./CreateCampaign.css";

const { Step } = Steps;

export const CreateByBrandPage = (props: any) => {
    const t = useTranslate();
    const { push } = useNavigation();

    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setLoading] = useState(false);

    const [categories, setCategories] = useState<any>([]);
    const [countries, setCountries] = useState<Fenomio_Common_BaseModels_Api_DetailItemDto[]>([]);
    const [isEditPage, setIsEditPage] = useState(false);
    const [campaignType, setCampaignType] = useState<CampaignType>(CampaignType.clicking);
    const [passPaymentPage, setPassPaymentPage] = useState<boolean>(false);

    const [campaignDetail, setCampaignDetail] = useState<any>(null);
    const [paymentFail, setPaymentFail] = useState<any>(false);
    const [campaignId, setCampaignId] = useState<number | null>(null);

    const currentUserId = UserHelper.getId();

    const checkPaymentCallback = () => {
        if (window.location.search) {
            let paramsStr = window.location.search;
            paramsStr = paramsStr.replace("?", "");
            const parameters = paramsStr.split("&");
            const failParam = parameters.filter((p) => p.includes("fail"))[0];
            setPaymentFail(failParam.split("=")[1]);
            if (failParam.split("=")[1] === "true") {
                setCurrentPage(5);
            } else {
                const campaignId = parseInt(parameters.filter((p) => p.includes("campaignId"))[0].split("=")[1]);
                setCampaignId(campaignId);
                setCurrentPage(5);
            }
        }
        return "";
    };

    useEffect(() => {
        getCategories();
        getCountries();
        checkPaymentCallback();
        const paramCampaignId = props.match.params.campaignId;
        if (paramCampaignId) {
            if (paramCampaignId && campaignDetail === null) {
                CampaignsService.getCampaignDetail({ campaignId: paramCampaignId, acceptLanguage: localizationHelper.getLocale() })
                    .then((resp) => {
                        if (
                            resp.data.managementType === CampaignManagementType.SelfService &&
                            resp.data.typeHash === CampaignType.clicking
                        ) {
                            const campaignDetailData = resp.data;
                            setIsEditPage(true);
                            setCampaignDetail({
                                ...resp.data,
                                campaignType: campaignType,
                                brandName: currentUserId, // update value in backend
                                influencerBrandName: currentUserId, // update value in backend
                                campaignLink: campaignDetailData.link,
                                campaignExplanation: campaignDetailData.description,
                                campaignName: campaignDetailData.name,
                                country: campaignDetailData.countryId,
                                categories:
                                    campaignDetailData.categories && campaignDetailData.categories.length > 0
                                        ? (campaignDetailData.categories as any[]).map((c) => c.id)
                                        : [],
                                whitelistCategories:
                                    campaignDetailData.whitelistCategories && campaignDetailData.whitelistCategories.length > 0
                                        ? (campaignDetailData.whitelistCategories as any[]).map((c) => c.id)
                                        : [],
                                blacklistCategories:
                                    campaignDetailData.blacklistCategories && campaignDetailData.blacklistCategories.length > 0
                                        ? (campaignDetailData.blacklistCategories as any[]).map((c) => c.id)
                                        : [],
                                platforms:
                                    campaignDetailData.platforms && campaignDetailData.platforms.length > 0
                                        ? (campaignDetailData.platforms as any[]).map((p) => p)
                                        : [],
                                startDate: moment(campaignDetailData.unformattedStartDate),
                                endDate: moment(campaignDetailData.unformattedEndDate),
                                totalAmount: campaignDetailData.totalBudgetDecimal,
                                influencerTotalAmount: campaignDetailData.influencerTotalBudgetDecimal,
                                amountByClick: campaignDetailData.perClickActionInstallAmount,
                                minimumInfluencerCount: campaignDetailData.minimumInfluencerCount,
                                amountByInfluencer: campaignDetailData.perInfluencerBudget,
                                amountByClickInfluencerEarning: campaignDetailData.amountByClickInfluencerEarning,
                                trackingPlatform: campaignDetailData.trackingPlatform,
                            } as ICampaignData);
                            setCampaignType(resp.data.typeHash);
                            setCampaignId(paramCampaignId);
                        } else {
                            push("/");
                        }
                    })
                    .catch(() => {
                        push("/");
                    });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createCampaign = () => {
        setLoading(true);

        let requestBody = {
            // backendUpdate
            // brandId: campaignDetail.brandName,
            // influencerBrandId: campaignDetail.influencerBrandName,

            type: campaignDetail.campaignType,
            managementType: CampaignManagementType.SelfService,
            name: campaignDetail.campaignName,
            description: campaignDetail.campaignExplanation,
            link: campaignDetail.campaignLink,
            countryCode: campaignDetail.country,
            categoryIds: campaignDetail.categories,
            whiteListCategoryIds: campaignDetail.whitelistCategories,
            blackListCategoryIds: campaignDetail.blacklistCategories,
            platforms: campaignDetail.platforms,
            startDate: campaignDetail.startDate?.format("YYYY-MM-DD"),
            endDate: campaignDetail.endDate?.format("YYYY-MM-DD"),
            totalBudget: campaignDetail.totalAmount,
            influencerTotalBudget: campaignDetail.influencerTotalAmount,
            perClickActionInstallAmount: campaignDetail.amountByClick,
            perClickActionInstallInfluencerEarning: campaignDetail.amountByClickInfluencerEarning,
            perInfluencerBudget: campaignDetail.amountByInfluencer,
            minimumInfluencerCount: campaignDetail.minimumInfluencerCount,
        };

        if (isEditPage) {
            CampaignsService.updateCampaign({
                id: campaignId,
                body: {
                    description: requestBody.description,
                    categoryIds: requestBody.categoryIds,
                    whiteListCategoryIds: requestBody.whiteListCategoryIds,
                    blackListCategoryIds: requestBody.blackListCategoryIds,
                    platforms: requestBody.platforms,
                    endDate: requestBody.endDate,
                },
                acceptLanguage: localizationHelper.getLocale(),
            }).then((resp) => {
                setCampaignId(campaignId);
                setLoading(false);
                if (campaignDetail.campaignState !== CampaignStates.WaitingForPayment) {
                    setCurrentPage(5);
                    setPaymentFail(false);
                } else setCurrentPage(4);
            });
        } else {
            CampaignsService.createCampaign({
                body: requestBody,
                acceptLanguage: localizationHelper.getLocale(),
            }).then((resp) => {
                setCampaignId(resp.data.Id);
                setLoading(false);
                setCurrentPage(4);
            });
        }
    };

    useEffect(() => {
        if (isEditPage && campaignDetail !== null) {
            if (campaignDetail.campaignState !== CampaignStates.WaitingForPayment) {
                setPassPaymentPage(true);
            }
        }
    }, [isEditPage, campaignDetail]);

    const getCountries = () => {
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
        });
    };

    const getCategories = () => {
        CategoryService.getAllCategory({ acceptLanguage: localizationHelper.getLocale() })
            .then((res) => {
                setCategories(res.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const editPage = (id: number) => {
        setCurrentPage(id);
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <Row gutter={[16, 16]}>
                    <Col span={24} xs={24} lg={24} xl={24}>
                        <Card>
                            <Row gutter={[8, 8]} className="ant-card-header">
                                <Col span={23}>
                                    {isEditPage && campaignDetail !== null
                                        ? campaignDetail.campaignName + " " + t("campaign.create.updateTitle")
                                        : t("campaign.create.pageTitle")}
                                </Col>
                                <Col span={1}>
                                    <Popover placement="bottomRight" title={t("global.create-campaign")} trigger="hover">
                                        <InfoCircleOutlined />
                                    </Popover>
                                </Col>
                            </Row>

                            <Steps current={currentPage}>
                                <Step title={t("global.campaign")} />
                                <Step title={t("campaign.create.step2.stepTitle")} />
                                <Step title={t("campaign.create.step3.stepTitle")} />
                                <Step title={t("campaign.create.step4.stepTitle")} />
                                {passPaymentPage === false && <Step title={t("global.payment")} />}
                                <Step title={t("global.result")} />
                            </Steps>
                            <br />

                            {currentPage === 0 && (
                                <FirstPage
                                    isEditPage={isEditPage}
                                    onBack={() => null}
                                    campaignData={campaignDetail}
                                    onFinish={(data: ICampaignStep1DataSelfService) => {
                                        setCampaignDetail({ ...campaignDetail, ...data });
                                        setCurrentPage(currentPage + 1);
                                    }}
                                    isLoading={isLoading}
                                />
                            )}
                            {currentPage === 1 && (
                                <SecondPage
                                    onBack={(data: ICampaignStep2DataSelfService) => {
                                        setCampaignDetail({ ...campaignDetail, ...data });
                                        setCurrentPage(currentPage - 1);
                                    }}
                                    isEditPage={isEditPage}
                                    countries={countries}
                                    categories={categories}
                                    campaignData={campaignDetail}
                                    onFinish={(data: ICampaignStep2DataSelfService) => {
                                        setCampaignDetail({ ...campaignDetail, ...data });
                                        setCurrentPage(currentPage + 1);
                                    }}
                                    isLoading={isLoading}
                                />
                            )}
                            {currentPage === 2 && (
                                <ThirdPage
                                    onBack={(data: ICampaignStep3DataSelfService) => {
                                        setCampaignDetail({ ...campaignDetail, ...data });
                                        setCurrentPage(currentPage - 1);
                                    }}
                                    isEditPage={isEditPage}
                                    campaignData={campaignDetail}
                                    onFinish={(data: ICampaignStep3DataSelfService) => {
                                        setCampaignDetail({ ...campaignDetail, ...data });
                                        setCurrentPage(currentPage + 1);
                                    }}
                                    isLoading={isLoading}
                                />
                            )}
                            {currentPage === 3 && (
                                <FourthPage
                                    onBack={(data: ICampaignData) => {
                                        setCampaignDetail({ ...campaignDetail, ...data });
                                        setCurrentPage(currentPage - 1);
                                    }}
                                    isEditPage={isEditPage}
                                    countries={countries}
                                    categories={categories}
                                    campaignData={campaignDetail}
                                    onFinish={() => {
                                        createCampaign();
                                    }}
                                    isLoading={isLoading}
                                    editPage={editPage}
                                />
                            )}
                            {currentPage === 4 && passPaymentPage === false && (
                                <PaymentButton createdCampaignId={campaignId} createdCampaignType={campaignType} />
                            )}
                            {currentPage === 5 && (
                                <CampaignCreateResult
                                    isEditPage={isEditPage}
                                    paymentFail={paymentFail}
                                    createdCampaignId={campaignId}
                                    createdCampaignType={campaignType}
                                />
                            )}
                        </Card>
                    </Col>
                </Row>
            </LayoutWrapper>
        </Authenticated>
    );
};
