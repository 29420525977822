import { FC } from "react";
import { Modal, ModalProps, Row, Col, Skeleton } from "antd";
import { useTranslate } from "@pankod/refine";
import { CloseOutlined } from "@ant-design/icons";

import "./style.scss";
import { usePaymentAccountDetail } from "./usePaymentAccountDetail";
import { Money } from "services/models/money";

interface PaymentReportModalProps extends ModalProps {}

export const PaymentAccountDetailModal: FC<PaymentReportModalProps> = (modalProps) => {
    const [open, data, loading, handleClose] = usePaymentAccountDetail();
    const t = useTranslate();

    return (
        <Modal
            open={open}
            closeIcon={<CloseOutlined />}
            closable
            footer={null}
            centered
            maskClosable
            onCancel={handleClose}
            {...modalProps}
        >
            <div className="report-payment-account-detail">
                <h4 className="report-payment-account-detail-title">
                    {t("reports.payment.paymentAccountDetails.modal.title", "Ödenecek Net Tutar")}
                </h4>
                <Skeleton loading={loading} paragraph={{ rows: 4 }} />

                {data ? (
                    <>
                        <Row>
                            <Col className="report-payment-account-detail-field-label" xs={12}>
                                {t(`reports.payment.paymentAccountDetails.modal.field.accruedPayment`, "Hak Ediş")}
                            </Col>
                            <Col className="report-payment-account-detail-field-value" xs={12}>
                                {new Money(data?.progressPaymentLastMonth).toStringWithCurrency(data?.currency)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="report-payment-account-detail-field-label" xs={12}>
                                {t(`reports.payment.paymentAccountDetails.modal.field.withholdingAmount`)}
                            </Col>
                            <Col className="report-payment-account-detail-field-value" xs={12}>
                                {new Money(data?.withholdingAmount).toStringWithCurrency(data?.currency)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="report-payment-account-detail-field-label" xs={12}>
                                {t(`reports.payment.paymentAccountDetails.modal.field.netAmount`)}
                            </Col>
                            <Col className="report-payment-account-detail-field-value" xs={12}>
                                {new Money(data?.netAmount).toStringWithCurrency(data?.currency)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="report-payment-account-detail-field-label" xs={12}>
                                {t(`reports.payment.paymentAccountDetails.modal.field.vat`)}
                            </Col>
                            <Col className="report-payment-account-detail-field-value" xs={12}>
                                {new Money(data?.vat).toStringWithCurrency(data?.currency)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="report-payment-account-detail-field-label" xs={12}>
                                {t(`reports.payment.paymentAccountDetails.modal.field.vatWithholding`)}
                            </Col>
                            <Col className="report-payment-account-detail-field-value" xs={12}>
                                {new Money(data?.vatWithholding).toStringWithCurrency(data?.currency)}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="report-payment-account-detail-field-label" xs={12}>
                                {t(`reports.payment.paymentAccountDetails.modal.field.payableNet`)}
                            </Col>
                            <Col className="report-payment-account-detail-field-value" xs={12}>
                                {new Money(data?.netPayable).toStringWithCurrency(data?.currency)}
                            </Col>
                        </Row>
                    </>
                ) : null}
            </div>
        </Modal>
    );
};
