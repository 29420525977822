import { RouteProps } from "react-router-dom";
import { ActivationPage } from "./activation";

export const activationRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: ActivationPage,
            path: "/activation/:id"
        },
    ];
};
