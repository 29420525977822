import { RightOutlined } from "@ant-design/icons";
import { Breadcrumb } from "@pankod/refine";
import { Link } from "react-router-dom";

export type breadcrumbItem = {
    name: string;
    url: string;
    attachData?: any;
};

export interface IFenBreadCrumbProp {
    items: breadcrumbItem[];
}

export const FenBreadCrumb = (props: IFenBreadCrumbProp) => {
    return (
        <Breadcrumb separator={<RightOutlined />}>
            {props.items.map((item) =>
                item.url !== "" ? (
                    <Breadcrumb.Item>
                        <Link
                            to={{
                                pathname:item.url,
                                state:item.attachData
                            }}
                            className="clickable-span"
                        >
                            {item.name}
                        </Link>
                    </Breadcrumb.Item>
                ) : (
                    <Breadcrumb.Item>{item.name}</Breadcrumb.Item>
                )
            )}
        </Breadcrumb>
    );
};
