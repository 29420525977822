import { useLogout } from "@pankod/refine";
import { RoleHelper, Roles } from "./roleHelper";

export declare type AllowedRolesProps = {
    children: JSX.Element[] | JSX.Element;
    roles: Roles[];
    allowRedirect?: boolean;
};

export const AllowedRoles: React.FC<AllowedRolesProps> = (props: AllowedRolesProps) => {
    const { mutate: logout } = useLogout();

    const checkIsEligable = () => {
        let isEliable = false;
        props.roles.forEach((role) => {
            if (RoleHelper.isInRole(role)) {
                isEliable = true;
            }
        });
        return isEliable;
    };
    return (
        <>
            {checkIsEligable() && props.children}
            {!checkIsEligable() && props.allowRedirect === false && ""}
            {!checkIsEligable() && (props.allowRedirect === true || props.allowRedirect === null) && logout()}
        </>
    );
};
