import { Authenticated, Button, Col, LayoutWrapper, Row, Select, useTranslate } from "@pankod/refine";
import { Form } from "antd";
import { ShowCampaignExplanation } from "components/atomics/show-items";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { RefundAndBonusTable } from "components/tables/refund-and-bonus-table";
import { localizationHelper } from "helpers/globalizationHelper";
import { useMemo, useState } from "react";
import { CampaignInfluencerStates, CampaignStates, CampaignType, SocialMedias } from "../../../Constanst";
import { CampaignsService } from "../../../services";
import { EarningChart } from "./components/earningChart";
import { PerformanceChart } from "./components/performanceChart";
import { SocialInfiniteList } from "./components/socialScrollingList";
import { MyCampaignDetailProps } from "./wrapper";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { Money } from "services/models/money";

export const ActionCampaign = (props: MyCampaignDetailProps) => {
    const [linkPlatform, setLinkPlatform] = useState(null);
    const t = useTranslate();

    const createLinkPlatformSelectionSubmit = (values: any) => {
        if (values.platform) {
            CampaignsService.createCampaignLink({
                body: {
                    campaignInfluencerId: props.campaignInfluencer.id,
                    platform: values.platform,
                },
                acceptLanguage: localizationHelper.getLocale(),
            }).then((resp) => {
                props.refresh();
            });
        }
    };

    const valuesTotal = useMemo(() => {
        let bonuses = 0;
        let refunds = 0;
        props.performance?.bonusesAndRefunds?.forEach((item: any) => {
            if (item.transactionType === 4) refunds += item.amount;
            else if (item.transactionType === 3) bonuses += item.amount;
        });
        return { bonuses, refunds };
    }, [props.performance?.bonusesAndRefunds]);

    return (
        <Authenticated>
            <style>
                {`
                    .head{
                        font-weight: bold;
                    }

                    .button{
                        background-color: #952AE7;
                        color: white;
                    }
                    .link-row{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        padding: 10px 0px;
                    }
                    .link-input{
                        color: #C2C9D1;
                        width: 15vw;
                    }
                `}
            </style>
            <LayoutWrapper>
                <FenBreadCrumb
                    items={[
                        {
                            name: t("global.myCampaign"),
                            url: "/campaign/list",
                        },
                        {
                            name: props.campaignDetail?.name,
                            url: "",
                        },
                    ]}
                />
                <Row gutter={[16, 16]}>
                    {!RoleHelper.isInRole(Roles.Admin) && !RoleHelper.isInRole(Roles.Root) && (
                        <Col xs={24} sm={24} md={12} span={12}>
                            <PageContainer
                                pageTitle={props.campaignDetail?.name}
                                helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                            >
                                <Row gutter={[20, 0]}>
                                    <Col xs={24} sm={24} md={12} span={12}>
                                        <Row>
                                            <h3 className="head">{t("global.campaignState")}</h3>
                                        </Row>
                                        <Row>
                                            <span className="clickable-span">{props.showCampaignState}</span>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.campaign-name")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{props.campaignDetail?.name}</p>
                                        </Row>
                                        <ShowCampaignExplanation description={props.campaignDetail?.description} />
                                        <Row>
                                            <h3 className="head">{t("global.platforms", "Platformlar")}</h3>
                                        </Row>
                                        <Row>
                                            <p>
                                                {props.campaignDetail?.strPlatforms && props.campaignDetail?.strPlatforms?.length > 0 ? (
                                                    props.campaignDetail?.strPlatforms?.join("-")
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.location")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{props.campaignDetail?.country}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("campaign.create.step2.categoriesLabel")}</h3>
                                        </Row>
                                        <Row>
                                            <p>
                                                {props.campaignDetail?.categories && props.campaignDetail?.categories.length > 0 ? (
                                                    (props.campaignDetail?.categories as [])
                                                        ?.map((c) => (c as any).name)
                                                        .map((c) => "#" + c)
                                                ) : (
                                                    <span>-</span>
                                                )}
                                            </p>
                                        </Row>
                                    </Col>
                                    <Col xs={24} sm={24} md={12} span={12}>
                                        <Row>
                                            <h3 className="head">{t("campaign.create.step3.startDateLabel")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{props.campaignDetail?.startDate}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.end-date")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{props.campaignDetail?.endDate ? props.campaignDetail?.endDate : "-"}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head"> {t('global.unit-price')} </h3>
                                        </Row>
                                        <Row>
                                            <p>{new Money(props.campaignDetail?.unitPrice).toStringWithCurrency(props.campaignDetail?.currency)}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("campaign.create.step3.influencerTotalAmountLabel")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{new Money(props.campaignDetail?.perInfluencerBudget).toStringWithCurrency(props.campaignDetail?.currency)}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.conversionEarnings")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{new Money(props.performance?.totalInfluencerEarning).toStringWithCurrency(props.campaignDetail?.currency)}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.bonuses")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{new Money(valuesTotal.bonuses).toStringWithCurrency(props.campaignDetail?.currency)}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.refunds")}</h3>
                                        </Row>
                                        <Row>
                                            <p>{new Money(valuesTotal.refunds).toStringWithCurrency(props.campaignDetail?.currency)}</p>
                                        </Row>
                                        <Row>
                                            <h3 className="head">{t("global.netEarnings")}</h3>
                                        </Row>
                                        <Row>
                                            <p>
                                                {new Money(
                                                    props.performance?.totalInfluencerEarning + valuesTotal.bonuses - valuesTotal.refunds
                                                ).toStringWithCurrency(props.campaignDetail?.currency)}
                                            </p>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row
                                    style={{
                                        display: "flex",
                                        justifyContent: "flex-end",
                                        alignItems: "center",
                                        width: "100%",
                                        padding: "20px 0px",
                                    }}
                                ></Row>
                            </PageContainer>
                        </Col>
                    )}
                    {!RoleHelper.isInRole(Roles.Admin) && !RoleHelper.isInRole(Roles.Root) && (
                        <Col xs={24} sm={24} md={12} span={12}>
                            <PageContainer
                                pageTitle={t("global.mylinks")}
                                helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                            >
                                <Form onFinish={createLinkPlatformSelectionSubmit}>
                                    <Row style={{ padding: "20px 0px" }}>
                                        <Col xs={24} sm={24} md={12} span={12}>
                                            <Row>
                                                <h3>Platform</h3>
                                            </Row>
                                            <Row>
                                                <Form.Item name={"platform"}>
                                                    <Select
                                                        onChange={(v) => setLinkPlatform(v)}
                                                        placeholder={t("campaign.create.step1.chooseplatform")}
                                                        options={Object.values(SocialMedias)
                                                            .filter((v) => {
                                                                if (
                                                                    props.campaignDetail?.platforms &&
                                                                    props.campaignDetail?.platforms?.includes(+v.code)
                                                                ) {
                                                                    if (
                                                                        props.campaignInfluencer &&
                                                                        props.campaignInfluencer.campaignInfluencerSocialMedia &&
                                                                        props.campaignInfluencer.campaignInfluencerSocialMedia.length > 0 &&
                                                                        (props.campaignInfluencer.campaignInfluencerSocialMedia as any[])
                                                                            .map((cism) => cism.name)
                                                                            .includes(+v.code)
                                                                    ) {
                                                                        return false;
                                                                    }

                                                                    return (props?.campaignInfluencer?.socialMediasDto as any[])
                                                                        .map((v) => v.name)
                                                                        .includes(v.name);
                                                                }

                                                                return false;
                                                            })
                                                            .map((value) => {
                                                                return { label: value.name, value: value.code };
                                                            })}
                                                        className="link-input"
                                                    />
                                                </Form.Item>
                                            </Row>
                                        </Col>
                                        <Row
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-end",
                                                alignItems: "center",
                                                width: "100%",
                                                padding: "20px 0px",
                                            }}
                                        >
                                            <Button
                                                disabled={
                                                    (linkPlatform !== null && linkPlatform !== undefined && !linkPlatform) ||
                                                    props?.campaignDetail?.campaignState !== CampaignStates.Accepted ||
                                                    props?.campaignDetail?.campaignInfluencerState === null ||
                                                    props?.campaignDetail?.campaignInfluencerState !== CampaignInfluencerStates.Accepted
                                                }
                                                htmlType={"submit"}
                                                type="default"
                                            >
                                                {t("buttons.create")}
                                            </Button>
                                        </Row>
                                    </Row>
                                    <hr style={{ borderTop: "1px solid #952AE7" }} />
                                    {/* Show this section if a fenomio link has been created */}
                                    <Row style={{ width: "100%" }}>
                                        <SocialInfiniteList
                                            disabled={
                                                (linkPlatform !== null && linkPlatform !== undefined && !linkPlatform) ||
                                                props?.campaignDetail?.campaignState !== CampaignStates.Accepted ||
                                                props?.campaignDetail?.campaignInfluencerState === null ||
                                                props?.campaignDetail?.campaignInfluencerState !== CampaignInfluencerStates.Accepted
                                            }
                                            links={props?.campaignInfluencer?.campaignInfluencerSocialMedia}
                                        />
                                    </Row>
                                </Form>
                            </PageContainer>
                        </Col>
                    )}
                    <Col span={24}>
                        <PageContainer
                            pageTitle={t("global.conversions")}
                            helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                        >
                            <PerformanceChart
                                campaignType={CampaignType.action}
                                changeDate={props.changeDate}
                                changePlatform={props.changePlatform}
                                performance={props.performance}
                            />
                        </PageContainer>
                    </Col>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <PageContainer
                            pageTitle={t("global.performancee")}
                            helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                        >
                            <EarningChart campaignType={CampaignType.action} performance={props.performance} />
                        </PageContainer>
                    </Col>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <RefundAndBonusTable
                            containerTitle={t("global.refundsAndBonuses")}
                            helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                            bonusesAndRefunds={props.performance?.bonusesAndRefunds}
                        />
                    </Col>
                </Row>
            </LayoutWrapper>
        </Authenticated>
    );
};
