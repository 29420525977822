import { Button, Form, Select, Space, useTranslate } from "@pankod/refine";
import { Col, Row } from "antd";
import { GetPlatforms } from "helpers/StateHelpers";
import { ICampaignData } from "pages/campaign/Model/CampaignList";
import { SocialPlatform } from "pages/profile/models";
import { FunctionComponent, useEffect, useState } from "react";

export interface IClickCampaignStep2Data {
    platforms: SocialPlatform[] | null;
    category: string | null;
    whitelistCategories: string[] | null;
    blacklistCategories: string[] | null;
    country: string | null;
}

interface ClickCampaignStep2Props {
    categories: any[];
    countries: any[];
    campaignData: ICampaignData;
    formFinished: (campaignData: IClickCampaignStep2Data) => void;
    back: any;
    isEditPage: boolean;
}

export const ClickCampaignStep2: FunctionComponent<ClickCampaignStep2Props> = (props: ClickCampaignStep2Props) => {
    const t = useTranslate();
    const [form] = Form.useForm<any>();
    const [categoryOptions, setCategoryOptions] = useState<any[]>([]);
    const [whiteListCategoryOptions, setWhiteListCategoryOptions] = useState<any[]>([]);
    const [blackListCategoryOptions, setBlackListCategoryOptions] = useState<any[]>([]);
    const colStyle = {
        marginLeft: "10px",
        marginRight: "10px",
    };

    useEffect(() => {
        prepareCategories();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (props.campaignData) {
            form.resetFields();
            prepareCategories();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.campaignData]);

    const getCategoryOptions = () => {
        let categories: any[] = [];

        if (props.categories && props.categories.length > 0) {
            const categoryFieldValue = form.getFieldValue("categories");
            const whiteListCategoryFieldValue = form.getFieldValue("whitelistCategories");
            const blackListCategoryFieldValue = form.getFieldValue("blacklistCategories");

            categories = props.categories.filter((c) => {
                if (categoryFieldValue && categoryFieldValue.length > 0 && categoryFieldValue.includes(c.id)) {
                    return false;
                }
                if (whiteListCategoryFieldValue && whiteListCategoryFieldValue.length > 0 && whiteListCategoryFieldValue.includes(c.id)) {
                    return false;
                }
                if (blackListCategoryFieldValue && blackListCategoryFieldValue.length > 0 && blackListCategoryFieldValue.includes(c.id)) {
                    return false;
                }

                return true;
            });

            let normalCategories: any[] = JSON.parse(JSON.stringify(categories));
            let whiteListCategories: any[] = JSON.parse(JSON.stringify(categories));
            let blackListCategories: any[] = JSON.parse(JSON.stringify(categories));

            if (categoryFieldValue && categoryFieldValue.length > 0) {
                const existingCategories = props.categories.filter((c) => categoryFieldValue.includes(c.id));
                existingCategories.forEach((c) => normalCategories.push(c));
                existingCategories.forEach((c) => whiteListCategories.unshift(c));
            }
            if (whiteListCategoryFieldValue && whiteListCategoryFieldValue.length > 0) {
                const existingCategories = props.categories.filter((c) => whiteListCategoryFieldValue.includes(c.id));
                existingCategories.forEach((c) => whiteListCategories.push(c));
                existingCategories.forEach((c) => normalCategories.unshift(c));
            }
            if (blackListCategoryFieldValue && blackListCategoryFieldValue.length > 0) {
                const existingCategories = props.categories.filter((c) => blackListCategoryFieldValue.includes(c.id));
                existingCategories.forEach((c) => blackListCategories.push(c));
            }
            normalCategories = [...new Set(normalCategories)];
            whiteListCategories = [...new Set(whiteListCategories)];
            blackListCategories = [...new Set(blackListCategories)];

            const normalOptions = normalCategories.map((c) => {
                return {
                    label: c.name,
                    value: c.id,
                };
            });
            setCategoryOptions(normalOptions);

            const whiteListOptions = whiteListCategories.map((c) => {
                return {
                    label: c.name,
                    value: c.id,
                };
            });
            setWhiteListCategoryOptions(whiteListOptions);

            const blacklistOptions = blackListCategories.map((c) => {
                return {
                    label: c.name,
                    value: c.id,
                };
            });
            setBlackListCategoryOptions(blacklistOptions);
        }
    };

    const prepareCategories = () => {
        getCategoryOptions();
    };

    return (
        <Form
            form={form}
            layout="vertical"
            labelAlign={"right"}
            onFinish={(values) => {
                props.formFinished(values);
            }}
        >
            <Row>
                <Col style={colStyle} xs={24} sm={24} md={7} span={7}>
                    <Form.Item
                        label={t("global.location")}
                        name="country"
                        rules={[
                            {
                                required: true,
                                message: t("global.please-select-location"),
                            },
                        ]}
                        initialValue={props.campaignData.country}
                    >
                        <Select
                            disabled={props.isEditPage}
                            placeholder={t("pages.phenomenon.countrySelectt")}
                            options={props.countries.map((country) => {
                                return {
                                    label: country.text,
                                    value: country.id,
                                };
                            })}
                        />
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: t("campaign.create.step2.categoriesRule"),
                            },
                        ]}
                        label={t("global.categories")}
                        name="categories"
                        initialValue={props.campaignData.categories}
                    >
                        <Select
                            allowClear={!props.isEditPage}
                            mode={"multiple"}
                            onChange={() => prepareCategories()}
                            options={categoryOptions}
                            filterOption={(input, option) =>
                                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />
                    </Form.Item>
                </Col>
                <Col style={colStyle} xs={24} sm={24} md={7} span={7}>
                    <Form.Item
                        label={t("campaign.create.step2.whitelistCategoriesLabel")}
                        name="whitelistCategories"
                        initialValue={props.campaignData.whitelistCategories}
                    >
                        <Select
                            allowClear={!props.isEditPage}
                            mode={"multiple"}
                            onChange={() => prepareCategories()}
                            options={whiteListCategoryOptions}
                            filterOption={(input, option) =>
                                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("campaign.create.step2.blacklistCategoriesLabel")}
                        name="blacklistCategories"
                        initialValue={props.campaignData.blacklistCategories}
                    >
                        <Select
                            allowClear={!props.isEditPage}
                            mode={"multiple"}
                            options={blackListCategoryOptions}
                            onChange={() => prepareCategories()}
                            filterOption={(input, option) =>
                                option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                option.props.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                        />
                    </Form.Item>
                </Col>
                <Col style={colStyle} xs={24} sm={24} md={7} span={7}>
                    <Form.Item
                        label={t("global.platforms", "Platformlar")}
                        name="platforms"
                        rules={[
                            {
                                required: true,
                                message: t("campaign.create.minimum-platform"),
                            },
                        ]}
                        initialValue={props.campaignData.platforms}
                    >
                        <Select mode="multiple" allowClear placeholder={t("global.platform-select")} options={GetPlatforms()} />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item>
                <Space direction="horizontal" style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button
                        onClick={() => props.back()}
                        style={{ marginLeft: "auto"}}
                        type="default"
                    >
                        {t("buttons.back")}
                    </Button>
                    <Button style={{ marginLeft: "auto" }} type="primary" htmlType="submit">
                        {t("global.continue")}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
