import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigation, useTranslate } from "@pankod/refine";
import { EVENTS } from "Constanst";
import { Button, Row, Table } from "antd";
import { PaymentAccountDetailModal } from "components";
import { FC } from "react";
import { ReportsService } from "services/services/ReportsService";
import { ReportTab } from "./ReportTab";
import { DateInput, ReportFilter } from "./filter";
import { useStandardReport } from "./hooks";
import { PaymentListData } from "services/models/reports";
import { Money } from "services/models/money";

export const PaymentReport: FC = () => {
    const [data, onFilter, filter, loading] = useStandardReport<PaymentListData[]>({
        fetchData: async (data) =>
            ReportsService.getCancelBonusReport({
                ...data,
                onlyPayments: true,
            }).then((response) => {
                return { data: response.data.filter((item) => item.process === "Payment") };
            }),
    });

    const { push } = useNavigation();

    const t = useTranslate();

    const handleDetail = (id: string) => {
        window.dispatchEvent(
            new CustomEvent(EVENTS.MODAL_IS_OPENED, {
                detail: id,
            })
        );
    };

    const columns = [
        {
            title: <span className="table-font">{t("global.date")}</span>,
            dataIndex: "date",
            key: "date",
        },
        {
            title: <span className="table-font">{t("campaign.create.step2.fenomenid")}</span>,
            dataIndex: "influencerId",
            key: "influencerId",
            render: (text: any) => (
                <span style={{ cursor: "pointer" }} className="table-font" onClick={() => push("/influencers/detail/" + text)}>
                    #{text}
                </span>
            ),
        },
        {
            title: <span className="table-font">{t("campaign.create.step2.adminid")}</span>,
            dataIndex: "adminId",
            key: "adminId",
            render: (text: any) => <span>#{text}</span>,
        },
        {
            title: <span className="table-font">{t("campaign.create.step3.lastamount")}</span>,
            dataIndex: "previousBalance",
            key: "previousBalance",
            render: (val:Number, row:PaymentListData) => <span style={{whiteSpace:"nowrap"}}>{new Money(val).toStringWithCurrencyEnum(row.currency)}</span>
        },
        {
            title: <span className="table-font">{t("campaign.create.step3.nextamount")}</span>,
            dataIndex: "nextBalance",
            key: "nextBalance",
            render: (val:Number, row:PaymentListData) => <span style={{whiteSpace:"nowrap"}}>{new Money(val).toStringWithCurrencyEnum(row.currency)}</span>
        },
        {
            title: <span className="table-font">{t("campaign.create.step1.referenceid")}</span>,
            dataIndex: "campaignId",
            key: "campaignId",
            render: (text: any) => <span>#{text}</span>,
        },
        {
            title: (
                <span className="table-font">
                    {t("reports.payment.table.title.paymentAccountDetails", "Ödeme Yapılan Hesap Bilgileri")}
                </span>
            ),
            dataIndex: "amount",
            key: "amount",
            render: (text: number, row: PaymentListData) => {
                return (
                    <>
                        <span style={{whiteSpace:"nowrap"}}>{new Money(text).toStringWithCurrencyEnum(row.currency)}</span>
                        <Button type="link" onClick={() => handleDetail(row.balanceCalculationId)}>
                            <InfoCircleOutlined />
                        </Button>
                    </>
                );
            },
        },
    ];

    return (
        <ReportTab
            downloadAPIService={async (filter) => ReportsService.getCancelBonusReportExport({ ...filter, onlyPayments: true })}
            filter={filter}
            fileName="payments.xlsx"
        >
            <Row>
                <ReportFilter onFilter={onFilter}>
                    <DateInput />
                </ReportFilter>
            </Row>
            <hr />
            <Table loading={loading} columns={columns} dataSource={data} pagination={false} />
            <PaymentAccountDetailModal />
        </ReportTab>
    );
};
