import { Funnel } from "@ant-design/plots";
import { Col, Form, LayoutWrapper, Row, Space, Table, useTranslate } from "@pankod/refine";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { RefundAndBonusTable } from "components/tables/refund-and-bonus-table";
import dayjs from "dayjs";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { ICampaignData } from "pages/campaign/Model/CampaignList";
import { RangeValue } from "rc-picker/lib/interface";
import { FunctionComponent, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BaseIdNameResponse } from "services/models/BaseIdNameResponse";
import { BonusesAndRefundsResponse } from "services/models/BonusesAndRefundsResponse";
import { DailyCampaignPerformanceSummaryResponse } from "services/models/DailyCampaignPerformanceSummaryResponse";
import { TransactionsSummaryOfCampaignResponse } from "services/models/TransactionsSummaryOfCampaignResponse";
import { TransactionsSummaryOfCampaignResponseItem } from "services/models/TransactionsSummaryOfCampaignResponseItem";
import { Filter } from "../Filter";
import { AdminChart } from "./AdminChart";
import { BrandChart } from "./BrandChart";

interface ClickCampaignPerformanceProps {
    data: ICampaignData;
    dailyPerformanceSummary: DailyCampaignPerformanceSummaryResponse;
    changePlatform: any;
    changeDate: any;
    changeName: (value: string) => void;

    bonusesAndRefunds?: BonusesAndRefundsResponse;
    bonusPaginationChange?: (pageNumber: number) => void;
    transactionsSummary: TransactionsSummaryOfCampaignResponse;
    transactionsSummaryPaginationChange: (pageNumber: number) => void;
}

export const SellingCampaignPerformance: FunctionComponent<ClickCampaignPerformanceProps> = (props: ClickCampaignPerformanceProps) => {
    const [funnel, setFunnel] = useState<any>();

    const data = props.data;
    const t = useTranslate();
    const currency = data.currency || "₺";

    useEffect(() => {
        if (props.dailyPerformanceSummary) {
            let totalClicks = props.dailyPerformanceSummary?.totalClicks;
            let totalActions = props.dailyPerformanceSummary?.totalConversions;

            const funnelData = [
                {
                    stage: t("campaign.create.step3.totalclick"),
                    number: totalClicks,
                },
                {
                    stage: t("campaign.create.step3.totalsell"),
                    number: totalActions,
                },
            ];

            const conf = {
                data: funnelData,
                xField: "stage",
                yField: "number",
                minSize: 0.4,
            };
            setFunnel(<Funnel color={["#E4CAF9", "#9254de"]} {...conf} />);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dailyPerformanceSummary]);

    return (
        <LayoutWrapper>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
                <FenBreadCrumb
                    items={[
                        { name: t("global.campaigns"), url: "/campaign/list" },
                        { name: data.name ?? "", url: "/campaign/detail/" + data.id, attachData: data },
                        { name: t("global.performancee"), url: "" },
                    ]}
                />
                <PerformanceChart
                    currency={currency}
                    changeDate={props.changeDate}
                    changePlatform={props.changePlatform}
                    changeName={props.changeName}
                    dailyPerformanceSummary={props.dailyPerformanceSummary}
                    bonusesAndRefunds={props.bonusesAndRefunds}
                    bonusPaginationChange={props.bonusPaginationChange}
                    transactionsSummary={props.transactionsSummary}
                    transactionsSummaryPaginationChange={props.transactionsSummaryPaginationChange}
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <PageContainer pageTitle={t("campaign.create.step3.totalearnings")} helperContent={"Buraya helper içeriği gelecek"}>
                            <Space direction="vertical" size="small" style={{ width: "100%" }}>
                                {funnel}
                            </Space>
                        </PageContainer>
                    </Col>
                </Row>
            </Space>
        </LayoutWrapper>
    );
};

interface PerformanceChartProps {
    dailyPerformanceSummary: DailyCampaignPerformanceSummaryResponse;
    changePlatform: any;
    changeDate: any;
    changeName: (value: string) => void;

    currency: any;
    bonusesAndRefunds?: BonusesAndRefundsResponse;
    bonusPaginationChange?: (pageNumber: number) => void;
    transactionsSummary: TransactionsSummaryOfCampaignResponse;
    transactionsSummaryPaginationChange: (pageNumber: number) => void;
}

const PerformanceChart: FunctionComponent<PerformanceChartProps> = (props: PerformanceChartProps) => {
    const [selectedDates, setSelectedDates] = useState<RangeValue<dayjs.Dayjs>>();

    const t = useTranslate();

    const changeDate = (e: any) => {
        props.changeDate(e);
        var endDate = e !== null ? e[1] : dayjs();
        var startDate = e !== null ? e[0] : dayjs().add(-7, "day");
        setSelectedDates([startDate, endDate]);
    };

    const isBrand = RoleHelper.isInRole(Roles.Brand);

    useEffect(() => {
        const startDate = dayjs().add(-7, "day");
        const endDate = dayjs();
        setSelectedDates([startDate, endDate]);
        //eslint-disabled-next-line
    }, []);

    return (
        <PageContainer pageTitle={t("global.performancee")} helperContent={"Buraya helper içeriği gelecek"}>
            <Filter
                changeDate={changeDate}
                changeName={props.changeName}
                changePlatform={props.changePlatform}
                selectedDates={selectedDates}
            />
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {isBrand ? (
                        <BrandChart currency={props.currency} items={props.dailyPerformanceSummary.items} />
                    ) : (
                        <AdminChart currency={props.currency} items={props.dailyPerformanceSummary.items} />
                    )}
                </Col>
            </Row>
            <RefundAndBonusTable
                bonusesAndRefunds={props.bonusesAndRefunds}
                pageChange={props.bonusPaginationChange}
                containerTitle={t("global.refundsAndBonuses")}
                helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                isAllInfluencers={true}
            />
            <InluencerPerformances
                transactionsSummary={props.transactionsSummary}
                transactionsSummaryPaginationChange={props.transactionsSummaryPaginationChange}
            />
        </PageContainer>
    );
};

interface InluencerPerformancesProps {
    transactionsSummary: TransactionsSummaryOfCampaignResponse;
    transactionsSummaryPaginationChange: (pageNumber: number) => void;
}

export const InluencerPerformances: FunctionComponent<InluencerPerformancesProps> = (props: InluencerPerformancesProps) => {
    const t = useTranslate();

    const columns = [
        {
            title: <>{t("global.phenomenon", "Fenomen")}</>,
            dataIndex: "influencer",
            key: "influencer",
            render: (influencer: BaseIdNameResponse, row: TransactionsSummaryOfCampaignResponseItem, index: number) => (
                <>
                    <Space direction="vertical" size="small">
                        {
                            <Link
                                className="clickable-span"
                                style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold" }}
                                to={`/influencers/detail/${influencer.id}`}
                            >
                                {" "}
                                {influencer.name}
                            </Link>
                        }
                        {row.influencerSocialMediaNames?.map((social: any) => (
                            <a
                                href={social.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{ padding: 0, fontSize: "small", color: "#9254de" }}
                            >
                                <img
                                    width={16}
                                    height={16}
                                    alt={"social"}
                                    src={`/images/social-media-icon/${social.socialMedia.toUpperCase()}36Px.svg`}
                                />{" "}
                                {"@" + social.username}{" "}
                            </a>
                        ))}
                    </Space>
                </>
            ),
        },
        {
            title: <>{t("global.clickCount")}</>,
            dataIndex: "totalClicks",
            key: "totalClicks",
        },
        {
            title: <>{t("global.transformationCount")}</>,
            dataIndex: "totalSales",
            key: "totalSales",
        },
        {
            title: <>{t("global.fenomioComission")}</>,
            dataIndex: "totalPrice",
            key: "totalPrice",
            render: (text: string, row: any) => <>{row.totalPayout - row.totalPrice}</>,
        },
        {
            title: <>{t("global.influencerComission")}</>,
            dataIndex: "totalPrice",
            key: "totalPrice",
        },
        {
            title: <>{t("global.totalComission")}</>,
            dataIndex: "totalPayout",
            key: "totalPayout",
        },
    ];

    const influencerSellingDetails = (details: any) => {
        const columns = [
            { title: t("global.productName"), dataIndex: "productName", key: "productName" },
            { title: t("global.transformationCount"), dataIndex: "count", key: "count" },
            {
                title: t("global.fenomioComission"),
                dataIndex: "price",
                key: "price",
                render: (text: string, row: any) => <>{row.payout - row.price}</>,
            },
            { title: t("global.influencerComission"), dataIndex: "price", key: "price" },
            { title: t("global.totalComission"), dataIndex: "payout", key: "payout" },
        ];

        return <Table columns={columns} dataSource={details.sales} pagination={false} />;
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form name="basic" layout="inline" autoComplete="off">
                        <Space direction="vertical" style={{ width: "100%", marginTop: 20, borderRadius: 15 }}>
                            <Table
                                rowKey={(record) => record.campaignInfluencerId}
                                pagination={{
                                    current: props.transactionsSummary?.pageInfo?.pageNumber || 1,
                                    pageSize: props.transactionsSummary?.pageInfo?.pageSize || 1,
                                    total: props.transactionsSummary?.pageInfo?.totalItemCount,
                                }}
                                onChange={(pagination) => props.transactionsSummaryPaginationChange(pagination.current as number)}
                                columns={columns}
                                expandable={{
                                    expandedRowRender: (record) => influencerSellingDetails(record),
                                    defaultExpandAllRows: false,
                                }}
                                dataSource={props.transactionsSummary?.data}
                                scroll={{ x: "100%" }}
                            />
                        </Space>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
