import { Button, Col, Modal, Row, Typography } from "antd";
import { FC } from "react";
import { useMultiplePaymentApprovement } from "./useMultiplePaymentApprovement";
import { useTranslate } from "@pankod/refine";
import { Money } from "services/models/money";

const { Paragraph } = Typography;

export interface MultiplePaymentApprovementDetail {
    influencerList: string[];
    selectedPaymentAmount: number;
}
export const MultiplePaymentApprovement: FC = () => {
    const [open, detail, handleClose, handleOk, loading] = useMultiplePaymentApprovement<MultiplePaymentApprovementDetail>();

    const t = useTranslate();
    return (
        <Modal
            open={open}
            okText={t("global.accept")}
            okType={"default"}
            cancelText={t("global.giveup")}
            cancelButtonProps={{ type: "primary" }}
            onCancel={handleClose}
            onOk={() => handleOk()}
            confirmLoading={loading}
            closable={false}
        >
            <Row justify="space-between">
                <Col>
                    <h1 style={{ textAlign: "center", fontSize: "34px", color: "#952AE7" }}>
                        <b>{t("global.payment-confirmation")}</b>
                    </h1>
                </Col>
                <Col>
                    <Button type="link" onClick={handleClose}>
                        <img src="/images/close.svg" alt="" />
                    </Button>
                </Col>
            </Row>
            <Typography>
                <Paragraph style={{ fontSize: 16, fontWeight: 600 }}>
                    {detail?.influencerList?.length} kişi için {new Money(detail?.selectedPaymentAmount).toStringWithCurrency()} tutarındaki
                    ödemeyi onaylıyor musunuz?
                </Paragraph>
            </Typography>
        </Modal>
    );
};
