import { Button, Col, Row, Table, useNavigation, useTranslate } from "@pankod/refine";
import ProfileImg from "components/microparts/profile-img";
import { PageContainer } from "components/pageContainer/pageContainer";
import { Roles } from "helpers/roleHelper";
import { GetCampaignTypeTextWithManagementType } from "helpers/StateHelpers";
import { useEffect, useState } from "react";
import { PaginationConstant } from "../../Constanst";
import { localizationHelper } from "../../helpers/globalizationHelper";
import UserHelper from "../../helpers/userHelper";
import { CampaignsService } from "../../services";
import { Pagination } from "../../services/core/Pagination";
import { ProfileModel } from "../profile/models";
import { Link } from "react-router-dom";
import { Money } from "services/models/money";

const BrandDashboard = () => {
    const { push } = useNavigation();
    const t = useTranslate();

    const [profileData, setProfileData] = useState<ProfileModel>();
    const [campaigns, setCampaigns] = useState<any>();
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        getProfileInfo();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (search) getCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const getProfileInfo = () => {
        UserHelper.getProfile().then((resp) => {
            setProfileData(resp as ProfileModel);
        });
    };
    const prepareFilterRequest = () => {
        const campaignSearch: any = {};

        campaignSearch.campaignType = 0;
        campaignSearch.campaignName = null;
        campaignSearch.startDate = null;
        campaignSearch.endDate = null;
        campaignSearch.campaignStates = [];
        campaignSearch.campaignInfluencerStates = [];
        campaignSearch.categoryIds = [];
        campaignSearch.countryIds = [];
        campaignSearch.pageNumber = pagination.current;
        campaignSearch.pageSize = pagination.pageSize;
        campaignSearch.platform = 0;
        return campaignSearch;
    };

    const getCampaigns = () => {
        setDataLoading(true);
        CampaignsService.searchCampaigns({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                setDataLoading(false);
                setCampaigns(resp?.data.data);
                setPagination({
                    current: resp.data.pageInfo.pageNumber,
                    pageSize: resp.data.pageInfo.pageSize,
                    total: resp.data.pageInfo.totalItemCount,
                });
                setSearch(false);
            })
            .catch((error) => console.error(error));
    };

    const columns = [
        {
            title: <span className="table-font">Id</span>,
            dataIndex: "id",
            render: (text: string, row: any, index: number) => (
                <Link to={"/campaign/detail/" + row.id}>
                    <Button type="link">{text}</Button>
                </Link>
            ),
        },
        {
            title: <span className="table-font">{t("global.campaign-name")}</span>,
            dataIndex: "name",
            render: (text: string) => (text ? <p className="font-color">{text}</p> : "-"),
        },
        {
            title: <span className="table-font">{t("global.campaignType")}</span>,
            dataIndex: "campaignType",
            key: "campaignType",
            render: (text: any, row: any) => <span>{GetCampaignTypeTextWithManagementType(row?.typeHash, row?.managementType)}</span>,
        },
        {
            title: <span className="table-font">{t("global.start")}</span>,
            dataIndex: "startDate",
        },
        {
            title: <span className="table-font">{t("global.end")}</span>,
            dataIndex: "endDate",
            render: (text: string) => (text ? <p>{text}</p> : "-"),
        },
        {
            title: <span className="table-font">{t("campaign.create.step3.totalAmount")}</span>,
            dataIndex: "budget",
            render: (num: Number, row:any) => <span style={{whiteSpace:"nowrap"}}>{new Money(num).toStringWithCurrency(row.currency)}</span>
        },
        {
            title: <span className="table-font">{t("global.unit-price")}</span>,
            dataIndex: "unitPrice",
            render: (num: Number, row:any) => <span style={{whiteSpace:"nowrap"}}>{new Money(num).toStringWithCurrency(row.currency)}</span>
        },
        {
            title: <span className="table-font">{t("global.transformation")}</span>,
            dataIndex: "transformation",
        },
        {
            title: <span className="table-font">{t("global.category")}</span>,
            dataIndex: "categories",
            render: (categories: any[]) => (categories.length ? <p>{categories[0].name}</p> : "-"),
        },
        {
            title: <span className="table-font">{t("global.status")}</span>,
            dataIndex: "status",
            render: (status: string) => <div style={{ color: "green" }}>{status}</div>,
        },
    ];

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });
        setSearch(true);
    };

    return (
        <>
            <Row gutter={[20, 0]}>
                <Col style={{ marginBottom: "10px" }} xs={24} sm={24} md={12} span={12}>
                    <PageContainer pageTitle={t("global.my-profile")} helperContent={"Yardım içeriği eklenecek!"}>
                        <div className={"dashboard-profile"}>
                            <Row align="bottom">
                                <Col>
                                    <ProfileImg type={Roles.Brand} alt="Profil" width={128} height={128} />
                                </Col>
                                <Col style={{ padding: "8px 0px 8px 20px" }}>
                                    <h2 style={{ color: "#9254de", fontWeight: "bold" }}>{profileData?.name}</h2>
                                    <p>
                                        {profileData?.sector} / {profileData?.country}
                                    </p>
                                    <p>{profileData?.createdOn}</p>
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", padding: "60px 0px", display: "flex", justifyContent: "flex-end" }}>
                                <Link to={"/profile/edit"}>
                                    <Button type="ghost">{t("global.update-profile")}</Button>
                                </Link>
                            </Row>
                        </div>
                    </PageContainer>
                </Col>
                <Col xs={24} sm={24} md={12} span={12}>
                    <span
                        className="clickable-span"
                        onClick={() => {
                            push("/campaign/create/apply");
                        }}
                    >
                        <PageContainer pageTitle={t("campaign.create.pageTitle")} helperContent={"Yardım içeriği eklenecek!"}>
                            <div className="dashboard-campaign-create">
                                <div className="info-text mb-15">
                                    <h4 className="title">
                                        <u>{t("global.create-new-campaign")}</u>
                                    </h4>
                                </div>
                                <div className="body">{t("global.brand.dashboard.create-new-campaign-text")}</div>
                            </div>
                        </PageContainer>
                    </span>
                </Col>
            </Row>
            <Row style={{ width: "100%", marginTop: "20px" }}>
                <Col span={24}>
                    <PageContainer pageTitle={t("global.myCampaign")} helperContent={"Yardım içeriği eklenecek!"}>
                        <Table
                            pagination={pagination}
                            columns={columns}
                            dataSource={campaigns}
                            onChange={handleTableChange}
                            loading={dataLoading}
                            scroll={{ x: 1300 }}
                        />
                    </PageContainer>
                </Col>
            </Row>
        </>
    );
};

export default BrandDashboard;
