import { useTranslate } from "@pankod/refine";
import { CampaignType } from "Constanst";
import { Col, Row, Space, Tabs, DatePicker, Spin } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import { PageContainer } from "components/pageContainer/pageContainer";
import { localizationHelper } from "helpers/globalizationHelper";
import { DemoPie } from "pages/wallet/components/donutChart";
import { FC } from "react";
import { useInfluencerConversion } from "./useInfluencerConversion";

import "./style.scss";

const { RangePicker } = DatePicker;

export interface InfluencerConversionProps {
    influencerId: string;
}

export const InfluencerConversion: FC<InfluencerConversionProps> = ({ influencerId }) => {
    const t = useTranslate();

    const [conversion, loading, fetchConversion] = useInfluencerConversion(influencerId);

    return (
        <Row style={{ width: "100%", marginTop: "20px" }}>
            <Col span={24}>
                <PageContainer pageTitle={t("global.earnings")} helperContent={t("global.influencer.wallet.helper-content.earnings")}>
                    <>
                        <RangePicker
                            locale={localizationHelper.getLocaleForComponent()}
                            allowClear
                            onChange={(e, date) => fetchConversion(date[0], date[1])}
                        />

                        {loading ? (
                            <div className="conversion-loading-container">
                                <Spin />
                            </div>
                        ) : (
                            <Tabs defaultActiveKey="1">
                                <TabPane tab={<Space direction="horizontal">Tüm Kazançlar</Space>} key="1">
                                    <DemoPie detail={conversion} />
                                </TabPane>
                                <TabPane tab={<Space direction="horizontal">{t("global.downloadbased")}</Space>} key="2">
                                    <DemoPie detail={conversion} type={CampaignType.download} />
                                </TabPane>
                                <TabPane tab={<Space direction="horizontal">{t("global.actionbased")}</Space>} key="3">
                                    <DemoPie detail={conversion} type={CampaignType.action} />
                                </TabPane>
                                <TabPane tab={<Space direction="horizontal">{t("global.sellbased")}</Space>} key="4">
                                    <DemoPie detail={conversion} type={CampaignType.selling} />
                                </TabPane>
                                <TabPane tab={<Space direction="horizontal">{t("global.clickbased")}</Space>} key="5">
                                    <DemoPie detail={conversion} type={CampaignType.clicking} />
                                </TabPane>
                            </Tabs>
                        )}
                    </>
                </PageContainer>
            </Col>
        </Row>
    );
};
