import { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";
import ResponseModel from "../models/ResponseModel";
import GetBrandReportsModel from "../models/GetBrandReportsModel";
import { Fenomio_Common_BaseModels_Api_DataResponse } from "../models/Fenomio_Common_BaseModels_Api_DataResponse";
import { localizationHelper } from "helpers/globalizationHelper";
import { PaymentDetailData, PaymentListData } from "services/models/reports";

export class ReportsService {
    public static getBrandReports({
        acceptLanguage,
    }: {
        /** The campaign identifier. **/ /** Please, set language. **/ acceptLanguage?: any;
    }): CancelablePromise<ResponseModel<GetBrandReportsModel>> {
        return __request({
            method: "GET",
            path: `Report/brand`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getBrandReportsExport({
        acceptLanguage,
    }: {
        /** The campaign identifier. **/ /** Please, set language. **/ acceptLanguage?: any;
    }): CancelablePromise<ResponseModel<GetBrandReportsModel>> {
        return __request({
            method: "GET",
            path: `Report/brand/export`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCampaignReport({
        startDate,
        endDate,
        acceptLanguage,
    }: {
        startDate?: any;
        endDate?: any;
        page?: any;
        size?: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "GET",
            path: `Report/campaign?startDate=` + startDate + "&endDate=" + endDate,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCampaignReportExport({
        startDate,
        endDate,
        acceptLanguage,
    }: {
        startDate?: any;
        endDate?: any;
        acceptLanguage?: string;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "GET",
            path: `Report/campaign/export?startDate=` + startDate + "&endDate=" + endDate,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getInfluencerReport({
        startDate,
        endDate,
        acceptLanguage,
    }: {
        startDate?: any;
        endDate?: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "GET",
            path: `Report/influencer?startDate=` + startDate + "&endDate=" + endDate,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getInfluencerReportExport({
        startDate,
        endDate,
        acceptLanguage,
    }: {
        startDate?: any;
        endDate?: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "GET",
            path: `Report/influencer/export?startDate=` + startDate + "&endDate=" + endDate,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCancelBonusReport({
        startDate,
        endDate,
        onlyPayments,
        acceptLanguage,
    }: {
        startDate?: any;
        endDate?: any;
        onlyPayments?: boolean;
        acceptLanguage?: string;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<PaymentListData[]>> {
        if (!onlyPayments) onlyPayments = false;
        return __request({
            method: "GET",
            path: `Report/cancel-rebate-bonus?startDate=` + startDate + "&endDate=" + endDate + "&onlyPayments=" + onlyPayments,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCancelBonusDetail({
        id,
        acceptLanguage = "TR-tr",
    }: {
        id: string;
        acceptLanguage?: string;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<PaymentDetailData>> {
        return __request({
            method: "GET",
            path: `report/cancel-rebate-bonus/account-detail/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCancelBonusReportExport({
        startDate,
        endDate,
        onlyPayments,
        acceptLanguage,
    }: {
        startDate?: any;
        endDate?: any;
        onlyPayments?: boolean;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        console.log("onlyPayments: ", onlyPayments);
        return __request({
            method: "GET",
            path: `Report/cancel-rebate-bonus/export?startDate=` + startDate + "&endDate=" + endDate + "&onlyPayments=" + onlyPayments,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getPayEarningReport({
        startDate,
        endDate,
        page,
        size,
        acceptLanguage,
    }: {
        startDate?: any;
        endDate?: any;
        page?: any;
        size?: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "GET",
            path: `Report/pay-earnings?startDate=` + startDate + "&endDate=" + endDate + "&page=" + page + "&size=" + size,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getPayEarningReportExport({
        startDate,
        endDate,
        acceptLanguage,
    }: {
        startDate?: any;
        endDate?: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "GET",
            path: `Report/pay-earnings/export?startDate=` + startDate + "&endDate=" + endDate,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getDailyReport({
        startDate,
        endDate,
        acceptLanguage,
    }: {
        startDate?: any;
        endDate?: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "GET",
            path: `Report/daily?startDate=` + startDate + "&endDate=" + endDate,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getDailyReportExport({
        startDate,
        endDate,
        acceptLanguage,
    }: {
        startDate?: any;
        endDate?: any;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "GET",
            path: `Report/daily/export?startDate=` + startDate + "&endDate=" + endDate,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getAmazonReports({
        acceptLanguage = localizationHelper.getLocale(),
        ...body
    }: {
        /** The campaign identifier. **/ /** Please, set language. **/ acceptLanguage?: any;
        [key: string]: any;
    }): CancelablePromise<ResponseModel<GetBrandReportsModel>> {
        return __request({
            method: "POST",
            body,
            path: `Report/campaign-influencer-report`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getAmazonReportsExport({
        acceptLanguage = localizationHelper.getLocale(),
        ...body
    }: {
        /** The campaign identifier. **/ /** Please, set language. **/ acceptLanguage?: any;
    }): CancelablePromise<ResponseModel<GetBrandReportsModel>> {
        return __request({
            method: "POST",
            body,
            path: `Report/campaign-influencer-report/export`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }
}
