import { DualAxes } from "@ant-design/plots";
import { Col, DatePicker, Row, Select, useTranslate } from "@pankod/refine";
import { Roles } from "helpers/roleHelper";
import { GetPlatforms } from "helpers/StateHelpers";
import UserHelper from "helpers/userHelper";
import { getCampaignLiterals, getCampaignLiteralsForClick } from "pages/campaign/list/Components/CampaignListHelper";
import { useEffect, useState } from "react";
import { CampaignType } from "../../../../Constanst";
import { localizationHelper } from "helpers/globalizationHelper";
import { RangeValue } from "rc-picker/lib/interface";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
interface PerformanceChartProps {
    performance: any;
    changePlatform: any;
    changeDate: any;
    campaignType: CampaignType;
}

export const PerformanceChart = (props: PerformanceChartProps) => {
    const [selectedDates, setSelectedDates] = useState<RangeValue<dayjs.Dayjs>>();
    const t = useTranslate();
    const userRole = UserHelper.getRole();

    const [config] = useState<any>({
        data: [[], []],
        xField: "time",
        yField: ["value", "count"],
        yAxis: {
            count: {
                min: 0,
            },
            value: {
                min: 0,
            },
        },
        geometryOptions: [
            {
                geometry: "column",
                columnWidthRatio: 0.4,
                seriesField: "name",
                isGroup: true,
            },
            {
                geometry: "line",
                seriesField: "name",
                color: ["black", "blue"],
            },
        ],
    });
    const [dualAxes, setDualAxes] = useState<any>(<DualAxes {...config} />);
    const [, setInfluencers] = useState<any[]>([]);

    const getDualDisplayName = () => {
        const localized = getCampaignLiterals(props.campaignType);
        return t(localized);
    };

    const getDualDisplayNameForClick = () => {
        const localized = getCampaignLiteralsForClick(props.campaignType);
        return t(localized);
    };

    useEffect(() => {
        const startDate = dayjs().add(-7, "day");
        const endDate = dayjs();
        setSelectedDates([startDate, endDate]);
        //eslint-disabled-next-line
    }, []);
    useEffect(() => {
        if (props.performance && props.performance?.results) {
            const clicks: any[] = [];
            const transforms: any[] = [];

            (props.performance?.results as any[]).reverse().forEach((i) => {
                clicks.push({
                    time: i.time,
                    count: i.count,
                    name: getDualDisplayName(),
                });

                if (props.campaignType !== CampaignType.clicking) {
                    clicks.push({
                        time: i.time,
                        count: i.clickCount,
                        name: getDualDisplayNameForClick(),
                    });
                }

                if (userRole !== Roles.Influencer) {
                    transforms.push({
                        time: i.time,
                        value: Number(i.fenomioBudget.toFixed(2)),
                        name: t("global.fenomioTotalBudgett") + "(" + props.performance.currency + ")",
                    });
                    transforms.push({
                        time: i.time,
                        value: Number(i.influencerBudget.toFixed(2)),
                        name: t("global.phenomenonTotalBudget") + "(" + props.performance.currency + ")",
                    });
                } else {
                    transforms.push({
                        time: i.time,
                        value: Number(i.influencerBudget.toFixed(2)),
                        name: t("global.earning") + "(" + props.performance.currency + ")",
                    });
                }
            });

            const conf = {
                data: [transforms, clicks],
                xField: "time",
                yField: ["value", "count"],
                yAxis: {
                    count: {
                        min: 0,
                    },
                    value: {
                        min: 0,
                    },
                },
                geometryOptions: [
                    {
                        geometry: "column",
                        columnWidthRatio: 0.4,
                        seriesField: "name",
                        isGroup: true,
                        color: ["#952AE7", "#FF0C85"],
                    },
                    {
                        geometry: "line",
                        seriesField: "name",
                        color: ["black", "blue"],
                    },
                ],
            };
            setDualAxes(<DualAxes {...conf} />);
            setInfluencers(props.performance?.influencers);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.performance, props.performance?.results]);

    const changeDate = (e: any) => {
        props.changeDate(e);
        var endDate = e !== null ? e[1] : dayjs();
        var startDate = e !== null ? e[0] : dayjs().add(-7, "day");
        setSelectedDates([startDate, endDate]);
    };

    return (
        <Row gutter={[16, 16]}>
            <Col xs={24} sm={24} md={16} span={16}>
                {dualAxes}
            </Col>
            <Col span={8}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <Select
                            style={{ width: "100%" }}
                            placeholder={t("global.platforms")}
                            allowClear
                            onChange={(e) => props.changePlatform(e)}
                            options={GetPlatforms()}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <RangePicker
                            locale={localizationHelper.getLocaleForComponent()}
                            onChange={(e) => changeDate(e)}
                            style={{ width: "100%" }}
                            value={selectedDates}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
};
