import { Button, Col, Row, Space, Typography, useTranslate } from "@pankod/refine";
import { ShowCampaignExplanation } from "components/atomics/show-items";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { CampaignInfluencerStates, CampaignStates } from "Constanst";
import { FunctionComponent, useState } from "react";
import { Money } from "services/models/money";
const { Text, Title } = Typography;
interface ActionCampaignDetailProps {
    campaignDetail: any;
    apply: (id: any) => void;
    acceptInvite: (id: any) => void;
    rejectInvite: (id: any) => void;
    showCampaignState: string;
}

export const ActionCampaignDetail: FunctionComponent<ActionCampaignDetailProps> = (props: ActionCampaignDetailProps) => {
    const [isLoading] = useState(false);
    const t = useTranslate();

    return (
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <FenBreadCrumb
                items={[
                    { name: t("global.campaigns"), url: "/campaign/list" },
                    { name: props.campaignDetail?.name, url: "" },
                ]}
            />
            <PageContainer pageTitle={props.campaignDetail?.name} helperContent={"Buraya helper içeriği gelecek"}>
                <>
                    <DisplayForm
                        campaignDetail={props.campaignDetail}
                        isLoading={isLoading}
                        apply={props.apply}
                        acceptInvite={props.acceptInvite}
                        rejectInvite={props.rejectInvite}
                        showCampaignState={props.showCampaignState}
                    />
                </>
            </PageContainer>
        </Space>
    );
};

interface DisplayFormProps {
    isLoading: boolean;
    apply: (id: any) => void;
    acceptInvite: (id: any) => void;
    rejectInvite: (id: any) => void;
    campaignDetail: any;
    showCampaignState: string;
}

const DisplayForm: FunctionComponent<DisplayFormProps> = (props: DisplayFormProps) => {
    const t = useTranslate();

    const getCategories = () => {
        if (props.campaignDetail?.categories && props.campaignDetail.categories.length > 0) {
            return (props.campaignDetail?.categories as any[]).map((c) => "#" + c.name).join(" ");
        } else {
            return "-";
        }
    };

    return (
        <>
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                <Row>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <Title level={5}>{t("global.campaignState")}</Title>
                        <Text>{props.showCampaignState}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <Title level={5}>{t("campaign.create.step3.startDateLabel")}</Title>
                        <Text>{props.campaignDetail.startDate}</Text>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <Title level={5}>{t("global.campaign-name")}</Title>
                        <Text>{props.campaignDetail?.name}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <Title level={5}>{t("global.end-date")}</Title>
                        <Text>{props.campaignDetail.endDate}</Text>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <ShowCampaignExplanation description={props.campaignDetail.description} />
                    </Col>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <Title level={5}>{t("global.unit-price")}</Title>
                        <Text>{new Money(props.campaignDetail.unitPrice).toStringWithCurrency()}</Text>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <Title level={5}>{t("global.platforms", "Platformlar")}</Title>
                        <Text>{props.campaignDetail.allPlatforms.join(", ")}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <Title level={5}>{t("campaign.create.step3.amountByInfluencerLabell")}</Title>
                        <Text>{new Money(props.campaignDetail.perInfluencerBudget).toStringWithCurrency()}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={12} span={6}>
                        <Title level={5}>{t("global.location")}</Title>
                        <Text>{props.campaignDetail.country}</Text>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <Title level={5}>{t("campaign.create.step2.categoriesLabel")}</Title>
                        <Text>{getCategories()}</Text>
                    </Col>
                </Row>

                <hr style={{ border: "1px solid #0000000F" }} />
                {((props.campaignDetail?.campaignState === CampaignStates.Accepted &&
                    ((props.campaignDetail?.campaignInfluencerState !== null &&
                        props.campaignDetail?.campaignInfluencerState === CampaignInfluencerStates.Rejected) ||
                        props.campaignDetail?.campaignInfluencerState === CampaignInfluencerStates.Revert)) ||
                    (props.campaignDetail?.campaignState === CampaignStates.Accepted &&
                        props.campaignDetail?.campaignInfluencerState === null)) && (
                    <Row style={{ display: "flex", justifyContent: "end", padding: "20px" }}>
                        <Col xs={24} sm={24} md={4} span={4}>
                            <Button
                                type="default"
                                onClick={() => {
                                    props.apply(props.campaignDetail?.id);
                                }}
                            >
                                {t("global.apply", "Başvur")}
                            </Button>
                        </Col>
                    </Row>
                )}

                {props.campaignDetail?.campaignState === CampaignStates.Accepted &&
                    props.campaignDetail?.campaignInfluencerState != null &&
                    props.campaignDetail?.campaignInfluencerState === CampaignInfluencerStates.Invited && (
                        <Row style={{ display: "flex", justifyContent: "end", padding: "20px" }}>
                            <Col xs={24} sm={24} md={10} span={10}>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        props.rejectInvite(props.campaignDetail?.id);
                                    }}
                                >
                                    {t("global.reject", "Reddet")}
                                </Button>
                            </Col>
                            <Col xs={24} sm={24} md={10} span={10}>
                                <Button
                                    type="default"
                                    onClick={() => {
                                        props.acceptInvite(props.campaignDetail?.id);
                                    }}
                                >
                                    {t("global.accept", "Kabul Et")}
                                </Button>
                            </Col>
                        </Row>
                    )}
            </Space>
        </>
    );
};
