import { Tabs, Space, Badge, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { FunctionComponent, useEffect, useState } from "react";
import { InfluencerList } from "./InfluencerList";
import { CampaignsService } from "../../../../../../../../services";
import { localizationHelper } from "helpers/globalizationHelper";
import { InfluencerListType } from "pages/campaign/InfluencerListType";

const { TabPane } = Tabs;

interface CampaignInfluencerListContainerProps {
    data: any;
    categories: any[];
}

const CampaignInfluencerListContainer: FunctionComponent<CampaignInfluencerListContainerProps> = (
    props: CampaignInfluencerListContainerProps
) => {
    const t = useTranslate();
    const [countData, setCountData] = useState<any>({
        totalCount: 0,
        appliedCount: 0,
        activeCount: 0,
        rejectedCount: 0,
        invitedCount: 0,
    });

    useEffect(() => {
        fetchCounts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCounts = () => {
        CampaignsService.getCampaignInfluencersInfoByCampaign({
            campaignId: props.data.id,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setCountData(resp.data);
        });
    };

    const updateCount = () => {
        fetchCounts();
    };

    return (
        <PageContainer pageTitle={props.data?.name ?? ""} helperContent={"Buraya helper içeriği gelecek"}>
            <Tabs defaultActiveKey="1" destroyInactiveTabPane>
                <TabPane
                    disabled={countData.totalCount === 0}
                    tab={
                        <Space direction="horizontal">
                            {t("global.applicantPhenomenons")}
                            <Badge count={countData.totalCount} />
                        </Space>
                    }
                    key="1"
                >
                    <InfluencerList
                        categories={props.categories}
                        updateCount={updateCount}
                        campaignId={props.data.id}
                        listType={InfluencerListType.listAll}
                    />
                </TabPane>
                <TabPane
                    disabled={countData.activeCount === 0}
                    tab={
                        <Space direction="horizontal">
                            {t("global.activeInfluencers")}
                            <Badge count={countData.activeCount} />
                        </Space>
                    }
                    key="2"
                >
                    <InfluencerList
                        categories={props.categories}
                        updateCount={updateCount}
                        campaignId={props.data.id}
                        listType={InfluencerListType.active}
                    />
                </TabPane>
                <TabPane
                    disabled={countData.appliedCount === 0}
                    tab={
                        <Space direction="horizontal">
                            {t("global.pendingInfluencers")}
                            <Badge count={countData.appliedCount} />
                        </Space>
                    }
                    key="3"
                >
                    <InfluencerList
                        categories={props.categories}
                        updateCount={updateCount}
                        campaignId={props.data.id}
                        listType={InfluencerListType.applied}
                    />
                </TabPane>
                <TabPane
                    disabled={countData.rejectedCount === 0}
                    tab={
                        <Space direction="horizontal">
                            {t("global.rejectedInfluencers")}
                            <Badge count={countData.rejectedCount} />
                        </Space>
                    }
                    key="4"
                >
                    <InfluencerList
                        categories={props.categories}
                        updateCount={updateCount}
                        campaignId={props.data.id}
                        listType={InfluencerListType.rejected}
                    />
                </TabPane>
                <TabPane
                    disabled={countData.invitedCount === 0}
                    tab={
                        <Space direction="horizontal">
                            {t("global.invitedInfluencers")}
                            <Badge count={countData.invitedCount} />
                        </Space>
                    }
                    key="5"
                >
                    <InfluencerList
                        categories={props.categories}
                        updateCount={updateCount}
                        campaignId={props.data.id}
                        listType={InfluencerListType.invited}
                    />
                </TabPane>
            </Tabs>
        </PageContainer>
    );
};

export default CampaignInfluencerListContainer;
