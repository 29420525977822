/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { Fenomio_Common_BaseModels_Api_DataResponse } from "services";
import { Country } from "services/models/Lookups";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class LookupsService {
    /**
     * Get Countries
     * @returns Fenomio_Common_BaseModels_Api_DetailItemDto Success
     * @throws ApiError
     */
    public static getCountries({
        acceptLanguage,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<Country[]>> {
        return __request({
            method: "GET",
            path: `lookups/countries`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getSectors({
        acceptLanguage,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<Array<any>>> {
        return __request({
            method: "GET",
            path: `lookups/sectors`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static searchSectors({
        body,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        body?: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `lookups/sectors/search`,
            body: body,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }
}
