import { Button, Col, Form, Input, Modal, Row, Select, Space, Tag, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { InfluencerStatusColors } from "Constanst";
import { Format } from "helpers/dateFormatHelper";
import { GetInfluencerStatuText } from "helpers/StateHelpers";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import {
    Fenomio_Common_BaseModels_Api_DetailItemDto,
    Fenomio_Contract_App_Brand_UpdateBrandRequest,
    Fenomio_Contract_App_Influencer_BillingDetail_CreateOrUpdateInfluencerBillingDetailRequest,
    Influencer,
    InfluencersService,
    LookupsService,
} from "services";
import { checkTCKN } from "../../../../../helpers/FormHelper";
import { localizationHelper } from "../../../../../helpers/globalizationHelper";
import { CategoryService } from "../../../../../services/services/CategoryService";
import { GetAllCategoryData } from "../../../../campaign/Model/GetAllCategoryData";
import Avatar from "../../../../profile/edit/editProfilePic";
import { Link } from "react-router-dom";

interface AdminInfluencerGeneralInfoProps {
    influencer: Influencer;
    setEditing: (value: boolean) => void;
    setPaymentInfoEditing: (value: boolean) => void;
    isEditing: boolean;
    isPaymentInfoEditing: boolean;
    refresh: any;
}

type LayoutType = Parameters<typeof Form>[0]["layout"];

export const AdminInfluencerGeneralInfo: FunctionComponent<AdminInfluencerGeneralInfoProps> = (props: AdminInfluencerGeneralInfoProps) => {
    return (
        <PageContainer
            pageTitle={props.influencer?.firstName + " " + (props.influencer?.lastName ? props.influencer?.lastName : "")}
            helperContent={""}
        >
            <>
                {!props.isEditing && !props.isPaymentInfoEditing && (
                    <DisplayForm
                        setPaymentInfoEditing={props.setPaymentInfoEditing}
                        influencer={props.influencer}
                        setEditing={props.setEditing}
                    />
                )}
                {props.isEditing && <EditForm refresh={props.refresh} influencer={props.influencer} setEditing={props.setEditing} />}
                {props.isPaymentInfoEditing && (
                    <PaymentInfoEditForm refresh={props.refresh} influencer={props.influencer} setEditing={props.setPaymentInfoEditing} />
                )}
            </>
        </PageContainer>
    );
};

interface DisplayFormProps {
    influencer: Influencer;
    setEditing: (value: boolean) => void;
    setPaymentInfoEditing: (value: boolean) => void;
}

const DisplayForm = (props: DisplayFormProps) => {
    const t = useTranslate();

    return (
        <Form<Fenomio_Contract_App_Brand_UpdateBrandRequest> layout="horizontal" requiredMark={false} scrollToFirstError>
            <Row gutter={[30, 10]}>
                <Col span={24}>
                    <div style={{ padding: "20px 0px" }}>
                        <Row align="middle">
                            <Col>
                                <img
                                    width={150}
                                    src={props.influencer?.logoPath ? props.influencer?.logoPath : "/images/profile/profile_influencer.svg"}
                                    alt={"Profil"}
                                    className="profile-pic"
                                />
                            </Col>
                            <Col style={{ padding: "0px 0px 0px 20px" }}>
                                <h2 style={{ color: "#9254de", fontWeight: "bold", marginTop: "16px" }}>
                                    <Space>
                                        <span>
                                            {props.influencer?.firstName} {props.influencer?.lastName}{" "}
                                        </span>
                                        <Tag color={InfluencerStatusColors[props.influencer?.status]?.toString()}>
                                            {GetInfluencerStatuText(props.influencer?.status)}
                                        </Tag>
                                    </Space>
                                </h2>
                                <p style={{ marginBottom: "8px" }}></p>
                                <p>
                                    <span>
                                        {props.influencer?.categories &&
                                            props.influencer?.categories.length > 0 &&
                                            props.influencer?.categories.map((c: any) => {
                                                return c.isPublic ? (
                                                    <span>{"#" + c.name + " "}</span>
                                                ) : (
                                                    <span style={{ color: "red" }}>{"#" + c.name + " "}</span>
                                                );
                                            })}
                                    </span>
                                    {" / " + props.influencer?.country}
                                </p>
                                <p>{moment(props.influencer?.createdOn).format("DD-MM-YYYY")}</p>
                            </Col>
                        </Row>
                    </div>
                    <hr style={{ borderTop: "1px solid #952AE7" }} />
                    <Row>
                        <Col xs={24} sm={24} md={12} span={12}>
                            <h3 style={{ color: "#952AE7", fontWeight: "bold" }}>Id</h3>
                            <p>#{props.influencer?.id}</p>

                            <h3 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("global.name-surname")}</h3>
                            <p>
                                {props.influencer?.firstName} {props.influencer?.lastName}
                            </p>

                            <h3 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("global.country")}</h3>
                            <p>{props.influencer?.country}</p>

                            <h3 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("global.contact-number")}</h3>
                            <p>{props.influencer?.phone}</p>

                            <h3 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("global.contact-email")}</h3>
                            <p>{props.influencer?.email}</p>
                        </Col>
                        <Col xs={24} sm={24} md={12} span={12}>
                            <h3 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("global.about-phenomenon")}</h3>
                            <p>{props.influencer?.biography}</p>

                            <h3 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("global.payment-information")}</h3>

                            <p>
                                {
                                    [null, t("global.bill-exception-licence"), t("global.invoice"), t("global.bill-active-cantinvoice")][
                                        props.influencer.billingStatus
                                    ]
                                }
                            </p>
                            <p>
                                {props.influencer?.billingDetail && props.influencer?.billingDetail.isBillActive ? (
                                    <span>
                                        {props.influencer?.billingDetail?.companyName} -{" "}
                                        {t("global.company-type-" + props.influencer?.billingDetail?.companyType?.toString())}
                                    </span>
                                ) : (
                                    <div>
                                        <span>
                                            {props.influencer?.billingDetail?.firstName} {props.influencer?.billingDetail?.lastName} -{" "}
                                            {props.influencer?.billingDetail?.identityNumber}
                                        </span>
                                        <br />
                                        <span>{props.influencer?.billingDetail?.address}</span>
                                    </div>
                                )}
                            </p>
                            <p>
                                {props.influencer?.billingDetail && props.influencer.billingDetail.isBillActive ? (
                                    <span>
                                        {props.influencer?.billingDetail?.bankName} - {props.influencer?.billingDetail?.iban}
                                    </span>
                                ) : (
                                    <div>
                                        <span>
                                            {props.influencer?.billingDetail?.bankName} - {props.influencer?.billingDetail?.iban}
                                        </span>{" "}
                                        <br />
                                    </div>
                                )}
                            </p>
                            <Button
                                onClick={() => {
                                    props.setPaymentInfoEditing(true);
                                }}
                                type="default"
                                style={{ fontWeight: "bold" }}
                            >
                                {t("global.payment-information-update")}
                            </Button>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Space direction="horizontal" size={"middle"} style={{ width: "100%", justifyContent: "flex-end" }}>
                <Link to={"/influencers/detail/performance/" + props.influencer?.id}>
                    <Button type="default">{t("campaign.create.step3.viewearnings")}</Button>
                </Link>
                <Button
                    onClick={() => {
                        props.setEditing(true);
                    }}
                >
                    {" "}
                    {t("global.update-profile")}{" "}
                </Button>
            </Space>
        </Form>
    );
};

interface EditFormProps {
    influencer: any;
    setEditing: (value: boolean) => void;
    refresh: any;
}

const EditForm = (props: EditFormProps) => {
    const [, setLoading] = useState(false);
    const [form] = Form.useForm();
    const { TextArea } = Input;
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [categories, setCategories] = useState<GetAllCategoryData[]>([]);
    const [countries, setCountries] = useState<Fenomio_Common_BaseModels_Api_DetailItemDto[]>([]);
    const { Option } = Select;
    const [, setFormLayout] = useState<LayoutType>("horizontal");
    const t = useTranslate();

    useEffect(() => {
        form.resetFields();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.influencer]);

    const editUser = (value: any) => {
        setLoading(true);
        InfluencersService.updateInfluencerProfile({
            id: value.id,
            body: {
                name: value.firstName,
                surname: value.lastName,
                phone: value.phone,
                biography: value.biography,
                categories: value.categories,
                country: value.countryId,
            },
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            Modal.success({
                title: t("notifications.success"),
                content: t("notifications.updatesuccess"),
            });
            props.refresh(value.id);
            props.setEditing(false);
            setLoading(false);
        });
    };

    const prepareInitialValues = () => {
        let profileData = JSON.parse(JSON.stringify(props.influencer));
        if (profileData) {
            profileData.categories = (profileData.categories as any[]).map((c) => c.id);
            return profileData;
        }
    };

    useEffect(() => {
        getCountries();
        getAllCategory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllCategory = async () => {
        setCategoriesLoading(true);
        CategoryService.getAllCategory({ acceptLanguage: localizationHelper.getLocale() })
            .then((res) => {
                setCategories(res.data as any[]);
                setCategoriesLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const getCountries = async () => {
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
        });
    };

    const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
        setFormLayout(layout);
    };

    return (
        <>
            <Row>
                <style>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance:textfield; /* Firefox */
        }
      `}</style>
                <Col span={24}>
                    <div style={{ padding: "20px 0px" }}>
                        <Row>
                            <Col style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                {/* <img src={profileData?.logoPath} alt="img" width={150} style={{ borderRadius: '10px', boxShadow: '1px 1px 1px 1px' }} />
              <EditOutlined style={{ marginTop: '-20px', marginRight: '8px', fontSize: '18px', cursor: 'pointer' }} /> */}
                                <Avatar profileData={prepareInitialValues} />
                            </Col>
                        </Row>
                    </div>
                    <hr style={{ borderTop: "1px solid #952AE7" }} />
                    <Form
                        layout="vertical"
                        form={form}
                        initialValues={prepareInitialValues()}
                        onFinish={(values) => {
                            editUser(values);
                        }}
                        onValuesChange={onFormLayoutChange}
                    >
                        <Row gutter={[20, 0]}>
                            <Col xs={24} sm={24} md={8} span={8}>
                                <label>Id</label>
                                <Form.Item name={"id"}>
                                    <Input disabled={true} />
                                </Form.Item>
                                <label>{t("global.name")}</label>
                                <Form.Item
                                    rules={[{ required: true, type: "string", message: t("global.first-name-required") }]}
                                    name={"firstName"}
                                >
                                    <Input />
                                </Form.Item>
                                <label>{t("global.surname")}</label>
                                <Form.Item
                                    rules={[{ required: true, type: "string", message: t("global.last-name-required") }]}
                                    name={"lastName"}
                                >
                                    <Input />
                                </Form.Item>
                                <label>{t("global.country")}</label>
                                <Form.Item
                                    rules={[{ required: true, type: "string", message: t("global.required-location") }]}
                                    name={"countryId"}
                                >
                                    <Select loading={categoriesLoading}>
                                        {countries.map((item) => (
                                            <Option key={item.id}>{item.text}</Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} span={8}>
                                <label>{t("campaign.create.step2.categoriesLabel")}</label>
                                <Form.Item
                                    rules={[
                                        { required: true, type: "array", message: t("global.required-category") },
                                        {
                                            type: "array",
                                            validator: (rule, value) => {
                                                let publicCategoryCounter = categories.filter(
                                                    (x: any) => value.includes(x.id) && x.isPublic === true
                                                ).length;

                                                return new Promise((resolve, reject) => {
                                                    if (publicCategoryCounter > 3)
                                                        reject(t("global.required-category-public-limit").toString());
                                                    else resolve("");
                                                });
                                            },
                                        },
                                    ]}
                                    name={"categories"}
                                >
                                    <Select
                                        mode="tags"
                                        allowClear
                                        loading={categoriesLoading}
                                        options={categories.map((item) => {
                                            return {
                                                label: item.name,
                                                value: item.id,
                                            };
                                        })}
                                    />
                                </Form.Item>
                                <label>{t("global.contact-number")}</label>
                                <Form.Item name={"phone"}>
                                    <Input />
                                </Form.Item>
                                <label>{t("global.contact-email")}</label>
                                <Form.Item rules={[{ required: true, type: "email", message: t("global.required-email") }]} name={"email"}>
                                    <Input disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} span={8}>
                                <label>{t("global.about-phenomenon")}</label>
                                <Form.Item name={"biography"}>
                                    <TextArea rows={4} />
                                </Form.Item>

                                <Row justify="end">
                                    <Form.Item>
                                        <Button htmlType={"submit"} type="default" style={{ borderRadius: "10px" }}>
                                            {t("buttons.save")}
                                        </Button>
                                    </Form.Item>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};

const PaymentInfoEditForm = (props: any) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
    const { TextArea } = Input;
    const [isInvoice, setIsInvoice] = useState(0);
    const { Option } = Select;
    const t = useTranslate();

    const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
        setFormLayout(layout);
    };

    useEffect(() => {
        if (props.influencer) {
            if (props.influencer.billingDetail !== null) {
                if (props.influencer.billingDetail.hasExceptionLicence) {
                    setIsInvoice(2);
                } else if (props.influencer.billingDetail.isBillActive) {
                    setIsInvoice(1);
                } else {
                    setIsInvoice(0);
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.influencer]);

    const formItemLayout =
        formLayout === "horizontal"
            ? {
                  labelCol: { span: 14 },
                  wrapperCol: { span: 14 },
              }
            : null;

    const buttonItemLayout =
        formLayout === "horizontal"
            ? {
                  wrapperCol: { span: 14, offset: 4 },
              }
            : null;

    const prepareInitialValues = () => {
        if (props.influencer === null) {
            return {};
        }

        if (props?.influencer?.billingDetail === null || props?.influencer?.billingDetail === undefined) {
            return {};
        } else {
            let billingDetail = JSON.parse(JSON.stringify(props.influencer.billingDetail));
            if (billingDetail.isBillActive !== true) {
                billingDetail.fullName = billingDetail.firstName + " " + billingDetail.lastName;
                form.setFieldsValue({ isBillActive: 0 });
            } else form.setFieldsValue({ isBillActive: 1 });
            if (billingDetail.hasExceptionLicence) form.setFieldsValue({ isBillActive: 2 });
            return billingDetail;
        }
    };

    const submit = (values: any) => {
        let hasExceptionLicence = isInvoice === 2;
        let isBillActive = isInvoice === 1;
        let request: Fenomio_Contract_App_Influencer_BillingDetail_CreateOrUpdateInfluencerBillingDetailRequest = {
            ...values,
            hasExceptionLicence: hasExceptionLicence,
            isBillActive: isBillActive,
            id: props.influencer?.billingDetail?.id,
            iban: values.iban.toRemoveEmptyCharacters(),
        };
        InfluencersService.upsertInfluencerBillingDetail({
            id: props.influencer?.id,
            body: request,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            props.refresh(props.influencer.id);
            props.setEditing(false);
        });
    };

    const handleTCKN = (e: React.ChangeEvent<HTMLInputElement>) => {
        let parsedValue = e.target.value;
        const regexPattern = /[^0-9]/gm;
        parsedValue = parsedValue.replace(regexPattern, "");

        let formValues = form.getFieldsValue();
        formValues["identityNumber"] = parsedValue;
        form.setFieldsValue(formValues);
    };

    return (
        <>
            <Row>
                <Col span={24} style={{ marginTop: "10px" }}>
                    <div style={{ padding: "20px 0px" }}>
                        <Row>
                            <Col style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                                <img
                                    src={props.influencer?.logoPath ? props.influencer?.logoPath : "/images/profile/profile_influencer.png"}
                                    alt="img"
                                    width={150}
                                    style={{ border: "1px solid #FFFFFF", borderRadius: "14px" }}
                                />
                            </Col>
                            <Col style={{ padding: "5px 0px 0px 20px" }}>
                                <div
                                    style={{ paddingBottom: "30px" }}
                                    className={props.influencer.status === 1 ? "status status-active" : "status status-passive"}
                                >
                                    {t("global." + (props.influencer.status === 1 ? "active" : "passive"))}
                                </div>
                                <h2 className="color-purple">
                                    {props.influencer?.firstName} {props.influencer?.lastName}
                                </h2>
                                <p className="color-gray margin-0">
                                    <span>
                                        {props.influencer?.categories &&
                                            props.influencer.categories.length > 0 &&
                                            props.influencer.categories.map((c: any) => "#" + c.name).join(" ")}
                                    </span>
                                    {" / "} {props.influencer?.country}
                                </p>
                                <p className="color-gray margin-0">{Format(props.influencer?.createdOn)}</p>
                            </Col>
                        </Row>
                    </div>
                    <hr style={{ borderTop: "1px solid #952AE7" }} />
                    <Form
                        {...formItemLayout}
                        layout="vertical"
                        form={form}
                        initialValues={prepareInitialValues()}
                        onFinish={(values) => submit(values)}
                        onValuesChange={onFormLayoutChange}
                        style={{ display: "flex" }}
                    >
                        <Row gutter={[50, 10]} style={{ width: "100%" }}>
                            <Col xs={24} sm={24} md={8} span={8}>
                                <Form.Item
                                    label={t("global.bill-active")}
                                    name={"isBillActive"}
                                    style={{
                                        width: 250,
                                    }}
                                    rules={[{ required: true, message: t("global.bill-active-required") }]}
                                >
                                    <Select onChange={(v) => setIsInvoice(v)}>
                                        <Option value={1}>{t("global.bill-active-invoice")}</Option>
                                        <Option value={0}>{t("global.bill-active-cantinvoice")}</Option>
                                        <Option value={2}>{t("global.bill-exception-licence")}</Option>
                                    </Select>
                                </Form.Item>

                                {isInvoice === 1 ? (
                                    <Form.Item
                                        label={t("global.company-type")}
                                        name={"companyType"}
                                        rules={isInvoice === 1 ? [{ required: true, message: t("global.full-name-required") }] : []}
                                        style={{
                                            width: 250,
                                        }}
                                    >
                                        <Select
                                            options={[
                                                { label: t("global.company-type-anonim"), value: "anonim" },
                                                { label: t("global.company-type-limited"), value: "limited" },
                                                { label: t("global.company-type-sahis"), value: "sahis" },
                                            ]}
                                        ></Select>
                                    </Form.Item>
                                ) : (
                                    <div>
                                        <Form.Item
                                            label={t("global.name")}
                                            name={"firstName"}
                                            rules={isInvoice !== 1 ? [{ required: true, message: t("global.first-name-required") }] : []}
                                            style={{
                                                width: 250,
                                            }}
                                        >
                                            <Input placeholder={t("global.first-name-placeholder")} />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("global.surname")}
                                            name={"lastName"}
                                            rules={isInvoice !== 1 ? [{ required: true, message: t("global.last-name-required") }] : []}
                                            style={{
                                                width: 250,
                                            }}
                                        >
                                            <Input placeholder={t("global.last-name-placeholder")} />
                                        </Form.Item>
                                    </div>
                                )}
                            </Col>
                            <Col xs={24} sm={24} md={8} span={8}>
                                {isInvoice === 1 ? (
                                    <Form.Item
                                        label={t("global.company-name")}
                                        name={"companyName"}
                                        rules={isInvoice === 1 ? [{ required: true, message: t("global.company-name-required") }] : []}
                                        style={{
                                            width: 250,
                                        }}
                                    >
                                        <Input placeholder={t("global.company-name-placeholder")} />
                                    </Form.Item>
                                ) : (
                                    <Form.Item
                                        label={t("global.identity-number")}
                                        name={"identityNumber"}
                                        style={{
                                            width: 250,
                                        }}
                                        rules={
                                            isInvoice !== 1
                                                ? [
                                                      { required: true, message: t("global.identity-number-required") },
                                                      { min: 11, message: t("global.identity-number-min") },
                                                      { max: 11, message: t("global.identity-number-max") },
                                                      {
                                                          validator: (rule, value) => {
                                                              return new Promise((resolve, reject) => {
                                                                  if (!checkTCKN(value)) reject(t("global.identity-number-invalid"));
                                                                  else resolve("");
                                                              });
                                                          },
                                                      },
                                                  ]
                                                : []
                                        }
                                    >
                                        <Input type="text" placeholder={t("global.identity-number-placeholder")} onChange={handleTCKN} />
                                    </Form.Item>
                                )}
                                <Form.Item
                                    label={t("global.bank-name")}
                                    name={"bankName"}
                                    rules={[{ required: true, message: t("global.bank-name-required") }]}
                                    style={{
                                        width: 250,
                                    }}
                                >
                                    <Input placeholder={t("global.bank-name-placeholder")} />
                                </Form.Item>
                                <Form.Item
                                    name={"iban"}
                                    label={t("global.iban")}
                                    rules={[
                                        { required: true, message: t("global.iban-required") },
                                        { min: 15, message: t("global.iban-min-value") },
                                        { max: 45, message: t("global.iban-max-value") },
                                    ]}
                                    style={{
                                        width: 250,
                                    }}
                                >
                                    <Input placeholder={t("global.iban-placeholder")} />
                                </Form.Item>
                            </Col>
                            {isInvoice !== 1 && (
                                <Col xs={24} sm={24} md={8} span={8}>
                                    <Form.Item
                                        label={t("global.address")}
                                        name={"address"}
                                        rules={isInvoice !== 1 ? [{ required: true, max: 250, message: t("global.address-required") }] : []}
                                        style={{
                                            width: 250,
                                        }}
                                    >
                                        <TextArea rows={4} placeholder={t("global.address-placeholder")} />
                                    </Form.Item>
                                </Col>
                            )}
                            <Row style={{ width: "100%" }} justify="end">
                                <Form.Item {...buttonItemLayout}>
                                    <Button htmlType="submit" type="default" style={{ borderRadius: "10px" }}>
                                        {t("buttons.save")}
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
