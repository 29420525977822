export const TOKEN_KEY = "accessToken";
export const UPDATED_PHOTO = "updatedPhoto";
export const REFRESH_TOKEN_KEY = "refreshToken";
export const REFRESH_TOKEN_EXPIRE_KEY = "refreshTokenExpireDate";
export const REMEMBER_ME_KEY = "rememberMe";

export const isDebug = () => {
    return process.env.NODE_ENV === "development";
};

export const ProfileConstants = {
    detailMaxLength: 100,
    workDetailMaxLength: 300,
};
export enum Platforms {
    Instagram = 1,
    Twitter = 2,
    YouTube = 3,
    Twitch = 4,
    Telegram = 5,
    Discord = 6,
    TikTok = 7,
}

export enum CampaignInfluencerSocialMediaTransactionSource {
    ClickMeter = 1,
    Other = 2,
}

export enum PlatformsIcons {
    "/images/social-media-icon/INSTAGRAM36Px.svg" = Platforms.Instagram,
    "/images/social-media-icon/TWITTER36Px.svg" = Platforms.Twitter,
    "/images/social-media-icon/YOUTUBE36Px.svg" = Platforms.YouTube,
    "/images/social-media-icon/TWITCH36Px.svg" = Platforms.Twitch,
    "/images/social-media-icon/TELEGRAM36Px.svg" = Platforms.Telegram,
    "/images/social-media-icon/DISCORD36Px.svg" = Platforms.Discord,
    "/images/social-media-icon/TIKTOK36Px.svg" = Platforms.TikTok,
}

export enum CampaignManagementType {
    SelfService = 1,
    Managed = 2,
}

export enum CampaignType {
    clicking = 1,
    download = 2,
    action = 3,
    selling = 4,
    amazon = 5,
}

export enum TrackingPlatform {
    Adjust = 1,
    Appsflyer = 2,
    BcnMoniteze = 3,
    Website = 4,
}

export enum CampaignStates {
    WaitingForPayment = 1 as any,
    Paused = 2 as any,
    Accepted = 3 as any,
    Expired = 4 as any,
    BudgetCompleted = 5 as any,
}

export enum CampaignStatesColors {
    "cyan" = CampaignStates.WaitingForPayment as number,
    "orange" = CampaignStates.Paused as number,
    "green" = CampaignStates.Accepted as number,
    "purple" = CampaignStates.Expired as number,
    "magenta" = CampaignStates.BudgetCompleted as number,
}

export enum CampaignInfluencerStates {
    Accepted = 1, // Active Influencers
    Applied = 2, // Waiting Influencers
    Rejected = 3, // Rejected Influencers
    Invited = 4, // Invited to Campaign Influencers
    Revert = 5, // Revert invitation by influencer.
    BudgetCompleted = 6, // Influencer Budget Completed, but Campaign Continue...
    Removed = 7, // Removed from Campaign - Influencers
    Exit = 8, // Influencer exit by own decision. kendi kararıyla kampanyadan çıktı? çıkarılma talebi yollanacak?
}

export enum BrandStatus {
    Active = 1,
    InActive = 2,
    NeverActive = 3,
    WaitingForVerification = 4,
}

export enum BrandStatusColors {
    "green",
    "blue",
    "red",
    "orange",
}

export enum InfluencerStatus {
    Active = 1,
    InActive = 2,
    NeverActive = 3,
}

export enum InfluencerStatusColors {
    "green" = InfluencerStatus.Active,
    "blue" = InfluencerStatus.InActive,
    "red" = InfluencerStatus.NeverActive,
}

export const PaginationConstant = {
    initialPage: 1,
    initialPageSize: 10,
};

export const InitialCountryArray = ["75bbcb99-c7a1-4197-b810-4a70caa8ef13"];
export const InitialCountry = "75bbcb99-c7a1-4197-b810-4a70caa8ef13";

interface SocialMedia {
    name: keyof typeof Platforms;
    code: number;
    icon: string;
}
export const SocialMedias: Record<string, SocialMedia> = {
    instagram: { name: "Instagram", code: 1, icon: "instagram" },
    twitter: { name: "Twitter", code: 2, icon: "twitter" },
    youtube: { name: "YouTube", code: 3, icon: "youtube" },
    twitch: { name: "Twitch", code: 4, icon: "twitch" },
    telegram: { name: "Telegram", code: 5, icon: "telegram" },
    discord: { name: "Discord", code: 6, icon: "discord" },
    tiktok: { name: "TikTok", code: 7, icon: "tiktok" },
};
export const findSocialMediaById = (id: number) => {
    switch (id) {
        case 1:
            return SocialMedias.instagram;
        case 2:
            return SocialMedias.twitter;
        case 3:
            return SocialMedias.youtube;
        case 4:
            return SocialMedias.twitch;
        case 5:
            return SocialMedias.telegram;
        case 6:
            return SocialMedias.discord;
        case 7:
            return SocialMedias.tiktok;
    }
};

export const SocialMediasCampaign = {
    instagram: { name: "Instagram", code: 1, icon: "instagram" },
    twitter: { name: "Twitter", code: 2, icon: "twitter" },
    youtube: { name: "Youtube", code: 3, icon: "youtube" },
    twitch: { name: "Twitch", code: 4, icon: "twitch" },
    telegram: { name: "Telegram", code: 5, icon: "telegram" },
    discord: { name: "Discord", code: 6, icon: "discord" },
    tiktok: { name: "Tiktok", code: 7, icon: "tiktok" },
};

export enum EVENTS {
    TABLE_FILTER = "TABLE_FILTER",
    EXPORT_BUTTON_CLICKED = "EXPORT_BUTTON_CLICKED",
    MODAL_IS_OPENED = "MODAL_IS_OPENED",
    PAYMENT_BALANCE_DETAIL_MODAL_IS_OPENED = "PAYMENT_BALANCE_DETAIL_MODAL_IS_OPENED",
    PAYMENT_ACCOUNT_DETAIL_MODAL_IS_OPENED = "PAYMENT_ACCOUNT_DETAIL_MODAL_IS_OPENED",
    PAYMENT_APPROVEMENT_MODAL_IS_OPENED = "PAYMENT_APPROVEMENT_MODAL_IS_OPENED",
    PAYMENT_MULTIPLE_APPROVEMENT_MODAL_IS_OPENED = "PAYMENT_MULTIPLE_APPROVEMENT_MODAL_IS_OPENED",
    PAYMENT_LIST_MODAL_IS_OPENED = "PAYMENT_LIST_MODAL_IS_OPENED",
    PAYMENT_REFUND_OR_BONUS_MODAL_IS_OPENED = "PAYMENT_REFUND_OR_BONUS_MODAL_IS_OPENED",
    PAYMENT_RESULT_MODAL_IS_OPENED = "PAYMENT_RESULT_MODAL_IS_OPENED",
    INFLUENCER_TRANSACTION_DATE_CHANGED = "INFLUENCER_TRANSACTION_DATE_CHANGED",
    INFLUENCER_TRANSACTION_PLATFORM_CHANGED = "INFLUENCER_TRANSACTION_PLATFORM_CHANGED",
    PLATFORM_HISTORY_MODEL_IS_OPENED = "PLATFORM_HISTORY_MODEL_IS_OPENED",
    PLATFORM_DELETE_MODEL_IS_OPENED = "PLATFORM_DELETE_MODEL_IS_OPENED",
}
