import { useState, useEffect, useRef } from "react";
import { CampaignsService } from "services";
import { AllTransactionsOfCampaignInfluencerResponseItem } from "services/models/AllTransactionsOfCampaignInfluencerResponseItem";
import dayjs from "dayjs";
import { Pagination } from "services/core/Pagination";
import { CampaignInfluencerSocialMediaTransactionSource, EVENTS, PaginationConstant } from "Constanst";
import { AllTransactionsOfCampaignInfluencerRequest } from "services/models/AllTransactionsOfCampaignInfluencerRequest";

export const useTransactions = (id: string) => {
    const [data, setData] = useState<AllTransactionsOfCampaignInfluencerResponseItem[]>([]);
    const [loading, setLoading] = useState(true);
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
        total: 0,
    });

    const filter = useRef<AllTransactionsOfCampaignInfluencerRequest>({
        endDate: dayjs().toString(),
        startDate: dayjs().add(-7, "day").toString(),
        pageNumber: PaginationConstant.initialPage,
        pageSize: -1,
        transactionSource:CampaignInfluencerSocialMediaTransactionSource.Other
    });

    function fetchTransactions(body: AllTransactionsOfCampaignInfluencerRequest) {
        setLoading(true);
        const request = CampaignsService.getAllInfluencerTransactionsOfCampaignInfluencer({ id, body });

        request
            .then(({ data: { data, pageInfo } }) => {
                setData(data as AllTransactionsOfCampaignInfluencerResponseItem[]);
            })
            .finally(() => {
                setLoading(false);
            });
        return request;
    }

    useEffect(() => {
        const request = fetchTransactions(filter.current);

        return () => {
            request.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function dateChangeListener(event: CustomEvent) {
            const { startDate, endDate } = event.detail;

            filter.current = { ...filter.current, startDate, endDate };

            fetchTransactions(filter.current);
        }

        window.addEventListener(EVENTS.INFLUENCER_TRANSACTION_DATE_CHANGED, dateChangeListener as EventListener);

        return () => {
            window.removeEventListener(EVENTS.INFLUENCER_TRANSACTION_DATE_CHANGED, dateChangeListener as EventListener);
        };
    });

    useEffect(() => {
        function platformChangeListener(event: CustomEvent) {
            filter.current = { ...filter.current, platform: event.detail };

            fetchTransactions(filter.current);
        }

        window.addEventListener(EVENTS.INFLUENCER_TRANSACTION_PLATFORM_CHANGED, platformChangeListener as EventListener);

        return () => {
            window.removeEventListener(EVENTS.INFLUENCER_TRANSACTION_PLATFORM_CHANGED, platformChangeListener as EventListener);
        };
    });

    function handleTableChange(page: any) {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });

        filter.current = { ...filter.current, pageNumber: page.current, pageSize: page.pageSize };

        fetchTransactions(filter.current);
    }

    return [data, loading, pagination, handleTableChange] as const;
};
