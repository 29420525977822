/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import { AllTransactionsOfCampaignInfluencerRequest } from "services/models/AllTransactionsOfCampaignInfluencerRequest";
import { BonusesAndRefundsRequest } from "services/models/BonusesAndRefundsRequest";
import { TransactionsSummaryOfCampaignRequest } from "services/models/TransactionsSummaryOfCampaignRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";
import { Fenomio_Common_BaseModels_Api_DataResponse } from "services/models/Fenomio_Common_BaseModels_Api_DataResponse";
import { AllTransactionsOfCampaignInfluencerResponse } from "services/models/AllTransactionsOfCampaignInfluencerResponse";

export class CampaignsService {
    public static searchCampaigns({
        body,
        acceptLanguage,
    }: {
        /** The campaign identifier. **/
        body: any;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `campaigns/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static createCampaignBrands({
        body,
        acceptLanguage,
    }: {
        /** The campaign identifier. **/
        body: any;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `campaigns`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static createCampaign({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: "campaigns",
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static updateCampaign({ id, body, acceptLanguage }: { id: any; body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: "campaigns/" + id,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static applyCampaign({
        campaignId,
        influencerId,
        acceptLanguage,
    }: {
        campaignId: any;
        influencerId: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `campaigns/${campaignId}/influencers/${influencerId}/apply`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCampaignDetail({ campaignId, acceptLanguage }: { campaignId: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `campaigns/${campaignId}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCampaignInfluencersInfoByCampaign({
        campaignId,
        acceptLanguage,
    }: {
        campaignId: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `campaigns/${campaignId}/influencers/info`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCampaignInfluencerById({
        campaignInfluencerId,
        acceptLanguage,
    }: {
        campaignInfluencerId: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `campaigns/influencers/${campaignInfluencerId}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCampaignInfluencersInfoByInfluencer({
        influencerId,
        acceptLanguage,
    }: {
        influencerId: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `campaigns/influencers/${influencerId}/info`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCampaignInfluencers({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `campaigns/influencers/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static getAllCampaignSummary({ acceptLanguage }: { acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `campaigns/actives/summary`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static sendInvite({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `campaigns/influencers/invite`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static acceptCampaignInfluencer({
        campaignInfluencerIds,
        acceptLanguage,
    }: {
        campaignInfluencerIds: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `campaigns/influencers/accept`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: campaignInfluencerIds,
        });
    }

    public static revertCampaignInfluencer({
        campaignInfluencerIds,
        acceptLanguage,
    }: {
        campaignInfluencerIds: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `campaigns/influencers/revert`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: campaignInfluencerIds,
        });
    }

    public static removeCampaignInfluencer({
        campaignInfluencerIds,
        acceptLanguage,
    }: {
        campaignInfluencerIds: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `campaigns/influencers/remove`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: campaignInfluencerIds,
        });
    }

    public static rejectCampaignInfluencer({
        campaignInfluencerIds,
        acceptLanguage,
    }: {
        campaignInfluencerIds: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `campaigns/influencers/reject`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: campaignInfluencerIds,
        });
    }

    public static getCampaignInfluencer({ id, acceptLanguage }: { id: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `campaigns/influencers/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static createCampaignLink({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `campaigns/influencers/links`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static getDailyPerformanceSummary({
        id,
        body,
        acceptLanguage,
    }: {
        id: any;
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: "campaigns/" + id + "/daily-performance-summary",
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static getBonusesAndRefunds({
        id,
        body,
        acceptLanguage,
    }: {
        id: any;
        body: BonusesAndRefundsRequest;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: "campaigns/" + id + "/bonuses-and-refunds",
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static getTransactionsSummaryOfCampaign({
        id,
        body,
        acceptLanguage,
    }: {
        id: any;
        body: TransactionsSummaryOfCampaignRequest;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: "campaigns/" + id + "/transactions-summary-of-campaign",
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static getAllInfluencerTransactionsOfCampaignInfluencer({
        id,
        body,
        acceptLanguage,
    }: {
        id: any;
        body: AllTransactionsOfCampaignInfluencerRequest;
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<AllTransactionsOfCampaignInfluencerResponse>> {
        return __request({
            method: "POST",
            path: "campaigns/influencer/" + id + "/all-transactions-of-campaign",
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static getPerformance({ id, body, acceptLanguage }: { id: any; body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: "campaigns/" + id + "/performance",
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static getCampaignInfluencerPerformance({
        campaignInfluencerId,
        body,
        acceptLanguage,
    }: {
        campaignInfluencerId: any;
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: "campaigns/influencer/" + campaignInfluencerId + "/performance",
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static updateCampaignLink({ id, body, acceptLanguage }: { id: any; body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `campaigns/influencers/links/` + id,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static pauseCampaign({ id, acceptLanguage }: { id: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: "campaigns/" + id + "/pause",
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static CampaignManagementRequest({ acceptLanguage }: { acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: "campaigns/request",
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static acceptCampaignInvite({
        campaignId,
        influencerId,
        acceptLanguage,
    }: {
        campaignId: any;
        influencerId: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `campaigns/${campaignId}/influencers/${influencerId}/accept`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static rejectCampaignInvite({
        campaignId,
        influencerId,
        acceptLanguage,
    }: {
        campaignId: any;
        influencerId: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `campaigns/${campaignId}/influencers/${influencerId}/reject`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static exitCampaign({ campaignId, acceptLanguage }: { campaignId: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `campaign_influencer/exit/${campaignId}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getCampaignInfluencersActive({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `campaigns/influencers/search/active-campaigns`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static getCampaignInfluencersInvited({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `campaigns/influencers/search/invited-campaigns`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static getCampaignInfluencersApplied({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `campaigns/influencers/search/applied-campaigns`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static getCampaignInfluencersPast({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `campaigns/influencers/search/past-campaigns`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static getCampaignInfluencersHomepage({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `campaigns/search/influencer/home-page`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static getCampaignInfluencersCampaignPage({
        body,
        acceptLanguage,
    }: {
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `campaigns/search/influencer/campaigns-page`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static makePayment({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: "campaigns/make-payment",
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }
}
