import { Button, Card, Col, Row, Space, useTranslate } from "@pankod/refine";
import { PageTitle } from "components/PageTitle/PageTitle";
import { Filter, List } from "modules/campaigns";
import TableContext, { TableContextProvider } from "modules/campaigns/list/context";
import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

export const AdminCampaignList: FunctionComponent = () => {
    const t = useTranslate();

    return (
        <TableContextProvider>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Card>
                        <PageTitle title={t("global.campaigns")} content={<>Buraya yardım içeriği gelecek</>} />
                        <br />
                        <Space direction="vertical" size="large" style={{ width: "100%" }}>
                            <Filter />
                            <div>
                                <Row justify="end">
                                    <TableContext.Consumer>
                                        {({ pagination }) => (
                                            <p>
                                                {pagination.total} {t("global.comand")}
                                            </p>
                                        )}
                                    </TableContext.Consumer>
                                </Row>
                                <hr />
                            </div>
                            <Row>
                                <Col style={{ marginLeft: "auto" }}>
                                    <Link to={"/campaign/create"}>
                                        <Button type="ghost">{t("global.create-new-campaign")}</Button>
                                    </Link>
                                </Col>
                            </Row>
                            <List />
                        </Space>
                    </Card>
                </Col>
            </Row>
        </TableContextProvider>
    );
};
