import { localizationHelper } from "helpers/globalizationHelper";
import { useModal } from "hooks";
import { useEffect, useState } from "react";
import { PaymentDetailData } from "services/models/reports";
import { ReportsService } from "services/services/ReportsService";

export const usePaymentAccountDetail = () => {
    const [data, setData] = useState<PaymentDetailData | null>(null);
    const [loading, setLoading] = useState(true);
    const [open, id, handleClose] = useModal<string>();

    useEffect(() => {
        const fetchDetail = async () => {
            setLoading(true);
            ReportsService.getCancelBonusDetail({ id, acceptLanguage: localizationHelper.getLanguage() })
                .then((response) => {
                    setData(response.data);
                })
                .finally(() => {
                    setLoading(false);
                });
        };

        if (id) fetchDetail();

        return () => {
            setData(null);
        };
    }, [id]);

    return [open, data, loading, handleClose] as const;
};
