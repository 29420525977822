import { Space, Button, Row, Col, Form, Input, Table, useTranslate, useNavigation, Select } from "@pankod/refine";
import { CampaignInfluencerStates, PaginationConstant, SocialMedias } from "Constanst";
import { FunctionComponent, useState, useEffect } from "react";
import { Pagination } from "../../../../../../../../services/core/Pagination";
import { CampaignsService } from "../../../../../../../../services";
import { localizationHelper } from "../../../../../../../../helpers/globalizationHelper";
import { InfluencerListType } from "../../../../../../InfluencerListType";

export interface InfluencerListProps {
    listType: InfluencerListType;
    campaignId: string | null;
    updateCount: any;
    categories: any[];
}

export const InfluencerList: FunctionComponent<InfluencerListProps> = (props: InfluencerListProps) => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize
    });
    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState<any[]>([]);
    const [filterValues, setFilterValues] = useState<any>();
    const [selectedInfluencerIds, setSelectedInfluencerIds] = useState<any[]>([]);
    const t = useTranslate();
    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const onSelectInfluencer = (selectedRowKeys: any[]) => {
        setSelectedInfluencerIds(selectedRowKeys);
    };

    const fetchData = () => {
        setDataLoading(true);
        CampaignsService.getCampaignInfluencers({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data?.data);
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount
            });
            setSearch(false);
        });
    };

    const prepareFilterRequest = () => {
        let stateByListTypes: any[] = [];
        let campaignStateByListTypes: any[] = [];
        let includeBoth = false;

        switch (props.listType) {
            case InfluencerListType.active:
                stateByListTypes = [CampaignInfluencerStates.Accepted];
                break;
            case InfluencerListType.applied:
                stateByListTypes = [CampaignInfluencerStates.Applied];
                break;
            case InfluencerListType.listAll:
                // stateByListType = [CampaignInfluencerStates.Applied];
                // campaignStateByListTypes = [CampaignStates.Accepted]
                break;
            case InfluencerListType.invited:
                stateByListTypes = [CampaignInfluencerStates.Invited];
                break;
            case InfluencerListType.rejected:
                stateByListTypes = [CampaignInfluencerStates.Rejected];
                break;
        }

        let body = {
            campaignId: props.campaignId,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            states: stateByListTypes,
            campaignStates: campaignStateByListTypes,
            includeBothStates: includeBoth,
        };
        if (filterValues) {
            if (filterValues.influencerName) {
                // @ts-ignore
                body.influencerName = filterValues.influencerName;
            }
            if (filterValues.categoryId) {
                // @ts-ignore
                body.categoryId = filterValues.categoryId;
            }
        }

        return body;
    };

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total
        });
        setSearch(true);
    };

    const allApprove = () => {
        CampaignsService.acceptCampaignInfluencer({
            campaignInfluencerIds: selectedInfluencerIds,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };

    const allReject = () => {
        CampaignsService.rejectCampaignInfluencer({
            campaignInfluencerIds: selectedInfluencerIds,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };

    const approve = (row: any) => {
        CampaignsService.acceptCampaignInfluencer({
            campaignInfluencerIds: [row.id],
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };

    const reject = (row: any) => {
        CampaignsService.rejectCampaignInfluencer({
            campaignInfluencerIds: [row.id],
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };
    const allRevert = () => {
        CampaignsService.revertCampaignInfluencer({
            campaignInfluencerIds: selectedInfluencerIds,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };
    const allRemove = () => {
        CampaignsService.removeCampaignInfluencer({
            campaignInfluencerIds: selectedInfluencerIds,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };
    /*
    const remove = (row: any) => {
        CampaignsService.removeCampaignInfluencer({
            campaignInfluencerIds: [row.id],
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };

    const revert = (row: any) => {
        CampaignsService.revertCampaignInfluencer({
            campaignInfluencerIds: [row.id],
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };*/

    const { push } = useNavigation();
    const showInfluencer = (influencer: any) => {
        push("/influencers/detail/" + influencer.influencerId);
    };

    const columnsAppliant = [
        {
            title: "Id",
            dataIndex: "influencerId",
            key: "influencerId",
            render: (text: any, row: any) => <p>#{text}</p>,
        },
        {
            title: t("global.namee"),
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    <Space direction="vertical" size="small">
                        {
                            <span
                                className="clickable-span"
                                onClick={() => showInfluencer(row)}
                                style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold" }}
                            >
                                {" "}
                                {row.influencerFirstName} {row.influencerLastName}
                            </span>
                        }
                        {row.socialMediasDto.map((social: any) => (
                            <a
                                href={social.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{ padding: 0, height: 25, fontSize: "small", color: "#9254de" }}
                            >
                                {" "}
                                {"@" + social.username}{" "}
                            </a>
                        ))}
                    </Space>
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.instagram.icon}.svg`} alt="instagram icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.instagram.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.instagram.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.twitter.icon}.svg`} alt="twitter icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.twitter.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.twitter.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.youtube.icon}.svg`} alt="youtube icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.youtube.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.youtube.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.twitch.icon}.svg`} alt="twitch icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.twitch.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.twitch.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.telegram.icon}.svg`} alt="telegram icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.telegram.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.telegram.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.discord.icon}.svg`} alt="discord icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.discord.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.discord.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.tiktok.icon}.svg`} alt="tiktok icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.tiktok.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.tiktok.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: t("global.category"),
            width: 100,
            dataIndex: "category",
            key: "category",
            render: (text: string, row: any, index: number) => <>{row.categories.join(", ")}</>,
        },
    ];

    const columnsActive = [
        {
            title: "Id",
            dataIndex: "influencerId",
            key: "influencerId",
            render: (text: any, row: any) => <p>#{text}</p>,
        },
        {
            title: t("global.namee"),
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    <Space direction="vertical" size="small">
                        {
                            <span
                                className="clickable-span"
                                onClick={() => showInfluencer(row)}
                                style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold" }}
                            >
                                {" "}
                                {row.influencerFirstName} {row.influencerLastName}
                            </span>
                        }
                        {row.socialMediasDto.map((social: any) => (
                            <a
                                href={social.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{ padding: 0, height: 25, fontSize: "small", color: "#9254de" }}
                            >
                                {" "}
                                {"@" + social.username}{" "}
                            </a>
                        ))}
                    </Space>
                </>
            ),
        },
        {
            title: t("global.transformation"),
            dataIndex: "transformation",
            key: "transformation",
            render: (text: string, row: any, index: number) => <>{text}</>,
        },
        {
            title: t("global.joinDate"),
            dataIndex: "startDate",
            key: "startDate",
            render: (text: string, row: any, index: number) => <>{text}</>,
        },
        {
            title: t("posts.fields.category.title"),
            width: 100,
            dataIndex: "category",
            key: "category",
            render: (text: string, row: any, index: number) => <>{row.categories.join(", ")}</>,
        },
    ];

    const columnsWaiting = [
        {
            title: "Id",
            dataIndex: "influencerId",
            key: "influencerId",
            render: (text: any, row: any) => <p>#{text}</p>,
        },
        {
            title: t("global.namee"),
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    <Space direction="vertical" size="small">
                        {
                            <span
                                className="clickable-span"
                                onClick={() => showInfluencer(row)}
                                style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold" }}
                            >
                                {" "}
                                {row.influencerFirstName} {row.influencerLastName}
                            </span>
                        }
                        {row.socialMediasDto.map((social: any) => (
                            <a
                                href={social.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{ padding: 0, height: 25, fontSize: "small", color: "#9254de" }}
                            >
                                {" "}
                                {"@" + social.username}{" "}
                            </a>
                        ))}
                    </Space>
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.instagram.icon}.svg`} alt="instagram icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.instagram.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.instagram.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.twitter.icon}.svg`} alt="twitter icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.twitter.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.twitter.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.youtube.icon}.svg`} alt="youtube icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.youtube.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.youtube.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.twitch.icon}.svg`} alt="twitch icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.twitch.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.twitch.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.telegram.icon}.svg`} alt="telegram icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.telegram.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.telegram.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.discord.icon}.svg`} alt="discord icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.discord.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.discord.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.tiktok.icon}.svg`} alt="tiktok icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.tiktok.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.tiktok.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: t("global.applyDate"),
            width: 100,
            dataIndex: "applyDate",
            key: "applyDate",
            render: (text: string, row: any, index: number) => <span>{text}</span>,
        },
        {
            title: t("global.category"),
            width: 100,
            dataIndex: "category",
            key: "category",
            render: (text: string, row: any, index: number) => <span>{text}</span>,
        },
        {
            title: `${t("global.action", "Aksiyon")}`,
            width: 100,
            dataIndex: "category",
            key: "category",
            render: (text: string, row: any, index: number) => (
                <>
                    <u style={{ cursor: "pointer" }} onClick={() => approve(row)}>
                        <span>{t("global.accept", "Kabul Et")}</span>
                    </u>{" "}
                    <u onClick={() => reject(row)} style={{ cursor: "pointer" }}>
                        <span>{t("global.reject", "Reddet")}</span>
                    </u>
                </>
            ),
        },
    ];

    const columnsRejected = [
        {
            title: "Id",
            dataIndex: "influencerId",
            key: "influencerId",
            render: (text: any, row: any) => <p>#{text}</p>,
        },
        {
            title: t("global.namee"),
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    <Space direction="vertical" size="small">
                        {
                            <span
                                className="clickable-span"
                                onClick={() => showInfluencer(row)}
                                style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold" }}
                            >
                                {" "}
                                {row.influencerFirstName} {row.influencerLastName}
                            </span>
                        }
                        {row.socialMediasDto.map((social: any) => (
                            <a
                                href={social.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{ padding: 0, height: 25, fontSize: "small", color: "#9254de" }}
                            >
                                {" "}
                                {"@" + social.username}{" "}
                            </a>
                        ))}
                    </Space>
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.instagram.icon}.svg`} alt="instagram icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.instagram.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.instagram.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.twitter.icon}.svg`} alt="twitter icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.twitter.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.twitter.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.youtube.icon}.svg`} alt="youtube icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.youtube.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.youtube.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.twitch.icon}.svg`} alt="twitch icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.twitch.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.twitch.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.telegram.icon}.svg`} alt="telegram icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.telegram.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.telegram.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.discord.icon}.svg`} alt="discord icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.discord.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.discord.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: <img src={`/images/social/${SocialMedias.tiktok.icon}.svg`} alt="tiktok icon" />,
            width: 100,
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    {row.socialMediasDto.some((k: { name: string }) => k.name === SocialMedias.tiktok.name) ? (
                        <span>
                            {row.socialMediasDto.find((k: { name: string }) => k.name === SocialMedias.tiktok.name).followers / 1000}K
                        </span>
                    ) : (
                        "-"
                    )}
                </>
            ),
        },
        {
            title: t("global.rejectionDate"),
            width: 100,
            dataIndex: "rejectDate",
            key: "rejectDate",
            render: (text: string, row: any, index: number) => <span>{text?.split("T")[0]}</span>,
        },
    ];

    const [columns, setColumns] = useState(columnsAppliant);

    useEffect(() => {
        switch (props.listType) {
            case InfluencerListType.applied:
                setColumns(columnsWaiting);
                break;
            case InfluencerListType.active:
                setColumns(columnsActive);
                break;
            case InfluencerListType.listAll:
                setColumns(columnsAppliant);
                break;
            case InfluencerListType.rejected:
                setColumns(columnsRejected);
                break;
            case InfluencerListType.invited:
                setColumns(columnsActive);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.listType]);

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={(values) => setFilterValues(values)}
                        autoComplete="off"
                    >
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Row>
                                <Col style={{ marginRight: "5px" }} span={5}>
                                    <Form.Item label={t("global.phenomenon", "Fenomen")} name="influencerName">
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item label={t("global.category")} name="categoryId">
                                        <Select
                                            allowClear
                                            options={props.categories.map((category) => {
                                                return {
                                                    label: category.name,
                                                    value: category.id,
                                                };
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col style={{ marginLeft: "auto", marginTop: "25px" }}>
                                    <Button type="ghost" htmlType={"submit"}>
                                        {t("buttons.filter")}
                                    </Button>
                                </Col>
                            </Row>
                            <hr style={{ border: "1px solid #0000000F" }} />
                            <Space direction="horizontal" style={{ width: "100%", justifyContent: "flex-end" }}>
                                <Button
                                    type="default"
                                    style={{
                                        marginLeft: "auto",
                                        display: props.listType === InfluencerListType.applied ? "block" : "none",
                                    }}
                                    disabled={selectedInfluencerIds.length === 0}
                                    onClick={() => allApprove()}
                                >
                                    {t("global.accept", "Kabul Et")}
                                </Button>
                                <Button
                                    className="ant-btn-danger"
                                    style={{
                                        marginLeft: "auto",
                                        display: props.listType === InfluencerListType.applied ? "block" : "none",
                                    }}
                                    disabled={selectedInfluencerIds.length === 0}
                                    onClick={() => allReject()}
                                >
                                    {t("global.reject", "Reddet")}
                                </Button>

                                <Button
                                    type="default"
                                    style={{ marginLeft: "auto", display: props.listType === InfluencerListType.active ? "block" : "none" }}
                                    onClick={() => allRemove()}
                                >
                                    {t("buttons.campaignLogout")}
                                </Button>
                                <Button
                                    type="default"
                                    style={{
                                        marginLeft: "auto",
                                        display: props.listType === InfluencerListType.invited ? "block" : "none",
                                    }}
                                    onClick={() => allRevert()}
                                >
                                    {t("buttons.withdrawInvitation")}
                                </Button>
                            </Space>
                            <Table
                                rowSelection={
                                    props.listType === InfluencerListType.active || props.listType === InfluencerListType.invited
                                        ? {
                                              preserveSelectedRowKeys: true,
                                              selectedRowKeys: selectedInfluencerIds,
                                              onChange: onSelectInfluencer,
                                          }
                                        : undefined
                                }
                                onChange={(page) => handleTableChange(page)}
                                loading={dataLoading}
                                pagination={pagination}
                                columns={columns}
                                dataSource={data.map((d) => {
                                    d.key = d.id;
                                    return d;
                                })}
                                scroll={{ x: "100%" }}
                            />
                        </Space>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
