import { Button, Col, Form, Input, Popover, Row, Select, Space, Typography, useNavigation, useTranslate } from "@pankod/refine";
import { ShowCampaignExplanation } from "components/atomics/show-items";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { localizationHelper } from "helpers/globalizationHelper";
import { GetCampaignTypes, GetCampaignTypeTextWithManagementType, GetPlatforms } from "helpers/StateHelpers";
import { FunctionComponent, useState } from "react";
import { CampaignsService } from "../../../../../../../services";
import CampaignInfluencerListContainer from "./Components/CampaignInfluencerListContainer";
import { Money } from "services/models/money";
const { Text, Title } = Typography;

interface ActionCampaignDetailProps {
    brands: any[];
    categories: any[];
    countries: any[];
    campaignDetail: any;
    refresh: any;
}

export const ActionCampaignDetail: FunctionComponent<ActionCampaignDetailProps> = (props: ActionCampaignDetailProps) => {
    const [isEditing, setEditing] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const t = useTranslate();

    return (
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <FenBreadCrumb
                items={[
                    { name: t("global.campaigns"), url: "/campaign/list" },
                    { name: props.campaignDetail?.name, url: "" },
                ]}
            />
            <PageContainer pageTitle={props.campaignDetail?.name} helperContent={"Buraya helper içeriği gelecek"}>
                <>
                    {!isEditing && (
                        <DisplayForm
                            refresh={props.refresh}
                            campaignDetail={props.campaignDetail}
                            isEditing={isEditing}
                            isLoading={isLoading}
                            setEditing={setEditing}
                            setLoading={setLoading}
                        />
                    )}
                    {false && (
                        <EditForm
                            refresh={props.refresh}
                            campaignDetail={props.campaignDetail}
                            isEditing={isEditing}
                            isLoading={isLoading}
                            setEditing={setEditing}
                            setLoading={setLoading}
                        />
                    )}
                </>
            </PageContainer>
            <CampaignInfluencerListContainer categories={props.categories} campaignData={props.campaignDetail} />
        </Space>
    );
};

interface DisplayFormProps {
    isEditing: boolean;
    isLoading: boolean;
    setLoading: (loading: boolean) => void;
    setEditing: (editing: boolean) => void;
    campaignDetail: any;
    refresh: any;
}

const DisplayForm: FunctionComponent<DisplayFormProps> = (props: DisplayFormProps) => {
    const { push } = useNavigation();
    const t = useTranslate();

    const showCampaignPerformance = () => {
        push("/campaign/detail/performance/" + props.campaignDetail.id);
    };

    return (
        <>
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>Id</Title>
                        <Text>{props.campaignDetail.id}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>{t("global.campaignLink")}</Title>
                        <Text>{props.campaignDetail.link}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>{t("campaign.create.step2.categoriesLabel")}</Title>
                        <Text>{(props.campaignDetail?.categories as [])?.map((c: any) => "#" + c.name).join(" - ")}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>{t("campaign.create.step3.startDateLabel")}</Title>
                        <Text>{props.campaignDetail.startDate}</Text>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>{t("global.campaignType")}</Title>
                        <Text>
                            {GetCampaignTypeTextWithManagementType(props.campaignDetail?.typeHash, props.campaignDetail?.managementType)}
                        </Text>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>{t("global.platforms", "Platformlar")}</Title>
                        <Text>
                            {GetPlatforms()
                                .filter((sm) => props.campaignDetail.platforms?.includes(sm.value))
                                .map((sm) => sm.label)
                                .join(" - ")}
                        </Text>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>{t("global.end-date")}</Title>
                        <Text>{props.campaignDetail.endDate}</Text>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>{t("global.campaign-name")}</Title>
                        <Text>{props.campaignDetail?.name}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>{t("global.country")}</Title>
                        <Text>{props.campaignDetail.country}</Text>
                    </Col>
                </Row>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <ShowCampaignExplanation description={props.campaignDetail.description} />
                    </Col>
                </Row>
            </Space>
            <hr style={{ border: "1px solid #0000000F" }} />
            <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>{t("campaign.create.step3.totalAmount")}</Title>
                        <Text>{new Money(props.campaignDetail.totalBudget).toStringWithCurrency()}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>{t("campaign.create.step3.minimumInfluencerCountLabel")}</Title>
                        <Text>{props.campaignDetail.minimumInfluencerCount}</Text>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Title level={5}>{t("campaign.create.step3.amountByClickLabel")}</Title>
                        <Text>{new Money(props.campaignDetail.perClickActionInstallAmount).toStringWithCurrency()}</Text>
                    </Col>
                </Row>
                <Row>
                    <Col span={2}>
                        <Button
                            type="primary"
                            onClick={() => {
                                showCampaignPerformance();
                            }}
                            style={{ display: !props.isEditing ? "block" : "none", backgroundColor: "#952AE7" }}
                        >
                            {t("global.performanceView")}
                        </Button>
                    </Col>
                </Row>
            </Space>
        </>
    );
};

interface EditFormProps {
    isEditing: boolean;
    isLoading: boolean;
    setLoading: (loading: boolean) => void;
    setEditing: (editing: boolean) => void;
    campaignDetail: any;
    refresh: any;
}

export const EditForm: FunctionComponent<EditFormProps> = (props: EditFormProps) => {
    const { push } = useNavigation();

    const t = useTranslate();

    const saveEditing = () => {
        props.setLoading(true);
        setTimeout(() => {
            props.setEditing(false);
            props.setLoading(false);
        }, 2000);
    };

    const stopCampaign = () => {
        CampaignsService.pauseCampaign({
            id: props.campaignDetail.id,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            props.refresh();
        });
    };

    const inviteInfluencer = () => {};

    const showCampaignPerformance = () => {
        push("/campaign/detail/performance/" + props.campaignDetail.id);
    };

    return (
        <Form
            layout="vertical"
            // initialValues={{ size: componentSize }}
            // onValuesChange={onFormLayoutChange}
            // size={componentSize as SizeType}
        >
            <Form.Item label={t("global.brand", "Marka")} name="brand" initialValue={props.campaignDetail?.brandName}>
                <Input />
            </Form.Item>
            <Form.Item label={t("global.campaignType")} name="campaignType" initialValue={props.campaignDetail?.campaignType}>
                <Select disabled={!props.isEditing} options={GetCampaignTypes()} />
            </Form.Item>
            <Form.Item label={t("global.campaign-name")} name="campaignName" initialValue={props.campaignDetail?.campaignName}>
                <Input />
            </Form.Item>
            <Form.Item
                label={t("campaign.create.step1.campaignExplanationLabel")}
                name="campaignExplanation"
                initialValue={props.campaignDetail?.campaignExplanation}
            >
                <Input />
            </Form.Item>
            <Form.Item label={t("global.campaignLink")} name="campaignLink" initialValue={props.campaignDetail?.campaignLink}>
                <Input />
            </Form.Item>
            <Form.Item label={t("trackingPlatform")} name="trackingPlatform" initialValue={props.campaignDetail?.trackingPlatform}>
                <Input />
            </Form.Item>
            <Form.Item label={t("global.trackingCode")} name="trackingCode" initialValue={props.campaignDetail?.trackingCode}>
                <Input />
            </Form.Item>
            <Form.Item
                label={t("global.platforms", "Platformlar")}
                name="platforms"
                initialValue={(props.campaignDetail?.platforms as [])?.map((k: any) => k.name).join(", ")}
            >
                <Input />
            </Form.Item>
            <Form.Item label={t("campaign.create.step2.categoriesLabel")} name="categories" initialValue={props.campaignDetail?.categories}>
                <Input />
            </Form.Item>
            <Form.Item
                label={t("campaign.create.step2.whitelistCategoriesLabel")}
                name="whitelistCategories"
                initialValue={props.campaignDetail?.whitelistCategories}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("campaign.create.step2.blacklistCategoriesLabel")}
                name="blacklistCategories"
                initialValue={props.campaignDetail?.blacklistCategories}
            >
                <Input />
            </Form.Item>
            <Form.Item label={t("global.country")} name="country" initialValue={props.campaignDetail?.country}>
                <Input />
            </Form.Item>
            <Form.Item label={t("campaign.create.step3.startDateLabel")} name="startDate" initialValue={props.campaignDetail?.startDate}>
                <Input />
            </Form.Item>
            <Form.Item label={t("global.end-date")} name="endDate" initialValue={props.campaignDetail?.endDate}>
                <Input />
            </Form.Item>
            <Form.Item label={t("campaign.create.step3.totalAmount")} name="totalAmount" initialValue={props.campaignDetail?.totalAmount}>
                <Input />
            </Form.Item>
            <Form.Item
                label={t("campaign.create.step3.influencerTotalAmountLabel")}
                name="influencerTotalAmount"
                initialValue={props.campaignDetail?.influencerTotalAmount}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("campaign.create.step3.minimumInfluencerCountLabel")}
                name="minimumInfluencerCount"
                initialValue={props.campaignDetail?.minimumInfluencerCount}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("campaign.create.step3.amountByClickLabel")}
                name="amountByClick"
                initialValue={props.campaignDetail?.amountByClick}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("campaign.create.step3.amountByClickFenomioEarningLabel")}
                name="amountByClickFenomioEarning"
                initialValue={props.campaignDetail?.amountByClickFenomioEarning}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("campaign.create.step3.amountByClickInfluencerEarningLabel")}
                name="amountByClickInfluencerEarning"
                initialValue={props.campaignDetail?.amountByClickInfluencerEarning}
            >
                <Input />
            </Form.Item>
            <Form.Item
                label={t("campaign.create.step3.amountByInfluencerLabel")}
                name="amountByInfluencer"
                initialValue={props.campaignDetail?.amountByInfluencer}
            >
                <Input />
            </Form.Item>
            <Form.Item>
                <Row>
                    <Col span={2}>
                        <Button
                            type="primary"
                            onClick={() => {
                                showCampaignPerformance();
                            }}
                            style={{ display: !props.isEditing ? "block" : "none" }}
                        >
                            {t("global.performanceView")}
                        </Button>
                    </Col>
                    <Col offset={18} span={2}>
                        <Popover
                            placement="bottomRight"
                            content={
                                <Space direction="vertical" size="small">
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            inviteInfluencer();
                                        }}
                                    >
                                        {t("global.invitePhenomenon")}
                                    </Button>
                                    <Button
                                        type="link"
                                        onClick={() => {
                                            stopCampaign();
                                        }}
                                    >
                                        {t("global.stop")}
                                    </Button>
                                </Space>
                            }
                            trigger="click"
                        >
                            <Button type="primary" style={{ display: !props.isEditing ? "block" : "none" }}>
                                {t("global.other")}
                            </Button>
                        </Popover>
                    </Col>
                    <Col span={2}>
                        <Button
                            type="primary"
                            onClick={() => {
                                props.setEditing(true);
                            }}
                            style={{ display: !props.isEditing ? "block" : "none" }}
                        >
                            {t("buttons.edit")}
                        </Button>
                        <Button
                            type="primary"
                            onClick={() => {
                                saveEditing();
                            }}
                            loading={props.isLoading}
                            style={{ display: props.isEditing ? "block" : "none" }}
                        >
                            {t("buttons.save")}
                        </Button>
                    </Col>
                </Row>
            </Form.Item>
        </Form>
    );
};
