import { useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { CampaignType } from "Constanst";
import { ICampaignData } from "pages/campaign/Model/CampaignList";
import { SocialPlatform } from "pages/profile/models";
import { FunctionComponent, useEffect, useState } from "react";
import { ActionCampaignStep2, ActionCampaignStep3, ActionCampaignStep4, IActionCampaignStep2Data, IActionCampaignStep3Data } from "./Steps";
import { BrandsService, CampaignsService, LookupsService } from "../../../../../services";
import { localizationHelper } from "../../../../../helpers/globalizationHelper";
import { CategoryService } from "../../../../../services/services/CategoryService";
import StepComponent from "components/CreateCampaigns/StepComponent";
import { CampaignStep1, ICampaignStep1Data } from "../../step1";
import CampaignCreateResult from "../../Result";
import moment from "moment";

interface CreateActionCampaignProps {
    back: any;
    isEditPage: any;
    campaignDetail: any;
    campaignId: any;
}

export const CreateActionCampaign: FunctionComponent<CreateActionCampaignProps> = (props: CreateActionCampaignProps) => {
    const t = useTranslate();
    const [currentPage, setCurrentPage] = useState(0);
    const [isLoading, setLoading] = useState(false);
    const [campaignData, setCampaignData] = useState<ICampaignData>({
        campaignType: CampaignType.clicking,
        platforms: [] as SocialPlatform[],
    } as ICampaignData);

    const [brands, setBrands] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);
    const [countries, setCountries] = useState<any>([]);
    const [createdCampaignId, setCreatedCampaignId] = useState(null);

    useEffect(() => {
        getBrands();
        getCategories();
        getCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const createCampaign = () => {
        setLoading(true);

        let requestBody = {
            brandId: campaignData.brandName,
            influencerBrandId: campaignData.influencerBrandName,
            type: CampaignType.action,
            actionDescription: campaignData.actionExplanation,
            managementType: 2,
            name: campaignData.campaignName,
            description: campaignData.campaignExplanation,
            link: campaignData.campaignLink,
            trackingCode: campaignData.trackingCode,
            trackingPlatform: campaignData.trackingPlatform,
            countryCode: campaignData.country,
            categoryIds: campaignData.categories,
            whiteListCategoryIds: campaignData.whitelistCategories,
            blackListCategoryIds: campaignData.blacklistCategories,
            platforms: campaignData.platforms,
            startDate: campaignData.startDate?.format("YYYY-MM-DD"),
            endDate: campaignData.endDate?.format("YYYY-MM-DD"),
            totalBudget: campaignData.totalAmount,
            influencerTotalBudget: campaignData.influencerTotalAmount,
            perClickActionInstallAmount: campaignData.amountByClick,
            perClickActionInstallInfluencerEarning: campaignData.amountByClickInfluencerEarning,
            perInfluencerBudget: campaignData.amountByInfluencer,
            minimumInfluencerCount: campaignData.minimumInfluencerCount,
        };

        if (props.isEditPage) {
            CampaignsService.updateCampaign({
                id: props.campaignId,
                body: {
                    description: requestBody.description,
                    categoryIds: requestBody.categoryIds,
                    whiteListCategoryIds: requestBody.whiteListCategoryIds,
                    blackListCategoryIds: requestBody.blackListCategoryIds,
                    platforms: requestBody.platforms,
                    endDate: requestBody.endDate,
                },
                acceptLanguage: localizationHelper.getLocale(),
            }).then((resp) => {
                setCreatedCampaignId(props.campaignId);
                setLoading(false);
                setCurrentPage(4);
            });
        } else {
            CampaignsService.createCampaign({
                body: requestBody,
                acceptLanguage: localizationHelper.getLocale(),
            }).then((resp) => {
                setCreatedCampaignId(resp.data.Id);
                setLoading(false);
                setCurrentPage(4);
            });
        }
    };

    useEffect(() => {
        if (props.campaignDetail && props.isEditPage) {
            setCampaignData({
                campaignType: CampaignType.action,
                brandName: props.campaignDetail.brandId,
                influencerBrandName: props.campaignDetail.influencerBrandId,
                campaignLink: props.campaignDetail.link,
                campaignExplanation: props.campaignDetail.description,
                campaignName: props.campaignDetail.name,
                country: props.campaignDetail.countryId,
                categories:
                    props.campaignDetail.categories && props.campaignDetail.categories.length > 0
                        ? (props.campaignDetail.categories as any[]).map((c) => c.id)
                        : [],
                whitelistCategories:
                    props.campaignDetail.whitelistCategories && props.campaignDetail.whitelistCategories.length > 0
                        ? (props.campaignDetail.whitelistCategories as any[]).map((c) => c.id)
                        : [],
                blacklistCategories:
                    props.campaignDetail.blacklistCategories && props.campaignDetail.blacklistCategories.length > 0
                        ? (props.campaignDetail.blacklistCategories as any[]).map((c) => c.id)
                        : [],
                platforms:
                    props.campaignDetail.platforms && props.campaignDetail.platforms.length > 0
                        ? (props.campaignDetail.platforms as any[]).map((p) => p)
                        : [],
                startDate: moment(props.campaignDetail.unformattedStartDate),
                endDate: moment(props.campaignDetail.unformattedEndDate),
                totalAmount: props.campaignDetail.totalBudgetDecimal,
                influencerTotalAmount: props.campaignDetail.influencerTotalBudgetDecimal,
                amountByClick: props.campaignDetail.perClickActionInstallAmount,
                minimumInfluencerCount: props.campaignDetail.minimumInfluencerCount,
                trackingPlatform: props.campaignDetail.trackingPlatform,
                trackingCode: props.campaignDetail.trackingCode,
                amountByInfluencer: props.campaignDetail.perInfluencerBudget,
                amountByClickInfluencerEarning: props.campaignDetail.amountByClickInfluencerEarning,
            } as ICampaignData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.campaignDetail]);

    const getCountries = () => {
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
        });
    };

    const getBrands = () => {
        BrandsService.getAllBrands({
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setBrands(resp.data);
        });
    };

    const getCategories = () => {
        CategoryService.getAllCategory({ acceptLanguage: localizationHelper.getLocale() })
            .then((res) => {
                setCategories(res.data);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    return (
        <PageContainer
            pageTitle={
                props.isEditPage
                    ? props.campaignDetail.name + " " + t("campaign.create.updateTitle")
                    : t("campaign.create.action.pageTitle")
            }
            helperContent={t("campaign.create.action.helperContent")}
        >
            <>
                <StepComponent current={currentPage} />
                <br />
                {currentPage === 0 && (
                    <CampaignStep1
                        isEditPage={props.isEditPage}
                        back={props.back}
                        campaignType={CampaignType.action}
                        brands={brands}
                        campaignData={campaignData}
                        formFinished={(data: ICampaignStep1Data) => {
                            setCampaignData({ ...campaignData, ...data });
                            setCurrentPage(currentPage + 1);
                        }}
                    />
                )}
                {currentPage === 1 && (
                    <ActionCampaignStep2
                        back={() => setCurrentPage(0)}
                        countries={countries}
                        isEditPage={props.isEditPage}
                        categories={categories}
                        campaignData={campaignData}
                        formFinished={(data: IActionCampaignStep2Data) => {
                            setCampaignData({ ...campaignData, ...data });
                            setCurrentPage(currentPage + 1);
                        }}
                    />
                )}
                {currentPage === 2 && (
                    <ActionCampaignStep3
                        isEditPage={props.isEditPage}
                        back={() => setCurrentPage(1)}
                        campaignData={campaignData}
                        formFinished={(data: IActionCampaignStep3Data) => {
                            setCampaignData({ ...campaignData, ...data });
                            setCurrentPage(currentPage + 1);
                        }}
                    />
                )}
                {currentPage === 3 && (
                    <ActionCampaignStep4
                        isEditPage={props.isEditPage}
                        back={() => setCurrentPage(2)}
                        brands={brands}
                        categories={categories}
                        countries={countries}
                        loading={isLoading}
                        campaignData={campaignData}
                        formFinished={() => {
                            setCurrentPage(currentPage + 1);
                        }}
                        triggerEdit={(page) => {
                            setCurrentPage(page);
                        }}
                        approved={createCampaign}
                    />
                )}
                {currentPage === 4 && (
                    <CampaignCreateResult
                        isEditPage={props.isEditPage}
                        paymentFail={null}
                        createdCampaignType={CampaignType.action}
                        createdCampaignId={createdCampaignId}
                    />
                )}
            </>
        </PageContainer>
    );
};
