import { useTranslate } from "@pankod/refine";
import { localizationHelper } from "helpers/globalizationHelper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { BrandsService } from "services";
import { AlignType } from "rc-table/lib/interface";
import { Money } from "services/models/money";

export const useReceiptList = () => {
    const t = useTranslate();
    const { brandId } = useParams<{ brandId: string }>();
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        if (brandId) {
            BrandsService.getReceipts({
                id: brandId,
                acceptLanguage: localizationHelper.getLocale(),
            }).then((resp) => {
                setData(resp.data);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [brandId]);

    const columns = [
        {
            title: t("global.referance"),
            dataIndex: "referenceId",
            key: "referencId",
            align: "center" as AlignType,
        },
        {
            title: t("global.date"),
            dataIndex: "date",
            key: "date",
            align: "center" as AlignType,
        },
        {
            title: t("global.price"),
            dataIndex: "price",
            key: "price",
            align: "center" as AlignType,
            render: (num: Number, row:any) => <span style={{whiteSpace:"nowrap"}}>{new Money(num).toStringWithCurrency(row.currency)}</span>
        },
    ];
    return [data, columns] as const;
};
