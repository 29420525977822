import { CloseOutlined } from "@ant-design/icons";
import { useTranslate } from "@pankod/refine";
import { Col, Modal, Row } from "antd";
import { useModal } from "hooks";
import moment from "moment";
import { FC } from "react";
import { Receipt } from "services/models/Receipt";
import { Money } from "services/models/money";

export const ReceiptDetailModal: FC = () => {
    const [open, receipt, handleClose] = useModal<Receipt>();

    console.log({ open, receipt });

    const t = useTranslate();

    return (
        <Modal
            open={open}
            onCancel={handleClose}
            closeIcon={<CloseOutlined />}
            closable
            footer={null}
            destroyOnClose={true}
            width={700}
            centered
        >
            <p style={{ display: "flex", justifyContent: "space-between", marginTop: "20px", fontSize: "large" }}>
                <span style={{ fontWeight: "bold" }}>
                    #{receipt?.id} {t("global.receipt")}
                </span>
                <span>{t("profile.receipt.noFinancialValue", "Mali değeri yoktur")}.</span>
            </p>
            <hr />
            <Row>
                <Col span={24} style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Row>
                        <Col span={8}>
                            <span style={{ fontWeight: "bold" }}> {t("profile.receipt.processDate", "İşlem Tarihi")}:</span>
                        </Col>
                        <Col span={16}>
                            <span>{moment(receipt?.createdOn).format("DD.MM.YYYY")}</span>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Row>
                        <Col span={8}>
                            <span style={{ fontWeight: "bold" }}>{t("profile.receipt.processName", "İşlem Adı")} :</span>
                        </Col>
                        <Col span={16}>
                            <span>{t("profile.receipt.Payment", "Ödeme")}</span>
                        </Col>
                    </Row>
                </Col>
                <Col span={24} style={{ marginTop: "10px", marginBottom: "10px" }}>
                    <Row>
                        <Col span={8}>
                            <span style={{ fontWeight: "bold" }}>{t("profile.receipt.accountInfo", "Hesap Bilgileri")}:</span>
                        </Col>
                        <Col span={16}>
                            <p>
                                {receipt && receipt.hasExceptionLicence ? (
                                    <span>{t("global.bill-exception-licence")}</span>
                                ) : receipt && receipt?.isBillActive ? (
                                    <span>{t("global.invoice")}</span>
                                ) : (
                                    <span>{t("global.cantinvoice")}</span>
                                )}
                            </p>
                            <p>
                                {receipt && receipt?.isBillActive ? (
                                    <span>
                                        {receipt?.companyName} - {t("global.company-type-" + receipt?.companyType.toString())}
                                    </span>
                                ) : (
                                    <div>
                                        <span>
                                            {receipt?.fullName} - {receipt?.identityNumber}
                                        </span>
                                        <br />
                                        <span>{receipt?.address}</span>
                                    </div>
                                )}
                            </p>
                            <p>
                                {receipt && receipt?.isBillActive ? (
                                    <span>
                                        {receipt?.bankName} - {receipt?.iban}
                                    </span>
                                ) : (
                                    <div>
                                        <span>
                                            {receipt?.bankName} - {receipt?.iban}
                                        </span>
                                        <br />
                                    </div>
                                )}
                            </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <hr />
            <Row>
                <Col span={24}>
                    <span style={{ fontWeight: "bold" }}>{t("profile.receipt.totalAmountWithTaxes", "Vergiler Dahil Toplam Tutar")} :</span>{" "}
                    {new Money(receipt?.amount).toStringWithCurrency(receipt?.currency === 1 ? "$" : "₺")}
                </Col>
            </Row>
        </Modal>
    );
};
