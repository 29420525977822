import { RouteProps } from "react-router-dom";
import { ReceiptsPage } from "./receipts";
import { ProfilePage } from "./profile";
import { ChangePasswordPage } from "./changePassword";
import { EditProfilePage } from "./editProfile";
import EditPlatform from "./edit/editPlatform";
import { EditPayment } from "./edit/editPayment";

export const profileRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: ProfilePage,
            path: "/profile/detail",
        },
        {
            exact: true,
            component: EditProfilePage,
            path: "/profile/edit",
        },
        {
            exact: true,
            component: ChangePasswordPage,
            path: "/profile/changePassword",
        },
        {
            exact: true,
            component: ReceiptsPage,
            path: "/profile/receipts",
        },
        {
            exact: true,
            component: EditPlatform,
            path: "/profile/editPlatform",
        },
        {
            exact: true,
            component: EditPayment,
            path: "/profile/editPayment",
        },
    ];
};
