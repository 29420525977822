import { RouteProps } from "react-router-dom";
import { CategoryListPage } from "./CategoryListPage";
import { CategoryDetailPage } from "./CategoryDetailPage";

export const categoryRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: CategoryListPage,
            path: "/category/list",
        },
        {
            exact: true,
            component: CategoryDetailPage,
            path: "/category/detail",
        },
        {
            exact: true,
            component: CategoryDetailPage,
            path: "/category/edit",
        },
        {
            exact: true,
            component: CategoryDetailPage,
            path: "/category/create",
        },
    ];
};
