import { Button, Col, Form, Input, Row, Select, useTranslate } from "@pankod/refine";
import { GetCampaignTypes, GetPlatforms } from "helpers/StateHelpers";
import { useEffect, useState } from "react";
import { localizationHelper } from "../../../helpers/globalizationHelper";
import { LookupsService } from "../../../services";

const MyCampaignsFilterComponent = (data: any) => {
    const [countries, setCountries] = useState<any[]>([]);
    const t = useTranslate();

    useEffect(() => {
        LookupsService.getCountries({
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setCountries(resp?.data as []);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Form
            name="basic"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={async (values) => {
                data.filter(values);
            }}
            autoComplete="off"
            style={{ width: "100%" }}
        >
            <Row justify="center" align="middle">
                <Col xs={24} sm={24} md={6} span={6}>
                    <Form.Item label={t("global.campaign-name")} name="campaignName">
                        <Input
                            style={{
                                width: "95%",
                            }}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Form.Item label={t("global.campaignType")} name="campaignType">
                        <Select
                            style={{
                                width: "95%",
                            }}
                            allowClear
                            options={GetCampaignTypes()}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Form.Item label={t("global.country")} name="country">
                        <Select
                            style={{
                                width: "95%",
                            }}
                            allowClear
                            options={countries?.map((country) => {
                                return {
                                    label: country.text,
                                    value: country.id,
                                };
                            })}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={6} span={6}>
                    <Form.Item label={t("global.platform")} name="socialMediaPlatforms">
                        <Select
                            style={{ width: "95%" }}
                            placeholder={t("global.platforms")}
                            allowClear
                            options={GetPlatforms()}
                            mode="tags"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row justify="end" style={{ padding: "20px 70px" }}>
                <Col>
                    <Button htmlType={"submit"} type="ghost" style={{ float: "right" }}>
                        {t("buttons.filter")}
                    </Button>
                </Col>
            </Row>
            <Row style={{ width: "100%", display: "flex", justifyContent: "flex-start" }}>
                <p>
                    {data.totalCount} {t("global.comand")}
                </p>
            </Row>
        </Form>
    );
};

export default MyCampaignsFilterComponent;
