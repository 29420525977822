import { RouteProps } from "react-router-dom";
import { CampaignList } from "./CampaignList";

export const campaignListRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: CampaignList,
            path: "/campaign/list",
        },
    ];
};
