import { RouteProps } from "react-router-dom";
import { SignAgreementsPage } from "./signAgreements";

export const signAgreementsRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: SignAgreementsPage,
            path: "/sign-agreements"
        },
    ];
};
