import { useState, useEffect } from "react";

type fetchDataRequest = {
    startDate?: string | Date;
    endDate?: string | Date;
    acceptLanguage?: any;
    [key: string]: any;
};

type Props<T> = {
    fetchData: (data: fetchDataRequest) => Promise<T | any>;
    onFilterSuccess?: () => void;
};

export const useStandardReport = function <T>({ fetchData, onFilterSuccess = () => {} }: Props<T>) {
    const [filter, setFilter] = useState<any>({ startDate: "", endDate: "" });
    const [data, setData] = useState<T>();
    const [loading, setLoading] = useState<boolean>(true);

    const onFilter = (values: any) => {
        setFilter(values);
        getReport(values).then(() => {
            onFilterSuccess();
        });
    };

    const getReport = async ({ startDate = "", endDate = "", ...restFilter }) => {
        setLoading(true);
        fetchData({ startDate, endDate, ...restFilter })
            .then((resp) => {
                setData(resp.data);
            })
            .finally(() => setLoading(false));
    };

    useEffect(() => {
        getReport(filter);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [data, onFilter, filter, loading] as const;
};
