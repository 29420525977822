import { localizationHelper } from "helpers/globalizationHelper";
import { useCallback, useMemo, useState } from "react";
import { InfluencersService } from "services/services/InfluencersService";

export const useInfluencerInput = () => {
    const [options, setOptions] = useState([]);
    const [loading, setLoading] = useState(false);

    const resetOptions = useCallback((reset: boolean) => {
        if (reset) {
            setOptions([]);
        }
    }, []);

    const onSearch = useMemo(() => {
        let timer: any = null;
        return (firstName: string) => {
            if (timer) {
                clearTimeout(timer);
            }
            timer = setTimeout(() => {
                setLoading(true);
                resetOptions(true);
                InfluencersService.searchInfluencers({
                    body: {
                        firstName,
                        pageSize: 10,
                    },
                    acceptLanguage: localizationHelper.getLocale(),
                })
                    .then((response) => {
                        setOptions(
                            response.data.data.map((influencer: { id: string; firstName: string; lastName: string }) => ({
                                value: influencer.id,
                                label: `${influencer.firstName} ${influencer.lastName}`,
                            }))
                        );
                    })

                    .finally(() => {
                        setLoading(false);
                    });
            }, 500);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [options, loading, onSearch, resetOptions] as const;
};
