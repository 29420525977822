import { Button, Col, Form, Input, Row, Select, useTranslate } from "@pankod/refine";
import { CampaignType } from "Constanst";
import { GetCampaignTypes, GetPlatforms } from "helpers/StateHelpers";
import { SocialPlatform } from "pages/profile/models";
import { useEffect } from "react";
import { PageProps } from "./PageProps";

const { TextArea } = Input;

export interface ICampaignStep1DataSelfService {
    campaignType: CampaignType | null;
    campaignName: string | null;
    campaignExplanation: string | null;
    platforms: SocialPlatform[] | null;
}

export const FirstPage = (props: PageProps) => {
    const [form] = Form.useForm();
    const t = useTranslate();

    useEffect(() => {
        if (props.campaignData !== null) {
            form.setFieldsValue(props.campaignData);
        }
    }, [props.campaignData, form]);

    return (
        <>
            <Form
                style={{ width: "100%" }}
                form={form}
                layout="vertical"
                name="firstStep"
                requiredMark={false}
                onFinish={(values) => {
                    props.onFinish(values);
                }}
                scrollToFirstError
            >
                <Row>
                    <Col xs={24} sm={24} md={6} span={6} style={{ paddingRight: "10px" }}>
                        <Form.Item label={t("campaign.create.campaignTypeLabel")} name="campaignType" initialValue={CampaignType.clicking}>
                            <Select disabled={true} options={GetCampaignTypes()} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6} style={{ paddingRight: "10px" }}>
                        <Form.Item
                            label={t("campaign.create.step1.campaignNameLabel")}
                            name="campaignName"
                            rules={[
                                { required: true, min: 3, max: 50, type: "string", message: t("campaign.create.step1.campaignNameRule") },
                            ]}
                        >
                            <Input disabled={props.isEditPage} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6} style={{ paddingRight: "10px" }}>
                        <Form.Item
                            label={t("global.platforms", "Platformlar")}
                            name="platforms"
                            rules={[
                                {
                                    required: true,
                                    message: t("campaign.create.minimum-platform"),
                                },
                            ]}
                            initialValue={props.campaignData?.platforms}
                        >
                            <Select mode="multiple" allowClear placeholder={t("global.platform-select")} options={GetPlatforms()} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={6} span={6}>
                        <Form.Item
                            label={t("campaign.create.step1.campaignExplanationLabel")}
                            name="campaignExplanation"
                            rules={[
                                {
                                    required: true,
                                    min: 10,
                                    max: 5000,
                                    type: "string",
                                    message: t("campaign.create.step1.campaignExplanationRule"),
                                },
                            ]}
                            initialValue={props.campaignData?.campaignExplanation}
                        >
                            <TextArea placeholder={t("global.campaign-description-info")} rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ float: "right", display: "flex" }}>
                    <Col>
                        <Button type="default" htmlType="submit" style={{ width: "100px" }} loading={props.isLoading}>
                            {t("buttons.next")}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
};
