import { Authenticated, Button, LayoutWrapper, Row, Table, useNavigation } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { Filtery_Models_FilteryRequest, UsersService } from "../../services";
import { PaginationConstant } from "../../Constanst";
import { localizationHelper } from "../../helpers/globalizationHelper";
import { useEffect, useState } from "react";
import { Pagination } from "../../services/core/Pagination";

export const Users = () => {
    const { push } = useNavigation();
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const getData = () => {
        setDataLoading(true);
        UsersService.searchUsers({
            requestBody: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data.data);
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount
            });
            setSearch(false);
        });
    };

    const prepareFilterRequest = () => {
        const body: Filtery_Models_FilteryRequest = {
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
        };

        return body;
    };
    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total
        });
        setSearch(true);
    };

    const columns = [
        {
            title: <span className="table-font">Oluşturma Tarihi</span>,
            dataIndex: "createdOn",
            key: "createdOn",
        },
        {
            title: <span className="table-font">Ad</span>,
            dataIndex: "firstName",
            key: "firstName",
        },
        {
            title: <span className="table-font">Soyad</span>,
            dataIndex: "lastName",
            key: "lastName",
        },
        {
            title: <span className="table-font">Rol</span>,
            dataIndex: "type",
            key: "type ",
        },
    ];

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageContainer pageTitle={"Kullanıcılar"} helperContent={"Yardım içeriği eklenecek!"}>
                    <Row justify="start">
                        <Button onClick={() => push("/users/add")} type="default" style={{ margin: "10px" }}>
                            Ekle
                        </Button>
                    </Row>
                    <Table
                        columns={columns}
                        pagination={pagination}
                        loading={dataLoading}
                        onChange={(page) => handleTableChange(page)}
                        dataSource={data}
                        scroll={{ x: "100%" }}
                    />
                </PageContainer>
            </LayoutWrapper>
        </Authenticated>
    );
};
