import { Icons, useTranslate } from "@pankod/refine";
import { Button, Checkbox, Form, Input, Modal, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import PasswordChecklist from "react-password-checklist";
import {
    Fenomio_Common_BaseModels_Api_DetailItemDto,
    InfluencersService,
    LookupsService,
} from "services";

import { setNeedsShowMessage } from "pages/auth/authSlice";
import { useAppDispatch } from "hooks";
import { localizationHelper } from "helpers/globalizationHelper";
import { toast } from "react-toastify";
import allCountries from "../../../helpers/Countries";
import { latestAgreementsAtom } from "providers/atoms";
import { useAtom } from "jotai";
import UserAgreement from "components/UserAgreement/UserAgreement";
import { AgreementResponse } from "services/models/AgreementResponse";
export const PhenomenonRegisterPage = () => {
    const [agreements] = useAtom(latestAgreementsAtom);
    const translate = useTranslate();
    const [form] = Form.useForm();
    const { Option } = Select;

    const [isLoading, setLoading] = useState(false);
    const { EyeInvisibleOutlined, EyeTwoTone } = Icons;
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [agreementVisible, setagreementVisible] = useState(false);
    const [activeAgreement, setActiveAgreement] = useState<AgreementResponse|undefined>(undefined);
    const [selectedPhoneCode, setSelectedPhoneCode] = useState("90");
    const [countries, setCountries] = useState<Fenomio_Common_BaseModels_Api_DetailItemDto[]>([]);
    const defaultSelectedCountry = 250;
    const dispatch = useAppDispatch();

    const PrefixSelector = (
        <Select
            value={selectedPhoneCode}
            onChange={(e: any) => {
                setSelectedPhoneCode(e);
            }}
            style={{
                width: 72,
            }}
        >
            {allCountries.map((country) => {
                return <Option value={country.dialCode}>+{country.dialCode}</Option>;
            })}
        </Select>
    );

    useEffect(() => {
        getCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCountries = async () => {
        setLoading(true);
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
            setLoading(false);
        });
    };

    function registerUser(values: any) {
        setLoading(true);
        InfluencersService.signUpInfluencer({
            acceptLanguage: localizationHelper.getLocale(),
            requestBody: {
                email: values.email,
                password: values.password,
                firstName: values.name,
                lastName: values.surname,
                phone: values.phone ? ((selectedPhoneCode ? selectedPhoneCode : "90") + values.phone) : "",
                countryCode: values.countryCode,
                agreements:[{
                    id:service?.id,
                    value:values[service?.id||"service"]?values[service?.id||"service"]:false
                },
                {
                    id:kvkk?.id,
                    value:values[kvkk?.id||"kvkk"]?values[kvkk?.id||"kvkk"]:false
                },
                {
                    id:clarification?.id,
                    value:values[clarification?.id||"clarification"]?values[clarification?.id||"clarification"]:false
                },
                {
                    id:ipaz?.id,
                    value:values[ipaz?.id||"ipaz"]?values[ipaz?.id||"ipaz"]:false
                }]
            },
        })
            .then((res) => {
                setLoading(false);
                if (res.status === "SUCCESS") {
                    dispatch(setNeedsShowMessage(translate("pages.register.successMessage")));
                }
            })
            .catch((err) => {
                setLoading(false);
                toast.error(err.body.message);
            });
    }

    const KvkkPolicyText = ()=>{
        return (
            <span style={{ color: "#952ae7" }}
                onClick={() => {
                    setActiveAgreement(kvkk)
                    setagreementVisible(true);
                }}
            >
                {translate("pages.register.kvkkPolicyLink")}
            </span>
        );
    }

    const KvkkClarificationText = ()=>{
        return (
            <span style={{ color: "#952ae7" }}
                onClick={() => {
                    setActiveAgreement(clarification)
                    setagreementVisible(true);
                }}
            >
                {translate("pages.register.clarificationLink")}
            </span>
        );
    }

    const KvkkWholeLine = () => {
        const fullText = translate("pages.register.kvkkCheckbox",{0:"!0!"});
        const parts = fullText.split("!0!");
        return (
            <span>
                {parts[0]||""}
                <KvkkPolicyText/>
                {parts[1]||""}
            </span>
        )
    }

    const ClarificationWholeLine = () => {
        const fullText = translate("pages.register.clarificationCheckbox",{0:"!0!"});
        const parts = fullText.split("!0!");
        return (
            <span>
                {parts[0]||""}
                <KvkkClarificationText/>
                {parts[1]||""}
            </span>
        )
    }

    const kvkk = useMemo(()=>{
        return agreements?.find(x=>x.agreementType===3 && x.userType === 4);
    },[agreements]);

    const clarification = useMemo(()=>{
        return agreements?.find(x=>x.agreementType===4 && x.userType === 4);
    },[agreements]);

    const service = useMemo(()=>{
        return agreements?.find(x=>x.agreementType===1 && x.userType === 4);
    },[agreements]);

    const ipaz = useMemo(()=>{
        return agreements?.find(x=>x.agreementType===5 && x.userType === 4);
    },[agreements]);

    return (
        <Form
            layout="vertical"
            form={form}
            onFinish={async (values) => {
                registerUser(values);
            }}
            requiredMark={false}
            initialValues={{
                countryCode: defaultSelectedCountry,
            }}
        >
            <style>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance:textfield; /* Firefox */
        }
      `}</style>
            <Input type="hidden" value="phenomenon" />
            <Form.Item
                name="name"
                rules={[
                    {
                        required: true,
                        message: translate("pages.phenomenon.phenomenonNameLength"),
                        min: 2,
                        max: 50,
                    },
                ]}
            >
                <Input size="large" maxLength={30} placeholder={translate("pages.register.registerName", "Ad")} />
            </Form.Item>
            <Form.Item
                name="surname"
                rules={[
                    {
                        required: true,
                        message: translate("pages.phenomenon.phenomenonSurnameLength"),
                        min: 2,
                        max: 50,
                    },
                ]}
            >
                <Input size="large" maxLength={30} placeholder={translate("pages.register.registerSurname", "Soyad")} />
            </Form.Item>
            <Form.Item
                name="email"
                rules={[
                    {
                        type: "email",
                        required: true,
                        message: translate("pages.register.emaill"),
                    },
                ]}
            >
                <Input
                    type="email"
                    size="large"
                    maxLength={40}
                    autoComplete="email"
                    placeholder={translate("pages.login.email", "E Posta")}
                />
            </Form.Item>
            <Form.Item
                name="countryCode"
                rules={[
                    {
                        required: true,
                        message: translate("pages.phenomenon.countrySelect"),
                    },
                ]}
            >
                <Select
                    size="large"
                    onChange={(cty) => {
                        const selectedcty = allCountries.find((item) => item.value === cty);
                        if (selectedcty !== undefined) setSelectedPhoneCode(selectedcty.dialCode.toString());
                    }}
                    options={countries?.map((country) => {
                        return {
                            label: country.text,
                            value: country.value,
                        };
                    })}
                ></Select>
            </Form.Item>
            <Form.Item name="phone">
                <Input
                    onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    maxLength={15}
                    addonBefore={PrefixSelector}
                />
            </Form.Item>
            <Form.Item
                name="password"
                rules={[
                    { required: true, message: translate("pages.register.pleasePassword") },
                    { min: 8, message: translate("pages.register.passwordValidation.minLength", "Şifre en az 8 karakter olmalı") },
                ]}
            >
                <Input.Password
                    type="password"
                    size="large"
                    maxLength={20}
                    placeholder={translate("pages.login.password", "Şifre")}
                    onChange={(e) => setPassword(e.target.value)}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </Form.Item>
            <Form.Item
                name="passwordConfirm"
                rules={[
                    {
                        required: true,
                        message: translate("pages.register.passwordAgain"),
                    },
                    { min: 8, message: translate("pages.register.passwordValidation.minLength", "Şifre en az 8 karakter olmalı") },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                                return Promise.resolve();
                            }

                            return Promise.reject(new Error(translate("pages.register.passwordAgainError")));
                        },
                    }),
                ]}
            >
                <Input.Password
                    type="password"
                    size="large"
                    maxLength={20}
                    placeholder={translate("pages.login.password-again", "Şifre (Tekrar)")}
                    onChange={(e) => setPasswordAgain(e.target.value)}
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </Form.Item>
            <PasswordChecklist
                rules={["minLength", "specialChar", "number", "capital", "match"]}
                minLength={8}
                value={password}
                valueAgain={passwordAgain}
                onChange={(isValid) => {}}
                messages={{
                    minLength: translate("pages.register.minLength", "Şifre en az 8 karakter olmalı"),
                    specialChar: translate("pages.register.specialChar", "Şifre özel karakter içermeli"),
                    number: translate("pages.register.number", "Şifre en az bir rakam içermeli"),
                    capital: translate("pages.register.capital", "Şifre en az bir büyük harf içermeli"),
                    match: translate("pages.register.match", "Şifre tekrarı ile aynı olmalı"),
                    lowercase: translate("pages.register.lowercase", "Şifre en az bir küçük harf içermeli"),
                }}
            />
            <br></br>
            <Form.Item
                name={service?.id||"service"}
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) => {
                            return value ? Promise.resolve() : Promise.reject(new Error("Sözleşmeyi onaylamalısınız"));
                        },
                    },
                ]}
            >
                <Checkbox>
                    <span
                        style={{ color: "#952ae7" }}
                        onClick={() => {
                            setActiveAgreement(service)
                            setagreementVisible(true);
                        }}
                    >
                        {translate("pages.register.iacceptagreement", " Kabul Ediyorum.")}
                    </span>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={kvkk?.id||"kvkk"}
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) => {
                            return value ? Promise.resolve() : Promise.reject(new Error("Sözleşmeyi onaylamalısınız"));
                        },
                    },
                ]}
            >
                <Checkbox>
                    <KvkkWholeLine/>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={clarification?.id||"clarification"}
                valuePropName="checked"
                rules={[
                    {
                        validator: (_, value) => {
                            return value ? Promise.resolve() : Promise.reject(new Error("Sözleşmeyi onaylamalısınız"));
                        },
                    },
                ]}
            >
                <Checkbox>
                    <ClarificationWholeLine/>
                </Checkbox>
            </Form.Item>
            <Form.Item
                name={ipaz?.id||"ipaz"}
                valuePropName="checked"
            >
                <Checkbox>
                    <span
                        style={{ color: "#952ae7" }}
                        onClick={() => {
                            setActiveAgreement(ipaz)
                            setagreementVisible(true);
                        }}
                    >
                        {translate("pages.register.ipaz", " Kabul Ediyorum.")}
                    </span>
                </Checkbox>
            </Form.Item>
            <Button className="ant-btn-block" type="default" size="large" htmlType="submit" loading={isLoading} block>
                {translate("pages.register.registerButton", "Kaydet")}
            </Button>
            <Modal
                visible={agreementVisible}
                okType="default"
                okText={translate("pages.register.agreement.acceptButton", "Okudum, Onaylıyorum")}
                onOk={() => {
                    setagreementVisible(false);
                    const dynamicObj:any = {};
                    dynamicObj[activeAgreement?.id||""] = true;
                    form.setFieldsValue(dynamicObj);
                }}
                cancelButtonProps={{ hidden: true }}
                onCancel={() => {
                    setagreementVisible(false);
                    const dynamicObj:any = {};
                    dynamicObj[activeAgreement?.id||""] = false;
                    form.setFieldsValue(dynamicObj);
                }}
                width={800}
            >
                <UserAgreement body={activeAgreement?.text} title={activeAgreement?.name}/>
            </Modal>
        </Form>
    );
};
