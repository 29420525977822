import { useState } from "react";
import { Space, Button, Typography, useTranslate } from "@pankod/refine";
import { PopModal } from "components/Modal/PopModal";

const { Text, Title, Paragraph } = Typography;

interface ShowCampaignExplanationProps {
    buttonLocation?: "flex-start" | "flex-end" | "center" | undefined;
    description?: string | null | undefined;
}

export const ShowCampaignExplanation = (props: ShowCampaignExplanationProps) => {
    const t = useTranslate();
    const [activeModal, setActiveModal] = useState(0);
    const [copied, setCopied] = useState(false);

    return (
        <>
            <Title level={5}>{t("campaign.create.step1.campaignExplanationLabel")}</Title>
            <Text>{props.description?.truncate(250, true)}</Text>
            <br />
            <Space
                direction="horizontal"
                align="end"
                size={"small"}
                style={{ width: "100%", justifyContent: props.buttonLocation ? props.buttonLocation : "flex-start" }}
            >
                <Button
                    type="link"
                    onClick={() => {
                        setActiveModal(1);
                    }}
                    style={{ marginLeft: 0, marginRight: 0, paddingLeft: 0, paddingRight: 0 }}
                >
                    {t("global.campaign-description-show-popup")}
                </Button>
            </Space>
            <PopModal
                isVisible={activeModal === 1}
                title={t("global.campaign-description")}
                titleLevel={4}
                buttonTextCancel={t("global.giveup")}
                buttonTypeCancel={"primary"}
                onCancel={() => {
                    setActiveModal(0);
                    setCopied(false);
                }}
                buttonTextOK={copied ? t("global.copied") : t("global.copy")}
                buttonTypeOK={"default"}
                onOk={() => {
                    navigator.clipboard.writeText(props.description!);
                    setCopied(true);
                }}
                content={
                    <>
                        <Typography>
                            <Paragraph>
                                <pre className="pre-show-description">{props.description}</pre>
                            </Paragraph>
                        </Typography>
                    </>
                }
            />
        </>
    );
};
