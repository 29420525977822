import { Button, Col, Form, Input, Row, Select, Space, Table, useNavigation, useTranslate } from "@pankod/refine";
import { CampaignInfluencerStates, PaginationConstant } from "Constanst";
import { FunctionComponent, useEffect, useState } from "react";
import { Pagination } from "../../../../../../../../services/core/Pagination";
import { CampaignsService } from "../../../../../../../../services";
import { localizationHelper } from "../../../../../../../../helpers/globalizationHelper";
import { InfluencerListType } from "pages/campaign/InfluencerListType";

export interface InfluencerListProps {
    listType: InfluencerListType;
    campaignId: string | null;
    categories: any[];
    updateCount: any;
}

export const InfluencerList: FunctionComponent<InfluencerListProps> = (props: InfluencerListProps) => {
    const t = useTranslate();
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize
    });
    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState<any>([]);
    const [filterValues, setFilterValues] = useState<any>();
    const [selectedInfluencerIds, setSelectedInfluencerIds] = useState<any[]>([]);

    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const fetchData = () => {
        setDataLoading(true);
        CampaignsService.getCampaignInfluencers({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data?.data);
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount
            });
            setSearch(false);
        });
    };

    const prepareFilterRequest = () => {
        let stateByListTypes: any[] = [];
        let campaignStateByListTypes: any[] = [];
        let includeBoth = false;

        switch (props.listType) {
            case InfluencerListType.active:
                stateByListTypes = [CampaignInfluencerStates.Accepted];
                break;
            case InfluencerListType.applied:
                stateByListTypes = [CampaignInfluencerStates.Applied];
                break;
            case InfluencerListType.listAll:
                // stateByListType = [CampaignInfluencerStates.Applied];
                // campaignStateByListTypes = [CampaignStates.Accepted]
                break;
            case InfluencerListType.invited:
                stateByListTypes = [CampaignInfluencerStates.Invited];
                break;
            case InfluencerListType.rejected:
                stateByListTypes = [CampaignInfluencerStates.Rejected];
                break;
        }

        let body = {
            campaignId: props.campaignId,
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            states: stateByListTypes,
            campaignStates: campaignStateByListTypes,
            includeBothStates: includeBoth,
        };
        if (filterValues) {
            if (filterValues.influencerName) {
                // @ts-ignore
                body.influencerName = filterValues.influencerName;
            }
            if (filterValues.categoryId) {
                // @ts-ignore
                body.categoryId = filterValues.categoryId;
            }
        }

        return body;
    };
    const onSelectInfluencer = (selectedRowKeys: any[]) => {
        setSelectedInfluencerIds(selectedRowKeys);
    };

    const allRevert = () => {
        CampaignsService.revertCampaignInfluencer({
            campaignInfluencerIds: selectedInfluencerIds,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };
    const allRemove = () => {
        CampaignsService.removeCampaignInfluencer({
            campaignInfluencerIds: selectedInfluencerIds,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };
    /*
    const remove = (row: any) => {
        CampaignsService.removeCampaignInfluencer({
            campaignInfluencerIds: [row.id],
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };

    const revert = (row: any) => {
        CampaignsService.revertCampaignInfluencer({
            campaignInfluencerIds: [row.id],
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize
            });
            setSearch(true);
            props.updateCount();
        });
    };*/

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total
        });
        setSearch(true);
    };

    const { push } = useNavigation();
    const showInfluencer = (influencer: any) => {
        push("/influencers/detail/" + influencer.influencerId);
    };

    const columnsActive = [
        {
            title: "Id",
            dataIndex: "influencerId",
            key: "influencerId",
            render: (text: any, row: any) => <p>#{text}</p>,
        },
        {
            title: t("global.namee"),
            dataIndex: "nickname",
            key: "nickname",
            render: (text: string, row: any, index: number) => (
                <>
                    <Space direction="vertical" size="small">
                        {
                            <span
                                className="clickable-span"
                                onClick={() => showInfluencer(row)}
                                style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold" }}
                            >
                                {" "}
                                {row.influencerFirstName} {row.influencerLastName}
                            </span>
                        }
                        {row.socialMediasDto.map((social: any) => (
                            <a
                                href={social.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{ padding: 0, height: 25, fontSize: "small", color: "#9254de" }}
                            >
                                {" "}
                                {"@" + social.username}{" "}
                            </a>
                        ))}
                    </Space>
                </>
            ),
        },
        {
            title: t("global.transformation"),
            dataIndex: "transformation",
            key: "transformation",
            render: (text: string, row: any, index: number) => <>{text}</>,
        },
        {
            title: t("global.joinDate"),
            dataIndex: "startDate",
            key: "startDate",
            render: (text: string, row: any, index: number) => <>{text}</>,
        },
        {
            title: t("posts.fields.category.title"),
            width: 100,
            dataIndex: "category",
            key: "category",
            render: (text: string, row: any, index: number) => <>{row.categories.join(", ")}</>,
        },
    ];

    const [columns, setColumns] = useState(columnsActive);

    useEffect(() => {
        switch (props.listType) {
            case InfluencerListType.active:
                setColumns(columnsActive);
                break;
            case InfluencerListType.rejected:
                setColumns(columnsActive);
                break;
            case InfluencerListType.invited:
                setColumns(columnsActive);
                break;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.listType]);

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={(values) => setFilterValues(values)}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Row>
                                <Col style={{ marginRight: "5px" }} span={5}>
                                    <Form.Item label={t("global.phenomenon", "Fenomen")} name="influencerName">
                                        <Input allowClear />
                                    </Form.Item>
                                </Col>
                                <Col span={5}>
                                    <Form.Item label={t("global.category")} name="categoryId">
                                        <Select
                                            allowClear
                                            options={props.categories.map((category) => {
                                                return {
                                                    label: category.name,
                                                    value: category.id,
                                                };
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col style={{ marginLeft: "auto", marginTop: "25px" }}>
                                    <Button type="ghost" htmlType={"submit"}>
                                        {t("buttons.filter")}
                                    </Button>
                                </Col>
                            </Row>
                            <hr style={{ border: "1px solid #0000000F" }} />
                            <Space direction="horizontal" style={{ width: "100%", justifyContent: "flex-end" }}>
                                <Button
                                    type="default"
                                    style={{ marginLeft: "auto", display: props.listType === InfluencerListType.active ? "block" : "none" }}
                                    onClick={() => allRemove()}
                                >
                                    {t("buttons.campaignLogout")}
                                </Button>
                                <Button
                                    type="default"
                                    style={{
                                        marginLeft: "auto",
                                        display: props.listType === InfluencerListType.invited ? "block" : "none",
                                    }}
                                    onClick={() => allRevert()}
                                >
                                    {t("buttons.withdrawInvitation")}
                                </Button>
                            </Space>
                            <Table
                                rowSelection={
                                    props.listType === InfluencerListType.active || props.listType === InfluencerListType.invited
                                        ? {
                                              preserveSelectedRowKeys: true,
                                              selectedRowKeys: selectedInfluencerIds,
                                              onChange: onSelectInfluencer,
                                          }
                                        : undefined
                                }
                                onChange={(page) => handleTableChange(page)}
                                loading={dataLoading}
                                pagination={pagination}
                                columns={columns}
                                dataSource={data}
                                scroll={{ x: "100%" }}
                            />
                        </Space>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
