import { EVENTS, PaginationConstant } from "Constanst";
import { localizationHelper } from "helpers/globalizationHelper";
import { useQuery } from "hooks";
import { useContext, useEffect, useMemo, useState } from "react";
import { CampaignsService } from "services";
import { Pagination } from "services/core/Pagination";
import TableContext from "./context";

export const useTable = () => {
    const [loading, setLoading] = useState(false);
    const [campaigns, setCampaigns] = useState<any>([]);

    const { pagination, setPagination } = useContext(TableContext);

    const query = useQuery();

    const prepareFilterRequest = (filterValues: any) => {
        const categoryIds = query.get("categoryId");
        const brandIds = query.get("brandId");
        return {
            campaignType: filterValues?.campaignType || 0,
            campaignName: filterValues?.campaignName || null,
            startDate: filterValues?.date?.[0] ? new Date(filterValues.date[0]).toDateString() : null,
            endDate: filterValues?.date?.[1] ? new Date(filterValues.date[1]).toDateString() : null,
            categoryIds: filterValues?.categoryIds ? [...filterValues?.categoryIds] : [],
            brandIds: brandIds ? [brandIds] : [],
            countryIds: categoryIds ? [categoryIds] : filterValues.countryIds || [],
            pageNumber: filterValues.current,
            pageSize: filterValues.pageSize,
            state: filterValues.status || 0,
            campaignOrderState: "DateDesc",
        };
    };

    const getCampaigns = useMemo(() => {
        let filters = {
            campaignOrderState: "DateDesc",
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize,
        };

        return (values = {}) => {
            filters = { ...filters, ...values };

            setLoading(true);
            CampaignsService.searchCampaigns({
                body: prepareFilterRequest(filters),
                acceptLanguage: localizationHelper.getLocale(),
            })
                .then((resp) => {
                    setCampaigns(resp?.data.data);
                    setPagination((prevState: Pagination) => ({
                        current: prevState.current,
                        pageSize: prevState.pageSize,
                        total: resp?.data?.pageInfo.totalItemCount,
                    }));
                })
                .catch((error) => console.error(error))
                .finally(() => {
                    setLoading(false);
                });
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });
        getCampaigns({ current: page.current, pageSize: page.pageSize });
    };

    useEffect(() => {
        getCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const filterEventListener = (event: CustomEvent) => {
            setPagination({
                current: PaginationConstant.initialPage,
                pageSize: PaginationConstant.initialPageSize,
            });
            getCampaigns({ ...event.detail, current: PaginationConstant.initialPage, pageSize: PaginationConstant.initialPageSize });
        };
        window.addEventListener(EVENTS.TABLE_FILTER, filterEventListener as EventListener);

        return () => {
            window.removeEventListener(EVENTS.TABLE_FILTER, filterEventListener as EventListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [loading, campaigns, pagination, handleTableChange] as const;
};
