import { RouteProps } from "react-router-dom";
import CreateCampaign from "./CreateCampaign";

export const campaignCreateRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: CreateCampaign,
            path: "/campaign/create",
        },
        {
            exact: true,
            component: CreateCampaign,
            path: "/campaign/edit/:campaignId",
        },
    ];
};
