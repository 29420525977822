import { Button, useTranslate } from "@pankod/refine";

import { BellFilled } from "@ant-design/icons";
import { NotificationPopup } from "./NotificationPopup";
import UserHelper from "../../helpers/userHelper";
import { useEffect, useState } from "react";
import { NotificationService } from "../../services/services/NotificationService";
import { localizationHelper } from "../../helpers/globalizationHelper";
import ProfileImg from "components/microparts/profile-img";
import { Popover, Dropdown, Avatar, MenuProps } from "antd";
import { useTranslation as usei18nextTranslation } from "react-i18next";

import "./header.scss";

export const Header: React.FC = () => {
    const { i18n } = usei18nextTranslation();
    const currentLocale = i18n.language;
    const changeLocale = (lang: string) => i18n.changeLanguage(lang);
    const menuItems: MenuProps["items"] = [...(i18n.languages || [])].sort().map((lang: string) => ({
        key: lang,
        onClick: () => changeLocale(lang),
        icon: (
            <span style={{ marginRight: 8 }}>
                <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
        ),
        label: lang === "tr" ? "Türkçe" : lang === "es" ? "Español" : "English",
    }));

    const role = UserHelper.getRole();
    const name = UserHelper.getName();
    const t = useTranslate();

    const [count, setCount] = useState<any>();

    useEffect(() => {
        fetchCount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchCount = () => {
        NotificationService.InformationCount({
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((response) => {
                setCount(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <>
            <header className="header">
                <div className="header-inner">
                    <a href="/" className="logo">
                        <img src="/images/logo/logo.png" className="Logo" alt="logo" />
                    </a>

                    <div
                        onClick={() => {
                            fetchCount();
                        }}
                        className="auth-user"
                    >
                        <Popover placement="bottomRight" content={<NotificationPopup fetchCount={fetchCount} />} trigger="click">
                            <div className="notification">
                                <Button>
                                    {count > 0 && <span className="size">{count}</span>}

                                    <BellFilled />
                                </Button>
                            </div>
                        </Popover>
                        <div className="user-info">
                            <ProfileImg alt={t("global.profile")} type={role} negative />

                            {/* <img width={56} height={56} src="https://via.placeholder.com/728x90.png" alt="" /> */}
                            <div>
                                <div className="name"> {name}</div>
                            </div>
                            <Dropdown
                                menu={{
                                    items: menuItems,
                                    selectedKeys: currentLocale ? [currentLocale] : [],
                                }}
                                placement="bottomLeft"
                            >
                                <Button type="link" shape="circle">
                                    <Avatar size={24} src={`/images/flags/${currentLocale}.svg`} />
                                </Button>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};
