import { QuestionCircleOutlined } from "@ant-design/icons";
import { Space, Button, Row, Col, Form, Table, Popover, useTranslate } from "@pankod/refine";
import { CampaignType } from "Constanst";
import { localizationHelper } from "helpers/globalizationHelper";
import { FunctionComponent } from "react";
import { CampaignsService } from "../../../../../services";
import { CampaignListType } from "./AdminInfluencerCampaignList";
import { Link } from "react-router-dom";
import { Money } from "services/models/money";

export interface CampaignListProps {
    data: any[];
    listType: CampaignListType;
    loading: any;
    pagination: any;
    pageChanged: any;
    refresh: any;
}

export const CampaignList: FunctionComponent<CampaignListProps> = (props: CampaignListProps) => {
    const t = useTranslate();

    const remove = (row: any) => {
        CampaignsService.removeCampaignInfluencer({
            campaignInfluencerIds: [row.id],
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            props.refresh();
        });
    };

    const accept = (row: any) => {
        CampaignsService.acceptCampaignInfluencer({
            campaignInfluencerIds: [row.id],
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            props.refresh();
        });
    };

    const revert = (row: any) => {
        CampaignsService.revertCampaignInfluencer({
            campaignInfluencerIds: [row.id],
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            props.refresh();
        });
    };

    const campaignRowTemplate = {
        title: t("global.campaign"),
        dataIndex: "campaignName",
        key: "campaign",
        render: (text: string, row: any, index: number) => (
            <Link to={"/campaign/detail/" + row.campaignId}>
                <span
                    className="clickable-span"
                    style={{ padding: 0, fontSize: "small", marginLeft: "3px", color: "#9254de", fontWeight: "bold" }}
                >
                    {text}
                </span>
            </Link>
        ),
    };
    const brandRowTemplate = {
        title: `${t("global.brand", "Marka")}`,
        dataIndex: "brandName",
        key: "brand",
        render: (text: string, row: any, index: number) => (
            <Link to={"/brands/detail/" + row.brandId}>
                <span
                    className="clickable-span"
                    style={{ padding: 0, fontSize: "small", marginLeft: "3px", color: "#9254de", fontWeight: "bold" }}
                >
                    {text}
                </span>
            </Link>
        ),
    };

    const columnsAppliant = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            render: (text: string, row: any, index: number) => <p style={{ fontSize: "small" }}>{text.substring(24)}</p>,
        },
        campaignRowTemplate,
        brandRowTemplate,
        {
            title: t("global.campaignType"),
            dataIndex: "campaignType",
            key: "campaignType",
            render: (text: string, row: any, index: number) => {
                if (row.campaignType !== CampaignType.selling) {
                    return <span>{text}</span>;
                } else {
                    return (
                        <Popover
                            placement="bottomRight"
                            content={
                                <Space direction="vertical" size="small">
                                    <p>{t("global.actionDescription")}</p>
                                    <p>{t("global.actionDescription")}</p>
                                </Space>
                            }
                        >
                            {text}
                            <QuestionCircleOutlined />
                        </Popover>
                    );
                }
            },
        },
        {
            title: t("global.start"),
            dataIndex: "startDate",
            key: "startDate",
        },
        {
            title: t("global.end"),
            dataIndex: "endDate",
            key: "endDate",
        },
        {
            title: t("global.unit-price"),
            dataIndex: "unitPrice",
            key: "unitPrice",
            render: (unitPrice: number, row: any, index: number) => {
                if (row.typeHash !== CampaignType.selling) {
                    return <span>{unitPrice ? new Money(row.unitPrice).toStringWithCurrency(row.currency === 1 ? "$" : "₺") : "-"}</span>;
                }

                return (
                    <Popover
                        placement="bottomRight"
                        content={
                            <Space direction="vertical" size="small">
                                <p>{t("global.earnings-rates")}</p>
                                <p>{t("global.earnings-rates")}</p>
                            </Space>
                        }
                    >
                        <Button type="link" onClick={() => {}}>
                            <span>
                                {t("global.earnings-rates")} <QuestionCircleOutlined />
                            </span>
                        </Button>
                    </Popover>
                );
            },
        },
        {
            title: t("campaign.create.step3.totalAmount"),
            dataIndex: "balancePerInfluencer",
            key: "balancePerInfluencer",
            render: (balancePerInfluencer: number, row: any) => (
                <span>
                    {balancePerInfluencer ? new Money(row.balancePerInfluencer).toStringWithCurrency(row.currency === 1 ? "$" : "₺") : "-"}
                </span>
            ),
        },
        {
            title: t("global.category"),
            dataIndex: "category",
            key: "category",
            render: (text: string, row: any, index: number) => {
                if (row.categories && row.categories.length > 0) {
                    return <p style={{ fontSize: "small" }}>#{row.categories[0]}</p>;
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            title: `${t("global.action", "Aksiyon")}`,
            key: "action",
            with: 100,
            render: (text: string, row: any, index: number) => (
                <Space direction="vertical" size="small">
                    <p>
                        <span
                            className="clickable-span"
                            style={{ padding: 0, fontSize: "small", marginRight: "3px", color: "#9254de", fontWeight: "bold" }}
                            onClick={() => {
                                accept(row);
                            }}
                        >
                            {t("buttons.accept")}
                        </span>
                        |
                        <span
                            className="clickable-span"
                            style={{ padding: 0, fontSize: "small", marginLeft: "3px", color: "#9254de", fontWeight: "bold" }}
                            onClick={() => {
                                revert(row);
                            }}
                        >
                            {t("global.reject")}
                        </span>
                    </p>
                </Space>
            ),
        },
    ];

    const columnsActive = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            render: (text: string, row: any, index: number) => <p style={{ fontSize: "small" }}>{text.substring(24)}</p>,
        },
        campaignRowTemplate,
        brandRowTemplate,
        {
            title: t("global.campaignType"),
            dataIndex: "campaignType",
            key: "campaignType",
            render: (text: string, row: any, index: number) => {
                if (row.campaignType !== CampaignType.selling) {
                    return <span>{text}</span>;
                } else {
                    return (
                        <Popover
                            placement="bottomRight"
                            content={
                                <Space direction="vertical" size="small">
                                    <p>{t("global.actionDescription")}</p>
                                    <p>{t("global.actionDescription")}</p>
                                </Space>
                            }
                        >
                            {text}
                            <QuestionCircleOutlined />
                        </Popover>
                    );
                }
            },
        },
        {
            title: t("global.start"),
            dataIndex: "startDate",
            key: "startDate",
        },
        {
            title: t("global.end"),
            dataIndex: "endDate",
            key: "endDate",
        },
        {
            title: t("global.unit-price"),
            dataIndex: "unitPrice",
            key: "unitPrice",
            render: (unitPrice: number, row: any, index: number) => {
                if (row.typeHash !== CampaignType.selling) {
                    return <span>{unitPrice ? new Money(row.unitPrice).toStringWithCurrency(row.currency === 1 ? "$" : "₺") : "-"}</span>;
                }

                return (
                    <Popover
                        placement="bottomRight"
                        content={
                            <Space direction="vertical" size="small">
                                <p>{t("global.earnings-rates")}</p>
                                <p>{t("global.earnings-rates")}</p>
                            </Space>
                        }
                    >
                        <Button type="link" onClick={() => {}}>
                            <span>
                                {t("global.earnings-rates")} <QuestionCircleOutlined />
                            </span>
                        </Button>
                    </Popover>
                );
            },
        },
        {
            title: t("campaign.create.step3.totalAmount"),
            dataIndex: "balancePerInfluencer",
            key: "balancePerInfluencer",
            render: (balancePerInfluencer: number, row: any) => (
                <span>
                    {balancePerInfluencer ? new Money(row.balancePerInfluencer).toStringWithCurrency(row.currency === 1 ? "$" : "₺") : "-"}
                </span>
            ),
        },
        {
            title: t("global.transformation"),
            dataIndex: "transformation",
            key: "transformation",
        },
        {
            title: t("global.category"),
            dataIndex: "category",
            key: "category",
            render: (text: string, row: any, index: number) => {
                if (row.categories && row.categories.length > 0) {
                    return <p style={{ fontSize: "small" }}>#{row.categories[0]}</p>;
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            title: `${t("global.action", "Aksiyon")}`,
            key: "action",
            with: 100,
            render: (text: string, row: any, index: number) => (
                <Space direction="vertical" size="small">
                    <p>
                        <span
                            className="clickable-span"
                            style={{ padding: 0, fontSize: "small", marginRight: "3px", color: "red", fontWeight: "bold" }}
                            onClick={() => {
                                remove(row);
                            }}
                        >
                            Çıkar
                        </span>
                        |
                        <Link to={"/campaign-influencer/detail/" + row.id}>
                            <span
                                className="clickable-span"
                                style={{ padding: 0, fontSize: "small", marginLeft: "3px", color: "#9254de", fontWeight: "bold" }}
                            >
                                {t("global.performanceView")}
                            </span>
                        </Link>
                    </p>
                </Space>
            ),
        },
    ];

    const columnsWaiting = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            render: (text: string, row: any, index: number) => <p style={{ fontSize: "small" }}>{text.substring(24)}</p>,
        },
        campaignRowTemplate,
        brandRowTemplate,
        {
            title: t("global.campaignType"),
            dataIndex: "campaignType",
            key: "campaignType",
            render: (text: string, row: any, index: number) => {
                if (row.campaignType !== CampaignType.selling) {
                    return <span>{text}</span>;
                } else {
                    return (
                        <Popover
                            placement="bottomRight"
                            content={
                                <Space direction="vertical" size="small">
                                    <p>{t("global.actionDescription")}</p>
                                    <p>{t("global.actionDescription")}</p>
                                </Space>
                            }
                        >
                            {text}
                            <QuestionCircleOutlined />
                        </Popover>
                    );
                }
            },
        },
        {
            title: t("global.start"),
            dataIndex: "startDate",
            key: "startDate",
        },
        {
            title: t("global.end"),
            dataIndex: "endDate",
            key: "endDate",
        },
        {
            title: t("global.unit-price"),
            dataIndex: "unitPrice",
            key: "unitPrice",
            render: (unitPrice: number, row: any, index: number) => {
                if (row.typeHash !== CampaignType.selling) {
                    return <span>{unitPrice ? new Money(row.unitPrice).toStringWithCurrency(row.currency === 1 ? "$" : "₺") : "-"}</span>;
                }

                return (
                    <Popover
                        placement="bottomRight"
                        content={
                            <Space direction="vertical" size="small">
                                <p>{t("global.earnings-rates")}</p>
                                <p>{t("global.earnings-rates")}</p>
                            </Space>
                        }
                    >
                        <Button type="link" onClick={() => {}}>
                            <span>
                                {t("global.earnings-rates")} <QuestionCircleOutlined />
                            </span>
                        </Button>
                    </Popover>
                );
            },
        },
        {
            title: t("campaign.create.step3.totalAmount"),
            dataIndex: "balancePerInfluencer",
            key: "balancePerInfluencer",
            render: (balancePerInfluencer: number, row: any) => (
                <span>
                    {balancePerInfluencer ? new Money(row.balancePerInfluencer).toStringWithCurrency(row.currency === 1 ? "$" : "₺") : "-"}
                </span>
            ),
        },
        {
            title: t("global.transformation"),
            dataIndex: "transformation",
            key: "transformation",
        },
        {
            title: t("global.category"),
            dataIndex: "category",
            key: "category",
            render: (text: string, row: any, index: number) => {
                if (row.categories && row.categories.length > 0) {
                    return <p style={{ fontSize: "small" }}>#{row.categories[0]}</p>;
                } else {
                    return <p>-</p>;
                }
            },
        },
    ];

    const columnsRejected = [
        {
            title: "Id",
            dataIndex: "id",
            key: "id",
            render: (text: string, row: any, index: number) => <p style={{ fontSize: "small" }}>{text.substring(24)}</p>,
        },
        campaignRowTemplate,
        brandRowTemplate,
        {
            title: t("global.campaignType"),
            dataIndex: "campaignType",
            key: "campaignType",
            render: (text: string, row: any, index: number) => {
                if (row.campaignType !== CampaignType.selling) {
                    return <span>{text}</span>;
                } else {
                    return (
                        <Popover
                            placement="bottomRight"
                            content={
                                <Space direction="vertical" size="small">
                                    <p>{t("global.actionDescription")}</p>
                                    <p>{t("global.actionDescription")}</p>
                                </Space>
                            }
                        >
                            {text}
                            <QuestionCircleOutlined />
                        </Popover>
                    );
                }
            },
        },
        {
            title: t("global.start"),
            dataIndex: "startDate",
            key: "startDate",
        },
        {
            title: t("global.end"),
            dataIndex: "endDate",
            key: "endDate",
        },
        {
            title: t("global.unit-price"),
            dataIndex: "unitPrice",
            key: "unitPrice",
            render: (unitPrice: number, row: any, index: number) => {
                if (row.typeHash !== CampaignType.selling) {
                    return <span>{unitPrice ? new Money(row.unitPrice).toStringWithCurrency(row.currency === 1 ? "$" : "₺") : "-"}</span>;
                }

                return (
                    <Popover
                        placement="bottomRight"
                        content={
                            <Space direction="vertical" size="small">
                                <p>{t("global.earnings-rates")}</p>
                                <p>{t("global.earnings-rates")}</p>
                            </Space>
                        }
                    >
                        <Button type="link" onClick={() => {}}>
                            <span>
                                {t("global.earnings-rates")} <QuestionCircleOutlined />
                            </span>
                        </Button>
                    </Popover>
                );
            },
        },
        {
            title: t("campaign.create.step3.totalAmount"),
            dataIndex: "balancePerInfluencer",
            key: "balancePerInfluencer",
            render: (balancePerInfluencer: number, row: any) => (
                <span>
                    {balancePerInfluencer ? new Money(row.balancePerInfluencer).toStringWithCurrency(row.currency === 1 ? "$" : "₺") : "-"}
                </span>
            ),
        },
        {
            title: t("global.transformation"),
            dataIndex: "transformation",
            key: "transformation",
        },
        {
            title: t("global.category"),
            dataIndex: "category",
            key: "category",
            render: (text: string, row: any, index: number) => {
                if (row.categories && row.categories.length > 0) {
                    return <p style={{ fontSize: "small" }}>#{row.categories[0]}</p>;
                } else {
                    return <p>-</p>;
                }
            },
        },
        {
            title: `${t("global.action", "Aksiyon")}`,
            key: "action",
            with: 100,
            render: (text: string, row: any, index: number) => (
                <Space direction="vertical" size="small">
                    <p>
                        <Link to={"/campaign-influencer/detail/" + row.id}>
                            <span
                                className="clickable-span"
                                style={{ padding: 0, fontSize: "small", marginLeft: "3px", color: "#9254de", fontWeight: "bold" }}
                            >
                                {t("global.performanceView")}
                            </span>
                        </Link>
                    </p>
                </Space>
            ),
        },
    ];

    const getTable = () => {
        switch (props.listType) {
            case CampaignListType.active:
                return (
                    <Table
                        pagination={props.pagination}
                        loading={props.loading}
                        columns={columnsActive}
                        dataSource={props.data}
                        onChange={(page) => props.pageChanged(page)}
                        scroll={{ x: "100%" }}
                    />
                );
            case CampaignListType.invited:
                return (
                    <Table
                        pagination={props.pagination}
                        loading={props.loading}
                        columns={columnsWaiting}
                        dataSource={props.data}
                        onChange={(page) => props.pageChanged(page)}
                        scroll={{ x: "100%" }}
                    />
                );
            case CampaignListType.applied:
                return (
                    <Table
                        pagination={props.pagination}
                        loading={props.loading}
                        columns={columnsAppliant}
                        dataSource={props.data}
                        onChange={(page) => props.pageChanged(page)}
                        scroll={{ x: "100%" }}
                    />
                );
            case CampaignListType.rejected:
                return (
                    <Table
                        pagination={props.pagination}
                        loading={props.loading}
                        columns={columnsRejected}
                        dataSource={props.data}
                        onChange={(page) => props.pageChanged(page)}
                        scroll={{ x: "100%" }}
                    />
                );
            case CampaignListType.past:
                return (
                    <Table
                        pagination={props.pagination}
                        loading={props.loading}
                        columns={columnsRejected}
                        dataSource={props.data}
                        onChange={(page) => props.pageChanged(page)}
                        scroll={{ x: "100%" }}
                    />
                );
        }
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form name="basic" layout="inline" initialValues={{ remember: true }} autoComplete="off">
                        <Space direction="vertical" style={{ width: "100%" }}>
                            {getTable()}
                        </Space>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
