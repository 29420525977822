import { Table } from "antd";
import { PaymentActions } from "components";
import { FC } from "react";
import { useColumns } from "./useColumns";
import { useList } from "./useList";

export const List: FC = () => {
    const [
        loading,
        payments,
        pagination,
        handleTableChange,
        selectedInfluencerList,
        setSelectedInfluencerList,
        downloadInfluencerPaymentReport,
    ] = useList();
    // Table columns
    const columns = useColumns();

    return (
        <>
            <PaymentActions
                selectedInfluencerList={selectedInfluencerList}
                handleExport={downloadInfluencerPaymentReport}
                payments={payments}
            />
            <Table
                columns={columns}
                rowSelection={{
                    preserveSelectedRowKeys: true,
                    //selectedRowKeys: (selectedInfluencerList as any[]).map((i) => i.id),
                    onChange: (value: any[]) => {
                        setSelectedInfluencerList(value);
                    },
                }}
                pagination={pagination}
                loading={loading}
                onChange={handleTableChange}
                dataSource={payments}
                rowKey={"id"}
                scroll={{ x: "100%" }}
            />
        </>
    );
};
