import { Button, Col, Row, Space, Typography, useNavigation, useTranslate } from "@pankod/refine";
import { ShowCampaignExplanation } from "components/atomics/show-items";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { CampaignStates } from "Constanst";
import { localizationHelper } from "helpers/globalizationHelper";
import { GetCampaignTypeTextWithManagementType, GetPlatforms, GetTrackingPlatformText } from "helpers/StateHelpers";
import { FunctionComponent } from "react";
import { CampaignsService } from "../../../../../../../services";
import CampaignInfluencerListContainer from "./Components/CampaignInfluencerListContainer";
import { Link } from "react-router-dom";
const { Text, Title } = Typography;

interface AmazonCampaignDetailProps {
    brands: any[];
    categories: any[];
    countries: any[];
    campaignDetail: any;
    refresh: any;
}

export const AmazonCampaignDetail: FunctionComponent<AmazonCampaignDetailProps> = (props: AmazonCampaignDetailProps) => {
    const { push } = useNavigation();
    const t = useTranslate();

    const stopCampaign = () => {
        CampaignsService.pauseCampaign({
            id: props.campaignDetail.id,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            props.refresh();
        });
    };

    return (
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <FenBreadCrumb
                items={[
                    { name: t("global.campaigns"), url: "/campaign/list" },
                    { name: props.campaignDetail?.name, url: "" },
                ]}
            />
            <PageContainer pageTitle={props.campaignDetail?.name} helperContent={"Buraya helper içeriği gelecek"}>
                <>
                    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>Id</Title>
                                <Text>{props.campaignDetail.id}</Text>
                            </Col>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("global.campaignLink")}</Title>
                                <Text>{props.campaignDetail.link}</Text>
                            </Col>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("campaign.create.step2.categoriesLabel")}</Title>
                                <Text>{(props.campaignDetail?.categories as [])?.map((c: any) => "#" + c.name).join(" - ")}</Text>
                            </Col>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("campaign.create.step3.startDateLabel")}</Title>
                                <Text>{props.campaignDetail.startDate}</Text>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("global.campaignType")}</Title>
                                <Text>
                                    {GetCampaignTypeTextWithManagementType(
                                        props.campaignDetail?.typeHash,
                                        props.campaignDetail?.managementType
                                    )}
                                </Text>
                            </Col>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("global.trackingPlatform")}</Title>
                                <Text>{GetTrackingPlatformText(props.campaignDetail.trackingPlatform)}</Text>
                            </Col>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("campaign.create.step2.whitelistCategoriesLabel")}</Title>
                                <Text>
                                    {props.campaignDetail?.whitelistCategories && props.campaignDetail?.whitelistCategories.length > 0
                                        ? props.campaignDetail.whitelistCategories.map((ct: any) => ct.name).join(",")
                                        : ""}
                                </Text>
                            </Col>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("global.end-date")}</Title>
                                <Text>{" - "}</Text>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("global.campaignName")}</Title>
                                <Text>{props.campaignDetail?.name}</Text>
                            </Col>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("campaign.create.step1.trackingCodeLabel")}</Title>
                                <Text>{props.campaignDetail?.trackingCode}</Text>
                            </Col>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("campaign.create.step2.blacklistCategoriesLabel")}</Title>
                                <Text>
                                    {props.campaignDetail?.blacklistCategories && props.campaignDetail?.blacklistCategories.length > 0
                                        ? props.campaignDetail.blacklistCategories.map((ct: any) => ct.name).join(",")
                                        : ""}
                                </Text>
                            </Col>
                        </Row>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <ShowCampaignExplanation description={props.campaignDetail.description} />
                            </Col>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("global.platforms", "Platformlar")}</Title>
                                <Text>
                                    {GetPlatforms()
                                        .filter((sm) => props.campaignDetail.platforms?.includes(sm.value))
                                        .map((sm) => sm.label)
                                        .join(" - ")}
                                </Text>
                            </Col>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("pages.brand.country")}</Title>
                                <Text>{props.campaignDetail.country}</Text>
                            </Col>
                        </Row>
                    </Space>
                    <hr style={{ border: "1px solid #0000000F", margin: "25px 0" }} />
                    <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("global.commissionRates")}</Title>
                                {props.campaignDetail.commissionRates.map((item:string)=>{
                                    var itemGroup = item.split('%');

                                    return <div>{itemGroup[0]+': %'+itemGroup[1]}</div>
                                })}
                            </Col>
                            <Col xs={24} sm={24} md={6} span={6}>
                                <Title level={5}>{t("global.bonusInformation")}</Title>
                                <Text>{props.campaignDetail.bonusDescription}</Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col style={{ marginTop: "5px" }} xs={24} md={12} span={6}>
                                <Link to={"/campaign/detail/performance/" + props.campaignDetail.id}>
                                    <Button
                                        type="default"
                                    >
                                        {t("global.performanceView")}
                                    </Button>
                                </Link>
                            </Col>
                            <Col xs={24} md={12}>
                                <Row style={{ display: "flex", float: "right" }}>
                                    {props.campaignDetail.campaignState === CampaignStates.Accepted && (
                                        <Col style={{ marginTop: "5px", display: "flex", justifyContent: "end" }} xs={24} md={12}>
                                            <Button
                                                disabled={props.campaignDetail?.campaignState !== CampaignStates.Accepted}
                                                className="ant-btn-danger"
                                                onClick={() => {
                                                    stopCampaign();
                                                }}
                                            >
                                                {t("global.stop")}
                                            </Button>
                                        </Col>
                                    )}
                                    <Col
                                        style={{ marginTop: "5px", display: "flex", justifyContent: "end", paddingLeft: "20px" }}
                                        xs={24}
                                        md={12}
                                    >
                                        <Button
                                            type="default"
                                            onClick={() => {
                                                push("/campaign/edit/" + props.campaignDetail.id);
                                            }}
                                        >
                                            {t("buttons.edit")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Space>
                </>
            </PageContainer>
            <CampaignInfluencerListContainer categories={props.categories} data={props.campaignDetail} />
        </Space>
    );
};
