import { useEffect, useState } from "react";
import { InfluencersService, WalletBalance } from "services";

export const useInfluencerBalance = (influencerId: string) => {
    const [wallet, setWallet] = useState<WalletBalance>({ balance: 0, balanceOnMonth: 0, totalBalance: 0, currency: "" });
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        const asyncResponse = InfluencersService.getWalletBalance(influencerId);

        setLoading(true);
        asyncResponse
            .then((response) => {
                setWallet(response.data);
                return;
            })
            .finally(() => {
                setLoading(false);
            });

        return () => {
            asyncResponse.cancel();
        };
    }, [influencerId]);

    return [wallet, loading] as const;
};
