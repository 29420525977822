import { RouteProps } from "react-router-dom";
import AdminCreate from "./components/adminCreate";
import AuditLogs from "./auditLogs";
import { Users } from "./users";
import { Sectors } from "./sectors";

export const managementPanelRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: Users,
            path: "/users/list",
        },
        {
            exact: true,
            component: AdminCreate,
            path: "/users/add",
        },
        {
            exact: true,
            component: AuditLogs,
            path: "/logs/list",
        },
        {
            exact: true,
            component: Sectors,
            path: "/sectors/list",
        },
    ];
};
