import { Funnel } from "@ant-design/plots";
import { Col, LayoutWrapper, Row, Space, useTranslate } from "@pankod/refine";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { RefundAndBonusTable } from "components/tables/refund-and-bonus-table";
import dayjs from "dayjs";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { ICampaignData } from "pages/campaign/Model/CampaignList";
import { RangeValue } from "rc-picker/lib/interface";
import { FunctionComponent, useEffect, useState } from "react";
import { BonusesAndRefundsResponse } from "services/models/BonusesAndRefundsResponse";
import { DailyCampaignPerformanceSummaryResponse } from "services/models/DailyCampaignPerformanceSummaryResponse";
import { TransactionsSummaryOfCampaignResponse } from "services/models/TransactionsSummaryOfCampaignResponse";
import { Filter } from "../Filter";
import PerformanceInfluencerListContainer from "../PerformanceInfluencerListContainer";
import { AdminChart } from "./AdminChart";
import { BrandChart } from "./BrandChart";

interface ClickCampaignPerformanceProps {
    data: ICampaignData;
    dailyPerformanceSummary: DailyCampaignPerformanceSummaryResponse;
    changePlatform: any;
    changeDate: any;
    changeName: (value: string) => void;
    bonusesAndRefunds?: BonusesAndRefundsResponse;
    bonusPaginationChange?: (pageNumber: number) => void;
    transactionsSummary: TransactionsSummaryOfCampaignResponse;
    transactionsSummaryPaginationChange: (pageNumber: number) => void;
}

export const DownloadCampaignPerformance: FunctionComponent<ClickCampaignPerformanceProps> = (props: ClickCampaignPerformanceProps) => {
    const [funnel, setFunnel] = useState<any>();
    const t = useTranslate();
    const data = props.data;
    const currency = data.currency || "₺";

    useEffect(() => {
        if (props.dailyPerformanceSummary) {
            let totalClicks = props.dailyPerformanceSummary?.totalClicks;
            let totalActions = props.dailyPerformanceSummary?.totalConversions;

            const funnelData = [
                {
                    stage: t("campaign.create.step3.totalclick"),
                    number: totalClicks,
                },
                {
                    stage: t("campaign.create.step3.totaldownload"),
                    number: totalActions,
                },
            ];

            const conf = {
                data: funnelData,
                xField: "stage",
                yField: "number",
                minSize: 0.4,
            };
            setFunnel(<Funnel color={["#E4CAF9", "#9254de"]} {...conf} />);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dailyPerformanceSummary]);

    return (
        <LayoutWrapper>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
                <FenBreadCrumb
                    items={[
                        { name: t("global.campaigns"), url: "/campaign/list" },
                        { name: data.name ?? "", url: "/campaign/detail/" + data.id, attachData: data },
                        { name: t("global.performancee"), url: "" },
                    ]}
                />
                <PerformanceChart
                    currency={currency}
                    changeDate={props.changeDate}
                    changePlatform={props.changePlatform}
                    changeName={props.changeName}
                    dailyPerformanceSummary={props.dailyPerformanceSummary}
                    data={props.data}
                    bonusesAndRefunds={props.bonusesAndRefunds}
                    bonusPaginationChange={props.bonusPaginationChange}
                    transactionsSummary={props.transactionsSummary}
                    transactionsSummaryPaginationChange={props.transactionsSummaryPaginationChange}
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <PageContainer pageTitle={data.name ?? ""} helperContent={"Buraya helper içeriği gelecek"}>
                            <Space direction="vertical" size="small" style={{ width: "100%" }}>
                                {funnel}
                            </Space>
                        </PageContainer>
                    </Col>
                </Row>
            </Space>
        </LayoutWrapper>
    );
};

interface PerformanceChartProps {
    dailyPerformanceSummary: DailyCampaignPerformanceSummaryResponse;
    changePlatform: any;
    changeDate: any;
    changeName: (value: string) => void;
    currency: any;
    data: any;
    bonusesAndRefunds?: BonusesAndRefundsResponse;
    bonusPaginationChange?: (pageNumber: number) => void;
    transactionsSummary: TransactionsSummaryOfCampaignResponse;
    transactionsSummaryPaginationChange: (pageNumber: number) => void;
}

const PerformanceChart: FunctionComponent<PerformanceChartProps> = (props: PerformanceChartProps) => {
    const [selectedDates, setSelectedDates] = useState<RangeValue<dayjs.Dayjs>>();
    const t = useTranslate();

    const changeDate = (e: any) => {
        props.changeDate(e);
        var endDate = e !== null ? e[1] : dayjs();
        var startDate = e !== null ? e[0] : dayjs().add(-7, "day");
        setSelectedDates([startDate, endDate]);
    };

    const isBrand = RoleHelper.isInRole(Roles.Brand);

    useEffect(() => {
        const startDate = dayjs().add(-7, "day");
        const endDate = dayjs();
        setSelectedDates([startDate, endDate]);
        //eslint-disabled-next-line
    }, []);

    return (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
            <PageContainer pageTitle={t("global.performancee")} helperContent={"Buraya helper içeriği gelecek"}>
                <Filter
                    changeDate={changeDate}
                    changeName={props.changeName}
                    changePlatform={props.changePlatform}
                    selectedDates={selectedDates}
                />
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        {" "}
                        {isBrand ? (
                            <BrandChart currency={props.currency} items={props.dailyPerformanceSummary.items} />
                        ) : (
                            <AdminChart currency={props.currency} items={props.dailyPerformanceSummary.items} />
                        )}
                    </Col>
                </Row>
            </PageContainer>
            <RefundAndBonusTable
                bonusesAndRefunds={props.bonusesAndRefunds}
                pageChange={props.bonusPaginationChange}
                containerTitle={t("global.refundsAndBonuses")}
                helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                isAllInfluencers={true}
            />
            <PerformanceInfluencerListContainer
                currency={props.currency}
                transactionsSummary={props.transactionsSummary}
                transactionsSummaryPaginationChange={props.transactionsSummaryPaginationChange}
                data={props.data}
            />
        </Space>
    );
};
