import { Button, Space, useNavigation } from "@pankod/refine";
import { FunctionComponent, useEffect, useState } from "react";
import { NotificationService } from "../../services/services/NotificationService";
import { localizationHelper } from "../../helpers/globalizationHelper";
import { Pagination } from "../../services/core/Pagination";
import { PaginationConstant } from "../../Constanst";
import { Row } from "antd";
import moment from "moment";

interface NotificationPopupProps {
    fetchCount: any;
}

export const NotificationPopup: FunctionComponent<NotificationPopupProps> = (props: any) => {
    const { push } = useNavigation();

    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: 3,
        total: 0,
    });
    const [filterValues] = useState<any>();
    const [, setDataLoading] = useState(false);
    const [, setload] = useState<boolean>();
    const [notificationData, setNotificationData] = useState<any>([]);

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = () => {
        setDataLoading(true);
        NotificationService.searchNotifications({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setNotificationData(resp?.data);
            setPagination({
                current: resp?.pageInfo?.pageNumber,
                pageSize: resp?.pageInfo?.pageSize,
                total: resp?.pageInfo?.totalItemCount
            });
            props.fetchCount();
            setload(true);
        });
    };

    const prepareFilterRequest = () => {
        let body = {
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
            read: false,
        };
        if (filterValues) {
            if (filterValues.read) {
                // @ts-ignore
                body.read = filterValues.read;
            }
        }

        return body;
    };

    const templateControl = (template: string, row: any) => {
        let convertedTemplate = template.replace(
            "{campaign}",
            `<a class="table-font" href=${"/tr/campaign/detail/" + row.campaignId}> ${row.campaign?.name}</a>`
        );
        convertedTemplate = convertedTemplate.replace(
            "{influencer}",
            `<a class="table-font" href=${"/tr/influencers/detail/" + row.influencerId} > ${
                row.influencer?.firstName + " " + row.influencer?.lastName
            }</a>`
        );
        convertedTemplate = convertedTemplate.replace(
            "{brand}",
            `<a class="table-font" href=${"/tr/brands/detail/" + row.brandId} > ${row.brand?.name} </a>`
        );
        convertedTemplate = convertedTemplate.replace("{socialMedia}", row.socialMediaDescription ? row.socialMediaDescription : "");
        if (row.customParams) {
            for (var i: number = 0; i < row.customParams.length; i++) {
                convertedTemplate = convertedTemplate.replace(`{${i}}`, row.customParams[i]);
            }
        }
        return { __html: convertedTemplate };
    };

    return (
        <div style={{ width: 360 }}>
            <Space direction="vertical" size={"small"} style={{ width: "100%" }}>
                {notificationData.map((item: any) => {
                    return (
                        <div style={{ height: 95, width: "100%" }}>
                            <Space direction="horizontal" size={"small"} style={{ width: "100%" }}>
                                <span style={{ color: "grey" }}>
                                    {moment.utc(item.createdOn, "DD/MM/YYYY HH:mm").local().format("YYYY-MM-DD HH:mm")}
                                </span>
                            </Space>
                            <Space direction="horizontal" size={"small"}>
                                <span
                                    style={{ fontSize: 13, fontWeight: 700 }}
                                    dangerouslySetInnerHTML={templateControl(item.notificationSetting.template, item)}
                                ></span>
                            </Space>
                            <hr style={{ border: "1px solid #952AE7" }} />
                        </div>
                    );
                })}
                <Row>
                    <Button
                        style={{ marginLeft: 107 }}
                        type="default"
                        onClick={() => {
                            push("/notifications/list");
                        }}
                    >
                        Tamamını Gör
                    </Button>
                </Row>
            </Space>
        </div>
    );
};

export interface INotification {
    template: string;
    createdOn: string;
}
