// @flow
import * as React from "react";
import { Col, Popover, Row } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

type Props = {
    title: string;
    content: React.ReactNode;
};

export class PageTitle extends React.Component<Props> {
    render() {
        return (
            <>
                <Row gutter={[8, 8]} className="ant-card-header">
                    <Col span={23}>{this.props.title}</Col>
                    <Col span={1}>
                        <Popover content={this.props.content} placement="bottomRight" title={this.props.title} trigger="hover">
                            <InfoCircleOutlined />
                        </Popover>
                    </Col>
                </Row>
            </>
        );
    }
}
