import { Funnel } from "@ant-design/plots";
import { Col, Form, LayoutWrapper, Row, Space, Table, useNavigation, useTranslate } from "@pankod/refine";
import { CampaignInfluencerSocialMediaTransactionSource, PaginationConstant, Platforms } from "Constanst";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { RefundAndBonusTable } from "components/tables/refund-and-bonus-table";
import dayjs from "dayjs";
import { localizationHelper } from "helpers/globalizationHelper";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { ICampaignData } from "pages/campaign/Model/CampaignList";
import { RangeValue } from "rc-picker/lib/interface";
import { FunctionComponent, useEffect, useState } from "react";
import { CampaignsService } from "services";
import { AllTransactionsOfCampaignInfluencerResponse } from "services/models/AllTransactionsOfCampaignInfluencerResponse";
import { BaseIdNameResponse } from "services/models/BaseIdNameResponse";
import { BonusesAndRefundsResponse } from "services/models/BonusesAndRefundsResponse";
import { DailyCampaignPerformanceSummaryResponse } from "services/models/DailyCampaignPerformanceSummaryResponse";
import { TransactionsSummaryOfCampaignResponse } from "services/models/TransactionsSummaryOfCampaignResponse";
import { TransactionsSummaryOfCampaignResponseItem } from "services/models/TransactionsSummaryOfCampaignResponseItem";
import { Pagination } from "../../../../../../../services/core/Pagination";
import { Filter } from "../Filter";
import { AdminChart } from "./AdminChart";
import { BrandChart } from "./BrandChart";
import { Money } from "services/models/money";

interface AmazonCampaignPerformanceProps {
    data: ICampaignData;
    dailyPerformanceSummary: DailyCampaignPerformanceSummaryResponse;
    bonusesAndRefunds?: BonusesAndRefundsResponse;
    bonusPaginationChange?: (pageNumber: number) => void;
    transactionsSummary?: TransactionsSummaryOfCampaignResponse;
    transactionsSummaryPaginationChange?: (pageNumber: number) => void;
    changePlatform: any;
    changeDate: any;
    changeName: (value: string) => void;
}

export const AmazonCampaignPerformance: FunctionComponent<AmazonCampaignPerformanceProps> = (props: AmazonCampaignPerformanceProps) => {
    const data = props.data;
    const t = useTranslate();
    const currency = data.currency || "₺";
    const [funnel, setFunnel] = useState<any>();

    useEffect(() => {
        if (props.dailyPerformanceSummary) {
            let totalClicks = props.dailyPerformanceSummary?.totalClicks;
            let totalActions = props.dailyPerformanceSummary?.totalConversions;

            const funnelData = [
                {
                    stage: t("campaign.create.step3.totalclick"),
                    number: totalClicks,
                },
                {
                    stage: t("campaign.create.step3.totalsell"),
                    number: totalActions,
                },
            ];

            const conf = {
                data: funnelData,
                xField: "stage",
                yField: "number",
                minSize: 0.4,
            };
            setFunnel(<Funnel color={["#E4CAF9", "#9254de"]} {...conf} />);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.dailyPerformanceSummary]);

    return (
        <LayoutWrapper>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
                <FenBreadCrumb
                    items={[
                        { name: t("global.campaigns"), url: "/campaign/list" },
                        { name: data.name ?? "", url: "/campaign/detail/" + data.id, attachData: data },
                        { name: t("global.performancee"), url: "" },
                    ]}
                />
                <PerformanceChart
                    dailyPerformanceSummary={props.dailyPerformanceSummary}
                    currency={currency}
                    changeDate={props.changeDate}
                    changePlatform={props.changePlatform}
                    changeName={props.changeName}
                    data={props.data}
                    bonusesAndRefunds={props.bonusesAndRefunds}
                    bonusPaginationChange={props.bonusPaginationChange}
                    transactionsSummary={props.transactionsSummary}
                    transactionsSummaryPaginationChange={props.transactionsSummaryPaginationChange}
                />
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={24} md={12} span={12}>
                        <PageContainer pageTitle={t("campaign.create.step3.totalearnings")} helperContent={"Buraya helper içeriği gelecek"}>
                            <Space direction="vertical" size="small" style={{ width: "100%" }}>
                                {funnel}
                            </Space>
                        </PageContainer>
                    </Col>
                </Row>
            </Space>
        </LayoutWrapper>
    );
};

interface PerformanceChartProps {
    dailyPerformanceSummary: DailyCampaignPerformanceSummaryResponse;
    changePlatform: any;
    changeDate: any;
    changeName: (value: string) => void;
    currency: any;
    data: any;
    bonusesAndRefunds?: BonusesAndRefundsResponse;
    bonusPaginationChange?: (pageNumber: number) => void;
    transactionsSummary?: TransactionsSummaryOfCampaignResponse;
    transactionsSummaryPaginationChange?: (pageNumber: number) => void;
}

const PerformanceChart: FunctionComponent<PerformanceChartProps> = (props: PerformanceChartProps) => {
    const [selectedDates, setSelectedDates] = useState<RangeValue<dayjs.Dayjs>>();
    const [selectedPlatform, setSelectedPlatform] = useState<Platforms>();

    const t = useTranslate();

    const handleTableChange = (page: any) => {
        props.transactionsSummaryPaginationChange?.(page);
    };

    const changeDate = (e: any) => {
        props.changeDate(e);
        var endDate = e !== null ? e[1] : dayjs();
        var startDate = e !== null ? e[0] : dayjs().add(-7, "day");
        setSelectedDates([startDate, endDate]);
    };
    const changePlatform = (e: any) => {
        props.changePlatform(e);
        setSelectedPlatform(e as Platforms);
    };

    const isBrand = RoleHelper.isInRole(Roles.Brand);

    useEffect(() => {
        const startDate = dayjs().add(-7, "day");
        const endDate = dayjs();
        setSelectedDates([startDate, endDate]);
        //eslint-disabled-next-line
    }, []);
    return (
        <PageContainer pageTitle={t("global.performancee")} helperContent={"Buraya helper içeriği gelecek"}>
            <Filter
                hasPlatformFilter={false}
                changeDate={changeDate}
                changePlatform={changePlatform}
                changeName={props.changeName}
                selectedDates={selectedDates}
            />
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    {" "}
                    {isBrand ? (
                        <BrandChart currency={props.currency} items={props.dailyPerformanceSummary.items} />
                    ) : (
                        <AdminChart currency={props.currency} items={props.dailyPerformanceSummary.items} />
                    )}
                </Col>
            </Row>
            <RefundAndBonusTable
                bonusesAndRefunds={props.bonusesAndRefunds}
                pageChange={props.bonusPaginationChange}
                containerTitle={t("global.refundsAndBonuses")}
                helperContent="Buradan kampanyalarım hakkında bilgi görüntüleyebilirsiniz."
                isAllInfluencers={true}
            />
            <TransactionsSummary
                pageChange={handleTableChange}
                data={props.transactionsSummary}
                selectedDates={selectedDates}
                platform={selectedPlatform}
            />
        </PageContainer>
    );
};

interface TransactionsSummaryProps {
    data?: TransactionsSummaryOfCampaignResponse;
    pageChange: (page: number) => void;
    selectedDates?: RangeValue<dayjs.Dayjs>;
    platform?: Platforms;
}

export const TransactionsSummary: FunctionComponent<TransactionsSummaryProps> = (props: TransactionsSummaryProps) => {
    const t = useTranslate();
    const { push } = useNavigation();
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });
    const [expandedRow, setExpandedRow] = useState<Array<string>>([]);
    const [transactionsResponse, setTransactionsResponse] = useState<AllTransactionsOfCampaignInfluencerResponse>();
    const [transactionResponseWaiting, setTransactionsResponseWaiting] = useState(false);
    useEffect(() => {
        setPagination({
            current: props.data?.pageInfo?.pageNumber || 1,
            pageSize: props.data?.pageInfo?.pageSize || 1,
            total: props.data?.pageInfo?.totalItemCount,
        });
    }, [props.data]);
    const showInfluencer = (id: string) => {
        push("/influencers/detail/" + id);
    };

    const columns = [
        {
            title: <>{t("global.phenomenon", "Fenomen")}</>,
            dataIndex: "influencer",
            key: "influencer",
            render: (influencer: BaseIdNameResponse, row: TransactionsSummaryOfCampaignResponseItem, index: number) => (
                <>
                    <Space direction="vertical" size="small">
                        {
                            <span
                                className="clickable-span"
                                onClick={() => showInfluencer(influencer.id)}
                                style={{ padding: 0, borderWidth: 0, color: "#9254de", fontWeight: "bold" }}
                            >
                                {" "}
                                {influencer.name}
                            </span>
                        }
                        {row.influencerSocialMediaNames?.map((social: any) => (
                            <a
                                href={social.link}
                                target="_blank"
                                rel="noreferrer"
                                style={{ padding: 0, fontSize: "small", color: "#9254de" }}
                            >
                                <img
                                    width={16}
                                    height={16}
                                    alt={"social"}
                                    src={`/images/social-media-icon/${social.socialMedia.toUpperCase()}36Px.svg`}
                                />{" "}
                                {"@" + social.username}{" "}
                            </a>
                        ))}
                    </Space>
                </>
            ),
        },
        {
            title: <>{t("global.clickCount")}</>,
            dataIndex: "clicks",
            key: "clicks",
        },
        {
            title: <>{t("global.saleCount")}</>,
            dataIndex: "count",
            key: "count",
        },
        {
            title: <>{t("global.refundCount")}</>,
            dataIndex: "refundCount",
            key: "count",
        },
        {
            title: <>{t("global.fenomioComission")}</>,
            dataIndex: "payoutFenomio",
            key: "payoutFenomio",
            render:(val:Number, row:any) => <>{new Money(val).toStringWithCurrency(row?.currency)}</>
        },
        {
            title: <>{t("global.influencerComission")}</>,
            dataIndex: "payout",
            key: "payout",
            render:(val:Number, row:any) => <>{new Money(val).toStringWithCurrency(row?.currency)}</>
        },
        {
            title: <>{t("global.totalComission")}</>,
            dataIndex: "payoutTotal",
            key: "payoutTotal",
            render:(val:Number, row:any) => <>{new Money(val).toStringWithCurrency(row?.currency)}</>
        },
    ];

    type InfluencerSellingDetailsProps = {
        loading: boolean;
        details: {
            responseItem?: TransactionsSummaryOfCampaignResponseItem;
        };
    };

    const InfluencerSellingDetails = (props: InfluencerSellingDetailsProps) => {
        const columns = [
            { title: t("global.productName"), dataIndex: "productName", key: "productName" },
            { title: t("global.saleCount"), dataIndex: "count", key: "saleCount" },
            { title: t("global.refundCount"), dataIndex: "refundCount", key: "refundCount" },
            {
                title: t("global.fenomioComission"),
                dataIndex: "payoutFenomio",
                key: "payoutFenomio",
                render:(val:Number, row:any) => <>{new Money(val).toStringWithCurrency(row?.currency)}</>
            },
            {
                title: t("global.influencerComission"),
                dataIndex: "payout",
                key: "payout",
                render:(val:Number, row:any) => <>{new Money(val).toStringWithCurrency(row?.currency)}</>
            },
            {
                title: t("global.totalComission"),
                dataIndex: "payoutTotal",
                key: "payoutTotal",
                render:(val:Number, row:any) => <>{new Money(val).toStringWithCurrency(row?.currency)}</>
            },
        ];

        return (
            <Table
                rowKey={(record) => record.transactionId}
                key={props.details?.responseItem?.campaignInfluencerId}
                loading={props.loading}
                columns={columns}
                dataSource={transactionsResponse?.data}
                pagination={false}
            />
        );
    };

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form name="basic" layout="inline" autoComplete="off">
                        <Space direction="vertical" style={{ width: "100%", marginTop: 20, borderRadius: 15 }}>
                            <Table
                                rowKey={(record) => record.campaignInfluencerId}
                                pagination={pagination}
                                onChange={(page) => props.pageChange(page.current || 1)}
                                columns={columns}
                                expandable={{
                                    expandedRowRender: (record, index) => (
                                        <InfluencerSellingDetails
                                            key={index}
                                            details={{
                                                responseItem: record,
                                            }}
                                            loading={transactionResponseWaiting}
                                        />
                                    ),
                                    defaultExpandAllRows: false,
                                    expandedRowKeys: expandedRow,
                                    onExpand(expanded, record) {
                                        setExpandedRow(expanded ? [record.campaignInfluencerId] : []);
                                        if (!expanded) return;
                                        setTransactionsResponseWaiting(true);
                                        CampaignsService.getAllInfluencerTransactionsOfCampaignInfluencer({
                                            id: record.campaignInfluencerId,
                                            acceptLanguage: localizationHelper.getLocale(),
                                            body: {
                                                platform: props.platform,
                                                pageNumber: PaginationConstant.initialPage,
                                                pageSize: -1, // for getting all items.
                                                transactionSource: CampaignInfluencerSocialMediaTransactionSource.Other,
                                                startDate: props.selectedDates?.[0]?.toString(),
                                                endDate: props.selectedDates?.[1]?.toString(),
                                            },
                                        })
                                            .then((resp) => {
                                                setTransactionsResponseWaiting(false);
                                                setTransactionsResponse(resp.data);
                                            })
                                            .catch(() => {
                                                setTransactionsResponseWaiting(false);
                                            });
                                    },
                                }}
                                dataSource={props.data?.data}
                                scroll={{ x: "100%" }}
                            />
                        </Space>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
