import { LayoutWrapper } from "@pankod/refine";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { FunctionComponent, useEffect, useState } from "react";
import { ICampaignData } from "../Model/CampaignList";
import { AdminCampaignDetail, BrandCampaignDetail, InfluencerCampaignDetail } from "./CampaignDetails";
import { BrandsService, CampaignsService, LookupsService } from "../../../services";
import { localizationHelper } from "../../../helpers/globalizationHelper";
import { CategoryService } from "../../../services/services/CategoryService";
import UserHelper from "../../../helpers/userHelper";
import { CampaignType } from "../../../Constanst";
import { GetCampaignStateForInfluencer } from "helpers/StateHelpers";

interface CampaignDetailProps {
    location: {
        state: ICampaignData;
    };
    match: any;
}

export const CampaignDetail: FunctionComponent<CampaignDetailProps> = (props: CampaignDetailProps) => {
    const [campaignDetail, setCampaignDetail] = useState<any>();
    const [brands, setBrands] = useState<any>([]);
    const [categories, setCategories] = useState<any>([]);
    const [countries, setCountries] = useState<any>([]);
    const [detailLoading, setDetailLoading] = useState(true);
    const [brandLoading, setBrandLoading] = useState(true);
    const [categoryLoading, setCategoryLoading] = useState(true);
    const [countryLoading, setCountryLoading] = useState(true);
    const [campaignId] = useState(props?.location?.state?.id ?? props?.match?.params?.campaignId);
    const [showCampaignState, setShowCampaignState] = useState<string>("");

    useEffect(() => {
        if (UserHelper.getRole() === Roles.Admin || UserHelper.getRole() === Roles.Root) {
            getBrands();
            setBrandLoading(false);
        }
        getCategories();
        getCountries();
        getCampaignDetail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCountries = () => {
        setCountryLoading(true);
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
            setCountryLoading(false);
        });
    };

    const getCampaignDetail = () => {
        setDetailLoading(true);
        CampaignsService.getCampaignDetail({
            campaignId: campaignId,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setCampaignDetail(resp.data);
            const getCampaignState = GetCampaignStateForInfluencer(resp.data.campaignState, resp.data.campaignInfluencerState);
            setShowCampaignState(getCampaignState);

            setDetailLoading(false);
        });
    };

    const getBrands = () => {
        setBrandLoading(true);
        BrandsService.getAllBrands({
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setBrands(resp.data);
            setBrandLoading(false);
        });
    };

    const getCategories = () => {
        setCategoryLoading(true);
        CategoryService.getAllCategory({ acceptLanguage: localizationHelper.getLocale() })
            .then((res) => {
                setCategories(res.data);
                setCategoryLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const refresh = () => {
        getCampaignDetail();
    };

    const campaignTypes = () => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        return campaignDetail?.typeHash as CampaignType;
    };

    return (
        <LayoutWrapper>
            {(RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root)) && (
                <AdminCampaignDetail
                    loading={detailLoading || brandLoading || categoryLoading || countryLoading}
                    refresh={refresh}
                    brands={brands}
                    countries={countries}
                    campaignDetail={campaignDetail}
                    categories={categories}
                    campaignType={campaignTypes()}
                />
            )}
            {!detailLoading && !categoryLoading && !countryLoading && RoleHelper.isInRole(Roles.Brand) && (
                <BrandCampaignDetail
                    loading={detailLoading || categoryLoading || countryLoading}
                    refresh={refresh}
                    brands={brands}
                    countries={countries}
                    campaignDetail={campaignDetail}
                    categories={categories}
                    campaignType={campaignTypes()}
                />
            )}
            {!detailLoading && !categoryLoading && !countryLoading && RoleHelper.isInRole(Roles.Influencer) && (
                <InfluencerCampaignDetail
                    loading={detailLoading || categoryLoading || countryLoading}
                    refresh={refresh}
                    campaignType={campaignTypes()}
                    campaignDetail={campaignDetail}
                    showCampaignState={showCampaignState}
                />
            )}
        </LayoutWrapper>
    );
};
