import { useTranslate, Row, Col, Form, Space, Input, Select, Button } from "@pankod/refine";
import { GetBrandStatus } from "helpers/StateHelpers";
import { FunctionComponent } from "react";
import { useFilter } from "./hooks/useFilter";

interface FilterComponentProps {
    applyFilter: any;
    count: number;
}

export const Filter: FunctionComponent<FilterComponentProps> = ({ applyFilter, count }) => {
    const t = useTranslate();

    const [countries, sectors] = useFilter();

    return (
        <>
            <Row>
                <Col span={24}>
                    <Form
                        name="basic"
                        layout="vertical"
                        initialValues={{ remember: true }}
                        onFinish={async (values) => {
                            applyFilter(values);
                        }}
                        autoComplete="off"
                    >
                        <Space direction="vertical" style={{ width: "100%" }}>
                            <Row gutter={[30, 0]}>
                                <Col xs={24} sm={24} md={6} span={6}>
                                    <Form.Item label={t("global.brand", "Marka")} name="brandName">
                                        <Input style={{ width: "100%" }} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6} span={6}>
                                    <Form.Item label={t("global.sector")} name="sector">
                                        <Select
                                            style={{ width: "100%" }}
                                            allowClear
                                            options={sectors?.map((sector) => {
                                                return {
                                                    label: sector.name,
                                                    value: sector.id,
                                                };
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6} span={6}>
                                    <Form.Item label={t("pages.brand.status")} name="status">
                                        <Select style={{ width: "100%" }} allowClear options={GetBrandStatus()} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6} span={6}>
                                    <Form.Item label={t("pages.brand.country")} name="country">
                                        <Select
                                            style={{ width: "100%" }}
                                            allowClear
                                            options={countries?.map((c) => {
                                                return {
                                                    label: c.text,
                                                    value: c.id,
                                                };
                                            })}
                                        />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} sm={24} md={6} span={6}>
                                    <Form.Item label={t("pages.brand.hasMarketingPermission")} name="hasMarketingPermission">
                                        <Select
                                            style={{ width: "100%" }}
                                            allowClear
                                            options={[
                                                {
                                                    label: t("global.yes"),
                                                    value: true,
                                                },
                                                {
                                                    label: t("global.no"),
                                                    value: false,
                                                },
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row style={{ marginTop: 20 }}>
                                <Col style={{ marginLeft: "auto" }}>
                                    <Button htmlType={"submit"} type="ghost">
                                        {t("buttons.filter")}
                                    </Button>
                                </Col>
                            </Row>
                            <Row justify="end">
                                <p>
                                    {count} {t("global.brand.numberoflist")}
                                </p>
                            </Row>
                        </Space>
                    </Form>
                </Col>
            </Row>
        </>
    );
};
