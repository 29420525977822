import { CopyOutlined } from "@ant-design/icons";
import { EVENTS } from "Constanst";
import { Button, Col, message, Modal, Row } from "antd";
import { useModal } from "hooks";
import { FC } from "react";
import { Money } from "services/models/money";

export const PaymentBalanceDetailModal: FC = () => {
    // TODO: paymentBalanceOnMonthDetails model will be added
    const [open, detail, handleClose] = useModal<any>(EVENTS.PAYMENT_BALANCE_DETAIL_MODAL_IS_OPENED);

    if (!detail) return null;

    const { paymentBalanceOnMonthDetails } = detail;

    const handleCopyNetAmount = () => {
        const netAmount = new Money(paymentBalanceOnMonthDetails?.netPayable).toStringWithCurrency(paymentBalanceOnMonthDetails?.currency);
        navigator.clipboard
            .writeText(netAmount)
            .then(() => {
                message.success("Ödenecek Net Tutar başarıyla kopyalandı.");
            })
            .catch((error) => {
                console.error("Copy failed:", error);
                message.error("Kopyalama başarısız oldu. Lütfen tekrar deneyin.");
            });
    };

    return (
        <Modal
            open={open}
            okButtonProps={{ hidden: true }}
            cancelButtonProps={{ hidden: true }}
            onCancel={handleClose}
            closable={false}
            width={495}
            footer={null}
        >
            <Row justify="space-between">
                <Col>
                    <h1 style={{ textAlign: "center", fontSize: "34px" }}>
                        <b>Ödenecek Net Tutar</b>
                    </h1>
                </Col>
                <Col>
                    <Button type="link" onClick={handleClose}>
                        <img src="/images/close.svg" alt="" />
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col style={{ color: "#808080", fontSize: "16px", fontWeight: 500 }}>
                    *KDV dahil toplam tutar 6900₺ ve üzerine tevkifat uygulanır.
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "12px", marginBottom: "32px" }} />

            <Row style={{ fontSize: "16px" }} justify="space-between">
                <Col span={9} style={{ fontWeight: 600 }}>
                    Hak Ediş
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.progressPaymentLastMonth).toStringWithCurrency(
                        paymentBalanceOnMonthDetails?.currency
                    )}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />

            <Row style={{ fontSize: "16px" }} justify="space-between">
                <Col span={9} style={{ fontWeight: 600 }}>
                    Stopaj Tutarı (%15)
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.withholdingAmount).toStringWithCurrency(
                        paymentBalanceOnMonthDetails?.currency
                    )}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />

            <Row style={{ fontSize: "16px" }} justify="space-between">
                <Col span={9} style={{ fontWeight: 600 }}>
                    Net Tutar
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.netAmount).toStringWithCurrency(paymentBalanceOnMonthDetails?.currency)}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />

            <Row style={{ fontSize: "16px" }} justify="space-between">
                <Col span={9} style={{ fontWeight: 600 }}>
                    KDV (%20)
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.vat).toStringWithCurrency(paymentBalanceOnMonthDetails?.currency)}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />

            <Row style={{ fontSize: "16px" }} justify="space-between">
                <Col span={9} style={{ fontWeight: 600 }}>
                    KDV Tevkifat
                </Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.vatWithholding).toStringWithCurrency(paymentBalanceOnMonthDetails?.currency)}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />

            <Row style={{ fontSize: "20px", fontWeight: 700 }} justify="space-between">
                <Col span={9}>ÖDENECEK NET</Col>
                <Col span={9} style={{ textAlign: "right" }}>
                    {new Money(paymentBalanceOnMonthDetails?.netPayable).toStringWithCurrency(paymentBalanceOnMonthDetails?.currency)}
                </Col>
            </Row>
            <hr style={{ border: "1px solid #E8E8E8", marginTop: "16px", marginBottom: "16px" }} />
            <Row justify="start">
                <Col
                    onClick={handleCopyNetAmount}
                    style={{ textAlign: "start", color: "#952AE7", fontSize: "16px", fontWeight: 600, cursor: "pointer" }}
                >
                    <CopyOutlined /> Copy
                </Col>
            </Row>
        </Modal>
    );
};
