import { LayoutWrapper, useNavigation, useTranslate } from "@pankod/refine";
import { Button, Col, Form, Input, Modal, Row } from "antd";
import { PageContainer } from "components/pageContainer/pageContainer";
import { useEffect, useMemo, useState } from "react";
import { EVENTS, SocialMedias } from "../../../Constanst";
import UserHelper from "../../../helpers/userHelper";
import { InfluencersService } from "../../../services";
import { toast } from "react-toastify";
import { localizationHelper } from "helpers/globalizationHelper";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { Roles } from "helpers/roleHelper";
import { isValidAndSecureUrl } from "helpers/urlHelper";
import { DeleteOutlined, HistoryOutlined } from "@ant-design/icons";
import { PlatformDeleteModal } from "components/PlatformDeleteModal";
import { PlatformHistoryModal } from "components/PlatformHistoryModal";
import { SocialMedia, SocialMediaHistory } from "services/models/socialMedia";
import { useParams } from "react-router-dom";

interface PlatformInput {
    name: string;
    icon: string;
}

const EditPlatform = (props: any) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [socialMediaList, setSocialMediaList] = useState<SocialMedia[] | null>(null);
    const [socialMediaHistoryList, setSocialMediaHistoryList] = useState<SocialMediaHistory[] | null>(null);

    const [form] = Form.useForm();
    const { push } = useNavigation();
    const t = useTranslate();
    const userRole = UserHelper.getRole();

    const { id } = useParams<{ id: string }>();

    const influencerId = id || UserHelper.getId();

    const matcher = (value: any) => {
        return isValidAndSecureUrl(value);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
        push("/profile/detail");
    };

    const onSubmit = (values: any) => {
        const request = prepareRequest(values);

        InfluencersService.updateInfluencerSocialMedia({
            body: request,
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                if (userRole === Roles.Influencer) {
                    setIsModalVisible(true);
                } else {
                    push("/influencers/detail/" + influencerId);
                }
            })
            .catch((err) => {
                toast.error(t("global.platform-update-error"));
            });
    };

    const prepareRequest = (formValues: any) => {
        const socialMedias = [];

        if (formValues.instagramLink && formValues.instagramFollowers && formValues.instagramUsername) {
            let isSendUpdate = true;

            if (props.location.state && props.location.state.length > 0) {
                const sm = (props.location.state as any[]).filter((sm) => sm.socialMedia === SocialMedias.instagram.name);

                if (sm !== null && sm.length !== 0) {
                    if (
                        sm[0].username === formValues.instagramUsername &&
                        sm[0].link === formValues.instagramLink &&
                        sm[0].followers === formValues.instagramFollowers
                    ) {
                        isSendUpdate = false;
                    }
                }
            }

            if (isSendUpdate) {
                socialMedias.push({
                    name: SocialMedias.instagram.code,
                    username: formValues.instagramUsername,
                    followers: formValues.instagramFollowers,
                    link: formValues.instagramLink,
                });
            }
        }

        if (formValues.twitterLink && formValues.twitterFollowers && formValues.twitterUsername) {
            let isSendUpdate = true;

            if (props.location.state && props.location.state.length > 0) {
                const sm = (props.location.state as any[]).filter((sm) => sm.socialMedia === SocialMedias.twitter.name);

                if (sm !== null && sm.length !== 0) {
                    if (
                        sm[0].username === formValues.twitterUsername &&
                        sm[0].link === formValues.twitterLink &&
                        sm[0].followers === formValues.twitterFollowers
                    ) {
                        isSendUpdate = false;
                    }
                }
            }

            if (isSendUpdate) {
                socialMedias.push({
                    name: SocialMedias.twitter.code,
                    username: formValues.twitterUsername,
                    followers: formValues.twitterFollowers,
                    link: formValues.twitterLink,
                });
            }
        }

        if (formValues.discordLink && formValues.discordFollowers && formValues.discordUsername) {
            let isSendUpdate = true;

            if (props.location.state && props.location.state.length > 0) {
                const sm = (props.location.state as any[]).filter((sm) => sm.socialMedia === SocialMedias.discord.name);

                if (sm !== null && sm.length !== 0) {
                    if (
                        sm[0].username === formValues.discordUsername &&
                        sm[0].link === formValues.discordLink &&
                        sm[0].followers === formValues.discordFollowers
                    ) {
                        isSendUpdate = false;
                    }
                }
            }

            if (isSendUpdate) {
                socialMedias.push({
                    name: SocialMedias.discord.code,
                    username: formValues.discordUsername,
                    followers: formValues.discordFollowers,
                    link: formValues.discordLink,
                });
            }
        }

        if (formValues.telegramLink && formValues.telegramFollowers && formValues.telegramUsername) {
            let isSendUpdate = true;

            if (props.location.state && props.location.state.length > 0) {
                const sm = (props.location.state as any[]).filter((sm) => sm.socialMedia === SocialMedias.telegram.name);

                if (sm !== null && sm.length !== 0) {
                    if (
                        sm[0].username === formValues.telegramUsername &&
                        sm[0].link === formValues.telegramLink &&
                        sm[0].followers === formValues.telegramFollowers
                    ) {
                        isSendUpdate = false;
                    }
                }
            }

            if (isSendUpdate) {
                socialMedias.push({
                    name: SocialMedias.telegram.code,
                    username: formValues.telegramUsername,
                    followers: formValues.telegramFollowers,
                    link: formValues.telegramLink,
                });
            }
        }

        if (formValues.tiktokLink && formValues.tiktokFollowers && formValues.tiktokUsername) {
            let isSendUpdate = true;

            if (props.location.state && props.location.state.length > 0) {
                const sm = (props.location.state as any[]).filter((sm) => sm.socialMedia === SocialMedias.tiktok.name);

                if (sm !== null && sm.length !== 0) {
                    if (
                        sm[0].username === formValues.tiktokUsername &&
                        sm[0].link === formValues.tiktokLink &&
                        sm[0].followers === formValues.tiktokFollowers
                    ) {
                        isSendUpdate = false;
                    }
                }
            }

            if (isSendUpdate) {
                socialMedias.push({
                    name: SocialMedias.tiktok.code,
                    username: formValues.tiktokUsername,
                    followers: formValues.tiktokFollowers,
                    link: formValues.tiktokLink,
                });
            }
        }

        if (formValues.twitchLink && formValues.twitchFollowers && formValues.twitchUsername) {
            let isSendUpdate = true;

            if (props.location.state && props.location.state.length > 0) {
                const sm = (props.location.state as any[]).filter((sm) => sm.socialMedia === SocialMedias.twitch.name);

                if (sm !== null && sm.length !== 0) {
                    if (
                        sm[0].username === formValues.twitchUsername &&
                        sm[0].link === formValues.twitchLink &&
                        sm[0].followers === formValues.twitchFollowers
                    ) {
                        isSendUpdate = false;
                    }
                }
            }

            if (isSendUpdate) {
                socialMedias.push({
                    name: SocialMedias.twitch.code,
                    username: formValues.twitchUsername,
                    followers: formValues.twitchFollowers,
                    link: formValues.twitchLink,
                });
            }
        }

        if (formValues.youtubeLink && formValues.youtubeFollowers && formValues.youtubeUsername) {
            let isSendUpdate = true;

            if (props.location.state && props.location.state.length > 0) {
                const sm = (props.location.state as any[]).filter((sm) => sm.socialMedia === SocialMedias.youtube.name);

                if (sm !== null && sm.length !== 0) {
                    if (
                        sm[0].username === formValues.youtubeUsername &&
                        sm[0].link === formValues.youtubeLink &&
                        sm[0].followers === formValues.youtubeFollowers
                    ) {
                        isSendUpdate = false;
                    }
                }
            }

            if (isSendUpdate) {
                socialMedias.push({
                    name: SocialMedias.youtube.code,
                    username: formValues.youtubeUsername,
                    followers: formValues.youtubeFollowers,
                    link: formValues.youtubeLink,
                });
            }
        }

        return {
            influencerId,
            createInfluencerSocialMediaDtos: socialMedias,
        };
    };

    const checkSocialMediaFields = (socialMedia: string) => {
        return !!(
            form.getFieldValue(socialMedia + "Link") ||
            form.getFieldValue(socialMedia + "Username") ||
            form.getFieldValue(socialMedia + "Followers")
        );
    };

    const linkRules = [
        () => ({
            // @ts-ignore
            validator(row, value) {
                const socialMedia = row.field.replace("Link", "");
                if (checkSocialMediaFields(socialMedia) && (!value || !matcher(value))) {
                    return Promise.reject(new Error(t("global.addres-error")));
                }

                return Promise.resolve();
            },
        }),
    ];

    const usernameRules = [
        () => ({
            // @ts-ignore
            validator(row, value) {
                const socialMedia = row.field.replace("Username", "");

                if (checkSocialMediaFields(socialMedia) && !value) {
                    return Promise.reject(new Error(t("global.username-error")));
                }

                if (value && value.length < 3) {
                    return Promise.reject(new Error(t("global.username-length-error")));
                }

                return Promise.resolve();
            },
        }),
    ];

    const followersRules = [
        () => ({
            // @ts-ignore
            validator(row, value) {
                const socialMedia = row.field.replace("Followers", "");

                if (checkSocialMediaFields(socialMedia) && !value) {
                    return Promise.reject(new Error(t("global.social-count-error")));
                }

                if (value) {
                    if (value < 10000) {
                        return Promise.reject(new Error(t("global.social-count-min-error")));
                    } else if (value > 100000000) {
                        return Promise.reject(new Error(t("global.social-count-max-error")));
                    }
                }

                return Promise.resolve();
            },
        }),
    ];

    const platforms = useMemo<PlatformInput[]>(
        () => [
            { name: "instagram", icon: "INSTAGRAM36Px.svg" },
            { name: "twitter", icon: "TWITTER36Px.svg" },
            { name: "discord", icon: "DISCORD36Px.svg" },
            { name: "telegram", icon: "TELEGRAM36Px.svg" },
            { name: "tiktok", icon: "TIKTOK36Px.svg" },
            { name: "twitch", icon: "TWITCH36Px.svg" },
            { name: "youtube", icon: "YOUTUBE36Px.svg" },
        ],
        []
    );

    const iconButtonStyle = {
        margin: 0,
        padding: 0,
    };

    const getAllSocialMediaHistory = () => {
        InfluencersService.getInfluencerSocialMedias(influencerId, localizationHelper.getLanguage()).then((result) => {
            setSocialMediaHistoryList(result.data as SocialMediaHistory[]);
        });
    };

    const getInfluencerSocialMediaList = () => {
        InfluencersService.getInfluencers({
            id: influencerId,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((response) => {
            form.setFieldsValue(getFormValues(response.data.socialMedias as SocialMedia[]));
            setSocialMediaList(response.data.socialMedias as SocialMedia[]);
        });
    };

    const onDelete = (socialName: string) => {
        const name = socialName.toLocaleLowerCase();
        const list = [`${name}Link`, `${name}Username`, `${name}Followers`];
        form.resetFields(list);
        getInfluencerSocialMediaList();
        getAllSocialMediaHistory();
    };

    const handlePlatformDelete = (socialMediaName: string) => {
        const socialMedia = socialMediaList?.find(
            (socialMedia) => socialMedia.socialMedia.toLowerCase() === socialMediaName && !socialMedia.remove
        );

        window.dispatchEvent(
            new CustomEvent(EVENTS.PLATFORM_DELETE_MODEL_IS_OPENED, { detail: { onDelete, data: socialMedia, influencerId } })
        );
    };

    const handlePlatformHistory = (socialMediaName: string) => {
        const selectedSocialMedias = socialMediaHistoryList?.filter(
            (socialMedia) => socialMedia.socialMedia.toLowerCase() === socialMediaName
        );
        if (selectedSocialMedias?.length)
            window.dispatchEvent(new CustomEvent(EVENTS.PLATFORM_HISTORY_MODEL_IS_OPENED, { detail: selectedSocialMedias }));
    };

    const getSocialMediaProperties = (socialMedia: SocialMedia) => {
        const name = socialMedia.socialMedia.toLowerCase();
        return {
            [`${name}Link`]: socialMedia.link,
            [`${name}Username`]: socialMedia.username,
            [`${name}Followers`]: socialMedia.isApproved ? socialMedia.followers : socialMedia.pendingApprovalFollowers,
        };
    };

    const getFormValues = (socialMediaList: SocialMedia[]) => {
        return socialMediaList.reduce((result, socialMedia) => {
            // API returns also removed social medias.
            // Thus, removed social media must be filtered when form is filled
            // if social media is removed, don't add it to form values
            if (socialMedia.remove) return result;
            return { ...result, ...getSocialMediaProperties(socialMedia) };
        }, {});
    };

    useEffect(() => {
        getAllSocialMediaHistory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getInfluencerSocialMediaList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <LayoutWrapper>
            <style>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance:textfield; /* Firefox */
        }
      `}</style>
            <FenBreadCrumb
                items={[
                    {
                        name: t("global.profile"),
                        url: userRole === Roles.Influencer ? "/profile/detail" : "/influencers/detail/" + influencerId,
                    },
                    { name: t("global.platforms"), url: "" },
                ]}
            />

            {socialMediaList ? (
                <Col style={{ marginBottom: "75px" }} xs={24} sm={24} md={18} span={18}>
                    <PageContainer pageTitle={t("global.platforms", "Platformlar")} helperContent={""}>
                        <Row style={{ width: "100%" }}>
                            <Form
                                form={form}
                                layout={"vertical"}
                                onFinish={(values) => onSubmit(values)}
                                style={{ display: "flex", width: "100%" }}
                                initialValues={() => getFormValues(socialMediaList)}
                            >
                                <Col span={24} className="item">
                                    <Row gutter={[10, 0]} style={{ textAlign: "center" }}>
                                        <Col offset={3} span={7}>
                                            <h3>Hesap Linki</h3>
                                        </Col>
                                        <Col span={6}>
                                            <h3>Hesap Adı</h3>
                                        </Col>
                                        <Col span={6}>
                                            <h3>Takipçi Sayısı</h3>
                                        </Col>
                                    </Row>
                                    {platforms.map(({ name, icon }) => (
                                        <Row gutter={[10, 0]} key={`platform-input-key-${name}`}>
                                            <Col span={2}>
                                                <img alt={`social-media-${name}`} src={`/images/social-media-icon/${icon}`} />
                                            </Col>
                                            <Col span={1}>
                                                <Button
                                                    type="link"
                                                    shape="circle"
                                                    size="small"
                                                    style={iconButtonStyle}
                                                    onClick={() => handlePlatformHistory(name)}
                                                >
                                                    <HistoryOutlined />
                                                </Button>
                                            </Col>
                                            <Col span={7}>
                                                <Form.Item name={`${name}Link`} rules={linkRules}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item name={`${name}Username`} rules={usernameRules}>
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                            <Col span={6}>
                                                <Form.Item name={`${name}Followers`} rules={followersRules}>
                                                    <Input type={"number"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={2}>
                                                <Button
                                                    type="link"
                                                    shape="circle"
                                                    size="small"
                                                    style={iconButtonStyle}
                                                    onClick={() => handlePlatformDelete(name)}
                                                    disabled={!checkSocialMediaFields(name)}
                                                >
                                                    <DeleteOutlined style={{ fontSize: 16 }} />
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}

                                    <Row justify="end">
                                        <Form.Item>
                                            <Button htmlType={"submit"} type="default" style={{ borderRadius: "10px" }}>
                                                {t("buttons.save")}
                                            </Button>
                                            <Modal visible={isModalVisible} centered footer={[]} onCancel={handleCancel}>
                                                <div style={{ textAlign: "center" }}>
                                                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "22px" }}>
                                                        {t("global.influencer.platforms.add-platform-action-response-title")}
                                                    </h3>
                                                    <p>{t("global.influencer.platforms.add-platform-action-response-content")}</p>
                                                    <Button type="default" onClick={() => push("/profile/detail")}>
                                                        {t("global.influencer.back-to-profile")}
                                                    </Button>
                                                </div>
                                            </Modal>
                                        </Form.Item>
                                    </Row>
                                </Col>
                            </Form>
                        </Row>
                    </PageContainer>
                </Col>
            ) : null}

            <PlatformDeleteModal />
            <PlatformHistoryModal />
        </LayoutWrapper>
    );
};

export default EditPlatform;
