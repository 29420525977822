import { useTranslate, Col, Table } from "@pankod/refine";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { FC } from "react";
import { useReceiptList } from "../hooks";

export const ReceiptList: FC = () => {
    const t = useTranslate();
    const [data, columns] = useReceiptList();

    return (
        <Col span={24}>
            <FenBreadCrumb
                items={[
                    { name: t("global.brands"), url: "/brands/list" },
                    { name: t("global.platforms"), url: "" },
                    { name: t("global.reciepts"), url: "" },
                ]}
            />
            <PageContainer pageTitle={t("global.reciepts")} helperContent={"Buraya yardım içeriği gelecek"}>
                <Table columns={columns} dataSource={data} scroll={{ x: "100%" }} />
            </PageContainer>
        </Col>
    );
};
