import { Funnel } from "@ant-design/plots";
import { Col, Row, Space, useTranslate } from "@pankod/refine";
import { useEffect, useState } from "react";
import { CampaignType } from "../../../../Constanst";

interface EarningChartProps {
    performance: any;
    campaignType: CampaignType;
}

export const EarningChart = (props: EarningChartProps) => {
    const t = useTranslate();
    const [funnel, setFunnel] = useState<any>();

    const getFunnelDisplayName = () => {
        if (props.campaignType === CampaignType.clicking) {
            return t("campaign.create.step3.totalclick");
        } else if (props.campaignType === CampaignType.action) {
            return t("campaign.create.step3.totalaction");
        } else if (props.campaignType === CampaignType.download) {
            return t("campaign.create.step3.totaldownload");
        } else {
            return t("campaign.create.step3.totalsell");
        }
    };

    useEffect(() => {
        if (props.performance && props.performance?.results) {
            let totalClicks = props.performance.totalClickCount;
            let totalActions = 0;

            if (props.campaignType === CampaignType.action) {
                totalActions = props.performance.totalActionCount;
            } else if (props.campaignType === CampaignType.download) {
                totalActions = props.performance.totalInstallCount;
            } else if (props.campaignType === CampaignType.selling || props.campaignType === CampaignType.amazon) {
                totalActions = props.performance.totalSellCount;
            }

            const funnelData = [
                {
                    stage: t("campaign.create.step3.totalclick"),
                    number: totalActions < 0 && totalClicks === 0 ? 1 : totalClicks,
                },
                {
                    stage: getFunnelDisplayName(),
                    number: totalActions,
                },
            ];

            const conf = {
                data: funnelData,
                xField: "stage",
                yField: "number",
                minSize: 0.4,
            };
            setFunnel(<Funnel color={["#E4CAF9", "#9254de"]} {...conf} />);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.performance, props.performance?.results]);

    return (
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Space direction="vertical" size="small" style={{ width: "100%" }}>
                        {funnel}
                    </Space>
                </Col>
            </Row>
        </Space>
    );
};
