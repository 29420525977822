import { Authenticated, LayoutWrapper } from "@pankod/refine";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { FunctionComponent } from "react";
import { AdminBrandCreate } from "modules/brands/create";

export const BrandCreate: FunctionComponent = () => {
    return (
        <Authenticated>
            <LayoutWrapper>{(RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root)) && <AdminBrandCreate />}</LayoutWrapper>
        </Authenticated>
    );
};
