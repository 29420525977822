import { CSSProperties } from "react";

export const loginPageLayoutStyle: CSSProperties = {
    minHeight: "100vh",
    paddingTop: "130px",
    paddingBottom: "200px",
    backgroundColor: "transparent",
    marginTop: "-400px",
};

export const loginPageLayoutRowStyle: CSSProperties = {
    alignContent: "center",
    flexDirection: "column",
};

export const containerStyles: CSSProperties = {
    margin: "auto",
};

export const titleStyle: CSSProperties = {
    textAlign: "center",
    color: "#4E4E4E",
    fontSize: "50px",
    letterSpacing: "-0.04em",
};

export const secondTitleStyle: CSSProperties = {
    textAlign: "center",
    color: "#4E4E4E",
    fontSize: "30px",
    letterSpacing: "-0.04em",
};

export const registerTabWorkaroutStyle =
    "#registerTabs > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before  { border-bottom: none; }";
export const loginTabWorkaroutStyle =
    "#login-tabs > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before  { border-bottom: 3px solid #f0f0f0; }";
