import "./header.scss";
import { Button, Dropdown, Avatar, MenuProps, Row, Col } from "antd";
import { useTranslation as usei18nextTranslation } from "react-i18next";
interface Props {
    onLoginClicked: () => void;
    onRegisterClicked: () => void;
}

export default function Header(props: Props) {
    const { i18n } = usei18nextTranslation();
    const currentLocale = i18n.language;
    const changeLocale = (lang: string) => i18n.changeLanguage(lang);
    const menuItems: MenuProps["items"] = [...(i18n.languages || [])].sort().map((lang: string) => ({
        key: lang,
        onClick: () => changeLocale(lang),
        icon: (
            <span style={{ marginRight: 8 }}>
                <Avatar size={16} src={`/images/flags/${lang}.svg`} />
            </span>
        ),
        label: lang === "tr" ? "Türkçe" : lang === "es" ? "Español" : "English",
    }));

    return (
        <header className="header">
            <Row align="middle" justify="space-between">
                <Col>
                    <div className="header-inner">
                        <a href="/" className="logo">
                            <img src={"/images/logo/logo.svg"} className="Logo" alt="logo" />
                        </a>
                    </div>
                </Col>
                <Col>
                    <Dropdown
                        menu={{
                            items: menuItems,
                            selectedKeys: currentLocale ? [currentLocale] : [],
                        }}
                        placement="bottomLeft"
                    >
                        <Button type="link" shape="circle">
                            <Avatar size={24} src={`/images/flags/${currentLocale}.svg`} />
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
        </header>
    );
}
