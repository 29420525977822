import { ClockCircleOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Row, Space, Table, Tabs, Tag, useNavigation, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { localizationHelper } from "helpers/globalizationHelper";
import { GetCampaignStateText, GetCampaignTypeTextWithManagementType } from "helpers/StateHelpers";
import moment from "moment";
import { FunctionComponent, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { CampaignInfluencerStates, CampaignManagementType, CampaignStatesColors, PaginationConstant } from "../../../../Constanst";
import UserHelper from "../../../../helpers/userHelper";
import { CampaignsService } from "../../../../services";
import { Pagination } from "../../../../services/core/Pagination";

const { TabPane } = Tabs;

interface BrandInfluencerDetailProps {
    influencer: any;
}

export const BrandInfluencerDetail: FunctionComponent<BrandInfluencerDetailProps> = (props: BrandInfluencerDetailProps) => {
    const { push } = useNavigation();
    const t = useTranslate();

    const [appliedPagination, setAppliedPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize
    });

    const [acceptedPagination, setAcceptedPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize
    });

    const [appliedCampaignsLoading, setAppliedCampaignsLoading] = useState(false);
    const [acceptedCampaignsLoading, setAcceptedCampaignsLoading] = useState(false);
    const [appliedCampaignsCurrentPage, setAppliedCampaignsCurrentPage] = useState(PaginationConstant.initialPage);
    const [acceptedCampaignsCurrentPage, setAcceptedCampaignsCurrentPage] = useState(PaginationConstant.initialPage);
    const [acceptedCampaigns, setAcceptedCampaigns] = useState<any[]>([]);
    const [appliedCampaigns, setAppliedCampaigns] = useState<any[]>([]);

    const [selectedCampaigns, setSelectedCampaigns] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState(1);

    const toggleSelection = (id: any) => {
        const indexOf = selectedCampaigns.indexOf(id);
        if (indexOf >= 0) {
            selectedCampaigns.splice(indexOf, 1);
            setSelectedCampaigns(selectedCampaigns);
        } else {
            selectedCampaigns.push(id);
            setSelectedCampaigns(selectedCampaigns);
        }
        setAppliedCampaigns(JSON.parse(JSON.stringify(appliedCampaigns)));
        setAcceptedCampaigns(JSON.parse(JSON.stringify(acceptedCampaigns)));
    };

    const toggleAllSelection = (e: any) => {
        if (+activeTab === 1) {
            appliedCampaigns.forEach((ac) => toggleSelection(ac.id));
        } else {
            acceptedCampaigns.forEach((ac) => toggleSelection(ac.id));
        }
    };

    const showCampaignDetail = (campaign: any) => {
        push("/campaign/detail", {
            id: campaign.campaignId,
            typeHash: campaign.campaignTypeHash,
        });
    };

    const columnsAppliant = [
        {
            title: <Checkbox onChange={toggleAllSelection} />,
            key: "checbox",
            render: (text: string, row: any, index: number) => (
                <Checkbox
                    checked={selectedCampaigns.includes(row.id)}
                    onChange={() => {
                        toggleSelection(row.id);
                    }}
                />
            ),
        },
        {
            title: t("global.campaign-name"),
            dataIndex: "campaignName",
            key: "campaign",
            render: (text: string, row: any, index: number) => (
                <Button
                    type="link"
                    onClick={() => {
                        showCampaignDetail(row);
                    }}
                >
                    {text}
                </Button>
            ),
        },
        {
            title: <span className="table-font">{t("global.campaignType")}</span>,
            dataIndex: "campaignType",
            key: "campaignType",
            render: (text: any, row: any) => (
                <span>{GetCampaignTypeTextWithManagementType(row?.campaignTypeHash, row?.managementType)}</span>
            ),
        },
        {
            title: <span className="table-font">{t("global.status")}</span>,
            dataIndex: "campaignStateModel",
            key: "campaignStateModel",
            render: ({ campaignState }: any) => {
                return <Tag color={CampaignStatesColors[campaignState].toString()}>{GetCampaignStateText(campaignState)}</Tag>;
            },
        },
        {
            title: t("global.start"),
            dataIndex: "startDate",
            key: "startDate",
        },
        {
            title: t("global.end"),
            dataIndex: "endDate",
            key: "endDate",
        },
        {
            title: t("global.unit-price-marj"),
            dataIndex: "unitPrice",
            key: "unitPrice",
        },
        {
            title: t("campaign.create.step3.totalAmount"),
            dataIndex: "balancePerInfluencer",
            key: "balancePerInfluencer",
        },
        {
            title: t("campaign.create.step3.budgetUsingRatebudgetUsingRate"),
            dataIndex: "transformation",
            key: "transformation",
        },
    ];

    const calculateFollowersString = (count: any) => {
        return (count % 1000 > 0 ? (count - (count % 1000)) / 1000 : count / 1000) + "K";
    };

    useEffect(() => {
        getCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.influencer]);

    useEffect(() => {
        if (props.influencer) {
            getAppliedCampaigns();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appliedCampaignsCurrentPage]);

    useEffect(() => {
        if (props.influencer) {
            getAcceptedCampaigns();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [acceptedCampaignsCurrentPage]);

    const getCampaigns = () => {
        if (props.influencer) {
            getAppliedCampaigns();
            getAcceptedCampaigns();
        }
    };

    const refresh = () => {
        setAppliedCampaignsCurrentPage(PaginationConstant.initialPage);
        setAcceptedCampaignsCurrentPage(PaginationConstant.initialPage);
        setAppliedPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize
        });
        setAcceptedPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize
        });

        if (props.influencer) {
            getAppliedCampaigns();
            getAcceptedCampaigns();
        }
    };

    const getAppliedCampaigns = () => {
        setAppliedCampaignsLoading(true);
        CampaignsService.getCampaignInfluencers({
            body: {
                influencerId: props.influencer.id,
                brandId: UserHelper.getId(),
                state: CampaignInfluencerStates.Applied,
                pageNumber: appliedPagination.current,
                pageSize: appliedPagination.pageSize,
                managementType: CampaignManagementType.SelfService,
            },
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setAppliedCampaigns(resp?.data.data);
            setAppliedPagination({
                current: appliedPagination.current,
                pageSize: appliedPagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount
            });
            setAppliedCampaignsLoading(false);
        });
    };

    const getAcceptedCampaigns = () => {
        setAcceptedCampaignsLoading(true);
        CampaignsService.getCampaignInfluencers({
            body: {
                influencerId: props.influencer.id,
                brandId: UserHelper.getId(),
                state: CampaignInfluencerStates.Accepted,
                pageNumber: acceptedPagination.current,
                pageSize: acceptedPagination.pageSize,
                managementType: CampaignManagementType.SelfService,
            },
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setAcceptedCampaigns(resp?.data.data);
            setAcceptedPagination({
                current: appliedPagination.current,
                pageSize: appliedPagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount
            });
            setAcceptedCampaignsLoading(false);
        });
    };

    const onAppliedCampaignsPageChange = (page: any) => {
        setAppliedCampaignsCurrentPage(page.current);
        setAppliedPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total
        });
    };

    const onAcceptedCampaignsPageChange = (page: any) => {
        setAcceptedCampaignsCurrentPage(page.current);
        setAcceptedPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total
        });
    };

    const tabChanged = (e: any) => {
        setActiveTab(e);
        setSelectedCampaigns([]);
    };

    const accept = () => {
        CampaignsService.acceptCampaignInfluencer({
            campaignInfluencerIds: selectedCampaigns,
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                refresh();
            })
            .catch((error) => {
                toast.error(t("notifications.errorr"));
            });
    };

    const reject = () => {
        CampaignsService.rejectCampaignInfluencer({
            campaignInfluencerIds: selectedCampaigns,
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                refresh();
            })
            .catch((error) => {
                toast.error(t("notifications.errorr"));
            });
    };

    const remove = () => {
        CampaignsService.removeCampaignInfluencer({
            campaignInfluencerIds: selectedCampaigns,
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                refresh();
            })
            .catch((error) => {
                toast.error(t("notifications.errorr"));
            });
    };

    return (
        <>
            <span className="clickable-span" style={{ color: "#952AE7" }}>
                {t("global.my-profile")}
            </span>{" "}
            {">"}{" "}
            <span className="clickable-span" style={{ color: "grey" }}>
                {t("global.phenomenon", "Fenomen")}
            </span>
            <Row gutter={[30, 10]}>
                <Col xs={24} sm={24} md={12} span={12}>
                    <PageContainer pageTitle={t("global.phenomenon", "Fenomen")} helperContent={"Yardım içeriği eklenecek!"}>
                        <div style={{ padding: "20px 0px" }}>
                            <Row>
                                <Col>
                                    <img
                                        src={
                                            props.influencer?.logoPath
                                                ? props.influencer?.logoPath
                                                : "/images/profile/profile_influencer.png"
                                        }
                                        alt="img"
                                        width={150}
                                        style={{ borderRadius: "10px", boxShadow: "1px 1px 1px 1px" }}
                                    />
                                </Col>
                                <Col style={{ padding: "50px 0px 0px 20px" }}>
                                    <h2 style={{ color: "#9254de", fontWeight: "bold" }}>
                                        {props.influencer?.firstName} {props.influencer?.lastName}
                                    </h2>
                                    <p>
                                        <span>
                                            {props.influencer?.categories &&
                                                props.influencer?.categories.length > 0 &&
                                                props.influencer?.categories.map((c: any) => {
                                                    return c.isPublic ? <span>{"#" + c.name + " "}</span> : null;
                                                })}
                                        </span>
                                        {" / " + props.influencer?.country}
                                    </p>
                                    <p>{moment(props.influencer?.createdOn).format("DD-MM-YYYY")}</p>
                                </Col>
                            </Row>
                        </div>
                        <hr style={{ borderTop: "1px solid #952AE7" }} />
                        <Row>
                            <Col>
                                <h3 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("global.about-phenomenon")}</h3>
                                <p>{props.influencer?.biography}</p>
                            </Col>
                        </Row>
                    </PageContainer>
                </Col>
                <Col xs={24} sm={24} md={12} span={12}>
                    <PageContainer pageTitle={t("global.platforms", "Platformlar")} helperContent={"Yardım içeriği eklenecek!"}>
                        <Row gutter={[40, 24]}>
                            {props.influencer?.socialMedias.map((item: any) => (
                                <Col span={8} style={{ textAlign: "center" }}>
                                    <Row
                                        style={{
                                            display: "flex",
                                            justifyContent: "space-evenly",
                                            alignItems: "center",
                                            flexDirection: "row",
                                            flexWrap: "nowrap",
                                        }}
                                    >
                                        <img alt={"social"} src={`/images/social-media-icon/${item.socialMedia.toUpperCase()}36Px.svg`} />
                                        <Col style={{ fontSize: "0.85em" }}>
                                            <a href={item.link} target="_blank" rel="noreferrer" style={{ color: "#9254de" }}>
                                                {"  "}@{item.socialMedia.toLowerCase()}
                                            </a>
                                            <p>
                                                {item.isApproved
                                                    ? calculateFollowersString(item.followers)
                                                    : calculateFollowersString(item.pendingApprovalFollowers)}{" "}
                                                {!item.isApproved ? <ClockCircleOutlined /> : null}{" "}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                            ))}
                        </Row>
                    </PageContainer>
                </Col>
            </Row>
            <Row style={{ marginTop: "40px" }}>
                <Col span={24}>
                    <PageContainer pageTitle={t("global.myCampaign")} helperContent={"Buraya yardım içeriği gelecek"}>
                        <Tabs defaultActiveKey="1" onChange={(e) => tabChanged(e)}>
                            <TabPane tab={<Space direction="horizontal">{t("global.pending-campaigns")}</Space>} key="1">
                                <Row style={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: "10px 0px" }}>
                                    <Button
                                        onClick={() => reject()}
                                        disabled={!selectedCampaigns || selectedCampaigns.length === 0}
                                        type="primary"
                                    >
                                        {t("global.reject", "Reddet")}
                                    </Button>
                                    <Button
                                        onClick={() => accept()}
                                        disabled={!selectedCampaigns || selectedCampaigns.length === 0}
                                        type="default"
                                        style={{ marginLeft: "20px" }}
                                    >
                                        {t("global.accept", "Kabul Et")}
                                    </Button>
                                </Row>
                                <Table
                                    pagination={appliedPagination}
                                    columns={columnsAppliant}
                                    dataSource={appliedCampaigns}
                                    loading={appliedCampaignsLoading}
                                    onChange={(page) => onAppliedCampaignsPageChange(page)}
                                    scroll={{ x: "100%" }}
                                />
                            </TabPane>
                            <TabPane tab={<Space direction="horizontal">{t("global.accept-campaigns")}</Space>} key="2">
                                <Row style={{ width: "100%", display: "flex", justifyContent: "flex-end", padding: "10px 0px" }}>
                                    <Button
                                        onClick={() => remove()}
                                        disabled={!selectedCampaigns || selectedCampaigns.length === 0}
                                        type="primary"
                                    >
                                        Çıkar
                                    </Button>
                                </Row>
                                <Table
                                    columns={columnsAppliant}
                                    dataSource={acceptedCampaigns}
                                    pagination={acceptedPagination}
                                    loading={acceptedCampaignsLoading}
                                    onChange={(page) => onAcceptedCampaignsPageChange(page)}
                                    scroll={{ x: "100%" }}
                                />
                            </TabPane>
                        </Tabs>
                    </PageContainer>
                </Col>
            </Row>
        </>
    );
};
