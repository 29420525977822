import { EVENTS } from "Constanst";
import { useEffect, useState } from "react";
import { LookupsService } from "services";
import { Country } from "services/models/Lookups";

export const useFilter = () => {
    const [countries, setCountries] = useState<Country[]>([]);
    function handleFilter(values: any) {
        window.dispatchEvent(new CustomEvent(EVENTS.TABLE_FILTER, { detail: values }));
    }

    useEffect(() => {
        const getCountries = () => {
            LookupsService.getCountries({}).then((res) => {
                setCountries(res.data ?? []);
            });
        };

        getCountries();
    }, []);
    return [handleFilter, countries] as const;
};
