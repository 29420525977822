import { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { List, Skeleton, Divider, notification } from "antd";
import InfiniteScroll from "react-infinite-scroll-component";
import { CopyOutlined } from "@ant-design/icons";
import { SocialMedias } from "../../../../Constanst";
//import { toast } from 'react-toastify';
import { useTranslate } from "@pankod/refine";

export interface SocialInfiniteListProps {
    links: any[];
    disabled: boolean;
}

export const SocialInfiniteList = (props: SocialInfiniteListProps) => {
    const [loading, setLoading] = useState(false);
    const t = useTranslate();
    const copyToClipboard = (link: string) => {
        const textField = document.createElement("textarea");
        textField.innerText = link;
        document.body.appendChild(textField);
        textField.select();
        document.execCommand("copy");
        textField.remove();
        notification.open({ message: t("global.copied"), type: "success" });
        //toast.success(t('global.copied'));
    };

    const loadMoreData = () => {
        if (!props.links) {
            setLoading(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        loadMoreData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.links]);

    const getSocialImage = (link: any) => {
        if (link.name === +SocialMedias.twitter.code) {
            return <img src="/images/social-media-icon/TWITTER36Px.svg" alt={"social"} />;
        } else if (link.name === +SocialMedias.instagram.code) {
            return <img src="/images/social-media-icon/INSTAGRAM36Px.svg" alt={"social"} />;
        } else if (link.name === +SocialMedias.youtube.code) {
            return <img src="/images/social-media-icon/YOUTUBE36Px.svg" alt={"social"} />;
        } else if (link.name === +SocialMedias.tiktok.code) {
            return <img src="/images/social-media-icon/TIKTOK36Px.svg" alt={"social"} />;
        } else if (link.name === +SocialMedias.twitch.code) {
            return <img src="/images/social-media-icon/TWITCH36Px.svg" alt={"social"} />;
        } else if (link.name === +SocialMedias.telegram.code) {
            return <img src="/images/social-media-icon/TELEGRAM36Px.svg" alt={"social"} />;
        } else if (link.name === +SocialMedias.discord.code) {
            return <img src="/images/social-media-icon/DISCORD36Px.svg" alt={"social"} />;
        }
    };

    return (
        <div
            id="scrollableDiv"
            style={{
                height: 250,
                overflow: "auto",
                padding: "0 16px",
                border: "1px solid rgba(140, 140, 140, 0.35)",
                width: "100%",
            }}
        >
            <InfiniteScroll
                dataLength={props.links?.length}
                next={loadMoreData}
                hasMore={loading}
                loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
                endMessage={<Divider plain />}
                scrollableTarget="scrollableDiv"
            >
                <List
                    dataSource={props.links}
                    renderItem={(item: any) => (
                        <List.Item key={item.targetLink}>
                            <List.Item.Meta
                                title={
                                    props.disabled ? (
                                        <div>
                                            {getSocialImage(item)}{" "}
                                            <span className="clickable-span" style={{ color: "#9254de", fontWeight: "bold" }}>
                                                {item.platformName}
                                            </span>
                                        </div>
                                    ) : (
                                        <div>
                                            {getSocialImage(item)}{" "}
                                            <span
                                                className="clickable-span"
                                                onClick={() => copyToClipboard(item.targetLink)}
                                                style={{ color: "#9254de", fontWeight: "bold" }}
                                            >
                                                {item.platformName}{" "}
                                            </span>
                                            <CopyOutlined
                                                style={{ cursor: "pointer", color: "#952AE7" }}
                                                onClick={() => copyToClipboard(item.targetLink)}
                                            />
                                        </div>
                                    )
                                }
                            />
                        </List.Item>
                    )}
                />
            </InfiniteScroll>
        </div>
    );
};
