import { EVENTS, PaginationConstant } from "Constanst";
import { base64ToByteArray, downloadFile } from "helpers";
import { useCallback, useEffect, useRef, useState } from "react";
import { InfluencersService } from "services";
import { Pagination } from "services/core/Pagination";

export const useList = () => {
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedInfluencerList, setSelectedInfluencerList] = useState<string[]>([]);
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
        total: 0,
    });

    const filter = useRef<Record<string, any>>({
        pageNumber: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });

    const downloadInfluencersPaymentReport = useCallback(() => {
        InfluencersService.influencersPaymentReportExport({
            body: filter.current,
        }).then((resp) => {
            const bytes = base64ToByteArray(resp);
            downloadFile(bytes, "influencerPayments.xlsx");
        });
    }, []);

    const getPayments = useCallback(function (searchFilter = {}) {
        const newFilter = { ...filter.current, ...searchFilter };

        if (newFilter?.countries && newFilter.countries.length === 0) {
            delete newFilter.countries;
        }

        filter.current = newFilter;
        setLoading(true);

        InfluencersService.searchInfluencersWithPaymentData({
            body: filter.current,
        })
            .then((resp) => {
                setPayments(resp?.data?.data);
                setPagination((prevState) => ({
                    ...prevState,
                    total: resp?.data?.pageInfo.totalItemCount,
                }));
            })
            .finally(() => {
                setLoading(false);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleTableChange(page: any) {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });

        getPayments({ pageNumber: page.current, pageSize: page.pageSize });
    }

    useEffect(() => {
        getPayments();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function filterEventListener(event: CustomEvent) {
            getPayments(event.detail);
        }

        window.addEventListener(EVENTS.TABLE_FILTER, filterEventListener as EventListener);

        return () => {
            window.removeEventListener(EVENTS.TABLE_FILTER, filterEventListener as EventListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [
        loading,
        payments,
        pagination,
        handleTableChange,
        selectedInfluencerList,
        setSelectedInfluencerList,
        downloadInfluencersPaymentReport,
    ] as const;
};
