import { Button, Col, Form, Input, Modal, Row, useGetIdentity, useTranslate } from "@pankod/refine";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { PageContainer } from "components/pageContainer/pageContainer";
import { localizationHelper } from "helpers/globalizationHelper";
import UserHelper from "helpers/userHelper";
import { FunctionComponent, useEffect, useState } from "react";
import { Fenomio_Contract_App_Brand_UpdateBrandRequest, Fenomio_Contract_App_User_UpdateUserRequest, UsersService } from "services";
import { ProfileModel } from "./models";

export const AdminProfile: FunctionComponent = () => {
    const [updateEnabled, setUpdateEnabled] = useState(false);
    const translate = useTranslate();
    const [isLoading, setLoading] = useState(false);
    const [profile, setProfile] = useState<any>({});
    const [role, setRole] = useState<any>();
    const t = useTranslate();

    useEffect(() => {
        const pathname = window.location.pathname;
        if (pathname.includes("edit")) {
            setUpdateEnabled(true);
        }

        refresh();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refresh = () => {
        UserHelper.getProfile().then((resp) => {
            setProfile(resp);
        });
        setRole(UserHelper.getRole());
    };

    return (
        <Col xs={24} sm={24} md={14} span={14}>
            <FenBreadCrumb
                items={[
                    { name: t("global.profile"), url: "/profile/detail" },
                    { name: t("global.account-overview"), url: "" },
                ]}
            />            <PageContainer pageTitle={t("global.account-overview")} helperContent={"Buraya yardım içeriği gelecek"}>
                <Row>
                    <Col span={24} xs={24} lg={24} xl={16}>
                        {updateEnabled && (
                            <UpdateForm
                                refresh={refresh}
                                profile={profile}
                                role={role}
                                setLoading={setLoading}
                                setUpdateEnabled={setUpdateEnabled}
                                isLoading={isLoading}
                                updateEnabled={updateEnabled}
                            />
                        )}
                        {!updateEnabled && <DisplayForm profile={profile} role={role} />}
                    </Col>
                </Row>
                <Row justify={"end"}>
                    <Col>
                        <Button
                            type="default"
                            onClick={() => setUpdateEnabled(true)}
                            style={{ display: updateEnabled ? "none" : "block" }}
                        >
                            {translate("pages.profile.edit", "Profili Düzenle")}
                        </Button>
                    </Col>
                </Row>
            </PageContainer>
        </Col>
    );
};

export const DisplayForm = (props: any) => {
    const t = useTranslate();

    return (
        <Form<Fenomio_Contract_App_Brand_UpdateBrandRequest> layout="horizontal" requiredMark={false} scrollToFirstError>
            <Row>
                <Col xs={24} lg={12}>
                    <div className="info-text mb-25">
                        <h4 className="title">
                            {props.profile.firstName} {props.profile.lastName}
                        </h4>
                        <div className="body">
                            <span>{props.role}</span>
                        </div>
                    </div>

                    <div className="info-text mb-15">
                        <h4>
                            <b>Id</b>
                        </h4>
                        <div className="body">
                            <span>#{props.profile.id}</span>
                        </div>
                    </div>

                    <div className="info-text mb-15">
                        <h4>
                            <b>{t("global.name-surname")}</b>
                        </h4>
                        <div className="body">
                            <span>
                                {props.profile.firstName} {props.profile.lastName}
                            </span>
                        </div>
                    </div>

                    <div className="info-text mb-15">
                        <h4>
                            <b>{t("global.contact-email")}</b>
                        </h4>
                        <div className="body">
                            <span>{props.profile.email}</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

interface UpdateFormProps {
    isLoading: boolean;
    updateEnabled: boolean;
    setLoading: (loading: boolean) => void;
    setUpdateEnabled: (enabled: boolean) => void;
    profile: any;
    role: any;
    refresh: any;
}

export const UpdateForm = (props: UpdateFormProps) => {
    const { data: user } = useGetIdentity<ProfileModel>();
    const [profileForm] = Form.useForm<Fenomio_Contract_App_User_UpdateUserRequest>();
    const t = useTranslate();

    useEffect(() => {
        if (user) {
            profileForm.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileForm, user]);

    function updateFields(fields: any) {
        profileForm.validateFields().then((values) => {
            props.setLoading(true);
            const nameSplit = fields.name.split(" ");
            const name = nameSplit[0];
            let surname = "";

            if (nameSplit.length > 1) {
                nameSplit.splice(0, 1);
                surname = nameSplit.join(" ");
            }

            UsersService.putUsers({
                id: UserHelper.getId(),
                acceptLanguage: localizationHelper.getLocale(),
                requestBody: {
                    email: props.profile.email,
                    firstName: name,
                    lastName: surname,
                },
            })
                .then(function (response) {
                    props.setLoading(false);
                    if (response.status === "SUCCESS") {
                        Modal.success({
                            title: t("notifications.success"),
                            content: t("notifications.edit-success"),
                        });
                        props.setUpdateEnabled(false);
                        props.refresh();
                    }
                })
                .catch((error) => {
                    props.setLoading(false);
                    if (error.body) {
                        let validationError = error.body;

                        let message = "";
                        if (validationError.validationMessages?.length > 0) {
                            message = validationError.validationMessages.join(", ");
                        } else {
                            message = validationError.Message;
                        }

                        Modal.error({
                            title: validationError.Message,
                            content: message,
                        });
                    }
                });
        });
    }

    return (
        <Form<Fenomio_Contract_App_User_UpdateUserRequest>
            layout="vertical"
            form={profileForm}
            requiredMark={false}
            scrollToFirstError
            onFinish={(values) => {
                updateFields(values);
            }}
        >
            <Row gutter={30}>
                <Col xs={24} lg={12}>
                    <div className="info-text mb-25">
                        <h4 className="title">
                            {props.profile.firstName} {props.profile.lastName}
                        </h4>
                        <div className="body">
                            <span>{props.role}</span>
                        </div>
                    </div>
                    <Form.Item name="id" label="Id:" initialValue={props.profile.id}>
                        <Input disabled />
                    </Form.Item>
                    <Form.Item
                        name="name"
                        label={t("global.name-surname") + ":"}
                        initialValue={props.profile.firstName + " " + props.profile.lastName}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name="email" label="E-Posta:" initialValue={props.profile.email}>
                        <Input disabled />
                    </Form.Item>
                </Col>
            </Row>

            <Row justify="end">
                <Col>
                    <Button
                        htmlType="submit"
                        type="default"
                        style={{ display: props.updateEnabled ? "block" : "none" }}
                        loading={props.isLoading}
                    >
                        {t("buttons.save")}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
