import { LayoutWrapper } from "@pankod/refine";
import { RoleHelper, Roles } from "helpers/roleHelper";
import React from "react";
import { AdminPaymentList } from "./Components/AdminPaymentList";

interface PaymentListPageProps {}

interface PaymentListPageState {}

class PaymentListPage extends React.Component<PaymentListPageProps, PaymentListPageState> {
    state = {};
    render() {
        return (
            <LayoutWrapper>{(RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root)) && <AdminPaymentList />}</LayoutWrapper>
        );
    }
}

export default PaymentListPage;
