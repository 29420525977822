import { RouteProps } from "react-router-dom";
import { InfluencersListPage } from "./list/InfluencersListPage";
import { InfluencersDetailPage } from "./detail/InfluencersDetailPage";
import { AdminInfluencerPerformance } from "./detail/Components/AdminInfluencer/AdminInfluencerPerformance";
import { InfluencerPayment } from "./payment/InfluencerPayment";
import { AmazonTrackingNumber } from "./AmazonTrackingNumber";
import AdminInfluencerCampaingnPerformance from "./detail/Components/AdminInfluencer/AdminInfluencerCampaignPerformance";
import { AdminInfluencerLinks } from "./detail/Components/AdminInfluencer/AdminInfluencerLinks";
import EditPlatform from "pages/profile/edit/editPlatform";

export const influencersRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: InfluencersListPage,
            path: "/influencers/list",
        },
        {
            exact: true,
            component: AdminInfluencerPerformance,
            path: "/influencers/detail/performance",
        },
        {
            exact: true,
            component: AdminInfluencerPerformance,
            path: "/influencers/detail/performance/:influencerId",
        },
        {
            exact: true,
            component: AdminInfluencerCampaingnPerformance,
            path: "/influencers/detail/performance/:campaignInfluencerId",
        },
        {
            exact: true,
            component: InfluencersDetailPage,
            path: "/influencers/detail/:id",
        },
        {
            exact: true,
            component: InfluencersDetailPage,
            path: "/influencers/edit/:id",
        },
        {
            exact: true,
            component: AdminInfluencerLinks,
            path: "/influencers/detail/:id/links",
        },
        {
            exact: true,
            component: InfluencerPayment,
            path: "/influencers/payment",
        },
        {
            exact: true,
            component: AmazonTrackingNumber,
            path: "/amazontrackingno/list",
        },
        {
            exact: true,
            component: EditPlatform,
            path: "/editPlatform/:id",
        },
    ];
};
