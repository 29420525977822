import { useTranslate } from "@pankod/refine";
import { Row, Table } from "antd";
import { FC, useState } from "react";
import { ReportsService } from "services/services/ReportsService";
import { DateInput, ReportFilter } from "./filter";
import { ReportTab } from "./ReportTab";
import { PopModal } from "components/Modal/PopModal";
import { DemoPie } from "pages/wallet/components/donutChart";
import { useStandardReport } from "./hooks";
import { Money } from "services/models/money";
import { WalletConversion } from "services";

export const DailyReport: FC = () => {
    const [data, onFilter, filter, loading] = useStandardReport<any>({ fetchData: ReportsService.getDailyReport });

    const [showTransformationModal, setShowTransformationModal] = useState<boolean>(false);
    const [dataForPie, setDataForPie] = useState<WalletConversion>();

    const t = useTranslate();

    const handleTransformationModal = (transformations: any) => {
        setDataForPie({
            currency: "",
            clickBalance: transformations.totalClickCount,
            installBalance: transformations.totalInstallCount,
            actionBalance: transformations.totalActionCount,
            sellBalance: transformations.totalSellCount,
            filteredTotal: transformations.filteredTotal,
        });
        setShowTransformationModal(true);
    };

    const columns = [
        {
            title: t("global.date"),
            dataIndex: "date",
            key: "date",
            render: (text: string) => <span>{text.split(" ")[0].replaceAll("/", ".")}</span>,
        },
        {
            title: t("global.inf-count"),
            dataIndex: "influencerCount",
            key: "influencerCount",
        },
        {
            title: t("global.active-inf-count"),
            dataIndex: "activeInfluencerCount",
            key: "activeInfluencerCount",
        },
        {
            title: t("global.active-camp-count"),
            dataIndex: "activeCampaignCount",
            key: "activeCampaignCount",
        },
        {
            title: t("campaign.create.step3.transformationquantity"),
            dataIndex: "transformationsResponse",
            key: "transformationsResponse",
            render: (val: any) => (
                <div
                    onClick={() => {
                        handleTransformationModal(val);
                    }}
                    style={{ color: "#9254de" }}
                >
                    {val?.totalTransformationCount}
                </div>
            ),
        },
        {
            title: t("global.brand-amount"),
            dataIndex: "totalBrandSpending",
            key: "totalBrandSpending",
            render: (val:Number) => <span style={{whiteSpace:"nowrap"}}>{new Money(val).toStringWithCurrencyEnum(3)}</span>
        },
        {
            title: t("global.fenomio-earning"),
            dataIndex: "fenomioEarning",
            key: "fenomioEarning",
            render: (val:Number) => <span style={{whiteSpace:"nowrap"}}>{new Money(val).toStringWithCurrencyEnum(3)}</span>
        },
        {
            title: t("global.fenomen-earning"),
            dataIndex: "totalInfluencerEarning",
            key: "totalInfluencerEarning",
            render: (val:Number) => <span style={{whiteSpace:"nowrap"}}>{new Money(val).toStringWithCurrencyEnum(3)}</span>
        },
    ];

    return (
        <ReportTab downloadAPIService={ReportsService.getDailyReportExport} filter={filter} fileName="daily.xlsx">
            <Row>
                <ReportFilter onFilter={onFilter}>
                    <DateInput />
                </ReportFilter>
            </Row>
            <hr />
            <Table loading={loading} columns={columns} dataSource={data} pagination={false} />
            <PopModal
                isVisible={showTransformationModal}
                title={t("global.transformation")}
                titleLevel={4}
                buttonTextCancel={t("global.giveup")}
                buttonTypeCancel={"primary"}
                onCancel={() => {
                    setShowTransformationModal(false);
                }}
                buttonTypeOK={"default"}
                onOk={() => {
                    setShowTransformationModal(false);
                }}
                content={<>{dataForPie ? <DemoPie detail={dataForPie} /> : null}</>}
            />
        </ReportTab>
    );
};
