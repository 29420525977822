import { CSSProperties } from "react";
import { TitleProps } from "@pankod/refine";
import { Typography } from "antd";

const { Title } = Typography;

export const TitleComponent: React.FC<TitleProps> = ({ collapsed }) => (
    <a href="/">
        {collapsed ? (
            <Title level={3} style={brandingStyles}>
                F
            </Title>
        ) : (
            <Title level={3} style={brandingStyles}>
                Fenomio
            </Title>
        )}
    </a>
);

export const brandingStyles: CSSProperties = {
    textAlign: "center",
    color: "#FFFFFF",
    fontSize: "30px",
    letterSpacing: "-0.04em",
    marginTop: "10px",
};
