import { Row } from "antd";
import { FC } from "react";
import { ReportsService } from "services/services/ReportsService";
import { ReportTab } from "./ReportTab";
import { DateInput, InfluencerInput, ReportFilter } from "./filter";
import { useStandardReport } from "./hooks";
import { toast } from "react-toastify";
import { FilterResponse } from "./filterResponse";

export const AmazonReport: FC = () => {
    const [, onFilter, filter] = useStandardReport({
        fetchData: (values) => ReportsService.getAmazonReports({ ...values, pageSize: 5 }),
        onFilterSuccess: () => toast.success(<FilterResponse />),
    });

    return (
        <ReportTab mailAPIService={ReportsService.getAmazonReportsExport} filter={filter} fileName="amazon-sales.xlsx">
            <Row>
                <ReportFilter onFilter={onFilter}>
                    <InfluencerInput />
                    <DateInput />
                </ReportFilter>
            </Row>
        </ReportTab>
    );
};
