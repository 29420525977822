/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Fenomio_Contract_App_User_CreateUserRequest } from "../models/Fenomio_Contract_App_User_CreateUserRequest";
import type { Fenomio_Contract_App_User_UpdateUserRequest } from "../models/Fenomio_Contract_App_User_UpdateUserRequest";
import type { Fenomio_Contract_App_User_UserSearchView } from "../models/Fenomio_Contract_App_User_UserSearchView";
import type { Filtery_Models_FilteryRequest } from "../models/Filtery_Models_FilteryRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class UsersService {
    /**
     * Delete User
     * @returns any Success
     * @throws ApiError
     */
    public static deleteUsers({
        id,
        acceptLanguage,
    }: {
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "DELETE",
            path: `users/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    /**
     * Get User
     * @returns Fenomio_Contract_App_User_UserSearchView Success
     * @throws ApiError
     */
    public static getUsers({
        id,
        acceptLanguage,
    }: {
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `users/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    /**
     * Update User
     * @returns any Success
     * @throws ApiError
     */
    public static putUsers({
        id,
        acceptLanguage,
        requestBody,
    }: {
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody?: Fenomio_Contract_App_User_UpdateUserRequest;
    }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `users/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    /**
     * Create User
     * @returns any Success
     * @throws ApiError
     */
    public static postUsers({
        acceptLanguage,
        requestBody,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody?: Fenomio_Contract_App_User_CreateUserRequest;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `users`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    /**
     * User Search
     * @returns any Success
     * @throws ApiError
     */
    public static postUsers1({
        acceptLanguage,
        requestBody,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody?: Filtery_Models_FilteryRequest;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `users/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    public static searchUsers({
        acceptLanguage,
        requestBody,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `users/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }
}
