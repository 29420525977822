import { FC } from "react";
import { ReportTab } from "./ReportTab";
import { useTranslate } from "@pankod/refine";
import { useStandardReport } from "./hooks";
import { ReportsService } from "services/services/ReportsService";
import { Row, Col } from "antd";
import { localizationHelper } from "helpers/globalizationHelper";

export const BrandReport: FC = () => {
    const [data] = useStandardReport<any>({ fetchData: ReportsService.getBrandReports });

    const t = useTranslate();

    return (
        <ReportTab
            downloadAPIService={ReportsService.getBrandReportsExport}
            filter={{ acceptLanguage: localizationHelper.getLocale() }}
            fileName="brands.xlsx"
        >
            <Row
                style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "row",
                    width: "90%",
                    justifyContent: "space-between",
                    padding: "20px 0px",
                }}
            >
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>{t("global.brand-count")}</div>
                    <div>{data?.brandCount}</div>
                </Col>
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>Aktif {t("global.brand-count")}</div>
                    <div>{data?.activeBrandCount}</div>
                </Col>
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>İnaktif {t("global.brand-count")}</div>
                    <div>{data?.inActiveBrandCount}</div>
                </Col>
                <Col className="gutter-row">
                    <div style={{ fontWeight: "bold" }}>Never Aktif {t("global.brand-count")}</div>
                    <div>{data?.neverActiveBrandCount}</div>
                </Col>
            </Row>
        </ReportTab>
    );
};
