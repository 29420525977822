import { Modal, ModalProps, Table } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FC } from "react";
import { useTranslate } from "@pankod/refine";
import { EVENTS } from "Constanst";
import { useModal } from "hooks";
import { HistoryState, SocialMediaHistory } from "services/models/socialMedia";
import moment from "moment";

export const PlatformHistoryModal: FC<ModalProps> = (props) => {
    const [open, data, handleClose] = useModal<SocialMediaHistory>(EVENTS.PLATFORM_HISTORY_MODEL_IS_OPENED);

    const t = useTranslate();

    const columns = [
        {
            title: t("global.platform", "Platform"),
            dataIndex: "socialMedia",
            key: "socialMedia",
        },
        {
            title: t("global.Process", "Islem"),
            dataIndex: "state",
            key: "state",
            render: (state: number) => {
                return t(`profile.platform.history.state.${HistoryState[state] || HistoryState[1]}`);
            },
        },
        {
            title: t("global.date"),
            dataIndex: "createdOn",
            key: "createdOn",
            render: (date: string) => {
                return moment(date).format("DD.MM.YYYY");
            },
        },
        {
            title: `${t("profile.platform.accountName", "Hesap Adı")}`,
            dataIndex: "username",
            key: "username",
        },
        {
            title: `${t("profile.platform.followerCount", "Takipçi Sayısı")}`,
            dataIndex: "followers",
            key: "followers",
        },
    ];
    return (
        <Modal
            open={open}
            closeIcon={<CloseOutlined />}
            style={{ height: "fit-content", padding: "2rem 1rem" }}
            onCancel={handleClose}
            footer={null}
            centered
            maskClosable
            closable
            width="fit-content"
            {...props}
        >
            <Table columns={columns} dataSource={data} pagination={false} style={{ margin: "2rem 1rem" }} />
        </Modal>
    );
};
