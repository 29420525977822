import { EVENTS } from "Constanst";
import { localizationHelper } from "helpers/globalizationHelper";
import { Category } from "pages/campaign/Model/GetAllCategoryData";
import { useEffect, useState } from "react";
import { LookupsService } from "services";
import { Country } from "services/models/Lookups";
import { CategoryService } from "services/services/CategoryService";

export const useFilter = () => {
    const [countries, setCountries] = useState<Country[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);

    const doFilter = (values: any) => {
        window.dispatchEvent(new CustomEvent(EVENTS.TABLE_FILTER, { detail: values }));
    };

    useEffect(() => {
        const getCategories = () => {
            CategoryService.getAllCategory({ acceptLanguage: localizationHelper.getLocale() })
                .then((res) => {
                    setCategories(res.data);
                })
                .catch((error) => {});
        };

        const getCountries = () => {
            LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() })
                .then((res) => {
                    setCountries(res.data);
                })
                .catch((error) => {});
        };
        getCountries();
        getCategories();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [countries, categories, doFilter] as const;
};
