import { Steps, StepsProps, useTranslate } from "@pankod/refine";

const { Step } = Steps;

const StepComponent = (props: StepsProps) => {
    const t = useTranslate();
    return (
        <Steps current={props.current}>
            <Step title={t("campaign.create.step1.stepTitle")} />
            <Step title={t("campaign.create.step2.stepTitle")} />
            <Step title={t("campaign.create.step3.stepTitle")} />
            <Step title={t("campaign.create.step4.stepTitle")} />
        </Steps>
    );
};
export default StepComponent;
