// @flow
import { Authenticated, Card, Col, LayoutWrapper, Row, Space, Table, useTranslate } from "@pankod/refine";
import { findSocialMediaById } from "Constanst";
import { PageTitle } from "components/PageTitle/PageTitle";
import { localizationHelper } from "helpers/globalizationHelper";
import * as React from "react";
import { InfluencersService } from "services";
import { withTranslation } from "react-i18next";

type State = {
    influencers: any[];
};

class AdminInfluencerLinksWithTranslation extends React.Component<any, State> {
    constructor(props: any, context: any) {
        super(props, context);

        this.getLinks = this.getLinks.bind(this);
    }

    state = {
        isLoading: false,
        influencers: [],
        id: this.props.match.params.id,
    };

    componentDidMount() {
        this.getLinks(this.state.id);
    }

    getLinks(id: any) {
        InfluencersService.getInfluencerLinks({
            acceptLanguage: localizationHelper.getLocale(),
            body: {
                influencerId: id,
            },
        }).then((resp: any) => {
            this.setState({
                influencers: resp.data.data,
            });
        });
    }

    render() {
        return (
            <Authenticated>
                <LayoutWrapper>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <Card>
                                <PageTitle title={this.props.t("adminInfluencerLinks.title")} content={<>{this.props.t("adminInfluencerLinks.content")}</>} />
                                <br />
                                <Space direction="vertical" size="large" style={{ width: "100%" }}>
                                    <hr style={{ border: "1px solid #0000000F" }} />
                                    <ListComponent dataLoading={this.state.isLoading} data={this.state.influencers} />
                                </Space>
                            </Card>
                        </Col>
                    </Row>
                </LayoutWrapper>
            </Authenticated>
        );
    }
}

export const AdminInfluencerLinks = withTranslation()(AdminInfluencerLinksWithTranslation);

interface ListComponentProps {
    data: any[];
    dataLoading: any;
}

const ListComponent: React.FunctionComponent<ListComponentProps> = (props: ListComponentProps) => {
    const t = useTranslate();
    const columns = [
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">Datapoint ID</span>
                </p>
            ),
            dataIndex: "dataPointId",
            key: "dataPointId",
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">Platform</span>
                </p>
            ),
            dataIndex: "name",
            key: "name",
            render: (val: string, row: any, index: number) => (
                <p style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "small" }}>{findSocialMediaById(Number.parseInt(val))?.name}</span>
                </p>
            ),
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">Clickmeter Link</span>
                </p>
            ),
            dataIndex: "targetLink",
            key: "targetLink",
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">{t('global.targetLink')}</span>
                </p>
            ),
            dataIndex: "freeLink",
            key: "freeLink",
            render: (text: string, row: any, index: number) => (
                <p style={{ textAlign: "center" }}>
                    <span style={{ fontSize: "small" }}>{text}</span>
                </p>
            ),
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">{t('global.campaign')}</span>
                </p>
            ),
            dataIndex: "campaignInfluencer",
            key: "campaignInfluencer",
            render: (data: any, row: any, index: number) => <span style={{ fontSize: "small" }}>{data.campaignId}</span>,
        },
        {
            title: (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">{t("global.createdAt")}</span>
                </p>
            ),
            dataIndex: "createdOn",
            key: "createdOn",
            render: (val: string, row: any, index: number) => (
                <p style={{ textAlign: "center" }}>
                    <span className="table-font">{new Date(val).toLocaleString("tr-TR")}</span>
                </p>
            ),
        },
    ];

    return (
        <>
            <Row>
                <Col span={24}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Table columns={columns} loading={props.dataLoading} dataSource={props.data} scroll={{ x: "100%" }} />
                    </Space>
                </Col>
            </Row>
        </>
    );
};
