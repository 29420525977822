import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "./store";
import { useLocation } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import { EVENTS } from "Constanst";

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export function useQuery() {
    const { search } = useLocation();

    return useMemo(() => new URLSearchParams(search), [search]);
}

export const useModal = <DataType>(event: string = EVENTS.MODAL_IS_OPENED) => {
    const [open, setOpen] = useState(false);
    const [data, setData] = useState<DataType | any>();

    const handleClose = () => setOpen(false);

    useEffect(() => {
        const listener = (event: CustomEvent) => {
            setOpen(true);
            setData(event.detail);
        };

        window.addEventListener(event, listener as EventListener);
        return () => {
            window.removeEventListener(event, listener as EventListener);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [open, data, handleClose] as const;
};
