import { LayoutWrapper, useNavigation } from "@pankod/refine";
import { localizationHelper } from "helpers/globalizationHelper";
import { GetCampaignStateForInfluencer } from "helpers/StateHelpers";
import { useEffect, useState } from "react";
import { CampaignType, EVENTS } from "../../../Constanst";
import UserHelper from "../../../helpers/userHelper";
import { CampaignsService } from "../../../services";
import { ActionCampaign } from "./actionCampaign";
import { AmazonCampaign } from "./amazonCampaign";
import { ClickCampaign } from "./clickCampaign";
import { DownloadCampaign } from "./downloadCampaign";
import { SalesCampaign } from "./salesCampaign";
import dayjs from "dayjs";

export interface MyCampaignDetailProps {
    campaignInfluencer: any;
    campaignDetail: any;
    refresh: any;
    changePlatform: any;
    changeDate: any;
    performance: any;
    showCampaignState: string;
}

export const MyCampaignWrapper = (props: any) => {
    const { push } = useNavigation();

    const campaignInfluencerId = props?.match?.params?.campaignInfluencerId;
    const campaignId = props?.match?.params?.campaignId;

    const [campaignInfluencer, setCampaignInfluencer] = useState<any>();
    const [campaign, setCampaign] = useState<any>();
    const [selectedPlatform, setSelectedPlatform] = useState<any>(null);
    const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
    const [selectedEndDate, setSelectedEndDate] = useState<any>(null);
    const [performanceDetail, setPerformanceDetail] = useState<any>(null);
    const [showCampaignState, setShowCampaignState] = useState<string>("");

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchData = () => {
        if (campaignInfluencerId) {
            CampaignsService.getCampaignInfluencer({
                id: campaignInfluencerId,
                acceptLanguage: localizationHelper.getLocale(),
            })
                .then((resp) => {
                    setCampaignInfluencer(resp.data);
                    CampaignsService.getCampaignDetail({
                        campaignId: resp.data.campaignId,
                        acceptLanguage: localizationHelper.getLocale(),
                    })
                        .then((resp2) => {
                            setCampaign(resp2.data);
                            const getCampaignState = GetCampaignStateForInfluencer(
                                resp2.data.campaignState,
                                resp2.data.campaignInfluencerState
                            );
                            setShowCampaignState(getCampaignState);
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                    const endDate = dayjs();
                    const startDate = dayjs(endDate, "DD-MM-YYYY").add(-7, "days");
                    getPerformance({
                        startDate: startDate,
                        endDate: endDate,
                        platform: selectedPlatform,
                    });
                })
                .catch((error) => {});
        } else if (campaignId) {
            CampaignsService.applyCampaign({
                campaignId,
                influencerId: UserHelper.getId(),
                acceptLanguage: localizationHelper.getLocale(),
            })
                .then((resp) => {
                    push("/campaign-influencer/detail/" + resp.message);
                })
                .catch((error) => {});
        } else {
            push("/list");
        }
    };

    const getPerformance = (body: any) => {
        CampaignsService.getCampaignInfluencerPerformance({
            campaignInfluencerId: campaignInfluencerId,
            body,
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                setPerformanceDetail(resp.data);
            })
            .catch((error) => {});
    };

    const refresh = () => {
        fetchData();
    };

    const changePlatform = (e: any) => {
        window.dispatchEvent(new CustomEvent(EVENTS.INFLUENCER_TRANSACTION_PLATFORM_CHANGED, { detail: e }));
        setSelectedPlatform(e);
        getPerformance({
            startDate: selectedStartDate,
            endDate: selectedEndDate,
            platform: e,
        });
    };

    const changeDate = (e: any) => {
        var endDate = e !== null ? e[1]?.$d : dayjs();
        var startDate = e !== null ? e[0]?.$d : dayjs(endDate, "DD-MM-YYYY").add(-7, "days");

        window.dispatchEvent(new CustomEvent(EVENTS.INFLUENCER_TRANSACTION_DATE_CHANGED, { detail: { endDate, startDate } }));

        setSelectedStartDate(startDate);
        setSelectedEndDate(endDate);

        getPerformance({
            startDate: startDate,
            endDate: endDate,
            platform: selectedPlatform,
        });
    };

    return (
        <>
            {!campaignInfluencer && <LayoutWrapper></LayoutWrapper>}
            {campaignInfluencer && campaignInfluencer.campaignTypeHash === CampaignType.action && (
                <ActionCampaign
                    refresh={refresh}
                    changePlatform={changePlatform}
                    changeDate={changeDate}
                    performance={performanceDetail}
                    campaignDetail={campaign}
                    campaignInfluencer={campaignInfluencer}
                    showCampaignState={showCampaignState}
                />
            )}
            {campaignInfluencer && campaignInfluencer.campaignTypeHash === CampaignType.selling && (
                <SalesCampaign
                    refresh={refresh}
                    changePlatform={changePlatform}
                    changeDate={changeDate}
                    performance={performanceDetail}
                    campaignDetail={campaign}
                    campaignInfluencer={campaignInfluencer}
                    showCampaignState={showCampaignState}
                />
            )}
            {campaignInfluencer && campaignInfluencer.campaignTypeHash === CampaignType.download && (
                <DownloadCampaign
                    refresh={refresh}
                    changePlatform={changePlatform}
                    changeDate={changeDate}
                    performance={performanceDetail}
                    campaignDetail={campaign}
                    campaignInfluencer={campaignInfluencer}
                    showCampaignState={showCampaignState}
                />
            )}
            {campaignInfluencer && campaignInfluencer.campaignTypeHash === CampaignType.clicking && (
                <ClickCampaign
                    refresh={refresh}
                    changePlatform={changePlatform}
                    changeDate={changeDate}
                    performance={performanceDetail}
                    campaignDetail={campaign}
                    campaignInfluencer={campaignInfluencer}
                    showCampaignState={showCampaignState}
                />
            )}
            {campaignInfluencer && campaignInfluencer.campaignTypeHash === CampaignType.amazon && (
                <AmazonCampaign
                    refresh={refresh}
                    changePlatform={changePlatform}
                    changeDate={changeDate}
                    performance={performanceDetail}
                    campaignDetail={campaign}
                    campaignInfluencer={campaignInfluencer}
                    showCampaignState={showCampaignState}
                />
            )}
        </>
    );
};
