import { useTranslate, Form, Row, Col, Avatar } from "@pankod/refine";
import { BrandStatusColors } from "Constanst";
import UserHelper from "helpers/userHelper";
import { IBrand } from "modules/brands/model";
import { Fenomio_Contract_App_Brand_UpdateBrandRequest } from "services";

interface DisplayFormProps {
    brand: IBrand;
}

export const DisplayForm = ({ brand }: DisplayFormProps) => {
    const t = useTranslate();

    return (
        <Form<Fenomio_Contract_App_Brand_UpdateBrandRequest> layout="horizontal" requiredMark={false} scrollToFirstError>
            <Row gutter={[30, 0]}>
                <Col>
                    <Avatar
                        shape="square"
                        size={128}
                        src={UserHelper.getLogoPath() ?? "/images/profile/profile_brand.png"}
                        style={{ marginBottom: "20px" }}
                    />
                </Col>
                <Col>
                    <Col>
                        <h3 style={{ fontWeight: "bold", color: BrandStatusColors[brand?.status - 1] }}>
                            {t(`global.brand.status.${brand?.status}`)}
                        </h3>
                    </Col>
                    <Col style={{ padding: "50px 0px 0px 20px" }}>
                        <h2 style={{ color: "#9254de", fontWeight: "bold" }}>{brand?.name}</h2>
                        <p>
                            {brand?.sector} /{brand?.country}
                        </p>
                        <p>{brand?.recordDate}</p>
                    </Col>
                </Col>
            </Row>
            <hr style={{ borderTop: "1px solid #952AE7" }} />
            <Row>
                <Col span={24}>
                    <Row>
                        <h3 style={{ color: "#9254de", fontWeight: "bold" }}>{t("global.branddescription")}</h3>
                    </Row>
                    <Row>
                        <p>{brand?.workingDetail}</p>
                    </Row>
                    <Row>
                        <h3 style={{ color: "#9254de", fontWeight: "bold" }}>{t("global.detailbrandwork")}</h3>
                    </Row>
                    <Row>
                        <p>{brand?.workingDetail}</p>
                    </Row>
                </Col>
            </Row>
        </Form>
    );
};
