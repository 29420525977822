import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { useLogin, useTranslate, useGetLocale, useNavigation } from "@pankod/refine";
import { Button, Checkbox, Form, Input, Modal } from "antd";
import ConfidentalAgreement from "components/ConfidentalAgreement/ConfidentalAgreement";
import { useState } from "react";
import { Fenomio_Contract_App_User_GetTokenContract } from "services";
import { useAppDispatch } from "../../../hooks";
import { toggleForgotPassword } from "../authSlice";
import "./login.css";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import React from "react";

export const Login = (props: any) => {
    const { push } = useNavigation();
    const [form] = Form.useForm<Fenomio_Contract_App_User_GetTokenContract>();
    const translate = useTranslate();
    const { mutate: login, isLoading } = useLogin<Fenomio_Contract_App_User_GetTokenContract>();
    const [agreementVisible, setagreementVisible] = useState(false);
    const dispatch = useAppDispatch();
    const { executeRecaptcha } = useGoogleReCaptcha();

    const handleReCaptchaVerify = React.useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }
        push("/");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        handleReCaptchaVerify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const locale = useGetLocale()();

    const loginUser = (data: Fenomio_Contract_App_User_GetTokenContract) => {
        login(data);
    };
    return (
        <Form<Fenomio_Contract_App_User_GetTokenContract>
            layout="vertical"
            form={form}
            onFinish={(values) => {
                loginUser(values);
            }}
            requiredMark={false}
            initialValues={{
                remember: false,
            }}
        >
            <Form.Item name="email" 
                rules={[{ required: true, type: "email", message: translate("pages.login.emailAddress") }]} 
                initialValue={(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')?'admin@fenomio.com':''} >
                <Input size="large" autoComplete="email" max={50} placeholder={translate("pages.login.email", "E Posta")} />
            </Form.Item>
            <Form.Item
                name="password"
                initialValue={(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')?'AmYLZxYxzEb8vTCMBJ6T!#':''}
                rules={[
                    { required: true, message: translate("pages.login.ispassword") },
                ]}
            >
                <Input.Password
                    type="password"
                    maxLength={50}
                    placeholder={translate("pages.login.password", "Şifre")}
                    autoComplete="off"
                    size="large"
                    iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                />
            </Form.Item>
            <div>
                <Form.Item name="remember" valuePropName="checked" noStyle>
                    <Checkbox>{translate("pages.login.remember", "Beni Hatırla")}</Checkbox>
                </Form.Item>

                <span
                    className="ant-btn-link clickable-spans"
                    style={{ float: "right", fontSize: "12px", cursor: "pointer" }}
                    onClick={() => {
                        dispatch(toggleForgotPassword(true));
                    }}
                >
                    {translate("pages.login.forgotPassword", "Şifremi Unuttum")}
                </span>
            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyItems: "center",
                }}
            >
                <Button
                    type="default"
                    size="large"
                    htmlType="submit"
                    loading={isLoading}
                    onClick={handleReCaptchaVerify}
                >
                    {translate("pages.login.signin", "Giriş Yap")}
                </Button>
                <br />
                <Button
                    type="link"
                    onClick={() => {
                        setagreementVisible(true);
                    }}
                >
                    {translate("pages.login.confidentialityAgreement", "Gizlilik Sözleşmesi")}
                </Button>
                <br />
                <br />
                <div id="registerOffer">
                    <span>{translate("pages.login.noAccount", "Henüz bir hesabınız yok mu?")}</span>
                    <Button type="link" style={{ paddingLeft: "5px", textDecoration: "underline" }} onClick={() => props.showRegisterTab()}>
                        <span style={{ textDecoration: "underline" }}>{translate("pages.login.signupnow", "Hemen kaydolun.")}</span>
                    </Button>
                </div>
            </div>
            <Modal
                visible={agreementVisible}
                okButtonProps={{ hidden: true }}
                cancelButtonProps={{ hidden: true }}
                onCancel={() => {
                    setagreementVisible(false);
                }}
                footer={null}
                width={800}
            >
                <ConfidentalAgreement lang={locale} />
            </Modal>
        </Form>
    );
};
