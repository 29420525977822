import { useState, useEffect } from "react";
import { Button, Grid, Layout, Menu } from "antd";
import { AmazonOutlined, LogoutOutlined, MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

import { useLogout, useMenu, useTranslate } from "@pankod/refine";
import { antLayoutSider, antLayoutSiderMobile } from "./styles";
import UserHelper from "../../helpers/userHelper";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { Link } from "react-router-dom";

const { SubMenu } = Menu;

export const Sider: React.FC = () => {
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [currentPath, setCurrentPath] = useState<any>([]);
    const { mutate: logout } = useLogout();
    const { menuItems, selectedKey } = useMenu();
    const breakpoint = Grid.useBreakpoint();
    const isMobile = !breakpoint.lg;
    const userRole = UserHelper.getRole();
    const t = useTranslate();

    useEffect(() => {
        getCurrentPath();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    const selectedPath = selectedKey.split("/");

    //to keep the menu tabs open
    const getCurrentPath = () => {
        if (selectedPath[1] && selectedPath[1] !== "profile" && selectedPath[1] !== "wallet") {
            if (selectedPath[1] === "campaign" && selectedPath[2] === "create" && userRole !== Roles.Admin && userRole !== Roles.Root) {
                setCurrentPath(["/" + selectedPath[1] + "/" + selectedPath[2] + "/" + selectedPath[3]]);
            } else {
                setCurrentPath(["/" + selectedPath[1] + "/list"]);
            }
        } else {
            setCurrentPath([selectedKey]);
        }
    };

    return (
        <Layout.Sider
            collapsed={collapsed}
            onCollapse={(collapsed: boolean): void => setCollapsed(collapsed)}
            collapsedWidth={isMobile ? 0 : 100}
            breakpoint="lg"
            style={{
                ...(isMobile ? antLayoutSiderMobile : antLayoutSider),
                width: "300px",
            }}
            width={250}
            theme="light"
            trigger={
                <div style={{ height: "50px" }}>
                    <MenuFoldOutlined />
                </div>
            }
        >
            <Menu
                //to keep the sub-menu tabs open
                defaultOpenKeys={
                    selectedPath[1] === "amazontrackingno" || selectedPath[1] === "influencers"
                        ? ["influencer"]
                        : selectedPath[1] === "profile"
                        ? ["profile"]
                        : selectedPath[1] === "users" || selectedPath[1] === "logs" || selectedPath[1] === "sectors"
                        ? ["root"]
                        : [""]
                }
                selectedKeys={currentPath}
                defaultSelectedKeys={currentPath}
                mode="inline"
                onClick={({ key }) => {
                    if (key === "logout") {
                        logout();
                        return;
                    }
                    if (!breakpoint.lg) {
                        setCollapsed(true);
                    }
                    //push(key as string);
                }}
            >
                {menuItems.map(({ icon, label, route }) => {
                    let isSelected = route === selectedKey;
                    return (
                        <Menu.Item className="menu-font-color" key={route} icon={icon}>
                            <Link to={route}>
                                {label}
                                {!collapsed && isSelected}
                            </Link>
                        </Menu.Item>
                    );
                })}
                {/* ProfileMenu */}
                {RoleHelper.isInRole(Roles.Root) || RoleHelper.isInRole(Roles.Admin) ? (
                    <SubMenu
                        className="menu-font-color"
                        key="influencer"
                        icon={<img alt="icon" src="/images/menu-icon/influencers.svg" />}
                        title={t("global.phenomenon")}
                    >
                        <Menu.Item
                            className="menu-font-color"
                            icon={<img alt="icon" src="/images/menu-icon/influencers.svg" />}
                            key="/influencers/list"
                        >
                            <Link to={"/influencers/list"}>{t("global.phenomenons")}</Link>
                        </Menu.Item>
                        <Menu.Item className="menu-font-color" icon={<AmazonOutlined />} key="/amazontrackingno/list">
                            <Link to="/amazontrackingno/list">{t("global.amazon-tracking-no")}</Link>
                        </Menu.Item>
                    </SubMenu>
                ) : null}
                {RoleHelper.isInRole(Roles.Root) ? (
                    <SubMenu
                        className="menu-font-color"
                        key="root"
                        icon={<img alt="icon" src="/images/menu-icon/profile.svg" />}
                        title="Yönetim Paneli"
                    >
                        <Menu.Item className="menu-font-color" key="/users/list">
                            <Link to="/users/list">{t("global.users")}</Link>
                        </Menu.Item>
                        <Menu.Item className="menu-font-color" key="/logs/list">
                            <Link to="/logs/list">{t("global.logs")}</Link>
                        </Menu.Item>
                        <Menu.Item className="menu-font-color" key="/sectors/list">
                            <Link to="/sectors/list">{t("global.sectors")}</Link>
                        </Menu.Item>
                    </SubMenu>
                ) : null}
                {/* ProfileMenu */}
                <SubMenu
                    className="menu-font-color"
                    key="profile"
                    icon={<img alt="icon" src="/images/menu-icon/profile.svg" />}
                    title={t("global.my-profile")}
                >
                    <Menu.Item className="menu-font-color" key="/profile/detail">
                        <Link to="/profile/detail">{t("global.account-overview")}</Link>
                    </Menu.Item>
                    <Menu.Item className="menu-font-color" key="/profile/edit">
                        <Link to="/profile/edit">{t("global.profile-edit")}</Link>
                    </Menu.Item>
                    <Menu.Item className="menu-font-color" key="/profile/changePassword">
                        <Link to="/profile/changePassword">{t("global.password-change")}</Link>
                    </Menu.Item>
                    {userRole !== Roles.Admin && userRole !== Roles.Root && (
                        <Menu.Item className="menu-font-color" key="/profile/receipts">
                            <Link to="/profile/receipts">{t("global.reciepts")}</Link>
                        </Menu.Item>
                    )}
                    <Button
                        id="logoutButtonNormal"
                        onClick={() => logout()}
                        style={{
                            width: "100%",
                            textAlign: "left",
                            height: "50px",
                            paddingLeft: "24px",
                        }}
                        type="link"
                        block
                    >
                        <img alt="icon" src="/images/menu-icon/logout.svg" style={{ margin: "10px" }}></img>{" "}
                        {!collapsed ? t("buttons.logout") : t("buttons.exit")}
                    </Button>
                </SubMenu>
            </Menu>
            <div
                id="logotButton"
                className="ant-layout-sider-trigger"
                style={{
                    position: "fixed",
                    bottom: "50px",
                    width: "250px",
                    background: "transparent",
                }}
            >
                <Button
                    id="logoutButtonCollapsed"
                    onClick={() => logout()}
                    style={{
                        width: "100%",
                        display: "none",
                        color: "white",
                        height: "50px",
                        textAlign: "center",
                    }}
                    type="link"
                    block
                >
                    <LogoutOutlined />
                </Button>
                <Button onClick={toggle} className="trigger mr-150 ">
                    {" "}
                    {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}{" "}
                </Button>
            </div>
        </Layout.Sider>
    );
};
