import { Card, Col, Row, Space, useTranslate } from "@pankod/refine";
import { PageTitle } from "components/PageTitle/PageTitle";
import { FunctionComponent } from "react";
import { Filter } from "./Filter";
import { List } from "./List";
import { useAdminBrandList } from "./hooks/useAdminBrandList";

export const AdminBrandList: FunctionComponent = () => {
    const t = useTranslate();
    const [dataLoading, data, pagination, handleTableChange, applyFilter] = useAdminBrandList();
    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Card>
                    <PageTitle title={t("global.brands", "Markalar")} content={<>Buraya yardım içeriği gelecek</>} />
                    <br />
                    <Space direction="vertical" size="large" style={{ width: "100%" }}>
                        <Filter count={pagination.total} applyFilter={applyFilter} />
                        <hr style={{ border: "1px solid #0000000F" }} />
                        <List pagination={pagination} dataLoading={dataLoading} onPageChange={handleTableChange} data={data} />
                    </Space>
                </Card>
            </Col>
        </Row>
    );
};
