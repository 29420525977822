import { FC, memo } from "react";
import { useFilter } from "./useFilter";
import { Button, Col, Form, Input, Row, Select, Space, useTranslate, DatePicker } from "@pankod/refine";
import { GetCampaignStates } from "helpers/StateHelpers";
import { localizationHelper } from "helpers/globalizationHelper";

const { RangePicker } = DatePicker;

export const Filter: FC = memo(() => {
    const [countries, categories, doFilter] = useFilter();

    const t = useTranslate();

    return (
        <Row>
            <Col span={24}>
                <Form
                    name="basic"
                    layout="vertical"
                    initialValues={{ remember: true }}
                    onFinish={async (values) => {
                        doFilter(values);
                    }}
                    autoComplete="off"
                >
                    <Space direction="vertical" style={{ width: "100%" }}>
                        <Row gutter={[40, 0]}>
                            <Col xs={24} sm={12} md={6} span={6}>
                                <Form.Item label={t("global.campaign-name")} name="campaignName">
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} span={6}>
                                <Form.Item label={t("pages.brand.statu")} name="status">
                                    <Select allowClear options={GetCampaignStates()} />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} span={6}>
                                <Form.Item label={t("global.country")} name="countryIds">
                                    <Select
                                        allowClear
                                        mode={"tags"}
                                        options={countries?.map((country) => {
                                            return {
                                                label: country.text,
                                                value: country.id,
                                            };
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} span={6}>
                                <Form.Item label={t("global.date")} name="date">
                                    <RangePicker locale={localizationHelper.getLocaleForComponent()} style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: 20 }} gutter={[40, 0]}>
                            <Col xs={24} sm={12} md={6} span={6}>
                                <Form.Item label={t("campaign.create.step2.categoriesLabel")} name="categoryIds">
                                    <Select
                                        style={{ width: "100%" }}
                                        allowClear
                                        mode={"multiple"}
                                        options={categories?.map((category) => {
                                            return {
                                                label: category.name,
                                                value: category.id,
                                            };
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12} md={6} span={6}></Col>
                            <Col xs={24} sm={24} md={12} span={12} style={{ paddingTop: "28px" }}>
                                <Row justify="end" align="bottom">
                                    <Button htmlType="submit" type="ghost">
                                        {t("buttons.filter")}
                                    </Button>
                                </Row>
                            </Col>
                        </Row>
                    </Space>
                </Form>
            </Col>
        </Row>
    );
});
