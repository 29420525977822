import { PaginationConstant } from "Constanst";
import { localizationHelper } from "helpers/globalizationHelper";
import UserHelper from "helpers/userHelper";
import { useEffect, useState } from "react";
import { BrandsService } from "services";
import { Pagination } from "services/core/Pagination";
import { BrandReceipt } from "services/models/Receipt";

export const useBrandReceipt = () => {
    const [loading, setLoading] = useState(true);
    const [receipts, setReceipts] = useState<BrandReceipt[]>([]);
    const [pagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });

    useEffect(() => {
        const getReceipts = async () => {
            setLoading(true);

            BrandsService.getReceipts({
                id: UserHelper.getId(),
                acceptLanguage: localizationHelper.getLocale(),
            })
                .then((response) => {
                    setReceipts((response.data as BrandReceipt[]) || []);
                })
                .finally(() => setLoading(false));
        };

        getReceipts();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [loading, receipts, pagination] as const;
};
