import { useTranslate } from "@pankod/refine";
import { FunctionComponent, useEffect, useState } from "react";
import { Table } from "antd";
import { PaginationConstant, SocialMedias } from "../../../../../Constanst";
import { InfluencersService } from "../../../../../services";
import { Pagination } from "../../../../../services/core/Pagination";
import { localizationHelper } from "helpers/globalizationHelper";

interface AdminInfluencerWaitingApprovementProps {
    influencer: any;
    refresh: any;
}

export const AdminInfluencerWaitingApprovement: FunctionComponent<AdminInfluencerWaitingApprovementProps> = (
    props: AdminInfluencerWaitingApprovementProps
) => {
    const t = useTranslate();
    const [dataLoading, setDataLoading] = useState(false);
    //const [, setCurrentPage] = useState(PaginationConstant.initialPage);
    const [data, setData] = useState<any[]>([]);
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize
    });
    const columns = [
        {
            title: t("global.platform"),
            dataIndex: "socialMedia",
            render: (text: string) => {
                if (text === SocialMedias.instagram.name) {
                    return <img alt={"social"} src="/images/social-media-icon/INSTAGRAM36Px.svg" />;
                } else if (text === SocialMedias.twitter.name) {
                    return <img alt={"social"} src="/images/social-media-icon/TWITTER36Px.svg" />;
                } else if (text === SocialMedias.youtube.name) {
                    return <img alt={"social"} src="/images/social-media-icon/YOUTUBE36Px.svg" />;
                } else if (text === SocialMedias.tiktok.name) {
                    return <img alt={"social"} src="/images/social-media-icon/TIKTOK36Px.svg" />;
                } else if (text === SocialMedias.twitch.name) {
                    return <img alt={"social"} src="/images/social-media-icon/TWITCH36Px.svg" />;
                } else if (text === SocialMedias.telegram.name) {
                    return <img alt={"social"} src="/images/social-media-icon/TELEGRAM36Px.svg" />;
                } else if (text === SocialMedias.discord.name) {
                    return <img alt={"social"} src="/images/social-media-icon/DISCORD36Px.svg" />;
                }
            },
        },
        {
            title: "Link",
            dataIndex: "link",
            render: (text: string, row: any) => {
                return (
                    <a href={text} target="_blank" rel="noreferrer">
                        @{row.username}
                    </a>
                );
            },
        },
        {
            title: t("global.oldfollowerscount"),
            dataIndex: "followers",
        },
        {
            title: t("global.newfollowerscount"),
            dataIndex: "pendingApprovalFollowers",
        },
        {
            title: t("global.action"),
            dataIndex: "reject",
            render: (text: string, row: any) => (
                <>
                    <span className="clickable-span" style={{ marginRight: "5px" }} onClick={() => approve(row)}>
                        {t("global.accept", "Kabul Et")}
                    </span>
                    <span className="clickable-span" onClick={() => reject(row)}>
                        {t("global.reject", "Reddet")}
                    </span>
                </>
            ),
        },
    ];

    useEffect(() => {
        if (props.influencer && props.influencer && props.influencer?.socialMedias) {
            setData((props.influencer.socialMedias as any[])?.filter((ism) => !ism.isApproved));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.influencer]);

    const approve = (row: any) => {
        setDataLoading(true);
        InfluencersService.approveInfluencerSocialMedias({ id: row.id, acceptLanguage: localizationHelper.getLocale() }).then((resp) => {
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount
            });
            setDataLoading(false);
            props.refresh(props.influencer.id);
        });
    };

    const reject = (row: any) => {
        setDataLoading(true);
        InfluencersService.rejectInfluencerSocialMedias({ id: row.id, acceptLanguage: localizationHelper.getLocale() }).then((resp) => {
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount
            });
            setDataLoading(false);
            props.refresh(props.influencer.id);
        });
    };

    return (
        <>
            <h2 style={{ color: "#952AE7", fontWeight: "bold" }}>{t("campaign.create.step2.changespendingapproval")}</h2>
            <div>
                <Table columns={columns} dataSource={data} pagination={false} size="middle" loading={dataLoading} />
            </div>
        </>
    );
};
