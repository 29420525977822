export function base64ToByteArray(base64: any) {
    let binaryString = window.atob(base64);
    let len = binaryString.length;

    let bytes = new Uint8Array(len);

    for (let i = 0; i < len; i++) {
        bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
}
