import { AuthProvider } from "@pankod/refine";
import { localizationHelper } from "helpers/globalizationHelper";
import { persistentStorageHelper } from "helpers/persistentStorageHelper";
import { storageHelper } from "helpers/storageHelper";
import UserHelper from "helpers/userHelper";
import { LoginService } from "services";
import * as Constants from "../Constanst";

export const authProvider: AuthProvider = {
    login: async ({ email, password, remember }) => {
        return await LoginService.login({
            acceptLanguage: localizationHelper.getLocale(),
            requestBody: {
                email: email,
                password: password,
                rememberMe: remember,
            },
        })
            .then((response: any) => {
                if (response.status === "SUCCESS") {
                    persistentStorageHelper.setItem(Constants.REMEMBER_ME_KEY, remember);
                    if(response.data.missingAgreements && response.data.missingAgreements.length>0){
                        const event = new CustomEvent("needSign", { detail: response.data });
                        document.dispatchEvent(event);
                        return Promise.reject();
                    }
                    storageHelper.setItem(Constants.TOKEN_KEY, response.data.accessToken);
                    storageHelper.setItem(Constants.REFRESH_TOKEN_KEY, response.data.refreshToken);
                    storageHelper.setItem(Constants.REFRESH_TOKEN_EXPIRE_KEY, response.data.refreshTokenExpireDate);
                    const event = new CustomEvent("updateLogin", { detail: "Example of an event" });

                    document.dispatchEvent(event);
                    return Promise.resolve();
                }
                return Promise.reject();
            })
            .catch((error) => {
                if(!error){
                    return Promise.reject();
                }
                let message = error.body.message;
                return Promise.reject(message);
            });
    },
    logout: () => {
        storageHelper.removeItem(Constants.TOKEN_KEY);
        storageHelper.removeItem(Constants.UPDATED_PHOTO);
        storageHelper.removeItem(Constants.REFRESH_TOKEN_KEY);
        storageHelper.removeItem(Constants.REFRESH_TOKEN_EXPIRE_KEY);
        storageHelper.setItem(Constants.REMEMBER_ME_KEY, false);
        return Promise.resolve();
    },
    checkError: async () => {
        if (await UserHelper.needsRefreshToken()) {
            await UserHelper.refreshToken();
            return Promise.resolve();
        }
        return Promise.reject({ redirectPath: "/login" });
    },
    checkAuth: async (params: any) => {
        const token = storageHelper.getItem(Constants.TOKEN_KEY);

        if (token) {
            return Promise.resolve();
        }
        return Promise.reject({ redirectPath: "/login" });
    },
    getPermissions: async () => {
        const role = UserHelper.getRole();
        if (role) {
            return Promise.resolve(role);
        } else {
            return Promise.reject();
        }
    },
    getUserIdentity: async () => {
        try {
            const response = UserHelper.getProfile();
            return await Promise.resolve(response);
        } catch (error) {
            return await Promise.reject();
        }
    },
};
