import { useEffect, useState } from "react";
import { message, Upload } from "@pankod/refine";
import { BrandsService, InfluencersService } from "../../../services";
import { localizationHelper } from "../../../helpers/globalizationHelper";
import UserHelper from "../../../helpers/userHelper";
import { Roles } from "../../../helpers/roleHelper";
import { storageHelper } from "../../../helpers/storageHelper";
import * as Constants from "../../../Constanst";

const Avatar = (props: any) => {
    const [, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const userRole = UserHelper.getRole();

    useEffect(() => {
        setLoading(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageUrl]);

    const beforeUpload = (file: any) => {
        const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
        if (!isJpgOrPng) {
            message.error("You can only upload JPG/PNG file!");
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error("Image must smaller than 2MB!");
        }
        return isJpgOrPng && isLt2M;
    };

    const handleChange = (info: any) => {
        if (info.file.status === "uploading") {
            setLoading(true);
            return;
        }
        if (info.file.status === "done") {
            // Get this url from response in real world.
        }
    };

    const updatePhoto = (options: any) => {
        const { file } = options;
        const fmData = new FormData();
        fmData.append("file", file);

        if (userRole === Roles.Influencer) {
            InfluencersService.uploadPhoto({ body: fmData, acceptLanguage: localizationHelper.getLocale() })
                .then((response) => {
                    storageHelper.setItem(Constants.UPDATED_PHOTO, response.data);
                    setImageUrl(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else if (userRole === Roles.Brand) {
            BrandsService.uploadPhoto({ body: fmData, acceptLanguage: localizationHelper.getLocale() })
                .then((response) => {
                    storageHelper.setItem(Constants.UPDATED_PHOTO, response.data);
                    setImageUrl(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }

        return;
    };

    return (
        <div>
            <Upload
                name="avatar"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                action={imageUrl}
                customRequest={updatePhoto}
            >
                {UserHelper.getLogoPath() ? <img src={UserHelper.getLogoPath()} alt="avatar" style={{ width: "100%" }} /> : <></>}
            </Upload>
        </div>
    );
};

export default Avatar;
