import i18n from "providers/i18n";

//eslint-disable-next-line no-extend-native
String.prototype.truncate = function (n: number, useWordBoundary: boolean): String {
    let current = String(this);
    if (current.length <= n) {
        return current;
    }
    const substring = current.slice(0, n - 1);
    return (useWordBoundary ? substring.slice(0, substring.lastIndexOf(" ")) : substring) + "...";
};

//eslint-disable-next-line no-extend-native
String.prototype.capitalizeEachWord = function (): String {
    let current = String(this);
    return current
        .split(" ")
        .map((x) => x.charAt(0).toUpperCase() + x.slice(1).toLowerCase())
        .join(" ");
};

//eslint-disable-next-line no-extend-native
String.prototype.toRemoveEmptyCharacters = function (): String {
    let current = String(this);
    return current.split(" ").join("");
};

//eslint-disable-next-line no-extend-native
String.prototype.getLocaleText = function (): string {
    let current = String(this);
    return i18n.t(current);
};

export {};
