import { EVENTS } from "Constanst";
import { MultiplePaymentApprovementDetail } from "components/PaymentPageModals/MultiplePaymentApprovement/Component";
import { PaymentsService } from "services/services/PaymentsService";

export const usePaymentActions = () => {
    function showPaymentConfirmModal({ payments, influencerList }: { influencerList: string[]; payments: any[] }) {
        const selectedPaymentAmount =
            payments
                ?.filter((payment) => influencerList.includes(payment?.id))
                .reduce((result, current) => {
                    return result + (current?.paymentBalanceOnMonthDetails?.netPayable || 0);
                }, 0) || 0;

        const detail: MultiplePaymentApprovementDetail = { influencerList, selectedPaymentAmount };
        window.dispatchEvent(new CustomEvent(EVENTS.PAYMENT_MULTIPLE_APPROVEMENT_MODAL_IS_OPENED, { detail }));
    }

    function handleFailedPayments(selectedInfluencerList: any[]) {
        if (selectedInfluencerList.length > 0)
            PaymentsService.paymentFailed({
                body: {
                    influencerIds: selectedInfluencerList,
                },
            });
    }
    return [showPaymentConfirmModal, handleFailedPayments] as const;
};
