import { Button, DatePicker, Form, Input, Space, useTranslate } from "@pankod/refine";
import { Col, Row } from "antd";
import dayjs from "dayjs";
import { localizationHelper } from "helpers/globalizationHelper";
import { ICampaignData } from "pages/campaign/Model/CampaignList";
import { FunctionComponent, useEffect, useState } from "react";
import { Money } from "services/models/money";

export interface IDownloadCampaignStep3Data {
    startDate: string | null;
    endDate: string | null;
    totalAmount: number | null;
    influencerTotalAmount: string | null;
    minimumInfluencerCount: string | null;
    amountByClick: string | null;
    amountByClickFenomioEarning: string | null;
    amountByClickInfluencerEarning: string | null;
    amountByInfluencer: string | null;
}

interface DownloadCampaignStep3Props {
    campaignData: ICampaignData;
    formFinished: (campaignData: IDownloadCampaignStep3Data) => void;
    back: any;
    isEditPage: boolean;
}

export const DownloadCampaignStep3: FunctionComponent<DownloadCampaignStep3Props> = (props: DownloadCampaignStep3Props) => {
    const t = useTranslate();

    const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
    const [form] = Form.useForm();
    const [marj, setMarj] = useState("...");

    const colStyle = {
        marginLeft: "10px",
        marginRight: "10px",
    };

    useEffect(() => {
        if (props.campaignData) {
            form.resetFields();
            calculateAmountByClickInfluencerEarning();
            calculateAmountByInfluencer();
            getMarj();
            setSelectedStartDate(dayjs(props.campaignData.startDate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.campaignData]);

    const disabledStartDate = (current: any) => {
        return current < dayjs().add(-1, "day");
    };

    const disabledEndDate = (current: any) => {
        if (selectedStartDate) {
            return current < selectedStartDate.add(1, "day");
        }
        return false;
    };

    const getMarj = () => {
        if (
            form.getFieldValue("totalAmount") &&
            +form.getFieldValue("totalAmount") > 0 &&
            form.getFieldValue("influencerTotalAmount") &&
            +form.getFieldValue("influencerTotalAmount")
        ) {
            const totalAmount = +form.getFieldValue("totalAmount");
            const totalInfAmount = +form.getFieldValue("influencerTotalAmount");
            setMarj(((100 * (totalAmount - totalInfAmount)) / totalAmount).toString());
        } else {
            setMarj("...");
        }
    };

    const calculateAmountByInfluencer = () => {
        if (form.getFieldValue("influencerTotalAmount") && form.getFieldValue("minimumInfluencerCount")) {
            form.setFieldsValue({
                amountByInfluencer: new Money(+form.getFieldValue("influencerTotalAmount") / form.getFieldValue("minimumInfluencerCount")),
            });
        }
    };

    const calculateAmountByClickInfluencerEarning = () => {
        if (form.getFieldValue("amountByClick") && form.getFieldValue("influencerTotalAmount") && form.getFieldValue("totalAmount")) {
            form.setFieldsValue({
                amountByClickInfluencerEarning: new Money(
                    +form.getFieldValue("amountByClick") * (form.getFieldValue("influencerTotalAmount") / form.getFieldValue("totalAmount"))
                ),
            });
        }
    };

    return (
        <Form
            layout="vertical"
            labelAlign={"right"}
            form={form}
            onFinish={(values) => {
                let data = { ...props.campaignData, ...values };
                props.formFinished(data);
            }}
            onValuesChange={(values) => {
                getMarj();
            }}
        >
            <Row>
                <Col style={colStyle} xs={24} sm={24} md={5} span={5}>
                    <Form.Item
                        label={t("global.start-date")}
                        name="startDate"
                        rules={[
                            {
                                required: true,
                                message: t("global.please-start-date"),
                            },
                        ]}
                        initialValue={props.campaignData.startDate !== undefined ? dayjs(props.campaignData.startDate) : undefined}
                    >
                        <DatePicker
                            locale={localizationHelper.getLocaleForComponent()}
                            disabled={props.isEditPage}
                            onChange={(c) => {
                                setSelectedStartDate(c);
                                form.setFieldsValue({ endDate: null });
                            }}
                            disabledDate={disabledStartDate}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("global.end-date")}
                        name="endDate"
                        initialValue={props.campaignData.endDate !== undefined ? dayjs(props.campaignData.endDate) : undefined}
                    >
                        <DatePicker
                            locale={localizationHelper.getLocaleForComponent()}
                            disabledDate={disabledEndDate}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                </Col>
                <Col style={colStyle} xs={24} sm={24} md={5} span={5}>
                    <Form.Item
                        label={t("global.total-budget")}
                        name="totalAmount"
                        rules={[
                            {
                                required: true,
                                message: t("global.please-total-budget"),
                            },
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value < 0 ? cb(t("global.please-positiveAmount")) : cb();
                                },
                            },
                        ]}
                        initialValue={props.campaignData.totalAmount}
                    >
                        <Input
                            disabled={props.isEditPage}
                            onChange={() => calculateAmountByClickInfluencerEarning()}
                            step={0.1}
                            type={"number"}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("campaign.create.step3.influencerTotalAmountLabel")}
                        name="influencerTotalAmount"
                        rules={[
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value > +form.getFieldValue("totalAmount")
                                        ? cb(t("campaign.create.step3.totalAmountCondition"))
                                        : cb();
                                },
                            },
                            {
                                required: true,
                                message: t("campaign.create.step3.please-influencerTotalAmountLabel"),
                            },
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value < 0 ? cb(t("global.please-positive")) : cb();
                                },
                            },
                        ]}
                        initialValue={props.campaignData.influencerTotalAmount}
                    >
                        <Input
                            onChange={(e) => {
                                calculateAmountByClickInfluencerEarning();
                                calculateAmountByInfluencer();
                            }}
                            disabled={props.isEditPage}
                            step={0.1}
                            type={"number"}
                        />
                    </Form.Item>
                </Col>
                <Col style={colStyle} xs={24} sm={24} md={5} span={5}>
                    <Form.Item
                        label={t("campaign.create.step3.minimumInfluencerCountLabel")}
                        rules={[
                            {
                                required: true,
                                message: t("campaign.create.step3.please-minimumInfluencerCountLabel"),
                            },
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value < 0 ? cb(t("global.please-positive")) : cb();
                                },
                            },
                        ]}
                        name="minimumInfluencerCount"
                        initialValue={props.campaignData.minimumInfluencerCount}
                    >
                        <Input disabled={props.isEditPage} onChange={(e) => calculateAmountByInfluencer()} step={0.1} type={"number"} />
                    </Form.Item>
                    <Form.Item
                        label={t("campaign.create.step3.amountByInstallLabel")}
                        name="amountByClick"
                        rules={[
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value > +form.getFieldValue("totalAmount")
                                        ? cb(t("campaign.create.step3.totalAmountCondition"))
                                        : cb();
                                },
                            },
                            {
                                required: true,
                                message: t("campaign.create.step3.amountByClickRule"),
                            },
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value < 0 ? cb(t("global.please-positive")) : cb();
                                },
                            },
                        ]}
                        initialValue={props.campaignData.amountByClick}
                    >
                        <Input
                            disabled={props.isEditPage}
                            onChange={(e) => calculateAmountByClickInfluencerEarning()}
                            step={0.1}
                            type={"number"}
                        />
                    </Form.Item>
                </Col>
                <Col style={colStyle} xs={24} sm={24} md={5} span={5}>
                    <Form.Item
                        label={t("campaign.create.step3.amountByInstallInfluencerEarningLabel")}
                        name="amountByClickInfluencerEarning"
                        rules={[
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value > +form.getFieldValue("influencerTotalAmount")
                                        ? cb(t("pages.phenomenon.condition-total-budget"))
                                        : cb();
                                },
                            },
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value < 0 ? cb(t("global.please-positive")) : cb();
                                },
                            },
                        ]}
                        initialValue={props.campaignData.amountByClickInfluencerEarning}
                    >
                        <Input disabled={true} type={"number"} />
                    </Form.Item>
                    <Form.Item
                        label={t("campaign.create.step3.amountByInfluencerLabel")}
                        name="amountByInfluencer"
                        rules={[
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value < 0 ? cb(t("global.please-positive")) : cb();
                                },
                            },
                        ]}
                        initialValue={props.campaignData.amountByInfluencer}
                        extra={t("campaign.create.step3.amountByInfluencerExtra", { profitMargin: marj })}
                    >
                        <Input disabled={true} type={"number"} />
                    </Form.Item>
                </Col>
            </Row>

            <Form.Item>
                <Space direction="horizontal" style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button onClick={() => props.back()} style={{ marginLeft: "auto" }} type="primary">
                        {t("buttons.back")}
                    </Button>
                    <Button style={{ marginLeft: "auto" }} type="default" htmlType="submit">
                        {t("global.continue")}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
