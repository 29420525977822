import { EVENTS } from "Constanst";
import { useModal } from "hooks";
import { useEffect, useState } from "react";
import { CampaignsService } from "services";
import { PaymentsService } from "services/services/PaymentsService";

export type RefundOrBonusBody = {
    campaignId: string;
    amount: string;
    description: string;
};

export const usePaymentRefundOrBonus = <T>() => {
    const [campaigns, setCampaigns] = useState<any[]>([]);
    const [loading, setLoading] = useState(false);
    const [open, detail, handleClose] = useModal<T>(EVENTS.PAYMENT_REFUND_OR_BONUS_MODAL_IS_OPENED);

    function handleSubmit({ campaignId, amount, description }: RefundOrBonusBody, type: "bonus" | "refund") {
        if (campaignId && amount && description) {
            const body = {
                campaignId: campaignId,
                amount: amount,
                description: description,
                influencerId: detail.id,
            };

            setLoading(true);

            if (type === "refund") {
                PaymentsService.createRefund({
                    body,
                }).finally(() => {
                    setLoading(false);
                    handleClose();
                });
            } else {
                PaymentsService.createBonus({
                    body,
                }).finally(() => {
                    setLoading(false);
                    handleClose();
                });
            }
        }
    }
    useEffect(() => {
        if (!detail?.id) return;

        const request = CampaignsService.getCampaignInfluencers({
            body: {
                influencerId: detail.id,
                pageNumber: 0,
                pageSize: 9999,
                state: 1,
            },
        });

        request.then((resp) => {
            setCampaigns(resp?.data?.data || []);
        });

        return () => {
            request.cancel();
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [detail?.id]);

    return [open, detail as T, handleClose, campaigns, handleSubmit, loading] as const;
};
