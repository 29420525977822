import { Modal, Typography } from "@pankod/refine";
import { ButtonTypes } from "components/fenomio-antd-props";
const { Title } = Typography;

interface IPopModalProps {
    title?: string;
    titleLevel?: 1 | 2 | 3 | 4 | 5;
    content: JSX.Element;
    isVisible: boolean;

    buttonTextCancel?: string;
    buttonTypeCancel?: ButtonTypes;
    onCancel?: any;

    onOk?: any;
    buttonTextOK?: string;
    buttonTypeOK?: ButtonTypes;

    footer?: any;
}

export const PopModal = (props: IPopModalProps) => {
    return (
        <>
            <Modal
                title={<Title level={props.titleLevel}>{props.title}</Title>}
                visible={props.isVisible}
                cancelText={props.buttonTextCancel}
                cancelButtonProps={{ type: props.buttonTypeCancel }}
                okText={props.buttonTextOK}
                okType={props.buttonTypeOK}
                onCancel={props.onCancel}
                onOk={props.onOk}
                footer={props.footer}
                closable
            >
                {props.content}
            </Modal>
        </>
    );
};
