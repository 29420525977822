import { Col, Row, Select, DatePicker, Input, useTranslate } from "@pankod/refine";
import { GetPlatforms } from "helpers/StateHelpers";
import { localizationHelper } from "helpers/globalizationHelper";
import { FC } from "react";

const { RangePicker } = DatePicker;

type FilterProps = {
    changeDate: (value: any) => void;
    changeName: (value: string) => void;
    changePlatform: (value: any) => void;
    selectedDates: any;
    hasPlatformFilter?: boolean;
};

export const Filter: FC<FilterProps> = ({ changeDate, changePlatform, changeName, selectedDates, hasPlatformFilter = true }) => {
    const t = useTranslate();
    return (
        <Row gutter={[16, 16]} justify="end">
            <Col xs={24} sm={8} md={6} span={6}>
                <Input
                    style={{ width: "100%" }}
                    placeholder={t("global.phenomenon", "Fenomen")}
                    onChange={(e) => changeName(e.target.value)}
                    allowClear
                />
            </Col>
            {hasPlatformFilter && (
                <Col xs={24} sm={8} md={6} span={6}>
                    <Select
                        style={{ width: "100%" }}
                        placeholder={t("global.platforms")}
                        allowClear
                        onChange={changePlatform}
                        options={GetPlatforms()}
                    />
                </Col>
            )}

            <Col xs={24} sm={8} md={6} span={6}>
                <RangePicker
                    locale={localizationHelper.getLocaleForComponent()}
                    onChange={(e) => changeDate(e)}
                    style={{ width: "100%" }}
                    value={selectedDates}
                />
            </Col>
        </Row>
    );
};
