import ReportsPage from "./ReportsPage";
import { RouteProps } from "react-router-dom";

export const reportsRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: ReportsPage,
            path: "/reports/list",
        },
    ];
};
