import { localizationHelper } from "helpers/globalizationHelper";
import { useEffect, useState } from "react";
import { Fenomio_Common_BaseModels_Api_DetailItemDto, LookupsService } from "services";

export const useFilter = () => {
    const [countries, setCountries] = useState<Fenomio_Common_BaseModels_Api_DetailItemDto[]>([]);
    const [sectors, setSectors] = useState<any[]>([]);

    useEffect(() => {
        getCountries();
        getSectors();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCountries = () => {
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
        });
    };

    const getSectors = () => {
        LookupsService.getSectors({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setSectors(res.data ?? []);
        });
    };
    return [countries, sectors] as const;
};
