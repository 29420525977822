import { Authenticated, Button, Input, LayoutWrapper, Select, useNavigation, useTranslate } from "@pankod/refine";
import { Card, Col, Form, Row, Space, Table } from "antd";
import "./categories.css";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { FunctionComponent, useEffect, useState } from "react";
import { Pagination } from "../../services/core/Pagination";
import { PaginationConstant } from "../../Constanst";
import { CategoryService } from "../../services/services/CategoryService";
import { localizationHelper } from "../../helpers/globalizationHelper";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export const CategoryListPage = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>();
    const t = useTranslate();

    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) loadData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const loadData = () => {
        setDataLoading(true);
        CategoryService.searchCategory({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data?.data);
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount
            });
            setSearch(false);
        });
    };

    const prepareFilterRequest = () => {
        const categorySearch: any = {};
        if (filterValues) {
            categorySearch.name = filterValues.category ?? null;
            categorySearch.IsPublic = filterValues.status ?? null;
            categorySearch.pageNumber = pagination.current;
            categorySearch.pageSize = pagination.pageSize;
        } else {
            categorySearch.name = null;
            categorySearch.IsPublic = null;
            categorySearch.pageNumber = pagination.current;
            categorySearch.pageSize = pagination.pageSize;
        }

        return categorySearch;
    };

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total
        });
        setSearch(true);
    };

    const doFilter = (values: any) => {
        setPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize
        });
        setFilterValues(values);
        setSearch(true);
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Card id="categoriesListCard">
                            <PageTitle
                                content={
                                    <div>
                                        <p>Content</p>
                                        <p>Content</p>
                                    </div>
                                }
                                title={t("campaign.create.step2.categoriesLabel")}
                            />
                            <FilterCategoryComponent doFilter={doFilter} pagination={pagination} dataLoading={dataLoading} />
                            <br />
                            <br />
                            <CategoryListComponent
                                pagination={pagination}
                                dataLoading={dataLoading}
                                onPageChange={handleTableChange}
                                data={data}
                            />
                        </Card>
                    </Col>
                </Row>
            </LayoutWrapper>
        </Authenticated>
    );
};

interface FilterComponentProps {
    doFilter: any;
    pagination: any;
    dataLoading: any;
}

const FilterCategoryComponent: FunctionComponent<FilterComponentProps> = (props) => {
    const statuses = [
        { value: "true", label: "Herkes Görebilir" },
        { value: "false", label: "Sadece Adminler Görebilir" },
    ];
    const [category] = useState(false);
    const { push } = useNavigation();
    const t = useTranslate();

    const createCategory = () => {
        push("/category/create", category);
    };

    const filter = (values: any) => {
        props.doFilter(values);
    };

    return (
        <>
            <br />
            <Form
                name="basic"
                initialValues={{ remember: true }}
                layout={"vertical"}
                onFinish={async (values) => {
                    filter(values);
                }}
                autoComplete="off"
                requiredMark={false}
            >
                <Row style={{ display: "flex", justifyContent: "space-between" }}>
                    <Col xs={24} sm={24} md={11} span={11}>
                        <Form.Item
                            label={t("global.category")}
                            name="category"
                            rules={[{ required: false, message: "Lütfen kategori adını giriniz!" }]}
                        >
                            <Input placeholder={t("global.categoryNamee")} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={11} span={11}>
                        <Form.Item name="status" label={t("global.status")} rules={[{ required: false }]}>
                            <Select placeholder={t("global.categoryStatus")} options={statuses} allowClear></Select>
                        </Form.Item>
                    </Col>
                    <Col style={{ display: "flex", justifyContent: "end" }} span={24}>
                        <Form.Item style={{ marginRight: "25px" }}>
                            <Button type="ghost" htmlType="submit">
                                {t("buttons.filter")}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
                <Row style={{ justifyContent: "space-between" }}>
                    <Button onClick={() => createCategory()} type="default" className="mt-25 mb-25">
                        {t("global.categoryAdd")}
                    </Button>
                </Row>
            </Form>
            <Row>
                <p>
                    {props.pagination.total} {t("global.categoryListed")}{" "}
                </p>
            </Row>
        </>
    );
};

interface ListComponentProps {
    data: any[];
    onPageChange: any;
    pagination: any;
    dataLoading: any;
}

export const CategoryListComponent: FunctionComponent<ListComponentProps> = (props) => {
    const { push } = useNavigation();
    const t = useTranslate();

    const showCategoryDetail = (categoryId: string) => {
        CategoryService.getCategoryById({
            id: categoryId,
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((category) => {
                console.log(category);
                push("/category/detail", category.data);
            })
            .catch(() => {
                toast.error(t("Servisten veri çekilemedi"));
            });
    };

    const columns = [
        {
            title: <span className="table-font">{t("global.category")}</span>,
            dataIndex: "name",
            key: "name",
            render: (text: string, row: any, index: number) => (
                <Button
                    type="link"
                    onClick={() => {
                        console.log("just click");
                        console.log(row);
                        showCategoryDetail(row.id);
                    }}
                >
                    {text}
                </Button>
            ),
        },
        {
            title: <span className="table-font">{t("global.influencerCount")}</span>,
            dataIndex: "influencerCount",
            key: "influencerCount",
            render: (text: string, row: any) => (
                <Link to={"/influencers/list?categoryId=" + row.id}>
                    <span className="clickable-span">
                        {text}
                    </span>
                </Link>
            ),
        },
        {
            title: <span className="table-font">{t("global.campaignCount")}</span>,
            dataIndex: "campaignCount",
            key: "campaignCount",
            render: (text: string, row: any) => (
                <Link to={"/campaign/list?categoryId=" + row.id}>
                    <span className="clickable-span">
                        {text}
                    </span>
                </Link>
            ),
        },
        {
            title: <span className="table-font">{t("pages.brand.statu")}</span>,
            dataIndex: "isPublic",
            render: (text: string, row: any, index: number) => (
                <Space size="middle">{text ? <span>{t("global.allViews")}</span> : <span>{t("global.onlyAdmin")}</span>}</Space>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            pagination={props.pagination}
            dataSource={props.data}
            loading={props.dataLoading}
            onChange={(page) => props.onPageChange(page)}
            scroll={{ x: "100%" }}
        />
    );
};
