import { RouteProps } from "react-router-dom";
import { Notifications } from "./Notifications";

export const notificationRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: Notifications,
            path: "/notifications/list",
        },
    ];
};
