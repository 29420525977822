import { EditFilled, InboxOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popover, Space, Tag, useNavigation, useTranslate } from "@pankod/refine";
import { CampaignStatesColors, CampaignType, PaginationConstant } from "Constanst";
import { GetCampaignStateText } from "helpers/StateHelpers";
import { localizationHelper } from "helpers/globalizationHelper";
import { useEffect, useMemo, useState } from "react";
import { CampaignsService } from "services";
import { Pagination } from "services/core/Pagination";
import { Money } from "services/models/money";

export const useCampaignListForm = (brandId: string) => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [campaigns, setCampaigns] = useState<any>();
    const [search, setSearch] = useState<boolean>(true);

    const { push } = useNavigation();

    const t = useTranslate();

    useEffect(() => {
        if (brandId || search) {
            getCampaigns();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, brandId]);

    const getCampaigns = () => {
        setDataLoading(true);
        CampaignsService.searchCampaigns({
            body: {
                pageNumber: pagination.current,
                pageSize: pagination.pageSize,
                brandIds: [brandId],
            },
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                setDataLoading(false);
                setCampaigns(resp?.data.data);
                setPagination({
                    current: pagination.current,
                    pageSize: pagination.pageSize,
                    total: resp?.data?.pageInfo.totalItemCount,
                });
                setSearch(false);
            })
            .catch((error) => console.error(error));
    };

    const showCampaignDetail = (campaign: any) => {
        push("/campaign/detail", campaign);
    };

    const columns = useMemo(
        () => [
            {
                title: <span className="table-font">Id</span>,
                dataIndex: "id",
                key: "id",
                render: (text: string, row: any, index: number) => (
                    <Button
                        type="link"
                        onClick={() => {
                            showCampaignDetail(row);
                        }}
                    >
                        #{text}
                    </Button>
                ),
            },
            {
                title: <span className="table-font">{t("campaign.create.step1.campaignNameLabel")}</span>,
                dataIndex: "name",
                key: "name",
                render: (text: string, row: any, index: number) => (
                    <Button
                        type="link"
                        onClick={() => {
                            showCampaignDetail(row);
                        }}
                    >
                        {text}
                    </Button>
                ),
            },
            {
                title: <span className="table-font">{t("campaign.create.step1.campaigntype")}</span>,
                dataIndex: "type",
                key: "type",
                render: (text: string, row: any, index: number) => {
                    if (row.campaignType !== CampaignType.selling) {
                        return <span>{text}</span>;
                    } else {
                        return (
                            <Popover
                                placement="bottomRight"
                                content={
                                    <Space direction="vertical" size="small">
                                        <p>{t("global.actionDescription")}</p>
                                        <p>{t("global.actionDescription")}</p>
                                    </Space>
                                }
                            >
                                {text}
                                <QuestionCircleOutlined />
                            </Popover>
                        );
                    }
                },
            },
            {
                title: <span className="table-font">{t("global.start")}</span>,
                dataIndex: "startDate",
                key: "startDate",
            },
            {
                title: <span className="table-font">{t("global.end")}</span>,
                dataIndex: "endDate",
                key: "endDate",
            },
            {
                title: <span className="table-font">{t("global.unit-price")}</span>,
                dataIndex: "unitPrice",
                key: "unitPrice",
                render: (unitPrice: string, row: any, index: number) => {
                    if (row.typeHash !== CampaignType.selling) {
                        return <span>{unitPrice ? new Money(row.unitPrice).toStringWithCurrency(row.currency) : "-"}</span>;
                    }

                    return (
                        <Popover
                            placement="bottomRight"
                            content={
                                <Space direction="vertical" size="small">
                                    <p>{t("global.earnings-rates")}</p>
                                    <p>{t("global.earnings-rates")}</p>
                                </Space>
                            }
                        >
                            <Button type="link" onClick={() => {}}>
                                <span>
                                    {t("global.earnings-rates")} <QuestionCircleOutlined />
                                </span>
                            </Button>
                        </Popover>
                    );
                },
            },
            {
                title: <span className="table-font">{t("global.total-budget")}</span>,
                dataIndex: "budget",
                key: "budget",
                render: (budget: number, row: any) => <span>{new Money(budget).toStringWithCurrency(row.currency)}</span>,
            },
            {
                title: <span className="table-font">{t("global.transformation")}</span>,
                dataIndex: "transformation",
                key: "transformation",
            },
            {
                title: <span className="table-font">{t("global.category")}</span>,
                dataIndex: "categories",
                key: "categories",
                render: (text: string, row: any, index: number) => <span>{(row.categories as any[]).map((c) => c.name).join(" - ")}</span>,
            },
            {
                title: <span className="table-font">{t("global.status")}</span>,
                dataIndex: "campaignStateModel",
                key: "campaignStateModel",
                render: ({ campaignState }: any) => {
                    return <Tag color={CampaignStatesColors[campaignState].toString()}>{GetCampaignStateText(campaignState)}</Tag>;
                },
            },
            {
                title: "",
                render: () => (
                    <div>
                        <InboxOutlined style={{ color: "#952AE7", fontSize: "22px" }} />
                    </div>
                ),
            },
            {
                title: "",
                render: () => (
                    <div>
                        <EditFilled style={{ color: "#952AE7", fontSize: "22px" }} />
                    </div>
                ),
            },
        ],
        //eslint-disable-next-line
        []
    );
    return [dataLoading, campaigns, pagination, columns, showCampaignDetail] as const;
};
