import { Refine } from "@pankod/refine";
import routerProvider from "@pankod/refine-react-router";
import simpleRestDataProvider from "@pankod/refine-simple-rest";
import "@pankod/refine/dist/styles.min.css";
import ApplicationRoutes from "ApplicationRoutes";
import { Footer, Header, Layout, OffLayoutArea, Sider, TitleComponent } from "components";
import { persistentStorageHelper } from "helpers/persistentStorageHelper";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { authProvider } from "providers/authProvider";
import { useTranslation } from "react-i18next";
import { AuthPage } from "./pages/auth/authPage";
import "./styles/app.scss";
import { useEffect, useState } from "react";
import { AgreementsService } from "services/services/AgreementsService";
import { localizationHelper } from "helpers/globalizationHelper";
import { useAtom } from "jotai";
import { latestAgreementsAtom } from "providers/atoms";

function App() {
    const { t, i18n } = useTranslation();
    const [menuList, setMenuList] = useState<any>();
    const [, setAgreements] = useAtom(latestAgreementsAtom);

    useEffect(() => {
        setMenuList(getMenu());
        AgreementsService.getLatest({
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setAgreements(resp.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    window.document.addEventListener("updateLogin", function (e) {
        setMenuList(getMenu());
    });

    const API_URL = process.env.REACT_APP_API_URL + "";
    const dataProvider = simpleRestDataProvider(API_URL);

    const i18nProvider = {
        translate: (key: string, params: object) => t(key, params),
        changeLocale: (lang: string) => i18n.changeLanguage(lang),
        getLocale: () => i18n.language,
    };

    const adminList = [
        {
            name: "dashboard",
            options: { label: "Ana Sayfa", route: "" },
            icon: <img src="/images/menu-icon/homepage.svg" alt="menu" />,
        },
        {
            name: "campaignList",
            options: { label: t("global.campaigns"), route: "campaign/list" },
            icon: <img src="/images/menu-icon/campaigns.svg" alt="menu" />,
        },
        {
            name: "brands",
            options: { label: `${t("global.brands")}`, route: "brands/list" },
            icon: <img src="/images/menu-icon/brands.svg" alt="menu" />,
        },
        {
            name: "payments",
            options: { label: t("global.payments"), route: "payment/list" },
            icon: <img src="/images/menu-icon/payments.svg" alt="menu" />,
        },
        {
            name: "reports",
            options: { label: t("global.reports"), route: "reports/list" },
            icon: <img src="/images/menu-icon/reports.svg" alt="menu" />,
        },
        {
            name: "categories",
            options: { label: t("campaign.create.step2.categoriesLabel"), route: "category/list" },
            icon: <img src="/images/menu-icon/categories.svg" alt="menu" />,
        },
    ];

    const brandList = [
        {
            name: "dashboard",
            options: { label: t("dashboard.title"), route: "" },
            icon: <img src="/images/menu-icon/homepage.svg" alt="menu" />,
        },
        {
            name: "campaign",
            options: { label: t("campaign.create.pageTitle"), route: "campaign/create/apply" },
            icon: <img src="/images/menu-icon/campaign-create.svg" alt="menu" />,
        },
        {
            name: "campaignList",
            options: { label: t("global.myCampaign"), route: "campaign/list" },
            icon: <img src="/images/menu-icon/campaigns.svg" alt="menu" />,
        },
        {
            name: "influencers",
            options: { label: `${t("global.phenomenons")}`, route: "influencers/list" },
            icon: <img src="/images/menu-icon/influencers.svg" alt="menu" />,
        },
    ];

    const influencerList = [
        {
            name: "dashboard",
            options: { label: t("dashboard.title"), route: "" },
            icon: <img src="/images/menu-icon/homepage.svg" alt="menu" />,
        },
        {
            name: "campaignList",
            options: { label: t("global.campaigns"), route: "campaign/list" },
            icon: <img src="/images/menu-icon/campaigns.svg" alt="menu" />,
        },
        {
            name: "myCampaignList",
            options: { label: t("global.myCampaign"), route: "campaign-influencer/list" },
            icon: <img src="/images/menu-icon/my-campaign.svg" alt="menu" />,
        },
        {
            name: "wallet",
            options: { label: t("global.wallet"), route: "wallet" },
            icon: <img src="/images/menu-icon/wallet.svg" alt="menu" />,
        },
    ];

    const getMenu = () => {
        if (RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root)) {
            return adminList;
        } else if (RoleHelper.isInRole(Roles.Brand)) {
            return brandList;
        } else if (RoleHelper.isInRole(Roles.Influencer)) {
            return influencerList;
        } else {
            //TODO: Burası neden var, çözmek lazım. Buna gerek olmayabilir.
            if (
                !window.location.pathname.endsWith("/login") &&
                !window.location.pathname.endsWith("/tr/") &&
                !window.location.pathname.endsWith("/en/") &&
                !window.location.pathname.endsWith("/es/") &&
                !window.location.pathname.includes("/activation") &&
                !window.location.pathname.includes("/reset-password")
            ) {
                window.open("/tr/login", "_self");
            }

            return influencerList;
        }
    };

    const { RouterComponent } = routerProvider;
    const CustomRouterComponent = () => <RouterComponent basename={"/" + persistentStorageHelper.getItem("i18nextLng")} />;
    const CustomReadyPage = () => (
        <div style={{ position: "fixed", top: 0, left: 0, background: "white", width: "100vw", height: "100vh" }}> Ready </div>
    );
    return (
        <Refine
            routerProvider={{
                ...routerProvider,
                RouterComponent: CustomRouterComponent,
                routes: ApplicationRoutes,
            }}
            authProvider={authProvider}
            dataProvider={dataProvider}
            Title={TitleComponent}
            Header={Header}
            Sider={Sider}
            Footer={Footer}
            Layout={Layout}
            ReadyPage={CustomReadyPage}
            OffLayoutArea={OffLayoutArea}
            i18nProvider={i18nProvider}
            LoginPage={AuthPage}
            resources={menuList}
        ></Refine>
    );
}

export default App;
