import UserHelper from "./userHelper";

export enum Roles {
    Influencer = "Influencer",
    Admin = "Admin",
    Brand = "Brand",
    Root = "Root",
}

export class RoleHelper {
    static isInRole(role: Roles): boolean {
        return role === UserHelper.getRole();
    }
    static getRoleLocale(): string {
        const userRole = UserHelper.getRole();
        return userRole.toLowerCase();
    }
}
