import { useTranslate } from "@pankod/refine";
import { EVENTS } from "Constanst";
import { base64ToByteArray, downloadFile } from "helpers";
import { FC, useEffect } from "react";
import { toast } from "react-toastify";

interface Filter {
    startDate?: any;
    endDate?: any;
    acceptLanguage?: any;
    [key: string]: any;
}

export interface ReportTabProps {
    downloadAPIService?: (filter: Filter) => Promise<any>;
    mailAPIService?: (filter: Filter) => Promise<any>;
    filter: Filter;
    fileName: string;
}

export const ReportTab: FC<ReportTabProps> = ({ children, downloadAPIService, mailAPIService, filter, fileName }) => {
    const t = useTranslate();
    useEffect(() => {
        const listener = () => {
            // call API Service if it exist
            if (downloadAPIService)
                downloadAPIService(filter).then((response) => {
                    const bytes = base64ToByteArray(response);
                    downloadFile(bytes, fileName);
                });
            else if (mailAPIService)
                mailAPIService(filter).then(() => {
                    toast.success(t("reports.mailSending.message", "The reports will be sent by e-mail"));
                });
        };
        window.addEventListener(EVENTS.EXPORT_BUTTON_CLICKED, listener);
        return () => {
            // remove event listener
            window.removeEventListener(EVENTS.EXPORT_BUTTON_CLICKED, listener);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter]);

    return <div>{children}</div>;
};
