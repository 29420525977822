import { InfoCircleOutlined } from "@ant-design/icons";
import { LayoutWrapper, useGetIdentity, useNavigation, useTranslate } from "@pankod/refine";
import { Button, Card, Col, Popover, Row, Typography } from "antd";
import { ProfileModel } from "pages/profile/models";
import { useState } from "react";
import { CampaignsService } from "../../../services";
import { localizationHelper } from "../../../helpers/globalizationHelper";

const { Title, Paragraph } = Typography;

export const CreateByFenomioPage = () => {
    const [createByFenomioTriggered, setCreateByFenomioTriggered] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const t = useTranslate();

    const triggerReguest = () => {
        setLoading(true);

        CampaignsService.CampaignManagementRequest({ acceptLanguage: localizationHelper.getLocale() })
            .then(() => {
                setLoading(false);
                setCreateByFenomioTriggered(true);
            })
            .catch();
    };

    return (
        <LayoutWrapper>
            <Row gutter={[16, 16]}>
                <Col span={24} xs={24} lg={24} xl={16}>
                    <Card>
                        <Row gutter={[8, 8]} className="ant-card-header">
                            <Col span={23}>Kampanyamı Fenomio Yönetsin</Col>
                            <Col span={1}>
                                <Popover
                                    content={
                                        <div>
                                            <p>Content</p>
                                            <p>Content</p>
                                        </div>
                                    }
                                    placement="bottomRight"
                                    title={t("global.selfServis")}
                                    trigger="hover"
                                >
                                    <InfoCircleOutlined />
                                </Popover>
                            </Col>
                        </Row>

                        <br />
                        {!createByFenomioTriggered ? (
                            <ValidateContactComponent triggerReguest={triggerReguest} isloading={isLoading} />
                        ) : (
                            <ExecuteContactComponent />
                        )}
                    </Card>
                </Col>
            </Row>
        </LayoutWrapper>
    );
};

interface ValidateContactComponentProps {
    triggerReguest: () => void;
    isloading: boolean;
}

export const ValidateContactComponent = (prop: ValidateContactComponentProps) => {
    const t = useTranslate();
    const { data: user } = useGetIdentity<ProfileModel>();

    return (
        <div style={{ display: "flex", placeContent: "center", flexDirection: "column", alignItems: "flex-start" }}>
            <Paragraph>{t("global.selfServis-description")}</Paragraph>
            <br />

            <Row style={{ width: "100%" }}>
                <Col xs={24} sm={24} md={8} span={8}>
                    <Title level={4}>{t("pages.brand.brandRepresentative")}</Title>
                    <span>{user?.delegateName}</span>
                </Col>
                <Col xs={24} sm={24} md={8} span={8}>
                    <Title level={4}>{t("global.contact-number")}</Title>
                    <span>{user?.phone}</span>
                </Col>
                <Col xs={24} sm={24} md={8} span={8}>
                    <Title level={4}>{t("pages.brand.email")}</Title>
                    <span>{user?.email}</span>
                </Col>
            </Row>

            <br />
            <br />
            <Button
                type="default"
                style={{ margin: "0 auto" }}
                loading={prop.isloading}
                onClick={() => prop.triggerReguest()}
            >
                {t("global.accept", "Kabul Et")}
            </Button>
        </div>
    );
};

export const ExecuteContactComponent = () => {
    const { data: user } = useGetIdentity<ProfileModel>();
    const { push } = useNavigation();
    const t = useTranslate();

    return (
        <div style={{ display: "flex", placeContent: "center", flexDirection: "column", alignItems: "flex-start" }}>
            <Paragraph>
                <Title level={5} style={{ marginBottom: "10px" }}>
                    {t("global.self-service-request")}
                </Title>
                {t("global.selfServis-description")}{" "}
            </Paragraph>

            <Row style={{ width: "100%" }}>
                <Col xs={24} sm={24} md={8} span={8}>
                    <Title level={4}>{t("pages.brand.brandRepresentative")}</Title>
                    <span>{user?.delegateName}</span>
                </Col>
                <Col xs={24} sm={24} md={8} span={8}>
                    <Title level={4}>{t("global.contact-number")}</Title>
                    <span>{user?.phone}</span>
                </Col>
                <Col xs={24} sm={24} md={8} span={8}>
                    <Title level={4}>{t("pages.brand.email")}</Title>
                    <span>{user?.email}</span>
                </Col>
            </Row>
            <br />
            <br />
            <Button onClick={() => push("/campaign/list")} type="default" style={{ margin: "0 auto" }}>
                {t("global.goToCampaign")}
            </Button>
        </div>
    );
};
