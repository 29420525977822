import { useTranslate } from "@pankod/refine";
import { Form, Space, Row, Col, Button } from "antd";
import { FC } from "react";
import { usePaymentActions } from "./usePaymentActions";
import { DownloadOutlined } from "@ant-design/icons";

type PaymentActionsProps = {
    selectedInfluencerList: any[];
    handleExport: () => void;
    payments: any[];
};

export const PaymentActions: FC<PaymentActionsProps> = ({ selectedInfluencerList, handleExport, payments }) => {
    const [showPaymentConfirmModal, handleFailedPayments] = usePaymentActions();
    const t = useTranslate();

    return (
        <Form name="basic" layout="inline" initialValues={{ remember: true }} autoComplete="off">
            <Row justify="space-between" style={{ width: "100%", marginBottom: "30px", marginTop: "30px" }}>
                <Space>
                    <Col xs={24} sm={24} md={5} style={{ marginTop: "5px" }}>
                        <Button
                            disabled={selectedInfluencerList.length === 0}
                            onClick={() => handleFailedPayments(selectedInfluencerList)}
                            type="default"
                        >
                            {" "}
                            Ödeme Yapılamadı{" "}
                        </Button>
                    </Col>
                    <Col xs={24} sm={24} md={5} style={{ marginTop: "5px" }}>
                        <Button
                            disabled={selectedInfluencerList.length === 0}
                            type="ghost"
                            onClick={() => {
                                showPaymentConfirmModal({ influencerList: selectedInfluencerList, payments });
                            }}
                        >
                            {t("global.payment-confirmation")}
                        </Button>
                    </Col>
                </Space>

                <Col style={{ marginTop: "5px" }}>
                    <Button
                        style={{
                            backgroundColor: "#BF0964",
                            fontSize: 16,
                            cursor: "pointer",
                        }}
                        type="default"
                        onClick={() => handleExport()}
                    >
                        Download payment info <DownloadOutlined style={{ fontSize: "24px" }} />
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
