import { Authenticated, LayoutWrapper } from "@pankod/refine";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { FunctionComponent } from "react";
import { AdminBrandDetail } from "./AdminBrandDetail";
import { InfluencerBrandDetail } from "./InfluencerBrandDetail";

export const BrandDetail: FunctionComponent<any> = (props: any) => {
    return (
        <Authenticated>
            <LayoutWrapper>
                {(RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root)) && <AdminBrandDetail />}
                {RoleHelper.isInRole(Roles.Influencer) && <InfluencerBrandDetail />}
            </LayoutWrapper>
        </Authenticated>
    );
};
