import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover, Select, useGetIdentity, useTranslate } from "@pankod/refine";
import { Button, Card, Col, Form, Input, Modal, Row } from "antd";
import { ProfileConstants } from "Constanst";
import { localizationHelper } from "helpers/globalizationHelper";
import UserHelper from "helpers/userHelper";
import { useEffect, useState } from "react";
import {
    BrandsService,
    Fenomio_Contract_App_Brand_UpdateBrandRequest,
    LookupsService,
    Fenomio_Common_BaseModels_Api_DetailItemDto,
} from "services";
import { ProfileModel } from "./models";
import Avatar from "./edit/editProfilePic";
import ProfileImg from "components/microparts/profile-img";
import { Roles } from "helpers/roleHelper";
import allCountries from "../../helpers/Countries";

const { TextArea } = Input;

export const BrandProfilePage = () => {
    const [updateEnabled, setUpdateEnabled] = useState(false);
    const translate = useTranslate();
    const [isLoading, setLoading] = useState(false);
    const t = useTranslate();

    useEffect(() => {
        const pathname = window.location.pathname;
        UserHelper.getProfile().then();
        if (pathname.includes("edit")) {
            setUpdateEnabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Row>
            <Col span={24} xs={24} lg={24} xl={16}>
                <Card id="editFormCard">
                    <Row gutter={[8, 8]} className="ant-card-header">
                        <Col span={23}>{updateEnabled ? t("global.profile-edit") : t("global.account-overview")}</Col>
                        <Col span={1}>
                            <Popover
                                content={
                                    <div>
                                        <p>Content</p>
                                        <p>Content</p>
                                    </div>
                                }
                                placement="bottomRight"
                                title={updateEnabled ? t("global.profile-edit") : t("global.account-overview")}
                                trigger="hover"
                            >
                                <InfoCircleOutlined />
                            </Popover>
                        </Col>
                    </Row>

                    {updateEnabled ? (
                        <UpdateForm
                            setLoading={setLoading}
                            setUpdateEnabled={setUpdateEnabled}
                            isLoading={isLoading}
                            updateEnabled={updateEnabled}
                        />
                    ) : (
                        <DisplayForm />
                    )}

                    <Row justify={"end"}>
                        <Col>
                            <Button onClick={() => setUpdateEnabled(true)} style={{ display: updateEnabled ? "none" : "block" }}>
                                {translate("global.profile-edit")}
                            </Button>
                        </Col>
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};

export const DisplayForm = () => {
    const { data: user } = useGetIdentity<ProfileModel>();
    const t = useTranslate();

    return (
        <Form<Fenomio_Contract_App_Brand_UpdateBrandRequest> layout="horizontal" requiredMark={false} scrollToFirstError>
            <Row justify="start" align="bottom">
                <Col flex="150px">
                    <ProfileImg width={150} type={Roles.Brand} />
                </Col>
                <Col flex="auto">
                    <div className="brand-hero">
                        <h3 className="title">{user?.name}</h3>
                        <div className="sub-title">{user?.sector}</div>
                        <div className="sub-title">{user?.createdOn}</div>
                    </div>
                </Col>
            </Row>
            <br />
            <hr />
            <br />
            <Row>
                <Col xs={24} lg={12}>
                    <div className="info-text mb-15">
                        <h4 className="title">{t("global.brandrepresentative")}</h4>
                        <div className="body">
                            <span>{user?.delegateName}</span>
                        </div>
                    </div>

                    <div className="info-text mb-15">
                        <h4 className="title">{t("global.contact-number")}</h4>
                        <div className="body">
                            <span>{user?.phone}</span>
                        </div>
                    </div>

                    <div className="info-text mb-15">
                        <h4 className="title">{t("global.contact-email")}</h4>
                        <div className="body">
                            <span>{user?.email}</span>
                        </div>
                    </div>
                </Col>
                <Col xs={24} lg={12}>
                    <div className="info-text mb-15">
                        <h4 className="title">{t("global.branddescription")}</h4>
                        <div className="body">{user?.detail}</div>
                    </div>

                    <div className="info-text mb-15">
                        <h4 className="title">{t("global.detailbrandwork")}</h4>
                        <div className="body">
                            <span>{user?.workingDetail}</span>
                        </div>
                    </div>
                </Col>
            </Row>
        </Form>
    );
};

interface UpdateFormProps {
    isLoading: boolean;
    updateEnabled: boolean;
    setLoading: (loading: boolean) => void;
    setUpdateEnabled: (enabled: boolean) => void;
}

export const UpdateForm = (props: UpdateFormProps) => {
    const translate = useTranslate();
    const { data: user } = useGetIdentity<ProfileModel>();
    const [profileForm] = Form.useForm<Fenomio_Contract_App_Brand_UpdateBrandRequest>();
    const [countries, setCountries] = useState<Fenomio_Common_BaseModels_Api_DetailItemDto[]>([]);
    const [sectors, setSectors] = useState<any[]>([]);
    const t = useTranslate();
    const [selectedPhoneCode, setSelectedPhoneCode] = useState("90");
    const { Option } = Select;

    const PrefixSelector = (
        <Select
            value={selectedPhoneCode}
            onChange={(e: any) => {
                setSelectedPhoneCode(e);
            }}
            style={{
                width: 72,
            }}
        >
            {allCountries.map((country) => {
                return <Option value={country.dialCode}>+{country.dialCode}</Option>;
            })}
        </Select>
    );

    const getCountries = async () => {
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
        });
    };

    useEffect(() => {
        LookupsService.getSectors({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setSectors(res.data ?? []);
            getCountries();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (user) {
            profileForm.resetFields();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profileForm, user]);

    function updateFields(fields: any) {
        profileForm.validateFields().then((values) => {
            props.setLoading(true);
            BrandsService.updateBrand({
                id: UserHelper.getId(),
                acceptLanguage: localizationHelper.getLocale(),
                requestBody: {
                    name: values.name,
                    detail: values.detail,
                    workDetail: values.workDetail,
                    sectorId: values.sector,
                    delegateName: values.delegateName,
                    phone: values.phone,
                },
            })
                .then(function (response) {
                    props.setLoading(false);
                    if (response.status === "SUCCESS") {
                        Modal.success({
                            title: t("notifications.success"),
                            content: t("notifications.edit-success"),
                        });
                        props.setUpdateEnabled(false);
                    }
                })
                .catch((error) => {
                    props.setLoading(false);
                    if (error.body) {
                        let validationError = error.body;

                        let message = "";
                        if (validationError.validationMessages?.length > 0) {
                            message = validationError.validationMessages.join(", ");
                        } else {
                            message = validationError.Message;
                        }

                        Modal.error({
                            title: validationError.Message,
                            content: message,
                        });
                    }
                });
        });
    }

    const formatPhone = (phone: string) => {
        if (phone && phone.length > 10) {
            phone = phone.substr(phone.length - 10);
        }

        return phone;
    };

    return (
        <Form<Fenomio_Contract_App_Brand_UpdateBrandRequest>
            layout="vertical"
            form={profileForm}
            requiredMark={false}
            scrollToFirstError
            onFinish={(values) => {
                updateFields(values);
            }}
        >
            <style>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance:textfield; /* Firefox */
        }
      `}</style>
            <Avatar
                shape="square"
                size={72}
                src={UserHelper.getLogoPath() ?? "/images/profile/profile_brand.png"}
                style={{ marginBottom: "20px" }}
            />
            <hr style={{ borderTop: "1px solid #952AE7" }} />
            <Row gutter={30}>
                <Col xs={24} lg={12}>
                    <Form.Item name="name" label={t("global.brand")} initialValue={user?.name}>
                        <Input />
                    </Form.Item>
                    <Form.Item name="delegateName" label={t("global.brandrepresentative") + ":"} initialValue={user?.delegateName}>
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="phone"
                        label={t("global.contact-number") + ":"}
                        initialValue={user?.phone ? formatPhone(user.phone) : ""}
                    >
                        <Input
                            addonBefore={PrefixSelector}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            maxLength={15}
                        />
                    </Form.Item>
                    <Form.Item label="Email" initialValue={user?.email}>
                        <Input defaultValue={user?.email} disabled={true} />
                    </Form.Item>
                    <Form.Item name="country" label={t("global.country") + ":"} initialValue={user?.country}>
                        <Select
                            allowClear
                            options={countries?.map((country) => {
                                return {
                                    label: country.text,
                                    value: country.value,
                                };
                            })}
                        ></Select>
                    </Form.Item>
                    <Form.Item
                        name="sector"
                        label={t("global.sector") + ":"}
                        initialValue={user && user.sectorId && !user.sectorId.includes("000") ? user.sectorId : null}
                    >
                        <Select
                            allowClear
                            options={sectors.map((sector) => {
                                return {
                                    label: sector.name,
                                    value: sector.id,
                                };
                            })}
                        />
                    </Form.Item>
                </Col>
                <Col xs={24} lg={12}>
                    <Form.Item name="detail" label={t("pages.brand.descriptionLabel")} initialValue={user?.detail}>
                        <TextArea showCount rows={4} maxLength={ProfileConstants.detailMaxLength} />
                    </Form.Item>
                    <Form.Item name="workDetail" label={t("pages.brand.brandDetail")} initialValue={user?.workingDetail}>
                        <TextArea showCount rows={5} maxLength={ProfileConstants.workDetailMaxLength} />
                    </Form.Item>
                </Col>
            </Row>

            <Row gutter={[8, 8]} justify="end">
                <Col>
                    <Button
                        htmlType="submit"
                        type="default"
                        style={{ display: props.updateEnabled ? "block" : "none" }}
                        loading={props.isLoading}
                    >
                        {translate("pages.profile.sendApprove", "Kaydet")}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};
