import { EVENTS } from "Constanst";
import { Modal, ModalProps } from "antd";
import { useModal } from "hooks";
import { CloseOutlined } from "@ant-design/icons";
import { FC, useState } from "react";
import { useTranslate } from "@pankod/refine";
import { SocialMedia } from "services/models/socialMedia";
import { InfluencersService } from "services";
import { localizationHelper } from "helpers/globalizationHelper";

export const PlatformDeleteModal: FC<ModalProps> = (props) => {
    const [open, detail, handleClose] = useModal<{ onDelete: (socialName: string) => void; data: SocialMedia; influencerId: string }>(
        EVENTS.PLATFORM_DELETE_MODEL_IS_OPENED
    );
    const [loading, setLoading] = useState(false);
    const t = useTranslate();

    const handleDelete = () => {
        if (!detail?.data) return;
        const data = detail.data;

        setLoading(true);
        InfluencersService.removeInfluencerSocialMedia({
            body: {
                influencerId: detail.influencerId,
                createInfluencerSocialMediaDtos: [
                    {
                        name: data.socialMedia,
                        username: data.username,
                        followers: data.followers,
                        link: data.link,
                        remove: true,
                    },
                ],
            },
            acceptLanguage: localizationHelper.getLanguage(),
        })
            .then(() => {
                detail?.onDelete(data.socialMedia);
            })

            .finally(() => {
                setLoading(false);
                handleClose();
            });
    };
    return (
        <Modal
            open={open}
            closeIcon={<CloseOutlined />}
            onOk={handleDelete}
            onCancel={handleClose}
            confirmLoading={loading}
            okText={t("buttons.delete")}
            cancelText={t("buttons.cancel")}
            centered
            maskClosable
            closable
            {...props}
        >
            {t("profile.platforms.delete.caution.message")}
        </Modal>
    );
};
