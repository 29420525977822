import { Roles } from "helpers/roleHelper";
import UserHelper from "helpers/userHelper";
import { ProfileModel } from "pages/profile/models";
import { useEffect, useState } from "react";

export const useUserInfo = () => {
    const [profileData, setProfileData] = useState<ProfileModel>();
    const [data, setData] = useState("");

    useEffect(() => {
        const getSectorOrCategory = (profileData: ProfileModel): string => {
            const role = UserHelper.getRole();
            if (role === Roles.Influencer) {
                if (profileData?.categories && profileData.categories.length > 0) {
                    return profileData.categories.map((c) => "#" + c.name).join(" ");
                }
            } else {
                if (profileData?.sector) {
                    return profileData.sector;
                }
            }
            return "";
        };
        UserHelper.getProfile().then((resp) => {
            setProfileData(resp as ProfileModel);
            const sectorOrCategory = getSectorOrCategory(resp as ProfileModel);
            setData(sectorOrCategory);
        });
    }, []);
    return [profileData, data] as const;
};
