import { FC, useMemo } from "react";
import { useInfluencerReceipt } from "./useInfluencerReceipt";
import moment from "moment";
import { useTranslate } from "@pankod/refine";
import { Space, Table } from "antd";
import { Pagination } from "services/core/Pagination";
import { Receipt } from "services/models/Receipt";
import { ReceiptDetailModal } from "components";
import { Money } from "services/models/money";

interface InfluencerReceiptsProps {
    influencerId?: string;
}
export const InfluencerReceipts: FC<InfluencerReceiptsProps> = ({ influencerId }) => {
    const [loading, receipts, pagination, handlePaginationChange, handleShowReceiptDetail] = useInfluencerReceipt(influencerId);

    const t = useTranslate();
    const columns = useMemo(
        () => [
            {
                title: <span className="table-font">{t("global.reference")}</span>,
                dataIndex: "id",
                key: "id",
                render: (text: string) => <>#{text}</>,
            },
            {
                title: <span className="table-font">{t("global.date")}</span>,
                dataIndex: "createdOn",
                key: "createdOn",
                render: (date: string) => <>#{moment(date).format("DD/MM/YYYY")}</>,
            },
            {
                title: <span className="table-font">{t("global.amount")}</span>,
                dataIndex: "amount",
                key: "amount",
                render: (amount: string) => <>{new Money(amount).toStringWithCurrency("₺")}</>,
            },
            {
                title: <span className="table-font">{t("global.action")}</span>,

                dataIndex: "",
                key: "action",
                render: (text: null, row: Receipt) => (
                    <Space size="middle">
                        <span className="clickable-span" onClick={() => handleShowReceiptDetail(row)} style={{ color: "#9254de" }}>
                            Görüntüle
                        </span>
                    </Space>
                ),
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
    return (
        <>
            <Table
                pagination={pagination}
                loading={loading}
                columns={columns}
                dataSource={receipts}
                onChange={(page) => handlePaginationChange(page as Pagination)}
                scroll={{ x: "100%" }}
            />
            <ReceiptDetailModal />
        </>
    );
};
