import * as React from "react";

import styles from "./user-agreement.module.scss";
import { useEffect, useState } from "react";

type Props = {
    body?: string|null;
    title?: string|null;
};

const UserAgreement: React.FC<Props> = (props) => {
    const [body, setBody] = useState("");
    useEffect(()=>{
        setBody("<h1>"+props.title+"</h1>"+props.body);
    },[props.body, props.title]);

    return (
        <div className={styles.container}>
            <div className="confidental-agreement" dangerouslySetInnerHTML={{__html:body}}>
                
            </div>
        </div>
    );
};

export default UserAgreement;
