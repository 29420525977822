import React, { useEffect, useState } from "react";
import { LayoutWrapper, useNavigation, useTranslate, Col, Row, Form, Input, Button, Select } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { ProfileModel } from "../models";
import {
    Fenomio_Contract_App_Influencer_BillingDetail_CreateOrUpdateInfluencerBillingDetailRequest,
    InfluencersService,
} from "../../../services";
import UserHelper from "../../../helpers/userHelper";
import { checkTCKN } from "../../../helpers/FormHelper";
import { localizationHelper } from "helpers/globalizationHelper";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";

type LayoutType = Parameters<typeof Form>[0]["layout"];

export const EditPayment = (props: any) => {
    const [form] = Form.useForm();
    const [formLayout, setFormLayout] = useState<LayoutType>("vertical");
    const { TextArea } = Input;
    const [profileData, setProfileData] = useState<ProfileModel>();
    const [isInvoice, setIsInvoice] = useState<number | undefined>(undefined);
    const { push } = useNavigation();
    const { Option } = Select;
    const t = useTranslate();

    const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
        setFormLayout(layout);
    };

    useEffect(() => {
        if (props.location && props.location.state && props.location.state.billingDetail) {
            setProfileData(props.location.state);
            if (props.location.state.billingDetail !== null) {
                if (props.location.state.billingDetail.hasExceptionLicence) {
                    setIsInvoice(2);
                    form.setFieldsValue({ isBillActive: 2 });
                } else if (props.location.state.billingDetail.isBillActive) {
                    setIsInvoice(1);
                    form.setFieldsValue({ isBillActive: 1 });
                } else {
                    setIsInvoice(0);
                    form.setFieldsValue({ isBillActive: 0 });
                }
            }
        } else {
            getProfileInfo();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getProfileInfo = () => {
        UserHelper.getProfile().then((resp) => {
            const profileModel = resp as ProfileModel;
            setProfileData(profileModel);
            if (profileModel?.billingDetail) {
                form.setFieldsValue(profileModel.billingDetail);
                if (profileModel.billingDetail?.hasExceptionLicence) {
                    setIsInvoice(2);
                    form.setFieldsValue({ isBillActive: 2 });
                } else if (profileModel.billingDetail?.isBillActive) {
                    setIsInvoice(1);
                    form.setFieldsValue({ isBillActive: 1 });
                } else {
                    setIsInvoice(0);
                    form.setFieldsValue({ isBillActive: 0 });
                }
            }
        });
    };

    const formItemLayout =
        formLayout === "horizontal"
            ? {
                  labelCol: { span: 14 },
                  wrapperCol: { span: 14 },
              }
            : null;

    const buttonItemLayout =
        formLayout === "horizontal"
            ? {
                  wrapperCol: { span: 14, offset: 4 },
              }
            : null;

    const prepareInitialValues = () => {
        if (props.location.state === null) {
            return {};
        }

        if (props?.location?.state?.billingDetail === null || props?.location?.state?.billingDetail === undefined) {
            return {};
        } else {
            let billingDetail = JSON.parse(JSON.stringify(props.location.state.billingDetail));
            return billingDetail;
        }
    };

    const submit = (values: any) => {
        let hasExceptionLicence = isInvoice === 2;
        let isBillActive = isInvoice === 1;
        if (isInvoice) {
        }
        let request: Fenomio_Contract_App_Influencer_BillingDetail_CreateOrUpdateInfluencerBillingDetailRequest = {
            ...values,
            hasExceptionLicence: hasExceptionLicence,
            isBillActive: isBillActive,
            id: profileData?.billingDetail?.id,
            iban: values.iban.toRemoveEmptyCharacters(),
        };

        InfluencersService.upsertInfluencerBillingDetail({
            id: profileData?.id,
            body: request,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            push("/profile/detail");
        });
    };

    const handleTCKN = (e: React.ChangeEvent<HTMLInputElement>) => {
        let parsedValue = e.target.value;
        const regexPattern = /[^0-9]/gm;
        parsedValue = parsedValue.replace(regexPattern, "");

        let formValues = form.getFieldsValue();
        formValues["identityNumber"] = parsedValue;
        form.setFieldsValue(formValues);
    };

    return (
        <LayoutWrapper>
            <FenBreadCrumb
                items={[
                    { name: t("global.profile"), url: "/profile/detail" },
                    { name: t("global.payment-information"), url: "" },
                ]}
            />
            {/*<span className="clickable-span color-gray" onClick={() => push("detail")}>
                {t("global.my-profile")}
                {"  >  "}
                </span>
                <span className="color-purple">{t("global.payment-information")}</span>*/}

            <Col span={24} style={{ marginTop: "10px" }}>
                <PageContainer pageTitle={t("global.payment-information")} helperContent={"Yardım içeriği eklenecek!"}>
                    <div style={{ padding: "20px 0px" }}>
                        <Row>
                            <Col>
                                <img
                                    src={profileData?.logoPath ? profileData?.logoPath : "/images/profile/profile_influencer.png"}
                                    alt="img"
                                    width={150}
                                    style={{ border: "1px solid #FFFFFF", borderRadius: "14px" }}
                                />
                            </Col>
                            <Col style={{ padding: "50px 0px 0px 20px" }}>
                                <h2 className="color-purple">
                                    {profileData?.name} {profileData?.surname}
                                </h2>
                                <p className="color-gray margin-0">
                                    <span>
                                        {profileData?.categories &&
                                            profileData.categories.length > 0 &&
                                            profileData.categories.map((c) => "#" + c.name).join(" ")}
                                    </span>
                                    {" / "} {profileData?.country}
                                </p>
                                <p className="color-gray margin-0">{profileData?.createdOn}</p>
                            </Col>
                        </Row>
                    </div>
                    <hr style={{ borderTop: "1px solid #952AE7" }} />
                    <Form
                        {...formItemLayout}
                        layout="vertical"
                        form={form}
                        initialValues={prepareInitialValues()}
                        onFinish={(values) => submit(values)}
                        onValuesChange={onFormLayoutChange}
                        style={{ display: "flex" }}
                    >
                        <Row gutter={[50, 10]} style={{ width: "100%" }}>
                            <Col xs={24} sm={24} md={8} span={8}>
                                <Form.Item
                                    label={t("global.bill-active")}
                                    name={"isBillActive"}
                                    style={{
                                        width: 250,
                                    }}
                                    rules={[{ required: true, message: t("global.bill-active-required") }]}
                                >
                                    <Select onChange={(v) => setIsInvoice(v)} allowClear>
                                        <Option value={1}>{t("global.bill-active-invoice")}</Option>
                                        <Option value={0}>{t("global.bill-active-cantinvoice")}</Option>
                                        <Option value={2}>{t("global.bill-exception-licence")}</Option>
                                    </Select>
                                </Form.Item>
                                {isInvoice !== undefined && isInvoice !== null && (
                                    <>
                                        {isInvoice === 1 ? (
                                            <Form.Item
                                                label={t("global.company-type")}
                                                name={"companyType"}
                                                rules={isInvoice ? [{ required: true, message: t("global.full-name-required") }] : []}
                                                style={{
                                                    width: 250,
                                                }}
                                            >
                                                <Select
                                                    options={[
                                                        { label: t("global.company-type-anonim"), value: "anonim" },
                                                        { label: t("global.company-type-limited"), value: "limited" },
                                                        { label: t("global.company-type-sahis"), value: "sahis" },
                                                    ]}
                                                ></Select>
                                            </Form.Item>
                                        ) : (
                                            <div>
                                                <Form.Item
                                                    label={t("global.name")}
                                                    name={"firstName"}
                                                    rules={
                                                        isInvoice !== 1
                                                            ? [{ required: true, message: t("global.first-name-required") }]
                                                            : []
                                                    }
                                                    style={{
                                                        width: 250,
                                                    }}
                                                >
                                                    <Input placeholder={t("global.first-name-placeholder")} />
                                                </Form.Item>
                                                <Form.Item
                                                    label={t("global.surname")}
                                                    name={"lastName"}
                                                    rules={
                                                        isInvoice !== 1 ? [{ required: true, message: t("global.last-name-required") }] : []
                                                    }
                                                    style={{
                                                        width: 250,
                                                    }}
                                                >
                                                    <Input placeholder={t("global.last-name-placeholder")} />
                                                </Form.Item>
                                            </div>
                                        )}
                                    </>
                                )}
                            </Col>
                            {isInvoice !== undefined && isInvoice !== null && (
                                <>
                                    <Col xs={24} sm={24} md={8} span={8}>
                                        {isInvoice === 1 ? (
                                            <Form.Item
                                                label={t("global.company-name")}
                                                name={"companyName"}
                                                rules={
                                                    isInvoice === 1 ? [{ required: true, message: t("global.company-name-required") }] : []
                                                }
                                                style={{
                                                    width: 250,
                                                }}
                                            >
                                                <Input placeholder={t("global.company-name-placeholder")} />
                                            </Form.Item>
                                        ) : (
                                            <Form.Item
                                                label={t("global.identity-number")}
                                                name={"identityNumber"}
                                                style={{
                                                    width: 250,
                                                }}
                                                rules={
                                                    isInvoice !== 1
                                                        ? [
                                                              { required: true, message: t("global.identity-number-required") },
                                                              { min: 11, message: t("global.identity-number-min") },
                                                              { max: 11, message: t("global.identity-number-max") },
                                                              {
                                                                  validator: (rule, value) => {
                                                                      return new Promise((resolve, reject) => {
                                                                          if (!checkTCKN(value))
                                                                              reject(t("global.identity-number-invalid"));
                                                                          else resolve("");
                                                                      });
                                                                  },
                                                              },
                                                          ]
                                                        : []
                                                }
                                            >
                                                <Input
                                                    type="text"
                                                    placeholder={t("global.identity-number-placeholder")}
                                                    onChange={handleTCKN}
                                                />
                                            </Form.Item>
                                        )}
                                        <Form.Item
                                            label={t("global.bank-name")}
                                            name={"bankName"}
                                            rules={[{ required: true, message: t("global.bank-name-required") }]}
                                            style={{
                                                width: 250,
                                            }}
                                        >
                                            <Input placeholder={t("global.bank-name-placeholder")} />
                                        </Form.Item>
                                        <Form.Item
                                            name={"iban"}
                                            label={t("global.iban")}
                                            rules={[
                                                { required: true, message: t("global.iban-required") },
                                                { min: 15, message: t("global.iban-min-value") },
                                                { max: 45, message: t("global.iban-max-value") },
                                            ]}
                                            style={{
                                                width: 250,
                                            }}
                                        >
                                            <Input placeholder={t("global.iban-placeholder")} />
                                        </Form.Item>
                                    </Col>

                                    {isInvoice !== 1 && (
                                        <Col xs={24} sm={24} md={8} span={8}>
                                            <Form.Item
                                                label={t("global.address")}
                                                name={"address"}
                                                rules={
                                                    isInvoice !== 1
                                                        ? [{ required: true, max: 250, message: t("global.address-required") }]
                                                        : []
                                                }
                                                style={{
                                                    width: 250,
                                                }}
                                            >
                                                <TextArea rows={4} placeholder={t("global.address-placeholder")} />
                                            </Form.Item>
                                        </Col>
                                    )}

                                    <Row style={{ width: "100%" }} justify="end">
                                        <Form.Item {...buttonItemLayout}>
                                            <Button htmlType="submit" type="default" style={{ borderRadius: "10px" }}>
                                                {t("buttons.save")}
                                            </Button>
                                        </Form.Item>
                                    </Row>
                                </>
                            )}
                        </Row>
                    </Form>
                </PageContainer>
            </Col>
        </LayoutWrapper>
    );
};
