import { Button, Space, useNavigation, useTranslate } from "@pankod/refine";
import { FunctionComponent } from "react";
import { CampaignType } from "../../../Constanst";
import { Col, Row } from "antd";

interface DownloadCampaignResultProps {
    createdCampaignId: any;
    createdCampaignType: CampaignType;
    paymentFail: any;
    isEditPage: boolean;
}

const CampaignCreateResult: FunctionComponent<DownloadCampaignResultProps> = (props: DownloadCampaignResultProps) => {
    const { push } = useNavigation();
    const t = useTranslate();
    const params = new URLSearchParams(window.location.search);
    const campaignId = params.get("campaignId");

    return (
        <>
            <p>
                {t("campaign.create.result.paragraph")}
            </p>
            {props.paymentFail === "true" && (
                <>
                    <Space direction="vertical" size="middle" style={{ justifyContent: "center", width: "100%", marginTop: 20 }}>
                        <Row style={{ display: "flex", justifyContent: "center" }}>
                            <Col>
                                <img src="/images/payment/cancel.png" alt="paymentFail" />
                            </Col>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "center" }}>
                            <Col>
                                <p>
                                    <b>{t("pages.payment.error")}</b>
                                </p>
                            </Col>
                        </Row>
                    </Space>
                </>
            )}

            {props.paymentFail === "false" && (
                <>
                    <Space direction="vertical" size="middle" style={{ justifyContent: "center", width: "100%", marginTop: 20 }}>
                        <Row style={{ display: "flex", justifyContent: "center" }}>
                            <Col>
                                <img width={64} height={64} src="/images/payment/success.png" alt="paymentSuccess" />
                            </Col>
                        </Row>
                        <Row style={{ display: "flex", justifyContent: "center" }}>
                            <Col>
                                <p>
                                    <b>{t("pages.payment.success")}</b>
                                </p>
                            </Col>
                        </Row>
                    </Space>
                </>
            )}

            {(props.paymentFail === null || props.paymentFail === "false") && (
                <Row>
                    <Col xs={24} sm={24} md={12} style={{ justifyContent: "center", display: "flex", marginTop: "10px" }}>
                        <Button
                            type="default"
                            onClick={() => {
                                push("/campaign/detail", {
                                    id: campaignId || props.createdCampaignId,
                                    typeHash: props.createdCampaignType,
                                });
                            }}
                        >
                            {t("campaign.create.result.primaryButton")}
                        </Button>
                    </Col>
                    <Col xs={24} sm={24} md={12} style={{ justifyContent: "center", display: "flex", marginTop: "10px" }}>
                        <Button
                            type="default"
                            onClick={() => {
                                push("/campaign/list");
                            }}
                        >
                            {t("campaign.create.result.secondaryButton")}
                        </Button>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default CampaignCreateResult;
