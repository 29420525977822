import { persistentStorageHelper } from "./persistentStorageHelper";
import trLocale from "antd/es/date-picker/locale/tr_TR";
import enLocale from "antd/es/date-picker/locale/en_GB";
import esLocale from "antd/es/date-picker/locale/es_ES";

export enum Language {
    tr = "tr",
    en = "en",
    es = "es",
}

export enum Locale {
    tr = "tr",
    en = "en",
    es = "es",
}

export class localizationHelper {
    static supportedLanguages: Language[] = [Language.tr, Language.en, Language.es];

    static getLanguage() {
        return persistentStorageHelper.getItem("i18nextLng") ?? "tr";
    }

    static getLocale() {
        switch (this.getLanguage() as Language) {
            case Language.en:
                return Locale.en;
            case Language.tr:
                return Locale.tr;
            case Language.es:
                return Locale.es;
        }
    }

    static getLocaleForComponent = () => {
        switch (this.getLanguage() as Language) {
            case Language.en:
                return enLocale;
            case Language.tr:
                return trLocale;
            case Language.es:
                return esLocale;
        }
    };
}
