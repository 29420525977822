import { PlusOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Form, Row, Space, Upload, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { IBrand } from "modules/brands/model";
import { FunctionComponent } from "react";
import { useAdminBrandCreate } from "./useAdminBrandCreate";

export const AdminBrandCreate: FunctionComponent = () => {
    const [isLoading, saveBrand, handleCancel] = useAdminBrandCreate();
    const t = useTranslate();

    return (
        <PageContainer pageTitle={t("pages.brand.add")} helperContent={"Buraya yardım içeriği gelecek"}>
            <>
                <Form<IBrand>
                    layout="horizontal"
                    requiredMark={false}
                    scrollToFirstError
                    onFinish={(value) => {
                        saveBrand(value);
                    }}
                >
                    <style>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance:textfield; /* Firefox */
        }
      `}</style>
                    <Row gutter={[30, 8]}>
                        <Col>
                            <Space direction="horizontal" size={"middle"} style={{ width: "100%" }}>
                                <Avatar shape="circle" size={128} style={{ marginBottom: "20px" }} />
                                <Form.Item style={{ height: 128, width: 128 }} name="dragger" valuePropName="fileList">
                                    <Upload.Dragger name="files" action="/upload.do">
                                        <p className="ant-upload-drag-icon">
                                            <PlusOutlined />
                                        </p>
                                        <p className="ant-upload-text">Upload</p>
                                    </Upload.Dragger>
                                </Form.Item>
                            </Space>
                            <Form.Item name="name" label={t("global.brand")}>
                                <input />
                            </Form.Item>
                            <Form.Item name="explanation" label={t("pages.brand.description")}>
                                <input />
                            </Form.Item>
                            <Form.Item name="biography" label={t("pages.brand.brandDetail")}>
                                <input />
                            </Form.Item>
                            <Form.Item name="sector" label={t("pages.brand.sector")}>
                                <input />
                            </Form.Item>
                            <Form.Item name="status" label={t("pages.brand.status")}>
                                <input />
                            </Form.Item>
                            <Form.Item name="country" label={t("pages.brand.country")}>
                                <input />
                            </Form.Item>
                            <Form.Item name="delegate" label={t("pages.brand.brandRepresentative")}>
                                <input />
                            </Form.Item>
                            <Form.Item name="phone" label={t("pages.brand.number")}>
                                <input />
                            </Form.Item>
                            <Form.Item name="email" label={t("pages.brand.email")}>
                                <input />
                            </Form.Item>
                            <Form.Item name="recordDate" label={t("pages.brand.registration")}>
                                <input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Space direction="horizontal" size={"middle"} style={{ width: "100%", justifyContent: "flex-end" }}>
                        <Button type="primary" onClick={handleCancel}>
                            {t("buttons.cancel")}
                        </Button>
                        <Button type="default" loading={isLoading} htmlType="submit">
                            {t("pages.register.registerButton")}
                        </Button>
                    </Space>
                </Form>
            </>
        </PageContainer>
    );
};
