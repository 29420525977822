import { Table } from "antd";
import { FC } from "react";
import { useTransactions } from "./useTransactions";
import { Money } from "services/models/money";
import { useTranslate } from "@pankod/refine";

export const Transactions: FC<{ id: string }> = ({ id }) => {
    const [data, loading] = useTransactions(id);
    const t = useTranslate();
    return (
        <Table
            style={{ marginTop: "20px" }}
            rowKey="id"
            columns={[
                {
                    title: <span className="table-font">{t("global.productName")}</span>,
                    dataIndex: "productName",
                    key: "productName",
                },
                {
                    title: <span className="table-font">{t("global.saleCount")}</span>,
                    dataIndex: "count",
                    key: "saleCount",
                },
                {
                    title: <span className="table-font">{t("global.refundCount")}</span>,
                    dataIndex: "refundCount",
                    key: "refundCount",
                },
                {
                    title: <span className="table-font">{t("global.commissionAmount")}</span>,
                    dataIndex: "payout",
                    key: "payout",
                    render: (payout: number) => <span>{new Money(payout).toStringWithCurrency()}</span>,
                },
            ]}
            dataSource={data}
            scroll={{ x: "100%" }}
            loading={loading}
        />
    );
};
