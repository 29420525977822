// @flow
import { Authenticated, LayoutWrapper, useNavigation, useTranslate } from "@pankod/refine";
import { Button, Card, Col, Form, Input, Radio, Row } from "antd";
import * as React from "react";
import { withTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FenBreadCrumb } from "../../components/BreadCrumb/BreadCrumb";
import { PageTitle } from "../../components/PageTitle/PageTitle";
import { localizationHelper } from "../../helpers/globalizationHelper";
import { Fenomio_Contract_App_Brand_UpdateBrandRequest } from "../../services";
import { CategoryService } from "../../services/services/CategoryService";


type State = {
    showEdit: boolean;
    isLoading: boolean;
    create: boolean;
};

class CategoryDetailPageComponent extends React.Component<any, State> {
    state = {
        showEdit: false,
        isLoading: false,
        create: false,
        
    };

    componentDidMount() {
        let pathname = window.location.pathname;
        if (pathname.includes("detail")) {
            this.setState({
                showEdit: true,
            });
        }
        if (pathname.includes("create")) {
            this.setState({
                showEdit: false,
                create: true,
            });
        }
    }
    t = this.props.t;

    category = this.props.location.state;
    
    render() {
        return (
            <Authenticated>
                <LayoutWrapper>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FenBreadCrumb
                                items={[
                                    { name: this.t("global.category"), url: "/category/list" },
                                    { name: 
                                        this.state.showEdit === true 
                                        ? this.category.name
                                        : this.t("global.categoryAdd"), url: "" 
                                    },
                                ]}
                            />
                            <Card id="categoriesListCard">
                                <PageTitle
                                    content={
                                        <div>
                                            <p>Content</p>
                                            <p>Content</p>
                                        </div>
                                    }
                                    title={this.category.name}
                                />
                                {this.state.showEdit && (
                                    
                                    <UpdateForm
                                        id={this.category.id}
                                        setLoading={(value) => {
                                            this.setState({ isLoading: value });
                                        }}
                                        setUpdateEnabled={(value) => {
                                            this.setState({ showEdit: value });
                                        }}
                                        isLoading={this.state.isLoading}
                                        updateEnabled={!this.state.showEdit}
                                        data={{
                                            categoryNameTurkish: this.category.nameTR,
                                            categoryNameEnglish: this.category.nameEN,
                                            categoryNameSpanish: this.category.nameES,
                                            status: this.category.isPublic,
                                        }}
                                        create={false}
                                    />
                                )}

                                {!this.state.showEdit && this.state.create && (
                                    <UpdateForm
                                        id={this.category.id}
                                        setLoading={(value) => {
                                            this.setState({ isLoading: value });
                                        }}
                                        setUpdateEnabled={(value) => {
                                            this.setState({ showEdit: value });
                                        }}
                                        isLoading={this.state.isLoading}
                                        updateEnabled={!this.state.showEdit}
                                        data={{
                                            categoryNameTurkish: "",
                                            categoryNameEnglish: "",
                                            categoryNameSpanish: "",
                                            status: "",
                                        }}
                                        create={true}
                                    />
                                )}
                            </Card>
                        </Col>
                    </Row>
                </LayoutWrapper>
            </Authenticated>
        );
    }
}

export const CategoryDetailPage = withTranslation()(CategoryDetailPageComponent);

interface UpdateFormProps {
    isLoading: boolean;
    updateEnabled: boolean;
    setLoading: (loading: boolean) => void;
    setUpdateEnabled: (enabled: boolean) => void;
    data: any;
    id: string;
    create: boolean;
}

export const UpdateForm = (props: UpdateFormProps) => {
    const { push } = useNavigation();
    const translate = useTranslate();
    const [updateForm] = Form.useForm();
    const [trInput, setTrInput] = React.useState(props.data.categoryNameTurkish);
    const [enInput, setEnInput] = React.useState(props.data.categoryNameEnglish);
    const [esInput, setEsInput] = React.useState(props.data.categoryNameSpanish);

    function updateFields(fields: any) {
        updateForm.validateFields().then((values) => {
            props.setLoading(true);
            if (props.create) {
                CategoryService.createCategory({
                    body: {
                        name: values.turkisCategoryName,
                        nameEn: values.englishCategoryName,
                        nameEs: values.spanishCategoryName,
                        isPublic: values.status ?? true,
                    },
                    acceptLanguage: localizationHelper.getLocale(),
                })
                    .then((resp) => {
                        props.setLoading(false);
                        props.setUpdateEnabled(false);
                        toast.success(translate("global.category-add-success"));
                        push("/category/list");
                    })
                    .catch((error) => {
                        props.setLoading(false);
                        toast.error(translate("global.category-add-error-name"));
                    });
                return;
            }
            CategoryService.updateCategory({
                body: {
                    name: values.turkisCategoryName,
                    nameEn: values.englishCategoryName,
                    nameEs: values.spanishCategoryName,
                    isPublic: values.status ?? true,
                },
                acceptLanguage: localizationHelper.getLocale(),
                id: props.id,
            })
                .then((resp) => {
                    props.setLoading(false);
                    props.setUpdateEnabled(false);
                    push("/category/list");
                })
                .catch((error) => {
                    props.setLoading(false);
                    toast.error(translate("global.category-add-error-name"));
                });
        });
    }

    return (
        <Form<Fenomio_Contract_App_Brand_UpdateBrandRequest>
            layout="vertical"
            form={updateForm}
            requiredMark={false}
            scrollToFirstError
            onFinish={(values) => {
                updateFields(values);
            }}
        >
            <Form.Item
                name="turkisCategoryName"
                label={translate("global.turkisCategoryName")}
                initialValue={props.data.categoryNameTurkish}
            >
                <Input style={{ width: 200 }} onChange={(e: any) => setTrInput(e.target.value)} />
            </Form.Item>
            <Form.Item
                name="englishCategoryName"
                label={translate("global.englishCategoryName")}
                initialValue={props.data.categoryNameEnglish}
            >
                <Input style={{ width: 200 }} onChange={(e: any) => setEnInput(e.target.value)} />
            </Form.Item>
            <Form.Item
                name="spanishCategoryName"
                label={translate("global.spanishCategoryName")}
                initialValue={props.data.categoryNameSpanish}
            >
                <Input style={{ width: 200 }} onChange={(e: any) => setEsInput(e.target.value)} />
            </Form.Item>
            <Form.Item name="status" label={translate("global.howView")} initialValue={props.data.status || true}>
                <Radio.Group>
                    <Radio.Button value={true}>{translate("global.public")}</Radio.Button>
                    <Radio.Button value={false}>{translate("global.private")}</Radio.Button>
                </Radio.Group>
            </Form.Item>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button type="default" loading={props.isLoading} htmlType={"submit"} disabled={!(trInput && enInput && esInput)}>
                    {translate("pages.category.listCampaigns", "Kaydet")}
                </Button>
            </div>
        </Form>
    );
};
