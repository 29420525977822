import { useTranslate } from "@pankod/refine";
import { Col, Row, Skeleton } from "antd";
import { PageContainer } from "components/pageContainer/pageContainer";
import { FC } from "react";
import { useInfluencerBalance } from "./useInfluencerBalance";
import { Money } from "services/models/money";

export interface InfluencerBalanceProps {
    influencerId: string;
}
export const InfluencerBalance: FC<InfluencerBalanceProps> = ({ influencerId }) => {
    const [wallet, loading] = useInfluencerBalance(influencerId);
    const t = useTranslate();
    return (
        <Row gutter={[20, 0]}>
            <Col xs={24} sm={24} md={8} span={8} style={{ marginTop: "10px" }}>
                <PageContainer pageTitle={t("global.balance")} helperContent={t("global.influencer.wallet.helper-content.balance")}>
                    {loading ? (
                        <Skeleton.Input size="large" block />
                    ) : (
                        <h2 style={{ fontWeight: "bold" }}>{new Money(wallet.balance).toStringWithCurrency(wallet.currency)}</h2>
                    )}
                </PageContainer>
            </Col>
            <Col xs={24} sm={24} md={8} span={8} style={{ marginTop: "10px" }}>
                <PageContainer
                    pageTitle={t("global.payThisMonth")}
                    helperContent={t("global.influencer.wallet.helper-content.balance-on-month")}
                >
                    {loading ? (
                        <Skeleton.Input size="large" block />
                    ) : (
                        <h2 style={{ fontWeight: "bold" }}>{new Money(wallet.balanceOnMonth).toStringWithCurrency(wallet.currency)}</h2>
                    )}
                </PageContainer>
            </Col>
            <Col xs={24} sm={24} md={8} span={8} style={{ marginTop: "10px" }}>
                <PageContainer
                    pageTitle={t("campaign.create.step3.totalearnings")}
                    helperContent={t("global.influencer.wallet.helper-content.total-earning")}
                >
                    {loading ? (
                        <Skeleton.Input size="large" block />
                    ) : (
                        <h2 style={{ fontWeight: "bold" }}>{new Money(wallet.totalBalance).toStringWithCurrency(wallet.currency)}</h2>
                    )}
                </PageContainer>
            </Col>
        </Row>
    );
};
