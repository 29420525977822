/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Fenomio_Contract_App_Influencer_GetInfluencerDetailResponse } from "../models/Fenomio_Contract_App_Influencer_GetInfluencerDetailResponse";
import type { Fenomio_Contract_App_Influencer_SignUpInfluencerRequest } from "../models/Fenomio_Contract_App_Influencer_SignUpInfluencerRequest";
import type { Filtery_Models_FilteryRequest } from "../models/Filtery_Models_FilteryRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";
import { BillingDetail, Fenomio_Common_BaseModels_Api_DataResponse, WalletBalance, WalletConversion } from "services";
import { SocialMediaHistory } from "services/models/socialMedia";

export class InfluencersService {
    /**
     * Get Influencer
     * @returns Fenomio_Contract_App_Influencer_GetInfluencerDetailResponse Success
     * @throws ApiError
     */
    public static getInfluencers({
        id,
        acceptLanguage,
    }: {
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<Fenomio_Contract_App_Influencer_GetInfluencerDetailResponse>> {
        return __request({
            method: "GET",
            path: `influencers/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getInfluencerLinks({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `influencers/links/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static getInfluencerWalletInfo({
        id,
        body,
        acceptLanguage,
    }: {
        id: string;
        body: any;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<any>> {
        return __request({
            method: "POST",
            path: `influencers/${id}/wallet-info`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static getWalletBalance(id: string): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<WalletBalance>> {
        return __request({
            method: "POST",
            path: `influencers/${id}/wallet-balance-detail`,
        });
    }

    public static getWalletConversion(
        id: string,
        body: { startDate: string | null; endDate: string | null }
    ): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<WalletConversion>> {
        return __request({
            method: "POST",
            path: `influencers/${id}/wallet-conversions-detail`,
            body,
        });
    }

    /**
     * Sign Up Influencer
     * @returns any Success
     * @throws ApiError
     */
    public static signUpInfluencer({
        acceptLanguage,
        requestBody,
    }: {
        /** Please, set language. **/
        acceptLanguage?: any;
        requestBody?: Fenomio_Contract_App_Influencer_SignUpInfluencerRequest;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `influencers/signup`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
            mediaType: "application/json-patch+json",
        });
    }

    public static deleteInfluencer({
        id,
        acceptLanguage,
    }: {
        id: string;
        /** Please, set language. **/
        acceptLanguage?: any;
    }): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<Fenomio_Contract_App_Influencer_GetInfluencerDetailResponse>> {
        return __request({
            method: "DELETE",
            path: `influencers/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static searchInfluencers({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `influencers/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static searchInfluencersWithPaymentData({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `influencers/search-with-payment-data`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static getInfluencerSocialMedias(
        influencerId: string,
        acceptLanguage: string
    ): CancelablePromise<{ data: SocialMediaHistory[] }> {
        return __request({
            method: "GET",
            path: `influencers/${influencerId}/social-medias/`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static getInfluencerSocialMedia({ id, acceptLanguage }: { id: string; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `influencers/social-medias/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static updateInfluencerSocialMedia({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `influencers/social-medias`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    // TODO: response type will be changed
    public static removeInfluencerSocialMedia({ body, acceptLanguage }: { body: any; acceptLanguage: string }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: "influencers/social-medias",
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body,
        });
    }

    public static searchInfluencerSocialMedias({
        requestBody,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        requestBody?: Filtery_Models_FilteryRequest;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `influencers/social-medias/search`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: requestBody,
        });
    }

    public static approveInfluencerSocialMedias({
        id,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        id?: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `influencers/social-medias/${id}/approve`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static rejectInfluencerSocialMedias({
        id,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        id?: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `influencers/social-medias/${id}/reject`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static updateInfluencerProfile({
        id,
        body,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        id: any;
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `influencers/${id}`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static getInfluencersBillingDetail(
        id: string,
        acceptLanguage?: any
    ): CancelablePromise<Fenomio_Common_BaseModels_Api_DataResponse<BillingDetail>> {
        return __request({
            method: "GET",
            path: `influencers/${id}/billing-detail`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static upsertInfluencerBillingDetail({
        id,
        body,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        id: any;
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `influencers/${id}/billing-detail`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static amazonTrackingCodeApprove({
        id,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        id: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "PUT",
            path: `influencers/${id}/approve`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static uploadPhoto({
        body,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        body: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `influencers/upload-photo`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }

    public static getPhoto({
        id,
        acceptLanguage,
    }: {
        /** Please, set language. **/
        id: any;
        acceptLanguage?: any;
    }): CancelablePromise<any> {
        return __request({
            method: "GET",
            path: `influencers/${id}/get-photo`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
        });
    }

    public static influencersPaymentReportExport({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `influencers/paymentsreportexport`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }
    public static influencersReportExport({ body, acceptLanguage }: { body: any; acceptLanguage?: any }): CancelablePromise<any> {
        return __request({
            method: "POST",
            path: `influencers/influencersreportexport`,
            headers: {
                "Accept-Language": acceptLanguage,
            },
            body: body,
        });
    }
}
