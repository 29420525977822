import { useNavigation, useTranslate, useLogout } from "@pankod/refine";
import { Col, Row, Form, Input, Modal, Button, Select } from "antd";
import { PageContainer } from "components/pageContainer/pageContainer";
import { useEffect, useState } from "react";
import Avatar from "./editProfilePic";
import { Fenomio_Common_BaseModels_Api_DetailItemDto, InfluencersService, LookupsService } from "../../../services";
import { CategoryService } from "../../../services/services/CategoryService";
import { localizationHelper } from "../../../helpers/globalizationHelper";
import { GetAllCategoryData } from "../../campaign/Model/GetAllCategoryData";
import "./influencerEditCss.css";
import UserHelper from "../../../helpers/userHelper";
import allCountries from "../../../helpers/Countries";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { toast } from "react-toastify";

type LayoutType = Parameters<typeof Form>[0]["layout"];

export const InfluencerEditPage = (props: any) => {
    const { push } = useNavigation();
    const [form] = Form.useForm();
    const [, setFormLayout] = useState<LayoutType>("horizontal");
    const { TextArea } = Input;
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [, setCountriesLoading] = useState(false);
    const [categoriesLoading, setCategoriesLoading] = useState(false);
    const [, setCountries] = useState<Fenomio_Common_BaseModels_Api_DetailItemDto[]>([]);
    const [categories, setCategories] = useState<GetAllCategoryData[]>([]);
    const [profileData, setProfileData] = useState<any>();
    const { mutate: logout } = useLogout();
    const { Option } = Select;
    const t = useTranslate();
    const [selectedPhoneCode, setSelectedPhoneCode] = useState("90");

    const PrefixSelector = (
        <Select
            value={selectedPhoneCode}
            onChange={(e: any) => {
                setSelectedPhoneCode(e);
            }}
            style={{
                width: 72,
            }}
        >
            {allCountries.map((country) => {
                return <Option value={country.dialCode}>+{country.dialCode}</Option>;
            })}
        </Select>
    );

    useEffect(() => {
        const getProfile = () => {
            UserHelper.getProfile().then((resp) => {
                if (resp !== undefined && resp !== null) {
                    let profileData = JSON.parse(JSON.stringify(resp));
                    profileData.categories = profileData.categories.map((x: any) => x.id);

                    if (profileData.phone && profileData.phone.length > 10) {
                        profileData.phone = profileData.phone.substr(profileData.phone.length - 10);
                    }
                    setProfileData(profileData);
                    form.resetFields();
                }
            });
        };
        getProfile();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getCountries();
        getAllCategory();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAllCategory = async () => {
        setCategoriesLoading(true);
        CategoryService.getAllCategory({ acceptLanguage: localizationHelper.getLocale() })
            .then((res) => {
                setCategories(res.data as any[]);
                setCategoriesLoading(false);
            })
            .catch((error) => {
                console.error(error);
            });
    };
    const getCountries = async () => {
        setCountriesLoading(true);
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
            setCountriesLoading(false);
        });
    };

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const deleteInfluencer = () => {
        InfluencersService.deleteInfluencer({
            acceptLanguage: localizationHelper.getLocale(),
            id: profileData.id,
        })
            .then(() => {
                setIsModalVisible(false);
                logout();
                toast.success(t("notifications.deleteUserSuccess"));
            })
            .catch((e) => {
                setIsModalVisible(false);
            });
    };

    const onFormLayoutChange = ({ layout }: { layout: LayoutType }) => {
        setFormLayout(layout);
    };

    const submit = (values: any) => {
        InfluencersService.updateInfluencerProfile({
            id: profileData.id,
            body: values,
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                Modal.success({
                    title: t("notifications.success"),
                    content: t("notifications.updatesuccess"),
                });
                push("/profile/detail");
            })
            .catch(() => {
                Modal.error({
                    title: "Hata",
                    content: "Güncelleme esnasında bir sorun oluştu.",
                });
                push("/profile/detail");
            });
    };

    return (
        <Col style={{ marginBottom: "75px" }} xs={24} sm={24} md={16} span={16}>
            <style>{`
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
            /* display: none; <- Crashes Chrome on hover */
            -webkit-appearance: none;
            margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
        }

        input[type=number] {
            -moz-appearance:textfield; /* Firefox */
        }
      `}</style>
            <FenBreadCrumb
                items={[
                    { name: t("global.profile"), url: "" },
                    { name: t("global.profile-edit"), url: "" },
                ]}
            />
            <PageContainer
                pageTitle={t("global.influencer.profile-page.edit-profile")}
                helperContent={t("global.influencer.profilepage.helper-content.edit-profile")}
            >
                <div style={{ padding: "20px 0px" }}>
                    <Row>
                        <Col style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
                            <Avatar profileData={profileData} />
                        </Col>
                    </Row>
                </div>
                <hr style={{ borderTop: "1px solid #952AE7" }} />
                <Form
                    layout="vertical"
                    form={form}
                    initialValues={profileData}
                    onFinish={(values) => {
                        submit(values);
                    }}
                    onValuesChange={onFormLayoutChange}
                >
                    <Row gutter={[20, 0]}>
                        <Col xs={24} sm={24} md={8} span={8}>
                            <label>{t("global.name")}</label>
                            <Form.Item rules={[{ required: true, type: "string", message: t("global.first-name-required") }]} name={"name"}>
                                <Input />
                            </Form.Item>
                            <label>{t("global.surname")}</label>
                            <Form.Item
                                rules={[{ required: true, type: "string", message: t("global.last-name-required") }]}
                                name={"surname"}
                            >
                                <Input />
                            </Form.Item>
                            <label>{t("global.contact-number")}</label>
                            <Form.Item
                                name={"phone"}
                                rules={[
                                    {
                                        required: false,
                                        message: t("pages.register.phoneMessage"),
                                    },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (selectedPhoneCode === "90") {
                                                if (value.length > 10) {
                                                    return Promise.reject(new Error(t("pages.register.phoneLength")));
                                                }
                                            }
                                            return Promise.resolve();
                                        },
                                    }),
                                ]}
                            >
                                <Input
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    maxLength={15}
                                    addonBefore={PrefixSelector}
                                />
                            </Form.Item>
                            <label>{t("global.contact-email")}</label>
                            <Form.Item rules={[{ required: true, type: "email", message: t("global.required-email") }]} name={"email"}>
                                <Input disabled={true} />
                            </Form.Item>
                            <Modal visible={isModalVisible} centered footer={[]} onCancel={handleCancel}>
                                <div style={{ textAlign: "center" }}>
                                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>
                                        {t("notifications.deleteMyAccount")}
                                    </h3>
                                    <p style={{ fontWeight: "bold" }}>{t("notifications.sureDelete")}</p>
                                    <p>{t("notifications.sureDeleteInfo")}</p>
                                    <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                        <Button type="default" onClick={deleteInfluencer}>
                                            {t("global.accept", "Kabul Et")}
                                        </Button>
                                        <Button type="primary" onClick={handleCancel}>
                                            {t("global.giveup")}
                                        </Button>
                                    </div>
                                </div>
                            </Modal>
                        </Col>
                        <Col xs={24} sm={24} md={16} span={16}>
                            <label>{t("global.about-phenomenon")}</label>
                            <Form.Item name={"biography"}>
                                <TextArea rows={4} />
                            </Form.Item>
                            <label>{t("campaign.create.step2.categoriesLabel")}</label>
                            <Form.Item
                                rules={[
                                    { required: true, type: "array", message: t("global.required-category") },
                                    { max: 3, type: "array", message: t("global.required-category-public-limit-for-influencer") },
                                ]}
                                name={"categories"}
                            >
                                <Select
                                    mode="multiple"
                                    options={categories.map((item: any) => {
                                        return {
                                            label: item.name,
                                            value: item.id,
                                        };
                                    })}
                                    allowClear
                                    loading={categoriesLoading}
                                />
                            </Form.Item>
                            <Row justify="end">
                                <Form.Item>
                                    <Button htmlType={"submit"} type={"default"} style={{ borderRadius: "10px" }}>
                                        {t("buttons.save")}
                                    </Button>
                                </Form.Item>
                            </Row>
                            <Row justify="end">
                                <span className="clickable-span" onClick={showModal} style={{ color: "red", fontWeight: "bold" }}>
                                    {t("notifications.deleteMyAccount")}
                                </span>
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </PageContainer>
        </Col>
    );
};
