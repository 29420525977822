import { RouteProps } from "react-router-dom";
import PaymentListPage from "./list/PaymentList";

export const paymentRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: PaymentListPage,
            path: "/payment/list",
        },
    ];
};
