import { Button, Col, DatePicker, Form, Input, Row, Space, useTranslate } from "@pankod/refine";
import dayjs from "dayjs";
import { localizationHelper } from "helpers/globalizationHelper";
import { FunctionComponent, useEffect, useState } from "react";
const { TextArea } = Input;

export interface IDownloadCampaignStep3Data {
    startDate: string | null;
    endDate: string | null;
    totalAmount: string | null;
    influencerTotalAmount: string | null;
    minimumInfluencerCount: string | null;
    amountByClick: string | null;
    amountByClickFenomioEarning: string | null;
    amountByClickInfluencerEarning: string | null;
    amountByInfluencer: string | null;
    back: any;
}

interface DownloadCampaignStep3Props {
    campaignData: any;
    formFinished: (campaignData: IDownloadCampaignStep3Data) => void;
    back: any;
    isEditPage: boolean;
}

export const DownloadCampaignStep3: FunctionComponent<DownloadCampaignStep3Props> = (props: DownloadCampaignStep3Props) => {
    const t = useTranslate();

    const [selectedStartDate, setSelectedStartDate] = useState<any>(null);
    const [form] = Form.useForm();
    const [additionalProductGroupCount, setAdditionalProductGroupCount] = useState<number>(0);

    useEffect(() => {
        setAdditionalProductGroupCount(getAdditionalProductGroupInitialCount());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getAdditionalProductGroupInitialCount = (): number => {
        let count = 0;

        for (let i = 0; i < 999; i++) {
            if (props.campaignData["productGroup" + i] && props.campaignData["influencerComissionRatio" + i]) {
                count++;
            } else {
                break;
            }
        }

        return count;
    };

    useEffect(() => {
        if (props.campaignData) {
            form.resetFields();
            if (props.campaignData.productGroupCount > 1) {
                setAdditionalProductGroupCount(props.campaignData.productGroupCount - 1);
            }
            setSelectedStartDate(dayjs(props.campaignData.startDate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.campaignData]);

    const colStyle = {
        marginLeft: "10px",
        marginRight: "10px",
    };

    const disabledStartDate = (current: any) => {
        return current < dayjs().add(-1, "day");
    };

    const disabledEndDate = (current: any) => {
        if (selectedStartDate) {
            return current < selectedStartDate.add(1, "day");
        }
        return false;
    };

    const addProductGroup = () => {
        setAdditionalProductGroupCount(additionalProductGroupCount + 1);
    };

    const getAdditionalProductGroups = () => {
        const items: any[] = [];

        for (let i = 0; i < additionalProductGroupCount; i++) {
            items.push(
                <div key={i}>
                    <Form.Item
                        label={t("global.productGroup")}
                        name={"productGroup" + i}
                        rules={[
                            {
                                required: true,
                                message: t("global.please-productGroup"),
                            },
                        ]}
                        initialValue={props.campaignData["productGroup" + i]}
                    >
                        <Input disabled={props.isEditPage} />
                    </Form.Item>
                    <Form.Item
                        label={t("global.influencerComissionRatioo")}
                        name={"influencerComissionRatio" + i}
                        rules={[
                            {
                                required: true,
                                message: t("global.please-influencerComissionRatioo"),
                            },
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value < 0 ? cb(t("global.please-positiveAmount")) : cb();
                                },
                            },
                        ]}
                        initialValue={props.campaignData["influencerComissionRatio" + i]}
                    >
                        <Input disabled={props.isEditPage} addonBefore="%" type={"number"} />
                    </Form.Item>
                </div>
            );
        }

        return items;
    };

    return (
        <Form
            layout="vertical"
            labelAlign={"right"}
            form={form}
            onFinish={(values) => {
                let data = { ...props.campaignData, ...values };
                props.formFinished(data);
            }}
        >
            <Row>
                <Col xs={24} sm={24} md={7} style={colStyle} span={7}>
                    <Form.Item
                        label={t("global.start-date")}
                        name="startDate"
                        rules={[
                            {
                                required: true,
                                message: t("global.please-start-date"),
                            },
                        ]}
                        initialValue={props.campaignData.startDate !== undefined ? dayjs(props.campaignData.startDate) : undefined}
                    >
                        <DatePicker
                            locale={localizationHelper.getLocaleForComponent()}
                            disabled={props.isEditPage}
                            onChange={(c) => {
                                setSelectedStartDate(c);
                                form.setFieldsValue({ endDate: null });
                            }}
                            disabledDate={disabledStartDate}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("global.end-date")}
                        name="endDate"
                        initialValue={props.campaignData.endDate !== undefined ? dayjs(props.campaignData.endDate) : undefined}
                    >
                        <DatePicker
                            locale={localizationHelper.getLocaleForComponent()}
                            disabledDate={disabledEndDate}
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Form.Item
                        label={t("global.influencerCommissionRate")}
                        name="influencerCommissionRate"
                        rules={[
                            {
                                required: true,
                                message: t("global.please-influencerCommissionRate"),
                            },
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value < 0 ? cb(t("global.please-positiveAmount")) : cb();
                                },
                            },
                        ]}
                        initialValue={props.campaignData.influencerCommissionRate}
                    >
                        <Input disabled={props.isEditPage} type={"number"} min={0} />
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={7} style={colStyle} span={7}>
                    <Form.Item
                        label={t("global.productGroup")}
                        name="productGroup"
                        rules={[
                            {
                                required: true,
                                message: t("global.please-productGroup"),
                            },
                        ]}
                        initialValue={props.campaignData.productGroup}
                    >
                        <Input disabled={props.isEditPage} />
                    </Form.Item>
                    <Form.Item
                        label={t("global.influencerComissionRatioo")}
                        name="influencerComissionRatio"
                        rules={[
                            {
                                required: true,
                                message: t("global.please-influencerComissionRatioo"),
                            },
                            {
                                validator: (rule: any, value: string, cb: (msg?: string) => void) => {
                                    +value < 0 ? cb(t("global.please-positiveAmount")) : cb();
                                },
                            },
                        ]}
                        initialValue={props.campaignData.influencerComissionRatio}
                    >
                        <Input disabled={props.isEditPage} addonBefore="%" type={"number"} />
                    </Form.Item>
                    {getAdditionalProductGroups()}
                    <p>
                        <span style={{ cursor: "pointer" }} onClick={() => addProductGroup()} className={"table-font"}>
                            + {t("global.add-productGroup")}
                        </span>
                    </p>
                </Col>
                <Col xs={24} sm={24} md={7} style={colStyle} span={7}>
                    <Form.Item
                        label={t("campaign.create.step3.bonusinfo")}
                        name="bonusInformation"
                        initialValue={props.campaignData.bonusInformation}
                    >
                        <TextArea disabled={props.isEditPage} rows={4} />
                    </Form.Item>
                </Col>
            </Row>
            <Form.Item>
                <Space direction="horizontal" style={{ width: "100%", justifyContent: "flex-end" }}>
                    <Button onClick={() => props.back()} style={{ marginLeft: "auto" }} type="primary">
                        {t("buttons.back")}
                    </Button>
                    <Button type="default" style={{ marginLeft: "auto" }} htmlType="submit">
                        {t("global.continue")}
                    </Button>
                </Space>
            </Form.Item>
        </Form>
    );
};
