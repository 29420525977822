import { Authenticated, Button, LayoutWrapper, Row, Table, Form, Input, Col, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { useEffect, useState } from "react";
import { Pagination } from "../../services/core/Pagination";
import { PaginationConstant } from "../../Constanst";
import { LookupsService } from "../../services";
import { localizationHelper } from "../../helpers/globalizationHelper";

export const Sectors = () => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [data, setData] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>();
    const t = useTranslate();

    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    const getData = () => {
        setDataLoading(true);
        LookupsService.searchSectors({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setData(resp?.data.data);
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount
            });
        });
    };

    const prepareFilterRequest = () => {
        const body: any = {
            pageNumber: pagination.current,
            pageSize: pagination.pageSize,
        };
        if (filterValues && filterValues.name) {
            body.name = filterValues.name;
        }

        return body;
    };

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total
        });
        setSearch(true);
    };
    const doFilter = (values: any) => {
        setPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize
        });
        setFilterValues(values);
        setSearch(true);
    };

    const columns = [
        {
            title: <span className="table-font">Id</span>,
            dataIndex: "id",
            key: "id",
        },
        {
            title: <span className="table-font">{t("pages.register.registerName")}</span>,
            dataIndex: "name",
            key: "name",
        },
    ];

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageContainer pageTitle={t("global.sectors")} helperContent={"Yardım içeriği eklenecek!"}>
                    <Col span={24}>
                        <Row>
                            <Form
                                name="basic"
                                initialValues={{ remember: true }}
                                onFinish={async (values) => {
                                    doFilter(values);
                                }}
                                autoComplete="off"
                                layout="vertical"
                                style={{ width: "100%" }}
                            >
                                <Row>
                                    <Col sm={24} xs={24} md={8} span={8}>
                                        <Form.Item name="name" label={"Ad"}>
                                            <Input />
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row justify="end">
                                    <Button htmlType="submit" type="ghost" style={{ margin: "10px" }}>
                                        {t("buttons.filter")}
                                    </Button>
                                </Row>
                            </Form>
                        </Row>
                        <Table
                            pagination={pagination}
                            loading={dataLoading}
                            scroll={{ x: "100%" }}
                            onChange={(page) => handleTableChange(page)}
                            dataSource={data}
                            columns={columns}
                        />
                    </Col>
                </PageContainer>
            </LayoutWrapper>
        </Authenticated>
    );
};
