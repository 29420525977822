import { EditOutlined } from "@ant-design/icons";
import { Button, Card, Form, Row, useTranslate } from "@pankod/refine";
import { Fenomio_Common_BaseModels_Api_DetailItemDto } from "services";
import { PageProps } from "./PageProps";
import { Money } from "services/models/money";

interface SummaryPageProps extends PageProps {
    editPage: (pageNumber: number) => void;
    countries: Fenomio_Common_BaseModels_Api_DetailItemDto[];
    categories: any[];
}

export const FourthPage = (props: SummaryPageProps) => {
    const t = useTranslate();

    return (
        <>
            <Form onFinish={props.onFinish}>
                <Card
                    title={
                        <span className="table-font" style={{ fontSize: "larger" }}>
                            {t("global.campaign")}
                        </span>
                    }
                    bodyStyle={{ padding: "10px", paddingLeft: "20px" }}
                >
                    <Form.Item label={t("campaign.create.step1.campaignNameLabel")} labelCol={{ span: 7 }} style={{ marginBottom: "0" }}>
                        <span>{props.campaignData.campaignName}</span>
                    </Form.Item>
                    <Form.Item
                        label={t("campaign.create.step1.campaignExplanationLabel")}
                        labelCol={{ span: 7 }}
                        style={{ marginBottom: "0" }}
                    >
                        <span>{props.campaignData.campaignExplanation}</span>
                    </Form.Item>
                    <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                        <span className="clickable-span" onClick={() => props.editPage(0)}>
                            <EditOutlined />
                        </span>
                    </Row>
                </Card>
                <br />
                <Card
                    title={
                        <span className="table-font" style={{ fontSize: "larger" }}>
                            {t("campaign.create.step2.stepTitle")}
                        </span>
                    }
                    bodyStyle={{ padding: "10px", paddingLeft: "20px" }}
                >
                    <Form.Item label={t("global.location")} labelCol={{ span: 7 }} style={{ marginBottom: "0" }}>
                        <span>{props.countries.filter((x: any) => x.id === props.campaignData.country)[0].text}</span>
                    </Form.Item>
                    <Form.Item label={t("global.categories")} labelCol={{ span: 7 }} style={{ marginBottom: "0" }}>
                        {props.categories
                            .filter((c) => props.campaignData.categories?.includes(c.id))
                            .map((c) => "#" + c.name)
                            .join(" - ")}
                    </Form.Item>
                    <Form.Item
                        label={t("campaign.create.step2.whitelistCategoriesLabel")}
                        labelCol={{ span: 7 }}
                        style={{ marginBottom: "0" }}
                    >
                        {props.categories
                            .filter((c) => props.campaignData.whitelistCategories?.includes(c.id))
                            .map((c) => "#" + c.name)
                            .join(" - ")}
                    </Form.Item>
                    <Form.Item
                        label={t("campaign.create.step2.blacklistCategoriesLabel")}
                        labelCol={{ span: 7 }}
                        style={{ marginBottom: "0" }}
                    >
                        {props.categories
                            .filter((c) => props.campaignData.blacklistCategories?.includes(c.id))
                            .map((c) => "#" + c.name)
                            .join(" - ")}
                    </Form.Item>
                    <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                        <span className="clickable-span" onClick={() => props.editPage(1)}>
                            <EditOutlined />
                        </span>
                    </Row>
                </Card>
                <br />
                <Card
                    title={
                        <span className="table-font" style={{ fontSize: "larger" }}>
                            {t("campaign.create.step3.stepTitle")}
                        </span>
                    }
                    bodyStyle={{ padding: "10px", paddingLeft: "20px" }}
                >
                    <Form.Item label={t("pages.phenomenon.amount")} labelCol={{ span: 7 }} style={{ marginBottom: "0" }}>
                        <span>{new Money(props.campaignData.totalAmount).toStringWithCurrency()}</span>
                    </Form.Item>
                    <Form.Item
                        label={t("campaign.create.step3.perClickActionInstallInfluencerEarning")}
                        labelCol={{ span: 7 }}
                        style={{ marginBottom: "0" }}
                    >
                        <span>{new Money(props.campaignData.amountByClick).toStringWithCurrency()}</span>
                    </Form.Item>
                    <Form.Item label={t("campaign.create.step3.minInfCount")} labelCol={{ span: 7 }} style={{ marginBottom: "0" }}>
                        <span>{props.campaignData.minimumInfluencerCount}</span>
                    </Form.Item>
                    <Form.Item label={t("global.timeInterval")} labelCol={{ span: 7 }} style={{ marginBottom: "0" }}>
                        <span>
                            {new Date(props.campaignData.startDate).toDateString()} - {new Date(props.campaignData.endDate).toDateString()}
                        </span>
                    </Form.Item>
                    <Form.Item label={t("global.targetLink")} labelCol={{ span: 7 }} style={{ marginBottom: "0" }}>
                        <span>{props.campaignData.campaignLink}</span>
                    </Form.Item>
                    <Row style={{ display: "flex", justifyContent: "flex-end" }}>
                        <span className="clickable-span" onClick={() => props.editPage(2)}>
                            <EditOutlined />
                        </span>
                    </Row>
                </Card>
                <br />
                <Form.Item wrapperCol={{ span: 10, offset: 10 }}>
                    <Button
                        style={{ marginLeft: "auto", marginRight: "5px" }}
                        type="primary"
                        onClick={() => {
                            props.onBack(props.campaignData);
                        }}
                    >
                        {t("buttons.back")}
                    </Button>
                    <Button type="default" htmlType="submit" style={{ width: "100px" }} loading={props.isLoading}>
                        {t("global.accept", "Kabul Et")}
                    </Button>
                </Form.Item>
            </Form>
        </>
    );
};
