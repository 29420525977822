import { activationRoutes } from "pages/auth/activation/activationRoutes";
import { resetPasswordRoutes } from "pages/auth/resetPassword/resetPasswordRoutes";
import { brandRoutes } from "pages/brand/brandRoutes";
import { campaignListRoutes, campaignDetailRoutes, campaignCreateRoutes } from "pages/campaign/campaignRoutes";
import { campaignRoutes } from "pages/campaign/createOffer/campaignRoutes";
import { categoryRoutes } from "pages/categories/categoryRoutes";
import { dashboardRoutes } from "pages/dashboard/dashboardRoutes";
import { influencersRoutes } from "pages/influencers/influencersRoutes";
import { notificationRoutes } from "pages/notifications/notificationRoutes";
import { paymentRoutes } from "pages/payment/paymentRoutes";
import { profileRoutes } from "pages/profile/profileRoutes";
import { reportsRoutes } from "pages/reports/reportsRoutes";
import { myCampaignsRoutes } from "pages/myCampaigns/myCampaignsRoutes";
import { walletRoutes } from "pages/wallet/walletRoutes";
import { managementPanelRoutes } from "pages/managementPanel/managementPanelRoutes";
import { signAgreementsRoutes } from "pages/auth/sign-agreements/signAgreementsRoutes";

const ApplicationRoutes = [
    ...dashboardRoutes(),
    ...profileRoutes(),
    ...campaignRoutes(),
    ...categoryRoutes(),
    ...activationRoutes(),
    ...resetPasswordRoutes(),
    ...campaignListRoutes(),
    ...campaignDetailRoutes(),
    ...campaignCreateRoutes(),
    ...influencersRoutes(),
    ...brandRoutes(),
    ...notificationRoutes(),
    ...reportsRoutes(),
    ...paymentRoutes(),
    ...myCampaignsRoutes(),
    ...walletRoutes(),
    ...managementPanelRoutes(),
    ...signAgreementsRoutes()
];

export default ApplicationRoutes;
