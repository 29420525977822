import { FunctionComponent, useState } from "react";
import { CampaignType } from "../../../../../Constanst";
import { ActionCampaignDetail, AmazonCampaignDetail, ClickCampaignDetil, DownloadCampaignDetail, SellingCampaignDetail } from "./CampaignTypes";
import { Col, Modal, Row } from "antd";
import { CampaignsService } from "../../../../../services";
import UserHelper from "../../../../../helpers/userHelper";
import { useNavigation, useTranslate } from "@pankod/refine";
import { localizationHelper } from "helpers/globalizationHelper";

interface InfluencerCampaignDetailProps {
    campaignDetail: any;
    campaignType: CampaignType;
    refresh: any;
    loading: any;
    showCampaignState: string;
}

export const InfluencerCampaignDetail: FunctionComponent<InfluencerCampaignDetailProps> = (props: InfluencerCampaignDetailProps) => {
    const { push } = useNavigation();
    const t = useTranslate();

    const [isApplyModalVisible, setIsApplyModalVisible] = useState(false);
    const [isApplyErrorModalVisible, setIsApplyErrorModalVisible] = useState(false);
    const [applyErrorMessage, setApplyErrorMessage] = useState("");
    const [applySuccessMessage, setApplySuccessMessage] = useState("");
    const [isResponseInviteModalVisible, setIsResponseInviteModalVisible] = useState(false);
    const [isResponseInviteErrorModalVisible, setIsResponseInviteErrorModalVisible] = useState(false);
    const [responseInviteMessage, setResponseInviteMessage] = useState("");
    const [responseInviteErrorMessage, setResponseInviteErrorMessage] = useState("");

    const handleCancel = () => {
        setIsApplyModalVisible(false);
        push("/campaign/list");
    };

    const apply = (campaignId: any) => {
        CampaignsService.applyCampaign({
            campaignId: campaignId,
            influencerId: UserHelper.getId(),
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                if (props.campaignType === CampaignType.amazon) {
                    push("/campaign-influencer/list");
                }
                setApplySuccessMessage(resp.message);
                setIsApplyModalVisible(true);
            })
            .catch((err) => {
                if (props.campaignType === CampaignType.amazon) {
                    push("/campaign-influencer/list");
                }
                setApplyErrorMessage(err.body.message);
                setIsApplyErrorModalVisible(true);
            });
    };

    const rejectInvite = (campaignId: any) => {
        CampaignsService.rejectCampaignInvite({
            campaignId: campaignId,
            influencerId: UserHelper.getId(),
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                setIsResponseInviteModalVisible(true);
                setResponseInviteMessage("Kampanya daveti red edildi");
            })
            .catch((err) => {
                setResponseInviteErrorMessage(err.body.message);
                setIsResponseInviteErrorModalVisible(true);
            });
    };

    const acceptInvite = (campaignId: any) => {
        CampaignsService.acceptCampaignInvite({
            campaignId: campaignId,
            influencerId: UserHelper.getId(),
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                setIsResponseInviteModalVisible(true);
                setResponseInviteMessage("Kampanya daveti kabul edildi.");
            })
            .catch((err) => {
                setResponseInviteErrorMessage(err.body.message);
                setIsResponseInviteErrorModalVisible(true);
            });
    };

    const handleApplyErrorCancel = () => {
        setIsApplyErrorModalVisible(false);
    };

    return (
        <>
            <Modal visible={isApplyModalVisible} centered footer={[]} onCancel={handleCancel}>
                <div style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>{t("global.campaignGet")}</h3>
                    <p>{applySuccessMessage}</p>
                </div>
            </Modal>
            <Modal visible={isApplyErrorModalVisible} centered footer={[]} onCancel={handleApplyErrorCancel}>
                <div style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>{t("global.campaignGetFail")}</h3>
                    <p>{applyErrorMessage}</p>
                </div>
            </Modal>
            <Modal visible={isResponseInviteModalVisible} centered footer={[]} onCancel={handleCancel}>
                <div style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>Yanıtınız Alınmıştır</h3>
                    <p>{responseInviteMessage}</p>
                </div>
            </Modal>
            <Modal visible={isResponseInviteErrorModalVisible} centered footer={[]} onCancel={handleApplyErrorCancel}>
                <div style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>Yanıtınız Alınamadı</h3>
                    <p>{responseInviteErrorMessage}</p>
                </div>
            </Modal>
            <Row style={{ marginBottom: "75px" }}>
                <Col xs={24} sm={24} md={12} span={12}>
                    {!props.loading && props.campaignType === CampaignType.action && (
                        <ActionCampaignDetail
                            apply={apply}
                            acceptInvite={acceptInvite}
                            rejectInvite={rejectInvite}
                            campaignDetail={props.campaignDetail}
                            showCampaignState={props.showCampaignState}
                        />
                    )}
                    {!props.loading && props.campaignType === CampaignType.clicking && (
                        <ClickCampaignDetil
                            apply={apply}
                            acceptInvite={acceptInvite}
                            rejectInvite={rejectInvite}
                            campaignDetail={props.campaignDetail}
                            showCampaignState={props.showCampaignState}
                        />
                    )}
                    {!props.loading && props.campaignType === CampaignType.download && (
                        <DownloadCampaignDetail
                            apply={apply}
                            acceptInvite={acceptInvite}
                            rejectInvite={rejectInvite}
                            campaignDetail={props.campaignDetail}
                            showCampaignState={props.showCampaignState}
                        />
                    )}
                    {!props.loading && props.campaignType === CampaignType.selling && (
                        <SellingCampaignDetail
                            apply={apply}
                            acceptInvite={acceptInvite}
                            rejectInvite={rejectInvite}
                            campaignDetail={props.campaignDetail}
                            showCampaignState={props.showCampaignState}
                        />
                    )}
                    {!props.loading && props.campaignType === CampaignType.amazon && (
                        <AmazonCampaignDetail
                            apply={apply}
                            acceptInvite={acceptInvite}
                            rejectInvite={rejectInvite}
                            campaignDetail={props.campaignDetail}
                            showCampaignState={props.showCampaignState}
                        />
                    )}
                </Col>
            </Row>
        </>
    );
};
