// @flow
import * as React from "react";
import { Authenticated, LayoutWrapper } from "@pankod/refine";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { AdminInfluencerList, BrandInfluencerList } from "./Components";

type Props = {};

type State = {};

export class InfluencersListPage extends React.Component<Props, State> {
    render() {
        return (
            <Authenticated>
                <LayoutWrapper>
                    {(RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root)) && <AdminInfluencerList />}
                    {RoleHelper.isInRole(Roles.Brand) && <BrandInfluencerList />}
                </LayoutWrapper>
            </Authenticated>
        );
    }
}
