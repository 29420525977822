import { Table, useNavigation, useTranslate } from "@pankod/refine";
import { Modal } from "antd";
import { PageContainer } from "components/pageContainer/pageContainer";
import { GetCampaignStateForInfluencer, GetCampaignTypeText } from "helpers/StateHelpers";
import { FunctionComponent, useEffect, useState } from "react";
import { CampaignInfluencerStates, CampaignStates, CampaignType, PaginationConstant } from "../../../../Constanst";
import { localizationHelper } from "../../../../helpers/globalizationHelper";
import UserHelper from "../../../../helpers/userHelper";
import { CampaignsService, LookupsService } from "../../../../services";
import { Pagination } from "../../../../services/core/Pagination";
import { FilterComponent } from "./filterComponent";
import { Link } from "react-router-dom";
import { Money } from "services/models/money";

interface InfluencerCampaignListProps {}

export const InfluencerCampaignList: FunctionComponent<InfluencerCampaignListProps> = () => {
    const { push } = useNavigation();

    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
    });

    const [dataLoading, setDataLoading] = useState(false);
    const [campaigns, setCampaigns] = useState<any>();
    const [countries, setCountries] = useState<any>();
    const [filterValues, setFilterValues] = useState<any>();
    const [isApplyModalVisible, setIsApplyModalVisible] = useState(false);
    const [isApplyErrorModalVisible, setIsApplyErrorModalVisible] = useState(false);
    const t = useTranslate();
    const [applyErrorMessage, setApplyErrorMessage] = useState("");

    const showDetail = (row: any) => {
        const hasCampaignStateModel = row.campaignStateModel !== null;
        if (hasCampaignStateModel) {
            const stateCampaign = row.campaignStateModel.campaignState;
            if (stateCampaign !== CampaignStates.WaitingForPayment) {
                const hasCampaignInfluencerStateModel = row.campaignInfluencerStateModel !== null;
                if (hasCampaignInfluencerStateModel) {
                    const stateCampaignInfluencer = row.campaignInfluencerStateModel.campaignInfluencerState;
                    if (
                        row.campaignInfluencerId != null &&
                        (stateCampaignInfluencer === CampaignInfluencerStates.Accepted ||
                            stateCampaignInfluencer === CampaignInfluencerStates.BudgetCompleted)
                    ) {
                        return "/campaign-influencer/detail/" + row.campaignInfluencerId;
                    } else {
                        return { pathname: "/campaign/detail", state: row };
                    }
                } else {
                    return { pathname: "/campaign/detail", state: row };
                }
            }
        }
        return "";
    };

    const showAction = (row: any) => {
        const campaignInfluencerState =
            row.campaignInfluencerStateModel != null ? row.campaignInfluencerStateModel.campaignInfluencerState : null;
        const campaignState = row.campaignStateModel.campaignState;

        if (row.typeHash !== CampaignType.selling && row.typeHash !== CampaignType.amazon) {
            if (
                (campaignState === CampaignStates.Accepted && campaignInfluencerState === null) ||
                (campaignState === CampaignStates.Accepted &&
                    campaignInfluencerState !== null &&
                    (campaignInfluencerState === CampaignInfluencerStates.Rejected ||
                        campaignInfluencerState === CampaignInfluencerStates.Revert))
            ) {
                return (
                    <span>
                        <Link to={showDetail(row)}>
                            <span className="clickable-span" style={{ color: "purple", textAlign: "center" }}>
                                {t("global.apply", "Başvur")}
                            </span>
                        </Link>
                    </span>
                );
            }
            return (
                <Link to={showDetail(row)}>
                    <span className="clickable-span" style={{ color: "#952AE7" }}>
                        {GetCampaignStateForInfluencer(campaignState, campaignInfluencerState)}
                    </span>
                </Link>
            );
        }

        if (row.typeHash === CampaignType.selling || row.typeHash === CampaignType.amazon) {
            if (campaignState === CampaignStates.Accepted && campaignInfluencerState === CampaignInfluencerStates.Accepted) {
                return (
                    <span>
                        <span className="clickable-span" onClick={() => apply(row)} style={{ color: "purple", textAlign: "center" }}>
                            {t("global.get-link")}
                        </span>
                    </span>
                );
            }
            if (row.typeHash === CampaignType.amazon) {
                return (
                    <span>
                        <span className="clickable-span" onClick={() => apply(row)} style={{ color: "purple", textAlign: "center" }}>
                            {t("global.get-link")}
                        </span>
                    </span>
                );
            }

            return (
                <Link to={showDetail(row)}>
                    <span className="clickable-span" style={{ color: "#952AE7" }}>
                        {GetCampaignStateForInfluencer(campaignState, campaignInfluencerState)}
                    </span>
                </Link>
            );
        }
    };

    const handleCancel = () => {
        setIsApplyModalVisible(false);
        setPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize,
        });

        setSearch(true);
    };

    const handleApplyErrorCancel = () => {
        setIsApplyErrorModalVisible(false);
    };

    const doFilter = (values: any) => {
        setPagination({
            current: PaginationConstant.initialPage,
            pageSize: PaginationConstant.initialPageSize,
        });
        setFilterValues(values);
        setSearch(true);
    };

    const [search, setSearch] = useState<boolean>(true);

    useEffect(() => {
        if (search) getCampaigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search]);

    useEffect(() => {
        getCountries();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCountries = () => {
        LookupsService.getCountries({ acceptLanguage: localizationHelper.getLocale() }).then((res) => {
            setCountries(res.data ?? []);
        });
    };

    const getCampaigns = () => {
        setDataLoading(true);
        CampaignsService.getCampaignInfluencersCampaignPage({
            body: prepareFilterRequest(),
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            setDataLoading(false);
            setCampaigns(resp?.data.data);
            setPagination({
                current: pagination.current,
                pageSize: pagination.pageSize,
                total: resp?.data?.pageInfo.totalItemCount,
            });
            setSearch(false);
        });
    };

    const prepareFilterRequest = () => {
        const campaignSearch: any = {};
        if (filterValues) {
            campaignSearch.campaignType =
                filterValues.campaignType !== undefined && filterValues.campaignType !== null ? filterValues.campaignType : 0;
            campaignSearch.campaignName =
                filterValues.campaignName !== undefined && filterValues.campaignName !== null ? filterValues.campaignName : null;
            campaignSearch.startDate =
                filterValues.date !== undefined && filterValues.date !== null && filterValues.date[0]
                    ? new Date(filterValues.date[0]).toDateString()
                    : null;
            campaignSearch.endDate =
                filterValues.date !== undefined && filterValues.date !== null && filterValues.date[1]
                    ? new Date(filterValues.date[1]).toDateString()
                    : null;

            campaignSearch.campaignStates = filterValues.status ? [filterValues.status] : [];
            campaignSearch.campaignInfluencerStates = [];
            campaignSearch.state = filterValues.status ?? 0;

            campaignSearch.categoryIds = filterValues.categoryIds ?? [];
            campaignSearch.countryIds = filterValues.countryId ? [filterValues.countryId] : [];
            campaignSearch.pageNumber = pagination.current;
            campaignSearch.pageSize = pagination.pageSize;
            campaignSearch.platforms = filterValues.platforms ?? [];
        } else {
            campaignSearch.campaignType = 0;
            campaignSearch.campaignName = null;
            campaignSearch.startDate = null;
            campaignSearch.endDate = null;

            campaignSearch.campaignStates = [];
            campaignSearch.campaignInfluencerStates = [];
            campaignSearch.state = 0;

            campaignSearch.categoryIds = [];
            campaignSearch.countryIds = [];
            campaignSearch.pageNumber = pagination.current;
            campaignSearch.pageSize = pagination.pageSize;
            campaignSearch.platforms = [];
        }
        return campaignSearch;
    };

    const handleTableChange = (page: any) => {
        setPagination({
            current: page.current,
            pageSize: page.pageSize,
            total: page.total,
        });
        setSearch(true);
    };

    const apply = (campaign: any) => {
        CampaignsService.applyCampaign({
            campaignId: campaign.id,
            influencerId: UserHelper.getId(),
            acceptLanguage: localizationHelper.getLocale(),
        })
            .then((resp) => {
                if (campaign.typeHash === CampaignType.selling) {
                    setIsApplyModalVisible(true);
                    push("/campaign-influencer/detail/sale/" + campaign.id);
                } else if (campaign.typeHash === CampaignType.amazon) {
                    push("/campaign-influencer/list");
                }
            })
            .catch((err) => {
                if (campaign.typeHash === CampaignType.amazon) {
                    push("/campaign-influencer/list");
                }
                setApplyErrorMessage(err.body.message);
                setIsApplyErrorModalVisible(true);
            });
    };

    const columns = [
        {
            title: <span className="table-font">{t("global.campaign-name")}</span>,
            dataIndex: "name",
            key: "name",
            render: (text: any, row: any) => (
                <Link to={showDetail(row)}>
                    <span className="clickable-span" style={{ color: "#952AE7" }}>
                        {text}
                    </span>
                </Link>
            ),
        },
        {
            title: <span className="table-font">{t("global.brand", "Marka")}</span>,
            dataIndex: "brandName",
            key: "brandName",
            render: (text: any, row: any) => (
                <Link to={"/brands/detail/" + row.brandId}>
                    <span className="clickable-span" style={{ color: "#952AE7" }}>
                        {text}
                    </span>
                </Link>
            ),
        },
        {
            title: <span className="table-font">{t("global.campaignType")}</span>,
            dataIndex: "type",
            key: "type",
            render: (text: any, row: any) => <span>{GetCampaignTypeText(row.typeHash)}</span>,
        },
        {
            title: <span className="table-font">{t("global.start")}</span>,
            dataIndex: "startDate",
            key: "startDate",
        },
        {
            title: <span className="table-font">{t("global.end")}</span>,
            dataIndex: "endDate",
            key: "endDate",
            render: (text: string, row: any) => {
                if (row.typeHash === CampaignType.amazon) {
                    return " - ";
                } else {
                    return text;
                }
            },
        },
        {
            title: <span className="table-font">{t("campaign.create.step3.amountByInfluencerLabell")}</span>,
            dataIndex: "balancePerInfluencer",
            key: "balancePerInfluencer",
            render: (balancePerInfluencer: string, row: any) => (
                <>{new Money(balancePerInfluencer).toStringWithCurrency(row?.currency === 1 ? "$" : "₺")}</>
            ),
        },
        {
            title: <span className="table-font">{t("global.unit-price")}</span>,
            dataIndex: "unitPrice",
            key: "unitPrice",
            render: (unitPrice: string, row: any) => <>{new Money(unitPrice).toStringWithCurrency(row?.currency === 1 ? "$" : "₺")}</>,
        },
        {
            title: <span className="table-font">Aksiyon</span>,
            dataIndex: "",
            key: "",
            render: (row: any) => {
                return showAction(row);
            },
        },
    ];

    return (
        <>
            <Modal visible={isApplyModalVisible} centered footer={[]} onCancel={handleCancel}>
                <div style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>{t("global.campaignGet")}</h3>
                    <p>{t("global.accountCheck")}</p>
                </div>
            </Modal>
            <Modal visible={isApplyErrorModalVisible} centered footer={[]} onCancel={handleApplyErrorCancel}>
                <div style={{ textAlign: "center" }}>
                    <h3 style={{ fontWeight: "bold", color: "#952AE7", fontSize: "20px" }}>{t("global.campaign-apply-error")}</h3>
                    <p>{applyErrorMessage}</p>
                </div>
            </Modal>
            <PageContainer pageTitle={t("global.campaigns")} helperContent={t("global.influencer.campaigns.helper-content.general-view")}>
                <FilterComponent count={pagination.total} doFilter={doFilter} countries={countries} />
                <Table
                    columns={columns}
                    pagination={pagination}
                    loading={dataLoading}
                    onChange={(page) => handleTableChange(page)}
                    scroll={{ x: "100%" }}
                    dataSource={campaigns}
                />
            </PageContainer>
        </>
    );
};
