import { Platforms } from "Constanst";

type PlatformName = keyof typeof Platforms;

export interface SocialMedia {
    followers: number;
    isApproved: boolean;
    link: string;
    name: PlatformName;
    username: string;
    followersCount: string;
}

export const getSocialMediaFlowers = (name: PlatformName, socialMediaList: SocialMedia[]): string => {
    const socialMedia = socialMediaList.find((media) => media.name === name);

    if (socialMedia) {
        return socialMedia.followersCount;
    }
    return "-";
};
