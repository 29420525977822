import { Checkbox, Form, LayoutWrapper, Modal, useNavigation, useTranslate } from "@pankod/refine";
import Header from "../header";
import { Button, Card, Layout, Row, Typography } from "antd";
import { localizationHelper } from "helpers/globalizationHelper";
import { useEffect, useMemo, useState } from "react";
import { storageHelper } from "helpers/storageHelper";
import * as Constants from '../../../Constanst'
import { useAtom } from "jotai";
import { latestAgreementsAtom } from "providers/atoms";
import UserAgreement from "components/UserAgreement/UserAgreement";
import { AgreementResponse } from "services/models/AgreementResponse";
import { OptionItem } from "services/models/OptionItem";
import { AgreementsService } from "services/services/AgreementsService";
import { secondTitleStyle } from "../styles";

const { Title } = Typography;

export const SignAgreementsPage = (props: any) => {
    const [agreementVisible, setagreementVisible] = useState(false);
    const [activeAgreement, setActiveAgreement] = useState<AgreementResponse|undefined>(undefined);
    const [isLoading, setLoading] = useState(false);
    const [agreements] = useAtom(latestAgreementsAtom);
    const { push } = useNavigation();
    const [form] = Form.useForm();

    const translate = useTranslate();

    useEffect(() => {
        if(!agreements || 
            agreements.length===0 || 
            !props.location.state || 
            !props.location.state.missingAgreements ||
            props.location.state.missingAgreements.length===0 )
            return;
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [agreements, props.location.state]);

    const refreshWindow = () => {
        storageHelper.setItem(Constants.TOKEN_KEY, props.location.state.accessToken);
        storageHelper.setItem(Constants.REFRESH_TOKEN_KEY, props.location.state.refreshToken);
        storageHelper.setItem(Constants.REFRESH_TOKEN_EXPIRE_KEY, props.location.state.refreshTokenExpireDate);
        push("/");
        const event = new CustomEvent("updateLogin", { detail: "Example of an event" });
        document.dispatchEvent(event);
    }

    const signAgreements = (values:any) => {
        var signedAgreements:OptionItem[] = [];
        for (const key in values) {
            if (Object.prototype.hasOwnProperty.call(values, key)) {
                const element = values[key];
                if(element)
                    signedAgreements.push({
                        id:key,
                        value:element
                    });
            }
        }
        if(signedAgreements.length>0)
            storageHelper.setItem(Constants.TOKEN_KEY, props.location.state.accessToken);
        setLoading(true);
        AgreementsService.signAgreements({
            body:{
                agreements:signedAgreements
            },
            acceptLanguage:localizationHelper.getLocale()
        }).then(()=>{
            setLoading(false);
            refreshWindow();
        }).catch(()=>{
            setLoading(false);
            storageHelper.removeItem(Constants.TOKEN_KEY);
        });
    }

    const kvkk = useMemo(()=>{
        var missingAgreements:OptionItem[]|undefined = props.location.state.missingAgreements;
        if(!missingAgreements)
            return undefined;
        var filtered = agreements?.filter(x=>x.agreementType===3);
        let selected: AgreementResponse|undefined;
        filtered?.forEach(element => {
            if(missingAgreements?.some(x=>x.id===element.id))
                selected = element;
        });
        return selected;
    },[agreements, props.location.state.missingAgreements]);

    const clarification = useMemo(()=>{
        var missingAgreements:OptionItem[]|undefined = props.location.state.missingAgreements;
        if(!missingAgreements)
            return undefined;
        var filtered = agreements?.filter(x=>x.agreementType===4);
        let selected: AgreementResponse|undefined;
        filtered?.forEach(element => {
            if(missingAgreements?.some(x=>x.id===element.id))
                selected = element;
        });
        return selected;
    },[agreements, props.location.state.missingAgreements]);

    const service = useMemo(()=>{
        var missingAgreements:OptionItem[]|undefined = props.location.state.missingAgreements;
        if(!missingAgreements)
            return undefined;
        var filtered = agreements?.filter(x=>x.agreementType===1);
        let selected: AgreementResponse|undefined;
        filtered?.forEach(element => {
            if(missingAgreements?.some(x=>x.id===element.id))
                selected = element;
        });
        return selected;
    },[agreements, props.location.state.missingAgreements]);

    const ipaz = useMemo(()=>{
        var missingAgreements:OptionItem[]|undefined = props.location.state.missingAgreements;
        if(!missingAgreements)
            return undefined;
        var filtered = agreements?.filter(x=>x.agreementType===5);
        let selected: AgreementResponse|undefined;
        filtered?.forEach(element => {
            if(missingAgreements?.some(x=>x.id===element.id))
                selected = element;
        });
        return selected;
    },[agreements, props.location.state.missingAgreements]);

    const KvkkPolicyText = ()=>{
        return (
            <span style={{ color: "#952ae7" }}
                onClick={() => {
                    setActiveAgreement(kvkk)
                    setagreementVisible(true);
                }}
            >
                {translate("pages.register.kvkkPolicyLink")}
            </span>
        );
    }

    const KvkkClarificationText = ()=>{
        return (
            <span style={{ color: "#952ae7" }}
                onClick={() => {
                    setActiveAgreement(clarification)
                    setagreementVisible(true);
                }}
            >
                {translate("pages.register.clarificationLink")}
            </span>
        );
    }

    const KvkkWholeLine = () => {
        const fullText = translate("pages.register.kvkkCheckbox",{0:"!0!"});
        const parts = fullText.split("!0!");
        return (
            <span>
                {parts[0]||""}
                <KvkkPolicyText/>
                {parts[1]||""}
            </span>
        )
    }

    const ClarificationWholeLine = () => {
        const fullText = translate("pages.register.clarificationCheckbox",{0:"!0!"});
        const parts = fullText.split("!0!");
        return (
            <span>
                {parts[0]||""}
                <KvkkClarificationText/>
                {parts[1]||""}
            </span>
        )
    }

    return (
        <>
            <LayoutWrapper
                Sider={() => <></>}
                Footer={() => <></>}
                Title={() => <></>}
                Header={() => (
                    <>
                        <Header onLoginClicked={() => {}} onRegisterClicked={() => {}} />
                    </>
                )}
            >
                <div style={{ height: "200px", margin: "-30px -10px 0px -25px" }}></div>
                <Layout>
                    <Row justify="center" align="middle">
                        <Card style={{ maxWidth: 500, textAlign: "center" }}>
                            <Title level={2} style={{ ...secondTitleStyle, verticalAlign: "middle" }}>
                                {translate("pages.register.approveAgreements")}
                            </Title>
                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={async (values) => {
                                    signAgreements(values);
                                }}
                                requiredMark={false}
                            >
                                {service && <Form.Item
                                    name={service?.id||"service"}
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                return value ? Promise.resolve() : Promise.reject(new Error("Sözleşmeyi onaylamalısınız"));
                                            },
                                        },
                                    ]}
                                >
                                    <Checkbox>
                                        <span
                                            style={{ color: "#952ae7" }}
                                            onClick={() => {
                                                setActiveAgreement(service)
                                                setagreementVisible(true);
                                            }}
                                        >
                                            {translate("pages.register.iacceptagreement", " Kabul Ediyorum.")}
                                        </span>
                                    </Checkbox>
                                </Form.Item>}
                                {kvkk && <Form.Item
                                    name={kvkk?.id||"kvkk"}
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                return value ? Promise.resolve() : Promise.reject(new Error("Sözleşmeyi onaylamalısınız"));
                                            },
                                        },
                                    ]}
                                >
                                    <Checkbox>
                                        <KvkkWholeLine/>
                                    </Checkbox>
                                </Form.Item>}
                                {clarification && <Form.Item
                                    name={clarification?.id||"clarification"}
                                    valuePropName="checked"
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                return value ? Promise.resolve() : Promise.reject(new Error("Sözleşmeyi onaylamalısınız"));
                                            },
                                        },
                                    ]}
                                >
                                    <Checkbox>
                                        <ClarificationWholeLine/>
                                    </Checkbox>
                                </Form.Item>}
                                {ipaz && <Form.Item
                                    name={ipaz?.id||"ipaz"}
                                    valuePropName="checked"
                                >
                                    <Checkbox>
                                        <span
                                            style={{ color: "#952ae7" }}
                                            onClick={() => {
                                                setActiveAgreement(ipaz)
                                                setagreementVisible(true);
                                            }}
                                        >
                                            {translate("pages.register.ipaz", " Kabul Ediyorum.")}
                                        </span>
                                    </Checkbox>
                                </Form.Item>}
                                <Button className="ant-btn-block" type="default" size="large" htmlType="submit" loading={isLoading} block>
                                    {translate("pages.register.registerButton", "Kaydet")}
                                </Button>
                                <Modal
                                    visible={agreementVisible}
                                    okType="default"
                                    okText={translate("pages.register.agreement.acceptButton", "Okudum, Onaylıyorum")}
                                    onOk={() => {
                                        setagreementVisible(false);
                                        const dynamicObj:any = {};
                                        dynamicObj[activeAgreement?.id||""] = true;
                                        form.setFieldsValue(dynamicObj);
                                    }}
                                    cancelButtonProps={{ hidden: true }}
                                    onCancel={() => {
                                        setagreementVisible(false);
                                        const dynamicObj:any = {};
                                        dynamicObj[activeAgreement?.id||""] = false;
                                        form.setFieldsValue(dynamicObj);
                                    }}
                                    width={800}
                                >
                                    <UserAgreement body={activeAgreement?.text} title={activeAgreement?.name}/>
                                </Modal>
                            </Form>
                        </Card>
                    </Row>
                </Layout>
            </LayoutWrapper>
        </>
    );
};
