import { PaginationConstant } from "Constanst";
import { FC, createContext, useState } from "react";
import { Pagination } from "services/core/Pagination";

const TableContext = createContext({
    pagination: { current: PaginationConstant.initialPage, pageSize: PaginationConstant.initialPageSize, total: 0 } as Pagination,
    setPagination: (params: any) => {},
});

export default TableContext;

export const TableContextProvider: FC = ({ children }) => {
    const [pagination, setPagination] = useState<Pagination>({
        current: PaginationConstant.initialPage,
        pageSize: PaginationConstant.initialPageSize,
        total: 0,
    });

    return <TableContext.Provider value={{ pagination, setPagination }}>{children}</TableContext.Provider>;
};
