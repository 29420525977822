//import { InfluencerImageUrl, BrandImageUrl } from "Constanst";
import { Roles } from "helpers/roleHelper";
import UserHelper from "../../helpers/userHelper";

type ProfileImgProps = {
    type: "" | Roles; //"Brand"|"Influencer"|"Admin"
    alt?: string;
    width?: number;
    height?: number;
    negative?: boolean;
};
const ProfileImg = (props: ProfileImgProps) => {
    const getDefaultImage = () => {
        let picSource = props.type === "Influencer" ? "/images/profile/profile_influencer" : "/images/profile/profile_brand";
        picSource += props.negative ? "_negative.svg" : ".svg";
        return picSource;
    };

    return (
        <img
            width={props.width}
            height={props.height}
            src={UserHelper.getLogoPath() ? UserHelper.getLogoPath() : getDefaultImage()}
            alt={props.alt}
            className="profile-pic"
        />
    );
};
export default ProfileImg;
