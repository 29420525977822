import { RouteProps } from "react-router-dom";
import { CampaignOfferPage } from "./CampaignOfferPage";
import { CreateByBrandPage } from "./CreateByBrandPage";
import { CreateByFenomioPage } from "./CreateByFenomioPage";

export const campaignRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: CampaignOfferPage,
            path: "/campaign/create/apply",
        },
        {
            exact: true,
            component: CreateByFenomioPage,
            path: "/campaign/create/apply/fenomio",
        },
        {
            exact: true,
            component: CreateByBrandPage,
            path: "/campaign/create/apply/brand",
        },
        {
            exact: true,
            component: CreateByBrandPage,
            path: "/campaign/update/:campaignId",
        },
    ];
};
