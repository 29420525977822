import { LayoutWrapper, useTranslate } from "@pankod/refine";
import { Card } from "antd";
import { RoleHelper, Roles } from "helpers/roleHelper";
import { ReactElement } from "react";
import UserHelper from "../../helpers/userHelper";
import { FenBreadCrumb } from "components/BreadCrumb/BreadCrumb";
import { BrandReceipts, InfluencerReceipts, UserInfo } from "modules/profile";

export const ReceiptsPage = () => {
    const t = useTranslate();
    const role = UserHelper.getRole();

    const receipts: { [key: string]: ReactElement } = { [Roles.Influencer]: <InfluencerReceipts />, [Roles.Brand]: <BrandReceipts /> };

    return (
        <LayoutWrapper>
            <FenBreadCrumb
                items={[
                    { name: t("global.profile"), url: "/profile/detail" },
                    { name: t("global.reciepts"), url: "" },
                ]}
            />
            {(RoleHelper.isInRole(Roles.Influencer) || RoleHelper.isInRole(Roles.Brand)) && (
                <Card id="receiptsCard">
                    <UserInfo />

                    {receipts[role]}
                </Card>
            )}
        </LayoutWrapper>
    );
};
