import { useEffect, useState } from "react";
import { InfluencersService, WalletConversion } from "services";

export const useInfluencerConversion = (influencerId: string) => {
    const [loading, setLoading] = useState(true);
    const [conversion, setConversion] = useState<WalletConversion>({
        currency: "",
        clickBalance: 0,
        filteredTotal: 0,
        actionBalance: 0,
        installBalance: 0,
        sellBalance: 0,
    });

    function fetchConversion(startDate: string | null = null, endDate: string | null = null) {
        setLoading(true);

        const request = InfluencersService.getWalletConversion(influencerId, { startDate, endDate });

        request
            .then((response) => {
                setConversion(response.data);
            })
            .finally(() => setLoading(false));

        return request;
    }

    useEffect(() => {
        const request = fetchConversion();

        return () => {
            request.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return [conversion, loading, fetchConversion] as const;
};
