import { Card, Col, Row } from "@pankod/refine";
import { PageTitle } from "components/PageTitle/PageTitle";
import { FunctionComponent } from "react";

interface PageContainerProps {
    children?: JSX.Element[] | JSX.Element;
    pageTitle: string;
    helperContent: React.ReactNode;
}

export const PageContainer: FunctionComponent<PageContainerProps> = (props: PageContainerProps) => {
    return (
        <Row gutter={[16, 16]} style={{height:'100%'}}>
            <Col span={24}>
                <Card style={{height:'100%'}}>
                    <PageTitle title={props.pageTitle} content={props.helperContent} />
                    {props.children}
                </Card>
            </Col>
        </Row>
    );
};
