import { RouteProps } from "react-router-dom";
import { BrandCreate } from "./create/BrandCreate";
import { BrandDetail } from "./detail/BrandDetail";
import { BrandList } from "./list/BrandList";
import { Receipt } from "./detail/Receipt";

export const brandRoutes = (): RouteProps[] => {
    return [
        {
            exact: true,
            component: BrandList,
            path: "/brands/list",
        },
        {
            exact: true,
            component: BrandCreate,
            path: "/brands/create",
        },
        {
            exact: true,
            component: BrandDetail,
            path: "/brands/detail/:brandId",
        },
        {
            exact: true,
            component: Receipt,
            path: "/brands/detail/:brandId/receipt",
        },
    ];
};
