import { LayoutWrapper, useNavigation, useTranslate } from "@pankod/refine";
import Header from "../header";
import { Button, Card, Layout, Row, Typography } from "antd";
import { LoginService } from "services";
import { localizationHelper } from "helpers/globalizationHelper";
import { useEffect } from "react";
import { activationMessageAtom, activationSuccessAtom } from "providers/atoms";
import { useAtom } from "jotai";

const { Title } = Typography;
let VALIDATE_STARTED = false;
export const ActivationPage = (props: any) => {
    const [restMessage, setRestMessage] = useAtom(activationMessageAtom);
    const [restSuccess, setRestSuccess] = useAtom(activationSuccessAtom);
    const { push } = useNavigation();

    const translate = useTranslate();

    useEffect(() => {
        if(!VALIDATE_STARTED)
            VALIDATE_STARTED = true;
        else
            return;
        comfirmEmail();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const comfirmEmail = async () => {
        LoginService.confirmEmail({ acceptLanguage: localizationHelper.getLocale(), id: props.match.params.id })

            .then((res) => {
                setRestSuccess(true);
                setRestMessage(res.message);
            })
            .catch((err) => {
                setRestSuccess(false);
                setRestMessage(err.body.message);
            });
    };

    return (
        <>
            <LayoutWrapper
                Sider={() => <></>}
                Footer={() => <></>}
                Title={() => <></>}
                Header={() => (
                    <>
                        <Header onLoginClicked={() => {}} onRegisterClicked={() => {}} />
                    </>
                )}
            >
                <div style={{ height: "200px", margin: "-30px -10px 0px -25px" }}></div>
                <Layout>
                    <Row justify="center" align="middle">
                        <Card style={{ maxWidth: 500, textAlign: "center" }}>
                            <Row justify="center">
                                {restSuccess && <Title level={3}> {translate("pages.auth.message.congratulations", "Tebrikler")}</Title>}
                            </Row>
                            <Row justify="center">
                                {restSuccess ? (
                                    <Title level={5}>{translate("global.mailSuccessConfirmation")}</Title>
                                ) : (
                                    translate(restMessage)
                                )}
                            </Row>
                            <br />
                            <Row justify="center">
                                {restSuccess && (
                                    <Button onClick={() => push("/login")} type="default">
                                        {translate("pages.auth.message.returnMain", "Giriş Sayfasına Dön")}
                                    </Button>
                                )}
                            </Row>
                        </Card>
                    </Row>
                </Layout>
            </LayoutWrapper>
        </>
    );
};
