import { Authenticated, LayoutWrapper, Space, Tabs, useTranslate } from "@pankod/refine";
import { PageContainer } from "components/pageContainer/pageContainer";
import { useState } from "react";
import ActiveCampaignsTab from "./components/activeCampaignsTab";
import IncomingOffersTab from "./components/incomingOffersTab";
import PastCampaignsTab from "./components/pastCampaignsTab";
import SubmittedOffersTab from "./components/submittedOffersTab";

const { TabPane } = Tabs;

export interface MyCampaignsProps {
    update: boolean;
    setUpdate: any;
    refresh: any;
}

export const MyCampaigns = () => {
    const [updateActiveCampaigns, setUpdateActiveCampaigns] = useState<any>(false);
    const [updateIncomingCampaigns, setUpdateIncomingCampaigns] = useState<any>(false);
    const [updateSubmittedCampaigns, setUpdateSubmittedCampaigns] = useState<any>(false);
    const [updatePastCampaigns, setUpdatePastCampaigns] = useState<any>(false);
    const t = useTranslate();

    const refresh = () => {
        setUpdateActiveCampaigns(true);
        setUpdateIncomingCampaigns(true);
        setUpdatePastCampaigns(true);
        setUpdateSubmittedCampaigns(true);
    };

    return (
        <Authenticated>
            <LayoutWrapper>
                <PageContainer
                    pageTitle={t("global.myCampaign")}
                    helperContent={t("global.influencer.mycampaignspage.helper-content.campaigns-info")}
                >
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={<Space direction="horizontal">{t("global.influencer.mycampaignspage.tabs.active-campaigns-title")}</Space>} key="1">
                            <ActiveCampaignsTab refresh={refresh} setUpdate={setUpdateActiveCampaigns} update={updateActiveCampaigns} />
                        </TabPane>
                        <TabPane tab={<Space direction="horizontal">{t("global.influencer.mycampaignspage.tabs.invited-campaigns-title")}</Space>} key="2">
                            <IncomingOffersTab refresh={refresh} setUpdate={setUpdateIncomingCampaigns} update={updateIncomingCampaigns} />
                        </TabPane>
                        <TabPane tab={<Space direction="horizontal">{t("global.influencer.mycampaignspage.tabs.applied-campaigns-title")}</Space>} key="3">
                            <SubmittedOffersTab
                                refresh={refresh}
                                update={updateSubmittedCampaigns}
                                setUpdate={setUpdateSubmittedCampaigns}
                            />
                        </TabPane>
                        <TabPane tab={<Space direction="horizontal">{t("global.influencer.mycampaignspage.tabs.old-campaigns-title")}</Space>} key="4">
                            <PastCampaignsTab refresh={refresh} update={updatePastCampaigns} setUpdate={setUpdatePastCampaigns} />
                        </TabPane>
                    </Tabs>
                </PageContainer>
            </LayoutWrapper>
        </Authenticated>
    );
};
