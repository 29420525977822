export const isValidAndSecureUrl = (_url:string) => {
    if(!_url.startsWith("https://"))
        return false;
    if(_url.includes(' '))
        return false;
    const urlParts = _url.split('.');
    if(urlParts.length<2)
        return false;
    for (let index = 0; index < urlParts.length; index++) {
        const element = urlParts[index];
        if(element.length<2)
            return false;
    }
    const slashSeperatedParts = _url.split('/');
    if(slashSeperatedParts.length<4)
        return false;
    return true;
}