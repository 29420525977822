// @flow
import { Authenticated, LayoutWrapper } from "@pankod/refine";
import { RoleHelper, Roles } from "helpers/roleHelper";
import * as React from "react";
import { BrandInfluencerDetail } from "./Components/BrandInfluencerDetail";
import { AdminInfluencerDetail } from "./Components/AdminInfluencer/AdminInfluencerDetail";
import { Influencer, InfluencersService } from "services";
import { localizationHelper } from "helpers/globalizationHelper";

type State = {
    showEdit: boolean;
    isLoading: boolean;
    influencer: Influencer | null;
    id: string;
};

export class InfluencersDetailPage extends React.Component<any, State> {
    constructor(props: any, context: any) {
        super(props, context);

        this.refresh = this.refresh.bind(this);
    }

    state = {
        showEdit: window.location.pathname.includes("edit"),
        isLoading: false,
        influencer: null,
        id: this.props.match.params.id,
    };

    componentDidMount() {
        this.refresh(this.state.id);
    }

    refresh(id: any) {
        InfluencersService.getInfluencers({
            id: id,
            acceptLanguage: localizationHelper.getLocale(),
        }).then((resp) => {
            this.setState({
                influencer: resp.data as Influencer,
            });
        });
    }

    render() {
        return (
            <Authenticated>
                <LayoutWrapper>
                    {(RoleHelper.isInRole(Roles.Admin) || RoleHelper.isInRole(Roles.Root)) && (
                        <AdminInfluencerDetail refresh={this.refresh} showEdit={this.state.showEdit} influencer={this.state.influencer} />
                    )}
                    {RoleHelper.isInRole(Roles.Brand) && <BrandInfluencerDetail influencer={this.state.influencer} />}
                </LayoutWrapper>
            </Authenticated>
        );
    }
}
